import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";
import S3BucketLink from "./S3BucketLink";

export default function S3BucketTable({
  s3Buckets,
  s3BucketLink = true,
  layout = ["Id", "Customer", "Size"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: s3Bucket } }) =>
          s3BucketLink ? (
            <S3BucketLink id={s3Bucket?.id}>
              {value || s3Bucket?.id}
            </S3BucketLink>
          ) : (
            value
          ),
      },
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: ({ value }) => <Customer.Link id={value}>{value}</Customer.Link>,
      },
      {
        id: "Size",
        Header: "Size in GB",
        accessor: "size",
      },
    ],
    [s3BucketLink],
  );

  return (
    <Table
      hasCount
      label="S3 Buckets"
      columns={columns}
      layout={layout}
      data={s3Buckets}
      {...props}
    />
  );
}
