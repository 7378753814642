import { createAttachmentAPI } from "../../../apis/attachments";
import shark from "../../../apis/shark";

function getQaReviewsAPI(filters) {
  const payload = {
    command: "getQaReviews",
    ...filters,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.qaReviews,
    searchArgs: filters,
  }));
}

function getQaReviewAPI({ id }) {
  const payload = {
    command: "getQaReview",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function updateQaReviewAPI(qaReview) {
  const payload = {
    command: "updateQaReview",
    ...qaReview,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createQaReviewAPI(qareview) {
  const payload = {
    command: "createQaReview",
    ...qareview,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createAttachmentQaReviewAPI(args) {
  return createAttachmentAPI({
    command: "createQaReviewAttachment",
    ...args,
  });
}

export const api = {
  getMany: getQaReviewsAPI,
  getOne: getQaReviewAPI,
  updateOne: updateQaReviewAPI,
  createOne: createQaReviewAPI,
  createAttachment: createAttachmentQaReviewAPI,
};
