import _ from "lodash";
import React, { useMemo, useState } from "react";
import {
  Col,
  Dropdown,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { BsLink45Deg } from "react-icons/bs";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";

import { SHARK_URL } from "./apis/shark";
import CenteredModal from "./common/modals/CenteredModal";
import CrNav from "./crs/CrNav";
import NavSearch from "./NavSearch";
import NavTimer from "./time/NavTimer";

function useNavLinks() {
  const { accessApprovals } = useSelector((state) => state.auth);

  const links = useMemo(() => {
    let links = [
      { label: "Home", to: "/home" },
      { label: "Projects", to: "/projects" },
      { label: "CRs", to: "/crs" },
      { label: "Time", to: "/time" },
      { label: "Customers", to: "/customers" },
      { label: "Users", to: "/users", more: 0 },
      { label: "Reports", to: "/reports", more: 1 },
      { label: "Servers", to: "/servers", more: 1 },
      { label: "EDI", to: "/edi", more: 1 },
      { label: "Invoices", to: "/invoices", more: 1 },
      { label: "Contracts", to: "/contracts", more: 1 },
      { label: "Code Reviews", to: "/code-reviews", more: 1 },
      {
        label: (
          <>
            {"Support Portal "}
            <BsLink45Deg />
          </>
        ),
        href: "https://support.producepro.com/",
        more: 2,
      },
      {
        label: (
          <>
            {"Knowledge Base "}
            <BsLink45Deg />
          </>
        ),
        href: "http://kb.producepro.com/",
        more: 2,
      },
      {
        label: (
          <>
            {"Notion: Developer Landing"}
            <BsLink45Deg />
          </>
        ),
        href: "https://www.notion.so/producepro/Developer-Landing-dd9fbb4757ae4b63b4933f6261f37228",
        more: 2,
      },
      {
        label: (
          <>
            {"Helix TeamHub "}
            <BsLink45Deg />
          </>
        ),
        href: "https://helixteamhub.cloud/producepro/",
        more: 2,
      },
      {
        label: (
          <>
            {"Jira "}
            <BsLink45Deg />
          </>
        ),
        href: "https://pprosoftware.atlassian.net/jira/",
        more: 2,
      },
      {
        label: (
          <>
            {"Analytics "}
            <BsLink45Deg />
          </>
        ),

        href: "https://analytics.producepro.com/bi",
        more: 2,
      },
    ];
    if (accessApprovals)
      links.push({ label: "Approvals", to: "/approvals", more: 1 });

    links.push({ label: "Expenses", to: "/expenses", more: 1 });
    links.push({ label: "DBC Code Management", to: "/dbc", more: 1 });

    return links;
  }, [accessApprovals]);
  return links;
}

export default function NavBarCommon({ isLoading }) {
  const { isLoggedIn, name } = useSelector((state) => state.auth);

  const links = useNavLinks();
  const { NavLinks, NavMore } = useMemo(() => {
    const NavLinks = ({ children }) => (
      <Nav className="mr-auto">
        {links
          .filter((l) => _.isUndefined(l.more))
          .map((l, idx) => (
            <LinkContainer to={l.to} key={`navlink-${idx}`}>
              <Nav.Link>{l.label}</Nav.Link>
            </LinkContainer>
          ))}
        {children}
      </Nav>
    );
    const NavMore = () => {
      const mapLinks = (l, idx) =>
        l.to ? (
          <LinkContainer to={l.to} key={`navmore${l.more}-${idx}`}>
            <NavDropdown.Item>{l.label}</NavDropdown.Item>
          </LinkContainer>
        ) : (
          <NavDropdown.Item
            active={false}
            href={l.href}
            target="_blank"
            key={`navmore${l.more}-${idx}`}
          >
            {l.label}
          </NavDropdown.Item>
        );
      return (
        <NavDropdown title="More" menuRole="menu">
          {links.filter((l) => l.more === 0).map(mapLinks)}
          <NavDropdown.Divider />
          {links.filter((l) => l.more === 1).map(mapLinks)}
          <NavDropdown.Divider />
          {links.filter((l) => l.more === 2).map(mapLinks)}
        </NavDropdown>
      );
    };
    return { NavLinks, NavMore };
  }, [links]);

  const [showShortcuts, setShowShortcuts] = useState(false);
  const [showColors, setShowColors] = useState(false);
  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top">
        <LinkContainer to="/home">
          <Navbar.Brand href="#">
            {SHARK_URL.includes("shark-test") ? "PPro T3 (Test)" : "PPro T3"}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        {!isLoggedIn ? (
          <LinkContainer to="/auth/login">
            <Nav.Link href="#Login">Login</Nav.Link>
          </LinkContainer>
        ) : (
          <Navbar.Collapse className="justify-content-end">
            <NavLinks>
              <NavMore />
            </NavLinks>
            <CrNav />
            <NavTimer isLoading={isLoading} />
            <NavSearch isLoading={isLoading} links={links} />
            <NavBarUser
              name={name}
              clickedShortcuts={() => setShowShortcuts(true)}
              clickedColors={() => setShowColors(true)}
            />
          </Navbar.Collapse>
        )}
      </Navbar>
      <CenteredModal
        show={showShortcuts}
        onClose={() => setShowShortcuts(false)}
        modalTitle="Shortcuts in T3"
        body={<ShortcutKeys />}
      />
      <CenteredModal
        show={showColors}
        onClose={() => setShowColors(false)}
        modalTitle="Colors in T3"
        body={<Colors />}
      />
    </>
  );
}

function ShortcutKeys() {
  return (
    <ListGroup className="container" style={{ textAlign: "center" }}>
      <ListGroup.Item variant="primary" className="py-1 font-weight-bold">
        Global
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row style={{ textAlign: "center" }}>
          <Col>New Project</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Alt + C</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row style={{ textAlign: "center" }}>
          <Col>New Time Entry</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Alt + T</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="primary" className="py-1 font-weight-bold">
        Search box
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row style={{ textAlign: "center" }}>
          <Col>Gain Focus</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Control + K</kbd> OR <kbd>Alt + /</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row>
          <Col>After gaining Focus</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>Up/Down Arrow to choose menu options or recent activity</Col>
        </Row>
        <Row>
          <Col>Type to search through menu or recent activity</Col>
        </Row>
        <Row>
          <Col>Type a CR or Project # to jump to it.</Col>
        </Row>
        <Row>
          <Col>Enter to submit your choice</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="primary" className="py-1 font-weight-bold">
        Time add/modification Form
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row>
          <Col>Save the record</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Control + S</kbd> OR <kbd>Control + Enter</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row>
          <Col>Cancel changes</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Esc</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="primary" className="py-1 font-weight-bold">
        Tables
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row style={{ textAlign: "center" }}>
          <Col>Sorting</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>Click column header to toggle sorting</Col>
        </Row>
        <Row>
          <Col>
            Hold <kbd>Shift</kbd> and click column header to sort by multiple
            columns
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="primary" className="py-1 font-weight-bold">
        Expense Entry Pages
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row>
          <Col>Add expense</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Alt + A</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item variant="secondary" className="py-1">
        <Row>
          <Col>Save the record</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1">
        <Row>
          <Col>
            <kbd>Control + Enter</kbd>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="py-1" variant="warning">
        {"Mac users: Control means the Command key"}
      </ListGroup.Item>
    </ListGroup>
  );
}

function Colors() {
  return (
    <Tabs defaultActiveKey="CR listing">
      <Tab eventKey="CR listing" title="CR listing">
        <ListGroup className="container">
          <ListGroup.Item className="py-1 table-dark">
            Emergency issue
          </ListGroup.Item>
          <ListGroup.Item className="py-1 bg-danger">
            Critical issue
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="danger">
            Priority or important issue
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="primary">
            Practice upgrade issue
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="warning">
            CR is late based on first contact or start work targets.
          </ListGroup.Item>
          <ListGroup.Item className="py-1 bg-warning">
            CR will be late within the next 60 minutes based on first contact or
            start work targets.
          </ListGroup.Item>
          <ListGroup.Item className="py-1">Default color</ListGroup.Item>
        </ListGroup>
      </Tab>
      <Tab eventKey="Time listing" title="Time listing">
        <ListGroup className="container">
          <ListGroup.Item className="py-1" variant="success">
            Billable time
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="warning">
            Customer time that does not have a CR yet.
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="secondary">
            Internal project time. Example: Vacation time
          </ListGroup.Item>
          <ListGroup.Item className="py-1">
            Time against an internal CR
          </ListGroup.Item>
          <ListGroup.Item className="py-1" variant="danger">
            Non-billable time
          </ListGroup.Item>
        </ListGroup>
      </Tab>
    </Tabs>
  );
}

function NavBarUser({ name, clickedShortcuts, clickedColors }) {
  const version = localStorage.getItem("version");
  const versionBuild = localStorage.getItem("versionBuild");
  return (
    <NavDropdown title={name} style={{ minWidth: "200px" }}>
      <LinkContainer to="/settings">
        <NavDropdown.Item>Settings</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Item onClick={() => clickedShortcuts()}>
        Shortcut keys
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => clickedColors()}>
        Colors
      </NavDropdown.Item>
      <LinkContainer to="/auth/logout">
        <NavDropdown.Item>Logout</NavDropdown.Item>
      </LinkContainer>
      <Dropdown.Divider />
      <Dropdown.Item disabled>
        <small>{`${version}${
          versionBuild && versionBuild !== "master" ? "-" + versionBuild : ""
        }`}</small>
      </Dropdown.Item>
    </NavDropdown>
  );
}
