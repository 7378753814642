import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import awsRegionSchema from "./awsRegionSchema";

export default function AwsRegionEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: awsRegionSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <AwsRegionForm isNew={isNew} />
    </Forms>
  );
}

function AwsRegionForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="regionDescription"
                label="Region Description"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
