import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Edi from "../../../edi";
import EdiNavSidebar from "../EdiNavSidebar";

export default function CustomerEdiSetupShow() {
  const id = parseInt(useParams().id);
  const { customerEdiSetup, loading, updateOne } = Edi.CustomerEdiSetup.useOne({
    id: id,
    canGet: true,
  });
  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="Customer EDI Setup"
        navSidebar={<EdiNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Customer EDI Setup" navSidebar={<EdiNavSidebar />}>
      {customerEdiSetup ? (
        <>
          <Edi.CustomerEdiSetup.Editor
            values={customerEdiSetup}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={customerEdiSetup} />
        </>
      ) : (
        <h4>Customer EDI Setup record not found.</h4>
      )}
    </Page>
  );
}
