import React from "react";

import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import Cr from "../../crs";
import CrNavSidebar from "./CrNavSidebar";

export default function CrNew() {
  const projectId = useSearchParams("project");
  return (
    <Page title={`New CR`} navSidebar={<CrNavSidebar />}>
      <Cr.New cr={{ projectId }} />
    </Page>
  );
}
