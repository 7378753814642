import shark from "../../../apis/shark";

function getUserGroupingsAPI() {
  const payload = {
    command: "getUserGroupings",
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData.userGroupings }));
}

function getUserGroupingAPI({ id }) {
  const payload = {
    command: "getUserGrouping",
    id: id,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createUserGroupingAPI(payload1) {
  const payload = {
    command: "createUserGrouping",
    ...payload1,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateUserGroupingAPI(payload1) {
  const payload = {
    command: "updateUserGrouping",
    ...payload1,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createUserGroupingMemberAPI(payload1) {
  const payload = {
    command: "createUserGroupingMember",
    ...payload1,
  };
  return shark.post(payload).then(({ responseData }) => responseData);
}

function deleteUserGroupingMemberAPI(payload1) {
  const payload = {
    command: "deleteUserGroupingMember",
    ...payload1,
  };
  return shark.post(payload).then(({ responseData }) => responseData);
}

export const api = {
  getMany: getUserGroupingsAPI,
  getOne: getUserGroupingAPI,
  createOne: createUserGroupingAPI,
  updateOne: updateUserGroupingAPI,
  addMember: createUserGroupingMemberAPI,
  deleteMember: deleteUserGroupingMemberAPI,
};
