import { entityTableFilter } from "../../common/entity";
import { useCustomerEdiSetups } from "./hooks";

const CustomerEdiSetupTableFilter = entityTableFilter({
  name: "customerEdiSetup",
  useEntities: useCustomerEdiSetups,
  filter: (e, value) => e.isInactive === false || e.id === value,
});

export default CustomerEdiSetupTableFilter;
