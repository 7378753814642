import { createEntityHooks } from "../common/entity";
import parseIntId from "../common/functions/parseIntId";
import serverState from "./state";

export const {
  useEntities: useServers,
  useEntity: useServer,
  useEntitiesBySearch: useServersByCustomer,
} = createEntityHooks({
  entity: serverState.entity,
  actions: serverState.actions,
  parseId: (id) => {
    const nextId = parseIntId(id);
    return nextId === 0 ? undefined : nextId;
  },
  expire: {
    key: "hours",
    value: 12,
  },
});
