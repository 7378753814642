import React from "react";
import Skeleton from "react-loading-skeleton";

import Page from "../../common/pages/Page";

export default function ProjectPage({
  project,
  heading,
  lead,
  isLoading,
  children,
  navSidebar,
}) {
  const title = project ? `${heading}: ${project.synopsis}` : heading;

  const _lead = lead ? (
    lead
  ) : project?.synopsis ? (
    project?.synopsis
  ) : isLoading ? (
    <Skeleton />
  ) : (
    ""
  );
  return (
    <Page title={title} heading={heading} lead={_lead} navSidebar={navSidebar}>
      {children}
    </Page>
  );
}
