import { entitySelect } from "../../common/entity";
import { useStatuses } from "./hooks";

const StatusSelect = entitySelect({
  name: "crStatus",
  useEntities: useStatuses,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default StatusSelect;
