import useLogin from "./useLogin";
import useLogout from "./useLogout";
import useToken from "./useToken";

const Auth = {
  useLogout: useLogout,
  useToken: useToken,
  useLogin: useLogin,
};

export default Auth;
