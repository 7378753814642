import React from "react";
import { Link } from "react-router-dom";

export default function ProjectLink({ id, children }) {
  if (isNaN(id)) return <>{id}</>;
  if (id === 0) return null;
  return (
    <Link
      className="text-reset"
      target="_blank"
      to={{ pathname: `/projects/${id}` }}
    >
      {children || id}
    </Link>
  );
}
