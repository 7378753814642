import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import AwsInstanceTypeLink from "../../servers/awsInstanceTypes/AwsInstanceTypeLink";
import AwsZoneLink from "../../servers/awsZones/AwsZoneLink";
import S3BucketLink from "../../servers/s3Buckets/S3BucketLink";
import ServerLink from "../../servers/ServerLink";
import CustomerLink from "../CustomerLink";
import CustomerTableFilter from "../CustomerTableFilter";
import HostingAgreementLink from "../hostingAgreements/HostingAgreementLink";
import { HOSTING_TYPES } from "../hostingAgreements/types";

export default function HostingAgreementMonthExceptionsTable({
  data,
  Components: OverrideComponents,
  layout = [
    "Customer",
    "Hosting Agreement",
    "Server",
    "Exception",
    "Agreed Server Type",
    "Server Type",
    "Agreed AWS Zone",
    "Server AWS Zone",
    "Agreed AWS Instance Type",
    "Server AWS Instance Type",
    "S3 Bucket",
  ],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },
      {
        id: "Hosting Agreement",
        Header: "Hosting Agreement",
        accessor: "hostingAgreementId",
        Cell: ({ value }) => <HostingAgreementLink id={value} />,
      },
      {
        id: "Server",
        Header: "Server",
        accessor: "serverId",
        Cell: (x) => (
          <ServerLink id={x?.value}>
            {x?.row?.original?.serverDescription}
          </ServerLink>
        ),
      },
      {
        id: "Server Type",
        Header: "Type",
        accessor: (l) => HOSTING_TYPES[l?.serverType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Agreed Server Type",
        Header: "Agreed Type",
        accessor: (l) =>
          HOSTING_TYPES[l?.hostingAgreementServerType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Exception",
        Header: "Exception",
        accessor: "exception",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Agreed AWS Zone",
        Header: "Agreed AWS Zone",
        accessor: "hostingAgreementAwsZone",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "Agreed AWS Instance Type",
        Header: "Agreed AWS Instance Type",
        accessor: "hostingAgreementAwsInstanceType",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
      {
        id: "Server AWS Zone",
        Header: "Server AWS Zone",
        accessor: "serverAwsZone",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "Server AWS Instance Type",
        Header: "Server AWS Instance Type",
        accessor: "serverAwsInstanceType",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
      {
        id: "S3 Bucket",
        Header: "S3 Bucket",
        accessor: "bucketId",
        Cell: ({ value }) => <S3BucketLink id={value} />,
      },
    ],
    [],
  );
  return (
    <Table
      hasCount
      label="Hosting Agreement Exceptions"
      columns={columns}
      layout={layout}
      data={data}
      getRowProps={(row) => {
        if (row.isGrouped) return {};
        if (row?.original.status === "C") {
          return {
            className: "table-success",
          };
        } else if (row?.original.exceptionsExist) {
          return {
            className: "table-warning",
          };
        } else {
          return {};
        }
      }}
      {...props}
    />
  );
}
