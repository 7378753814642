import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import TimeModify from "./TimeModify";

export default function TimeModifyModal({
  id,
  show,
  onHide,
  onCancel,
  onSave,
  onDelete,
  openTimers = false,
  stopTimer = false,
}) {
  const [splitted, setSplitted] = useState(false);

  //This setSplitted in the onHide to false is needed
  //because this component doesn't unload
  return (
    <Modal
      show={show}
      onHide={() => {
        if (splitted) {
          onSave();
          setSplitted(false);
        } else onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {"Time record: " + id}
          {stopTimer ? "  (Timer will be stopped)" : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <TimeModify
          timeId={id}
          stopTimer={stopTimer}
          openTimers={openTimers}
          onSaveSuccess={() => {
            setSplitted(false);
            onSave();
          }}
          onCancel={onCancel}
          onDelete={() => {
            setSplitted(false);
            onDelete();
          }}
          onSplit={() => setSplitted(true)}
        />
      </Modal.Body>
    </Modal>
  );
}
