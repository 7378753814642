import { sum } from "lodash";
import React from "react";

import NavSidebarItem from "../../common/navs/NavSidebarItem";
import Cr from "../../crs";
import CrNavSidebar from "./CrNavSidebar";

export default function CrShowNavSidebar({ id }) {
  const { cr } = Cr.useOne({ id, canGet: false });
  const revisionsClassName = cr?.code_revisions?.length ? "" : "font-italic";

  const timeClassName =
    sum([
      cr?.actualInvoicedHours,
      cr?.actualInvoicedNbHours,
      cr?.actualBillableHours,
      cr?.actualNonBillableHours,
    ]) > 0
      ? ""
      : "font-italic";
  return (
    <CrNavSidebar>
      <NavSidebarItem
        className="font-weight-bold"
        to={`/crs/${id}`}
      >{`CR ${id}`}</NavSidebarItem>
      <NavSidebarItem to={`/crs/${id}/edit`}>Edit</NavSidebarItem>
      <NavSidebarItem to={`/crs/${id}/project`}>Project</NavSidebarItem>
      <NavSidebarItem to={`/crs/${id}/time`} className={timeClassName}>
        Time
      </NavSidebarItem>
      <NavSidebarItem
        to={`/crs/${id}/revisions`}
        className={revisionsClassName}
      >
        Revisions
      </NavSidebarItem>
      <NavSidebarItem to={`/crs/${id}/log`}>Log</NavSidebarItem>
      <NavSidebarItem to={`/crs/${id}/qa`}>QA</NavSidebarItem>
    </CrNavSidebar>
  );
}
