import React from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

export default function toastMessage(
  props,
  { type = "default", ...toastOptions } = {},
) {
  return toast(<Prompt {...props} />, {
    type,
    ...toastOptions,
  });
}

function Prompt({ message, title = "" }) {
  return (
    <Row>
      <Col>
        <Row>
          <Col>{title ? <h5>{title}</h5> : null}</Col>
        </Row>
        <Row>
          <Col>
            <p className="text-break mb-0" style={{ whiteSpace: "pre-wrap" }}>
              {message}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
