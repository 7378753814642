function getRowValuesByColumnID(row1, row2, columnId) {
  return [row1.values[columnId], row2.values[columnId]];
}

function sortStringValues(aV, bV) {
  let a = String(aV)?.split("").filter(Boolean);
  let b = String(bV)?.split("").filter(Boolean);

  while (a.length && b.length) {
    let aa = a.shift();
    let bb = b.shift();

    let alower = aa.toLowerCase();
    let blower = bb.toLowerCase();

    // Case insensitive comparison until characters match
    if (alower > blower) {
      return 1;
    }
    if (blower > alower) {
      return -1;
    }
    // If lowercase characters are identical
    if (aa > bb) {
      return 1;
    }
    if (bb > aa) {
      return -1;
    }
    continue;
  }

  return a.length - b.length;
}

export function sortString(rowA, rowB, columnId) {
  const [a = "", b = ""] = getRowValuesByColumnID(rowA, rowB, columnId);
  return sortStringValues(a, b);
}

export function sortCustom(rowA, rowB, columnId) {
  function convertValue(v = "") {
    if (typeof v !== "string") {
      return String(v).padStart(12);
    }
    return v;
  }
  const [a = "", b = ""] = getRowValuesByColumnID(rowA, rowB, columnId);
  return sortStringValues(convertValue(a), convertValue(b));
}

export function sortNumeric(rowA, rowB, columnId) {
  const [a, b] = getRowValuesByColumnID(rowA, rowB, columnId);
  return a > b;
}
