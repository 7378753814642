import React from "react";
import { Button, Modal } from "react-bootstrap";

// Don't rename modalTitle to Title..
// there is something weird going on with that prop
export default function CenteredModal({
  modalTitle,
  onClose,
  bodyHeader,
  body,
  show,
}) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{bodyHeader}</h4>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
