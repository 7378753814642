import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import TradingPartner from "../../../edi/tradingPartners";
import EdiNavSidebar from "../EdiNavSidebar";

export default function TradingPartnerNew() {
  const { createOne } = TradingPartner.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="New Trading Partner"
        navSidebar={<EdiNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title={"New Trading Partner"} navSidebar={<EdiNavSidebar />}>
      <TradingPartner.Editor
        values={{ isInactive: false }}
        onSubmit={(tradingPartner) =>
          createOne(tradingPartner).then(({ id }) =>
            setRedirect(`/edi/tradingPartners/${id}`),
          )
        }
      />
    </Page>
  );
}
