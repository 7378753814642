import _ from "lodash";
import React from "react";
import { ListGroup } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { LinkContainer } from "react-router-bootstrap";

export default function List({
  list,
  fields,
  idField = "id",
  link,
  isLoading = false,
  skeletonLines = 15,
}) {
  let items = [];
  if (_.isUndefined(list) || list.length === 0) {
    if (isLoading) {
      for (let i = 0; i < skeletonLines; i++) {
        items.push(
          <ListGroup.Item action key={"skel-list-" + i}>
            <Skeleton />
          </ListGroup.Item>,
        );
      }
    } else return null;
  } else {
    items = list.map((item) => (
      <LinkContainer to={link + item[idField]} key={`list[${item[idField]}]`}>
        <ListGroup.Item action>
          <>{fields.map((field) => item[field]).join(" ")}</>
        </ListGroup.Item>
      </LinkContainer>
    ));
  }

  return <ListGroup>{items}</ListGroup>;
}
