import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Page from "../../../common/pages/Page";
import Contract from "../../../contracts";
import ContractNavSidebar from "../ContractNavSidebar";

export default function ContractProductIndex() {
  const [active, setActive] = useState(true);
  const { entities, loading, reset } = Contract.ContractProduct.useAll({
    canGet: true,
  });

  Contract.ContractProductCategory.useAll({
    canGet: true,
  });

  const data = useMemo(() => {
    return entities.filter((e) => {
      return e.isInactive === !active;
    });
  }, [active, entities]);

  return (
    <Page title="Contract Products" navSidebar={<ContractNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Contract.ContractProduct.Table
        contractProducts={data}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
