import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsZoneNavSidebar from "./AwsZoneNavSidebar";

export default function AwsZoneEdit() {
  const id = String(useParams().id).toLowerCase();
  const { awsZone, loading, updateOne } = Server.AwsZone.useOne({
    id,
    canGet: true,
  });
  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Zone"
        navSidebar={<AwsZoneNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Aws Zone" navSidebar={<AwsZoneNavSidebar />}>
      {awsZone ? (
        <>
          <Server.AwsZone.Editor
            values={awsZone}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={awsZone} />
        </>
      ) : (
        <h4>Aws Zone not found.</h4>
      )}
    </Page>
  );
}
