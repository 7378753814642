import shark from "../../../apis/shark";

export const api = {
  getOne: ({ id }) => {
    const payload = {
      command: "getHostingAgreementMonth",
      id: id,
    };
    return shark.post(payload).then((response) => ({
      data: response.responseData,
    }));
  },

  getMany: () => {
    const payload = { command: "getHostingAgreementMonths" };
    return shark.post(payload).then((r) => {
      return { data: r.responseData.hostingAgreementMonths };
    });
  },

  updateOne: (month) => {
    const payload = {
      command: "runHostingAgreementMonth",
      ...month,
    };
    return shark
      .post(payload)
      .then(({ responseData }) => ({ data: responseData }));
  },
};
