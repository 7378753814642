import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getTeams" }).then((r) => {
      return { data: r.responseData.teams };
    });
  },
};

const entity = createEntity({
  name: "team",
  type: "users/teams",
  getState: (state) => state.users.teams,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const teamState = {
  api,
  entity,
  actions,
  reducer,
};

export default teamState;
