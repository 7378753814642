import { entityTableFilter } from "../../common/entity";
import { useTypes } from "./hooks";

const TypeTableFilter = entityTableFilter({
  name: "projectType",
  useEntities: useTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default TypeTableFilter;
