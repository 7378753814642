import React, { useMemo } from "react";

import { CurrencyFormatter } from "../common/formatters";
import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import { SelectColumnFilter } from "../common/tables/TableFilters";
import User from "../users";
import { ESTIMATE_ACTIVE_STATUSES } from "./activeStatuses";
import EstimateLink from "./EstimateLink";
import { ESTIMATE_TYPES } from "./types";

export default function EstimateTable({
  estimates,
  layout = ["id", "type", "activeStatus", "owner", "hours", "cost"],
  setModifyShow,
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "Estimate #",
        accessor: "id",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (o) => (
          <EstimateLink
            id={o?.row?.original?.id}
            date={o?.row?.original.date}
            setModifyShow={setModifyShow}
          >
            {o?.row?.original?.id +
              " - " +
              _date.display(_date.fromStamp(o?.row?.original.date))}
          </EstimateLink>
        ),
      },
      {
        id: "type",
        Header: "Type",
        accessor: (estimate) =>
          ESTIMATE_TYPES[estimate?.type]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "activeStatus",
        Header: "Active",
        accessor: (estimate) =>
          ESTIMATE_ACTIVE_STATUSES[estimate?.activeStatus]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "owner",
        Header: "Owner",
        accessor: "owner",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "hours",
        Header: "Hours",
        accessor: "totalHours",
        format: {
          alignment: { horizontal: "right" },
          number: {
            decimals: 2,
          },
        },
      },
      {
        id: "cost",
        Header: "Cost",
        accessor: "totalCost",
        Cell: ({ value }) => <CurrencyFormatter value={value} />,
        format: {
          alignment: { horizontal: "right" },
        },
      },
    ],
    [setModifyShow],
  );
  return (
    <Table
      hasCount
      label="Estimates"
      columns={columns}
      layout={layout}
      data={estimates}
      {...props}
    />
  );
}
