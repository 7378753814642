import parsePhoneNumberFromString from "libphonenumber-js";

export function formatPhone(phone, extension) {
  if (!phone) return phone;
  let _phone = phone;
  if (extension) {
    _phone = phone + " x" + extension;
  }
  const newPhone = parsePhoneNumberFromString(
    _phone,
    "US",
  )?.formatInternational();

  if (!newPhone) return phone;
  return newPhone;
}
