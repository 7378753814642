import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";

import useUserActivityOptions from "../activity/userActivity/useUserActivityOptions";
import FieldSelect, { OptionExtended } from "../common/fields/FieldSelect";
import useReinitState from "../common/hooks/useReinitState";
import Project from "../projects";
import CrSearch from "./CrSearch";
import useCr from "./useCr";

const selectComponents = { Option: OptionExtended };

export default function CrSelect({
  id,
  label,
  onChange,
  placeholder = "CR/Project...",
  errors: initialErrors,
  value: initialValue,
  isLoading,
  searchButton = true,
  validateOnMount = false,
  canFetch,
  refreshActivityButton = false,
  customerFilter,
  ...props
}) {
  const [input, setInput] = useState("");
  const [errors, setErrors] = useReinitState(initialErrors);
  const { getProject, loading: loadingProject } = Project.useOne.getOne();
  const { getCr, loading: loadingCr } = useCr.getOne();

  const [searchProps, setSearchProps] = useState(null);
  const shouldShowSearch = searchProps !== null;

  let options = [];

  Project.useFindOption({ input, options });
  const CrSelectFilter = (activity) => {
    return (
      activity.source == "CR" &&
      (!customerFilter ||
        !activity?.customers ||
        activity?.customers.includes(customerFilter))
    );
  };
  const { loading: loadingActivity, getMany: getActivities } =
    useUserActivityOptions({ options, filter: CrSelectFilter });

  const {
    handleValue: handleCr,
    value,
    loading: valueLoading,
  } = Project.Select.useValue({
    get: getCr,
    errors: initialErrors,
    typeOf: "cr",
    setErrors,
    fetch: canFetch,
  });

  const _loading =
    isLoading ||
    valueLoading ||
    loadingProject ||
    loadingCr ||
    loadingActivity ||
    false;

  const handleChange = useCallback(
    async ({ typeOf, id } = {}) => {
      if (typeOf === "project")
        return getProject({ id })
          .then(({ data: project }) =>
            project?.crs.length > 1
              ? setSearchProps({ defaultProjectId: project?.id })
              : handleCr(project?.crs[0]?.id).then((cr) => onChange({ ...cr })),
          )
          .catch((e) => setErrors(e.message));
      else if (typeOf === "cr")
        return handleCr(id)
          .then((cr) => onChange({ ...cr }))
          .catch((e) => setErrors(e.message));
      else if (id === undefined) onChange(null);
    },
    [getProject, handleCr, onChange, setErrors],
  );

  const [validatedId, setValidatedId] = useState(null);
  const shouldUseInitial =
    !isNil(initialValue) &&
    initialValue !== value?.value?.id &&
    (validatedId === null || initialValue !== validatedId);

  useEffect(() => {
    if (shouldUseInitial) {
      if (validateOnMount) {
        setValidatedId(initialValue);
        handleChange({ typeOf: "cr", id: initialValue });
      } else handleCr(initialValue).catch((e) => setErrors(e.message));
    }
  }, [
    initialValue,
    shouldUseInitial,
    setErrors,
    validateOnMount,
    handleChange,
    handleCr,
  ]);

  return (
    <Form.Row className="align-items-center">
      <Col>
        <Form.Row>
          {label ? <Form.Label htmlFor={id}>{label}</Form.Label> : null}
        </Form.Row>
        <Form.Row>
          <Col style={{ minWidth: "300px" }}>
            <FieldSelect
              components={selectComponents}
              placeholder={placeholder}
              isLoading={_loading}
              disabled={_loading}
              value={value}
              options={options}
              onChange={handleChange}
              onInputChange={(s) => setInput(s)}
              blurInputOnSelect
              errors={errors}
              isInvalid={errors}
              {...props}
            />
          </Col>
          {searchButton ? (
            <Col xs="auto">
              <Button
                onClick={() => setSearchProps({})}
                variant="light"
                title="Search for CR"
              >
                <BiSearchAlt2 />
              </Button>
            </Col>
          ) : null}
          <Modal
            show={shouldShowSearch}
            size="xl"
            onHide={() => setSearchProps(null)}
            className="modal2"
            backdropClassName="modal2-backdrop"
          >
            <Modal.Header closeButton>
              <Modal.Title>CR Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CrSearch
                onSelect={(id) => {
                  setSearchProps(null);
                  handleChange({ id, typeOf: "cr" });
                }}
                {...searchProps}
              />
            </Modal.Body>
          </Modal>
        </Form.Row>
        <Form.Row className="justify-content-end mt-n3 py-0">
          <Col sm="auto">
            {refreshActivityButton ? (
              <Button
                variant="link"
                className="text-reset py-0 my-0"
                size="sm"
                onClick={() => {
                  getActivities();
                }}
              >
                Refresh Activity
              </Button>
            ) : null}
          </Col>
        </Form.Row>
      </Col>
    </Form.Row>
  );
}
