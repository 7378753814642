import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getFtpUsers" }).then((response) => {
      return { data: response.responseData.ftpUsers };
    });
  },
  getOne: ({ id }) => {
    return shark.post({ command: "getFtpUser", id: id }).then((response) => {
      return { data: response.responseData };
    });
  },
  createOne: (ftpUser) => {
    return shark
      .post({ command: "createFtpUser", ...ftpUser })
      .then((response) => {
        return { data: response.responseData };
      });
  },
  updateOne: (ftpUser) => {
    return shark
      .post({ command: "updateFtpUser", ...ftpUser })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "ftpUsers",
  type: "edi/ftpUsers",
  getState: (state) => state.edi.ftpUsers,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const ftpUserState = {
  api,
  entity,
  actions,
  reducer,
};

export default ftpUserState;
