import { entitySelect } from "../../common/entity";
import { useAwsRegions } from "./hooks";

const AwsRegionSelect = entitySelect({
  name: "awsRegion",
  useEntities: useAwsRegions,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.id + "(" + e.regionDescription + ")",
});

export default AwsRegionSelect;
