import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import AwsInstanceTypeEdit from "./AwsInstanceTypeEdit";
import AwsInstanceTypeIndex from "./AwsInstanceTypeIndex";
import AwsInstanceTypeNavSidebar from "./AwsInstanceTypeNavSidebar";
import AwsInstanceTypeNew from "./AwsInstanceTypeNew";

export default function AwsInstanceTypesPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Instance Type"
        navSidebar={<AwsInstanceTypeNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <AwsInstanceTypeNew />
      </Route>
      <Route path={`${path}/:id`}>
        <AwsInstanceTypeEdit />
      </Route>
      <Route exact path={`${path}`}>
        <AwsInstanceTypeIndex />
      </Route>
    </Switch>
  );
}
