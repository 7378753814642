import React from "react";
import NumberFormat from "react-number-format";

export function HoursFormatter({ value, suffix = " hr" }) {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      suffix={suffix}
    />
  );
}

export function NumberFormatter({
  value,
  decimals = 2,
  blankZero = false,
  ...props
}) {
  if (blankZero && Number(value) === Number(0)) return "";
  else
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator
        decimalScale={decimals}
        fixedDecimalScale
        {...props}
      />
    );
}

export function CurrencyFormatter({
  value,
  decimals = 2,
  blankZero = false,
  ...props
}) {
  if (blankZero && Number(value) === Number(0)) return "";
  else
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator
        decimalScale={decimals}
        fixedDecimalScale
        prefix={"$"}
        {...props}
      />
    );
}

export function PercentFormatter({ value, decimals = 2, blankZero = false }) {
  if (blankZero && Number(value) === Number(0)) return "";
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator
      decimalScale={decimals}
      fixedDecimalScale
      suffix={"%"}
    />
  );
}
