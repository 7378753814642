import _ from "lodash";
import React, { useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { _date, _datetime } from "../common/functions/dates";
import { entityLookup } from "../common/functions/entityLookup";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Customer from "../customers";
import User from "../users";
import getProjectPriorityClassName from "./getProjectPriorityClassName";
import Priority from "./priorities";
import Status from "./statuses";

export default function ProjectTable({ projects, onSelect, ...props }) {
  const customers = useSelector(Customer.selectors.selectEntities);
  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "Project",
        accessor: "id",
        disableFilters: true,

        Cell: ({ value, row: { original: project } }) =>
          _.isFunction(onSelect) ? (
            <Button
              variant="link"
              className="text-reset py-0 my-n1"
              onClick={() => onSelect(project?.id)}
            >
              {value}
            </Button>
          ) : (
            <Link
              className="text-reset"
              to={{ pathname: `/projects/${value}` }}
            >
              {value}
            </Link>
          ),
      },
      {
        id: "owner",
        Header: "Owner",
        accessor: "owner",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: ({ customers: projectCustomers = [] }) => {
          return projectCustomers
            ? projectCustomers.length > 1
              ? "Multiple"
              : entityLookup(customers, projectCustomers[0]?.customerId || "")
                  ?.name
            : "";
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "contact",
        Header: "Contact",
        accessor: ({ customers: projectCustomers = [] }) => {
          return projectCustomers
            ? projectCustomers.length > 1
              ? "Multiple"
              : projectCustomers[0]?.contactName || ""
            : "";
        },
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "status",
        Header: "Status",
        accessor: "statusId",
        Filter: Status.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Status.Description id={value} />,
      },
      {
        Header: "Status*",
        id: "statusExtended",
        accessor: "statusId",
        Filter: Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        sortInverted: true,

        Cell: ({ value, row: { original: project } }) => (
          <Form.Row>
            <Col>
              <Status.Description id={value} />
            </Col>
            <Col>
              {project?.isWaitingOnCustomer ? "⏳" : ""}
              {project?.isSAMFollowup ? "🆘" : ""}
            </Col>
          </Form.Row>
        ),
      },
      {
        id: "synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value?.substring(0, 8)))}</span>
        ),
      },
      {
        Header: "Priority",
        id: "priority",
        accessor: "priorityId",
        Filter: Priority.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Priority.Description id={value} />,
      },
      {
        Header: "Group",
        id: "group",
        accessor: "groupId",
        Filter: User.Group.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Create Date",
        id: "createDate",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value?.substring(0, 8)))}</span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Create Time",
        id: "createTime",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_datetime.displayTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        Header: "Modify Date",
        id: "modifyDate",
        accessor: "lastUpdateDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Modify Time",
        id: "modifyTime",
        accessor: "lastUpdateDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value ? _datetime.displayTime(_datetime.fromStamp(value)) : null}
          </span>
        ),
      },
      {
        Header: "SAM Follow-Up?",
        id: "isSAMFollowup",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isSAMFollowup }) => (isSAMFollowup ? "🆘" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "Contract Project?",
        id: "isContractProject",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isContractProject }) => (isContractProject ? "✅" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "Contract Description",
        id: "contractDescription",
        format: { alignment: { horizontal: "center" } },
        accessor: "contractDescription",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
    ],
    [customers, onSelect],
  );
  return (
    <Table
      hasCount
      label="Projects"
      columns={columns}
      data={projects}
      getRowProps={(row) => {
        if (!row.original) return {};
        const className = getProjectPriorityClassName(row.original);
        if (!className) return {};
        return { className: className };
      }}
      {...props}
      moveFooterToTop
    />
  );
}
