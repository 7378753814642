import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import ServerNavSidebar from "../ServerNavSidebar";

export default function S3BucketNavSidebar({ children }) {
  return (
    <ServerNavSidebar>
      {children}
      <div className="dropdown-divider" />
      <NavSidebarItem to="/servers/s3-buckets/new">
        New S3 Bucket
      </NavSidebarItem>
    </ServerNavSidebar>
  );
}
