import CustomerEdiSetupEditor from "./CustomerEdiSetupEditor";
import CustomerEdiSetupSelect from "./CustomerEdiSetupSelect";
import CustomerEdiSetupTable from "./CustomerEdiSetupTable";
import CustomerEdiSetupTableFilter from "./CustomerEdiSetupTableFilter";
import { useCustomerEdiSetup, useCustomerEdiSetups } from "./hooks";
import customerEdiSetupState from "./state";

const CustomerEdiSetup = {
  // Backend API
  api: customerEdiSetupState.api,
  // State
  actions: customerEdiSetupState.actions,
  selectors: customerEdiSetupState.entity.selectors,
  // Hooks
  useAll: useCustomerEdiSetups,
  useOne: useCustomerEdiSetup,
  // Components
  Select: CustomerEdiSetupSelect,
  TableFilter: CustomerEdiSetupTableFilter,
  Table: CustomerEdiSetupTable,
  Editor: CustomerEdiSetupEditor,
};

export default CustomerEdiSetup;
