import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const notificationGroupState = {
  api,
  entity,
  actions,
  reducer,
};

export default notificationGroupState;
