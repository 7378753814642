import { entityTableFilter } from "../../common/entity";
import { useModules } from "./hooks";

const ModuleTableFilter = entityTableFilter({
  name: "crModule",
  useEntities: useModules,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default ModuleTableFilter;
