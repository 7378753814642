import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import UserNavSidebar from "../UserNavSidebar";

export default function NotificationGroupNavSidebar({ children }) {
  return (
    <UserNavSidebar>
      <NavSidebarItem to="/users/notification-groups/new">
        New group
      </NavSidebarItem>
      {children}
    </UserNavSidebar>
  );
}
