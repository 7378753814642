import { useRepositories, useRepository } from "./hooks";
import RepositoryDescription from "./RepositoryDescription";
import RepositorySelect from "./RepositorySelect";

const Repository = {
  // Hooks
  useAll: useRepositories,
  useOne: useRepository,
  // Components
  Select: RepositorySelect,
  Description: RepositoryDescription,
};

export default Repository;
