import CustomerContract from "./contracts";
import CustomerChangedAlert from "./CustomerChangedAlert";
import CustomerContactLink from "./CustomerContactLink";
import CustomerLink from "./CustomerLink";
import CustomerLocationSelect from "./CustomerLocationSelect";
import CustomerName from "./CustomerName";
import CustomersSelect from "./CustomerSelect";
import CustomerSpecialWorkConditions from "./CustomerSpecialWorkConditions";
import CustomerTable from "./CustomerTable";
import CustomerTableFilter from "./CustomerTableFilter";
import { useCustomer, useCustomers } from "./hooks";
import HostingAgreement from "./hostingAgreements";
import HostingAgreementMonth from "./hostingAgreementsMonth";
import CustomerModules from "./modules";
import CustomerOrganizations from "./organizations";
import state from "./state";
import ThirdPartyLicense from "./thirdPartyLicenses";

const Customer = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useCustomer,
  useAll: useCustomers,
  // Components
  Table: CustomerTable,
  SpecialWorkConditions: CustomerSpecialWorkConditions,
  Select: CustomersSelect,
  LocationSelect: CustomerLocationSelect,
  Name: CustomerName,
  TableFilter: CustomerTableFilter,
  Link: CustomerLink,
  ContactLink: CustomerContactLink,
  ChangedAlert: CustomerChangedAlert,
  // Related Entity Objects
  Contracts: CustomerContract,
  Modules: CustomerModules,
  Organizations: CustomerOrganizations,
  ThirdPartyLicenses: ThirdPartyLicense,
  HostingAgreements: HostingAgreement,
  HostingAgreementMonths: HostingAgreementMonth,
};

export default Customer;
