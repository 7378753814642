import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { toast, Zoom } from "react-toastify";

export default function toastPrompt(props, toastOptions) {
  let toastId = toast(
    <Prompt
      {...props}
      dismiss={() => {
        toast.dismiss(toastId);
      }}
    />,
    {
      type: "default",
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      draggable: false,
      position: "top-center",
      transition: Zoom,
      ...toastOptions,
    },
  );
}

function Prompt({
  message,
  onConfirm,
  dismiss,
  confirm = "Confirm",
  cancel = "Cancel",
}) {
  return (
    <span>
      <p className="lead text-body">
        <strong>{message}</strong>
      </p>
      <ButtonGroup>
        <Button
          variant="success"
          onClick={() => {
            dismiss();
            onConfirm();
          }}
        >
          {confirm}
        </Button>
        <Button variant="danger" onClick={dismiss}>
          {cancel}
        </Button>
      </ButtonGroup>
    </span>
  );
}
