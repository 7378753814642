import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import UserNavSidebar from "../UserNavSidebar";

export default function UserGroupingNavSidebar({ children }) {
  return (
    <UserNavSidebar>
      <NavSidebarItem to="/users/user-groupings/new">
        New grouping
      </NavSidebarItem>
      {children}
    </UserNavSidebar>
  );
}
