import { combineReducers } from "@reduxjs/toolkit";

import customerContractsState from "../contracts/state";
import hostingAgreementState from "../hostingAgreements/state";
import hostingAgreementMonthsState from "../hostingAgreementsMonth/state";
import jiraProjectState from "../jiraProjects/state";
import customerModulesState from "../modules/state";
import customerOrganizationsState from "../organizations/state";
import thirdPartyLicensesState from "../thirdPartyLicenses/state";
import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const customerReducer = combineReducers({
  customers: reducer,
  contracts: customerContractsState.reducer,
  modules: customerModulesState.reducer,
  organizations: customerOrganizationsState.reducer,
  thirdPartyLicenses: thirdPartyLicensesState.reducer,
  jiraProjects: jiraProjectState.reducer,
  hostingAgreements: hostingAgreementState.reducer,
  hostingAgreementMonths: hostingAgreementMonthsState.reducer,
});

const customerState = {
  api,
  entity,
  actions,
  reducer: customerReducer,
};

export default customerState;
