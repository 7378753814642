import { bool, number, object, string } from "yup";

const serverSchema = object().shape({
  description: string().label("Description").required().max(60),
  customerId: number().label("Customer"),
  hostname: string().label("Hostname").required().max(60),
  awsInstanceId: string().label("AWS Instance Id").max(20),
  severType: string().label("Type").required().max(1),
  aliasPostfix: string().label("Type").max(15),
  locality: string().label("Locality").required().max(1),
  engineeringResponsibility: string().label("Engineering").required().max(1),
  status: string().label("Status").required().max(1).default("A"),
  isOldServer: bool().required().default(false),
});

export default serverSchema;
