import ExpenseCategoryDescription from "./ExpenseCategoryDescription";
import ExpenseCategorySelect from "./ExpenseCategorySelect";
import ExpenseCategoryTableFilter from "./ExpenseCategoryTableFilter";
import { useExpenseCategories, useExpenseCategory } from "./hooks";
import categoryState from "./state";

const Category = {
  // Backend API
  api: categoryState.api,
  // State
  actions: categoryState.actions,
  selectors: categoryState.entity.selectors,
  // Hooks
  useAll: useExpenseCategories,
  useOne: useExpenseCategory,
  // Components
  Select: ExpenseCategorySelect,
  TableFilter: ExpenseCategoryTableFilter,
  Description: ExpenseCategoryDescription,
};

export default Category;
