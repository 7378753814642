import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import TaxProductCategoryEdit from "./TaxProductCategoryEdit";
import TaxProductCategoryIndex from "./TaxProductCategoryIndex";
import TaxProductCategoryNew from "./TaxProductCategoryNew";

export default function TaxProductCategoriesPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <TaxProductCategoryNew />
      </Route>
      <Route path={`${path}/:id`}>
        <TaxProductCategoryEdit />
      </Route>
      <Route exact path={`${path}`}>
        <TaxProductCategoryIndex />
      </Route>
    </Switch>
  );
}
