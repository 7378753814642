import { entityTableFilter } from "../common/entity";
import { useUsers } from "./hooks";

const UserTableFilter = entityTableFilter({
  name: "user",
  useEntities: useUsers,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default UserTableFilter;
