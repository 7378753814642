import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import { createAttachmentAPI } from "../../apis/attachments";
import shark from "../../apis/shark";
import { _date } from "../../common/functions/dates";
import { getChangesExcludingStamps } from "../../common/functions/state/reducers";
import toastMessage from "../../common/toasts/toastMessage";
import Customer from "../../customers";

function getProjectsAPI({
  filter: { folderId, ...filter } = {},
  maxRecs,
  startAfter = "",
}) {
  const payload = {
    ...filter,
    command: "getProjects",
    maxRecs: maxRecs,
    startAfter: startAfter || "",
    transferredFolder: folderId,
  };
  return shark.post(payload).then(({ responseData = {} }) => ({
    data: responseData.projects,
    startAfter: responseData.startAfter,
    searchArgs: { folderId, ...filter },
  }));
}

function getProjectAPI(
  { id, logActivity, includeLogs, fetch = true },
  { getState },
  { selectors },
) {
  const payload = {
    command: "getProject",
    id: id,
    logActivity,
    includeLogs,
  };
  if (fetch !== true) {
    const cr = selectors.selectById(getState(), id);
    if (cr || fetch === false)
      return {
        data: cr,
        updateState: false,
      };
  }
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createProjectAPI(project) {
  function postProcess(nextProject) {
    if (project?.createCr && nextProject?.crs?.length) {
      toast.success(
        `CR ${nextProject?.crs[0].id} created from Project ${nextProject.id}`,
      );
    } else toast.success(`Project ${nextProject.id} created`);
    return nextProject;
  }

  const payload = {
    command: "createProject",
    project,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: postProcess(responseData) }));
}

function updateProjectAPI(
  { id, data: projectFields, updateCrPrompt },
  { getState, dispatch },
  { actions, selectors },
) {
  if (!id) return;
  const currentProject = selectors.selectById(getState(), id);

  function postProcess(nextProject) {
    toast.success(`Project ${id} saved`);
    const getProjectAction = () => actions.getOne({ id });
    if (
      nextProject.typeId === "DE" &&
      nextProject.typeId !== currentProject?.typeId
    ) {
      const customer = Customer.selectors.selectById(
        getState(),
        nextProject?.customers?.[0]?.customerId,
      );
      if (
        customer?.warrantyExpirationDate &&
        _date.fromStamp(customer?.warrantyExpirationDate) < moment()
      )
        toastMessage(
          {
            title: "Project updated to Defect",
            message: `${customer.name} Warranty Expired\n${_date.display(
              customer?.warrantyExpirationDate,
            )}`,
          },
          { type: "warning", autoClose: 15000 },
        );
    }

    if (
      updateCrPrompt &&
      nextProject.crs.length === 1 &&
      currentProject?.crs?.length
    ) {
      const crId = nextProject.crs[0].id;
      const compareAndCrPrompt = (message, properties) => {
        const compareProperties = (current) => {
          let changes = {};
          properties.forEach((property) => {
            if (nextProject[property] !== current?.[property]) {
              changes[property] = nextProject[property];
            }
          });
          if (_.isEmpty(changes)) return null;
          else return changes;
        };
        const crChanges = compareProperties(currentProject);
        if (crChanges)
          updateCrPrompt(
            {
              id: crId,
              message: message,
              dispatch,
              test: (cr) => compareProperties(cr),
              changes: {
                noemail: true,
                ...crChanges,
              },
              getProjectAction: getProjectAction,
            },
            { toastId: `updateProjectAPI-${id}-${properties.join("-")}` },
          );
      };
      compareAndCrPrompt("Update CR owner & group", ["owner", "groupId"]);
      compareAndCrPrompt("Update CR synopsis", ["synopsis"]);

      const compareTypes = (typeId) => typeId && nextProject.typeId !== typeId;
      if (compareTypes(currentProject?.typeId))
        updateCrPrompt(
          {
            id: crId,
            message: "Update CR type",
            dispatch,
            test: (cr) => compareTypes(cr?.overrideTypeId),
            changes: {
              noemail: true,
              overrideTypeId: nextProject.typeId,
            },
            getProjectAction: getProjectAction,
          },
          { toastId: `updateProjectAPI-${id}-typeId` },
        );
    }
    return nextProject;
  }

  const { createDateTime, modifyDateTime } = currentProject;
  //payload will represent the new settings, check if they are the different
  let changes = getChangesExcludingStamps(projectFields, currentProject);
  if (_.isEmpty(changes)) {
    toast.info(`Project ${id} No changes needed`);
    return { data: currentProject };
  }
  // Need to include full customers array if included
  if (changes?.customers)
    changes = { ...changes, customers: projectFields.customers };
  const payload = {
    command: "updateProject",
    id,
    project: { ...changes, createDateTime, modifyDateTime },
  };
  return shark.post(payload).then(({ responseData }) => ({
    data: postProcess(responseData),
  }));
}

function createProjectCommentAPI({ project, title, text }) {
  const payload = {
    command: "createProjectComment",
    project,
    title,
    text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function updateProjectCommentAPI({ project, title, text, id }) {
  const payload = {
    command: "updateProjectComment",
    project,
    id,
    title,
    text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createProjectAttachmentAPI(args) {
  return createAttachmentAPI({ command: "createProjectAttachment", ...args });
}

function updateProjectWatchAPI({ id, isWatching }) {
  const payload = {
    command: "updateProjectWatch",
    id,
    isWatching,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

export const api = {
  getOne: getProjectAPI,
  getMany: getProjectsAPI,
  createOne: createProjectAPI,
  updateOne: updateProjectAPI,
  createComment: createProjectCommentAPI,
  updateComment: updateProjectCommentAPI,
  createAttachment: createProjectAttachmentAPI,
  updateWatch: updateProjectWatchAPI,
};
