import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getExpenseCategories" }).then((response) => {
      return { data: response.responseData.expenseCategories };
    });
  },

  getOne: ({ id }) => {
    return shark
      .post({ command: "getExpenseCategory", id: id })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "categories",
  type: "expenses/categories",
  getState: (state) => state.expenses.categories,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const categoryState = {
  api,
  entity,
  actions,
  reducer,
};

export default categoryState;
