import { usePseudo, usePseudos } from "./hooks";
import PseudoEditor from "./PseudoEditor";
import PseudoLink from "./PseudoLink";
import Table from "./PseudoTable";
import state from "./state";

const Pseudo = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: usePseudo,
  useAll: usePseudos,
  // Components
  Table,
  Link: PseudoLink,
  Editor: PseudoEditor,
  // Related Entity Objects
};

export default Pseudo;
