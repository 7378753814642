import { useRef, useState } from "react";
import isEqual from "react-fast-compare";

export default function useMemoCompare(memoFn, deps) {
  const ref = useRef({});
  const [render, setRender] = useState(0);
  if (!isEqual(ref.current.deps, deps)) {
    ref.current = { value: memoFn(), deps };
    // Force Re-Render on ref change
    setRender(render + 1);
  }
  return ref.current.value;
}
