import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { buildAsyncThunkReducer } from "../../../common/functions/state";
import { api } from "./api";

const entity = createEntity({
  name: "thirdPartyLicense",
  type: "customers/thirdPartyLicenses",
  getState: (state) => state.customers.thirdPartyLicenses,
});

const createComment = createAsyncThunk(
  "customers/thirdPartyLicenses/comments/create",
  async (args) => {
    const response = await api.createComment(args);
    return response;
  },
);

const updateComment = createAsyncThunk(
  "customers/thirdPartyLicenses/comments/update",
  async (args) => {
    const response = await api.updateComment(args);
    return response;
  },
);

const actions = {
  createComment,
  updateComment,
  ...createEntityActions({ entity, api }),
};
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(
      builder,
      actions.createComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
  },
});

const thirdPartyLicensesState = {
  api,
  entity,
  actions,
  reducer,
};

export default thirdPartyLicensesState;
