import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getProjectPriorities" }).then((r) => {
      return { data: r.responseData.projectPriorities };
    });
  },
};

const entity = createEntity({
  name: "priority",
  type: "projects/priorities",
  getState: (state) => state.projects.priorities,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const priorityState = {
  api,
  entity,
  actions,
  reducer,
};

export default priorityState;
