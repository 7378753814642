import React from "react";

import Page from "../../common/pages/Page";
import User from "../../users";
import UserNavSidebar from "./UserNavSidebar";

export default function UserIndex() {
  const { users } = User.useAll();
  return (
    <div>
      <Page title="Users" navSidebar={<UserNavSidebar />}>
        <User.Table users={users.filter((e) => e.inactive === false)} />
      </Page>
    </div>
  );
}
