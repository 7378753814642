import { unwrapResult } from "@reduxjs/toolkit";

import toastPrompt from "../common/toasts/toastPrompt";
import projectState from "./state";

export default function updateProjectPrompt(
  { id, message, changes, dispatch, test = () => true },
  toastOptions,
) {
  dispatch(projectState.actions.getOne({ id }))
    .then(unwrapResult)
    .then(({ data: project }) => {
      if (test(project))
        toastPrompt(
          {
            message,
            confirm: "Yes",
            cancel: "No",
            onConfirm: () => {
              dispatch(
                projectState.actions.updateOne({
                  id,
                  data: changes,
                  shouldPromptToUpdateCr: false,
                }),
              );
            },
          },
          toastOptions,
        );
    });
}
