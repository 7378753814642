import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import Page from "../../common/pages/Page";
import User from "../../users";
import UserNavSidebar from "./UserNavSidebar";

export default function UserShow() {
  const id = String(useParams().id).toUpperCase();
  const {
    user,
    loading,
    updateOne,
    createServerLogin,
    deleteServerLogin,
    updateServerLoginKeys,
    updateServerLoginPassword,
  } = User.useOne({
    id,
    canGet: true,
  });
  const title = `User ${user?.id}`;

  const [generatedPassword, setGeneratedPassword] = useState("");
  return (
    <Page
      title={title}
      lead={loading ? "Loading..." : !user ? "User not found" : user?.name}
      navSidebar={<UserNavSidebar />}
    >
      {user ? (
        <Row>
          <Col>
            <Row>
              <Col>
                <User.Editor
                  values={user}
                  onSubmit={(server) => updateOne(server)}
                  isDisabled={loading}
                />
              </Col>
            </Row>
            <Row className="pt-4">
              <Col>
                <h4>Server Login</h4>
                <User.ServerLoginEditor
                  values={user}
                  onSubmit={(data, e, submitType) => {
                    setGeneratedPassword("");
                    switch (submitType) {
                      case "create":
                        return createServerLogin(data).then(
                          ({ serverLoginPassword }) =>
                            setGeneratedPassword(serverLoginPassword),
                        );
                      case "key":
                        return updateServerLoginKeys(data);
                      case "password":
                        return updateServerLoginPassword(data).then(
                          ({ serverLoginPassword }) => {
                            setGeneratedPassword(serverLoginPassword);
                          },
                        );
                      case "delete":
                        return deleteServerLogin(data);
                      default:
                        break;
                    }
                  }}
                  serverLoginPassword={generatedPassword}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Page>
  );
}
