import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import CustomerNavSidebar from "../CustomerNavSidebar";

export default function HostingAgreementNavSidebar({ children, customerId }) {
  return (
    <CustomerNavSidebar>
      <NavSidebarItem to={`/customers/hosting-agreements`}>
        Hosting Agreements
      </NavSidebarItem>
      <NavSidebarItem
        to={`/customers/hosting-agreements/new${
          customerId ? `?customerId=${customerId}` : ""
        }`}
      >
        New Agreement
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/hosting-agreements/month`}>
        Monthly Run
      </NavSidebarItem>
      <div className="dropdown-divider"></div>
      {children}
    </CustomerNavSidebar>
  );
}
