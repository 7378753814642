import { useHostingAgreement, useHostingAgreements } from "./hooks";
import HostingAgreementEditor from "./HostingAgreementEditor";
import HostingAgreementLink from "./HostingAgreementLink";
import HostingAgreementTable from "./HostingAgreementTable";
import state from "./state";

const HostingAgreement = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useHostingAgreement,
  useAll: useHostingAgreements,
  // Components
  Editor: HostingAgreementEditor,
  Table: HostingAgreementTable,
  Link: HostingAgreementLink,
};
export default HostingAgreement;
