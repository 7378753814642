import shark from "../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityHooks,
  createEntityReducer,
  entityDescription,
  entitySelect,
  entityTableFilter,
} from "../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getBillableOverrideReasons" }).then((r) => {
      return { data: r.responseData.billableOverrideReasons };
    });
  },
};

const entity = createEntity({
  name: "billableOverrideReason",
  type: "times/billableOverrideReasons",
  getState: (state) => state.times.billableOverrideReasons,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

export const billableOverrideReasonState = {
  api,
  entity,
  actions,
  reducer,
};

const {
  useEntities: useBillableOverrideReasons,
  useEntity: useBillableOverrideReason,
} = createEntityHooks({
  entity: entity,
  actions: actions,
});

const TimeBillableOverrideReasonSelect = entitySelect({
  name: "timeBillableOverrideReason",
  useEntities: useBillableOverrideReasons,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeBillableOverrideReasonTableFilter = entityTableFilter({
  name: "timeBillableOverrideReason",
  useEntities: useBillableOverrideReasons,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeBillableOverrideReasonDescription = entityDescription({
  name: "timeBillableOverrideReason",
  useEntity: useBillableOverrideReason,
});

const TimeBillableOverrideReason = {
  // Backend API
  api: billableOverrideReasonState.api,
  // State
  actions: billableOverrideReasonState.actions,
  selectors: billableOverrideReasonState.entity.selectors,
  // Hooks
  useAll: useBillableOverrideReasons,
  useOne: useBillableOverrideReason,
  // Components
  Select: TimeBillableOverrideReasonSelect,
  Description: TimeBillableOverrideReasonDescription,
  TableFilter: TimeBillableOverrideReasonTableFilter,
};
export default TimeBillableOverrideReason;
