import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ContractProductDefaultCrLink({
  id,
  children,
  onClick,
}) {
  if (!id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      {onClick ? (
        <Button variant="link" onClick={() => onClick(id)}>
          {children || id}
        </Button>
      ) : (
        <Link
          className="text-reset"
          to={{ pathname: `/contracts/contract-product-default-crs/${id}` }}
        >
          {children || id}
        </Link>
      )}
    </span>
  );
}
