import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "taxProductCategories",
  type: "invoices/taxProductCategories",
  getState: (state) => state.invoices.taxProductCategories,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const taxProductCategoryState = {
  api,
  actions,
  reducer,
  entity,
};

export default taxProductCategoryState;
