import React from "react";

import { SelectColumnFilterWithOptions } from "../common/tables/TableFilters";
import { useCustomers } from "./hooks";

export default function CustomerTableFilter({ column }) {
  const { filterValue } = column;
  const { customers } = useCustomers();
  const _options = customers
    .filter((s) => s.inactive === false || s.id === filterValue)
    .map((f) => {
      return { label: f.name, value: f.id };
    });
  return <SelectColumnFilterWithOptions column={column} options={_options} />;
}
