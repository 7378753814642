import moment from "moment";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Time from "../../time";
import TimeNavSidebar from "./TimeNavSidebar";

export default function TimeInternal() {
  const loggedInUser = useSelector((state) => state.auth.user);

  const [startDate, setStartDate] = useState(moment());
  const dateRange = useMemo(() => {
    const year = moment().year(startDate.year());
    return {
      startDate: moment(year).startOf("year"),
      endDate: moment(year).endOf("year"),
    };
  }, [startDate]);

  const { times, loading, getTimes } = Time.useAll({
    filter: {
      userId: loggedInUser,
      timeType: "Internal",
      ...dateRange,
    },
  });
  return (
    <Page
      title="Internal Time"
      lead={`${_date.display(dateRange.startDate)} - ${_date.display(
        dateRange.endDate,
      )}`}
      navSidebar={<TimeNavSidebar />}
    >
      <Row>
        <Col>
          <ReactDatePicker
            className="form-control"
            selected={startDate.toDate()}
            onChange={(date) => setStartDate(moment(date))}
            showYearPicker
            dateFormat="yyyy"
          />
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Time.Table
        times={times}
        layout={["date", "cr", "billingNote", "totalHours", "nonTimerHours"]}
        isLoading={loading}
        onRefresh={getTimes}
      />
    </Page>
  );
}
