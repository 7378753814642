import _ from "lodash";
import React from "react";

import FieldSelect from "../../common/fields/FieldSelect";
import { useDefectOccurrences } from "./hooks";

const DefectOccurrenceSelect = React.forwardRef(
  (
    {
      value,
      isMulti = false,
      placeholder = "Select Cr Defect Occurrence...",
      ...props
    },
    ref,
  ) => {
    const { crDefectOccurrences } = useDefectOccurrences();

    const _options = crDefectOccurrences.map((f) => {
      return { label: f.description, value: f.id };
    });

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = _options.filter((o) => value.includes(o.value));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    return (
      <FieldSelect
        ref={ref}
        value={valueOption}
        options={_options}
        isMulti={isMulti}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

DefectOccurrenceSelect.displayName = "DefectOccurrenceSelect";
export default DefectOccurrenceSelect;
