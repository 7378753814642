import _ from "lodash";
import React, { useMemo } from "react";
import { createFilter } from "react-select";

import FieldSelect from "../../common/fields/FieldSelect";
import { useModules } from "./hooks";

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: ({ data }) => {
    return `${data.label} ${data.groupDescription}`;
  },
  trim: true,
});

const ModuleSelect = React.forwardRef(
  (
    { value, isMulti = false, placeholder = "Select CR Module...", ...props },
    ref,
  ) => {
    const { modules, loading } = useModules();

    let moduleGroups = {};
    const _options = modules
      .filter((s) => s.inactive === false || s.id === value)
      .map((m) => {
        if (!moduleGroups[m.moduleGroupId])
          moduleGroups[m.moduleGroupId] = {
            id: m.moduleGroupId,
            label: m.moduleGroupDescription,
          };
        return {
          label: m.description,
          value: m.id,
          groupId: m.moduleGroupId,
          groupDescription: m.moduleGroupDescription,
        };
      });

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = _options.filter((o) => value.includes(o.value));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    const groupedOptions = useMemo(
      () =>
        _.sortBy(
          Object.entries(_.groupBy(_.sortBy(_options, "label"), "groupId")).map(
            ([key, value]) => {
              return { label: moduleGroups[key].label, options: value };
            },
          ),
          "label",
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [modules],
    );

    return (
      <FieldSelect
        // components={selectComponents}
        ref={ref}
        isLoading={loading}
        value={valueOption}
        options={groupedOptions}
        isMulti={isMulti}
        placeholder={placeholder}
        filterOption={filterOption}
        {...props}
      />
    );
  },
);
ModuleSelect.displayName = "ModuleSelect";
export default ModuleSelect;
