// Pass in the revision type variable and revision and
// get back the url to call.
export default function helixUrl(revType, revision) {
  let url = "https://helixteamhub.cloud/producepro/projects/";

  if (revType === "A") url = url + "online_solutions/repositories/ppro-apps";
  else if (revType === "V") url = url + "wms/repositories/ppro-voiceartisan";
  else if (revType === "I") url = url + "ppro/repositories/images";
  else url = url + "ppro/repositories/produce";
  return url + "/changesets/" + revision + "?ignore_whitespace=true";
}
