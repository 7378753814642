import { unwrapResult } from "@reduxjs/toolkit";
import _, { isFunction, isUndefined } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import app from "../../app/index";
import useMemoCompare from "../hooks/useMemoCompare";

/**
 * Entity Redux actions
 * @typedef {Object} Actions
 * @property {function} reset Redux action to clear entities from state
 * @property {function} getMany Redux action to fetch entities
 * @property {function} getOne Redux action to fetch an entity by ID
 * @property {function} createOne Redux action to create an entity
 * @property {function} updateOne Redux action to update an entity by ID
 * @property {function} deleteOne Redux action to delete an entity by ID
 */

/**
 * Options to define how long before the state entities expire
 * @typedef {Object} Expiration
 * @property {string} key Time type key (days, weeks, months, years)
 * @property {number} value Number of periods
 */

/**
 * @typedef {Object} Opts
 * @property {string} name Entity name
 * @property {Object} selectors Entity selectors
 * @property {Actions} actions Entity actions
 * @property {function} getState Callback that returns scope of state from state
 * @property {Expiration} expire Expiration options
 * @property {function} getToastMessage Function to determine toast message after create,update,delete
 */

/**
 * Create React custom hooks based on entity
 * @param {Opts} opts Options
 */
export function createEntityHooks({
  entity: { name, selectors, getState },
  actions: {
    reset: resetAction,
    getMany: getManyAction,
    getOne: getAction,
    createOne: createAction,
    updateOne: updateAction,
    deleteOne: deleteAction,
  } = {},
  expire = { key: "days", value: 7 },
  parseId = (id) => id,
  getToastMessage = ({ name, entity: { id }, type }) =>
    `${_.startCase(name)} ${id} ${type}`,
}) {
  if (!isFunction(getState)) {
    throw new Error(`getState is required`);
  }

  const hasGetMany = _.isFunction(getManyAction);
  const useEntities = ({ canGet = false } = {}) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const entities = useSelector((state) => selectors.selectAll(state));
    const loading = useSelector((state) => getState(state).requests.loading);
    const [error, setError] = useState(null);
    const lastUpdate = useSelector((state) => getState(state).lastUpdate);
    const canExpire = app.useCanStateExpire();

    const getCallback = useCallback(
      (args) => dispatch(getManyAction(args)).then(unwrapResult),
      [dispatch],
    );

    const shouldGet =
      hasGetMany &&
      !loaded &&
      (canGet === true || (canGet !== false && _.isEmpty(entities))) &&
      (lastUpdate === null ||
        (moment().diff(lastUpdate, expire.key) > expire.value && canExpire)) &&
      error === null;

    useEffect(() => {
      if (shouldGet) {
        getCallback()
          .catch(setError)
          .finally(() => setLoaded(false));
      }
    }, [dispatch, shouldGet, getCallback]);

    const resetCallback = useCallback(() => {
      dispatch(resetAction());
      setError(null);
    }, [dispatch]);

    const _getMany = hasGetMany ? getCallback : undefined;

    return {
      [_.camelCase(pluralize(name))]: entities,
      [_.camelCase("get-" + pluralize(name))]: _getMany,
      getMany: _getMany,
      loading: hasGetMany && (loading || shouldGet),
      lastUpdate,
      reset: _.isFunction(resetAction) ? resetCallback : undefined,
      entities,
      error,
    };
  };

  const useEntitiesBySearch = ({ canGet = false, search: searchArgs } = {}) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const search = useMemoCompare(() => searchArgs, [searchArgs]);

    const [entities, lastUpdate] = useSelector((state) =>
      selectors.selectBySearch(state, search),
    );
    const loading = useSelector((state) => getState(state).requests.loading);
    const [error, setError] = useState(null);
    const canExpire = app.useCanStateExpire();

    if (isUndefined(search) && canGet !== false)
      console.warn(`${name} search is undefined`);

    const getCallback = useCallback(
      () => dispatch(getManyAction(search)).then(unwrapResult),
      [dispatch, search],
    );

    const shouldGet =
      hasGetMany &&
      !loaded &&
      (canGet === true || (canGet !== false && _.isEmpty(entities))) &&
      (lastUpdate === null ||
        (moment().diff(lastUpdate, expire.key) > expire.value && canExpire)) &&
      error === null &&
      !!search;

    useEffect(() => {
      if (shouldGet) {
        getCallback()
          .catch(setError)
          .finally(() => setLoaded(false));
      }
    }, [dispatch, shouldGet, getCallback]);

    const resetCallback = useCallback(() => {
      dispatch(resetAction());
      setError(null);
    }, [dispatch]);

    const _getMany = _.isFunction(getManyAction) ? getCallback : undefined;

    return {
      [_.camelCase(pluralize(name))]: entities,
      [_.camelCase("get-" + pluralize(name))]: _getMany,
      getMany: _getMany,
      loading: hasGetMany && (loading || shouldGet),
      lastUpdate,
      reset: _.isFunction(resetAction) ? resetCallback : undefined,
      entities,
      error,
    };
  };

  const hasGet = _.isFunction(getAction);

  const useGetOne = ({ id: rawId } = {}) => {
    const id = parseId(rawId);
    const dispatch = useDispatch();
    const loading = useSelector((state) => getState(state).requests.loading);

    const getCallback = useCallback(
      (args) => dispatch(getAction({ id, ...args })).then(unwrapResult),
      [dispatch, id],
    );

    const _get = hasGet ? getCallback : undefined;

    return {
      loading,
      [_.camelCase("get-" + pluralize(name, 1))]: _get,
      getOne: _get,
    };
  };

  const useEntity = ({ id: rawId, canGet = false } = {}) => {
    const id = parseId(rawId);
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const entity = useSelector((state) => selectors.selectById(state, id));
    const [error, setError] = useState(null);

    const { loading, getOne: getCallback } = useGetOne({ id: rawId });

    const createCallback = useCallback(
      (entity) =>
        dispatch(createAction(entity))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success(
              getToastMessage({ name, entity: result, type: "Created" }),
            );
            return result;
          }),
      [dispatch],
    );
    const updateCallback = useCallback(
      ({ ...entity }) =>
        dispatch(updateAction({ ...entity, id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success(
              getToastMessage({ name, entity: result, type: "Updated" }),
            );
            return result;
          }),
      [dispatch, id],
    );

    const deleteCallback = useCallback(
      () =>
        dispatch(deleteAction({ id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success(
              getToastMessage({ name, entity: { id }, type: "Deleted" }),
            );
            return result;
          }),
      [dispatch, id],
    );

    const shouldGet =
      !!id &&
      hasGet &&
      !loaded &&
      (canGet === true || (canGet !== false && _.isUndefined(entity))) &&
      error === null;

    useEffect(() => {
      if (shouldGet) {
        getCallback()
          .catch(setError)
          .finally(() => setLoaded(true));
      }
    }, [dispatch, getCallback, shouldGet]);

    const reset = useCallback(() => {
      setError(null);
      setLoaded(false);
    }, []);

    const _get = hasGet ? getCallback : undefined;

    return {
      [_.camelCase(name)]: entity,
      loading: hasGet && (loading || shouldGet),
      entity,
      [_.camelCase("get-" + pluralize(name, 1))]: _get,
      getOne: _get,
      createOne: _.isFunction(createAction) ? createCallback : undefined,
      updateOne: _.isFunction(updateAction) ? updateCallback : undefined,
      deleteOne: _.isFunction(deleteAction) ? deleteCallback : undefined,
      reset,
    };
  };

  useEntity.getOne = useGetOne;

  const useEntityByFind = ({ find: findArgs }) => {
    const find = useMemoCompare(() => findArgs, [findArgs]);
    const { getOne } = useGetOne();
    const [id, setId] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
      setId(null);
      getOne({ find: find })
        .catch(setError)
        .then(({ data: { id } }) => {
          setId(id);
        });
    }, [find, getOne]);

    const {
      error: entityError,
      reset: resetEntity,
      ...entity
    } = useEntity({
      id: id,
    });

    const reset = useCallback(() => {
      setError(null);
      setId(null);
      resetEntity();
    }, [resetEntity]);
    return { ...entity, error: error || entityError, reset };
  };

  return {
    useEntities,
    useEntitiesBySearch,
    useEntity,
    useEntityByFind,
  };
}

export default createEntityHooks;
