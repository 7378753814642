import React from "react";

import { useQaStatus } from "./hooks";

export default function CrQaStatusDescription({ id }) {
  const { qaStatus } = useQaStatus({ id });
  if (!qaStatus) return null;

  return <>{qaStatus.description}</>;
}
