import React from "react";
import { Link } from "react-router-dom";

export default function TaxProductCategoryLink({ id, children }) {
  return (
    <Link
      className="text-reset"
      to={{ pathname: `/invoices/tax-product-categories/${id}` }}
    >
      {children || id}
    </Link>
  );
}
