import { createEntityHooks } from "../common/entity";
import parseIntId from "../common/functions/parseIntId";
import contractState from "./state";

export const {
  useEntities: useContracts,
  useEntity: useContract,
  useEntitiesBySearch: useContractsSearch,
} = createEntityHooks({
  entity: contractState.entity,
  actions: contractState.actions,
  parseId: (id) => {
    const nextId = parseIntId(id);
    return nextId === 0 ? undefined : nextId;
  },
  expire: {
    key: "hours",
    value: 12,
  },
});
