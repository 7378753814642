import React from "react";

import StampDisplay from "../../../common/components/StampDisplay";
import Contract from "../../../contracts";

export default function ContractProductDefaultCrEdit({
  loading,
  onSubmit,
  contractProductDefaultCr,
}) {
  return (
    <>
      {contractProductDefaultCr ? (
        <>
          <Contract.ContractProductDefaultCr.Editor
            isNew={false}
            values={contractProductDefaultCr}
            isLoading={loading}
            onSubmit={onSubmit}
          />

          <StampDisplay record={contractProductDefaultCr} />
        </>
      ) : (
        <h4>Contract Product Default Cr not found.</h4>
      )}
    </>
  );
}
