import shark from "../../apis/shark";
import { _date, _datetime } from "../../common/functions/dates";

export function getTimesAPI(filter) {
  const payload = {
    ...filter,
    command: "getTimes",
  };
  return shark.post(payload);
}

export function getTimeAPI({ id }) {
  const payload = {
    id: id,
    command: "getTime",
  };
  return shark.post(payload);
}

export function createTimeAPI(payload) {
  const payload2 = {
    command: "createTime",
    ...payload,
  };
  return shark.post(payload2);
}

export function updateTimeAPI(payload) {
  const payload2 = {
    command: "updateTime",
    ...payload,
  };
  return shark.post(payload2);
}

export function deleteTimeAPI(payload) {
  const payload2 = {
    command: "deleteTime",
    ...payload,
  };
  return shark.post(payload2);
}

export function moveTimeAPI(payload) {
  const payload2 = {
    command: "moveTime",
    ...payload,
  };
  return shark.post(payload2);
}

export function restartTimerAPI({ timeId }) {
  return shark.post({
    command: "restartTimer",
    timeId: timeId,
    datetime: _datetime.stamp(),
  });
}

export function getTimeStatsAPI({ startDate, endDate, userId }) {
  return shark.post({
    command: "getTimeStats",
    startDate: _date.stamp(startDate),
    endDate: _date.stamp(endDate),
    userId: userId,
  });
}
