import React from "react";
import { Col, Row, Table } from "react-bootstrap";

import { _datetime } from "../functions/dates";

function StampDisplay({ record, variant = "secondary" }) {
  return (
    <Row className="mt-2">
      <Col xs="auto">
        <Table hover variant={variant}>
          <thead>
            <tr>
              <th>Stamp</th>
              <th>When</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Created</td>
              <td>
                {_datetime.displayDateTime(
                  _datetime.fromStamp(record?.createDate + record.createTime),
                )}
              </td>
              <td>{record?.createOperator}</td>
            </tr>
            <tr>
              <td>Last Modified</td>
              <td>
                {record?.modifyOperator === ""
                  ? "Never"
                  : _datetime.displayDateTime(
                      _datetime.fromStamp(
                        record?.modifyDate + record.modifyTime,
                      ),
                    )}
              </td>
              <td>{record?.modifyOperator}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default StampDisplay;
