import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { _date } from "../../common/functions/dates";
import useTinykeys from "../../common/hooks/useTinykeys";
import { useQaReview } from "./hooks";
import QaReviewEditorModal from "./QaReviewEditorModal";

export default function QaReviewNewButton({
  label = "Add New QA Review",
  onCreate,
  initialValues,
  disabled = false,
}) {
  const { createOne } = useQaReview();
  const [showAdd, setShowAdd] = useState(false);

  useTinykeys(
    showAdd
      ? {}
      : {
          "Alt+KeyA": () => setShowAdd(true),
        },
  );

  return (
    <>
      <Button
        variant="success"
        onClick={() => setShowAdd(true)}
        disabled={disabled}
      >
        {label}
      </Button>
      <QaReviewEditorModal
        title="Add Qa Review"
        values={initialValues}
        show={showAdd}
        onHide={() => setShowAdd(false)}
        isNew={true}
        onSubmit={(qaReview) => {
          const payloadQaReview = { ...qaReview };
          payloadQaReview.reviewDate = _date.stamp(qaReview.reviewDate);
          createOne(payloadQaReview).then(() => {
            onCreate();
            setShowAdd(false);
          });
        }}
      />
    </>
  );
}
