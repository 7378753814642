import { entitySelect } from "../../common/entity";
import { useExpenseTypes } from "./hooks";

const ExpenseTypeSelect = entitySelect({
  name: "expenseType",
  useEntities: useExpenseTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default ExpenseTypeSelect;
