import storage from "localforage";
import { persistReducer } from "redux-persist";

import { reducer } from "./slices";

const persistConfig = {
  key: "activity/userActivity",
  version: 1,
  storage,
  whitelist: ["ids", "entities", "lastUpdate"],
};

export default persistReducer(persistConfig, reducer);
