import React from "react";
import { useParams } from "react-router";

import UserActivityTable from "../../activity/userActivity/UserActivityTable";
import Cr from "../../crs";
import CrPage from "./CrPage";
import CrShowNavSidebar from "./CrShowNavSidebar";

export default function CrShowLog() {
  const id = parseInt(useParams().id);
  const { cr, loading } = Cr.useOne({
    id,
    logActivity: false,
    includeLogs: true,
    shouldSetCurrent: true,
  });

  return (
    <CrPage
      cr={cr}
      heading={`CR ${id} Log`}
      navSidebar={<CrShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <UserActivityTable
        userActivity={cr?.logs}
        isLoading={loading}
        layout={["dateTime", "userId", "description", "type"]}
        initialFilters={{ id: "type", value: "Field Changed" }}
      />
    </CrPage>
  );
}
