import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { Forms, FormsField, useFormContext } from "../common/forms";
import { _date, _datetime } from "../common/functions/dates";
import crSchema from "./crSchema";

export default function CrTargetDatesTable({
  cr = {},
  isLoading = false,
  update,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const hideShowEdit = () => setShowEdit(false);
  const {
    targetStartWorkDateTime,
    targetFirstContactDateTime,
    targetDeliveryDate,
    actualStartWorkDateTime,
    actualFirstContactDateTime,
    actualDeliveryDate,
  } = cr;

  const setDateTime = (d) => {
    if (!d) return "";
    return _datetime.fromStamp(d).toISOString();
  };
  const setDate = (d) => {
    if (!d) return "";
    return _date.fromStamp(d).toISOString();
  };

  const values = {
    targetStartWorkDateTime: setDateTime(targetStartWorkDateTime),
    targetFirstContactDateTime: setDateTime(targetFirstContactDateTime),
    targetDeliveryDate: setDate(targetDeliveryDate),
    actualStartWorkDateTime: setDateTime(actualStartWorkDateTime),
    actualFirstContactDateTime: setDateTime(actualFirstContactDateTime),
    actualDeliveryDate: setDate(actualDeliveryDate),
  };

  function displayDateTime(d, fallback = "") {
    if (isLoading) return <Skeleton />;
    const dispDate = _datetime.displayDateTime(d);
    return dispDate || fallback;
  }
  function displayDate(d, fallback = "") {
    if (isLoading) return <Skeleton />;
    const dispDate = _datetime.display(d);
    return dispDate || fallback;
  }
  return (
    <>
      <Table size="sm">
        <thead>
          <tr>
            <th colSpan={3}>
              <Row>
                <Col className="text-center">
                  <h4>Target Dates</h4>
                </Col>
                <Col>
                  <Row className="justify-content-end">
                    <Col xs="auto">
                      {!isLoading ? (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => setShowEdit(true)}
                        >
                          Edit
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </th>
          </tr>

          <tr>
            <th></th>
            <th>Target</th>
            <th>Actual</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="text-right">
              First Contact
            </th>
            <td>{displayDateTime(values.targetFirstContactDateTime, "N/A")}</td>
            <td>{displayDateTime(values.actualFirstContactDateTime)}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              Start Work
            </th>
            <td>{displayDateTime(values.targetStartWorkDateTime, "N/A")}</td>
            <td>{displayDateTime(values.actualStartWorkDateTime)}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              Delivery
            </th>
            <td>{displayDate(values.targetDeliveryDate, "N/A")}</td>
            <td>{displayDate(values.actualDeliveryDate)}</td>
          </tr>
        </tbody>
      </Table>
      <Modal size="lg" show={showEdit} onHide={hideShowEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit CR Target Dates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TargetDatesForm
            onHide={hideShowEdit}
            value={values}
            update={update}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function TargetDatesForm({ value, onHide, update }) {
  return (
    <Forms
      defaultValues={value}
      onSubmit={(v) => {
        update(v);
        onHide();
      }}
      schema={{ schema: crSchema }}
    >
      <TargetDateForms onHide={onHide} />
    </Forms>
  );
}

function TargetDateForms({ onHide }) {
  const { submitForm } = useFormContext();
  return (
    <Form onSubmit={submitForm}>
      <Row className="border-bottom">
        <Col xs="6" className="text-right">
          <h5>First Contact</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormsField.Date
            name="targetFirstContactDateTime"
            label="Target"
            includeTime
            includeNow={false}
          />
        </Col>
        <Col>
          <FormsField.Date
            name="actualFirstContactDateTime"
            label="Actual"
            includeTime
            includeNow={false}
          />
        </Col>
      </Row>
      <Row className="pt-3 border-bottom">
        <Col xs="6" className="text-right">
          <h5>Start Work</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormsField.Date
            name="targetStartWorkDateTime"
            label="Target"
            includeTime
            includeNow={false}
          />
        </Col>
        <Col>
          <FormsField.Date
            name="actualStartWorkDateTime"
            label="Actual"
            includeTime
            includeNow={false}
          />
        </Col>
      </Row>
      <Row className="pt-3 border-bottom">
        <Col xs="6" className="text-right">
          <h5>Delivery</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormsField.Date
            name="targetDeliveryDate"
            label="Target"
            includeNow={false}
          />
        </Col>
        <Col>
          <FormsField.Date
            name="actualDeliveryDate"
            label="Actual"
            includeNow={false}
          />
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <ButtonGroup>
            <Button type="submit">Save</Button>
            <Button
              variant="danger"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  );
}
