import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import CustomerLink from "../../customers/CustomerLink";
import CustomerTableFilter from "../../customers/CustomerTableFilter";

export default function FtpUserTable({ ftpUsers, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "FTP User",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: (x) => {
          return (
            <Link
              className="text-decoration-none"
              to={{ pathname: `/edi/ftpUsers/${x?.row?.original?.id}` }}
            >
              {x?.value}
            </Link>
          );
        },
      },
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },
      {
        id: "Username",
        Header: "Username",
        accessor: "username",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "ID",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        dataType: "number",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="FTP Users"
      columns={columns}
      data={ftpUsers}
      initialSort={{ id: "Customer", desc: true }}
      {...props}
    />
  );
}
