import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import ErrorFallback from "../ErrorFallback";
import ApprovalsRouting from "./approvals";
import AuthRouting from "./auth";
import CodeReviewsRouting from "./codeReviews";
import ContractsRouting from "./contracts";
import CrsRouting from "./crs";
import CustomersRouting from "./customers";
import DbcRouting from "./dbc";
import EdiRouting from "./edi";
import ExpensesRouting from "./expenses";
import Home from "./Home";
import InvoicesRouting from "./invoices";
import ProjectsRouting from "./projects";
import ReportsRouting from "./reports";
import ServersRouting from "./servers";
import SettingsRouting from "./settings";
import TimeRouting from "./time";
import UsersRouting from "./users";

export default function Routing() {
  const authError = useSelector((state) => state.auth.error);

  if (authError)
    return (
      <ErrorFallback
        error={{ stack: authError }}
        resetErrorBoundary={() => window.location.reload(false)}
      />
    );
  return <Routes />;
}

function PrivateRoute({ children, ...props }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return (
    <Route
      {...props}
      render={({ location }) => {
        return isLoggedIn === true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              search: `?redirect=${location.pathname}`,
            }}
          />
        );
      }}
    />
  );
}

export function Routes() {
  return (
    <Switch>
      <Route path="/auth">
        <AuthRouting />
      </Route>
      <PrivateRoute path="/home">
        <Home />
      </PrivateRoute>
      <PrivateRoute path="/settings">
        <SettingsRouting />
      </PrivateRoute>
      <PrivateRoute path="/crs">
        <CrsRouting />
      </PrivateRoute>
      <PrivateRoute path="/projects">
        <ProjectsRouting />
      </PrivateRoute>
      <PrivateRoute path="/customers">
        <CustomersRouting />
      </PrivateRoute>
      <PrivateRoute path="/time">
        <TimeRouting />
      </PrivateRoute>
      <PrivateRoute path="/users">
        <UsersRouting />
      </PrivateRoute>
      <PrivateRoute path="/dbc">
        <DbcRouting />
      </PrivateRoute>
      <PrivateRoute path="/edi">
        <EdiRouting />
      </PrivateRoute>
      <PrivateRoute path="/invoices">
        <InvoicesRouting />
      </PrivateRoute>
      <PrivateRoute path="/code-reviews">
        <CodeReviewsRouting />
      </PrivateRoute>
      <PrivateRoute path="/contracts">
        <ContractsRouting />
      </PrivateRoute>
      <PrivateRoute path="/reports">
        <ReportsRouting />
      </PrivateRoute>
      <PrivateRoute path="/approvals">
        <ApprovalsRouting />
      </PrivateRoute>
      <PrivateRoute path="/expenses">
        <ExpensesRouting />
      </PrivateRoute>
      <PrivateRoute path="/servers">
        <ServersRouting />
      </PrivateRoute>
      <PrivateRoute>
        <Redirect to="/home" />
      </PrivateRoute>
    </Switch>
  );
}
