import { entitySelect } from "../../common/entity";
import { useAwsAccounts } from "./hooks";

const AwsAccountSelect = entitySelect({
  name: "awsAccount",
  useEntities: useAwsAccounts,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.description + "(" + e.id.substring(0, 4) + "...)",
});

export default AwsAccountSelect;
