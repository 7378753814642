import { createEntityHooks } from "../../common/entity";
import { jobsActions, jobsSelectors } from "./state";

const getJobsState = (state) => state.activity.jobs;

const { useEntities: useJobs } = createEntityHooks({
  entity: {
    name: "job",
    selectors: jobsSelectors,
    getState: getJobsState,
  },
  actions: { getMany: jobsActions.getJobs, reset: jobsActions.clearJobs },
  expire: {
    key: "minutes",
    value: 30,
  },
});

export default useJobs;
