import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Contract from "../../../contracts";
import ContractProductCategory from "../../../contracts/contractProductCategories";
import ContractProductDefaultCrTable from "../../../contracts/contractProductsDefaultCrs/ContractProductDefaultCrTable";
import ContractNavSidebar from "../ContractNavSidebar";
import ContractProductDefaultCrEditModal from "../contractProductDefaultCrs/ContractProductDefaultCrEditModal";
import ContractProductDefaultCrNewModal from "../contractProductDefaultCrs/ContractProductDefaultCrNewModal";

export default function ContractProductEdit() {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [modifyModalId, setModifyModalId] = useState();

  const id = String(useParams().id).toUpperCase();
  const { contractProduct, loading, updateOne, reset } =
    Contract.ContractProduct.useOne({
      id,
      canGet: true,
    });

  ContractProductCategory.useAll({
    canGet: true,
  });

  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Page
        title="Contract Product"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  return (
    <Page title="Contract Product" navSidebar={<ContractNavSidebar />}>
      {contractProduct ? (
        <>
          <Contract.ContractProduct.Editor
            isNew={false}
            values={contractProduct}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <Accordion defaultActiveKey="0">
            <Card className={"mt-3"}>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className={"text-left"}
              >
                Default Crs
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ContractProductDefaultCrTable
                    data={contractProduct.defaultCrs}
                    loading={loading}
                    onClick={(defaultCrId) => {
                      setShowModifyModal(true);
                      setModifyModalId(defaultCrId);
                    }}
                  />
                  <Button
                    className={"mt-2"}
                    variant="success"
                    onClick={() => setShowNewModal(true)}
                  >
                    Add Default Cr
                  </Button>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <StampDisplay record={contractProduct} />

          {showModifyModal ? (
            <ContractProductDefaultCrEditModal
              id={modifyModalId}
              onHide={() => setShowModifyModal(false)}
              onSave={() => {
                setShowModifyModal(false);
                reset();
              }}
              show={true}
            />
          ) : null}

          {showNewModal ? (
            <ContractProductDefaultCrNewModal
              onHide={() => setShowNewModal(false)}
              onSave={() => {
                setShowNewModal(false);
                reset();
              }}
              show={true}
              contractProductId={id}
            />
          ) : null}
        </>
      ) : (
        <h4>Contract Product not found.</h4>
      )}
    </Page>
  );
}
