import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";

import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import Contract from "../../contracts";
import Customer from "../../customers";
import ContractNavSidebar from "./ContractNavSidebar";

const TABLE_COMPONENTS = {
  Customer: ({ value }) => <Customer.Link id={value}>{value}</Customer.Link>,
};

const projectOpenOptions = [];
projectOpenOptions.push({ label: "\u00A0", value: "" });
projectOpenOptions.push({ label: "Projects are open", value: "O" });
projectOpenOptions.push({ label: "All projects are closed", value: "C" });

const contractStatusOptions = [];
contractStatusOptions.push({ label: "\u00A0", value: "" });
contractStatusOptions.push({ label: "Active", value: "N" });
contractStatusOptions.push({ label: "Inactive", value: "Y" });

export default function ContractIndex() {
  const [activeTab, setActiveTab] = useState("contracts");
  const [loading, setLoading] = useState(false);
  const customerIdParam = parseInt(useSearchParams("customer"));
  const contractProductIdParam = useSearchParams("contractProduct");
  const projectsOpenParam = useSearchParams("projectsOpen");
  const statusParam = useSearchParams("status");
  const [searchCtr, setSearchCtr] = useState(0);
  const [contracts, setContracts] = useState([]);

  const [projectsOpenSearch, setProjectsOpenSearch] = useState(
    _.isUndefined(projectsOpenParam) ? "" : projectsOpenParam,
  );
  const [contractProductIdSearch, setContractProductIdSearch] = useState(
    contractProductIdParam,
  );
  const [customerIdSearch, setCustomerIdSearch] = useState(customerIdParam);
  const [statusSearch, setStatusSearch] = useState(
    _.isUndefined(statusParam) ? "N" : statusParam,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  Contract.ContractProduct.useAll({
    canGet: true,
  });
  Contract.ContractProductCategory.useAll({
    canGet: true,
  });

  let title = "Contract Search";

  const searchObject = useMemo(() => {
    const out = {};
    if (!_.isNaN(customerIdParam)) out.customerId = customerIdParam;
    if (!_.isEmpty(contractProductIdParam))
      out.contractProductId = contractProductIdParam;
    if (!_.isEmpty(projectsOpenParam)) out.projectsOpen = projectsOpenParam;
    if (!_.isEmpty(statusParam)) out.isInactive = statusParam;
    return out;
  }, [contractProductIdParam, customerIdParam, projectsOpenParam, statusParam]);

  //  const { contracts, reset, getMany } = Contract.useAll({
  //    canGet: "false",
  //  });

  const runSearch = useCallback(() => {
    setLoading(true);
    dispatch(Contract.actions.getMany(searchObject))
      .then(unwrapResult)
      .then((result) => {
        setContracts(result.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .catch(setLoading(false));
  }, [dispatch, searchObject]);

  useEffect(() => {
    runSearch();
  }, [runSearch, searchCtr]);

  const clickedSearch = () => {
    let searchString = "/contracts?projectsOpen=" + projectsOpenSearch;
    if (!_.isUndefined(customerIdSearch) && customerIdSearch !== "")
      searchString = searchString + "&customer=" + customerIdSearch;
    if (
      !_.isUndefined(contractProductIdSearch) &&
      contractProductIdSearch !== ""
    )
      searchString =
        searchString + "&contractProduct=" + contractProductIdSearch;
    if (!_.isEmpty(statusSearch) && statusSearch !== "")
      searchString = searchString + "&status=" + statusSearch;

    setActiveTab("contracts");
    setSearchCtr(searchCtr + 1);
    history.push(searchString);
  };

  return (
    <Page title={title} navSidebar={<ContractNavSidebar />}>
      <Tabs
        id="contract-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="contracts" title="Contracts">
          <Contract.Table
            contracts={contracts}
            isLoading={loading}
            Components={TABLE_COMPONENTS}
            onRefresh={() => {
              setSearchCtr(searchCtr + 1);
            }}
          />
        </Tab>
        <Tab eventKey="search" title="Search">
          <Row>
            <Col sm={4}>
              <Form.Label>Contract Product</Form.Label>
              <Contract.ContractProduct.Select
                id="contractProductId"
                value={contractProductIdSearch}
                onChange={(value) => setContractProductIdSearch(value)}
                isClearable
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Label>Customer</Form.Label>
              <Customer.Select
                id="customerIdSearch"
                value={customerIdSearch}
                onChange={(value) => setCustomerIdSearch(value)}
                isClearable
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Label>Contract status</Form.Label>
              <Select
                name="status"
                label="Contract Status"
                as="select"
                value={contractStatusOptions.find(
                  (x) => x.value === statusSearch,
                )}
                options={contractStatusOptions}
                onChange={(x) => setStatusSearch(x.value)}
              ></Select>
            </Col>
            <Col sm={4}>
              <Form.Label>Project statuses</Form.Label>
              <Select
                name="projectOpen"
                label="Project still open?"
                as="select"
                value={projectOpenOptions.find(
                  (x) => x.value === projectsOpenSearch,
                )}
                options={projectOpenOptions}
                onChange={(x) => setProjectsOpenSearch(x.value)}
              ></Select>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <Button onClick={clickedSearch}>Search</Button>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Page>
  );
}
