import { useStatus, useStatuses } from "./hooks";
import statusState from "./state";
import StatusDescription from "./StatusDescription";
import StatusSelect from "./StatusSelect";
import StatusTableFilter from "./StatusTableFilter";

const Status = {
  // Backend API
  api: statusState.api,
  // State
  actions: statusState.actions,
  selectors: statusState.entity.selectors,
  // Hooks
  useAll: useStatuses,
  useOne: useStatus,
  // Components
  Select: StatusSelect,
  Description: StatusDescription,
  TableFilter: StatusTableFilter,
};
export default Status;
