import moment from "moment";
import React, { useMemo, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Forms, useController, useFormContext } from "../common/forms";
import queryToString from "../common/functions/queryToString";
import CrTable from "./CrTable";
import CrFilterParameters from "./filters/CrFilterParameters";
import useCrsByFilter from "./useCrsByFilter";

export default function CrSearch({
  defaultCustomerId,
  defaultOwnerId,
  defaultProjectId,
  onSelect,
}) {
  const [defaultFilter, initialSearch] = useMemo(() => {
    let initialSearch = false;
    let defaultFilter = { folderId: "CURRENT" };
    if (defaultCustomerId) {
      defaultFilter.customers = [defaultCustomerId];
      defaultFilter.customersInclude = true;
      initialSearch = true;
    }
    if (defaultOwnerId) {
      defaultFilter.owners = [defaultOwnerId];
      defaultFilter.ownersInclude = true;
      initialSearch = true;
    }
    if (defaultProjectId) {
      defaultFilter.projects = [defaultProjectId];
      initialSearch = true;
    }
    return [defaultFilter, initialSearch];
  }, [defaultCustomerId, defaultOwnerId, defaultProjectId]);

  const [filter, setFilter] = useState(defaultFilter);
  const [search, setSearch] = useState(initialSearch);

  const { crs, loading, hasMore, getMore, reset, lastUpdate } = useCrsByFilter({
    filter,
    blocked: !search,
  });

  const user = useSelector((state) => state.auth.user);

  // we want to only send records once
  // the refresh functionality doesn't keep in line the default
  // sort that I want to do here
  const tableData = useMemo(() => {
    return crs?.sort((a, b) => {
      const aval = a.owner === user ? " " : a.owner;
      const bval = b.owner === user ? " " : b.owner;
      if (aval > bval) return 1;
      if (aval < bval) return -1;
      return 0;
    });
  }, [crs, user]);

  return (
    <>
      <Row>
        <Col>
          <Collapse in={!search}>
            <div>
              <FilterEditor
                filter={filter}
                isLoading={loading}
                defaults={defaultFilter}
                onSubmit={(f) => {
                  setFilter(f);
                  setSearch(true);
                }}
              />
            </div>
          </Collapse>
          <Collapse in={search}>
            <div>
              <Row className="justify-content-between">
                <Col xs="auto">
                  <p className="lead">{queryToString(filter)}</p>
                </Col>
                <Col xs="auto">
                  <Button variant="link" onClick={() => setSearch(false)}>
                    Edit Search
                  </Button>
                </Col>
              </Row>
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse in={search}>
            <div>
              <CrTable
                crs={tableData}
                isLoading={loading}
                hasMore={hasMore}
                getMore={getMore}
                selectRow={true}
                crChosen={(crId) => onSelect(crId)}
                layout={[
                  "project",
                  "id",
                  "modifyDate",
                  "owner",
                  "status",
                  "type",
                  "synopsis",
                ]}
                onRefresh={reset}
                refreshLabel={
                  "Fetched " + moment(lastUpdate).fromNow() + " \u27F3"
                }
              />
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}

function FilterEditor({ onSubmit, filter, isLoading, defaults }) {
  return (
    <Forms
      onSubmit={({ filter }) => onSubmit(filter)}
      defaultValues={{
        filter: {
          ...defaults,
          ...filter,
        },
      }}
    >
      <FilterEditorForm isLoading={isLoading} />
    </Forms>
  );
}

function FilterEditorForm({ isLoading }) {
  const { submitForm } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name: "filter" });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <CrFilterParameters
              values={value}
              onChange={onChange}
              isDisabled={isDisabled}
              isInvalid={!!error}
              errors={error?.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button variant="success" onClick={submitForm} disabled={isLoading}>
              Search
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
