import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import programState from "../programs/state";

const api = {
  getMany: () => {
    return shark.post({ command: "getGroups" }).then((r) => {
      return { data: r.responseData.groups };
    });
  },
};

const entity = createEntity({
  name: "groups",
  type: "users/groups",
  getState: (state) => state.users.groups,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      state.programs = programState.reducer(state.programs, action);
    });
  },
});

const groupState = {
  api,
  entity,
  actions,
  reducer,
};

export default groupState;
