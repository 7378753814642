import React, { useCallback } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import shark from "../../../apis/shark";
import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Edi from "../../../edi";
import FtpUserDeleteLogTable from "../../../edi/ftpUsers/FtpUserDeleteLogTable";
import EdiNavSidebar from "../EdiNavSidebar";

export default function FtpUserEdit() {
  const id = parseInt(useParams().id);
  const { ftpUser, loading, updateOne } = Edi.FtpUser.useOne({
    id: id,
    canGet: true,
  });
  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  const emailPwFtpUser = useCallback(async (id) => {
    const toastId = toast.info(`Emailing password...`, {
      autoClose: false,
      hideProgressBar: false,
      progress: 0.5,
    });

    await shark
      .post({ command: "emailPwFtpUser", id: id })
      .then(() => {
        toast.update(toastId, {
          render: `Email Sent`,
          type: toast.TYPE.SUCCESS,
          progress: undefined,
          hideProgressBar: true,
          autoClose: 5000,
        });
      })
      // eslint-disable-next-line unused-imports/no-unused-vars
      .catch((error) =>
        toast.update(toastId, {
          render: `Email Failed`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        }),
      );
  }, []);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="FTP User"
        navSidebar={<EdiNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="FTP User" navSidebar={<EdiNavSidebar />}>
      {ftpUser ? (
        <>
          <Edi.FtpUser.Editor
            values={ftpUser}
            isLoading={loading}
            isNew={false}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <Button onClick={() => emailPwFtpUser(id)}>Email Password</Button>
          <StampDisplay record={ftpUser} />
          <Alert variant={"warning"}>
            <FtpUserDeleteLogTable
              logs={ftpUser.deleteLogs}
              isLoading={loading}
            />
          </Alert>
        </>
      ) : (
        <h4>FTP User not found.</h4>
      )}
    </Page>
  );
}
