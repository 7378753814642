import React from "react";
import { useParams } from "react-router";

import Comments from "../../../common/details/Comments";
import DetailSection from "../../../common/details/DetailSection";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import { LICENSE_TYPES } from "../../../customers/thirdPartyLicenses/types";
import ThirdPartyLicenseNavSidebar from "./ThirdPartyLicenseNavSidebar";

export default function ThirdPartyLicenseShow() {
  const id = parseInt(useParams().id);
  const { entity, updateOne, loading, createComment, updateComment } =
    Customer.ThirdPartyLicenses.useOne({
      id,
      canGet: true,
    });
  const { customer } = Customer.useOne({ id: entity?.customerId });
  const title = customer
    ? `${customer?.name} - ${LICENSE_TYPES?.[entity?.type]?.description}`
    : `Customer Third Party License${entity?.id ? ": " + entity.id : ""}`;
  return (
    <Page
      title={title}
      lead={
        loading
          ? "Loading..."
          : !entity
          ? "Customer Third Party License not found"
          : ""
      }
      navSidebar={
        <ThirdPartyLicenseNavSidebar customerId={entity?.customerId} />
      }
    >
      {entity ? (
        <Customer.ThirdPartyLicenses.Editor
          values={entity}
          onSubmit={(license) => updateOne(license)}
          isDisabled={loading}
        />
      ) : null}
      <DetailSection title="Comments" key="comments-section">
        <Comments
          id={`project-${id}-comments`}
          comments={entity?.comments}
          isLoading={loading}
          create={createComment}
          update={updateComment}
        />
      </DetailSection>
    </Page>
  );
}
