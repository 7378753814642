import { createAttachmentAPI } from "../../apis/attachments";
import shark from "../../apis/shark";

function getExpensesAPI(search) {
  const payload = {
    command: "getExpenses",
    ...search,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.expenses,
    searchArgs: search,
  }));
}

function getExpenseAPI({ id }) {
  const payload = {
    command: "getExpense",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createExpenseAPI(expense) {
  const payload = {
    command: "createExpense",
    ...expense,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateExpenseAPI(expense) {
  const payload = {
    command: "updateExpense",
    ...expense,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function deleteExpenseAPI({ id, ...expense }) {
  const payload = {
    command: "deleteExpense",
    id,
    ...expense,
  };
  return shark.post(payload).then(() => ({ data: id }));
}

function getExpenseDateSettingsAPI(args) {
  const payload = {
    command: "getExpenseDateSettings",
    ...args,
  };
  return shark.post(payload).then((response) => response.responseData);
}

function createExpenseAttachmentAPI(args) {
  return createAttachmentAPI({ command: "createExpenseAttachment", ...args });
}

export const api = {
  getMany: getExpensesAPI,
  getOne: getExpenseAPI,
  createOne: createExpenseAPI,
  updateOne: updateExpenseAPI,
  deleteOne: deleteExpenseAPI,
  getDateSettings: getExpenseDateSettingsAPI,
  createAttachment: createExpenseAttachmentAPI,
};
