import { bool, object, string } from "yup";

const contractProductSchema = object().shape({
  id: string().label("Id").required().max(6),
  description: string().label("Description").required().max(30),
  isInactive: bool().required().default(false),
  isPriorityIssue: bool().required().default(false),
  isApplicbleForCommission: bool().required().default(false),
  isFixedCostWithAllNonbillTime: bool().required().default(false),
  isExpenseTripBillable: bool().required().default(false),
  willCreateProject: bool().required().default(false),
  willCreateExpenseTrip: bool().required().default(false),
  taxProduct: string().label("Tax Product").required().max(12),
  projectGroup: string().label("Project Group").max(6),
  defaultOwner: string().label("Default Owner").max(3),
  projectType: string().label("Project Type").max(2),
  sortValue: string().label("Sort value").max(6),
});

export default contractProductSchema;
