import { combineReducers } from "@reduxjs/toolkit";

import jobsReducer from "../jobs/state";
import notificationsReducer from "../notifications/state";
import userActivityReducer from "../userActivity/state";
import watchState from "../watches/state";

const activityReducer = combineReducers({
  notifications: notificationsReducer,
  userActivity: userActivityReducer,
  jobs: jobsReducer,
  watches: watchState.reducer,
});
export default activityReducer;
