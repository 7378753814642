import ExpenseTripDescription from "./ExpenseTripDescription";
import ExpenseTripSelect from "./ExpenseTripSelect";
import {
  useExpenseTrip,
  useExpenseTrips,
  useExpenseTripsBySearch,
} from "./hooks";
import tripState from "./state";
import ExpenseUser from "./users";

//export const expenseTripReducer = expenseTripState.reducer;

const ExpenseTrip = {
  // Backend API
  api: tripState.api,
  // State
  actions: tripState.actions,
  selectors: tripState.entity.selectors,
  // Hooks
  useAll: useExpenseTrips,
  useSearch: useExpenseTripsBySearch,
  useOne: useExpenseTrip,
  // Components
  Select: ExpenseTripSelect,
  Description: ExpenseTripDescription,
  // Related entities
  User: ExpenseUser,
};
export default ExpenseTrip;
