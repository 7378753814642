import React from "react";

import DetailTableEdit from "../../common/details/DetailTableEdit";
import { Forms, useFormsAutoSubmit } from "../../common/forms";
import Cr from "../../crs";
import Project from "../../projects";

export default function ProjectShowDetails({
  project,
  update,
  isLoading,
  isDisabled,
}) {
  return (
    <DetailTableEdit isDisabled={isDisabled}>
      <Forms
        onSubmit={(e) => update(e, Cr.updatePrompt)}
        defaultValues={project}
        schema={{ schema: Project.schema }}
      >
        <TableBody
          project={project}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Forms>
    </DetailTableEdit>
  );
}

function TableBody({ project, isLoading, isDisabled }) {
  useFormsAutoSubmit({
    debounceMs: 300,
    shouldResetOnError: true,
  });
  return (
    <tbody>
      <DetailTableEdit.Row.OwnerGroup
        entity={project}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        name="priorityId"
        label="Priority"
        value={<Project.Priority.Description id={project?.priorityId} />}
        as={Project.Priority.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
        getRowProps={() => ({
          className: Project.getPriorityClassName(project),
        })}
      />
      <DetailTableEdit.Row
        name="typeId"
        label="Type"
        value={<Project.Type.Description id={project?.typeId} />}
        as={Project.Type.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        name="sourceId"
        label="Source"
        value={<Project.Source.Description id={project?.sourceId} />}
        as={Project.Source.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        name="estimateStatusId"
        label="Estimate"
        value={
          <Project.EstimateStatus.Description id={project?.estimateStatusId} />
        }
        as={Project.EstimateStatus.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </tbody>
  );
}
