import _ from "lodash";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useFieldArray, useFormContext } from "../common/forms";
import useReinitState from "../common/hooks/useReinitState";
import ProjectCustomerEdit from "./ProjectCustomerEdit";
import { PROJECT_CUSTOMER_INITIAL } from "./projectCustomerSchema";

export default function ProjectCustomersEdit({ name, isNew = false }) {
  const {
    formState: { errors },
    formsContext: { isDisabled },
  } = useFormContext();

  const error = errors?.[name];

  const { fields, append, remove } = useFieldArray({ name });

  const [editCustomers, setEditCustomers] = useReinitState(isNew);

  return (
    <Row className="border-bottom mb-3">
      <Col>
        <Form.Row>
          <Form.Label>Customers</Form.Label>
        </Form.Row>

        {fields?.map((c, idx) => (
          <ProjectCustomerEdit
            name={`customers[${idx}]`}
            key={`customers[${idx}]`}
            isFirst={idx === 0}
            onRemove={() => remove(idx)}
            isNew={isNew}
            isCustomerDisabled={!editCustomers}
          />
        ))}
        <Form.Row className="mb-3">
          <Col xs={"auto"}>
            {!editCustomers ? (
              <Button
                variant="secondary"
                onClick={() => setEditCustomers(true)}
                disabled={isDisabled}
              >
                Edit Customers
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={() => append(PROJECT_CUSTOMER_INITIAL)}
                disabled={isDisabled}
              >
                Add Customer
              </Button>
            )}
          </Col>
          <Col>
            {_.isString(error?.message) ? (
              <small className="text-danger">{error?.message}</small>
            ) : null}
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
}
