import { isUndefined } from "lodash";

import createEntityReducer from "../../common/entity/createEntityReducer";
import { buildAsyncThunkReducer } from "../../common/functions/state";
import { actions } from "./actions";
import { entity } from "./entity";

export const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  initialState: { crTableSettings: {}, currentCr: null },
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(
      builder,
      actions.createComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.createAttachment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateSetAll,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.moveOne,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateWatch,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.createQaComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateQaComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );

    builder.addCase(actions.setCrTableSettings, (state, action) => {
      const { filter, layout } = action.payload;
      if (!isUndefined(filter)) state.crTableSettings.filter = filter;
      if (!isUndefined(layout)) state.crTableSettings.layout = layout;
    });
    builder.addCase(actions.setCurrentCr, (state, action) => {
      state.currentCr = action.payload;
    });
  },
});
