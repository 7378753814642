import React from "react";

import EstimateTable from "./EstimateTable";
import { useEstimatesBySource } from "./hooks";

const layout = ["id", "type", "status", "owner"];

export default function EstimateTableBySource({ source, reference }) {
  const { estimates, loading, reset } = useEstimatesBySource({
    search: { source, reference },
    canGet: true,
  });

  return (
    <EstimateTable
      estimates={estimates}
      layout={layout}
      isLoading={loading}
      onRefresh={() => reset({ source, reference })}
      skeletonLines={3}
    />
  );
}
