import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import ContractProduct from "../../../contracts/contractProducts";
import ContractNavSidebar from "../ContractNavSidebar";

export default function ContractProductNew() {
  const { createOne } = ContractProduct.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Page
        title="Contract Product"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  return (
    <Page title="Contact Product" navSidebar={<ContractNavSidebar />}>
      <ContractProduct.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(contractProduct) =>
          createOne(contractProduct).then(({ id }) =>
            setRedirect(`/contracts/contract-products/${id}`),
          )
        }
      />
    </Page>
  );
}
