import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import Mailto from "../common/details/Mailto";
import Tel from "../common/details/Tel";
import { useUser } from "./hooks";
import Pseudo from "./pseudo";
import Team from "./teams";

export default function UserModal({ id, show, onHide, mailto }) {
  const { user } = useUser({ id });
  const { pseudo: pseudoUser } = Pseudo.useOne({ id });

  const _user = id?.charAt(0) !== "*" ? user : pseudoUser;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <Link to={`/users/${id}`}>{`${_user?.name} (${_user?.id})`}</Link>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lead mt-n1 mb-0">
          {<Team.Description id={_user?.teamId} />}
        </p>
        <UserField title="Phone (Work)" value={_user?.phoneWork}>
          <Tel phone={_user?.phoneWork} />
        </UserField>
        <UserField title="Phone (Cell)" value={_user?.phoneCell}>
          <Tel phone={_user?.phoneCell} />
        </UserField>
        <UserField title="Email" value={_user?.email}>
          <Mailto
            email={_user?.email}
            subject={mailto?.subject}
            body={mailto?.body}
          />
        </UserField>
      </Modal.Body>
    </Modal>
  );
}

function UserField({ title, value, children }) {
  if (_.isUndefined(value) || !value) return null;
  return (
    <>
      <div className="dropdown-divider" />
      <small className="my-0 font-weight-bold">{title}</small>
      <p className="mt-0 mb-0">{children || value}</p>
    </>
  );
}
