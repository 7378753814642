import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import ContractNavSidebar from "../ContractNavSidebar";
import ContractProductDefaultCrEditPage from "./ContractProductDefaultCrEditPage";
import ContractProductDefaultCrIndex from "./ContractProductDefaultCrIndex";
import ContractProductDefaultCrNewPage from "./ContractProductDefaultCrNewPage";

export default function ContractProductDefaultCrsPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Contract Product"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new/:id`}>
        <ContractProductDefaultCrNewPage />
      </Route>
      <Route path={`${path}/:id`}>
        <ContractProductDefaultCrEditPage />
      </Route>
      <Route exact path={`${path}`}>
        <ContractProductDefaultCrIndex />
      </Route>
    </Switch>
  );
}
