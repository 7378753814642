import EstimateEditorModal from "./EstimateEditorModal";
import EstimateLink from "./EstimateLink";
import SelectBySource from "./EstimateSelectBySource";
import Table from "./EstimateTable";
import EstimateTableBySource from "./EstimateTableBySource";
import { useEstimate, useEstimates, useEstimatesBySource } from "./hooks";
import state from "./state";

const Estimate = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useEstimate,
  useAll: useEstimates,
  useSearch: useEstimatesBySource,
  // Components
  Table,
  Link: EstimateLink,
  SelectBySource,
  TableBySource: EstimateTableBySource,
  Editor: EstimateEditorModal,
  // Related Entity Objects
};

export default Estimate;
