import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import AwsRegionEdit from "./AwsRegionEdit";
import AwsRegionIndex from "./AwsRegionIndex";
import AwsRegionNavSidebar from "./AwsRegionNavSidebar";
import AwsRegionNew from "./AwsRegionNew";

export default function AwsRegionsPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Region"
        navSidebar={<AwsRegionNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <AwsRegionNew />
      </Route>
      <Route path={`${path}/:id`}>
        <AwsRegionEdit />
      </Route>
      <Route exact path={`${path}`}>
        <AwsRegionIndex />
      </Route>
    </Switch>
  );
}
