import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getCustomerOrganizations" }).then((r) => {
      return { data: r.responseData.customerOrganizations };
    });
  },
};

const entity = createEntity({
  name: "organization",
  type: "customers/organizations",
  getState: (state) => state.customers.organizations,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const organizationState = {
  api,
  entity,
  actions,
  reducer,
};

export default organizationState;
