import AwsAccountEditor from "./AwsAccountEditor";
import AwsAccountLink from "./AwsAccountLink";
import AwsAccountSelect from "./AwsAccountSelect";
import Table from "./AwsAccountTable";
import AwsAccountTableFilter from "./AwsAccountTableFilter";
import { useAwsAccount, useAwsAccounts } from "./hooks";
import awsAccountState from "./state";

const AwsAccount = {
  // API
  api: awsAccountState.api,
  // State
  actions: awsAccountState.actions,
  selectors: awsAccountState.entity.selectors,
  // Hooks
  useOne: useAwsAccount,
  useAll: useAwsAccounts,
  // Components
  Table,
  TableFilter: AwsAccountTableFilter,
  Link: AwsAccountLink,
  Editor: AwsAccountEditor,
  Select: AwsAccountSelect,
  // Related Entity Objects
};

export default AwsAccount;
