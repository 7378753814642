import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import User from "../../../users";
import PseudoNavSidebar from "./PseudoNavSidebar";

export default function PseudoEdit() {
  const id = String(useParams().id).toUpperCase();
  const { pseudo, loading, updateOne } = User.Pseudo.useOne({
    id,
    canGet: true,
  });
  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Pseudo User"
        navSidebar={<PseudoNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Pseudo User" navSidebar={<PseudoNavSidebar />}>
      {pseudo ? (
        <User.Pseudo.Editor
          values={pseudo}
          isLoading={loading}
          onSubmit={(values) => {
            updateOne(values);
          }}
        />
      ) : (
        <h4>Pseudo user not found.</h4>
      )}
    </Page>
  );
}
