import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmModal({
  title,
  message,
  buttons,
  show,
  onHide,
  ...props
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modal2"
      backdropClassName="modal2-backdrop"
      {...props}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {buttons.map((b) => (
          <Button
            onClick={(e) => {
              b.onClick && b.onClick(e);
              onHide();
            }}
            variant={b.variant}
            key={`confirm-${b.label}-${b.idx}`}
          >
            {b.label}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
}
