import React from "react";

import useNotifications from "./activity/notifications/useNotifications";
import useUserActivity from "./activity/userActivity/useUserActivity";
import useToken from "./auth/useToken";
import useIntervalUpdateComponent from "./common/hooks/useIntervalUpdateComponent";
import Page from "./common/pages/Page";
import useCurrentTimer from "./time/currentTimer/useCurrentTimer";
import usePreloader from "./usePreloader";

export default function Preloader({ children, isLoading: cacheLoading }) {
  // Check Token authentication and enable state persistence
  const { loading: loadingAuth, isLoggedIn, error: authError } = useToken();

  const blocked = loadingAuth || !isLoggedIn || cacheLoading;
  const canGet = !blocked;

  usePreloader({ canGet });

  const canRoute =
    !loadingAuth && (!cacheLoading || !isLoggedIn || authError !== null);

  return (
    <>
      <Reloader canGet={canGet} />
      {canRoute ? (
        children
      ) : (
        <Page title="Logging in user" lead="Please wait..." />
      )}
    </>
  );
}

function Reloader({ canGet }) {
  useIntervalUpdateComponent(5000);

  useUserActivity({ canGet });
  useNotifications({ canGet });
  useCurrentTimer({ canGet });
  return null;
}
