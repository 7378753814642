import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { BiDotsHorizontalRounded, BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import Watch from "../activity/watches";
import GroupOwnerFields from "../common/components/forms/GroupOwnerFields";
import {
  ErrorIcon,
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import useSearchParams from "../common/hooks/useSearchParams";
import ContextMenu from "../common/popovers/ContextMenu";
import ProjectNew from "./ProjectNew";
import projectSchema from "./projectSchema";
import Status from "./statuses";
import Type from "./types";

export default function ProjectStatusEditor({
  project,
  update,
  isLoading,
  isDisabled,
  updateWatch,
  AdditionalForms = null,
}) {
  const location = useLocation();
  const history = useHistory();
  const [statusId, setStatusId] = useState(useSearchParams("status"));
  const [statusPrompt, setStatusPrompt] = useState(false);
  const onReset = () => {
    history.push(location.pathname);
    setStatusPrompt(false);
  };

  return (
    <Form.Row>
      <Forms
        onSubmit={(project) => update(project).then(onReset)}
        defaultValues={project}
        schema={{ schema: projectSchema }}
        isDisabled={isLoading || isDisabled}
      >
        <ProjectStatusId
          onShow={(s) => {
            setStatusPrompt(true);
            setStatusId(s);
          }}
          statusId={statusId}
          isLoading={isLoading}
        />
      </Forms>
      <Col>
        <ButtonGroup>
          <Button
            onClick={() => setStatusPrompt(true)}
            variant="light"
            title="Edit Project"
          >
            <BiEdit />
          </Button>
          <ActionContextMenu project={project} update={update} />
          <Watch.Button
            label="Project"
            value={project?.isWatching}
            onChange={(v) => updateWatch({ isWatching: v })}
          />
        </ButtonGroup>
        <Forms
          onSubmit={(project) =>
            update(project).then((p) => {
              onReset(p);
              setStatusId(undefined);
            })
          }
          defaultValues={{ ...project, ...(statusId && { statusId }) }}
          schema={{ schema: projectSchema }}
          isDisabled={isLoading || isDisabled}
          showDevTool
          shouldUnregister
        >
          <ProjectStatusForms
            prompt={statusPrompt}
            onHide={onReset}
            isLoading={isLoading}
            AdditionalForms={AdditionalForms}
          />
        </Forms>
      </Col>
    </Form.Row>
  );
}

function ProjectStatusId({ onShow, statusId: statusIdParam, isLoading }) {
  const {
    setValue,
    watch,
    submitForm,
    formsContext: { isDisabled },
  } = useFormContext();

  const [statusId, owner] = watch(["statusId", "owner", "typeId"]);

  const onStatusChange = useCallback(
    (value, valueNext) => {
      setValue("statusId", valueNext, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (valueNext !== value && valueNext !== "OP") {
        if (owner?.charAt(0) === "*") setValue("owner", "");
        onShow(valueNext);
      }
    },
    [owner, onShow, setValue],
  );

  useEffect(() => {
    if (!isDisabled && statusIdParam && statusIdParam) {
      onStatusChange(statusId, statusIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, statusIdParam]);

  return (
    <Col xs={8}>
      <StatusId
        name="statusId"
        onChange={(valueNext) => {
          onStatusChange(statusId, valueNext);
          if (statusId !== valueNext && valueNext !== "CL") {
            submitForm();
          }
        }}
        isInvalid={false}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    </Col>
  );
}

function ProjectStatusForms({ prompt, onHide, isLoading, AdditionalForms }) {
  const {
    watch,
    reset,
    defaultValues,
    formsContext: { isDisabled },
  } = useFormContext();

  const [statusId, typeId] = watch(["statusId", "owner", "typeId"]);

  const handleHide = () => {
    reset();
    onHide();
  };

  return (
    <Modal show={prompt} onHide={handleHide} size="lg">
      <Modal.Header>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StatusForm
          onHide={handleHide}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
        {AdditionalForms ? (
          <AdditionalForms
            statusId={statusId}
            project={{ ...defaultValues, statusId, typeId }}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

function StatusId({ name, ...props }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <Status.Select
      id="cr-status"
      {...field}
      placeholder="Status..."
      isInvalid={!!error}
      errors={error?.message}
      {...props}
    />
  );
}

function StatusForm({ isLoading, isDisabled, onHide }) {
  const {
    submitForm,
    formState: { isSubmitting, isValid, errors },
  } = useFormContext();

  useFormsKeys();

  return (
    <Form>
      <StatusId
        name="statusId"
        label="Status"
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <GroupOwnerFields />
      <FormsField.Select name="typeId" label="Type" as={Type.Select} />
      <FormsField.TextArea
        name="fixNotes"
        label="Fix Notes"
        minRows={6}
        placeholder="Fix notes..."
      />
      <Form.Row className="justify-content-between">
        <Col xs="auto">
          <ButtonGroup>
            <Button
              onClick={() => {
                if (isValid === false) console.log("Project Invalid", errors);
                submitForm();
              }}
              disabled={isSubmitting || isDisabled}
            >
              Save
            </Button>
            <Button
              variant="danger"
              onClick={onHide}
              disabled={isSubmitting || isDisabled}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs="auto">
          <ErrorIcon className="text-danger" />
        </Col>
      </Form.Row>
    </Form>
  );
}

function ActionContextMenu({ project, update }) {
  const user = useSelector((state) => state.auth.user);
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);

  const location = useLocation();
  // Close modal on navigate (link)
  useEffect(() => {
    setShowDuplicate(false);
  }, [location]);

  return (
    <>
      <Button
        ref={ref}
        onClick={() => setShow(true)}
        variant="light"
        title="Actions"
      >
        <BiDotsHorizontalRounded />
      </Button>

      <ContextMenu
        title={"Project Actions"}
        target={ref?.current}
        show={show}
        onHide={() => setShow(false)}
        placement="bottom"
      >
        <ListGroup onClick={() => setShow(false)}>
          <ListGroup.Item action onClick={() => update({ owner: user })}>
            Assign to me
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => setShowDuplicate(true)}>
            Duplicate
          </ListGroup.Item>
        </ListGroup>
      </ContextMenu>
      <DuplicateProjectModal
        show={showDuplicate}
        onHide={() => setShowDuplicate(false)}
        project={project}
      />
    </>
  );
}

function DuplicateProjectModal({ show, onHide, project = {} }) {
  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title id="new-project-modal">New Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectNew
          onNew={onHide}
          project={{
            ...project,
            id: undefined,
            priorityId: undefined,
            statusId: undefined,
            transferredFolder: undefined,
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
