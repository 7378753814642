import { CR_QA_STATUSES, useQaStatus, useQaStatuses } from "./hooks";
import QaStatusDescription from "./QaStatusDescription";
import QaStatusSelect from "./QaStatusSelect";

const QaStatus = {
  entities: CR_QA_STATUSES,
  // Hooks
  useAll: useQaStatuses,
  useOne: useQaStatus,
  // Components
  Select: QaStatusSelect,
  Description: QaStatusDescription,
};

export default QaStatus;
