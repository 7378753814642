import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../common/components/StampDisplay";
import Page from "../../common/pages/Page";
import Contract from "../../contracts";
import ContractDetailTable from "../../contracts/ContractDetailTable";
import ContractNavSidebar from "./ContractNavSidebar";

export default function ContractEdit() {
  const id = parseInt(useParams().id);
  const { contract, updateOne, loading } = Contract.useOne({
    id,
    canGet: true,
  });
  const title = `Contract${
    contract?.description ? ": " + contract.id + "-" + contract.description : ""
  }`;

  const { isAdmin, canMaintainContracts } = useSelector((state) => state.auth);

  const tableData = useMemo(() => {
    let output = [];
    if (contract?.details) output = output.concat(contract?.details);
    if (contract?.oslProjects) output = output.concat(contract?.oslProjects);
    return output;
  }, [contract?.details, contract?.oslProjects]);

  if (!(canMaintainContracts || isAdmin))
    return (
      <Page
        title="Edit Contract"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line no-constant-condition

  return (
    <Page
      title={title}
      lead={loading ? "Loading..." : !contract ? "Contract not found" : null}
      navSidebar={<ContractNavSidebar />}
    >
      {contract ? (
        <>
          <Contract.Editor
            values={contract}
            onSubmit={(contract) => updateOne(contract)}
            isDisabled={loading || !(canMaintainContracts || isAdmin)}
          />
          <StampDisplay record={contract} />

          <ContractDetailTable data={tableData} loading={loading} />
        </>
      ) : null}
    </Page>
  );
}
