import React from "react";

import Contract from "../../../contracts";

export default function ContractProductDefaultCrNew({
  loading,
  onSubmit,
  values,
}) {
  return (
    <>
      <Contract.ContractProductDefaultCr.Editor
        isNew={true}
        values={values}
        isLoading={loading}
        onSubmit={onSubmit}
      />
    </>
  );
}
