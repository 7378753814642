import _ from "lodash";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { BsStopwatchFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { _date, _datetime } from "../common/functions/dates";
import {
  formatTotalSeconds,
  timerCalculation,
} from "../common/functions/timerCalculation";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Cr from "../crs";
import Customer from "../customers";
import User from "../users";
import TimeBillableOverrideReason from "./billableOverrideReasons";
import TimeBillingNoteLink from "./TimeBillingNoteLink";
import { getTimeType, TIME_LISTING_TYPES } from "./timeListingTypes";
import useTimeModifyModal from "./useTimeModifyModal";
import useTimer from "./useTimer";

const DEFAULT_COMPONENTS = {
  CrLink: Cr.Link,
  Customer: Customer.Link,
};

export default function TimeTable({
  times,
  onSelect,
  onRefresh,
  isMultiSelect,
  additionalColumns,
  Components: OverrideComponents,
  ...props
}) {
  const loggedInUser = useSelector((state) => state.auth.user);

  const todayStamp = useMemo(() => _date.stamp(), []);
  const {
    current: { status: timerStatus },
    restart: restartTimer,
  } = useTimer();

  const [ModifyModal, modifyModelProps, setModifyShow] = useTimeModifyModal({
    handleChange: () => {
      setModifyShow();
      onRefresh();
    },
  });

  const Components = useMemo(
    () => ({ ...DEFAULT_COMPONENTS, ...OverrideComponents }),
    [OverrideComponents],
  );

  const columns = useMemo(
    () => [
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "created by",
        Header: "Created by",
        accessor: "createUserId",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        aggregate: "uniqueCount",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value, row: { original: time }, cell }) =>
          !cell.isGrouped ? (
            <Link
              className="text-reset"
              to={`/time?user=${time.userId}&date=${_date.urlParams(
                _date.fromStamp(value),
              )}`}
            >
              {_date.display(_date.fromStamp(value))}
            </Link>
          ) : (
            <span>{_date.display(_date.fromStamp(value))}</span>
          ),
      },
      {
        id: "totalHours",
        Header: "Hours",
        disableFilters: true,
        accessor: (t) => {
          if (t.hasRunningTimer) {
            return parseFloat(t.hours) + getRoundedHours(t.date, t.timers);
          } else return parseFloat(t.totalHours);
        },

        format: {
          alignment: { horizontal: "right" },
          number: {
            decimals: 2,
          },
        },
        dataType: "number",
        aggregate: "sum",
        sortType: "numeric",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            const hours = parseFloat(row.original.hours) + total.hours;
            const roundedHours =
              getRoundedHours(row.original.date, row.original.timers) +
              total.roundedHours;

            const seconds =
              getSeconds(row.original.date, row.original.timers) +
              total.seconds;

            return {
              hours: hours,
              roundedHours: roundedHours,
              seconds: seconds,
            };
          };
          // Only calculate total visits if rows change
          const total = rows.reduce(calcRow, {
            hours: 0,
            roundedHours: 0,
            seconds: 0,
          });

          const totalSeconds = total.hours * 60 * 60 + total.seconds;
          const secondsFormatted =
            total.seconds !== 0 ? formatTotalSeconds(totalSeconds).hhmmss : "";
          return (
            <div className="text-right">
              {secondsFormatted +
                " " +
                (total.roundedHours + total.hours)
                  .toFixed(2)
                  .toString()
                  .padStart(4)}
            </div>
          );
        },
      },
      {
        id: "project",
        Header: "Project",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return "TBD";
            case TIME_LISTING_TYPES.INTERNAL:
              return "Internal";
            default:
              return t.projectId;
          }
        },
        Cell: (o) =>
          (function (o) {
            if (o?.row?.original?.timeType === "CR") {
              return (
                <Link
                  className="text-reset"
                  to={{ pathname: `/projects/${o?.value}` }}
                >
                  {o?.value}
                </Link>
              );
            } else return <>{o?.value}</>;
          })(o),
        disableFilters: true,
      },
      {
        id: "cr",
        Header: "CR",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return "TBD";
            case TIME_LISTING_TYPES.INTERNAL:
              return t.internalProjectName || t.internalProjectId;
            default:
              if (t.crId === 0) return "N/A";
              else return t.crId;
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ row: { original: time }, value }) => {
          if (
            time?.timeType === "CR" ||
            time?.timeType === "Travel" ||
            time?.timeType === "On-site"
          ) {
            return (
              <Components.CrLink
                id={time?.crId}
                tooltipData={{
                  customers: time?.customers,
                  synopsis: time?.synopsis,
                }}
                onNewTime={onRefresh}
              />
            );
          } else return <>{value}</>;
        },
        aggregate: "count",
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return t.customer?.id || t.customer?.name;
            default:
              if (t?.customers?.length) {
                if (t.customers.length === 1)
                  return t.customers[0]?.id || t.customers[0]?.name;
                else return "Multiple customers";
              }
          }
        },

        Cell: ({ value }) =>
          typeof value === "number" ? (
            <Customer.Link id={value} />
          ) : (
            <div style={{ minWidth: "150px" }}>{value}</div>
          ),
        Filter: Customer.TableFilter,
        filter: "equals",
        aggregate: allSameOrEmpty,
      },
      {
        id: "billingNote",
        Header: "Billing Note",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: "billingNote",

        Cell: ({ value, row }) => {
          return (
            <TimeBillingNoteLink
              timeId={row?.original?.id}
              value={value}
              setModifyShow={setModifyShow}
              onSelect={onSelect}
            />
          );
        },
        aggregate: "count",
        Aggregated: ({ row }) => {
          return row.leafRows[0].original?.billingNoteAggregate || "";
        },
      },
      {
        id: "isBillable",
        Header: "Bill",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.BILLABLE:
              return String.fromCodePoint("0x1F4B2");
            case TIME_LISTING_TYPES.TBD:
              return "\u2753";
            case TIME_LISTING_TYPES.NON_BILLABLE_HIDDEN:
              return String.fromCodePoint("0x1F648");
            case TIME_LISTING_TYPES.INTERNAL:
              return String.fromCodePoint("0x1F6AB");
            default:
              return String.fromCodePoint("0x1F193");
          }
        },
        format: {
          alignment: { horizontal: "center" },
        },
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrEmpty,
      },
      {
        id: "nonTimerHours",
        Header: "Non Timer",
        accessor: "hours",
        dataType: "number",
        disableFilters: true,
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",
        sortType: "numeric",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "rate",
        Header: "Rate",
        disableFilters: true,
        dataType: "number",
        format: {
          number: {
            decimals: 2,
          },
        },
        aggregate: "average",
        accessor: "rate",
      },
      {
        id: "amount",
        Header: "Amount",
        disableFilters: true,
        dataType: "number",
        format: {
          number: {
            decimals: 2,
          },
        },
        aggregate: "sum",
        accessor: (t) => {
          const totalHours = t.hasRunningTimer
            ? parseFloat(t.hours) + getRoundedHours(t.date, t.timers)
            : t.totalHours;

          if (t.rate !== 0 && t.isBillable)
            return Number(parseFloat(t.rate) * totalHours);
        },
      },
      {
        id: "timerHours",
        Header: "Timer hours",
        accessor: (t) => {
          const roundedHours = getRoundedHours(t.date, t.timers);
          if (roundedHours !== 0) return Number(roundedHours);
        },
        disableSortBy: true,
        disableFilters: true,
        dataType: "number",
        format: {
          number: {
            decimals: 2,
          },
        },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return (
              parseFloat(
                getRoundedHours(row.original.date, row.original.timers),
              ) + total
            );
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "timer",

        Header: () => (
          <div className="text-center">
            <BsStopwatchFill className="align-content-center" size="20px" />
          </div>
        ),
        accessor: (t) => {
          let hhmmss = getHHMMSS(t.date, t.timers);
          if (hhmmss === "0:00:00") hhmmss = "";
          if (loggedInUser.trim() === t?.userId?.trim()) {
            if (t.hasRunningTimer) {
              return (
                <Button
                  block
                  size="sm"
                  variant="danger"
                  onClick={() => setModifyShow(t.id, true)}
                >
                  Stop
                </Button>
              );
            } else if (t.date === todayStamp && timerStatus !== "running") {
              if (hhmmss !== "")
                return (
                  <Button
                    block
                    size="sm"
                    variant="warning"
                    onClick={() => restartTimer(t.id)}
                  >
                    {hhmmss + " Restart"}
                  </Button>
                );
              else
                return (
                  <Button
                    block
                    size="sm"
                    variant="success"
                    onClick={() => restartTimer(t.id)}
                  >
                    Start
                  </Button>
                );
            } else {
              return <div className="text-center">{hhmmss}</div>;
            }
          } else {
            if (t.hasRunningTimer) {
              return (
                <div className="text-success text-center">
                  {hhmmss + (t.hasRunningTimer ? "*" : "")}
                </div>
              );
            } else {
              return (
                <div className="text-center">
                  {hhmmss + (t.hasRunningTimer ? "*" : "")}
                </div>
              );
            }
          }
        },

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return getSeconds(row.original.date, row.original.timers) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-center">
              {formatTotalSeconds(total).hhmmss}
            </div>
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "invoiceNumber",
        Header: "Invoice",
        accessor: (t) => {
          if (t?.invoiceNumber?.trim() !== "") {
            return t?.invoiceNumber;
          } else if (t?.holdDate?.trim() !== "") {
            return "Held until " + _date.display(_date.fromStamp(t?.holdDate));
          } else {
            return " ";
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        format: {
          alignment: { horizontal: "center" },
        },
      },
      {
        Header: "Override Reason",
        id: "Override Reason",
        accessor: "billableOverrideReasonId",
        Filter: TimeBillableOverrideReason.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => (
          <TimeBillableOverrideReason.Description id={value} />
        ),
      },
      {
        id: "Internal Comments",
        Header: "Internal Comments",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: "internalComments",

        Cell: ({ value }) => {
          return <div style={{ minWidth: "150px" }}>{value}</div>;
        },
        aggregate: "count",
        Aggregated: ({ row }) => {
          return row.leafRows[0].original?.internalCommentsAggregate || "";
        },
      },
      {
        Header: "Create Date",
        id: "createDate",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value?.substring(0, 8)))}</span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Create Time",
        id: "createTime",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_datetime.displayTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        Header: "Modify Date",
        id: "modifyDate",
        accessor: "modifyDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Modify Time",
        id: "modifyTime",
        accessor: "modifyDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value ? _datetime.displayTime(_datetime.fromStamp(value)) : null}
          </span>
        ),
      },
      ...(additionalColumns || []),
    ],
    [
      additionalColumns,
      onRefresh,
      setModifyShow,
      onSelect,
      loggedInUser,
      todayStamp,
      timerStatus,
      restartTimer,
      Components,
    ],
  );

  return (
    <>
      <Table
        hasCount
        bordered
        hover
        size="sm"
        name="Times"
        columns={columns}
        data={times}
        onRefresh={onRefresh}
        isMultiSelect={isMultiSelect}
        getRowProps={(row) => {
          if (row.isSelected) return { className: "table-primary" };
          if (row.isGrouped) return {};
          switch (getTimeType(row?.original)) {
            case TIME_LISTING_TYPES.BILLABLE:
              return {
                className: "table-success",
              };
            case TIME_LISTING_TYPES.TBD:
              return {
                className: "table-warning",
              };
            case TIME_LISTING_TYPES.INTERNAL:
              return {
                className: "table-secondary",
              };
            default:
              if (row?.original?.isInternalCr || row?.original?._skeleton)
                return {};
              else
                return {
                  className: "table-danger",
                };
          }
        }}
        moveFooterToTop
        {...props}
      />
      <ModifyModal {...modifyModelProps} />
    </>
  );
}

function getRoundedHours(dbcDate, timers) {
  const momentDate = _date.fromStamp(dbcDate);
  return timerCalculation(momentDate, timers).roundedHours;
}

function getHHMMSS(dbcDate, timers) {
  const momentDate = _date.fromStamp(dbcDate);
  return timerCalculation(momentDate, timers).hhmmss;
}

function getSeconds(dbcDate, timers) {
  const momentDate = _date.fromStamp(dbcDate);
  return timerCalculation(momentDate, timers).totalSeconds;
}

function allSameOrEmpty(leafValues) {
  return _.uniq(leafValues).length === 1 ? leafValues[0] || "" : "";
}
