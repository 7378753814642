import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import Contract from "../../../contracts";
import ContractProductDefaultCrEdit from "./ContractProductDefaultCrEdit";

export default function ContractProductDefaultCrEditModal({
  id,
  show,
  onHide,
  onSave,
}) {
  const { contractProductDefaultCr, loading, updateOne } =
    Contract.ContractProductDefaultCr.useOne({
      id,
      canGet: true,
    });
  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header className="py-1" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"You do not have access to this feature"}
          </Modal.Title>
        </Modal.Header>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {"Default Cr record: " + id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContractProductDefaultCrEdit
          contractProductDefaultCr={contractProductDefaultCr}
          loading={loading}
          onSubmit={(values) => updateOne(values).then(() => onSave())}
        />
      </Modal.Body>
    </Modal>
  );
}
