import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import TaxProduct from "../../../invoices/taxProducts";
import InvoicesNavSidebar from "../InvoicesNavSidebar";

export default function TaxProductNew() {
  const { createOne } = TaxProduct.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainInvoices, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainInvoices || isAdmin))
    return (
      <Page
        title="New Tax Product"
        navSidebar={<InvoicesNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title={"New Tax Product"} navSidebar={<InvoicesNavSidebar />}>
      <TaxProduct.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(taxProduct) =>
          createOne(taxProduct).then(({ id }) =>
            setRedirect(`/invoices/tax-products/${id}`),
          )
        }
      />
    </Page>
  );
}
