import TimeBillableOverrideReason from "./billableOverrideReasons";
import useCurrentTimer from "./currentTimer/useCurrentTimer";
import TimeHoldReason from "./holdReasons";
import TimeInternalProject from "./internalProjects";
import { timeActions, timeSelectors } from "./state";
import TimeBillingNoteLink from "./TimeBillingNoteLink";
import TimeCrEditor from "./TimeCrEditor";
import TimeEstimateTable from "./TimeEstimateTable";
import TimeFilters from "./TimeFilters";
import TimeModify from "./TimeModify";
import TimeModifyModal from "./TimeModifyModal";
import TimeNewModal from "./TimeNewModal";
import TimersTable from "./TimersTable";
import TimeTable from "./TimeTable";
import useTimeModifyModal from "./useTimeModifyModal";
import useTimer from "./useTimer";
import useTimes from "./useTimes";
import useTimeStats from "./useTimeStats";

const Time = {
  // Backend API
  // api: timeApi,
  // State
  actions: timeActions,
  selectors: timeSelectors,
  // Hooks
  useAll: useTimes,
  useTimer: useTimer,
  useCurrentTimer: useCurrentTimer,
  useModifyModal: useTimeModifyModal,
  useStats: useTimeStats,
  // Components
  Table: TimeTable,
  NewModal: TimeNewModal,
  ModifyModal: TimeModifyModal,
  EstimateTable: TimeEstimateTable,
  Filters: TimeFilters,
  TimersTable: TimersTable,
  Modify: TimeModify,
  CrEditor: TimeCrEditor,
  // Related Entity Objects
  BillableOverrideReason: TimeBillableOverrideReason,
  HoldReason: TimeHoldReason,
  InternalProject: TimeInternalProject,
  BillingNoteLink: TimeBillingNoteLink,
};
export default Time;
