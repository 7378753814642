import React, { useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { IoMdAttach } from "react-icons/io";

import AttachmentsTable from "./AttachmentsTable";

export default function AttachmentsButton({
  attachments,
  source,
  create,
  isDisabled,
  onChange,
  variant = "primary",
  label = "Attachments",
  as: Component = Button,
  ...props
}) {
  const [showAttachments, setshowAttachments] = useState(false);
  const [changes, setChanges] = useState(false);

  return (
    <>
      <Component
        variant={variant}
        onClick={() => setshowAttachments(true)}
        {...props}
      >
        <IoMdAttach /> {label}{" "}
        <Badge variant="light">{attachments?.length}</Badge>
      </Component>
      <Modal
        size="xl"
        show={showAttachments}
        onHide={() => {
          setshowAttachments(false);
          if (changes && onChange) onChange();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{label || "Attachments"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AttachmentsTable
            attachments={attachments}
            create={create}
            isDisabled={isDisabled}
            onDelete={() => setChanges(true)}
            source={source}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
