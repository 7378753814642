import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import moment from "moment";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../../common/functions/state";
import { getUserActivityAPI } from "./api";

const userActivityAdapter = createEntityAdapter();

const getUserActivity = createAsyncThunk(
  "userActivity/getMany",
  async (filter, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.userActivity, requestId)) return;

    const { responseData } = await getUserActivityAPI(filter);
    return responseData.userActivity;
  },
);

const userActivitySlice = createSlice({
  name: "userActivity",
  initialState: userActivityAdapter.getInitialState({
    ...INITIAL_LOADING_STATE,
    lastUpdate: moment().subtract(30, "days").toISOString(),
  }),
  reducers: {
    reset(state) {
      userActivityAdapter.removeAll(state);
      state.requests = INITIAL_LOADING_STATE.requests;
      state.lastUpdate = moment().subtract(30, "days").toISOString();
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getUserActivity, (state, action) => {
      if (action.payload.length !== 0) {
        userActivityAdapter.upsertMany(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
  },
});

export const reducer = userActivitySlice.reducer;

const { reset } = userActivitySlice.actions;
export const actions = {
  getUserActivity,
  clearUserActivity: reset,
};

export const selectors = userActivityAdapter.getSelectors(
  (state) => state.activity.userActivity,
);
