import { useQaReview, useQaReviews, useQaReviewsSearch } from "./hooks";
import QaReviewTable from "./QaReviewTable";
import state from "./state";

const QaReview = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useQaReview,
  useAll: useQaReviews,
  useSearch: useQaReviewsSearch,
  // Components
  Table: QaReviewTable,
};
export default QaReview;
