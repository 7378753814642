import React, { useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";

import { Forms, FormsField, useFormContext } from "../../common/forms";
import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import TeamDescription from "../teams/TeamDescription";
import TeamSelect from "../teams/TeamSelect";
import UserDescription from "../UserDescription";
import UserLink from "../UserLink";
import UserSelect from "../UserSelect";
import userGroupingState from "./state";
import userGroupingSchema from "./userGroupingSchema";

export default function UserGroupingEditor({
  values = {},
  onSubmit,
  isNew = false,
  isLoading,
}) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: userGroupingSchema }}
        showDevTool
      >
        <UserGroupingForms isNew={isNew} isLoading={isLoading} />
      </Forms>
      {!isNew ? (
        <Form.Row>
          <Col>
            <Form.Row>
              <Col className={"mt-3"}>
                <h3>Members</h3>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <MemberTable id={values?.id} values={values?.members} />
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
      ) : null}
    </>
  );
}

function UserGroupingForms({ isNew }) {
  const { submitForm } = useFormContext();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="description"
                label="Description"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Button variant="success" onClick={submitForm}>
            {isNew ? "Create Group" : "Save Group"}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

function MemberTable({ id, values, isLoading }) {
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        id: "type",
        Header: "Type",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: (t) => {
          switch (t.type) {
            case "U":
              return "User";
            case "T":
              return "Team";
            default:
              return "Error: " + t.type;
          }
        },
      },
      {
        id: "key",
        Header: "Member",
        accessor: "keyField",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: member } }) =>
          member.type === "U" ? (
            <>
              <UserLink id={value} showId={true} />{" "}
              <UserDescription id={value} />
            </>
          ) : (
            <>
              <TeamDescription id={value} />
            </>
          ),
      },
      {
        id: "edit",
        Header: "",
        accessor: "key",
        disableSortBy: true,
        Cell: ({ row: { original: member } }) => {
          return (
            <Button
              onClick={() =>
                dispatch(
                  userGroupingState.actions.deleteMember({
                    userGroupingId: id,
                    ...member,
                  }),
                )
              }
              disabled={isLoading}
              title="Delete member"
              size="sm"
              variant="link"
              className="text-reset py-0"
            >
              <MdDelete />
            </Button>
          );
        },
      },
    ],
    [dispatch, id, isLoading],
  );

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Table
                hasCount
                label="Members"
                columns={columns}
                data={values}
                layout={["type", "key", "edit"]}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Forms
            onSubmit={(values) =>
              dispatch(
                userGroupingState.actions.addMember({
                  userGrouping: id,
                  ...values,
                }),
              )
            }
            isDisabled={isLoading}
            defaultValues={{ type: "U" }}
          >
            <AddMemberForms />
          </Forms>
        </Col>
      </Form.Row>
    </>
  );
}

function AddMemberForms() {
  const { watch, submitForm } = useFormContext();
  const type = watch("type");
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <FormsField as="select" name="type" label="Type">
            <option value="U">User</option>
            <option value="T">Team</option>
          </FormsField>
        </Col>
        <Col xs="auto" style={{ minWidth: "30rem" }}>
          {type === "U" ? (
            <FormsField.Select name="keyField" label="User" as={UserSelect} />
          ) : (
            <FormsField.Select name="keyField" label="Team" as={TeamSelect} />
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Button variant="success" onClick={submitForm}>
            Add Member
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
