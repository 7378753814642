import { isUndefined } from "lodash";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import User from "../../users";
import CrQaStatusSelect from "../qaStatuses/QaStatusSelect";
import qaReviewSchema from "./qaReviewSchema";

export default function QaReviewEditor({
  values,
  onSubmit,
  isDisabled = false,
  isNew = false,
}) {
  return (
    <Forms
      defaultValues={values}
      onSubmit={({ ...values }) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: qaReviewSchema }}
      isDisabled={isDisabled}
      showDevTool
    >
      <QaReviewForm values={values} isNew={isNew} isDisabled={isDisabled} />
    </Forms>
  );
}

function QaReviewForm() {
  const { submitForm, setValue, watch } = useFormContext();
  const { isAdmin, user } = useSelector((state) => state.auth);

  const comment = watch("comment");
  const reviewer = watch("reviewer");
  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="md">
          <OwnerSelect
            name="reviewer"
            label="Reviewer"
            isDisabled={user !== reviewer && !isAdmin}
          />
        </Col>
        <Col xs={"auto"}>
          <FormsField.Date
            name="reviewDate"
            label="Review Date"
            includeArrows={false}
            disabled={user !== reviewer && !isAdmin}
            customInput={<input size={10} />}
          />
        </Col>
        <Col className="flex-grow-1">
          <QaReviewStatusSelect name="qaStatus" label="Review Status" />
        </Col>
      </Form.Row>
      <Form.Row className={"justify-content-between"}>
        <Col xs={3}>
          <FormsField.Check
            name="isFrontEndTested"
            label="Front end tested"
            disabled={user !== reviewer && !isAdmin}
          />
        </Col>
        <Col xs={"auto"}>
          <FormsField.Check
            name="isAutomatedTestedWritten"
            label="Automated Tests Written"
            disabled={user !== reviewer && !isAdmin}
          />
        </Col>
        <Col xs={"auto"}>
          <FormsField.Check
            name="isCodeReviewed"
            label="Code Reviewed"
            disabled={user !== reviewer && !isAdmin}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <TextareaAutosize
            type="text"
            as="textarea"
            className="form-control"
            minRows={3}
            onChange={(e) => {
              setValue("comment", e.target.value);
            }}
            value={comment}
            maxLength={20000}
          />
        </Col>
      </Form.Row>
      <Form.Row className={"justify-content-between border-top pt-2"}>
        <Button variant="success" onClick={submitForm}>
          Save
        </Button>
      </Form.Row>
    </Form>
  );
}

function OwnerSelect({ name, id = name, label, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <User.Select
        {...field}
        disabled={isDisabled}
        onChange={(v) => {
          onChange(v);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function QaReviewStatusSelect({ name, id = name, label, isInvalid }) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();
  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <CrQaStatusSelect
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        errors={error?.message}
      />
    </Form.Group>
  );
}
