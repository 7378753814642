import { at } from "lodash";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Collapse, Form, Row } from "react-bootstrap";

import { FormsField, useFormContext } from "../common/forms";
import Contact from "../contacts";
import Customer from "../customers";
import ProjectCustomerSelect from "./ProjectCustomerSelect";

export default function ProjectCustomerEdit({
  name,
  onRemove,
  isFirst = false,
  isCustomerDisabled,
  isNew,
}) {
  const [showDetails, setShowDetails] = useState(
    !isCustomerDisabled && !isFirst,
  );
  const {
    watch,
    formState: { errors },
    formsContext: { isDisabled },
  } = useFormContext();

  const [error] = at(errors, name);

  const customerId = watch(`${name}.customerId`);
  const contactId = watch(`${name}.contactId`);
  return (
    <Form.Row className="border-bottom mb-3">
      <Col>
        <Form.Row>
          <Col>
            <ProjectCustomerSelect
              name={`${name}.customerId`}
              isDisabled={isCustomerDisabled || isDisabled}
              isNew={isNew}
            />
          </Col>
          <Col>
            <FormsField.Select
              name={`${name}.contactId`}
              as={Contact.SelectByCustomer}
              isDisabled={!customerId || isDisabled}
              customerId={customerId}
              isClearable
            />
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                onClick={() => setShowDetails(!showDetails)}
                disabled={isDisabled}
              >
                ...
              </Button>
              {onRemove ? (
                <Button
                  variant="danger"
                  onClick={onRemove}
                  disabled={isCustomerDisabled || isDisabled}
                >
                  -
                </Button>
              ) : null}
            </ButtonGroup>
          </Col>
        </Form.Row>
        <Collapse in={(showDetails && !isDisabled) || error}>
          <Form.Row className="px-3 border-left border-primary">
            <Col>
              <Row className="pb-3">
                <Col>
                  <Customer.ContactLink
                    contactId={contactId || ""}
                    id={customerId}
                    label="Contact Details"
                  />
                </Col>
              </Row>
              <Form.Row>
                <Col xs="auto">
                  <FormsField
                    name={`${name}.splitType`}
                    label="Type"
                    as="select"
                    disabled={isDisabled}
                  >
                    <option value="P">Percent</option>
                    <option value="D">Flat Amount</option>
                  </FormsField>
                </Col>

                <Col xs="auto">
                  <FormsField
                    name={`${name}.splitAmount`}
                    label="Amount"
                    type="number"
                    min="0"
                    max="999"
                    disabled={isDisabled}
                  />
                </Col>
                <Col xs="auto">
                  <FormsField
                    name={`${name}.waitingOnCustomer`}
                    label="Waiting"
                    as="select"
                    disabled={isDisabled}
                  >
                    <option value="false">No</option>
                    <option value="true">Waiting for Customer</option>
                  </FormsField>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs="auto">
                  <FormsField
                    name={`${name}.poNumber`}
                    label="PO Number"
                    disabled={isDisabled}
                  />
                </Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Collapse>
      </Col>
    </Form.Row>
  );
}
