import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import ModuleSelect from "../../crs/modules/ModuleSelect";
import TypeSelect from "../../projects/types/TypeSelect";
import GroupSelect from "../../users/groups/GroupSelect";
import contractProductDefaultCrSchema from "./contractProductDefaultCrSchema";

export default function ContractProductDefaultCrEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: contractProductDefaultCrSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <ContractProductDefaultCrForm isNew={isNew} />
    </Forms>
  );
}

function ContractProductDefaultCrForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              {!isNew ? (
                <FormsField
                  name="id"
                  label="Id"
                  readOnly={!isNew}
                  autoComplete="off"
                />
              ) : null}
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField name="synopsis" label="Synopsis" autoComplete="off" />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField.TextArea
                name="description"
                label="Description"
                className="text-monospace"
                autoComplete="off"
                minRows={4}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "200px" }}>
              <FormsField.Select
                name="group"
                label="Project Group"
                as={GroupSelect}
              />
            </Col>
            <Col xs={"auto"} style={{ minWidth: "200px" }}>
              <FormsField.Select
                name="type"
                label="Project Type"
                as={TypeSelect}
              />
            </Col>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField.Select
                name="module"
                label="Module"
                as={ModuleSelect}
                isClearable={true}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="targetDeliveryDays"
                label="Target delivery x days before go-live"
                className="text-monospace"
                autoComplete="off"
                placeholder="0=No target. -1=target=go-live date"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"}>
              <FormsField.Check
                name="willPrefixDateOnSynopsis"
                label="Prefix the go-live date on the synopsis?"
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button
        variant="success"
        onClick={() => {
          submitForm();
        }}
      >
        Submit
      </Button>
    </Form>
  );
}
