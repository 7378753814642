import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";

import Cr from "../crs";
import Customer from "../customers";
import User from "../users";

export default function TimeCrEditor({
  value,
  disabled,
  onChange,
  defaultCustomerId = null,
  defaultUserId = null,
  validateOnMount = false,
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [customerId, setCustomerId] = useState(defaultCustomerId);
  const [userId, setUserId] = useState(defaultUserId);
  const { getCr, loading: crLoading } = Cr.useOne.getOne();
  return (
    <Form.Group>
      <Customer.ChangedAlert current={defaultCustomerId} next={customerId} />
      <Form.Row>
        <Col>
          <Form.Label className="mb-0">CR</Form.Label>
          <Cr.Select
            disabled={crLoading || disabled}
            value={value}
            onChange={(v) => {
              const nextCustomerId = v?.customers?.[0].id;
              if (nextCustomerId) setCustomerId(nextCustomerId);
              onChange(v);
            }}
            isClearable
            searchButton={false}
            isLoading={crLoading}
            validateOnMount={validateOnMount}
            canFetch
            refreshActivityButton
            customerFilter={customerId}
          />
        </Col>
      </Form.Row>
      <Form.Row className="px-3 border-left border-secondary">
        <Col>
          <Form.Row>
            <Col>
              <Form.Label className="mb-0">Search</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Customer.Select
                value={customerId}
                formGroupClassName="mb-0"
                onChange={setCustomerId}
                isClearable
                isDisabled={crLoading || disabled}
              />
            </Col>
            <Col>
              <User.Select
                value={userId}
                formGroupClassName="mb-0"
                pseudo={true}
                onChange={setUserId}
                isClearable
                isDisabled={crLoading || disabled}
              />
            </Col>
            <Col xs="auto">
              <Button
                variant="light"
                title="Search for CR"
                onClick={() => setShowSearch(true)}
                disabled={crLoading || disabled}
              >
                <BiSearchAlt2 />
              </Button>
              <Modal
                show={showSearch}
                size="xl"
                onHide={() => setShowSearch(false)}
                className="modal2"
                backdropClassName="modal2-backdrop"
              >
                <Modal.Header closeButton>
                  <Modal.Title>CR Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Cr.Search
                    onSelect={(id) =>
                      getCr({ id, fetch: "auto" })
                        .then(({ data: cr }) => onChange(cr))
                        .finally(() => setShowSearch(false))
                    }
                    defaultCustomerId={customerId}
                    defaultOwnerId={userId}
                  />
                </Modal.Body>
              </Modal>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form.Group>
  );
}
