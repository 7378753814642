import React from "react";
import { Form } from "react-bootstrap";

import { useCustomer } from "./hooks";

export default function CustomerName({ id }) {
  const { customer } = useCustomer({ id });
  if (!customer) return <>{id}</>;

  return <>{customer.name}</>;
}

export function CustomerNameFormControl({ id }) {
  const { customer } = useCustomer({ id });
  const displayVal = !customer ? id : customer?.name;

  return <Form.Control key={id} value={displayVal} disabled={true} />;
}
