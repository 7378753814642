import { entitySelect } from "../../common/entity";
import { useNotificationTypes } from "./hooks";

const NotificationTypeSelect = entitySelect({
  name: "crNotificationType",
  useEntities: useNotificationTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default NotificationTypeSelect;
