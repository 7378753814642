import React, { useState } from "react";
import { ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";

import Page from "../../common/pages/Page";
import Customer from "../../customers";
import CustomerIndexNavSidebar from "./CustomerIndexNavSidebar";

export default function CustomerIndex() {
  const [active, setActive] = useState(true);
  const [modules, setModules] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const filter = {
    active: active,
    modules: modules,
    organizations: organizations,
  };

  return (
    <Page
      title="Customers"
      tableOfContents={false}
      navSidebar={<CustomerIndexNavSidebar />}
    >
      <Row>
        <Col xs={"auto"}>
          <ButtonGroup toggle>
            <ToggleButton
              key="activeTrue"
              type="radio"
              variant="secondary"
              value={true}
              checked={active === true}
              onChange={(e) => setActive(e.currentTarget.value === "true")}
            >
              Active
            </ToggleButton>
            <ToggleButton
              key="activeFals"
              type="radio"
              variant="secondary"
              value={false}
              checked={active === false}
              onChange={(e) => setActive(e.currentTarget.value !== "false")}
            >
              Inactive
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col style={{ minWidth: "200px", maxWidth: "400px" }} xs={"auto"}>
          {"Modules"}
          <Customer.Modules.Select
            value={modules}
            onChange={setModules}
            isMulti={true}
          />
        </Col>
        <Col style={{ minWidth: "250px", maxWidth: "400px" }} xs={"auto"}>
          {"Organizations"}
          <Customer.Organizations.Select
            value={organizations}
            onChange={setOrganizations}
            isMulti={true}
          />
        </Col>
      </Row>
      <Customer.Table filter={filter} initialSort={{ id: "name" }} />
    </Page>
  );
}
