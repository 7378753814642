import { useWatches } from "./hooks";
import state from "./state";
import WatchButton from "./WatchButton";

const Watch = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useAll: useWatches,
  // Components
  Button: WatchButton,
  // Related Entity Objects
};

export default Watch;
