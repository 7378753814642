import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: ({ customerId }) => {
    return shark
      .post({ command: "getCustomerContracts", customer: customerId })
      .then((r) => {
        return {
          data: r.responseData.customerContracts,
          searchArgs: { customerId },
        };
      });
  },
};

const entity = createEntity({
  name: "contract",
  type: "customers/contracts",
  getState: (state) => state.customers.contracts,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const contractState = {
  api,
  entity,
  actions,
  reducer,
};
export default contractState;
