import React, { useCallback } from "react";

import InfiniteList from "../common/lists/InfiniteList";
import useCrsByFilter from "./useCrsByFilter";

export default function CrList({ filter }) {
  const { crs, loading, lastUpdate, hasMore, getMore, reset } = useCrsByFilter({
    filter,
  });

  const onRefreshHandler = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <InfiniteList
      name="CRs"
      fields={["id", "synopsis"]}
      data={crs}
      link="/crs/"
      isLoading={loading}
      lastUpdate={lastUpdate}
      getMore={getMore}
      hasMore={hasMore}
      onRefresh={onRefreshHandler}
    />
  );
}
