import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";
import CustomerTableFilter from "../CustomerTableFilter";
import JiraLink from "./JiraProjectLink";
import { JIRA_PROJECT_TYPES } from "./types";

const DEFAULT_COMPONENTS = {
  Customer: ({ value }) => (
    <Link
      className="text-decoration-none"
      to={{ pathname: `/customers/${value}` }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </Link>
  ),
};

export default function JiraProjectTable({
  jiraProjects,
  jiraLink = true,
  Components: OverrideComponents,
  layout = [
    "description",
    "customer",
    "jiraKey",
    "openDefectCount",
    "openCustomCount",
    "closedDefectCount",
    "closedCustomCount",
  ],
  initialSort = { id: "jiraKey" },
  ...props
}) {
  const Components = useMemo(
    () => ({ ...DEFAULT_COMPONENTS, ...OverrideComponents }),
    [OverrideComponents],
  );

  const columns = useMemo(
    () => [
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: jira } }) =>
          jiraLink ? (
            <JiraLink id={jira?.id}>
              {value || jira?.description || jira?.id}
            </JiraLink>
          ) : (
            value
          ),
      },
      {
        id: "customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: Components.Customer,
      },

      {
        id: "jiraKey",
        Header: "JIRA Prefix",
        accessor: "jiraKey",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "openDefectCount",
        Header: "Open Defects",
        accessor: "openDefectCount",
        disableFilters: true,
      },
      {
        id: "openCustomCount",
        Header: "Open Custom",
        accessor: "openCustomCount",
        disableFilters: true,
      },
      {
        id: "closedDefectCount",
        Header: "Closed Defects",
        accessor: "closedDefectCount",
        disableFilters: true,
      },
      {
        id: "closedCustomCount",
        Header: "Closed Custom",
        accessor: "closedCustomCount",
        disableFilters: true,
      },
      {
        id: "techLead",
        Header: "Tech Lead",
        accessor: "techLead",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "projectType",
        Header: "Type",
        accessor: (jiraProject) =>
          JIRA_PROJECT_TYPES[jiraProject?.projectType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    [Components, jiraLink],
  );
  return (
    <Table
      hasCount
      label="Jira Projects"
      columns={columns}
      layout={layout}
      data={jiraProjects}
      initialSort={initialSort}
      {...props}
    />
  );
}
