import React from "react";
import { Button, Card, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import ExpenseCategorySelect from "../../expenses/categories/ExpenseCategorySelect";
import TaxProduct from "../../invoices/taxProducts";
import TypeSelect from "../../projects/types/TypeSelect";
import GroupSelect from "../../users/groups/GroupSelect";
import RealUserSelect from "../../users/RealUserSelect";
import ContractProductCategory from "../contractProductCategories";
import contractProductSchema from "./contractProductSchema";

export default function ContractProductEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: contractProductSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <ContractProductForm isNew={isNew} />
    </Forms>
  );
}

function ContractProductForm({ isNew }) {
  const { submitForm, watch } = useFormContext();

  const willCreateProject = watch("willCreateProject");
  const willCreateExpenseTrip = watch("willCreateExpenseTrip");

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto">
              <FormsField
                name="sortValue"
                label="Sort value"
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="description"
                label="Description"
                autoComplete="off"
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "30em" }}>
              <FormsField.Select
                name="contractProductCategory"
                label="Contract Product Category"
                as={ContractProductCategory.Select}
                isClearable={true}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "30em" }}>
              <FormsField.Select
                name="taxProduct"
                label="Tax Product"
                as={TaxProduct.Select}
                isClearable={true}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"}>
              <FormsField.Check
                name="isApplicableForCommission"
                label="Applicable for Commission?"
              />
            </Col>
          </Form.Row>

          <Form.Row>
            {!willCreateProject ? (
              <Col>
                <FormsField.Check
                  name="willCreateProject"
                  label="Create project?"
                />
              </Col>
            ) : (
              <Card className={"my-2"}>
                <Card.Header>
                  <FormsField.Check
                    name="willCreateProject"
                    label="Create project?"
                  />
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col xs={"auto"} style={{ minWidth: "200px" }}>
                      <FormsField.Select
                        name="projectGroup"
                        label="Project Group"
                        as={GroupSelect}
                      />
                    </Col>
                    <Col xs={"auto"} style={{ minWidth: "200px" }}>
                      <FormsField.Select
                        name="overrideProjectOwner"
                        label="Override Owner"
                        as={RealUserSelect}
                        isClearable={true}
                      />
                    </Col>
                    <Col xs={"auto"} style={{ minWidth: "200px" }}>
                      <FormsField.Select
                        name="projectType"
                        label="Project Type"
                        as={TypeSelect}
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={"auto"}>
                      <FormsField.Check
                        name="isFixedCostWithAllNonbillTime"
                        label="Fixed cost with all non-bill time?"
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={"auto"}>
                      <FormsField.Check
                        name="isPriorityIssue"
                        label="Priority Issue?"
                      />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
            )}
          </Form.Row>

          <Form.Row>
            {!willCreateExpenseTrip ? (
              <Col>
                <FormsField.Check
                  name="willCreateExpenseTrip"
                  label="Create Expense Trip?"
                />
              </Col>
            ) : (
              <Card className={"my-2"}>
                <Card.Header>
                  <FormsField.Check
                    name="willCreateExpenseTrip"
                    label="Create Expense Trip?"
                  />
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col xs={"auto"}>
                      <FormsField.Check
                        name="isExpenseTripBillable"
                        label="Is billable?"
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={"auto"} style={{ minWidth: "300px" }}>
                      <FormsField.Select
                        name="expenseTripGlCategory"
                        label="Gl Category"
                        as={ExpenseCategorySelect}
                      />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
            )}
          </Form.Row>
        </Col>
      </Form.Row>
      <Button
        className={"mt-2"}
        variant="success"
        onClick={() => {
          submitForm();
        }}
      >
        Submit
      </Button>
    </Form>
  );
}
