import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import CustomerLink from "../../customers/CustomerLink";
import CustomerTableFilter from "../../customers/CustomerTableFilter";
import TradingPartnerLink from "../tradingPartners/TradingPartnerLink";
import TradingPartnerTableFilter from "../tradingPartners/TradingPartnerTableFilter";

export default function CustomerEdiSetupTable({ customerEdiSetups, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Subscriber",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: (x) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/edi/customerEdiSetups/${x?.value}` }}
          >
            {x?.row?.original?.subscriber + " - " + x?.row?.original?.company}
          </Link>
        ),
      },
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },
      {
        id: "Trading Partner",
        Header: "Trading Partner",
        accessor: "tradingPartnerId",
        Filter: TradingPartnerTableFilter,
        filter: "text",
        Cell: ({ value }) => <TradingPartnerLink id={value} />,
      },
      {
        Header: "Notes",
        accessor: "notes",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Customer Edi Setups"
      columns={columns}
      data={customerEdiSetups}
      {...props}
    />
  );
}
