import shark from "../../apis/shark";

const getVersion = () => {
  const version = localStorage.getItem("version");
  const versionBuild = localStorage.getItem("versionBuild");
  return `${version}${
    versionBuild && versionBuild !== "master" ? "-" + versionBuild : ""
  }`;
};

export function loginApi({ userId, password }) {
  const payload = {
    command: "validateUser",
    userId,
    password,
    appVersion: getVersion(),
  };

  return shark.post(payload);
}

export function logoutApi() {
  const payload = {
    command: "logoutUser",
    appVersion: getVersion(),
  };

  return shark.post(payload);
}

export function checkTokenApi() {
  const payload = {
    command: "validateToken",
    appVersion: getVersion(),
  };

  return shark.post(payload);
}

export function resetPasswordApi({ userId }) {
  const payload = {
    command: "resetPassword",
    userId,
  };

  return shark.post(payload);
}
