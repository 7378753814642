import ContractEditor from "./ContractEditor";
import ContractLink from "./ContractLink";
import ContractProductCategory from "./contractProductCategories";
import ContractProduct from "./contractProducts";
import ContractProductDefaultCr from "./contractProductsDefaultCrs";
import Table from "./ContractTable";
import { useContract, useContracts, useContractsSearch } from "./hooks";
import state from "./state";

const Contract = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useContract,
  useAll: useContracts,
  useSearch: useContractsSearch,
  // Components
  Table,
  Link: ContractLink,
  Editor: ContractEditor,
  // Related Entity Objects
  ContractProduct: ContractProduct,
  ContractProductDefaultCr: ContractProductDefaultCr,
  ContractProductCategory: ContractProductCategory,
};

export default Contract;
