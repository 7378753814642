import React from "react";

import { entitySelect } from "../../common/entity";
import { _date } from "../../common/functions/dates";
import Customer from "../../customers";
import { useExpenseTripsBySearch } from "./hooks";

const CustomerLabel = (t) => (
  <>
    <Customer.Name id={t.customerId} /> -{" "}
    {`${t.description} (${_date.display(_date.fromStamp(t.date))})`}
  </>
);

const ExpenseTripSelect = entitySelect({
  name: "expenseTrip",
  useEntities: useExpenseTripsBySearch,
  getHookArgs: ({ search }) => ({
    canGet: !!search,
    search,
  }),
  label: CustomerLabel,
});

export default ExpenseTripSelect;
