import _ from "lodash";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { _datetime } from "../common/functions/dates";
import TimeForm from "./TimeForm";
import timeTypes from "./timeTypes";

export default function TimeNew({
  defaultUser,
  defaultDate,
  defaultCrId,
  onSaveSuccess,
  onCancel,
  startTimer = false,
}) {
  const timeType =
    defaultCrId != null
      ? timeTypes.cr.value
      : startTimer
      ? timeTypes.customer.value
      : null;
  const loggedInUser = useSelector((state) => state.auth.user);
  const defaultUser2 =
    _.isEmpty(defaultUser) || startTimer ? loggedInUser : defaultUser;

  let isToday = true;
  const rightNow = moment();

  if (defaultDate != null && defaultDate !== "" && startTimer === false)
    isToday = rightNow.isSame(defaultDate, "day");

  const defaultDate2 = isToday ? moment() : moment(defaultDate);
  let dbcStamp = _datetime.stamp(defaultDate2);

  const time = {
    id: null,
    datetime: dbcStamp,
    userId: defaultUser2,
    timeType: timeType,
    crId: defaultCrId,
    showOnInvoice: true,
    timers: [],
    invoiceNumber: "",
    modifyDateTime: "",
    createDateTime: "",
  };

  return (
    <TimeForm
      mode="new"
      time={time}
      onCancel={onCancel}
      onSaveSuccess={onSaveSuccess}
      startTimer={startTimer}
    />
  );
}
