import _ from "lodash";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

import FieldDate from "../common/fields/FieldDate";
import { _date } from "../common/functions/dates";
import Customer from "../customers";
import User from "../users";

export function ApprovalsFilters({
  championDefault,
  customerDefault,
  projectTypeDefault,
  billThruDateDefault,
  showOpenDefault,
  showApprovedDefault,
  projectOwnerDefault,
  onSubmit,
  disableAll = false,
}) {
  const [champion, setChampion] = useState(championDefault);
  const [customer, setCustomer] = useState(customerDefault);
  const [date, setDate] = useState(billThruDateDefault);
  const useCustomerValue = Customer.useOne({ id: customer });

  const [showOpen, setShowOpen] = useState(
    showOpenDefault !== undefined ? showOpenDefault : "Y",
  );
  const [showApproved, setShowApproved] = useState(
    showApprovedDefault !== undefined ? showApprovedDefault : "B",
  );
  const [projectType, setProjectType] = useState(
    projectTypeDefault !== undefined ? projectTypeDefault : "O",
  );
  const [projectOwner, setProjectOwner] = useState(
    projectOwnerDefault !== undefined ? projectOwnerDefault : null,
  );

  const onDateChange = (newDate) => {
    setDate(_date.stamp(newDate));
  };
  const onCustomerChange = (newCustomer) => {
    setCustomer(newCustomer);
  };
  const onChampionChange = (newChampion) => {
    setChampion(newChampion);
    setCustomer(null);
  };
  const onProjectTypeChange = (newValue) => {
    setProjectType(newValue.value);
  };
  const onProjectOwnerChange = (newValue) => {
    setProjectOwner(newValue);
  };
  const onShowApprovedChange = (newValue) => {
    setShowApproved(newValue.value);
  };
  const onShowOpenChange = (newValue) => {
    setShowOpen(newValue.value);
  };

  const projectTypeOptions = [];
  projectTypeOptions.push({ label: "Contract", value: "C" });
  projectTypeOptions.push({ label: "Trips", value: "T" });
  projectTypeOptions.push({ label: "All other", value: "O" });

  let currentProjectTypeValue = null;
  for (let i = 0; i < projectTypeOptions.length; i++) {
    if (projectTypeOptions[i].value === projectType) {
      currentProjectTypeValue = projectTypeOptions[i];
      break;
    }
  }

  const approvedStatusOptions = [];
  approvedStatusOptions.push({ label: "Only Approved", value: "O" });
  approvedStatusOptions.push({ label: "Only Unapproved", value: "U" });
  approvedStatusOptions.push({ label: "Approved and Unapproved", value: "B" });

  let currentShowApproved = null;
  for (let i = 0; i < approvedStatusOptions.length; i++) {
    if (approvedStatusOptions[i].value === showApproved) {
      currentShowApproved = approvedStatusOptions[i];
      break;
    }
  }

  const projectStatusOptions = [];
  projectStatusOptions.push({ label: "Closed only", value: "N" });
  projectStatusOptions.push({ label: "Open and closed", value: "Y" });

  let currentShowOpen = null;
  for (let i = 0; i < projectStatusOptions.length; i++) {
    if (projectStatusOptions[i].value === showOpen) {
      currentShowOpen = projectStatusOptions[i];
      break;
    }
  }

  // Need a customer if you say All other
  const disableSubmit =
    (_.isNil(customer) || customer === "") &&
    (_.isNil(champion) || champion === "") &&
    (_.isNil(projectOwner) || projectOwner === "") &&
    projectType === "O";

  const clickedSubmit = () => {
    const options = {};

    options.customer = _.isNil(customer) ? "" : customer;
    options.projectType = projectType;
    options.projectOwner = _.isNil(projectOwner) ? "" : projectOwner;
    options.champion = _.isNil(champion) ? "" : champion;
    options.showApproved = showApproved;
    options.showOpen = showOpen;
    options.billThruDate = date;

    let desc = "";

    if (projectType === "C") desc = "Contracts";
    else if (projectType === "T") desc = "Trips";
    else desc = "All other";

    if (_.isNil(customer) || customer === "") {
      if (!_.isNil(champion) || champion === "") {
        desc = "Champion " + champion + " " + desc;
      }
    } else {
      desc = useCustomerValue.customer.name + " " + desc;
    }
    onSubmit(options, desc);
  };

  return (
    <Form>
      <Row>
        <Col sm={4}>
          <Form.Label>Champion</Form.Label>
          <User.Select
            id="approvals-champion"
            value={champion}
            isChampion={true}
            onChange={(value) => onChampionChange(value)}
            isClearable
            isDisabled={disableAll}
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
          />
        </Col>
        <Col sm={4}>
          <Form.Label>Customer</Form.Label>
          <Customer.Select
            id="approvals-customer"
            champion={champion}
            value={customer}
            onChange={(value) => onCustomerChange(value)}
            isClearable
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            isDisabled={disableAll}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Label>Project Type</Form.Label>
          <Select
            className={"basic-single"}
            classNamePrefix="select"
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            id={"type"}
            name={"type"}
            value={currentProjectTypeValue}
            options={projectTypeOptions}
            onChange={onProjectTypeChange}
            isMulti={false}
            isDisabled={disableAll}
          />
        </Col>
        <Col sm={4}>
          <Form.Label>Project Owner</Form.Label>
          <User.Select
            id="approvals-projectOwner"
            value={projectOwner}
            onChange={(value) => onProjectOwnerChange(value)}
            pseudo={true}
            isClearable
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            isDisabled={disableAll}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={4}>
          <Form.Label>Approved Projects</Form.Label>
          <Select
            className={"basic-single"}
            classNamePrefix="select"
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            id={"type"}
            name={"type"}
            value={currentShowApproved}
            options={approvedStatusOptions}
            onChange={onShowApprovedChange}
            isMulti={false}
            isDisabled={disableAll}
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
          />
        </Col>
        <Col sm={4}>
          <Form.Label>Project status</Form.Label>
          <Select
            className={"basic-single"}
            classNamePrefix="select"
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            id={"status"}
            name={"status"}
            value={currentShowOpen}
            options={projectStatusOptions}
            onChange={onShowOpenChange}
            isMulti={false}
            isDisabled={disableAll}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          {"Bill thru date:"}{" "}
          <FieldDate
            onChange={onDateChange}
            value={date}
            disabled={disableAll}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <Button
            onClick={clickedSubmit}
            disabled={disableAll || disableSubmit}
          >
            Submit job
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default React.memo(ApprovalsFilters);
