import React from "react";

import { SelectColumnFilterWithOptions } from "../../common/tables/TableFilters";
import { useAwsAccounts } from "./hooks";

export default function AwsAccountTableFilter({ column }) {
  const { filterValue } = column;
  const { awsAccounts } = useAwsAccounts();
  const _options = awsAccounts
    .filter((s) => s.isInactive === false || s.id === filterValue)
    .map((f) => {
      return { label: f.description, value: f.id };
    });
  return <SelectColumnFilterWithOptions column={column} options={_options} />;
}
