import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import S3BucketEdit from "./S3BucketEdit";
import S3BucketIndex from "./S3BucketIndex";
import S3BucketNew from "./S3BucketNew";

export default function S3BucketsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <S3BucketNew />
      </Route>
      <Route path={`${path}/:id`}>
        <S3BucketEdit />
      </Route>
      <Route exact path={`${path}`}>
        <S3BucketIndex />
      </Route>
    </Switch>
  );
}
