import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function InvoicesNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/invoices">Invoices Home</NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/invoices/tax-product-categories">
        Tax Product Categories
      </NavSidebarItem>
      <NavSidebarItem to="/invoices/tax-product-categories/new">
        New Tax Product Category
      </NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/invoices/tax-products">Tax Products</NavSidebarItem>
      <NavSidebarItem to="/invoices/tax-products/new">
        New Tax Product
      </NavSidebarItem>
      <div className="dropdown-divider" />
      {children}
    </NavSidebar>
  );
}
