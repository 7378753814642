import _ from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Button, Col, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import CrLinkWithContextMenu from "../../common/components/CrLinkWithContextMenu";
import { _date, _datetime } from "../../common/functions/dates";
import { timerCalculation } from "../../common/functions/timerCalculation";
import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import Time from "../../time";
import TimeNavSidebar from "./TimeNavSidebar";

const TimeTableComponents = {
  CrLink: CrLinkWithContextMenu,
};

export default function TimeIndex() {
  const loggedInUser = useSelector((state) => state.auth.user);
  const paramsUser = useSearchParams("user");
  const paramsDate = useSearchParams("date");
  const showTimersParam = useSearchParams("showTimers");
  const user = _.isEmpty(paramsUser) ? loggedInUser : paramsUser;
  const date = moment(paramsDate);
  const history = useHistory();

  const { times, loading, getTimes } = Time.useAll({
    filter: {
      userId: user,
      date: paramsDate,
    },
  });

  const showTimers = showTimersParam === "true";
  const { getCurrentTimer } = Time.useCurrentTimer({
    canGet: true,
  });

  const onRefresh = useCallback(() => {
    getTimes();
    getCurrentTimer();
  }, [getCurrentTimer, getTimes]);

  function getUrl({ user, date, showTimers = false }) {
    return `/time?user=${user}&date=${date}&showTimers=${showTimers}`;
  }

  if (!date.isValid() || !paramsUser) {
    const parmsDate = date.isValid()
      ? _date.urlParams(date)
      : _date.urlParams(moment());
    return <Redirect to={getUrl({ user, date: parmsDate, showTimers })} />;
  }

  const totalHours = times.reduce(
    (total, row) => {
      //if (isNaN(parseFloat(row.values.totalHours))) {
      //  return sum;
      // } else {

      let hours = parseFloat(row.hours) + getRoundedHours(row.date, row.timers);
      if (row.isBillable) {
        return { bill: total.bill + hours, nonbill: total.nonbill };
      } else {
        return { bill: total.bill, nonbill: total.nonbill + hours };
      }

      // return hours;
    },
    { bill: 0, nonbill: 0 },
  );

  return (
    <Page
      title="Daily Time"
      navSidebar={<IndexNavSidebar date={date} onChange={onRefresh} />}
    >
      <Row>
        <Col>
          <Row>
            <Col>
              <Time.Filters
                user={user}
                date={date}
                showTimers={showTimers}
                onRefresh={onRefresh}
              />
            </Col>
          </Row>
          <Tab.Container activeKey={showTimers ? "timers" : "time"}>
            <Row className="justify-content-between align-items-end py-2">
              <Col className="d-flex" xs={3}>
                <Button
                  className="rounded-0"
                  onClick={() =>
                    history.push(
                      getUrl({
                        user,
                        date: paramsDate,
                        showTimers: false,
                      }),
                    )
                  }
                  variant={showTimers ? "secondary" : "primary"}
                >
                  Time
                </Button>
                <Button
                  className="rounded-0"
                  onClick={() =>
                    history.push(
                      getUrl({
                        user,
                        date: paramsDate,
                        showTimers: true,
                      }),
                    )
                  }
                  variant={showTimers ? "primary" : "secondary"}
                >
                  Timers
                </Button>
              </Col>

              <Col className="d-flex" xs={9}>
                <Button variant="secondary" className="rounded-0" disabled>
                  {"Total hours: " +
                    parseFloat(totalHours.bill + totalHours.nonbill).toFixed(2)}
                </Button>
                <Button variant="success" className="rounded-0" disabled>
                  {"Billable hours: " + parseFloat(totalHours.bill).toFixed(2)}
                </Button>
                <Button variant="danger" className="rounded-0" disabled>
                  {"Nonbill hours: " +
                    parseFloat(totalHours.nonbill).toFixed(2)}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="time">
                    <Time.Table
                      times={times}
                      layout={[
                        "project",
                        "cr",
                        "customer",
                        "billingNote",
                        "isBillable",
                        "totalHours",
                        "nonTimerHours",
                        "timer",
                      ]}
                      isLoading={loading}
                      onRefresh={onRefresh}
                      Components={TimeTableComponents}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="timers">
                    <Time.TimersTable
                      times={times}
                      layout={[
                        "project",
                        "cr",
                        "customer",
                        "billingNote",
                        "startTime",
                        "endTime",
                        "duration",
                      ]}
                      isLoading={loading}
                      onRefresh={onRefresh}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Page>
  );
}

function IndexNavSidebar({ date, onChange }) {
  const [startTimer, setStartTimer] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const hideNewModal = useCallback(() => setShowNewModal(false), []);

  const timer = Time.useTimer();

  const [ModifyModal, modifyModelProps, setModifyShow] = Time.useModifyModal({
    handleChange: () => {
      setModifyShow();
      onChange();
    },
  });

  /*
  useEffect(() => {
    if (stoppingTimer) {
      dispatch(currentTimerActions.stopCurrentTimer({}))
        .then(unwrapResult)
        .then(result => {
          setStoppingTimer(false);
          //onSaveSuccess(result);
        })
        .catch(result => {
          setStoppingTimer(false);
        });
    }
  }, [dispatch, stoppingTimer]);
  */

  return (
    <TimeNavSidebar>
      <div className="dropdown-divider" />
      <Row>
        <Col className="p-1">
          <Button
            variant="primary"
            className="text-decoration-none px-0"
            block
            onClick={() => {
              setStartTimer(false);
              setShowNewModal(true);
            }}
          >
            New Time Entry
          </Button>
        </Col>
      </Row>

      {timer.current.status !== "running" ? (
        <Row>
          <Col className="p-1">
            <Button
              className="text-decoration-none px-0"
              variant="success"
              block
              onClick={() => {
                setStartTimer(true);
                setShowNewModal(true);
              }}
            >
              Start Timer (New)
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="p-1">
            <Button
              className="text-decoration-none px-0"
              onClick={() => setModifyShow(timer.current.id, true)}
              variant="danger"
              block
            >
              <Row>
                <Col>{"Stop timer"}</Col>
              </Row>
              <Row>
                <Col>
                  {"Started on: " +
                    _datetime.displayTime(timer.current.startTime)}
                </Col>
              </Row>
              <Row>
                <Col>{timer.current.timerDescription}</Col>
              </Row>
            </Button>
          </Col>
        </Row>
      )}
      {timer.current.status === "stopped" ? (
        <Row>
          <Col className="p-1">
            <Button
              className="danger text-decoration-none px-0"
              block
              onClick={() => timer.restart(timer.current.id)}
              variant="warning"
            >
              <Row>
                <Col>{"Restart last timer:"}</Col>
              </Row>
              <Row>
                <Col>
                  {"Ended: " + _datetime.displayTime(timer.current.endTime)}
                </Col>
              </Row>
              <Row>
                <Col>{timer.current.timerDescription}</Col>
              </Row>
            </Button>
          </Col>
        </Row>
      ) : null}
      <Time.NewModal
        show={showNewModal}
        onHide={hideNewModal}
        date={date}
        startTimer={startTimer}
        onSave={() => {
          hideNewModal();
          onChange();
        }}
        onCancel={hideNewModal}
      />
      <ModifyModal {...modifyModelProps} />
    </TimeNavSidebar>
  );
}

function getRoundedHours(dbcDate, timers) {
  const momentDate = _date.fromStamp(dbcDate);
  return timerCalculation(momentDate, timers).roundedHours;
}
