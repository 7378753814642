import _ from "lodash";
import React from "react";

import FieldSelect from "./FieldSelect";

export default function FieldYesNoSelect({
  value,
  loading = false,
  placeholder = "",
  ...props
}) {
  const _options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  let valueOption = null;
  if (!_.isNil(value)) valueOption = _.find(_options, ["value", value]);

  return (
    <FieldSelect
      isLoading={loading}
      value={valueOption}
      options={_options}
      isMulti={false}
      placeholder={placeholder}
      {...props}
    />
  );
}
