import CustomerEdiSetup from "./customerEdiSetups";
import FtpUser from "./ftpUsers";
import TradingPartner from "./tradingPartners";

const Edi = {
  // Related Entity Objects
  TradingPartner: TradingPartner,
  CustomerEdiSetup: CustomerEdiSetup,
  FtpUser: FtpUser,
};

export default Edi;
