import { createEntityHooks } from "../../common/entity";
import tripState from "./state";

export const {
  useEntities: useExpenseTrips,
  useEntitiesBySearch: useExpenseTripsBySearch,
  useEntity: useExpenseTrip,
} = createEntityHooks({
  entity: tripState.entity,
  actions: tripState.actions,
});
