import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Page from "../../../common/pages/Page";
import ReservationComparisonTable from "../../../servers/reservations/ReservationComparisonTable";
import { getReservationComparisonAPI } from "../../../servers/reservations/state/api";
import ServerNavSidebar from "../ServerNavSidebar";

export default function ReservationComparison() {
  const { isAdmin, canMaintainServers } = useSelector((state) => state.auth);

  if (!isAdmin && !canMaintainServers)
    return (
      <Page
        title="Reservation"
        navSidebar={<ServerNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return <AllowedPage />;
}

function AllowedPage() {
  const [data, setData] = useState([]);
  const [refreshCtr, setRefreshCtr] = useState(1);
  const [loading, setLoading] = useState(false);

  const runComparison = useCallback(() => {
    setLoading(true);
    getReservationComparisonAPI()
      .then(({ data: data }) => {
        setData(data.comparisons);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    runComparison();
  }, [runComparison, refreshCtr]);

  return (
    <Page title={"Reservation Comparisons"} navSidebar={<ServerNavSidebar />}>
      <ReservationComparisonTable
        comparisons={data}
        isLoading={loading}
        onRefresh={() => setRefreshCtr(refreshCtr + 1)}
      />
    </Page>
  );
}
