import { bool, object, string } from "yup";

const s3BucketSchema = object().shape({
  id: string().label("Id").required().max(63),
  customerId: string().label("Customer").required(),
  awsAccount: string().label("Account").max(20),
  awsRegion: string().label("Region").max(19),
  isInactive: bool().required().default(false),
});

export default s3BucketSchema;
