import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Contract from "../../../contracts";
import ContractNavSidebar from "../ContractNavSidebar";
import ContractProductDefaultCrEdit from "./ContractProductDefaultCrEdit";

export default function ContractProductDefaultCrEditPage() {
  const id = String(useParams().id).toUpperCase();
  const { contractProductDefaultCr, loading, updateOne } =
    Contract.ContractProductDefaultCr.useOne({
      id,
      canGet: true,
    });
  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Page
        title="Contract Product Default Cr"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  return (
    <Page
      title="Contract Product Default Cr"
      navSidebar={<ContractNavSidebar />}
    >
      <ContractProductDefaultCrEdit
        contractProductDefaultCr={contractProductDefaultCr}
        loading={loading}
        onSubmit={(values) => {
          updateOne(values);
        }}
      />
    </Page>
  );
}
