import { find, groupBy } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { createFilter } from "react-select";

import FieldSelect from "../../common/fields/FieldSelect";
import Favorite from "./favorites";
import { useFilters } from "./hooks";

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: ({ data }) => {
    return `${data.label} ${data.groupDescription}`;
  },
  trim: true,
});

export default function CrFilterSelect({
  value,
  placeholder = "Select CR Filter...",
  ...props
}) {
  const { filters = [], loading } = useFilters();
  const { favorites } = Favorite.useAll();
  const user = useSelector((state) => state.auth.user);

  const { options, optionsFlat } = useMemo(() => {
    const userFilters = filters.filter(
      (f) => f.public || !f.owner || f.owner === user,
    );
    const { isOwner = [], isPublic } = groupBy(userFilters, (f) =>
      f.owner === user ? "isOwner" : "isPublic",
    );

    const isFavorite = favorites?.length
      ? userFilters.filter((f) =>
          favorites.map((f) => f.crFilterId).includes(f.id),
        )
      : [];

    const mapOptions = (a, groupDescription) =>
      a.map((f) => ({
        label: f.id + ": " + f.description,
        value: f.id,
        groupDescription,
      }));

    const options = [
      { label: "Favorites", options: mapOptions(isFavorite, "Favorites") },
      { label: "Owner", options: mapOptions(isOwner, "Owner") },
      { label: "Public", options: mapOptions(isPublic, "Public") },
    ];

    return {
      options,
      optionsFlat: options.reduce((flat, g) => g.options.concat(flat), []),
    };
  }, [filters, user, favorites]);
  const valueOption = value ? find(optionsFlat, ["value", value]) : null;

  return (
    <FieldSelect
      isLoading={loading}
      value={valueOption}
      options={options}
      placeholder={placeholder}
      filterOption={filterOption}
      {...props}
    />
  );
}
