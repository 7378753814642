import axios from "axios";

import { getToken } from "../auth/state/token";
import isDev from "../common/isDev";

export const SHARK_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : !isDev()
  ? "https://shark.producepro.com/services"
  : "https://shark-test.producepro.com/services";

const apiClient = axios.create({
  baseURL: SHARK_URL,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
  },
});

const endPoint = "/services";

const apiData = {
  appId: "T3",
};

export default {
  post(data, { unimplemented = false, config = {} } = {}) {
    const logData = "password" in data ? { ...data, password: "" } : data;
    console.log(logData);
    const command = data.command;
    if (unimplemented) {
      return Promise.reject(`API marked Un-Implemented (${command})`);
    }
    return apiClient
      .post(endPoint, { ...apiData, ...data, authToken: getToken() }, config)
      .then((response) => {
        console.log(response.data);
        if (
          response.data.response === "FAIL" ||
          response.data.response === "ERROR"
        ) {
          return Promise.reject(response.data.responseMessage);
        } else if (response.data.response === "TIMEOUT") {
          return Promise.reject(`Request timed out (${command})`);
        } else if (response.data.responseFor !== command) {
          return Promise.reject("Command response mismatch");
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error, logData);
        const message = error?.response?.data?.responseMessage;
        return Promise.reject(message ? new Error(message) : error);
      });
  },
};
