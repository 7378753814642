import { entitySelect } from "../../common/entity";
import { useEstimateStatuses } from "./hooks";

const EstimateStatusSelect = entitySelect({
  name: "estimateStatus",
  useEntities: useEstimateStatuses,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default EstimateStatusSelect;
