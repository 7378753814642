import shark from "../../apis/shark";

export function approveProjectAPI({
  projectId,
  jobId,
  date,
  action,
  customerId,
}) {
  const payload = {
    command: "approveProject",
    date: date,
    action: action,
    jobId: jobId,
    projectId: projectId,
    customerId: customerId,
  };
  return shark.post(payload);
}

export function approveProjectsAPI({
  projects,
  jobId,
  date,
  action,
  customerId,
}) {
  const payload = {
    command: "approveProjects",
    date: date,
    action: action,
    jobId: jobId,
    projects: projects,
    customerId: customerId,
  };
  return shark.post(payload);
}

export function discountProjectAPI({ projectId, jobId, amount, comment }) {
  const payload = {
    command: "discountProject",
    jobId: jobId,
    projectId: projectId,
    amount: amount,
    comment: comment,
  };
  return shark.post(payload);
}

export function refreshProjectsAPI({ jobId, customers, billThruDate }) {
  const payload = {
    command: "refreshApprovalProjects",
    jobId: jobId,
    customers: customers,
    billThruDate: billThruDate,
  };
  return shark.post(payload);
}
