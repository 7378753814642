import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import AwsRegion from "../../../servers/awsRegions";
import AwsRegionNavSidebar from "./AwsRegionNavSidebar";

export default function AwsRegionNew() {
  const { createOne } = AwsRegion.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title="Aws Region" navSidebar={<AwsRegionNavSidebar />}>
      <AwsRegion.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(awsRegion) =>
          createOne(awsRegion).then(({ id }) =>
            setRedirect(`/servers/aws-regions/${id}`),
          )
        }
      />
    </Page>
  );
}
