import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

import CustomerName from "./CustomerName";

export default function CustomerChangedAlert({
  current: currentCustomer,
  next: nextCustomer,
}) {
  if (currentCustomer && nextCustomer && currentCustomer !== nextCustomer)
    return (
      <div>
        <Alert variant="warning" className="text-center">
          <Row>
            <Col>
              <strong>Warning: Customer does not match current project</strong>
            </Col>
          </Row>

          <Row>
            <Col>
              Current: <CustomerName id={currentCustomer} />
            </Col>
          </Row>
          <Row>
            <Col>
              New: <CustomerName id={nextCustomer} />
            </Col>
          </Row>
        </Alert>
      </div>
    );
  else return null;
}
