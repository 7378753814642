import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../common/pages/Page";
import Expense from "../../expenses";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

const useIdParam = () => parseInt(useParams().id);

export default function ExpenseCustomerEntry() {
  const customerId = useIdParam();
  const user = useSelector((state) => state.auth.user);
  const { expenses, loading, lastUpdate, reset } = Expense.useSearch({
    canGet: !!customerId,
    search: { userId: user, customerId: customerId, status: "O", mode: "C" },
  });

  return (
    <Page title={`Customer Expenses`} navSidebar={<ExpenseIndexNavSidebar />}>
      <Row>
        <Col>
          <Expense.NewButton
            onCreate={reset}
            initialValues={{ customerId: customerId }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Expense.Table
            expenses={expenses}
            isLoading={loading}
            layout={[
              "edit",
              "date",
              "expenseType",
              "overrideDescription",
              "note",
              "amount",
              "reimburseAmount",
              "billCustomerAmount",
              "paymentType",
              "overrideBillableFlag",
              "mileage",
            ]}
            onRefresh={reset}
            refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
          />
        </Col>
      </Row>
    </Page>
  );
}
