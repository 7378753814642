import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import NotificationGroupsPages from "./notification-groups";
import PseudosPages from "./pseudos";
import UserGroupingsPages from "./userGroupings";
import UserIndex from "./UserIndex";
import UserShow from "./UserShow";

export default function UsersRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/notification-groups`}>
        <NotificationGroupsPages />
      </Route>
      <Route path={`${path}/user-groupings`}>
        <UserGroupingsPages />
      </Route>
      <Route path={`${path}/pseudos`}>
        <PseudosPages />
      </Route>
      <Route path={`${path}/:id`}>
        <UserShow />
      </Route>
      <Route exact path={`${path}`}>
        <UserIndex />
      </Route>
    </Switch>
  );
}
