import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

import { _date, _datetime } from "../functions/dates";
import useReinitState from "../hooks/useReinitState";

const FieldDate = React.forwardRef(
  (
    {
      onChange,
      value: initialValue = null,
      includeTime = false,
      timeFormat = "HH:mm",
      timeIntervals = 15,
      timeCaption = "time",
      dateOnlyFormat = "MM/dd/yyyy",
      dateAndTimeFormat = "MM/dd/yyyy h:mm aa",
      disabled = false,
      noDateChange = false,
      includeArrows = true,
      label,
      labelClassName = "mb-2",
      minDate,
      maxDate,
      includeNow = true,
      onBlur = () => {},
      ...props
    },
    ref,
  ) => {
    const effectiveDateFormat = includeTime
      ? dateAndTimeFormat
      : dateOnlyFormat;

    const [value, setValue] = useReinitState(initialValue);

    function handleOnBlur() {
      const _value = moment(value);
      if (value !== initialValue && !_value.isSame(moment(initialValue)))
        onChange(value && moment(value));
    }

    const rightNowDesc = includeTime ? "Now" : "Today";

    const _label = _.isUndefined(label)
      ? includeTime
        ? _datetime.displayLongDateTime(value)
        : _date.displayLong(value)
      : label;

    const disableSubtract =
      minDate === undefined
        ? false
        : moment(value).isSameOrBefore(moment(minDate), "day");

    const disableAdd =
      maxDate === undefined
        ? false
        : moment(value).isSameOrAfter(moment(maxDate), "day");

    return (
      <>
        {_.isNull(_label) ? null : (
          <Form.Label className={labelClassName}>{_label}</Form.Label>
        )}
        <InputGroup className="pb-0">
          {includeArrows ? (
            <InputGroup.Prepend>
              <Button
                variant="outline-primary"
                className="px-3"
                disabled={disabled || noDateChange || disableSubtract}
                onClick={() => {
                  if (value === null) {
                    if (dateOnlyFormat === "yyyy") {
                      onChange(moment().startOf("year"));
                    } else if (dateOnlyFormat === "MM/yyyy") {
                      onChange(moment().startOf("month"));
                    } else {
                      onChange(moment());
                    }
                  } else {
                    if (dateOnlyFormat === "yyyy") {
                      onChange(moment(value).subtract(1, "years"));
                    } else if (dateOnlyFormat === "MM/yyyy") {
                      onChange(moment(value).subtract(1, "months"));
                    } else {
                      onChange(moment(value).subtract(1, "days"));
                    }
                  }
                }}
              >
                <BsArrowLeftShort />
              </Button>
              <Button
                variant="outline-primary"
                className="px-3"
                disabled={disabled || noDateChange || disableAdd}
                onClick={() => {
                  if (value === null) {
                    if (dateOnlyFormat === "yyyy") {
                      onChange(moment().startOf("year"));
                    } else if (dateOnlyFormat === "MM/yyyy") {
                      onChange(moment().startOf("month"));
                    } else {
                      onChange(moment());
                    }
                  } else {
                    if (dateOnlyFormat === "yyyy") {
                      onChange(moment(value).add(1, "years"));
                    } else if (dateOnlyFormat === "MM/yyyy") {
                      onChange(moment(value).add(1, "months"));
                    } else {
                      onChange(moment(value).add(1, "days"));
                    }
                  }
                }}
              >
                <BsArrowRightShort />
              </Button>
            </InputGroup.Prepend>
          ) : null}
          <DatePicker
            ref={ref}
            className="form-control"
            selected={value && moment(value).toDate()}
            onSelect={(value) => {
              onChange(value && moment(value));
            }}
            onChange={(newDate) => {
              setValue(newDate && moment(newDate));
              if (includeTime) onChange(newDate && moment(newDate));
            }}
            onBlur={(e) => {
              handleOnBlur();
              onBlur(e);
            }}
            dateFormat={effectiveDateFormat}
            includeTime={includeTime}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            showTimeSelect={includeTime}
            autoComplete={undefined}
            disabled={disabled}
            minDate={
              noDateChange
                ? moment(value).toDate()
                : minDate === undefined
                ? null
                : moment(minDate).toDate()
            }
            maxDate={
              noDateChange
                ? moment(value).toDate()
                : maxDate === undefined
                ? null
                : moment(maxDate).toDate()
            }
            {...props}
          />
          {includeNow ? (
            <InputGroup.Append>
              <Button
                variant="primary"
                className="px-3"
                onClick={() => onChange(moment())}
                disabled={disabled || noDateChange}
              >
                {rightNowDesc}
              </Button>
            </InputGroup.Append>
          ) : (
            <></>
          )}
        </InputGroup>
      </>
    );
  },
);
export default FieldDate;
