import shark from "../../../apis/shark";

function getAwsRegionsAPI() {
  const payload = {
    command: "getAwsRegions",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.awsRegions,
  }));
}

function getAwsRegionAPI({ id }) {
  const payload = {
    command: "getAwsRegion",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createAwsRegionAPI(awsRegion) {
  const payload = {
    command: "createAwsRegion",
    ...awsRegion,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateAwsRegionAPI(awsRegion) {
  const payload = {
    command: "updateAwsRegion",
    ...awsRegion,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getAwsRegionsAPI,
  getOne: getAwsRegionAPI,
  createOne: createAwsRegionAPI,
  updateOne: updateAwsRegionAPI,
};
