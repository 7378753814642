import { number, object, string } from "yup";

const hostingAgreementSchema = object().shape({
  customerId: number().label("Customer").required(),
  serverType: string().label("Server Type").required().max(1),
  billingDescription: string().label("Billing Description").max(220),
  information: string().label("Information").max(500),
  currentMonthlyPrice: number().label("Current $").min(0),
  maxPrice: number().label("Max price $").min(0),
  originalMonthlyPrice: number().label("Original $").min(0),
  effectiveMonth: string().label("Effective Month").required(),
  awsZone: string().label("Zone"),
  awsRegion: string().label("Region"),
  s3Bucket: string().label("S3 Bucket").max(63),
  awsInstanceType: string().label("Instance Type"),
  awsInstanceId: string().label("Instance Id").max(20),
  diskSpace: string().label("Disk space").max(30),
});

export default hostingAgreementSchema;
