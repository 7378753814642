import DifficultyDescription from "./DifficultyDescription";
import DifficultySelect from "./DifficultySelect";
import DifficultyTableFilter from "./DifficultyTableFilter";
import { useDifficulties, useDifficulty } from "./hooks";
import difficultyState from "./state";

const Difficulty = {
  // Backend API
  api: difficultyState.api,
  // State
  actions: difficultyState.actions,
  selectors: difficultyState.entity.selectors,
  // Hooks
  useAll: useDifficulties,
  useOne: useDifficulty,
  // Components
  Select: DifficultySelect,
  Description: DifficultyDescription,
  TableFilter: DifficultyTableFilter,
};
export default Difficulty;
