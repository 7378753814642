import { entitySelect } from "../../common/entity";
import { useAwsZones } from "./hooks";

const AwsZoneSelect = entitySelect({
  name: "awsZone",
  useEntities: useAwsZones,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.id + "(" + e.regionDescription + ")",
});

export default AwsZoneSelect;
