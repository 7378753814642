import shark from "../../apis/shark";

function getCodeReviewsAPI(filters) {
  const payload = {
    command: "getCodeReviews",
    ...filters,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.codeReviews,
    searchArgs: filters,
  }));
}

function getCodeReviewAPI({ id }) {
  const payload = {
    command: "getCodeReview",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function updateCodeReviewAPI(codereview) {
  const payload = {
    command: "updateCodeReview",
    ...codereview,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getCodeReviewsAPI,
  getOne: getCodeReviewAPI,
  updateOne: updateCodeReviewAPI,
};
