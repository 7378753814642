import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import ServerNavSidebar from "../ServerNavSidebar";

export default function ReservationEdit() {
  const id = String(useParams().id);
  const { reservation, loading, updateOne } = Server.Reservation.useOne({
    id,
    canGet: true,
  });
  const { isAdmin, canMaintainServers } = useSelector((state) => state.auth);

  if (!isAdmin && !canMaintainServers)
    return (
      <Page title="Reservation" lead="You do not have access to this feature" />
    );

  return (
    <Page title={"Reservation"} navSidebar={<ServerNavSidebar />}>
      {reservation ? (
        <>
          <Server.Reservation.Editor
            values={reservation}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
            isDisabled={true}
          />
          <StampDisplay record={reservation} />
        </>
      ) : (
        <h4>Reservation not found.</h4>
      )}
    </Page>
  );
}
