import _ from "lodash";
import React, { useMemo } from "react";

import FieldSelect from "../fields/FieldSelect";

export default function TableColumnSelect({
  columns,
  isMulti,
  value,
  shouldValuesSortLikeOptions = false,
  ...props
}) {
  const options = useMemo(
    () => columns.map((c) => ({ value: c.id, label: c.Header || c.id })),
    [columns],
  );
  let valueOption = null;
  if (value)
    if (isMulti) {
      valueOption = shouldValuesSortLikeOptions
        ? options.filter((o) => value.includes(o.value))
        : value.map((v) => _.find(options, (o) => o.value === v));
    } else {
      valueOption = _.find(options, ["value", value]);
    }
  return (
    <FieldSelect
      value={valueOption}
      options={options}
      isMulti={isMulti}
      {...props}
    />
  );
}
