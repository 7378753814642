import { bool, number, object, string } from "yup";

const serverSchema = object().shape({
  description: string().label("Description").required().max(40),
  customerId: number().label("Customer").required(),
  isInactive: bool().required().default(false),
  isNewInstall: bool().required().default(false),
  projectCreateWarning: bool().required().default(false),
  salesperson: string().label("Salesperson"),
  totalContractAmount: number().label("Total contract amount").max(99999999.99),
  blendedRate: number().label("Blended Rate").max(9999.99),
  blendedTravelRate: number().label("Blended Travel Rate").max(9999.99),
});

export default serverSchema;
