export default function getProjectPriorityClassName(project) {
  if (!project) return "";
  const { statusId, priorityId } = project;

  if (statusId === "CL" || statusId === "FX") return "";

  if (priorityId === "EM" || priorityId === "EF") return "table-dark";
  else if (priorityId === "CR") return "bg-danger";
  else if (priorityId === "PR" || priorityId === "IM") return "table-danger";
  else return "";
}
