import React from "react";
import { Link } from "react-router-dom";

export default function PseudoUserLink({ id, children }) {
  return (
    <Link className="text-reset" to={{ pathname: `/users/pseudos/${id}` }}>
      {children || id}
    </Link>
  );
}
