import _, { groupBy } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Col, Form, ListGroup, Row, Table as BSTable } from "react-bootstrap";
import { useParams } from "react-router";

import Address from "../../common/details/Address";
import DetailField from "../../common/details/DetailField";
import DetailSection from "../../common/details/DetailSection";
import DetailTable from "../../common/details/DetailTable";
import Tel from "../../common/details/Tel";
import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import Contact from "../../contacts";
import Customer from "../../customers";
import User from "../../users";
import CustomerShowNavSidebar from "./CustomerShowNavSidebar";

export default function CustomerShow() {
  const id = parseInt(useParams().id);
  const { customer, loading } = Customer.useOne({ id, canGet: true });
  const _loading = loading && _.isEmpty(customer);

  const sections = [
    <DetailSection title="Overview" key="overview-section">
      <Details customer={customer} isLoading={_loading} />
    </DetailSection>,
    <DetailSection title="Comments" key="work-conditions-section">
      <Customer.SpecialWorkConditions customers={[id]} isLoading={_loading} />
      <p>{customer?.comment}</p>
    </DetailSection>,
    <DetailSection title="Contacts" key="contacts-section">
      <Contact.TableByCustomer customerId={id} />
    </DetailSection>,
    <DetailSection title="Locations" key="location-section">
      <CustomerLocations
        locations={customer?.locations}
        locationsInfo={customer?.locationsInfo}
        isLoading={_loading}
      />
    </DetailSection>,
    <DetailSection title="PPro License" key="ppro-license-section">
      <CustomerLicense license={customer?.license} isLoading={_loading} />
    </DetailSection>,
    <DetailSection title="Modules & Apps" key="modules-section">
      <Row>
        <Col lg={12} xl={6}>
          <DetailField header="PPro Modules">
            <CustomerModules modules={customer?.modules} isLoading={_loading} />
          </DetailField>
        </Col>
        <Col lg={12} xl={6}>
          <DetailField header="Third Party Apps">
            <ListGroup>
              {customer?.thirdPartyApps?.map((a, idx) => (
                <ListGroup.Item key={`locations-${idx}`}>
                  {a.description}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </DetailField>
        </Col>
      </Row>
    </DetailSection>,
    <DetailSection title="Upgrade/Code Dates" key="dates-section">
      <Row>
        <Col lg={12} xl={6}>
          <DetailField header="Upgrade Dates">
            <CustomerUpgradeDates
              upgradeDates={customer?.upgradeDates}
              isLoading={_loading}
            />
          </DetailField>
        </Col>
        <Col lg={12} xl={6}>
          <DetailField header="Code Dates">
            <CustomerCodeDates
              codeDates={customer?.codeDates}
              isLoading={_loading}
            />
          </DetailField>
        </Col>
      </Row>
    </DetailSection>,
    <DetailSection title="Logins" key="logins-section">
      <CustomerLogins logins={customer?.logins} isLoading={_loading} />
    </DetailSection>,
    <DetailSection title="Accounting" key="accounting-section">
      <CustomerAccounting
        travelInformation={customer?.travelInformation}
        isPoRequired={customer?.isPoRequired}
      />
    </DetailSection>,
  ];

  return (
    <Page
      title={customer?.name ? customer.name : "Customer"}
      navSidebar={<CustomerShowNavSidebar id={id} />}
    >
      {sections}
    </Page>
  );
}

function isValidUrl(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
}

function Details({ customer, isLoading }) {
  const today = useMemo(() => moment(), []);
  const warranty = _date.fromStamp(customer?.warrantyExpirationDate);
  return (
    <Row>
      <Col sm={6} lg="auto">
        <DetailField header="Contact Information">
          <DetailTable
            fields={[
              {
                header: "Phone",
                value: <Tel phone={customer?.phone} />,
              },
              {
                value: <Tel phone={customer?.phone2} />,
                hide: !customer?.phone2,
              },
              {
                header: "Fax",
                value: customer?.fax,
              },
              {
                header: "Website",
                value: isValidUrl(customer?.website) ? (
                  <a
                    href={new URL(customer?.website)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {customer?.website}
                  </a>
                ) : (
                  customer?.website
                ),
              },
              {
                header: "IP Address",
                value: customer?.ipAddress,
              },
            ]}
            isLoading={isLoading}
          />
        </DetailField>
      </Col>
      <Col sm={6} lg={3}>
        <DetailField header="Business Types">
          <ul className="list-unstyled">
            {customer?.businessTypes?.map((t, idx) => (
              <li key={`businessType-${idx}`} className="pl-3">
                {t.description}
              </li>
            ))}
          </ul>
        </DetailField>
        <DetailField header="Produce Organizations">
          <ul className="list-unstyled">
            {customer?.produceOrganizations?.map((t, idx) => (
              <li key={`produceOrganization-${idx}`} className="pl-3">
                {t.description}
              </li>
            ))}
          </ul>
        </DetailField>
      </Col>
      <Col sm={6} lg={3} className="pb-0">
        <DetailField header="Dates" isLoading={isLoading}>
          <DetailTable
            className="mb-0"
            fields={[
              {
                header: "Live",
                value: _date.display(_date.fromStamp(customer?.liveDate)),
              },
              {
                header: "Warranty",
                rowProps: {
                  className:
                    customer?.warrantyExpirationDate && warranty < today
                      ? "table-danger"
                      : "",
                },
                value: _date.display(
                  _date.fromStamp(customer?.warrantyExpirationDate),
                ),
              },
              {
                header: "Upgrade",
                value: _date.display(
                  _date.fromStamp(customer?.lastUpgradeDate),
                ),
                hide: !customer?.lastUpgradeDate,
              },
              {
                header: "Code",
                value: _date.display(_date.fromStamp(customer?.lastCodeDate)),
              },
              {
                header: "Upgrade*",
                value: _date.display(
                  _date.fromStamp(customer?.futureUpgradeDate),
                ),
                rowProps: { className: "table-info" },
                hide: !customer?.futureUpgradeDate,
              },
              {
                header: "Code*",
                value: _date.display(_date.fromStamp(customer?.futureCodeDate)),
                rowProps: { className: "table-info" },
                hide: !customer?.futureUpgradeDate,
              },
            ]}
            isLoading={isLoading}
          />
        </DetailField>
        {customer?.futureUpgradeDate ? (
          <Form.Text className="text-right">* future pending</Form.Text>
        ) : null}
      </Col>
      <Col sm={6} lg={3}>
        <DetailTable
          fields={[
            {
              header: "Status",
              value: customer?.inactive ? "Inactive" : "Active",
            },
            {
              header: "Champion(s)",
              value: (
                <>
                  <p className="mb-0">
                    <User.Link id={customer?.championId} />
                  </p>
                  {customer?.otherChampions?.map((c, idx) => (
                    <p key={`champion-${idx}`} className="mb-0">
                      <User.Link id={c?.userId} />
                    </p>
                  ))}
                </>
              ),
            },
            {
              header: "Engineering",
              value: customer?.engineeringResponsibilty,
            },
            {
              header: "Support",
              value: customer?.supportPlan === "P" ? "Premium" : "Standard",
            },
            {
              header: "Parent Customer",
              value: <Customer.Link id={customer?.parentCustomerId} />,
              hide: !customer?.parentCustomerId,
            },
            {
              header: "Server Customer",
              value: <Customer.Link id={customer?.serverCustomerId} />,
              hide: !customer?.serverCustomerId,
            },
            {
              header: "Alias",
              value: customer?.alias,
            },
          ]}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}

function CustomerLocationType({ locations, header }) {
  if (!locations?.length) return null;

  return (
    <DetailField header={header}>
      <ListGroup>
        {locations?.map((t, idx) => (
          <ListGroup.Item key={`locations-${t.type}-${idx}`}>
            <Address
              address={t}
              className={`text-reset ${
                t?.type === "primary" ? "font-weight-bold" : ""
              }`}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </DetailField>
  );
}

function CustomerLocations({ locations, locationsInfo }) {
  const { primary, secondary, invoice, ...others } = useMemo(
    () => groupBy(locations, "type"),
    [locations],
  );

  return (
    <>
      {locationsInfo && locationsInfo !== "" ? (
        <Row className="my-3 pt-2 border table-info rounded text-center">
          <Col>
            <Row>
              <Col>
                <h5>Locations Information</h5>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                {locationsInfo?.split(/\r\n|\r|\n/).map((x) => {
                  return (
                    <Row key={x}>
                      <Col>{x}</Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <CustomerLocationType header="Primary" locations={primary} />
          <CustomerLocationType header="Invoice" locations={invoice} />
        </Col>
        <Col>
          <CustomerLocationType header="Secondary" locations={secondary} />
          <CustomerLocationType header="Other" locations={others} />
        </Col>
      </Row>
    </>
  );
}

function CustomerModules({ modules, isLoading }) {
  const _loading = isLoading && _.isEmpty(modules);
  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => value && _date.display(_date.fromStamp(value)),
      },
      {
        Header: "Licenses",
        accessor: "licenses",
        dataType: "number",
      },
    ],
    [],
  );
  return (
    <Row>
      <Col sm="auto">
        <Table
          columns={columns}
          data={modules}
          isLoading={_loading}
          initialSort={{ id: "description" }}
        />
      </Col>
    </Row>
  );
}

function CustomerUpgradeDates({ upgradeDates, isLoading }) {
  const _loading = isLoading && _.isEmpty(upgradeDates);
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => value && _date.display(_date.fromStamp(value)),
      },
      {
        Header: "Completed",
        accessor: "isComplete",
        Cell: ({ value }) => (value ? "Yes" : "No"),
      },
      {
        Header: "Code Date",
        accessor: "codeDate",
        Cell: ({ value }) =>
          (value && _date.display(_date.fromStamp(value))) || null,
      },
    ],
    [],
  );
  if (upgradeDates === undefined) return null;

  return (
    <Row>
      <Col sm="auto">
        <Table columns={columns} data={upgradeDates} isLoading={_loading} />
      </Col>
    </Row>
  );
}

function CustomerCodeDates({ codeDates, isLoading }) {
  const _loading = isLoading && _.isEmpty(codeDates);
  const columns = React.useMemo(
    () => [
      {
        Header: "System",
        accessor: "system",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => value && _date.display(_date.fromStamp(value)),
      },
    ],
    [],
  );
  return (
    <Row>
      <Col sm="auto">
        <Table columns={columns} data={codeDates} isLoading={_loading} />
      </Col>
    </Row>
  );
}

function CustomerLicense({ license }) {
  return (
    <Row>
      <Col xs="auto">
        <BSTable striped>
          <thead>
            <tr>
              <th>Type</th>
              <th>Users</th>
              <th>License</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PPro</td>
              <td>{license?.pproUsers}</td>
              <td></td>
            </tr>
            <tr>
              <td>PPro Single Function</td>
              <td>{license?.singleFunctionUsers}</td>
              <td></td>
            </tr>
            <tr>
              <td>PPro Extra Sessions</td>
              <td>
                {license?.extraSessionsUnlimted
                  ? "Unlimited"
                  : license?.extraSessionsUsers}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>DBC/DX</td>
              <td>{license?.dbcdxUsers}</td>
              <td>{license?.dbcdx}</td>
            </tr>
            <tr>
              <td>DBC/FS</td>
              <td>{license?.dbcfsUsers}</td>
              <td>{license?.dbcfs}</td>
            </tr>
          </tbody>
        </BSTable>
      </Col>
    </Row>
  );
}

function CustomerLogins({ logins, isLoading }) {
  const _loading = isLoading && _.isEmpty(logins);
  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Password",
        accessor: "password",
      },
    ],
    [],
  );
  return (
    <Row>
      <Col sm="auto">
        <Table columns={columns} data={logins} isLoading={_loading} />
      </Col>
    </Row>
  );
}

function CustomerAccounting({ travelInformation, isPoRequired }) {
  return (
    <Row>
      <Col md={12} xl={6}>
        <DetailTable
          fields={[
            {
              header: "PO Required",
              value: <Form.Check defaultChecked={isPoRequired} disabled />,
            },
            {
              header: "Travel Billable",
              value: (
                <Form.Check
                  defaultChecked={travelInformation?.billable}
                  disabled
                />
              ),
            },
            {
              header: "Travel Billable Hours",
              value: travelInformation?.billableHours,
            },
            {
              header: "Travel Non-Billable Hours",
              value: travelInformation?.nonBillableHours,
            },
          ]}
        />
      </Col>
    </Row>
  );
}
