import _ from "lodash";
import React from "react";

import { SelectColumnFilterWithOptions } from "../tables/TableFilters";

/**
 * @typedef {Object} Opts
 * @property {string} name Entity name
 * @property {function} useEntities Hook that returns entities
 * @property {function} label Callback to determine entity label
 * @property {function} filter Callback to filter entities
 */

/**
 * Create Description component based on entity
 * @param {Opts} opts Options
 */
export function entityTableFilter({
  name,
  useEntities,
  label = (e) => e.description,
  filter = () => true,
}) {
  const entityTableFilter = React.memo(({ column } = {}) => {
    const { filterValue: value } = column;
    const { entities } = useEntities({ canGet: true });
    const _options = entities
      .filter((e) => filter(e, value))
      .map((e) => {
        return { label: label(e), value: e.id };
      });
    return <SelectColumnFilterWithOptions column={column} options={_options} />;
  });
  entityTableFilter.displayName = `${_.upperFirst(name)}Select`;
  return entityTableFilter;
}

export default entityTableFilter;
