import shark from "../../../apis/shark";

export function getNotificationsAPI(filter) {
  const payload = {
    ...filter,
    command: "getNotifications",
  };
  return shark.post(payload);
}

export function getNotificationAPI({ id }) {
  const payload = {
    id: id,
    command: "getNotification",
  };
  return shark.post(payload);
}

export function updateNotificationAPI(payload) {
  const payload2 = {
    command: "updateNotification",
    ...payload,
  };
  return shark.post(payload2);
}
