import { combineReducers } from "@reduxjs/toolkit";

import activityReducer from "./activity/state";
import appReducer from "./app/state";
import approvalsReducer from "./approvals/state";
import authReducer from "./auth/state";
import codeReviewsState from "./codeReviews/state";
import contactState from "./contacts/state";
import contractsState from "./contracts/state";
import crState from "./crs/state";
import customerState from "./customers/state";
import dbcState from "./dbc/state";
import ediState from "./edi/state";
import estimateState from "./estimates/state";
import expenseState from "./expenses/state";
import invoicesState from "./invoices/state";
import projectState from "./projects/state";
import serverState from "./servers/state";
import settingsReducer from "./settings/state";
import timeReducer from "./time/state";
import userState from "./users/state";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  settings: settingsReducer,
  crs: crState.reducer,
  dbc: dbcState.reducer,
  edi: ediState.reducer,
  projects: projectState.reducer,
  customers: customerState.reducer,
  contacts: contactState.reducer,
  activity: activityReducer,
  users: userState.reducer,
  times: timeReducer,
  approvals: approvalsReducer,
  expenses: expenseState.reducer,
  servers: serverState.reducer,
  estimates: estimateState.reducer,
  contracts: contractsState.reducer,
  codeReviews: codeReviewsState.reducer,
  invoices: invoicesState.reducer,
});

const STORE_RESET = "store/RESET";
export const resetState = () => {
  return {
    type: STORE_RESET,
  };
};

export const resettableRootReducer = (state, action) => {
  if (action.type === STORE_RESET) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
