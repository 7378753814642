import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import AwsAccount from "../awsAccounts";
import AwsInstanceTypeLink from "../awsInstanceTypes/AwsInstanceTypeLink";
import AwsZoneLink from "../awsZones/AwsZoneLink";

export default function ReservationComparisonTable({ comparisons, ...props }) {
  const columns = useMemo(
    () => [
      {
        id: "AWS Account",
        Header: "AWS Account",
        accessor: "awsAccount",
        Filter: AwsAccount.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsAccount.Link id={value} />,
      },
      {
        id: "AWS Zone",
        Header: "AWS Zone",
        accessor: "awsZone",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "AWS Instance Type",
        Header: "AWS Instance Type",
        accessor: "awsInstanceType",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
      {
        id: "Server Count",
        Header: "Server Count",
        accessor: "serverCount",
      },
      {
        id: "Reservation Count",
        Header: "Reservation Count",
        accessor: "reservationCount",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Comparisons"
      columns={columns}
      data={comparisons}
      {...props}
    />
  );
}
