import React from "react";
import { Link } from "react-router-dom";

import DetailTableEdit from "../../common/details/DetailTableEdit";
import { Forms, useFormsAutoSubmit } from "../../common/forms";
import Cr from "../../crs";
import Project from "../../projects";

export default function CrShowDetails({
  cr,
  project,
  update,
  isLoading,
  isDisabled,
}) {
  return (
    <DetailTableEdit isDisabled={isDisabled || isLoading}>
      <Forms
        onSubmit={(e) => {
          return update(e);
        }}
        defaultValues={cr}
        schema={{ schema: Cr.schema }}
      >
        <TableBody
          cr={cr}
          project={project}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Forms>
    </DetailTableEdit>
  );
}

function TableBody({ cr, project, isLoading, isDisabled }) {
  useFormsAutoSubmit({ debounceMs: 300, shouldResetOnError: true });
  return (
    <tbody>
      <DetailTableEdit.Row.OwnerGroup
        entity={cr}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        label="Project"
        value={
          <Link
            className="text-reset"
            to={{ pathname: `/projects/${cr?.projectId}` }}
          >
            {cr?.projectId}
          </Link>
        }
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        label="Priority"
        value={<Project.Priority.Description id={project?.priorityId} />}
        isLoading={isLoading}
        isDisabled={isDisabled}
        getRowProps={() => ({ className: Cr.getPriorityClassName(cr) })}
      />
      <DetailTableEdit.Row
        label="Type"
        name="overrideTypeId"
        value={
          <Project.Type.Description
            id={cr?.overrideTypeId || project?.typeId}
          />
        }
        as={Project.Type.Select}
        isLoading={isLoading}
        placeholder="Select Type Override..."
        isClearable
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        label="Module"
        name="moduleId"
        value={<Cr.Module.Description id={cr?.moduleId} />}
        as={Cr.Module.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <DetailTableEdit.Row
        label="Difficulty"
        name="difficultyId"
        value={<Cr.Difficulty.Description id={cr?.difficultyId} />}
        as={Cr.Difficulty.Select}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </tbody>
  );
}
