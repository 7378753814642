import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import CodeReview from "../../codeReviews";
import CodeReviewTable from "../../codeReviews/CodeReviewTable";
import Comments from "../../common/details/Comments";
import DetailSection from "../../common/details/DetailSection";
import Cr from "../../crs";
import QaReview from "../../crs/qaReviews";
import QaReviewModifyModal from "../../crs/qaReviews/QaReviewModifyModal";
import QaReviewNewButton from "../../crs/qaReviews/QaReviewNewButton";
import QaReviewTable from "../../crs/qaReviews/QaReviewTable";
import Project from "../../projects";
import User from "../../users";
import CrPage from "./CrPage";
import CrShowDetails from "./CrShowDetails";
import CrShowNavSidebar from "./CrShowNavSidebar";
import CrShowToggles from "./CrShowToggles";

export default function CrShowQa() {
  const id = parseInt(useParams().id);
  const userId = useSelector((state) => state.auth.user);

  const {
    cr,
    loading,
    updateOne,
    updateWatch,
    getCr,
    lastUpdate,
    createQaComment,
    updateQaComment,
  } = Cr.useOne({
    id,
    logActivity: false,
    shouldSetCurrent: true,
    includeLogs: true,
    redirect: false,
  });

  const { project } = Project.useOne({
    id: cr?.projectId,
  });

  const { isAdmin, user, canPerformCodeReview } = useSelector(
    (state) => state.auth,
  );

  const isLoadingAndInvalid = loading && _.isEmpty(cr);

  const transferred =
    cr?.transferredFolder && cr?.transferredFolder !== "CURRENT";

  const isReviewer = isAdmin || canPerformCodeReview;

  const [showQaReviewModifyModalId, setShowQaReviewModifyModalId] =
    useState(null);

  const {
    qaReviews,
    reset: resetQaReviews,
    getMany: qaReviewsGetMany,
    loading: qaReviewsLoading,
  } = QaReview.useSearch({
    canGet: "true",
    search: {
      crId: id,
    },
  });

  const onFormsUpdateLocal = () => {
    resetQaReviews({ cr: id });
    qaReviewsGetMany();
    getCr();
  };

  const showQaReviewModify = (id) => {
    setShowQaReviewModifyModalId(id);
  };

  const closeQaReviewModify = () => {
    setShowQaReviewModifyModalId(null);
  };

  const onQaReviewsRefresh = useCallback(() => {
    resetQaReviews();
    qaReviewsGetMany();
  }, [qaReviewsGetMany, resetQaReviews]);

  //This is needed to prevent rerenders causing data to be wiped
  //out on the new estimate form
  const qaReviewNewInitVals = useMemo(() => {
    return {
      crId: id,
      reviewer: userId,
      reviewDate: moment(),
    };
  }, [id, userId]);

  return (
    <CrPage
      cr={cr}
      heading={`CR ${id} QA`}
      navSidebar={<CrShowNavSidebar id={id} />}
      isLoading={loading}
      tableOfContents={false}
    >
      <Cr.Alerts cr={cr} />
      <Form.Row>
        <Col md={8} sm={12} className="border-right">
          <Row>
            <Col xs="auto" style={{ minWidth: "300px" }}>
              <User.Select
                label="Reviewer"
                value={cr?.qaOwner}
                onChange={(id) =>
                  updateOne({
                    qaOwner: id,
                    ...(cr?.qaStatusId === "" ? { qaStatusId: "WA" } : {}),
                  })
                }
                isClearable
                isDisabled={transferred || !isReviewer}
                isLoading={isLoadingAndInvalid}
              />
            </Col>
            <Col xs="auto" style={{ minWidth: "300px" }}>
              <Cr.QaStatus.Select
                label="Status"
                value={cr?.qaStatusId}
                onChange={(id) => {
                  let newCr = { qaStatusId: id };
                  if (!id) newCr.qaOwner = "";
                  else if (id === "PA" || id === "PW") newCr.statusId = "CL";
                  else if (id === "FA") newCr.statusId = "OP";

                  if (id) {
                    if (!cr?.qaOwner) {
                      newCr.qaOwner = user;
                    }
                  }

                  return updateOne(newCr);
                }}
                isClearable
                isDisabled={transferred || !isReviewer}
                isLoading={isLoadingAndInvalid}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <QaReviewModifyModal
                onUpdate={onFormsUpdateLocal}
                qaReviewId={showQaReviewModifyModalId}
                onClose={closeQaReviewModify}
              />
              <QaReviewTable
                qaReviews={qaReviews}
                setModifyShow={showQaReviewModify}
                isLoading={qaReviewsLoading}
                onRefresh={() => onQaReviewsRefresh()}
                layout={[
                  "QA Review #",
                  "QA Status",
                  "Reviewer",
                  "Date",
                  "Attachments",
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <QaReviewNewButton
                initialValues={qaReviewNewInitVals}
                onCreate={onFormsUpdateLocal}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <span className="text-muted float-left">
                <Button
                  variant="link"
                  className="text-muted"
                  size="sm"
                  onClick={() => getCr()}
                  title="Refresh CR data"
                >
                  {loading
                    ? "Loading..."
                    : "Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
                </Button>
              </span>
            </Col>
          </Row>
          <Col className="mb-1">
            <Row>
              <Col>
                <Cr.StatusEditor
                  cr={cr}
                  project={project}
                  update={updateOne}
                  isLoading={isLoadingAndInvalid}
                  isDisabled={transferred}
                  updateWatch={updateWatch}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CrShowDetails
                  cr={cr}
                  project={project}
                  isLoading={isLoadingAndInvalid}
                  update={updateOne}
                  isDisabled={transferred}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CrShowToggles
                  cr={cr}
                  isLoading={isLoadingAndInvalid}
                  update={updateOne}
                  isDisabled={transferred}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Form.Row>
      <DetailSection title="QA Comments" key="comments-section">
        <Row>
          <Col>
            <Comments
              id={`cr-${id}-qa-comments`}
              comments={cr?.qaComments}
              isLoading={loading}
              isDisabled={transferred}
              create={createQaComment}
              update={updateQaComment}
            />
          </Col>
        </Row>
      </DetailSection>
      <DetailSection title="Revisions" key="code-review-section">
        <Row>
          <Col>
            <CrShowQaCodeReview
              id={`cr-${id}-qa-code-review`}
              crId={id}
              canPerformCodeReview={canPerformCodeReview}
            />
          </Col>
        </Row>
      </DetailSection>
    </CrPage>
  );
}

const defaultLayout = [
  "Actions",
  "Last Reviewed By",
  "File",
  "Revision Date",
  "Revision",
  "Author",
  "Revision Comment",
  "Source Group",
  "Source Owner",
];

const defaultInitialSort = { id: "Revision Date", desc: false };

function CrShowQaCodeReview({ crId, canPerformCodeReview }) {
  const [colorMethod, setColorMethod] = useState("last");
  const { codeReviews, reset, getMany, loading } = CodeReview.useSearch({
    canGet: "true",
    search: {
      crId: crId,
    },
  });

  const onRefresh = useCallback(() => {
    reset();
    getMany();
  }, [getMany, reset]);

  return (
    <>
      <Row>
        <Col xs={"auto"}>
          <Button variant="light" disabled className="rounded-0">
            Color based on:
          </Button>
          <Button
            className="rounded-0"
            onClick={() => setColorMethod("last")}
            variant={colorMethod === "last" ? "primary" : "secondary"}
          >
            Last Review
          </Button>
          <Button
            className="rounded-0"
            onClick={() => setColorMethod("my")}
            variant={colorMethod === "my" ? "primary" : "secondary"}
          >
            My Review
          </Button>
        </Col>
      </Row>
      <CodeReviewTable
        data={codeReviews}
        onRefresh={onRefresh}
        layout={defaultLayout}
        initialSort={defaultInitialSort}
        disabled={!canPerformCodeReview}
        loading={loading}
        colorMethod={colorMethod}
      />
    </>
  );
}
