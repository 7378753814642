import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import ContractNavSidebar from "../ContractNavSidebar";
import ContractProductEdit from "./ContractProductEdit";
import ContractProductIndex from "./ContractProductIndex";
import ContractProductNew from "./ContractProductNew";

export default function ContractProductsPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Contract Product"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <ContractProductNew />
      </Route>
      <Route path={`${path}/:id`}>
        <ContractProductEdit />
      </Route>
      <Route exact path={`${path}`}>
        <ContractProductIndex />
      </Route>
    </Switch>
  );
}
