import _ from "lodash";
import React, { useCallback, useMemo } from "react";

import useReinitState from "../hooks/useReinitState";

const REQUIRED_COLUMNS = [
  // Let's make a column for selection
  {
    id: "tableSelect",
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  },
];

const REQUIRED_COLUMNS_IDS = REQUIRED_COLUMNS.map((c) => c.id);

export default function useColumns({
  columns: columnsDef,
  layout: layoutDef,
  sortBy: sortByDef,
  filters: filtersDef,
  groupBy: groupByDef,
  expanded: expandedDef,
  select: isSelect,
  isLoading,
}) {
  const [layout] = useReinitState(layoutDef);
  const { columns, columnIds, columnsLookup, hasFilter, hasFooter } = useMemo(
    () => setupColumns(columnsDef),
    [columnsDef],
  );

  const getColumn = useCallback((id) => columnsLookup[id], [columnsLookup]);

  const { hiddenColumns, columnOrder } = useMemo(() => {
    let requiredCols = [];
    if (isSelect) requiredCols.push("tableSelect");

    if (!layout.length)
      return {
        hiddenColumns: _.difference(REQUIRED_COLUMNS_IDS, requiredCols),
        columnOrder: columnIds,
      };
    //Check for invalid columns in the layout
    const invalidLayoutColumns = _.difference(layout, columnIds);
    if (invalidLayoutColumns.length) {
      console.warn("Table layout included invalid 'id'", invalidLayoutColumns);
    }

    const layoutWithRequired = [...requiredCols, ...layout];
    return {
      hiddenColumns: _.difference(columnIds, layoutWithRequired),
      columnOrder: layoutWithRequired,
    };
  }, [layout, columnIds, isSelect]);

  const initSort = useCallback(
    (c) => (_.isString(c) ? { id: c, desc: false } : c),
    [],
  );

  const sortBy = useMemo(
    () =>
      sortByDef
        ? _.isArray(sortByDef)
          ? sortByDef.map(initSort)
          : [initSort(sortByDef)]
        : [],
    [initSort, sortByDef],
  );

  const _groupByDef = useMemo(() => {
    const groupBy = !groupByDef
      ? []
      : _.isArray(groupByDef)
      ? groupByDef
      : [groupByDef];

    return groupBy.filter((g) => !getColumn(g)?.disableGroupBy);
  }, [groupByDef, getColumn]);
  const [groupBy, setGroupBy] = useReinitState(_groupByDef);

  const expanded = useMemo(
    () => (_.isObject(expandedDef) ? expandedDef : {}),
    [expandedDef],
  );

  const filters = useMemo(
    () =>
      !filtersDef ? [] : _.isArray(filtersDef) ? filtersDef : [filtersDef],
    [filtersDef],
  );

  return useMemo(() => {
    return {
      columns,
      getColumn,
      hasFooter,
      hasFilter,
      initialState: {
        hiddenColumns,
        columnOrder,
        sortBy,
        groupBy: isLoading ? [] : groupBy,
        filters: isLoading ? [] : filters,
        expanded,
      },
      setGroupBy,
    };
  }, [
    columnOrder,
    columns,
    expanded,
    filters,
    getColumn,
    groupBy,
    hasFilter,
    hasFooter,
    hiddenColumns,
    isLoading,
    setGroupBy,
    sortBy,
  ]);
}

const DATATYPES = {
  number: {
    format: {
      alignment: {
        horizontal: "right",
      },
    },
    getFormattedValue: (value, format = {}) => {
      let v = _.toNumber(value);
      if (_.isNaN(v)) return "";
      const { number: { decimals } = {} } = format;
      if (decimals) {
        v = v.toFixed(decimals);
      }
      return v;
    },
  },
  currency: {
    format: {
      alignment: {
        horizontal: "right",
      },
    },
    getFormattedValue: (value, format = {}) => {
      let v = _.toNumber(value);
      if (_.isNaN(v)) return "";
      const { number: { decimals } = {} } = format;
      if (decimals) {
        v = v.toFixed(decimals);
      }
      return "$" + v;
    },
  },
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

function setupColumns(columnsDef) {
  let hasFooter = false;
  let hasFilter = false;

  function initColumns(columns) {
    return columns.map((colDef) => {
      let col = { ...colDef };

      if (_.has(col, "Footer")) hasFooter = true;

      if (!_.has(col, ["disableFilters"]))
        if (_.has(col, "filter")) col.disableFilters = false;
        else col.disableFilters = true;
      if (col.disableFilters === false) hasFilter = true;

      if (!_.has(col, ["disableGroupBy"]))
        if (_.has(col, ["aggregate"])) col.disableGroupBy = false;
        else col.disableGroupBy = true;

      const dataType = _.get(DATATYPES, col?.dataType);
      if (dataType)
        col.format = {
          ...dataType?.format,
          ...col.format,
        };

      const { className, format } = col;
      col.cellClassName = [
        className,
        getAlignmentClassName(format?.alignment),
      ].join(" ");
      col.headerClassName = [
        className,
        getAlignmentClassName(format?.alignment),
      ].join(" ");

      col.getFormattedValue = _.isFunction(dataType?.getFormattedValue)
        ? dataType.getFormattedValue
        : (value) => value;

      if (!_.has(col, "Cell")) {
        col.Cell = ({ value, column: { getFormattedValue, format } }) => {
          const formattedValue = getFormattedValue(value, format);
          return _.isUndefined(formattedValue) ? null : formattedValue;
        };
      }
      if (!col.disableGroupBy && !_.has(col, "Aggregated")) {
        col.Aggregated = ({
          value,
          column: { getFormattedValue, format, aggregate },
          row: { isExpanded },
        }) => {
          if (
            (isExpanded && aggregate === "uniqueCount") ||
            aggregate === "count"
          )
            return "";
          const formattedValue = getFormattedValue(value, format);
          return `${formattedValue}${aggregate === "uniqueCount" ? "*" : ""}`;
        };
      }

      if (_.has(col, "columns")) col.columns = initColumns(col.columns);
      return col;
    });
  }

  const columns = initColumns([...REQUIRED_COLUMNS, ...columnsDef]);
  const allColumns = _.flatten(columns);

  return {
    columns: columns,
    columnIds: allColumns.map((c) => {
      if (!c.id && !_.isString(c.accessor))
        console.warn("Table column definition missing 'id'", c);
      return c.id || c.accessor;
    }),
    columnsLookup: _.keyBy(allColumns, (c) => c?.id || c?.accessor),
    hasFilter,
    hasFooter,
  };
}

const ALIGNMENT_HORIZONAL_CLASSNAMES = {
  right: "text-right",
  left: "text-left",
  center: "text-center",
};
const ALIGNMENT_VERTICAL_CLASSNAMES = {
  middle: "align-middle",
  top: "align-top",
  bottom: "align-bottom",
  textBottom: "align-text-top",
  textTop: "align-text-top",
};

function getAlignmentClassName(alignment = {}) {
  let classNames = [];
  if (_.isEmpty(alignment)) return classNames;
  const horizontal = _.get(
    ALIGNMENT_HORIZONAL_CLASSNAMES,
    alignment?.horizontal,
  );
  if (horizontal) classNames.push(horizontal);
  const vertical = _.get(ALIGNMENT_VERTICAL_CLASSNAMES, alignment?.vertical);
  if (vertical) classNames.push(vertical);
  return classNames;
}
