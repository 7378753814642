import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getSourceCodes" }).then((response) => {
      return { data: response.responseData.sourceCodes };
    });
  },
  getOne: ({ id }) => {
    return shark.post({ command: "getSourceCode", id: id }).then((response) => {
      return { data: response.responseData };
    });
  },
};

const entity = createEntity({
  name: "sourceCodes",
  type: "dbc/sourceCodes",
  getState: (state) => state.dbc.sourceCodes,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const sourceCodeState = {
  api,
  entity,
  actions,
  reducer,
};

export default sourceCodeState;
