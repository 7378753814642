import FileSaver from "file-saver";
import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

import { deleteAttachmentAPI, getAttachmentAPI } from "../../apis/attachments";

export default function useAttachment() {
  const [loading, setLoading] = useState(false);
  const toastId = useRef(null);

  const getAttachment = useCallback(async (id, filename) => {
    setLoading(true);

    toastId.current = toast.info("Downloading " + filename + "...", {
      autoClose: false,
    });
    getAttachmentAPI({ id })
      .then((file) => {
        FileSaver.saveAs(file, filename);
        setLoading(false);
        toast.update(toastId.current, {
          render: "Downloaded (" + filename + ")",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.update(toastId.current, {
          render: `Download Failed (${filename}): ${error}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
        console.log(error);
        return Promise.reject(error);
      });
  }, []);

  const deleteAttachment = useCallback(async (id) => {
    return deleteAttachmentAPI({ id })
      .then((resp) => resp)
      .catch((error) => {
        return Promise.reject(error);
      });
  }, []);
  return { loading, getAttachment, deleteAttachment };
}
