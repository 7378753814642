import React, { useCallback, useState } from "react";
import { Button, Col, ListGroup, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";

import CodeReview from "../../codeReviews";
import CodeReviewTable from "../../codeReviews/CodeReviewTable";
import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import CodeReviewOptions from "./CodeReviewOptions";

const CodeReviewOptionsMemo = React.memo(CodeReviewOptions);
const CodeReviewTableMemo = React.memo(CodeReviewTable);

export default function CodeReviewShow() {
  const startDate = useSearchParams("startDate");
  const endDate = useSearchParams("endDate");
  const group = useSearchParams("group");
  const fileOwner = useSearchParams("fileOwner");
  const allCommitsFromCr = useSearchParams("allCommitsFromCr");
  const author = useSearchParams("author");
  const authorTeam = useSearchParams("authorTeam");
  const repository = useSearchParams("repository");
  const title = "Code Review";

  const { canPerformCodeReview, isManager, isAdmin } = useSelector(
    (state) => state.auth,
  );

  if (!(canPerformCodeReview || isManager || isAdmin)) {
    return (
      <Page
        title={title}
        lead="You do not have access to this feature"
        navSidebar={false}
        tableOfContents={false}
      />
    );
  } else if (startDate === "" || !startDate) {
    return (
      <Page
        title={title}
        lead="Start Date is required"
        navSidebar={false}
        tableOfContents={false}
      />
    );
  }
  return (
    <CodeReviewShowTable
      startDate={startDate}
      endDate={endDate}
      group={group}
      fileOwner={fileOwner}
      allCommitsFromCr={allCommitsFromCr}
      title={title}
      author={author}
      authorTeam={authorTeam}
      repository={repository}
    />
  );
}

function CodeReviewShowTable({
  startDate,
  endDate,
  fileOwner,
  author,
  authorTeam,
  allCommitsFromCr,
  group,
  title,
  repository,
}) {
  const [key, setKey] = useState("Commits");
  const [colorMethod, setColorMethod] = useState("last");
  const { codeReviews, reset, getMany, loading } = CodeReview.useSearch({
    canGet: "true",
    search: {
      startDate: startDate,
      endDate: endDate,
      group: group,
      sourceOwner: fileOwner,
      includeAllCommitsFromCr: allCommitsFromCr,
      authorUserId: author,
      authorTeam: authorTeam,
      revisionType: repository,
    },
  });

  const setKeyCommits = useCallback(() => setKey("Commits"), []);
  const onRefresh = useCallback(() => {
    reset();
    getMany();
  }, [getMany, reset]);
  const onTabSelect = useCallback((k) => setKey(k), []);

  return (
    <Page
      title={title}
      lead={
        loading ? "Loading..." : !codeReviews ? "Code Review not found" : ""
      }
      navSidebar={false}
      tableOfContents={false}
    >
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Tab.Container activeKey={key} onSelect={onTabSelect}>
            <Row>
              <Col xs={7}>
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="Commits">Commits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Options">Options</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xs={"auto"}>
                <Button variant="light" disabled className="rounded-0">
                  Color based on:
                </Button>
                <Button
                  className="rounded-0"
                  onClick={() => setColorMethod("last")}
                  variant={colorMethod === "last" ? "primary" : "secondary"}
                >
                  Last Review
                </Button>
                <Button
                  className="rounded-0"
                  onClick={() => setColorMethod("my")}
                  variant={colorMethod === "my" ? "primary" : "secondary"}
                >
                  My Review
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="Commits">
                    <CodeReviewTableMemo
                      data={codeReviews}
                      onRefresh={onRefresh}
                      colorMethod={colorMethod}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Options">
                    <CodeReviewOptionsMemo
                      startDate={startDate}
                      endDate={endDate}
                      fileOwner={fileOwner}
                      group={group}
                      allCommitsFromCr={allCommitsFromCr}
                      authorTeam={authorTeam}
                      repository={repository}
                      onSubmit={setKeyCommits}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </ListGroup.Item>
      </ListGroup>
    </Page>
  );
}
