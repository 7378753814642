import { isEmpty } from "lodash";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useFormState } from "react-hook-form";
import { BiErrorAlt } from "react-icons/bi";

import { Errors } from "./Errors";

export function ErrorIcon(props) {
  const { isValid, errors } = useFormState();
  if (isValid || isEmpty(errors)) return null;
  return (
    <OverlayTrigger
      overlay={
        <Popover id={`errors`}>
          <Popover.Title>Errors</Popover.Title>
          <Popover.Content>
            <Errors />
          </Popover.Content>
        </Popover>
      }
    >
      <BiErrorAlt size="1.5em" {...props} />
    </OverlayTrigger>
  );
}
