import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import User from "../../users";
import { _datetime } from "../functions/dates";
import useAttachment from "../hooks/useAttachment";
import useReinitState from "../hooks/useReinitState";
import Table from "../tables/Table";
import { TextColumnFilter } from "../tables/TableFilters";

export default function AttachmentsTable({
  attachments: initial,
  source,
  create,
  isDisabled,
  onDelete,
}) {
  const [attachments, setAttachments] = useReinitState(initial);
  const { getAttachment, deleteAttachment } = useAttachment();

  const deleteFile = useCallback(
    async (id, filename) => {
      const toastId = toast.info(`Deleting ${filename}...`, {
        autoClose: false,
        hideProgressBar: false,
        progress: 0.5,
      });
      await deleteAttachment(id)
        .then(() => {
          setAttachments((as) => as.filter((a) => a?.id !== id));
          onDelete();
          toast.update(toastId, {
            render: `Delete ${filename} Complete`,
            type: toast.TYPE.SUCCESS,
            progress: undefined,
            hideProgressBar: true,
            autoClose: 5000,
          });
        })
        .catch((error) =>
          toast.update(toastId, {
            render: `Delete ${filename} Failed: ${error}`,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          }),
        );
    },
    [deleteAttachment, onDelete, setAttachments],
  );

  const columns = useMemo(
    () => [
      {
        id: "filename",
        Header: "File",
        accessor: "filename",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ row, value }) => (
          <OverlayTrigger overlay={<Tooltip>Download {value}</Tooltip>}>
            <Button
              variant="link"
              className="text-reset py-0 my-0"
              onClick={() => getAttachment(row.original?.id, value)}
            >
              {value}
            </Button>
          </OverlayTrigger>
        ),
      },
      {
        id: "source",
        Header: "Log Source",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: ({ source, sourceId }) => {
          switch (source) {
            case "PR":
              return "Project " + sourceId;
            case "CR":
              return "CR " + sourceId;
            default:
              return sourceId || null;
          }
        },
        aggregate: "uniqueCount",
        Cell: ({ row: { original: { source, sourceId } = {} }, value }) => {
          if (source === "CR")
            return (
              <Link
                className="text-reset"
                to={{ pathname: `/crs/${sourceId}` }}
              >
                {value || null}
              </Link>
            );
          else if (source === "PR")
            return (
              <Link
                className="text-reset"
                to={{ pathname: `/projects/${sourceId}` }}
              >
                {value || null}
              </Link>
            );
          else {
            return <span>{value || null}</span>;
          }
        },
      },
      {
        id: "dateTime",
        Header: "Date",
        accessor: "dateTime",

        Cell: ({ value }) => (
          <span>{_datetime.displayDateTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        id: "userId",
        Header: "User",
        accessor: "userId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "delete",
        Header: "Delete",
        accessor: "id",

        Cell: ({ value, row: { original: { filename } = {} } }) => (
          <OverlayTrigger overlay={<Tooltip>Delete Attachment</Tooltip>}>
            <Button
              size="sm"
              variant="danger"
              // className="text-reset py-0 my-0"
              onClick={() => deleteFile(value, filename)}
              disabled={isDisabled}
            >
              Delete
            </Button>
          </OverlayTrigger>
        ),
      },
    ],
    [getAttachment, deleteFile, isDisabled],
  );

  async function uploadFiles(e) {
    const files = e.target.files;
    if (!files?.length) return;
    const toastId = toast.info("Uploading " + files.length + " files...", {
      autoClose: false,
      hideProgressBar: false,
      progress: 0.1,
    });
    let successfulCount = 0;
    const success = () => successfulCount++;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const updateToast = () =>
        toast.update(toastId, {
          progress: (i + 1) / files.length,
        });

      await create({ files: [file] })
        .then(success)
        .catch(() => {})
        .finally(updateToast);
    }

    toast.update(toastId, {
      render: `Upload Complete (${successfulCount} of ${files.length})`,
      type: toast.TYPE.SUCCESS,
      progress: undefined,
      hideProgressBar: true,
      autoClose: 5000,
    });
  }
  return (
    <>
      {isDisabled ? null : (
        <Row>
          <Col>
            <Form.File
              id="upload-attachment"
              label="Upload attachment"
              onChange={uploadFiles}
              multiple
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Table
            hasCount
            label="Attachments"
            data={attachments}
            columns={columns}
            layout={["filename", "source", "dateTime", "userId"].concat(
              !isDisabled ? ["delete"] : [],
            )}
            getRowProps={(row) => {
              if (row.isGrouped) return {};
              const a = row?.original;
              if (_.isEqual({ source: a.source, sourceId: a.sourceId }, source))
                return {
                  className: "table-primary",
                };
              else return {};
            }}
          />
        </Col>
      </Row>
    </>
  );
}
