import shark from "../../../apis/shark";

function getReservationsAPI({ customerId } = {}) {
  const args = { ...(customerId ? { customerId: customerId } : null) };
  const payload = {
    command: "getReservations",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.reservations,
    searchArgs: args,
  }));
}

function getReservationAPI({ id }) {
  const payload = {
    command: "getReservation",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createReservationAPI(reservation) {
  const payload = {
    command: "createReservation",
    ...reservation,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateReservationAPI(reservation) {
  const payload = {
    command: "updateReservation",
    ...reservation,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getReservationsAPI,
  getOne: getReservationAPI,
  createOne: createReservationAPI,
  updateOne: updateReservationAPI,
};

export async function getReservationComparisonAPI() {
  const payload = {
    command: "getReservationComparison",
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}
