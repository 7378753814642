import _ from "lodash";
import { toast } from "react-toastify";

import difference from "../difference";

export const INITIAL_LOADING_STATE = {
  requests: {
    ids: {},
    loading: false,
  },
};

export function testStateLoading(state, requestId) {
  const { requests } = state;
  if (requests.loading && requestId in requests.ids) return true;
  return false;
}

export function getChangesExcludingStamps(changes, current) {
  const currentFields = {
    ...current,
    id: undefined,
    createDateTime: undefined,
    modifyDateTime: undefined,
  };
  const changesFields = {
    ...changes,
    id: undefined,
    createDateTime: undefined,
    modifyDateTime: undefined,
  };
  return difference(changesFields, currentFields);
}

export function buildAsyncThunkReducer(
  builder,
  thunk,
  fullfilled = () => {},
  { state: stateSlice, rejected = () => {} } = {},
) {
  function setState(state) {
    let effectiveState;
    if (stateSlice) {
      effectiveState = state[stateSlice];
    } else effectiveState = state;
    return effectiveState;
  }

  builder.addCase(thunk.pending, (state, action) => {
    let _state = setState(state);
    const { requestId, arg = {} } = action.meta;

    const _arg = JSON.stringify(arg);
    if (!_.includes(_state.requests.ids, _arg)) {
      _state.requests.ids[requestId] = _arg;
      _state.requests.loading = true;
    }
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    let _state = setState(state);
    const { requestId } = action.meta;

    if (testStateLoading(_state, requestId)) {
      delete _state.requests.ids[requestId];
      if (_.isEmpty(_state.requests.ids)) _state.requests.loading = false;
      fullfilled(_state, action);
    }
  });
  builder.addCase(thunk.rejected, (state, action) => {
    let _state = setState(state);
    const { requestId } = action.meta;
    if (testStateLoading(_state, requestId)) {
      delete _state.requests.ids[requestId];
      if (_.isEmpty(_state.requests.ids)) _state.requests.loading = false;
      rejected(_state, action);

      toast.error(action.error.message);
    }
  });
}
