import React from "react";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function CodeTestIndex() {
  const { entities, loading, reset } = Dbc.CodeTest.useAll({
    canGet: true,
  });
  return (
    <Page title="DBC Code Tests" navSidebar={<DbcNavSidebar />}>
      <Dbc.CodeTest.Table
        codeTests={entities}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
