import _ from "lodash";
import moment from "moment";
import React from "react";
import Select from "react-select";

export default function TimeHoldDateSelect({
  value,
  onChange,
  id,
  isClearable = true,
  date,
  defaultFirstMonth = false,
}) {
  let valueFound = null;
  const startDate = date == null ? moment() : moment(date);
  startDate.date(1);

  let convertedValue = null;
  if (value != null) {
    convertedValue = moment(value).date(1);
  }

  const _options = [];
  for (let i = 1; i <= 24; i++) {
    let newDate = moment(startDate).add(i, "months");
    const obj = {
      value: newDate,
      label: newDate.format("MMMM YYYY"),
    };
    _options.push(obj);
    if (convertedValue != null && newDate.isSame(value, "day")) {
      valueFound = obj;
    }
    if (defaultFirstMonth) {
      if (
        valueFound === null &&
        (_.isEmpty(value) ||
          (typeof value.isValid === "function" && !value.isValid()))
      ) {
        valueFound = obj;
        onChange(newDate);
      }
    }
  }

  if (convertedValue !== null && valueFound === false) {
    const newDate = moment(value);
    const obj = {
      value: newDate,
      label: newDate.format("MMMM YYYY"),
    };
    _options.push(obj);
    valueFound = obj;
  }

  return (
    <Select
      className={"basic-single"}
      classNamePrefix="select"
      isLoading={false}
      isClearable={isClearable}
      isSearchable={true}
      id={id}
      name={id}
      value={valueFound}
      options={_options}
      onChange={onChange}
      isMulti={false}
    />
  );
}
