import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportModuleSummary() {
  return (
    <JobPage
      title="Module Summary"
      functionName="ModuleSummary"
      Options={ModuleSummaryOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.crs || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Group",
        Header: "Group",
        accessor: "groupId",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        id: "Module",
        Header: "Module",
        accessor: "moduleId",
        Filter: Cr.Module.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Cr.Module.Description id={value} />,
      },
      {
        id: "CR Type",
        Header: "Cr Type",
        accessor: "crType",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "CR",
        Header: "CR",
        accessor: "crId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Create Date",
        Header: "Create Date",
        accessor: "createDate",
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Average Number of Users",
        Header: "Avg # Users",
        accessor: "numberOfUsers",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 0 } },
        Aggregated: ({ row }) => {
          const calcRow = (total, row1) => {
            if (row1.isGrouped)
              if (row1.isExpanded) return total;
              else return row1.subRows.reduce(calcRow, total);
            const newCrs = total.crs + 1;
            const newUsers = total.users + row1.original.numberOfUsers;
            return { crs: newCrs, users: newUsers };
          };

          const total = row.subRows.reduce(calcRow, { crs: 0, users: 0 });
          const avg = total.users / total.crs;
          return <div className="text-right">{avg.toFixed(2)}</div>;
        },
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            const newCrs = total.crs + 1;
            const newUsers = total.users + row.original.numberOfUsers;
            return { crs: newCrs, users: newUsers };
          };

          const total = rows.reduce(calcRow, { crs: 0, users: 0 });
          const avg = total.users / total.crs;
          return <div className="text-right">{avg.toFixed(2)}</div>;
        },
      },
      {
        id: "Total Hours",
        Header: "Hours",
        accessor: "totalHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.totalHours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Billable Hours",
        Header: "Billable Hours",
        accessor: "billHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.billHours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Nonbill Hours",
        Header: "Nonbill Hours",
        accessor: "nonbillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.nonbillHours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} />;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "Group",
              "Module",
              "CR",
              "CR Type",
              "Synopsis",
              "Average Number of Users",
              "Billable Hours",
              "Nonbill Hours",
              "Total Hours",
              "Total",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

function ModuleSummaryOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("month"),
  endDate: ed = moment().endOf("month"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      startDate: "",
      endDate: "",
      ...options,
    },
  });

  const endDate = watch("endDate");
  const startDate = watch("startDate");

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          "from " +
          _date.display(options.startDate) +
          " to " +
          _date.display(options.endDate);

        submitJob({
          ...options,
          modules: options.modules,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="modules"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Cr.Module.Select
                label="Modules"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable={false}
                isMulti={true}
                isInvalid={errors.modules}
                errors={"Invalid Module"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
