import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import Customer from "../../customers";
import Server from "../../servers";
import ServerNavSidebar from "./ServerNavSidebar";

export default function ServerIndex() {
  const customerId = useSearchParams("customer");
  const { customer } = Customer.useOne({ id: customerId, canGet: false });
  const [statusFilter, setStatusFilter] = useState("A");

  Server.AwsAccount.useAll({
    canGet: true,
  });
  Server.AwsZone.useAll({
    canGet: true,
  });
  Server.AwsInstanceType.useAll({
    canGet: true,
  });

  const { servers, reset, getMany, loading } = Server.useSearch({
    canGet: "true",
    search: customerId ? { customerId } : {},
  });

  const title = customer?.name ? `Servers: ${customer?.name}` : "Servers";

  const data = useMemo(() => {
    return servers.filter((e) => {
      return e.status === statusFilter;
    });
  }, [statusFilter, servers]);

  return (
    <Page title={title} navSidebar={<ServerNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="statusActive"
          type="radio"
          variant="secondary"
          value={true}
          checked={statusFilter === "A"}
          onChange={() => setStatusFilter("A")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="statusOld"
          type="radio"
          variant="secondary"
          value={true}
          checked={statusFilter === "O"}
          onChange={() => setStatusFilter("O")}
        >
          Old
        </ToggleButton>
        <ToggleButton
          key="statusInactive"
          type="radio"
          variant="secondary"
          value={false}
          checked={statusFilter === "I"}
          onChange={() => setStatusFilter("I")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Server.Table
        servers={data}
        isLoading={loading}
        onRefresh={() => {
          reset();
          getMany();
        }}
      />
    </Page>
  );
}
