import ExpenseTypeDescription from "./ExpenseTypeDescription";
import ExpenseTypeSelect from "./ExpenseTypeSelect";
import ExpenseTypeTableFilter from "./ExpenseTypeTableFilter";
import { useExpenseType, useExpenseTypes } from "./hooks";
import expenseTypeState from "./state";

const ExpenseType = {
  // Backend API
  api: expenseTypeState.api,
  // State
  actions: expenseTypeState.actions,
  selectors: expenseTypeState.entity.selectors,
  // Hooks
  useAll: useExpenseTypes,
  useOne: useExpenseType,
  // Components
  Select: ExpenseTypeSelect,
  TableFilter: ExpenseTypeTableFilter,
  Description: ExpenseTypeDescription,
};

export default ExpenseType;
