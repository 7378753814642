import { entitySelect } from "../../common/entity";
import { useCustomerContractsByCustomer } from "./hooks";

const CustomerContractSelect = entitySelect({
  name: "customerContract",
  useEntities: useCustomerContractsByCustomer,
  getHookArgs: (props) => ({
    canGet: !!props.customerId,
    search: { customerId: props.customerId || undefined },
  }),
});
export default CustomerContractSelect;
