import { useTradingPartner, useTradingPartners } from "./hooks";
import tradingPartnerState from "./state";
import TradingPartnerDescription from "./TradingPartnerDescription";
import TradingPartnerEditor from "./TradingPartnerEditor";
import TradingPartnerSelect from "./TradingPartnerSelect";
import TradingPartnerTable from "./TradingPartnerTable";
import TradingPartnerTableFilter from "./TradingPartnerTableFilter";

const TradingPartner = {
  // Backend API
  api: tradingPartnerState.api,
  // State
  actions: tradingPartnerState.actions,
  selectors: tradingPartnerState.entity.selectors,
  // Hooks
  useAll: useTradingPartners,
  useOne: useTradingPartner,
  // Components
  Select: TradingPartnerSelect,
  TableFilter: TradingPartnerTableFilter,
  Description: TradingPartnerDescription,
  Table: TradingPartnerTable,
  Editor: TradingPartnerEditor,
};

export default TradingPartner;
