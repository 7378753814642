import { entitySelect } from "../../common/entity";
import { useExpenseCategories } from "./hooks";

const ExpenseCategorySelect = entitySelect({
  name: "expenseCategory",
  useEntities: useExpenseCategories,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default ExpenseCategorySelect;
