import { flatMap, isArray, isObject } from "lodash";
import React from "react";
import { useFormState } from "react-hook-form";

export function Errors() {
  const { isValid, errors } = useFormState();
  function getFlatValues(prevValues) {
    if (isArray(prevValues))
      return flatMap(prevValues.map((v) => getFlatValues(v)));
    else if (isObject(prevValues)) {
      if (prevValues?.message) return prevValues?.message;
      else return getFlatValues(flatMap(prevValues));
    } else return prevValues;
  }
  const values = getFlatValues(errors);
  if (isValid) return null;
  return (
    <ul>
      {values?.map((v, idx) => (
        <li key={`error-${idx}`}>{v}</li>
      ))}
    </ul>
  );
}
