import moment from "moment";

const FORMATS = {
  DATE_STAMP: "YYYYMMDD",
  MONTH_STAMP: "YYYYMM",
  DATE_DISPLAY: "MM/DD/YY",
  DATE_DISPLAY_LONG: "dddd, MMMM Do YYYY",
  DATE_DISPLAY_MM_YYYY: "MM / YYYY",
  DATE_URL_PARAMS: "YYYY-MM-DD",
  DATETIME_STAMP: "YYYYMMDDHHmmss",
  TIME_ONLY_STAMP: "HHmmss",
  DATETIME_DISPLAY_TIME: "h:mm a",
  DATETIME_DISPLAY_TIME_SECONDS: "h:mm:ss a",
  DATETIME_DISPLAY: "MM/DD/YY, h:mm a",
  DATETIME_DISPLAY_LONG: "dddd, MMMM Do YYYY, h:mm a",
};

function formatIfValid(date, format) {
  if (date?.isValid()) return date.format(format);
  else return "";
}

//Note: Since fromMomentDatStringTime uses fromStamp
// we need them to be functions non anonymous functions
export const _datetime = {
  fromStamp: function (datetime) {
    return moment(datetime, FORMATS.DATETIME_STAMP);
  },
  fromMomentDateStringTime: function (momentDate, stringTime) {
    return this.fromStamp(_date.stamp(momentDate) + stringTime);
  },
  display: (datetime) => formatIfValid(moment(datetime), FORMATS.DATE_DISPLAY),
  displayLong: (datetime) =>
    formatIfValid(moment(datetime), FORMATS.DATE_DISPLAY_LONG),
  displayTimeSeconds: (datetime) =>
    formatIfValid(moment(datetime), FORMATS.DATETIME_DISPLAY_TIME_SECONDS),
  displayTime: (datetime) =>
    formatIfValid(moment(datetime), FORMATS.DATETIME_DISPLAY_TIME),
  displayDateTime: (date = {}) =>
    formatIfValid(moment(date), FORMATS.DATETIME_DISPLAY),
  displayLongDateTime: (date = {}) =>
    formatIfValid(moment(date), FORMATS.DATETIME_DISPLAY_LONG),
  stamp: (datetime = moment()) =>
    formatIfValid(moment(datetime), FORMATS.DATETIME_STAMP),
  timeOnlyStamp: (datetime = moment()) =>
    moment(datetime).format(FORMATS.TIME_ONLY_STAMP),
  stampUtc: (date = moment()) => {
    // copy moment input, turn on UTC, and return formatted version
    moment(date).utc().format(FORMATS.DATE_STAMP);
  },
};

export const _date = {
  fromStamp: (date) => moment(date, FORMATS.DATE_STAMP),
  fromMonthStamp: (date) => moment(date, FORMATS.MONTH_STAMP),
  fromUrlParams: (date) => moment(date, FORMATS.DATE_URL_PARAMS),

  display: (date = {}) => formatIfValid(moment(date), FORMATS.DATE_DISPLAY),
  displayLong: (date = {}) =>
    formatIfValid(moment(date), FORMATS.DATE_DISPLAY_LONG),
  displayMM_YYYY: (date = {}) =>
    formatIfValid(moment(date), FORMATS.DATE_DISPLAY_MM_YYYY),
  stamp: (date = {}) => formatIfValid(moment(date), FORMATS.DATE_STAMP),
  monthStamp: (date = {}) => formatIfValid(moment(date), FORMATS.MONTH_STAMP),
  stampUtc: (date = {}) => {
    // copy moment input, turn on UTC, and return formatted version
    moment(date).utc().format(FORMATS.DATE_STAMP);
  },
  urlParams: (date = {}) =>
    formatIfValid(moment(date), FORMATS.DATE_URL_PARAMS),
};
