import { bool, number, object, string } from "yup";

const contractProductDefaultCrSchema = object().shape({
  synopsis: string().label("Synopsis").required().max(60),
  description: string().label("Description").required().max(500),
  isInactive: bool().required().default(false),
  willPrefixDateOnSynopsis: bool().required().default(false),
  targetDeliveryDays: number().label("Target Delivery Days"),
  group: string().label("Group").max(6),
  type: string().label("Project Type").max(2),
  module: string().label("Module").max(10),
});

export default contractProductDefaultCrSchema;
