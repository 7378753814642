import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Customer from "../../customers";
import Project from "../../projects";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportCustomerTime() {
  return (
    <JobPage
      title="Customer Time"
      functionName="CustomerTime"
      Options={CustomerTimeOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState(null);
  const data = useMemo(() => job?.output?.time || [], [job]);

  const clickedBillingNote = useCallback(
    (timeId) => {
      setShowTimeId(timeId);
      setShowModifyModal(true);
    },
    [setShowTimeId, setShowModifyModal],
  );

  const columns = useMemo(
    () => [
      {
        id: "User",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrCount,
      },
      {
        id: "Project",
        Header: "Project",
        accessor: (row) => (row.projectId === 0 ? "N/A" : row.projectId),
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Link id={value} />,
      },
      {
        id: "CR",
        Header: "CR",
        accessor: (row) => (row.crId === 0 ? "N/A" : row.crId),
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Cr.Link id={value} />,
      },
      {
        id: "Contact",
        Header: "Contact",
        accessor: "contactName",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "date",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrEmpty,
        Aggregated: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Billing note",
        Header: "Billing note",
        accessor: "billingNote",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,

        Cell: ({ value, row }) => {
          return (
            <Time.BillingNoteLink
              timeId={row?.original?.id}
              value={value}
              setModifyShow={clickedBillingNote}
            />
          );
        },
      },
      {
        id: "Invoice number",
        Header: "Invoice #",
        accessor: "invoiceNumber",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
        aggregate: allSameOrCount,
      },
      {
        id: "Invoice project type",
        Header: "Invoice Project Type",
        accessor: "invoiceTypeDescription",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
        aggregate: allSameOrCount,
      },
      {
        id: "Hours",
        Header: "Hours",
        accessor: "hours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} />;
        },
      },
    ],
    [clickedBillingNote],
  );

  return (
    <>
      <Time.ModifyModal
        id={showTimeId}
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        onCancel={() => setShowModifyModal(false)}
      />
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "Invoice project type",
              "Invoice number",
              "Project",
              "CR",
              "Synopsis",
              "Billing note",
              "User",
              "Date",
              "Hours",
              "Total",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

function CustomerTimeOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("month"),
  endDate: ed = moment().endOf("month"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      customerId: "",
      startDate: "",
      endDate: "",
      hiddenTime: false,
      projectType: "",
      ...options,
    },
  });

  const endDate = watch("endDate");
  const startDate = watch("startDate");
  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          "from " +
          _date.display(options.startDate) +
          " to " +
          _date.display(options.endDate);

        if (_.isNil(customerId) || customerId === "") {
          desc = "All customers " + desc;
        } else {
          desc = useCustomerValue?.customer?.name + " " + desc;
        }
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          hiddenTime: _.isNil(options.hiddenTime) ? false : options.hiddenTime,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="projectType"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Project.Type.Select
                label="Type"
                id="projectType"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={errors.projectType}
                errors={"Invalid Type"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="hiddenTime"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="hiddenTime"
                label="Include hidden time"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.owner}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}

function allSameOrCount(leafValues) {
  return _.uniq(leafValues).length === 1
    ? leafValues[0]
    : _.uniq(leafValues).length + "*";
}

function allSameOrEmpty(leafValues) {
  return _.uniq(leafValues).length === 1 ? leafValues[0] || "" : "";
}
