import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import Page from "../../common/pages/Page";
import Expense from "../../expenses";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseOtherEntry() {
  const user = useSelector((state) => state.auth.user);
  const { expenses, loading, lastUpdate, reset } = Expense.useSearch({
    canGet: true,
    search: { userId: user, status: "O", mode: "O" },
  });

  return (
    <Page
      title={`Other Expenses`}
      navSidebar={<ExpenseIndexNavSidebar />}
      tableOfContents={false}
    >
      <Row>
        <Col>
          <Expense.NewButton onCreate={reset} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Expense.Table
            expenses={expenses}
            isLoading={loading}
            layout={[
              "edit",
              "date",
              "expenseCategory",
              "overrideDescription",
              "note",
              "amount",
              "reimburseAmount",
              "paymentType",
              "billableOverride",
              "mileage",
            ]}
            onRefresh={reset}
            refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
          />
        </Col>
      </Row>
    </Page>
  );
}
