import { at, isUndefined } from "lodash";
import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";

import FieldDate from "../fields/FieldDate";

function FormsControl({ isInvalid, name, rules, children, ...props }) {
  const {
    register,
    formState: { errors },
    formsContext: { isDisabled = false },
  } = useFormContext();
  const [error] = at(errors, name);

  return (
    <>
      <Form.Control
        {...register(name, rules)}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...props}
      >
        {children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </>
  );
}

export function FormsField({
  name,
  id = name,
  label,
  children,
  formGroupClassName,
  ...props
}) {
  return (
    <Form.Group controlId={id} className={formGroupClassName}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <FormsControl name={name} {...props}>
        {children}
      </FormsControl>
    </Form.Group>
  );
}

function FormsCheck({
  name,
  id = name,
  rules,
  label,
  isInvalid,
  children,
  ...props
}) {
  const {
    register,
    formState: { errors },
    formsContext: { isDisabled = false },
  } = useFormContext();

  const [error] = at(errors, name);

  return (
    <Form.Group controlId={id}>
      <Form.Check
        {...register(name, rules)}
        label={label}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...props}
      >
        {children}
      </Form.Check>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function FormsController({
  name,
  id = name,
  rules,
  label,
  isInvalid,
  isValid,
  className,
  as: As,
  asLabel,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  const _isInvalid = isUndefined(isInvalid) ? !!error : !!isInvalid;

  let classNames = ["form-control"];
  if (className) classNames.push(className);
  if (_isInvalid) classNames.push("is-invalid");
  if (isValid) classNames.push("is-valid");

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <As
        {...field}
        className={classNames.join(" ")}
        disabled={isDisabled}
        label={asLabel}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function FormsTextArea(props) {
  return <FormsController as={TextareaAutosize} {...props} />;
}

function FormsDate({
  name,
  id = name,
  rules,
  label,
  isInvalid,
  isValid,
  className,
  maxDate,
  minDate,
  ...props
}) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name, rules });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  const _isInvalid = isUndefined(isInvalid) ? !!error : !!isInvalid;

  let classNames = ["form-control"];
  if (className) classNames.push(className);
  if (_isInvalid) classNames.push("is-invalid");
  if (isValid) classNames.push("is-valid");

  return (
    <Form.Group controlId={id}>
      <FieldDate
        {...field}
        onChange={(v) => {
          const newV = moment(v).toISOString();
          onChange(newV);
        }}
        className={classNames.join(" ")}
        disabled={isDisabled}
        label={label}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function FormsSelect({
  name,
  id = name,
  rules,
  label,
  isValid,
  as: As,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <As
      {...field}
      id={id}
      label={label}
      isDisabled={isDisabled}
      isInvalid={!!error}
      errors={error?.message}
      {...props}
    />
  );
}

FormsField.Control = FormsControl;
FormsField.Check = FormsCheck;
FormsField.TextArea = FormsTextArea;
FormsField.Date = FormsDate;
FormsField.Select = FormsSelect;
