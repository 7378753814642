export const HOSTING_TYPES = {
  P: {
    id: "P",
    description: "Production",
  },
  T: {
    id: "T",
    description: "Test",
  },
  M: {
    id: "M",
    description: "Mirror",
  },
  R: {
    id: "R",
    description: "Printer",
  },
  W: {
    id: "W",
    description: "Web",
  },
  Y: {
    id: "Y",
    description: "Web Mirror",
  },
  A: {
    id: "A",
    description: "Analytics",
  },
  E: {
    id: "E",
    description: "EDI/FTP",
  },
  F: {
    id: "F",
    description: "Shared FTP Server",
  },
  3: {
    id: "3",
    description: "S3",
  },
  O: {
    id: "O",
    description: "Other",
  },
};
