import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import DbcBranchesPages from "./branches";
import DbcCodeTestPages from "./codeTests";
import DbcIndex from "./DbcIndex";
import DbcReusableProgramsPages from "./reusablePrograms";
import DbcSourceCodesPages from "./sourceCodes";

export default function DbcsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/branches`}>
        <DbcBranchesPages />
      </Route>
      <Route path={`${path}/sourceCode`}>
        <DbcSourceCodesPages />
      </Route>
      <Route path={`${path}/reusablePrograms`}>
        <DbcReusableProgramsPages />
      </Route>
      <Route path={`${path}/codeTests`}>
        <DbcCodeTestPages />
      </Route>
      <Route exact path={`${path}`}>
        <DbcIndex />
      </Route>
    </Switch>
  );
}
