import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import FieldSelect from "../common/fields/FieldSelect";
import userState from "./state";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const groups = {
  1: "Add",
  2: "Related Emails",
  3: "Related Users",
  8: "Me",
  9: "Other Users",
};

export default function UserEmailSelect({
  users = [],
  emails = [],
  value: initialValues = [],
  ...props
}) {
  const [input, setInput] = useState("");

  const usersEntities = useSelector(userState.entity.selectors.selectEntities);
  const loggedInUser = useSelector((state) => state.auth.user);
  const usersOptions = useMemo(() => {
    const createOption = (id, groupId, label) => {
      const user = usersEntities?.[id];
      if (!user || !user?.email) return null;
      return {
        label: `${user.name}${label ? ` (${label})` : ""}`,
        value: user.email,
        groupId,
      };
    };

    const createOptionsWithManager = (id, groupId) => {
      let options = [createOption(id, groupId)];

      const user = usersEntities?.[id];
      const managerOption = createOption(user?.managerId, groupId, "Manager");
      if (managerOption) options.push(managerOption);

      return options.filter((o) => o);
    };

    const argsOptions = _.flatten(
      users
        .filter((u) => u?.id)
        .map((u) => createOptionsWithManager(u.id, `3:${u.label}`)),
    );

    const meOptions = createOptionsWithManager(loggedInUser, 8);

    const allUsersOptions = _.sortBy(
      Object.entries(usersEntities)
        .map(([id]) => {
          return createOption(id, 9);
        })
        .filter((u) => u?.value),
      "label",
    );

    return [].concat(argsOptions, meOptions, allUsersOptions);
  }, [users, usersEntities, loggedInUser]);

  const emailsOptions = useMemo(() => {
    const validEmails = emails?.filter((e) =>
      _.isString(e) ? validateEmail(e) : e,
    );

    if (validEmails?.length)
      return validEmails
        .map((e) => {
          if (_.isString(e)) {
            const email = String(e).toLowerCase();
            return { label: email, value: email, groupId: 2 };
          } else {
            return e;
          }
        })
        .filter((e) => e?.value);
    else return [];
  }, [emails]);

  const options = useMemo(() => {
    const inputString = String(input).toLowerCase();
    const inputOption = validateEmail(inputString)
      ? [{ label: inputString, value: inputString, groupId: 1 }]
      : [];

    return [...emailsOptions, ...usersOptions, ...inputOption];
  }, [emailsOptions, usersOptions, input]);

  const values = useMemo(
    () =>
      initialValues
        ? initialValues
            ?.map(
              (v) =>
                _.find(options, (o) => o.value === v) || { label: v, value: v },
            )
            .filter((e) => e?.value)
        : [],
    [initialValues, options],
  );

  const groupedOptions = useMemo(
    () =>
      _.sortBy(
        Object.entries(_.groupBy(options, "groupId")).map(([key, value]) => {
          return {
            groupId: key,
            label: groups?.[key] || key.split(":")[1] || "Unknown",
            options: value,
          };
        }),
        "groupId",
      ),
    [options],
  );

  return (
    <FieldSelect
      value={values}
      options={groupedOptions}
      isMulti
      onInputChange={setInput}
      {...props}
    />
  );
}
