import { number, object, string } from "yup";

const reservationSchema = object().shape({
  id: string().label("Id").required().max(36),
  awsAccount: string().label("Account").max(20),
  awsZone: string().label("Zone").max(20),
  startDate: string().label("Start date").required(),
  endDate: string().label("End date").required(),
  awsInstanceType: string().label("Instance Type").max(20),
  unitPrice: number().label("Unit price $").min(0),
  instanceCount: number().label("Instance count").min(0),
  effectiveMonth: string().label("Effective Month").required(),
});

export default reservationSchema;
