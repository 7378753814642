import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";

import fs from "../../common/functions";
import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../common/functions/state";
import { getSettingsAPI, updateSettingsAPI } from "./api";

export const getSettings = createAsyncThunk(
  "settings/get",
  async (_, { getState, requestId }) => {
    if (!testStateLoading(getState().settings, requestId)) return;

    const { responseData } = await getSettingsAPI();
    return responseData;
  },
);

export const updateSettings = createAsyncThunk(
  "settings/update",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().settings, requestId)) return;

    //payload will represent the new settings, check if they are the different
    const currentSettings = getState().settings.settings;
    const changes = fs.difference(payload, currentSettings);
    if (_.isEmpty(changes)) return;
    const { responseData } = await updateSettingsAPI(changes);
    return responseData;
  },
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    ...INITIAL_LOADING_STATE,
    settings: {},
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getSettings, (state, action) => {
      state.settings = action.payload;
    });
    buildAsyncThunkReducer(builder, updateSettings, (state, action) => {
      if (action.payload) {
        state.settings = { ...state.settings, ...action.payload };
        toast.success("Settings saved", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
  },
});

export const reducer = settingsSlice.reducer;

export const actions = { getSettings, updateSettings };
