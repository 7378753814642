import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function ExpenseNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/expenses">Expenses</NavSidebarItem>
      <div className="dropdown-divider"></div>
      {children}
    </NavSidebar>
  );
}

export function ExpenseIndexNavSidebar() {
  return (
    <ExpenseNavSidebar>
      <NavSidebarItem to="/expenses/trips">Trip</NavSidebarItem>
      <NavSidebarItem to="/expenses/customers">Customer</NavSidebarItem>
      <NavSidebarItem to="/expenses/categories">Other</NavSidebarItem>
      <NavSidebarItem to="/expenses/search">Search</NavSidebarItem>
    </ExpenseNavSidebar>
  );
}
