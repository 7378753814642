import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getCustomerEdiSetups" }).then((response) => {
      return { data: response.responseData.customerEdiSetups };
    });
  },
  getOne: ({ id }) => {
    return shark
      .post({ command: "getCustomerEdiSetup", id: id })
      .then((response) => {
        return { data: response.responseData };
      });
  },
  createOne: (customerEdiSetup) => {
    return shark
      .post({ command: "createCustomerEdiSetup", ...customerEdiSetup })
      .then((response) => {
        return { data: response.responseData };
      });
  },
  updateOne: (customerEdiSetup) => {
    return shark
      .post({ command: "updateCustomerEdiSetup", ...customerEdiSetup })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "customerEdiSetups",
  type: "edi/customerEdiSetups",
  getState: (state) => state.edi.customerEdiSetups,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const customerEdiSetupState = {
  api,
  entity,
  actions,
  reducer,
};

export default customerEdiSetupState;
