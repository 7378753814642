import React, { useMemo } from "react";

import AttachmentsButton from "../../common/details/AttachmentsButton";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import User from "../../users";
import QaStatus from "../qaStatuses";
import { useQaReview } from "./hooks";
import QaReviewLink from "./QaReviewLink";

export default function QaReviewTable({
  qaReviews,
  layout = [
    "QA Review #",
    "CR",
    "QA Status",
    "Reviewer",
    "Date",
    "Attachments",
  ],
  setModifyShow,
  isLoading = false,
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "QA Review #",
        Header: "QA Review #",
        accessor: "id",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (o) => (
          <QaReviewLink
            id={o?.row?.original?.id}
            date={o?.row?.original.reviewDate}
            setModifyShow={setModifyShow}
          >
            {"QA " + o?.row?.original?.id}
          </QaReviewLink>
        ),
      },
      {
        id: "CR",
        Header: "CR",
        accessor: "crId",
      },
      {
        Header: "QA Status",
        id: "QA Status",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ qaStatus }) => QaStatus.entities?.[qaStatus]?.emojii || "",
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        id: "Reviewer",
        Header: "Reviewer",
        accessor: "reviewer",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "reviewDate",
        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
      },
      {
        id: "Attachments",
        Header: "Attachments",
        accessor: "attachments",
        Cell: ({ value, row }) => (
          <QaReviewAttachmentsButton
            attachments={value}
            id={row?.original?.id}
          />
        ),
      },
    ],
    [setModifyShow],
  );
  return (
    <Table
      hasCount
      label="Qa Reviews"
      columns={columns}
      layout={layout}
      data={qaReviews}
      isLoading={isLoading}
      {...props}
    />
  );
}

function QaReviewAttachmentsButton({ attachments, id }) {
  const { createAttachment } = useQaReview({
    id,
    canGet: false,
  });
  return (
    <AttachmentsButton
      attachments={attachments}
      source={{ source: "QA", sourceId: id }}
      create={createAttachment}
    />
  );
}
