import React from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

export default function CrShowToggles({ cr, isLoading, update, isDisabled }) {
  const { isManager, isChampion, isAdmin } = useSelector((state) => state.auth);
  return (
    <Row>
      <Col>
        <Table size="sm" className="mb-0">
          <tbody>
            <tr>
              <td colSpan={2}>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <Form.Check
                      id="isWaitingOnCustomer"
                      inline
                      label="Waiting"
                      checked={cr?.isWaitingOnCustomer || false}
                      onChange={(e) =>
                        update({ isWaitingOnCustomer: e.target.checked })
                      }
                      disabled={isDisabled}
                    />
                    <Form.Check
                      id="isReadyForCodeCheckin"
                      inline
                      label="RFCC"
                      checked={cr?.isReadyForCodeCheckin || false}
                      onChange={(e) =>
                        update({ isReadyForCodeCheckin: e.target.checked })
                      }
                      disabled={isDisabled}
                    />
                    <Form.Check
                      id="isTestingRequired"
                      inline
                      label="Testing Required"
                      checked={cr?.isTestingRequired || false}
                      onChange={(e) =>
                        update({ isTestingRequired: e.target.checked })
                      }
                      disabled={isDisabled}
                    />
                    <Form.Check
                      id="isSAMFollowup"
                      inline
                      label="SAM Follow-Up"
                      checked={cr?.isSAMFollowup || false}
                      onChange={(e) =>
                        update({ isSAMFollowup: e.target.checked })
                      }
                      disabled={
                        isDisabled || !(isManager || isChampion || isAdmin)
                      }
                    />
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
