import { array, boolean, number, object, ref, string } from "yup";

import projectCustomerSchema from "./projectCustomerSchema";

const projectSchema = object().shape({
  synopsis: string().label("Synopsis").required().max(200),
  description: string().label("Description").required().max(5000),
  priorityId: string().label("Priority").required(),
  groupId: string().label("Group").required(),
  sourceId: string().label("Source").required(),
  statusId: string().label("Status").required(),
  typeId: string().label("Type").required(),
  owner: string()
    .label("Owner")
    .required()
    .when("statusId", {
      is: (v) => v !== "OP",
      then: string().test(
        "is-not-pseudo",
        "Pseudo user is only valid on open projects",
        (value) => value?.charAt(0) !== "*",
      ),
    }),
  customers: array().of(projectCustomerSchema),
  fixNotes: string()
    .label("Fix Notes")
    .max(500)
    .when("statusId", {
      is: (v) => v !== "OP",
      then: string().required("Fix notes are required for closed projects"),
    }),
  isSeparateInvoice: boolean().label("Separate invoice?"),
  estimateMinHours: number()
    .label("Estimate Hours (Minimum)")
    .min(0)
    .max(
      ref("estimateMaxHours"),
      "Estimate (hours) minimum must be less than/equal to maximum",
    ),
  estimateMaxHours: number()
    .label("Estimate Hours (Maximum)")
    .min(0)
    .min(
      ref("estimateMinHours"),
      "Estimate (hours) maximum must be greater than/equal to minimum",
    ),
  estimateMinCost: number()
    .label("Estimate Cost (Minimum)")
    .min(0)
    .max(
      ref("estimateMaxCost"),
      "Estimate (cost) minimum must be less than/equal to maximum",
    ),
  estimateMaxCost: number()
    .label("Estimate Cost (Maximum)")
    .min(0)
    .min(
      ref("estimateMinCost"),
      "Estimate (cost) maximum must be greater than/equal to minimum",
    ),
  estimateRate: number()
    .label("Estimate Rate")
    .when("estimateMaxHours", {
      is: (v) => v > 0,
      then: number().positive(),
    }),
  estimateUserId: string().label("Estimate Rate (User)"),
  estimateStatusId: string().label("Estimate Status"),
});

export default projectSchema;
