import { entityDescription } from "../common/entity";
import { useUser } from "./hooks";

const UserDescription = entityDescription({
  name: "user",
  useEntity: useUser,
  label: (u) => u.name,
});

export default UserDescription;
