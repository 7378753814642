import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import TypeDescription from "../../projects/types/TypeDescription";
import TypeTableFilter from "../../projects/types/TypeTableFilter";
import GroupDescription from "../../users/groups/GroupDescription";
import GroupTableFilter from "../../users/groups/GroupTableFilter";
import ContractProductDefaultCrLink from "./ContractProductDefaultCrLink";

export default function ContractProductDefaultCrTable({
  contractProductDefaultCrs,
  contractProductDefaultCrLink = true,
  onClick,
  Components: OverrideComponents,
  layout = ["Id", "Synopsis", "Description", "Group", "Type"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: contractProductDefaultCr } }) =>
          contractProductDefaultCrLink ? (
            <ContractProductDefaultCrLink
              id={contractProductDefaultCr?.id}
              onClick={onClick}
            >
              {value || contractProductDefaultCr?.id}
            </ContractProductDefaultCrLink>
          ) : (
            value
          ),
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Inactive?",
        Header: "Inactive?",
        accessor: ({ isInactive }) => (isInactive ? "☠" : " "),
      },
      {
        id: "Group",
        Header: "Group",
        accessor: "group",
        Filter: GroupTableFilter,
        filter: "equals",
        Cell: ({ value }) => <GroupDescription id={value} />,
      },
      {
        id: "Type",
        Header: "Type",
        accessor: "type",
        Filter: TypeTableFilter,
        filter: "equals",
        Cell: ({ value }) => <TypeDescription id={value} />,
      },
    ],
    [contractProductDefaultCrLink, onClick],
  );
  return (
    <Table
      hasCount
      label="Default Crs"
      columns={columns}
      layout={layout}
      data={contractProductDefaultCrs}
      getRowProps={(row) => {
        if (row.isGrouped) return {};
        if (row?.original.isInactive)
          return {
            className: "table-danger",
          };
        else return {};
      }}
      {...props}
    />
  );
}
