import React from "react";
import { Link } from "react-router-dom";

import { useAwsAccount } from "./hooks";

export default function AwsAccountLink({ id, children }) {
  const { awsAccount } = useAwsAccount({ id });

  if (!awsAccount?.id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Link
        className="text-reset"
        to={{ pathname: `/servers/aws-accounts/${id}` }}
      >
        {children || awsAccount?.description}
      </Link>
    </span>
  );
}
