import _ from "lodash";
import { useEffect } from "react";
import tinykeys from "tinykeys";

export default function useTinykeys(cfg = {}) {
  useEffect(() => {
    if (cfg && !_.isEmpty(cfg)) {
      // console.debug("keys: Add", cfg);
      const unsubscribe = tinykeys(window, cfg);
      return () => {
        // console.debug("keys: Remove", cfg);
        unsubscribe();
      };
    }
  });
}
