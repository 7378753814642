import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../../common/functions/state";
import {
  getNotificationAPI,
  getNotificationsAPI,
  updateNotificationAPI,
} from "./api";

const notificationsAdapter = createEntityAdapter();

const getNotifications = createAsyncThunk(
  "notification/getMany",
  async (filter, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.notifications, requestId)) return;

    const { responseData } = await getNotificationsAPI(filter);
    return responseData.notifications;
  },
);

const getNotification = createAsyncThunk(
  "notification/get",
  async (filter, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.notifications, requestId)) return;

    const { responseData } = await getNotificationAPI(filter);
    return responseData;
  },
);

export const updateNotification = createAsyncThunk(
  "notification/update",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.notifications, requestId)) return;

    const { responseData } = await updateNotificationAPI(payload);
    return responseData;
  },
);

const notificationsSlice = createSlice({
  name: "notification",
  initialState: notificationsAdapter.getInitialState({
    ...INITIAL_LOADING_STATE,
    lastUpdate: null,
  }),
  reducers: {
    clearNotifications(state) {
      notificationsAdapter.removeAll(state);
      state.requests = INITIAL_LOADING_STATE.requests;
      state.lastUpdate = null;
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getNotifications, (state, action) => {
      if (action.payload.length !== 0) {
        notificationsAdapter.upsertMany(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, getNotification, (state, action) => {
      if (action.payload.length !== 0) {
        notificationsAdapter.upsertOne(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, updateNotification, (state, action) => {
      if (action.payload) {
        notificationsAdapter.upsertOne(state, action.payload);
        toast.success("Notification record saved", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
  },
});

export const reducer = notificationsSlice.reducer;

const { clearNotifications } = notificationsSlice.actions;
export const actions = {
  getNotifications,
  getNotification,
  clearNotifications,
  updateNotification,
};

export const selectors = notificationsAdapter.getSelectors(
  (state) => state.activity.notifications,
);
