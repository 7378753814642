import { createEntityHooks } from "../../common/entity";
import awsInstanceTypeState from "./state";

export const {
  useEntities: useAwsInstanceTypes,
  useEntity: useAwsInstanceType,
} = createEntityHooks({
  entity: awsInstanceTypeState.entity,
  actions: awsInstanceTypeState.actions,
});
