import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import HostingAgreementNavSidebar from "./HostingAgreementNavSidebar";

export default function HostingAgreementIndex() {
  const customerId = useSearchParams("customer");

  const { hostingAgreementAccess, isAdmin } = useSelector(
    (state) => state.auth,
  );
  if (
    !(
      hostingAgreementAccess === "V" ||
      hostingAgreementAccess === "M" ||
      isAdmin
    )
  )
    return (
      <Page
        title="Customer Hosting Agreement"
        navSidebar={<HostingAgreementNavSidebar customerId={customerId} />}
        lead="You do not have access to this feature"
      />
    );

  return <HostingAgreementIndexDisplay />;
}

function HostingAgreementIndexDisplay() {
  const customerId = useSearchParams("customer");
  const { customer } = Customer.useOne({ id: customerId, canGet: false });

  const { entities, reset, getMany, loading } =
    Customer.HostingAgreements.useAll({
      canGet: false,
    });

  const title = customer?.name
    ? `${customer?.name} Hosting Agreements`
    : "Customer Hosting Agreements";

  useEffect(() => {
    reset();
    getMany({ customerId });
  }, [customerId, getMany, reset]);
  return (
    <Page
      title={title}
      navSidebar={<HostingAgreementNavSidebar customerId={customerId} />}
    >
      <Customer.HostingAgreements.Table
        data={entities}
        isLoading={loading}
        onRefresh={() => {
          reset();
          getMany({ customerId });
        }}
      />
    </Page>
  );
}
