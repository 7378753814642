import { createEntityHooks } from "../../common/entity";
import state from "./state";

const { useEntities: useHostingAgreementMonths, useEntity } = createEntityHooks(
  {
    entity: state.entity,
    actions: state.actions,
  },
);

const useHostingAgreement = ({ id, ...args } = {}) => {
  return { ...useEntity({ id, ...args }) };
};

export { useHostingAgreement, useHostingAgreementMonths };
