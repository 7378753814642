import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import ContractProductCategoryLink from "./ContractProductCategoryLink";

export default function ContractProductCategoryTable({
  contractProductCategories,
  contractProductCategoryLink = true,
  Components: OverrideComponents,
  layout = ["Id", "Description"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: contractProductCategory } }) =>
          contractProductCategoryLink ? (
            <ContractProductCategoryLink id={contractProductCategory?.id}>
              {value || contractProductCategory?.id}
            </ContractProductCategoryLink>
          ) : (
            value
          ),
      },
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [contractProductCategoryLink],
  );
  return (
    <Table
      hasCount
      label="Contract Product Categories"
      columns={columns}
      layout={layout}
      data={contractProductCategories}
      {...props}
    />
  );
}
