import { useReservation, useReservations } from "./hooks";
import ReservationComparisonTable from "./ReservationComparisonTable";
import ReservationEditor from "./ReservationEditor";
import ReservationLink from "./ReservationLink";
import ReservationSelect from "./ReservationSelect";
import Table from "./ReservationTable";
import reservationState from "./state";

const Reservation = {
  // API
  api: reservationState.api,
  // State
  actions: reservationState.actions,
  selectors: reservationState.entity.selectors,
  // Hooks
  useOne: useReservation,
  useAll: useReservations,
  // Components
  Table,
  ComparisonTable: ReservationComparisonTable,
  Link: ReservationLink,
  Editor: ReservationEditor,
  Select: ReservationSelect,
  // Related Entity Objects
};

export default Reservation;
