import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import CustomerEdiSetupEdit from "./CustomerEdiSetupEdit";
import CustomerEdiSetupIndex from "./CustomerEdiSetupIndex";
import CustomerEdiSetupNew from "./CustomerEdiSetupNew";

export default function CustomerEdiSetupsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <CustomerEdiSetupNew />
      </Route>
      <Route path={`${path}/:id`}>
        <CustomerEdiSetupEdit />
      </Route>
      <Route exact path={`${path}`}>
        <CustomerEdiSetupIndex />
      </Route>
    </Switch>
  );
}
