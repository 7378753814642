import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import NotificationGroupEdit from "./NotificationGroupEdit";
import NotificationGroupIndex from "./NotificationGroupIndex";
import NotificationGroupNavSidebar from "./NotificationGroupNavSidebar";
import NotificationGroupNew from "./NotificationGroupNew";

export default function NotificationGroupsPages() {
  let { path } = useRouteMatch();

  const { isManager, isAdmin } = useSelector((state) => state.auth);

  if (!(isManager || isAdmin))
    return (
      <Page
        title="Notification Group"
        navSidebar={<NotificationGroupNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <NotificationGroupNew />
      </Route>
      <Route path={`${path}/:id`}>
        <NotificationGroupEdit />
      </Route>
      <Route exact path={`${path}`}>
        <NotificationGroupIndex />
      </Route>
    </Switch>
  );
}
