import { combineReducers } from "@reduxjs/toolkit";

import difficultyState from "../difficulties/state";
import filterState from "../filters/state";
import modulesState from "../modules/state";
import notificationTypeState from "../notificationTypes/state";
import qaReviewsState from "../qaReviews/state";
import statusState from "../statuses/state";
import tableLayoutState from "../tableLayouts/state";
import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const crReducer = combineReducers({
  crs: reducer,
  filters: filterState.reducer,
  statuses: statusState.reducer,
  notificationTypes: notificationTypeState.reducer,
  difficulties: difficultyState.reducer,
  modules: modulesState.reducer,
  tableLayouts: tableLayoutState.reducer,
  qaReviews: qaReviewsState.reducer,
});

const crState = {
  api,
  entity,
  actions,
  reducer: crReducer,
};

export default crState;
