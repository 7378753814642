import React from "react";

import { SelectColumnFilterWithOptions } from "../../common/tables/TableFilters";
import { useContractProductCategories } from "./hooks";

export default function ContractProductCategoryTableFilter({ column }) {
  const { filterValue } = column;
  const { contractProductCategories } = useContractProductCategories();
  const _options = contractProductCategories
    .filter((s) => s.isInactive === false || s.id === filterValue)
    .map((f) => {
      return { label: f.description, value: f.id };
    });
  return <SelectColumnFilterWithOptions column={column} options={_options} />;
}
