import React from "react";

import NoEscapeModal from "./NoEscapeModal";

// Don't rename modalTitle to Title..
// there is something weird going on with that prop
export default function DeletingModal({
  modalTitle = "",
  bodyHeader = "Deleting",
  body,
  show,
}) {
  return (
    <NoEscapeModal
      show={show}
      modalTitle={modalTitle}
      bodyHeader={bodyHeader}
      body={body}
    />
  );
}
