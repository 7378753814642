import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "watch",
  type: "watches",
  getState: (state) => state.activity.watches,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const watchState = {
  api,
  actions,
  reducer,
  entity,
};

export default watchState;
