import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Project from "../projects";
import { isTierOneGroup } from "../users/groups";
import CrEditor from "./CrEditor";
import { crSchemaInitial } from "./crSchema";
import useCr from "./useCr";

export default function CrNew({ cr: initialValues, onNew = () => {} }) {
  const { createOne } = useCr();
  const [error, setError] = useState(null);

  const { defaultOwner, defaultGroupId } = useSelector(
    (state) => state.settings.settings,
  );

  const { projectId } = initialValues;
  const { project } = Project.useOne({
    id: projectId,
  });

  const { groupId, owner, synopsis, description } = useMemo(() => {
    let synopsis = "",
      description = "";
    if (!project?.crs?.length) {
      synopsis = project?.synopsis;
      description = project?.description;
    }

    const groupId = project ? project.groupId : defaultGroupId || "";
    const owner = defaultOwner
      ? defaultOwner
      : isTierOneGroup(groupId)
      ? "*TR1*"
      : "";

    return {
      owner,
      groupId,
      synopsis,
      description,
    };
  }, [project, defaultOwner, defaultGroupId]);

  const defaultValues = crSchemaInitial({
    projectId,
    groupId,
    owner,
    synopsis,
    description,

    //Use initial values from project prop, but ignore any undefined
    ..._.pickBy(initialValues || {}, (v) => !_.isUndefined(v)),
  });

  return (
    <CrEditor
      cr={defaultValues}
      isNew
      onSubmit={(newCr) => {
        createOne(newCr)
          .then(onNew)
          .catch((error) => setError(error.message));
      }}
      error={error}
      project={project}
    />
  );
}
