import { useSource, useSources } from "./hooks";
import SourceDescription from "./SourceDescription";
import SourceSelect from "./SourceSelect";
import SourceTableFilter from "./SourceTableFilter";
import sourceState from "./state";

const Source = {
  // Backend API
  api: sourceState.api,
  // State
  actions: sourceState.actions,
  selectors: sourceState.entity.selectors,
  // Hooks
  useAll: useSources,
  useOne: useSource,
  // Components
  Select: SourceSelect,
  Description: SourceDescription,
  TableFilter: SourceTableFilter,
};
export default Source;
