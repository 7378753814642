import moment from "moment";
import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useSelector } from "react-redux";

import { _date } from "../../../common/functions/dates";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import ServerNavSidebar from "../ServerNavSidebar";

const today = _date.stamp(moment());

export default function ReservationIndex() {
  const [active, setActive] = useState(true);
  const { reservations, loading, reset } = Server.Reservation.useAll({
    canGet: true,
  });

  const data = useMemo(() => {
    return reservations.filter((e) => {
      const expired = today > e.endDate;
      return expired === !active;
    });
  }, [active, reservations]);

  const { isAdmin, canMaintainServers } = useSelector((state) => state.auth);

  if (!isAdmin && !canMaintainServers)
    return (
      <Page
        title="Reservation"
        navSidebar={<ServerNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title={"Reservations"} navSidebar={<ServerNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Server.Reservation.Table
        reservations={data}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
