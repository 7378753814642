import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import UserGroupingEdit from "./UserGroupingEdit";
import UserGroupingIndex from "./UserGroupingIndex";
import UserGroupingNavSidebar from "./UserGroupingNavSidebar";
import UserGroupingNew from "./UserGroupingNew";

export default function UserGroupingsPages() {
  let { path } = useRouteMatch();

  const { isManager, isAdmin, canMaintainContracts } = useSelector(
    (state) => state.auth,
  );

  if (!(isManager || isAdmin || canMaintainContracts))
    return (
      <Page
        title="User Grouping"
        navSidebar={<UserGroupingNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <UserGroupingNew />
      </Route>
      <Route path={`${path}/:id`}>
        <UserGroupingEdit />
      </Route>
      <Route exact path={`${path}`}>
        <UserGroupingIndex />
      </Route>
    </Switch>
  );
}
