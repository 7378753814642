import { entitySelect } from "../../common/entity";
import { useReservations } from "./hooks";

const ReservationSelect = entitySelect({
  name: "reservation",
  useEntities: useReservations,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.id,
});

export default ReservationSelect;
