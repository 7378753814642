import React from "react";
import { useSelector } from "react-redux";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function UserNavSidebar({ children }) {
  const { isManager, isAdmin, canMaintainContracts } = useSelector(
    (state) => state.auth,
  );
  return (
    <NavSidebar>
      <NavSidebarItem to="/users">Users</NavSidebarItem>

      {isManager || isAdmin ? (
        <NavSidebarItem to="/users/notification-groups">
          Notification Groups
        </NavSidebarItem>
      ) : null}
      {isManager || isAdmin || canMaintainContracts ? (
        <NavSidebarItem to="/users/user-groupings">
          User Groupings
        </NavSidebarItem>
      ) : null}
      {isAdmin ? (
        <NavSidebarItem to="/users/pseudos">Pseudo Users</NavSidebarItem>
      ) : null}
      <div className="dropdown-divider" />
      {children}
    </NavSidebar>
  );
}
