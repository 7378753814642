import React, { useEffect, useState } from "react";
import { Collapse, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import User from "../../users";
import { useController, useFormContext } from "../forms";
import useOwnerGroupFields from "../hooks/forms/useGroupOwnerFields";

export default function DetailTableEdit({ isDisabled, children }) {
  return (
    <Table hover={!isDisabled} size="sm" className="mb-0">
      {children}
    </Table>
  );
}
function TableRow({
  name,
  label,
  value,
  isLoading,
  isDisabled = false,
  getRowProps = () => {},
  as,
  ...props
}) {
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (isLoading || isDisabled) setEdit(false);
  }, [isLoading, isDisabled]);

  const [title, onClickShowEdit] =
    as && !isDisabled && !isLoading
      ? ["Click to edit", () => setEdit(true)]
      : ["", undefined];

  return (
    <tr onClick={onClickShowEdit} title={title} {...getRowProps()}>
      <th scope="row" key="label">
        {isLoading ? <Skeleton /> : label}
      </th>
      {as ? (
        <Collapse in={edit}>
          <td colSpan={2}>
            <Input
              name={name}
              onBlur={() => setEdit(false)}
              focus={edit}
              as={as}
              {...props}
            />
          </td>
        </Collapse>
      ) : null}
      <Collapse in={!edit}>
        <td key="value">{isLoading ? <Skeleton /> : value}</td>
      </Collapse>
    </tr>
  );
}

function Input({ name, onBlur: onBlurProp, focus, as: As, ...props }) {
  const {
    field: { onBlur: onBlurField, ...field },
    fieldState: { error },
  } = useController({ name });

  const onBlur = (e) => {
    onBlurProp(e);
    onBlurField(e);
  };

  const { setFocus } = useFormContext();

  useEffect(() => {
    if (focus) setTimeout(() => setFocus(name));
  }, [name, focus, setFocus]);

  return (
    <As
      {...field}
      onBlur={onBlur}
      onMenuClose={onBlur}
      isInvalid={!!error}
      errors={error?.message}
      formGroupClassName="py-0 my-0"
      blurInputOnSelect
      openMenuOnFocus
      {...props}
    />
  );
}

function OwnerGroup({ entity, isLoading, isDisabled }) {
  const { handleGroupChange, handleOwnerChange } = useOwnerGroupFields();
  return (
    <>
      <TableRow
        name="owner"
        label="Owner"
        value={<User.Link id={entity?.owner} />}
        as={User.Select}
        isLoading={isLoading}
        onChange={handleOwnerChange}
        pseudo
        isClearable
        isDisabled={isDisabled}
      />
      <TableRow
        name="groupId"
        label={
          <User.Group.Label
            onChosen={(value) => {
              handleGroupChange(value);
            }}
          />
        }
        value={<User.Group.Description id={entity?.groupId} />}
        as={User.Group.Select}
        isLoading={isLoading}
        onChange={handleGroupChange}
        isDisabled={isDisabled}
      />
    </>
  );
}

DetailTableEdit.Row = TableRow;
DetailTableEdit.Row.OwnerGroup = OwnerGroup;
