import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import AwsAccount from "../../../servers/awsAccounts";
import AwsAccountNavSidebar from "./AwsAccountNavSidebar";

export default function AwsAccountNew() {
  const { createOne } = AwsAccount.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title="Aws Account" navSidebar={<AwsAccountNavSidebar />}>
      <AwsAccount.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(awsAccount) =>
          createOne(awsAccount).then(({ id }) =>
            setRedirect(`/servers/aws-accounts/${id}`),
          )
        }
      />
    </Page>
  );
}
