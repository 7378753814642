import React, { useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";

import useClickOutsideEvent from "../hooks/useClickOutsideEvent";

export default function ContextMenu({
  show,
  target,
  onHide,
  closeOnClick = true,
  placement = "bottom",
  title,
  children,
}) {
  const ref = useRef(null);
  useClickOutsideEvent(ref, () => {
    onHide();
  });
  return (
    <Overlay show={show} target={target} placement={placement} onHide={onHide}>
      <Popover onClick={closeOnClick ? onHide : undefined}>
        <div ref={ref}>
          {title ? <Popover.Title as="h4">{title}</Popover.Title> : null}
          <Popover.Content className="p-0">{children}</Popover.Content>
        </div>
      </Popover>
    </Overlay>
  );
}
