import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { BiEdit } from "react-icons/bi";
import { MdCallSplit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import tinykeys from "tinykeys";

import FieldDate from "../common/fields/FieldDate";
import FieldHours from "../common/fields/FieldHours";
import { _date, _datetime } from "../common/functions/dates";
import { timerCalculation } from "../common/functions/timerCalculation";
import CenteredModal from "../common/modals/CenteredModal";
import DeletingModal from "../common/modals/DeletingModal";
import GetReasonModal from "../common/modals/GetReasonModal";
import OkCancelModal from "../common/modals/OkCancelModal";
import SavingModal from "../common/modals/SavingModal";
import Customer from "../customers";
import User from "../users";
import TimeBillableOverrideReason from "./billableOverrideReasons";
import TimeHoldReason from "./holdReasons";
import TimeInternalProject from "./internalProjects";
import { timeActions } from "./state";
import TimeCrEditor from "./TimeCrEditor";
import TimeTimersTable from "./TimeTimersTable";
import timeTypes from "./timeTypes";
import TimeTypesSelect from "./timeTypeSelector";

const statusIncomplete = "statusIncomplete";
const statusNull = "statusNull";
const statusDone = "statusDone";

// Time Form Mode = "new", "edit"
// onSaveSuccessfull = callback when save is successfully submitted
//
export default function TimeForm({
  mode,
  onSaveSuccess,
  onCancel,
  onDelete,
  time,
  openTimers = false,
  startTimer = false,
  stopTimer = false,
  onSplit = () => {},
}) {
  if (time.showOnInvoice === null) time.showOnInvoice = true;

  // For some reason eslint-react can't handle so many temp variables
  // and or computations. Putting all the logic into this program
  // avoided the false hits
  const ds = useDefaultState({ time, mode, startTimer });

  const timeIsInvoiced =
    time.invoiceNumber !== null && time.invoiceNumber !== "";

  const methods = useForm({
    defaultValues: {
      original: ds,
      splitWasPerformed: false,
      splitIsVisible: false,
      originalIsVisible: false,
      defaultOriginalTimers: JSON.stringify([]),
      defaultSplitTimers: JSON.stringify([]),
      split: ds,
    },
  });
  const splitWasPerformed = methods.watch("splitWasPerformed");
  const splitIsVisible = methods.watch("splitIsVisible");
  const originalIsVisible = methods.watch("originalIsVisible");
  const defaultOriginalTimers = methods.watch("defaultOriginalTimers");
  const defaultSplitTimers = methods.watch("defaultSplitTimers");
  const splitId = methods.watch("split.id");

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(() => {})}>
          {splitWasPerformed ? (
            <Accordion>
              <Card bg="light">
                {originalIsVisible ? (
                  <>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Original - Split From Record
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <TimeFormSingle
                        defaultTimers={JSON.parse(defaultOriginalTimers)}
                        timeIsInvoiced={timeIsInvoiced}
                        stopTimer={stopTimer}
                        openTimers={openTimers}
                        mode={mode}
                        originalCustomerId={ds.customerId}
                        splitRecord={"original"}
                        onSaveSuccess={onSaveSuccess}
                        onDelete={onDelete}
                        onCancel={onCancel}
                      />
                    </Accordion.Collapse>
                  </>
                ) : null}
                {splitIsVisible ? (
                  <>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        {"New - Split To Record: " + splitId}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <TimeFormSingle
                        defaultTimers={JSON.parse(defaultSplitTimers)}
                        timeIsInvoiced={timeIsInvoiced}
                        stopTimer={stopTimer}
                        openTimers={openTimers}
                        mode={mode}
                        originalCustomerId={ds.customerId}
                        splitRecord={"split"}
                        onSaveSuccess={onSaveSuccess}
                        onDelete={onDelete}
                        onCancel={onCancel}
                      />
                    </Accordion.Collapse>
                  </>
                ) : null}
              </Card>
            </Accordion>
          ) : (
            <TimeFormSingle
              time={time}
              defaultTimers={time.timers}
              timeIsInvoiced={timeIsInvoiced}
              stopTimer={stopTimer}
              openTimers={openTimers}
              mode={mode}
              splitRecord={"original"}
              originalCustomerId={ds.customerId}
              onSaveSuccess={onSaveSuccess}
              onDelete={onDelete}
              onCancel={onCancel}
              onSplit={onSplit}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
}

// The meat of the form can be duplicated between
// the original record and the new split out record.
// prefix tells us what version we are dealing with.
function TimeFormSingle({
  defaultTimers,
  timeIsInvoiced,
  stopTimer,
  openTimers = false,
  mode,
  originalCustomerId,
  splitRecord,
  onSaveSuccess,
  onDelete,
  onCancel,
  onSplit,
}) {
  const dispatch = useDispatch();

  const { setValue } = useFormContext();

  const f = useGetAllFormVals({
    mode,
    timeIsInvoiced,
    stopTimer,
    splitRecord,
  });

  const [showTimers, setShowTimers] = useState(openTimers);
  const [hasSetAllInfo, setHasSetAllInfo] = useState(false);
  const [workLogHelpModalShow, setWorkLogHelpModalShow] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [splittingTimers, setSplittingTimers] = useState(false);
  const [, setSplitOrigHours] = useState(0.0);
  const [splitNewHours, setSplitNewHours] = useState(0.0);
  const [splitNewTimers, setSplitNewTimers] = useState([]);

  // Saving related
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showReasonTitle, setShowReasonTitle] = useState("");
  const [showReasonAction, setShowReasonAction] = useState("");
  const [modifyReason, setModifyReason] = useState("");
  const [saving, setSaving] = useState(false);
  const [savingSplit, setSavingSplit] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [askForTravelBac, setAskForTravelBac] = useState(false);
  const [bacTravelInformation, setBacTravelInformation] = useState({});
  const [travelBacReason, setTravelBacReason] = useState("");
  const loggedInUser = useSelector((state) => state.auth.user);

  const wasMyTimeRecord = loggedInUser.trim() === f.originalUserId.trim();
  const isMyTimeRecord = loggedInUser.trim() === f.userId.trim();

  useEffect(() => {
    let unsubscribe = tinykeys(window, {
      "$mod+s": shortcutSave,
      "$mod+Enter": shortcutSave,
    });
    return () => {
      unsubscribe();
    };
  });

  let deleteDisabled = true;
  if (!timeIsInvoiced) {
    deleteDisabled = false;
  }

  //-----------------Save button ----------------------//
  let saveDisabled = true;
  if (!timeIsInvoiced) {
    saveDisabled = f.sectionStatuses.saveDisabled;
  }
  //TODO check other section

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (saving === true) {
      const payload1 = {
        ...f.createTimePayload,
        travelBacReason: travelBacReason,
        overrideReason: modifyReason,
      };
      //            hours: splitOrigHours,
      //timers: splitOrigTimers,
      const payload = savingSplit
        ? {
            ...payload1,
            split: true,
            splitHours: splitNewHours,
            splitTimers: splitNewTimers,
          }
        : { ...payload1, split: false };
      if (savingSplit) {
        if (
          payload.timers.length === payload.splitTimers.length &&
          payload.hours === payload.splitHours
        ) {
          toast.error("Split from time record would be left with no time");
          setSaving(false);
          setSavingSplit(false);
          return;
        } else if (
          payload.splitTimers.length === 0 &&
          payload.splitHours === 0
        ) {
          toast.error("No time was selected to be split out");
          setSaving(false);
          setSavingSplit(false);
          return;
        }
      }

      let promiseResult =
        mode === "new"
          ? dispatch(timeActions.createTime(payload))
          : dispatch(timeActions.updateTime(payload));
      promiseResult
        .then(unwrapResult)
        .then((result) => {
          if (f.splitWasPerformed) {
            if (
              (splitRecord === "original" && !f.splitIsVisible) ||
              (splitRecord === "split" && !f.originalIsVisible)
            ) {
              onSaveSuccess();
            } else {
              setValue(splitRecord + "IsVisible", false);
            }
          } else if (savingSplit) {
            const ov = getDefaultState({ time: result, mode: mode });
            const nv = getDefaultState({
              time: result.splitTimeRecord,
              mode: mode,
            });

            setValue("splitIsVisible", true);
            setValue("originalIsVisible", true);
            setValue("defaultOriginalTimers", JSON.stringify(result.timers));
            setValue(
              "defaultSplitTimers",
              JSON.stringify(result.splitTimeRecord.timers),
            );
            //setValue("original", ov);
            //setValue("split", nv);
            for (var propt in ov) {
              //if (propt === "cr") continue;
              setValue("original." + propt, ov[propt]);
            }
            for (var propt2 in nv) {
              //if (propt2 === "cr") continue;
              setValue("split." + propt2, nv[propt2]);
            }
            // this must be last
            setValue("splitWasPerformed", true);
            onSplit();
          } else {
            onSaveSuccess();
          }
          setSaving(false);
          setSavingSplit(false);
        })
        .catch((result) => {
          console.log(result);
          setSaving(false);
          setSavingSplit(false);
        });
    }
    //TODO review dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saving]);
  //do not include f.createTimePayload to the above line
  //multiple saves were being sent off.

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (deleting === true) {
      dispatch(
        timeActions.deleteTime({ id: f.id, overrideReason: modifyReason }),
      )
        .then(unwrapResult)
        .then(() => {
          setDeleting(false);
          if (
            (splitRecord === "original" && !f.splitIsVisible) ||
            (splitRecord === "split" && !f.originalIsVisible)
          ) {
            onDelete({ id: f.id });
          } else {
            setValue(splitRecord + "IsVisible", false);
          }
        })
        // eslint-disable-next-line unused-imports/no-unused-vars
        .catch((result) => {
          setDeleting(false);
        });
    }
    //TODO review dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleting]);

  const clickCancel = () => {
    if (f.splitWasPerformed) {
      if (
        (splitRecord === "original" && !f.splitIsVisible) ||
        (splitRecord === "split" && !f.originalIsVisible)
      ) {
        onSaveSuccess();
      } else {
        setValue(splitRecord + "IsVisible", false);
      }
    } else {
      onCancel();
    }
  };

  const clickSave = useCallback(() => {
    if (saveDisabled) {
      toast.error("Cannot save, please fully fill out the form");
      return;
    }

    if ((!wasMyTimeRecord && mode !== "new") || !isMyTimeRecord) {
      setShowReasonAction("save");
      if (mode === "new") {
        setShowReasonTitle(
          "You are adding time for another user.\nPlease enter your reason.",
        );
      } else {
        setShowReasonTitle(
          "You are changing another user's time.\nPlease enter your reason.",
        );
      }
      setShowReasonModal(true);
    } else {
      if (f.isTravelType && f.travelInfo.needBacApproval) {
        setAskForTravelBac(true);
        let temp = { ...f.travelInfo };
        temp.totalHours = f.totalHours;
        setBacTravelInformation(temp);
      } else {
        setSaving(true);
      }
    }
  }, [
    f.isTravelType,
    f.totalHours,
    f.travelInfo,
    isMyTimeRecord,
    mode,
    saveDisabled,
    wasMyTimeRecord,
  ]);

  function shortcutSave(event) {
    event.preventDefault();
    // Cannot use ctrl s to save when you have a modal up.
    if (
      !showDeleteModal &&
      !showSplitModal &&
      !showTimers &&
      !showReasonModal &&
      !askForTravelBac
    )
      clickSave();
  }

  //-----------------Changing form fields ----------------------//
  // Global section
  const changeDate = (newDate) => {
    setValue(splitRecord + ".datetime", _datetime.stamp(newDate));
  };
  const changeHours = (e) => {
    setValue(splitRecord + ".hours", e?.value);
  };
  const changeUserId = (v) => {
    setValue(splitRecord + ".userId", v);
  };
  const changeInternalComments = (e) => {
    setValue(splitRecord + ".internalComments", e.target.value);
  };
  const changeTimeType = (e) => {
    if (e?.value === f.timeType) return;

    if (e?.value === timeTypes.onsite.value) {
      setValue(splitRecord + ".billingNote", "On-site");
    } else if (e?.value === timeTypes.travel.value) {
      setValue(splitRecord + ".billingNote", "");
      setValue(splitRecord + ".locationId", "");
    } else {
      if (f.isTravelType || f.isOnsiteType) {
        if (_.isUndefined(f.lastManualbillingNote)) {
          setValue(splitRecord + ".billingNote", "");
        } else {
          setValue(splitRecord + ".billingNote", f.lastManualbillingNote);
        }
      }
    }
    setValue(splitRecord + ".timeType", e?.value);
  };

  // Interal project section
  const changeInternalProject = (v) => {
    setValue(splitRecord + ".internalProjectId", v);
  };

  // Travel: Setting the location you were at or traveled to/from
  const changeLocationId = (v) => {
    setValue(splitRecord + ".locationId", v?.value);

    if (f.isTravelType) {
      const desc = v?.value === "other" ? "XXXXXX" : v?.label;

      if (f.travelDirection === "F") {
        setValue(splitRecord + ".billingNote", "Travel from " + desc);
      } else if (f.travelDirection === "T") {
        setValue(splitRecord + ".billingNote", "Travel to " + desc);
      }
    } else setValue(splitRecord + ".billingNote", "On-site");
  };

  // Travel to/from
  const changeTravelDirection = (v) => {
    setValue(splitRecord + ".travelDirection", v);
    if (!_.isEmpty(f.billingNote)) {
      if (v === "F") {
        setValue(
          splitRecord + ".billingNote",
          f.billingNote.replace("to", "from"),
        );
      } else if (v === "T") {
        setValue(
          splitRecord + ".billingNote",
          f.billingNote.replace("from", "to"),
        );
      }
    }
  };

  const changeOverrideBillableFlag = useCallback(
    (v) => {
      if (f.showOnInvoice === false && v === true)
        setValue(splitRecord + ".showOnInvoice", true);

      setValue(splitRecord + ".overrideBillableFlag", v);
    },
    [splitRecord, setValue, f.showOnInvoice],
  );

  const changeBillableOverrideReasonId = (value) => {
    if (f.billableOverrideReasonId === value) return;

    const oldBors = f.billableOverrideReasonId;

    setValue(splitRecord + ".billableOverrideReasonId", value);

    if (f.borsState.entities[value]?.billFlagDefault === "N") {
      changeOverrideBillableFlag(false);
      if (value === "HELP" || value === "FIXN" || value === "QA")
        setValue(splitRecord + ".showOnInvoice", false);
      else if (oldBors === "HELP" || oldBors === "FIXN" || oldBors === "QA")
        setValue(splitRecord + ".showOnInvoice", true);
    } else {
      changeOverrideBillableFlag(true);
      if (f.showOnInvoice === false)
        setValue(splitRecord + ".showOnInvoice", true);
    }
  };

  const handleCrChange = (crNext) => {
    if (crNext.setallHold || crNext.setallBillability) {
      toast.warning("'Set All Time' settings found and applied");
      setHasSetAllInfo(true);

      setValue(
        splitRecord + ".internalComments",
        crNext.setallInternalComments,
      );

      if (crNext.setallHold) {
        setValue(splitRecord + ".holdDate", crNext.setallHoldDate);
        setValue(splitRecord + ".holdReasonId", crNext.setallHoldReasonId);
      }
      if (crNext.setallBillability) {
        setValue(
          splitRecord + ".billableOverrideReasonId",
          crNext.setallBillableOverrideReasonId,
        );
        setValue(
          splitRecord + ".overrideBillableFlag",
          crNext.setallOverrideBillableFlag,
        );

        if (crNext.setallInvoiceFlag) {
          setValue(splitRecord + ".showOnInvoice", crNext.setallShowOnInvoice);
        } else {
          changeOverrideBillableFlag(false);
          if (
            crNext.setallBillableOverrideReasonId === "HELP" ||
            crNext.setallBillableOverrideReasonId === "FIXN" ||
            crNext.setallBillableOverrideReasonId === "QA"
          ) {
            setValue(splitRecord + ".showOnInvoice", false);
          }
        }
      }
    } else {
      if (hasSetAllInfo) {
        setValue(splitRecord + ".billableOverrideReasonId", null);
        setValue(splitRecord + ".showOnInvoice", true);
        setValue(splitRecord + ".holdReasonId", null);
        setValue(splitRecord + ".holdDate", null);
        setValue(splitRecord + ".internalComments", "");
        toast.warning("'Set All Time' settings unapplied");
      }
      setHasSetAllInfo(false);
      if (
        f.billableOverrideReasonId === null ||
        _.isEmpty(f.billableOverrideReasonId)
      )
        setValue(splitRecord + ".showOnInvoice", true);
    }
    setValue(splitRecord + ".locationId", "");
    setValue(splitRecord + ".crId", crNext.id);
    setValue(splitRecord + ".customerId", crNext?.customers?.[0]?.id);
    setValue(splitRecord + ".cr", { ...crNext });
    setValue(splitRecord + ".crIsValid", true);
  };

  const changeHoldReasonId = (value) => {
    setValue(splitRecord + ".holdReasonId", value);
  };
  const changeHoldDate = (v) => {
    setValue(splitRecord + ".holdDate", _date.stamp(v.value));
  };

  // Work log section
  const changedWorklog = (chosenArray) => {
    let newContacted = false;
    let newPerformed = false;
    let newNeither = false;
    for (let i = 0; i < chosenArray.length; i++) {
      if (chosenArray[i] === 1) newContacted = true;
      if (chosenArray[i] === 2) newPerformed = true;
      if (chosenArray[i] === 3) newNeither = true;
    }
    if (f.isNeither === false && newNeither === true) {
      newContacted = false;
      newPerformed = false;
    } else if (f.isNeither && (newContacted || newPerformed)) {
      newNeither = false;
    }

    setValue(splitRecord + ".isCustomerContacted", newContacted);
    setValue(splitRecord + ".isWorkPerformed", newPerformed);
    setValue(splitRecord + ".isNeither", newNeither);
  };

  const timersTableOnCancel = useCallback(
    () => setShowTimers(false),
    [setShowTimers],
  );

  const finalizeSplit = useCallback(() => {
    setSplittingTimers(false);
    setSavingSplit(true);
    clickSave();
  }, [clickSave]);

  const timerstTableOnOk = useCallback(
    (timers) => {
      setShowTimers(false);
      if (splittingTimers) {
        const splitTimers = timers
          .filter((t) => t.selected === true)
          .map((t) => t.startTime);

        setSplitNewTimers(splitTimers);
        setSplittingTimers(false);
        finalizeSplit();
      }
      setValue(splitRecord + ".timers", JSON.stringify(timers));
    },
    [splittingTimers, setValue, splitRecord, finalizeSplit],
  );

  //-----------------Work log JSX  -----------------//
  const workLogSection = (
    <WorkLogToggle
      onChange={changedWorklog}
      value={f.workLogValue}
      clickedHelp={() => setWorkLogHelpModalShow(true)}
      workLogSectionStatus={f.sectionStatuses.workLog}
      showOnInvoice={f.showOnInvoice || _.isEmpty(f.borsRecord)}
      isCustomerType={f.isCustomerType}
      timeIsInvoiced={timeIsInvoiced}
    />
  );

  const splitButtonShown =
    !f.splitWasPerformed && !timeIsInvoiced && mode !== "new";

  const cancelSaveOrDeleteOrSplit = () => {
    setShowReasonModal(false);
    setSavingSplit(false);
    setShowDeleteModal(false);
  };

  return (
    <>
      {/* -------------- Modal Section ----------------*/}
      <SavingModal show={saving} />
      <DeletingModal show={deleting} />
      <GetReasonModal
        show={showReasonModal}
        modalTitle={showReasonTitle}
        onCancel={cancelSaveOrDeleteOrSplit}
        defaultReason={mode === "new" ? "Entering time for another user" : ""}
        onOk={(reason) => {
          setModifyReason(reason);
          setShowReasonModal(false);
          if (showReasonAction === "save") {
            setSaving(true);
          } else {
            setDeleting(true);
          }
        }}
      />
      <GetReasonModal
        show={askForTravelBac}
        modalTitle={"BAC approval for travel time"}
        modalBodyHeader={
          <>
            <Row>
              <Col>
                {"You entered " + bacTravelInformation.totalHours + " hours."}
              </Col>
            </Row>
            <Row>
              <Col>
                {"Customer only allows for a max of " +
                  (bacTravelInformation.nonBillableHours +
                    bacTravelInformation.billableHours) +
                  " BAC applicable hours."}
              </Col>
            </Row>
            <Row className={"pb-3"}>
              <Col></Col>
            </Row>
            <Row className={"pb-3"}>
              <Col>
                {
                  "If you would like to ask for approval of additional BAC hours then please explain your reason below."
                }
              </Col>
            </Row>
          </>
        }
        okText={"Ask for approval"}
        cancelText={"Don't ask"}
        onCancel={() => {
          setAskForTravelBac(false);
          setSaving(true);
        }}
        defaultReason={""}
        onOk={(reason) => {
          setTravelBacReason(reason);
          setAskForTravelBac(false);
          setSaving(true);
        }}
      />

      <OkCancelModal
        show={showDeleteModal}
        onCancel={cancelSaveOrDeleteOrSplit}
        onOk={() => {
          setShowDeleteModal(false);
          setDeleting(true);
        }}
        modalTitle="Confirm deletion"
        body="Are you sure you want to delete this time record?"
      />
      <CenteredModal
        show={workLogHelpModalShow}
        onClose={() => setWorkLogHelpModalShow(false)}
        modalTitle="Work log help"
        body={<WorkLogBody />}
      />

      {showSplitModal ? (
        <SplitModal
          okButtonText={f.timers.length > 0 ? "Continue" : "Perform Split"}
          show={showSplitModal}
          hours={f.hours}
          onOk={(hours, split) => {
            setSplitNewHours(split);
            setSplitOrigHours(hours);
            setShowSplitModal(false);
            if (f.timers.length > 0) {
              setShowTimers(true);
              setSplittingTimers(true);
            } else {
              setSplitNewTimers([]);
              finalizeSplit();
            }
          }}
          onCancel={() => setShowSplitModal(false)}
        />
      ) : null}

      <TimeTimersTable
        show={showTimers}
        onCancel={timersTableOnCancel}
        onOk={timerstTableOnOk}
        timers={defaultTimers}
        date={f.momentDatetime}
        disabled={timeIsInvoiced}
        splitMode={splittingTimers}
      />
      <Form.Group className="mb-0">
        <ListGroup variant="flush">
          {timeIsInvoiced ? (
            <ListGroup.Item variant="danger" className="pt-1">
              <Row>
                <Col>
                  {"Time record is now closed. "}
                  {f.invoiceNumber !== "LOCKED"
                    ? "It was built onto invoice #" + f.invoiceNumber + "."
                    : null}
                </Col>
              </Row>
            </ListGroup.Item>
          ) : (
            <></>
          )}
          {/* -------------Normal Time entry Section ----------------*/}
          <ListGroup.Item
            variant={getListItemVariant(f.sectionStatuses.top)}
            className="pt-1"
          >
            <Row className="py-0 mb-1">
              <Col lg={5} xs={6} className="pb-0 mb-0">
                <Form.Label className="mb-0">User</Form.Label>
                <User.Select
                  onChange={changeUserId}
                  value={f.userId}
                  formGroupClassName="mb-0"
                  {...f.userSelectProps}
                />
              </Col>
              <Col lg={7} xs={12} className="pb-0">
                <FieldDate
                  onChange={changeDate}
                  value={f.momentDatetime}
                  includeTime={true}
                  disabled={f.dateEditDisabled}
                  noDateChange={f.hasTimers | f.startTimer}
                  labelClassName="mb-0"
                />
              </Col>
            </Row>
            <Row className="py-0 mb-1">
              <Col md={splitButtonShown ? 3 : 4} xs={6}>
                <Form.Label className="mb-0">Hours</Form.Label>
                <FieldHours
                  id="hours"
                  value={f.hours}
                  onChange={changeHours}
                  disabled={timeIsInvoiced}
                />
              </Col>
              <Col md={4} xs={6}>
                <Form.Label className="mb-0">Timer Hours</Form.Label>
                <Button
                  block
                  variant={f.runningTimer ? "success" : "primary"}
                  disabled={f.startTimer}
                  onClick={() => {
                    mode !== "new"
                      ? setShowTimers(true)
                      : setValue(splitRecord + ".startTimer", true);
                  }}
                >
                  {f.startTimer ? (
                    "Starting..."
                  ) : f.timers.length === 0 ? (
                    "None"
                  ) : (
                    <div className="clearfix">
                      <span className="float-left">
                        {f.timerHoursFormatted}
                      </span>
                      <span className="text-center">{"rounds to"}</span>
                      <span className="float-right">
                        {" "}
                        {Number(f.timerHours).toFixed(2) +
                          (f.runningTimer ? "*" : "")}
                      </span>
                    </div>
                  )}
                </Button>
              </Col>
              <Col md={splitButtonShown ? 3 : 4} xs={6}>
                <Form.Label className="mb-0">Total Hours</Form.Label>
                <Form.Control
                  type="numeric"
                  placeholder=""
                  readOnly
                  value={Number(f.totalHours).toFixed(2)}
                />
              </Col>
              {splitButtonShown ? (
                <Col md={1} xs={2}>
                  <Form.Label className="mb-0">Split</Form.Label>
                  <Button
                    onClick={() => {
                      if (f.sectionStatuses.saveDisabled) {
                        toast.error(
                          "Cannot split because the time entry is incomplete",
                        );
                      } else if (f.isTravelType) {
                        toast.error("Cannot split a travel time record");
                      } else if (f.hours !== 0.0) setShowSplitModal(true);
                      else if (f.timers.length > 0) {
                        setSplittingTimers(true);
                        setShowTimers(true);
                      } else
                        toast.error("Cannot split when there is zero time");
                    }}
                  >
                    <MdCallSplit className="pl-0 ml-0" size="28px" />
                  </Button>
                </Col>
              ) : null}
            </Row>
            <Row className="mb-1">
              <Col>
                <Form.Label className="mb-0">Billing Note</Form.Label>
                <TextareaAutosize
                  name="billingNote"
                  type="text"
                  placeholder="Billing Note..."
                  as="textarea"
                  className="form-control"
                  minRows={2}
                  onChange={(e) => {
                    setValue(splitRecord + ".billingNote", e.target.value);
                    setValue(
                      splitRecord + ".lastManualbillingNote",
                      e.target.value,
                    );
                  }}
                  value={f.billingNote}
                  readOnly={timeIsInvoiced || f.billingNoteDisabled}
                  maxLength={500}
                />
              </Col>
            </Row>
          </ListGroup.Item>

          {/* ------------ Time Type Section ----------------*/}
          <ListGroup.Item
            variant={getListItemVariant(f.sectionStatuses.timeType)}
            className="pt-1"
          >
            <Row>
              <Col>
                <Form.Label className="mb-0">Time Type</Form.Label>
                <TimeTypesSelect
                  id="timeType"
                  defaultValue={f.timeType}
                  onChange={changeTimeType}
                  disabled={timeIsInvoiced}
                />
              </Col>
            </Row>
            {f.timeType ? null : (
              <Row>
                <Col>
                  <Form.Label>Please choose a time Type</Form.Label>
                </Col>
              </Row>
            )}
          </ListGroup.Item>

          {/* ------------ Internal type Section ----------------*/}
          {f.isInternalType ? (
            <ListGroup.Item
              className="pt-1"
              variant={getListItemVariant(f.sectionStatuses.internalProject)}
            >
              <Row>
                <Col>
                  <Form.Label className="mb-0">Internal Project</Form.Label>
                  <TimeInternalProject.Select
                    id="internalProjectId"
                    value={f.internalProjectId}
                    isClearable={false}
                    onChange={changeInternalProject}
                  />
                </Col>
                <Col></Col>
              </Row>
            </ListGroup.Item>
          ) : f.typeRequiresCr ? (
            <>
              {/* ------------ CR # Section ----------------*/}
              {f.crIsValid ? (
                <>
                  <ListGroup.Item
                    variant={getListItemVariant(f.sectionStatuses.cr)}
                    className="pt-1"
                  >
                    <CrDetails
                      cr={f.cr}
                      disabled={timeIsInvoiced}
                      onChangeClick={() => {
                        setValue(splitRecord + ".crIsValid", false);
                        setValue(splitRecord + ".crId", null);
                      }}
                      originalCustomerId={originalCustomerId}
                    />
                  </ListGroup.Item>
                  {f.isOnsiteType || f.isTravelType ? (
                    <ListGroup.Item
                      variant={getListItemVariant(f.sectionStatuses.crLocation)}
                      className="pt-1"
                    >
                      <Row>
                        {f.isTravelType ? (
                          <Col xs="auto">
                            <Form.Label className="mb-0 mr-3">
                              {"Travel "}
                            </Form.Label>
                            <Button
                              className="rounded-0"
                              {...f.travelToProps}
                              onClick={() => changeTravelDirection("T")}
                            >
                              To
                            </Button>
                            <Button
                              className="rounded-0"
                              {...f.travelFromProps}
                              onClick={() => changeTravelDirection("F")}
                            >
                              From
                            </Button>
                          </Col>
                        ) : null}

                        <Col>
                          {/* ------------ On-Site Location ----------------*/}
                          {f.isOnsiteType ? (
                            <Form.Label className="mb-0">
                              {"Customer Location"}
                            </Form.Label>
                          ) : null}
                          <Customer.LocationSelect
                            formGroupClassName="mb-0"
                            customerId={f.cr.customers[0].id}
                            value={f.locationId}
                            onChange={changeLocationId}
                            disabled={timeIsInvoiced || f.locationDisabled}
                            addOther={true}
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ) : null}
                </>
              ) : (
                <ListGroup.Item
                  variant={getListItemVariant(f.sectionStatuses.cr)}
                  className="pt-1"
                >
                  <TimeCrEditor
                    value={f.crId}
                    onChange={(cr) => {
                      if (cr) handleCrChange(cr);
                      else {
                        setValue(splitRecord + ".crIsValid", false);
                        setValue(splitRecord + ".crId", null);
                        setValue(splitRecord + ".cr", null);
                      }
                    }}
                    disabled={timeIsInvoiced}
                    defaultCustomerId={f.customerId}
                    validateOnMount={f.crId && !f.crIsValid}
                  />
                </ListGroup.Item>
              )}
              {/* ------------ CR Section ----------------*/}

              {f.crIsValid ? (
                <>
                  {f.isOnsiteType === false &&
                  f.isTravelType === false &&
                  !f.cr?.isInternalCr
                    ? workLogSection
                    : null}
                  {/* -------------- Billable Override Section --------------*/}
                  <ListGroup.Item
                    className="py-1"
                    variant={f.billabilitySection ? "success" : "danger"}
                  >
                    <Row>{f.billMessage}</Row>
                    {f.billMessage2 !== null ? (
                      <Row>{f.billMessage2}</Row>
                    ) : (
                      <></>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="pt-1"
                    variant={getListItemVariant(f.sectionStatuses.bors)}
                  >
                    <Row>
                      <Col>
                        <Form.Label className="mb-0">
                          Billable override reason
                        </Form.Label>
                        <TimeBillableOverrideReason.Select
                          value={f.billableOverrideReasonId}
                          onChange={changeBillableOverrideReasonId}
                          disabled={timeIsInvoiced}
                          isClearable={true}
                        />
                      </Col>
                      <Col>
                        {f.borsRecord != null ? (
                          <>
                            <Row>
                              <Col>
                                <Form.Label className="mb-0">
                                  Override Billability
                                </Form.Label>
                              </Col>
                              <Col>
                                <Form.Label className="mb-0">
                                  Show on Invoice
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  className="rounded-0"
                                  {...f.billableOverrideYesProps}
                                  onClick={() =>
                                    changeOverrideBillableFlag(true)
                                  }
                                >
                                  Bill
                                </Button>
                                <Button
                                  className="rounded-0"
                                  {...f.billableOverrideNoProps}
                                  onClick={() =>
                                    changeOverrideBillableFlag(false)
                                  }
                                >
                                  {"Don't"}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  className="rounded-0"
                                  {...f.showOnInvoiceYesProps}
                                  onClick={() =>
                                    setValue(
                                      splitRecord + ".showOnInvoice",
                                      true,
                                    )
                                  }
                                >
                                  Show
                                </Button>
                                <Button
                                  className="rounded-0"
                                  {...f.showOnInvoiceNoProps}
                                  onClick={() =>
                                    setValue(
                                      splitRecord + ".showOnInvoice",
                                      false,
                                    )
                                  }
                                >
                                  Hide
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {/* -------------- Hold Section --------------*/}
                  {f.crIsValid && f.cr.isInternalCr ? null : (
                    <ListGroup.Item
                      className="pt-1"
                      variant={getListItemVariant(f.sectionStatuses.hold)}
                    >
                      <Row>
                        <Col>
                          <Form.Label className="mb-0">Hold Reason</Form.Label>
                          <TimeHoldReason.Select
                            value={f.holdReasonId}
                            onChange={changeHoldReasonId}
                            disabled={timeIsInvoiced}
                            isClearable={true}
                          />
                        </Col>
                        <Col>
                          {f.holdReasonRecord != null ? (
                            <>
                              <Row>
                                <Col>
                                  <Form.Label className="mb-0">
                                    Hold until
                                  </Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <TimeHoldReason.DateSelect
                                    value={f.holdDateMoment}
                                    onChange={changeHoldDate}
                                    date={f.momentDatetime}
                                    isClearable={false}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  {/* -------------- Internal Comments Section --------------*/}
                  <ListGroup.Item
                    className="pt-1"
                    variant={getListItemVariant(
                      f.sectionStatuses.internalComments,
                    )}
                  >
                    <Row>
                      <Col>
                        <Form.Label className="mb-0">
                          Internal Comments
                        </Form.Label>
                        <TextareaAutosize
                          name="comment"
                          type="text"
                          placeholder={f.internalCommentHelp}
                          as="textarea"
                          className="form-control"
                          minRows={1}
                          onChange={changeInternalComments}
                          value={f.internalComments}
                          readOnly={timeIsInvoiced}
                          maxLength={120}
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </>
              ) : null}
            </>
          ) : f.isCustomerType ? (
            <>
              {/* -------------- Customer Section --------------*/}
              <ListGroup.Item
                variant={getListItemVariant(f.sectionStatuses.noCrCustomer)}
              >
                <Row className="mb-0">
                  <Col xs={6}>
                    <Form.Label>Customer</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Customer.Select
                      formGroupClassName="mb-0"
                      value={f.customerId}
                      onChange={(v) => setValue(splitRecord + ".customerId", v)}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setValue(splitRecord + ".timeType", timeTypes.cr.value);
                        setValue(splitRecord + ".crIsValid", false);
                      }}
                    >
                      Assign CR
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
              {f.customerId !== null &&
              f.customerId !== "" &&
              !f.customerValue.customer?.isInternal
                ? workLogSection
                : null}
            </>
          ) : null}
          {!timeIsInvoiced ? (
            <ListGroup.Item
              className="mx-0 my-0 py-1"
              variant={saveDisabled ? "warning" : "primary"}
            >
              <Row className="mb-0 pb-0">
                <Col className="px-1">
                  <Button
                    variant={saveDisabled ? "dark" : "primary"}
                    disabled={saveDisabled}
                    block
                    className="rounded-0"
                    type="submit"
                    onClick={() => clickSave()}
                  >
                    Save
                  </Button>
                </Col>
                <Col className="px-1">
                  <Button
                    variant={saving ? "dark" : "secondary"}
                    disabled={saving || deleting}
                    block
                    className="rounded-0"
                    type="submit"
                    onClick={() => clickCancel()}
                  >
                    Cancel
                  </Button>
                </Col>
                {mode !== "new" ? (
                  <Col className="px-1">
                    <Button
                      variant={saving ? "dark" : "danger"}
                      disabled={saving || deleting || deleteDisabled}
                      block
                      className="rounded-0"
                      type="submit"
                      onClick={() => {
                        if (wasMyTimeRecord) {
                          setShowDeleteModal(true);
                        } else {
                          setShowReasonModal(true);
                          setShowReasonTitle(
                            "You are deleting another user's time. \n Please enter your reason.",
                          );
                          setShowReasonAction("delete");
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </Form.Group>
    </>
  );
}

function WorkLogToggle({
  onChange,
  clickedHelp,
  value,
  workLogSectionStatus,
  showOnInvoice,
  isCustomerType,
  timeIsInvoiced,
}) {
  const clicked = (v) => {
    let newArray = [];
    let found = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === v) found = true;
      else newArray.push(value[i]);
    }
    if (!found) newArray.push(v);
    onChange(newArray);
  };

  let performed = false;
  let contacted = false;
  let neither = false;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === 1) contacted = true;
    else if (value[i] === 2) performed = true;
    else if (value[i] === 3) neither = true;
  }

  return (
    <ListGroup.Item variant={getListItemVariant(workLogSectionStatus)}>
      {showOnInvoice === true || isCustomerType ? (
        <Row className="justify-content-between">
          <Col xs={"auto"}>
            <Button variant="dark" disabled className="rounded-0">
              Worklog
            </Button>
          </Col>
          <Col xs={"auto"} className="btn-group">
            <Button
              className="rounded-0"
              onClick={() => clicked(1)}
              variant={getWorkLogVariant(contacted)}
              disabled={timeIsInvoiced}
            >
              Customer was contacted
            </Button>
            <Button
              className="rounded-0"
              onClick={() => clicked(2)}
              variant={getWorkLogVariant(performed)}
              disabled={timeIsInvoiced}
            >
              Work was performed
            </Button>
            <Button
              className="rounded-0"
              onClick={() => clicked(3)}
              variant={getWorkLogVariant(neither)}
              disabled={timeIsInvoiced}
            >
              Neither
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              className="rounded-0"
              variant="warning"
              onClick={(e) => {
                e.preventDefault();
                clickedHelp();
              }}
            >
              Help!
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Button variant="dark" disabled className="rounded-0">
              Worklog
            </Button>
            {
              "  Work log is not applicable when time is hidden from the invoice"
            }
          </Col>
        </Row>
      )}
    </ListGroup.Item>
  );
}

function getWorkLogVariant(boolean) {
  if (boolean) return "success";
  else return "outline-success";
}

function WorkLogBody() {
  return (
    <ListGroup class="container" style={{ textAlign: "center" }}>
      <ListGroup.Item variant="dark" className="py-1 font-weight-bold">
        Customer was contacted
      </ListGroup.Item>
      <ListGroup.Item variant="success" className="py-1">
        You talked to the customer
      </ListGroup.Item>
      <ListGroup.Item variant="success" className="py-1">
        You left them a voicemail
      </ListGroup.Item>
      <ListGroup.Item variant="success" className="py-1">
        You sent them an email
      </ListGroup.Item>
      <ListGroup.Item variant="danger" className="py-1">
        DO NOT CHOOSE this if you are unable to reach the customer and
        don&apos;t leave them a message
      </ListGroup.Item>
      <ListGroup.Item></ListGroup.Item>
      <ListGroup.Item
        variant="dark"
        style={{ textAlign: "center" }}
        className="py-1 font-weight-bold"
      >
        Work was performed
      </ListGroup.Item>
      <ListGroup.Item variant="success" className="py-1">
        Choose this if some progress was made on the issue. <br />
        Examples include... training, configuring, troubleshooting, debugging,
        programming
      </ListGroup.Item>
      <ListGroup.Item variant="danger" className="py-1">
        DO NOT CHOOSE this if you are passing it off to someone else or another
        team
      </ListGroup.Item>
      <ListGroup.Item variant="danger" className="py-1">
        DO NOT CHOOSE this if you are learning about the system before you work
        on it
      </ListGroup.Item>
      <ListGroup.Item variant="danger">
        DO NOT CHOOSE this if you are doing initial research and don&apos;t plan
        on taking ownership
      </ListGroup.Item>
      <ListGroup.Item></ListGroup.Item>
      <ListGroup.Item variant="dark" className="py-1 font-weight-bold">
        Note: You can pick both
      </ListGroup.Item>
    </ListGroup>
  );
}

// Should never return danger
function getListItemVariant(status) {
  if (status === statusIncomplete) return "warning";
  else if (status === statusNull) return "secondary";
  else if (status === statusDone) return "primary";
  else return "danger";
}

function getLocationDisabled(
  isOnsiteType,
  isTravelType,
  travelDirection,
  isValidatingCrPr,
) {
  if (isValidatingCrPr) return true;

  if (isOnsiteType || isTravelType) {
    if (isOnsiteType || travelDirection === "F" || travelDirection === "T")
      return false;
  }
  return true;
}

function getTravelInfo(totalHours, customer, locationId) {
  let output = { needBacApproval: false };

  if (_.isEmpty(customer?.travelInformation)) {
    output.billableHours = 0;
    output.nonBillableHourse = 0;
    output.billable = true;
    return output;
  }

  output.billableHours = customer.travelInformation.billableHours;
  output.nonBillableHours = customer.travelInformation.nonBillableHours;
  output.billable = customer.travelInformation.billable;

  if (!_.isEmpty(locationId)) {
    const found = customer.locations.find((e) => e.code === locationId);
    if (!_.isEmpty(found)) {
      if (found.travelInformation.billableHours !== 0) {
        output.billableHours = found.travelInformation.billableHours;
      }
      if (found.travelInformation.nonBillableHours !== 0) {
        output.nonBillableHours = found.travelInformation.nonBillableHours;
      }
    }
  }

  output.needBacApproval =
    totalHours > output.billableHours + output.nonBillableHours;

  return output;
}

function useDefaultState({ time, mode, startTimer }) {
  return getDefaultState({ time, mode, startTimer });
}

function getDefaultState({ time, mode, startTimer }) {
  let output = {};
  output.id = time?.id;
  output.userId = time?.userId;
  output.originalUserId = output.userId;
  output.datetime = _.isEmpty(time?.datetime)
    ? _datetime.stamp(moment())
    : time?.datetime;
  output.hours =
    time != null && time.hours != null ? parseFloat(time.hours) : 0;
  output.billingNote = time?.billingNote;
  output.lastManualbillingNote = time?.billingNote;
  output.timeType =
    time?.crId != null && mode === "new" ? timeTypes.cr.value : time?.timeType;

  output.internalComments = time?.internalComments;

  output.internalProjectId = time?.internalProjectId;

  output.customerId =
    time?.customer?.id ||
    time?.customers?.[0].id ||
    time?.cr?.customers?.[0].id;

  output.locationId = time?.locationId;

  output.travelDirection = time?.travelDirection;

  output.billableOverrideReasonId = time?.billableOverrideReasonId;
  output.overrideBillableFlag = time?.overrideBillableFlag;
  output.showOnInvoice = time?.showOnInvoice;

  output.holdReasonId = time?.holdReasonId;
  output.holdDate = time.holdDate !== "" ? time.holdDate : null;

  //  time != null &&
  //  time.holdDate !== null &&
  //  !_.isUndefined(time.holdDate) &&
  //  time.holdDate !== ""
  //    ? _date.fromStamp(time.holdDate)
  //    : null;

  output.isCustomerContacted = time?.isCustomerContacted;
  output.isWorkPerformed = time?.isWorkPerformed;
  output.isNeither = time?.isNeither;

  output.crId = time?.cr !== null ? time?.crId?.toString() : "";
  output.originalCrId = output.crId;

  output.cr = time?.cr;
  output.timers = JSON.stringify(time?.timers);

  const requiresCrType = doesTypeRequireCr(output.timeType);
  //CrInput related
  output.crIsValid = requiresCrType && (mode === "modify" || time.cr?.id);

  output.originalIsBillable = time?.isBillable;

  output.createDateTime = time.createDateTime;
  output.modifyDateTime = time.modifyDateTime;
  output.invoiceNumber = time.invoiceNumber;
  output.startTimer = startTimer;
  return output;
}

/*
function calculateTimerHours(dateTime, timersArray) {
  if (timersArray === null) return 0;
  let seconds = 0;
  const dbcdate = _date.stamp(dateTime);
  for (let i = 0; i < timersArray.length; i++) {
    const start = _datetime.fromStamp(dbcdate + timersArray[i].startTime);
    const end =
      timersArray[i].endTime === ""
        ? moment()
        : _datetime.fromStamp(dbcdate + timersArray[i].endTime);

    seconds = seconds + end.diff(start, "seconds");
  }
  return Math.ceil(seconds / (15 * 60)) / 4;
}
*/

function CrDetails({ cr, onChangeClick, disabled, originalCustomerId }) {
  if (!cr) return null;

  const { customers } = cr;

  return (
    <Form.Group className="mb-0">
      <Customer.ChangedAlert
        current={originalCustomerId}
        next={customers?.[0].id}
      />
      <Row>
        <Col xs="auto">
          <Form.Row>
            <Col>
              <h5 className="mb-0">CR {cr.id}</h5>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>Project {cr.projectId}</Col>
          </Form.Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <strong style={{ whiteSpace: "pre-line" }}>
                {customers.map((c) => c?.name || "").join(",\n")}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{cr.synopsis}</p>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={onChangeClick}
            disabled={disabled}
            title="Change CR #"
          >
            {"Change "}
            <BiEdit />
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
}

function getSectionStatuses({
  userId,
  hoursInvalid,
  timeType,
  internalProjectId,
  billingNote,
  crIsValid,
  locationId,
  travelDirection,
  billableOverrideReasonId,
  holdReasonId,
  holdDate,
  internalComments,
  cr,
  isCustomerContacted,
  isWorkPerformed,
  isNeither,
  customerId,
  showOnInvoice,
  mode,
  startTimer,
  customerValue,
  borsRecord,
  holdReasonRecord,
}) {
  const billingNoteInvalid = !getIsBillingNoteValid(billingNote);

  const topSectionStatus = getTopSectionStatus(
    userId,
    hoursInvalid,
    billingNoteInvalid,
  );
  const timeTypeSectionStatus = getTypeSectionStatus(timeType);

  const internalProjectSectionStatus =
    getInternalProjectSectionStatus(internalProjectId);

  const crSectionStatus = getCrSectionStatus(crIsValid);

  const crLocationStatus = getLocationSectionStatus({
    crIsValid,
    timeType,
    locationId,
    travelDirection,
  });

  const borsSectionStatus = getBorsSectionStatus({
    crIsValid,
    isInternalCr: cr?.isInternalCr,
    borsRecord,
  });

  const holdSectionStatus = getHoldSectionStatus({
    crIsValid,
    isInternalCr: cr?.isInternalCr,
    holdDate,
    holdReasonRecord,
  });

  const internalCommentSectionStatus = getInternalCommentsSectionStatus({
    internalComments,
    billableOverrideReasonId,
    holdReasonId,
    borsRecord,
    holdReasonRecord,
  });

  const workLogSectionStatus = getWorkLogSectionStatus({
    isCustomerContacted,
    isWorkPerformed,
    isNeither,
    timeType,
    customerId,
    showOnInvoice,
    crIsValid,
    mode,
    startTimer,
    customerValue,
    cr,
  });

  const noCrCustomerSectionStatus = _.isNil(customerId)
    ? statusIncomplete
    : statusDone;

  let saveDisabled = true;
  if (topSectionStatus === statusDone && timeTypeSectionStatus === statusDone) {
    if (timeType === timeTypes.internal.value) {
      if (internalProjectSectionStatus !== statusIncomplete)
        saveDisabled = false;
    } else if (timeType === timeTypes.customer.value) {
      if (
        noCrCustomerSectionStatus !== statusIncomplete &&
        workLogSectionStatus !== statusIncomplete
      )
        saveDisabled = false;
    } else if (doesTypeRequireCr(timeType)) {
      if (
        workLogSectionStatus !== statusIncomplete &&
        crLocationStatus !== statusIncomplete &&
        borsSectionStatus !== statusIncomplete &&
        holdSectionStatus !== statusIncomplete &&
        internalCommentSectionStatus !== statusIncomplete &&
        crSectionStatus !== statusIncomplete
      )
        saveDisabled = false;
    }
  }

  return {
    top: topSectionStatus,
    timeType: timeTypeSectionStatus,
    internalProject: internalProjectSectionStatus,
    cr: crSectionStatus,
    crLocation: crLocationStatus,
    bors: borsSectionStatus,
    hold: holdSectionStatus,
    internalComments: internalCommentSectionStatus,
    workLog: workLogSectionStatus,
    noCrCustomer: noCrCustomerSectionStatus,
    saveDisabled: saveDisabled,
  };
}

function doesTypeRequireCr(timeType) {
  return (
    timeType === timeTypes.travel.value ||
    timeType === timeTypes.onsite.value ||
    timeType === timeTypes.cr.value
  );
}

function getTopSectionStatus(userId, hoursInvalid, billingNoteInValid) {
  return _.isEmpty(userId) || hoursInvalid || billingNoteInValid
    ? statusIncomplete
    : statusDone;
}

function getTypeSectionStatus(timeType) {
  return _.isEmpty(timeType) ? statusIncomplete : statusDone;
}

function getInternalProjectSectionStatus(internalProjectId) {
  return _.isEmpty(internalProjectId) ? statusIncomplete : statusDone;
}

function getCrSectionStatus(crIsValid) {
  return crIsValid ? statusDone : statusIncomplete;
}

function getLocationSectionStatus({
  crIsValid,
  timeType,
  locationId,
  travelDirection,
}) {
  let output = statusNull;

  const isTravelType = timeType === timeTypes.travel.value;
  const isOnsiteType = timeType === timeTypes.onsite.value;

  if (crIsValid && (isOnsiteType || isTravelType)) {
    if (_.isEmpty(locationId)) {
      output = statusIncomplete;
    } else if (
      isTravelType &&
      travelDirection !== "F" &&
      travelDirection !== "T"
    ) {
      output = statusIncomplete;
    } else {
      output = statusDone;
    }
  }
  return output;
}

function getBorsSectionStatus({ crIsValid, isInternalCr, borsRecord }) {
  return crIsValid && isInternalCr
    ? statusNull
    : _.isEmpty(borsRecord)
    ? statusNull
    : statusDone;
}

function getHoldSectionStatus({
  crIsValid,
  isInternalCr,
  holdDate,
  holdReasonRecord,
}) {
  return crIsValid && isInternalCr
    ? statusNull
    : _.isEmpty(holdReasonRecord)
    ? statusNull
    : _.isEmpty(holdDate) ||
      (typeof holdDate.isValid === "function" && !holdDate.isValid())
    ? statusIncomplete
    : statusDone;
}

function getInternalCommentsSectionStatus({
  internalComments,
  billableOverrideReasonId,
  holdReasonId,
  borsRecord,
  holdReasonRecord,
}) {
  return !_.isEmpty(internalComments) && internalComments.trim() !== ""
    ? statusDone
    : (!_.isEmpty(borsRecord) && billableOverrideReasonId === "OTHR") ||
      (!_.isEmpty(holdReasonRecord) && holdReasonId === "OTHR")
    ? statusIncomplete
    : statusNull;
}

function getWorkLogSectionStatus({
  isCustomerContacted,
  isWorkPerformed,
  isNeither,
  timeType,
  customerId,
  showOnInvoice,
  crIsValid,
  mode,
  startTimer,
  customerValue,
  cr,
}) {
  let workLogSectionStatus =
    isCustomerContacted || isWorkPerformed || isNeither
      ? statusDone
      : statusIncomplete;

  if (
    timeType === timeTypes.cr.value &&
    (customerId === null ||
      customerId === "" ||
      customerValue.customer?.isInternal)
  )
    workLogSectionStatus = statusNull;

  if (
    timeType === timeTypes.customer.value &&
    customerValue?.customer?.isInternal
  )
    workLogSectionStatus = statusNull;

  if (timeType === timeTypes.cr.value) {
    if (crIsValid && cr.isInternalCr) workLogSectionStatus = statusNull;
    if (showOnInvoice === false) workLogSectionStatus = statusNull;
  }

  if (startTimer && mode === "new" && workLogSectionStatus === statusIncomplete)
    workLogSectionStatus = statusNull;

  if (
    timeType === timeTypes.onsite.value ||
    timeType === timeTypes.travel.value
  ) {
    workLogSectionStatus = statusNull;
  }
  return workLogSectionStatus;
}

function getIsBillingNoteValid(billingNote, timeType) {
  const invalid =
    _.isEmpty(billingNote) ||
    billingNote.trim() === "" ||
    (billingNote.includes("XXXXXX") && timeType === timeTypes.travel.value);
  return !invalid;
}

// The point of this hook is to pay attention to all the fields
// along with building up "so to say" work variables based on those fields
// Then the "original" or "split" record can go off the same logic
// and then even the save button can use this logic
function useGetAllFormVals({ timeIsInvoiced, mode, stopTimer, splitRecord }) {
  const { watch } = useFormContext();
  const [, setNow] = useState(moment());
  const o = {};

  o.id = watch(splitRecord + ".id");
  o.startTimer = watch(splitRecord + ".startTimer");
  o.invoiceNumber = watch(splitRecord + ".invoiceNumber");
  o.createDateTime = watch(splitRecord + ".createDateTime");
  o.modifyDateTime = watch(splitRecord + ".modifyDateTime");
  o.splitWasPerformed = watch("splitWasPerformed");
  o.splitIsVisible = watch("splitIsVisible");
  o.originalIsVisible = watch("originalIsVisible");
  o.datetime = watch(splitRecord + ".datetime");
  o.momentDatetime = _datetime.fromStamp(o.datetime);

  o.userId = watch(splitRecord + ".userId");
  o.originalUserId = watch(splitRecord + ".originalUserId");
  o.hours = watch(splitRecord + ".hours");
  o.billingNote = watch(splitRecord + ".billingNote");
  o.lastManualbillingNote = watch(splitRecord + ".lastManualbillingNote");
  o.timeType = watch(splitRecord + ".timeType");
  o.crId = watch(splitRecord + ".crId");
  o.originalCrId = watch(splitRecord + ".originalCrId");
  o.cr = watch(splitRecord + ".cr");
  o.customerId = watch(splitRecord + ".customerId");
  o.locationId = watch(splitRecord + ".locationId");
  o.internalProjectId = watch(splitRecord + ".internalProjectId");
  o.travelDirection = watch(splitRecord + ".travelDirection");
  o.holdReasonId = watch(splitRecord + ".holdReasonId");
  o.holdDate = watch(splitRecord + ".holdDate");
  o.holdDateMoment =
    o.holdDate !== null && o.holdDate !== ""
      ? _date.fromStamp(o.holdDate)
      : null;
  o.internalComments = watch(splitRecord + ".internalComments");
  o.billableOverrideReasonId = watch(splitRecord + ".billableOverrideReasonId");
  o.showOnInvoice = watch(splitRecord + ".showOnInvoice");
  o.overrideBillableFlag = watch(splitRecord + ".overrideBillableFlag");
  const timers = watch(splitRecord + ".timers");
  o.timers = _.isUndefined(timers) ? [] : JSON.parse(timers);

  o.isCustomerContacted = watch(splitRecord + ".isCustomerContacted");
  o.isWorkPerformed = watch(splitRecord + ".isWorkPerformed");
  o.isNeither = watch(splitRecord + ".isNeither");

  // Billable override reasons and record, same with hold reasons
  o.originalIsBillable = watch(splitRecord + ".originalIsBillable");
  o.borsState = useSelector((state) => state.times.billableOverrideReasons);
  o.borsRecord = o.borsState.entities[o.billableOverrideReasonId];
  o.holdReasonsState = useSelector((state) => state.times.holdReasons);
  o.holdReasonRecord = o.holdReasonsState.entities[o.holdReasonId];

  o.timeIsNotInvoiced = !timeIsInvoiced;
  o.isTravelType = o.timeType === timeTypes.travel.value;
  o.isOnsiteType = o.timeType === timeTypes.onsite.value;
  o.isCrType = o.timeType === timeTypes.cr.value;
  o.isInternalType = o.timeType === timeTypes.internal.value;
  o.isCustomerType = o.timeType === timeTypes.customer.value;
  o.typeRequiresCr = doesTypeRequireCr(o.timeType);
  o.customerValue = Customer.useOne({ id: o.customerId });

  o.crIsValid = watch(splitRecord + ".crIsValid");

  o.isBillingNoteValid = getIsBillingNoteValid(o.billingNote, o.timeType);

  o.workLogValue = [];
  if (o.isCustomerContacted) o.workLogValue.push(1);
  if (o.isWorkPerformed) o.workLogValue.push(2);
  if (o.isNeither) o.workLogValue.push(3);

  const { roundedHours: timerHours, hhmmss: timerHoursFormatted } =
    timerCalculation(o.momementDatetime, o.timers);

  o.timerHoursFormatted = timerHoursFormatted;
  o.timerHours = timerHours;
  o.totalHours = o.hours + timerHours;

  o.runningTimer = false;
  let hasTimers = false;
  if (o.timers !== null) {
    for (let i = 0; i < o.timers.length; i++) {
      hasTimers = true;
      if (o.timers[i].endTime === "") {
        o.runningTimer = true;
        break;
      }
    }
  }
  o.hoursInvalid =
    o.totalHours === 0 && o.startTimer === false && o.runningTimer === false;

  // Travel Section //
  // eslint-disable-next-line react-hooks/rules-of-hooks
  o.travelInfo = useMemo(
    () => getTravelInfo(o.totalHours, o.cr?.customers[0], o.locationId),
    [o.cr?.customers, o.locationId, o.totalHours],
  );

  o.userSelectProps = {};
  if (hasTimers || timeIsInvoiced) {
    o.userSelectProps.isDisabled = true;
  }
  o.dateEditDisabled = o.userSelectProps.isDisabled;

  // Auto refresh the screen for running timers
  useEffect(() => {
    if (o.timers !== null && o.timers.length > 0) {
      const id = setInterval(() => {
        if (o.timers !== null && o.timers.length > 0) setNow(moment());
      }, 60000);
      return () => clearInterval(id);
    }
  }, [o.timers]);

  o.billableOverrideYesProps = o.overrideBillableFlag
    ? { variant: "success", disabled: true }
    : {
        variant: "outline-success",
        disabled: timeIsInvoiced,
      };
  // re-enabling other for all crs, not just internal
  // || !cr?.isInternalCr

  o.billableOverrideNoProps = o.overrideBillableFlag
    ? { variant: "outline-danger", disabled: false || timeIsInvoiced }
    : { variant: "danger", disabled: true };

  o.showOnInvoiceYesProps = o.showOnInvoice
    ? { variant: "success", disabled: true }
    : { variant: "outline-success", disabled: false || timeIsInvoiced };

  o.showOnInvoiceNoProps = o.showOnInvoice
    ? { variant: "outline-danger", disabled: false || timeIsInvoiced }
    : { variant: "danger", disabled: true };

  o.travelToProps =
    o.travelDirection === "T"
      ? { variant: "primary", disabled: true }
      : { variant: "secondary", disabled: false || timeIsInvoiced };

  o.travelFromProps =
    o.travelDirection === "F"
      ? { variant: "primary", disabled: true }
      : { variant: "secondary", disabled: false || timeIsInvoiced };

  //if you are not allowed to change the billability based on the
  // reason code you chose. Then disable the ability to change
  // the billability. Most reason codes can only be used for non-bill
  // and thus you can't change it to billable.
  //const temp = timeIsInvoiced || !cr?.isInternalCr;
  if (o.borsRecord?.allowBillFlagChange === "N" || timeIsInvoiced) {
    o.billableOverrideYesProps.disabled = true;
    o.billableOverrideNoProps.disabled = true;
  }

  // if you have marked the time as override billable of true
  // then clearly you can't hide the time.
  if (o.overrideBillableFlag) {
    o.showOnInvoiceYesProps.disabled = true;
    o.showOnInvoiceNoProps.disabled = true;
  }

  //-----------------Section statuses ----------------------//

  o.sectionStatuses = getSectionStatuses({
    userId: o.userId,
    hoursInvalid: o.hoursInvalid,
    timeType: o.timeType,
    internalProjectId: o.internalProjectId,
    billingNote: o.billingNote,
    crIsValid: o.crIsValid,
    locationId: o.locationId,
    travelDirection: o.travelDirection,
    billableOverrideReasonId: o.billableOverrideReasonId,
    holdReasonId: o.holdReasonId,
    holdDate: o.holdDate,
    internalComments: o.internalComments,
    cr: o.cr,
    isCustomerContacted: o.isCustomerContacted,
    isWorkPerformed: o.isWorkPerformed,
    isNeither: o.isNeither,
    customerId: o.customerId,
    showOnInvoice: o.showOnInvoice,
    mode,
    startTimer: o.startTimer,
    customerValue: o.customerValue,
    borsRecord: o.borsRecord,
    holdReasonRecord: o.holdReasonRecord,
  });

  //--------------END Section statuses ----------------------//

  o.billingNoteDisabled = o.isTravelType && o.locationId !== "other";
  o.locationDisabled = getLocationDisabled(
    o.isOnsiteType,
    o.isTravelType,
    o.travelDirection,
  );

  o.internalCommentHelp =
    o.sectionStatuses.internalComments === statusIncomplete
      ? "Internal comments are required"
      : "Not required";

  o.billabilitySection = false;

  let billMessage = "";
  if (o.borsRecord != null) {
    o.billabilitySection = o.overrideBillableFlag;
    billMessage = o.billabilitySection
      ? "Time record has been overriden to Billable."
      : o.showOnInvoice
      ? "Time record has been overridden to non-billable"
      : "Time record has been overridden to be non-billable AND hidden";
  } else {
    if (
      o.cr?.id === o.originalCrId &&
      !_.isUndefined(o.cr?.id) &&
      mode !== "new" &&
      o.billableOverrideReasonId === ""
    ) {
      o.billabilitySection = o.originalIsBillable;
      billMessage = o.originalIsBillable
        ? "Time record is billable"
        : "Time record is non-billable";
    } else if (o.cr?.defaultBillability === true) {
      o.billabilitySection = true;
      billMessage = "Time record will be billable";
    } else {
      billMessage = "Time record will be non-billable";
    }
  }
  o.billMessage = billMessage;
  o.billMessage2 = o.hasSetAllInfo
    ? "'Set All Time' settings were found and applied. Override at your own peril."
    : null;

  const customerId2 = o.customerId;
  o.createTimePayload = {
    //top section
    id: o.id,
    userId: o.userId,
    datetime: o.datetime,
    hours: o.hours,
    billingNote: o.billingNote,
    // time type
    timeType: o.timeType,
    //internal time records
    internalProjectId: o.internalProjectId,

    //CR tbd
    customerId: _.isNil(customerId2) ? "" : customerId2,

    //CR
    crId: o.crId,
    //CR - Billable Override Reason related stuff
    billableOverrideReasonId: _.isEmpty(o.billableOverrideReasonId)
      ? ""
      : o.billableOverrideReasonId,
    overrideBillableFlag: o.overrideBillableFlag,
    showOnInvoice: o.showOnInvoice,

    //CR - HoldReason
    holdReasonId: _.isEmpty(o.holdReasonId) ? "" : o.holdReasonId,
    holdDate: o.holdDate,

    // Onsite and travel time
    locationId: o.locationId,
    // Travel
    travelDirection: o.travelDirection,
    travelBacReason: o.travelBacReason,

    //Work log (CR + CR TBD)
    isCustomerContacted: o.isCustomerContacted,
    isWorkPerformed: o.isWorkPerformed,
    isNeither: o.isNeither,

    //Internal comments (CR + CR TBD)
    internalComments: o.internalComments,
    startTimer: o.startTimer,
    stopTimer: stopTimer,
    stopTimerTime: _datetime.stamp(),
    timers: o.timers,
    createDateTime: o.createDateTime,
    modifyDateTime: o.modifyDateTime,
    overrideReason: o.modifyReason,
  };

  return o;
}

function SplitModal({ okButtonText, show, hours, onOk, onCancel }) {
  const [origHours, setOrigHours] = useState(hours);
  const [splitHours, setSplitHours] = useState(0.0);

  return (
    <Modal
      onEscapeKeyDown={onCancel}
      enforceFocus={false}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header closeButton onHide={onCancel}>
        <Modal.Title id="contained-modal-title-vcenter">
          Split hours
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="py-0 mb-1">
          <Col md={4} xs={6}>
            <Form.Label className="mb-0">Original Time</Form.Label>
            <FieldHours
              id="orighours"
              value={origHours}
              max={hours}
              onChange={(e) => {
                setOrigHours(e?.value);
                setSplitHours(hours - e?.value);
              }}
            />
          </Col>
          <Col md={4} xs={6}>
            <Form.Label className="mb-0">New Time</Form.Label>
            <FieldHours
              id="splithours"
              value={splitHours}
              max={hours}
              onChange={(e) => {
                setOrigHours(hours - e?.value);
                setSplitHours(e?.value);
              }}
            />
          </Col>
          <Col md={4} xs={6}>
            <Form.Label className="mb-0">Combined</Form.Label>
            <FieldHours id="splithours" value={hours} disabled={true} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div></div>
        <div>
          <Button
            className="mr-1"
            onClick={() => {
              onOk(origHours, splitHours);
            }}
            variant="primary"
          >
            {okButtonText}
          </Button>
          <Button className="mr-0" onClick={onCancel} variant="secondary">
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
