import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { PROJECT_CUSTOMER_INITIAL } from "./projectCustomerSchema";
import ProjectEditor from "./ProjectEditor";
import useProject from "./useProject";

export default function ProjectNew({
  onNew = () => {},
  onCancel,
  project: initialValues,
}) {
  const { createOne } = useProject();
  const [error, setError] = useState(null);

  const { defaultOwner, defaultGroupId } = useSelector(
    (state) => state.settings.settings,
  );

  const defaultValues = {
    customers: [PROJECT_CUSTOMER_INITIAL],
    groupId: defaultGroupId || "",
    sourceId: "PH",
    statusId: "OP",
    typeId: "SU",
    priorityId: "RG",
    owner: defaultOwner || "*TR1*",
    createCr: true,

    //Use initial values from project prop, but ignore any undefined
    ..._.pickBy(initialValues || {}, (v) => !_.isUndefined(v)),
  };

  return (
    <ProjectEditor
      project={defaultValues}
      validateOnMount={!!initialValues}
      isNew
      onSubmit={(project) => {
        createOne(project)
          .catch((err) => setError(err?.message))
          .then((result) => {
            return onNew(result);
          });
      }}
      onCancel={onCancel}
      error={error}
    />
  );
}
