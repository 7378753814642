import { useEffect, useState } from "react";

//https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react
export default function useDocumentFocus() {
  const [isFocus, setIsFocus] = useState();
  const onFocus = () => setIsFocus(true);
  const onBlur = () => setIsFocus(false);
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });
  return isFocus;
}
