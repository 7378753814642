import { combineReducers } from "@reduxjs/toolkit";

import categoryState from "../categories/state";
import tripState from "../trips/state";
import typeState from "../types/state";
import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const expenseReducer = combineReducers({
  expenses: reducer,
  categories: categoryState.reducer,
  types: typeState.reducer,
  trips: tripState.reducer,
});

const expenseState = {
  api,
  entity,
  actions,
  reducer: expenseReducer,
};

export default expenseState;
