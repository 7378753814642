import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import CustomerIndex from "./CustomerIndex";
import CustomerShow from "./CustomerShow";
import CustomerShowCrs from "./CustomerShowCrs";
import CustomerShowProjects from "./CustomerShowProjects";
import HostingAgreementsPages from "./hostingAgreements";
import JiraProjectsRouting from "./jiraProjects";
import ThirdPartyLicensesPages from "./third-party-licenses";

export default function CustomersRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/hosting-agreements`}>
        <HostingAgreementsPages />
      </Route>
      <Route path={`${path}/third-party-licenses`}>
        <ThirdPartyLicensesPages />
      </Route>
      <Route path={`${path}/jira-projects`}>
        <JiraProjectsRouting />
      </Route>
      <Route path={`${path}/:id/projects`}>
        <CustomerShowProjects />
      </Route>
      <Route path={`${path}/:id/crs`}>
        <CustomerShowCrs />
      </Route>
      <Route path={`${path}/:id`}>
        <CustomerShow />
      </Route>
      <Route exact path={`${path}`}>
        <CustomerIndex />
      </Route>
    </Switch>
  );
}
