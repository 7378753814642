import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { isFunction, isString } from "lodash";

import { testStateLoading } from "../../common/functions/state";

/**
 * @typedef {Object} Opts
 * @property {Object} entity Entity object
 * @property {Object} api Async API functions
 */

/**
 * Create Redux actions using Redux-Toolkit entities
 * @param {Opts} opts Options
 */
export function createEntityActions({ entity, api }) {
  const { type, getState: getEntityState } = entity;
  if (!isFunction(getEntityState) || !isString(type)) {
    throw new Error(`entity is required`);
  }
  if (!isFunction(api.getMany)) {
    throw new Error(`getMany API is required`);
  }

  const getMany = createEntityThunkAction(
    entity,
    "/getMany",
    api.getMany,
    getActions,
  );
  const getOne = createEntityThunkAction(
    entity,
    "/getOne",
    api.getOne,
    getActions,
  );
  const createOne = createEntityThunkAction(
    entity,
    "/create",
    api.createOne,
    getActions,
  );
  const updateOne = createEntityThunkAction(
    entity,
    "/update",
    api.updateOne,
    getActions,
  );
  const deleteOne = createEntityThunkAction(
    entity,
    "/delete",
    api.deleteOne,
    getActions,
  );

  const reset = createAction(type + "/reset");

  const actions = {
    getMany,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    reset,
  };

  function getActions() {
    return actions;
  }

  return actions;
}

export default createEntityActions;

export function createEntityThunkAction(
  entity,
  type,
  payloadCreator,
  getActions = () => {},
) {
  if (!isFunction(payloadCreator)) return undefined;

  const { type: entityType, getState: getEntityState } = entity;

  return createAsyncThunk(entityType + type, async (args, thunkAPI) => {
    const { getState, requestId } = thunkAPI;
    if (!testStateLoading(getEntityState(getState()), requestId))
      return undefined;
    return await payloadCreator(args, thunkAPI, {
      actions: getActions(),
      ...entity,
    });
  });
}
