import { bool, object, string } from "yup";

const notificationOption = () =>
  string().oneOf(["A", "N", "P"]).default("N").required();

const notificationGroupSchema = object().shape({
  id: string()
    .label("Id")
    .required()
    .max(8)
    .transform((v) => `${v}`.toUpperCase()),
  description: string().label("Description").max(30).required(),
  groupId: string().label("Group"),
  pseudoUserId: string().label("Pseudo User"),
  newProjectSetting: notificationOption().label("Notify on new issue"),
  leaveTeamSetting: notificationOption().label(
    "Notify when issue leaves team to another",
  ),
  assignedSetting: notificationOption().label(
    "Notify when issues are assigned",
  ),
  priorityChangedSetting: notificationOption().label(
    "Notify when priority changes",
  ),
  qaStatusChangedSetting: string()
    .oneOf(["Y", "N", "F"])
    .required()
    .label("Notify when QA status changes")
    .default("N"),
  inactive: bool().required().default(false),
});

export default notificationGroupSchema;
