import { useModule, useModules } from "./hooks";
import ModuleDescription from "./ModuleDescription";
import ModuleSelect from "./ModuleSelect";
import ModuleTableFilter from "./ModuleTableFilter";
import state from "./state";

const Module = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useModule,
  useAll: useModules,
  // Components
  Select: ModuleSelect,
  Description: ModuleDescription,
  TableFilter: ModuleTableFilter,
};
export default Module;
