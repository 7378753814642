import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";

export default function TradingPartnerTable({ tradingPartners, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Trading Partner",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: (x) => {
          return (
            <Link
              className="text-decoration-none"
              to={{ pathname: `/edi/tradingPartners/${x?.row?.original?.id}` }}
            >
              {x?.value}
            </Link>
          );
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Trading Partners"
      columns={columns}
      data={tradingPartners}
      {...props}
    />
  );
}
