import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import ThirdPartyLicenseIndex from "./ThirdPartyLicenseIndex";
import ThirdPartyLicenseNew from "./ThirdPartyLicenseNew";
import ThirdPartyLicenseShow from "./ThirdPartyLicenseShow";

export default function ThirdPartyLicensesPages({ NavSidebar }) {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <ThirdPartyLicenseNew
          navSidebar={<ThirdPartyLicenseNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route path={`${path}/:id`}>
        <ThirdPartyLicenseShow
          navSidebar={<ThirdPartyLicenseNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route exact path={`${path}`}>
        <ThirdPartyLicenseIndex
          navSidebar={<ThirdPartyLicenseNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
    </Switch>
  );
}

function ThirdPartyLicenseNavSidebar({ NavSidebar }) {
  return (
    <NavSidebar>
      <div className="dropdown-divider"></div>
      <NavSidebarItem to="/customers/third-party-licenses/new">
        New License
      </NavSidebarItem>
    </NavSidebar>
  );
}
