import { entitySelect } from "../../common/entity";
import { useContractProducts } from "./hooks";

const ContractProductSelect = entitySelect({
  name: "contractProduct",
  useEntities: useContractProducts,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.description + "(" + e.id + ")",
});

export default ContractProductSelect;
