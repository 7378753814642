import { combineReducers } from "@reduxjs/toolkit";

import customerEdiSetupsState from "../customerEdiSetups/state";
import ftpUsersState from "../ftpUsers/state";
import tradingPartnersState from "../tradingPartners/state";

const ediReducer = combineReducers({
  tradingPartners: tradingPartnersState.reducer,
  customerEdiSetups: customerEdiSetupsState.reducer,
  ftpUsers: ftpUsersState.reducer,
});

const ediState = {
  reducer: ediReducer,
};

export default ediState;
