import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

const layout = [
  "Team",
  "User",
  "Reason",
  "CR",
  "Billing note",
  "User",
  "Owner",
  "Date",
  "Hours",
  "Lost Revenue",
];

const groupBy = ["Team", "User", "Reason"];

export default function ReportNonbillableTime() {
  return (
    <JobPage
      title="Nonbillable Time"
      functionName="NonbillableTime"
      Options={NonbillableTimeOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState(null);
  const data = useMemo(() => job?.output?.time || [], [job]);

  const clickedBillingNote = useCallback(
    (timeId) => {
      setShowTimeId(timeId);
      setShowModifyModal(true);
    },
    [setShowTimeId, setShowModifyModal],
  );

  const columns = useMemo(
    () => [
      {
        id: "User",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "CR",
        Header: "CR",
        accessor: (row) => (row.crId === 0 ? "N/A" : row.crId),
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <Cr.Link id={value} />,
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "date",
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Billing note",
        Header: "Billing note",
        accessor: "billingNote",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value, row }) => {
          return (
            <Time.BillingNoteLink
              timeId={row?.original?.timeId}
              value={value}
              setModifyShow={clickedBillingNote}
            />
          );
        },
      },
      {
        id: "Team",
        Header: "Team",
        accessor: "team",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerName",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        id: "Reason",
        Header: "Reason",
        accessor: "reason",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Project Type",
        Header: "Project Type",
        accessor: "projectType",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Rate",
        Header: "Rate",
        accessor: "rate",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Hours",
        Header: "Hours",
        accessor: "hours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Lost Revenue",
        Header: "Lost Revenue",
        accessor: "total",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} />;
        },
      },
      {
        id: "Cr in Warranty",
        Header: "In Warranty?",
        accessor: "crInWarranty",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
    ],
    [clickedBillingNote],
  );

  return (
    <>
      <Time.ModifyModal
        id={showTimeId}
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        onCancel={() => setShowModifyModal(false)}
      />
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={layout}
            initialGroupBy={groupBy}
          />
        </Col>
      </Row>
    </>
  );
}

function NonbillableTimeOptions(props) {
  return (
    <ReportOptions
      {...props}
      options={{
        tbdOnly: true,
        includeInternalTime: false,
        includeInternalCrs: false,
        includeDefects: false,
        includeQuestions: false,
      }}
    >
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeInternalTime"
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeInternalTime"
                label="Include internal time?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeInternalCrs"
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeInternalCrs"
                label="Include Internal Crs?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeDefects"
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeDefects"
                label="Include defect time with no override reason?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeQuestions"
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeQuestions"
                label="Include how to question time with no override reason?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}
