import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import ServerNavSidebar from "../ServerNavSidebar";

export default function AwsInstanceTypeNavSidebar({ children }) {
  return (
    <ServerNavSidebar>
      {children}
      <div className="dropdown-divider" />
      <NavSidebarItem to="/servers/aws-instance-types/new">
        New Aws Instance Type
      </NavSidebarItem>
    </ServerNavSidebar>
  );
}
