import { bool, number, object, string } from "yup";

const customerEdiSetupSchema = object().shape({
  subscriber: string().max(6).label("Subscriber").required(),
  company: string().max(4).label("Company").required(),
  tradingPartnerId: number().required(),
  customerId: number().required(),
  notes: string().max(500).label("Notes"),
  isInactive: bool().required().default(false),
});

export default customerEdiSetupSchema;
