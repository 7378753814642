import { isEmpty } from "lodash";
import { number, object, string } from "yup";

const expenseSchema = object().shape({
  date: string().label("Date").required(),
  overrideDescription: string().label("Description").max(30),
  expenseTypeId: string()
    .label("Type")
    .when(["expenseTripId", "customerId"], {
      is: (t, c) => !!t || !!c,
      then: string().required(),
    }),
  expenseCategoryId: string()
    .label("Category")
    .when(["expenseTripId", "customerId"], {
      is: (t, c) => !t && !c,
      then: string().required(),
    }),
  expenseTripId: number().label("Expense Trip"),
  customerId: number().label("Customer"),
  note: string()
    .label("Notes")
    .test(
      "testPerDiemAmount",
      "Per Diem is greater than specified max. Notes are required.",
      (_, { parent, createError }) => {
        const { amount, perDiem, expenseTypeId, note } = parent;
        if (
          expenseTypeId === "ME" &&
          amount > perDiem.max &&
          isEmpty(note?.trim())
        )
          return createError({
            message: `Per Diem is greater than specified max (${perDiem.max}). Notes are required.`,
          });
        return true;
      },
    ),
  amount: number().label("Amount").required(),
});

export default expenseSchema;
