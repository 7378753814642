import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import AttachmentsButton from "../../common/details/AttachmentsButton";
import Comments from "../../common/details/Comments";
import DetailField from "../../common/details/DetailField";
import DetailSection from "../../common/details/DetailSection";
import { _datetime } from "../../common/functions/dates";
import useIntervalUpdateComponent from "../../common/hooks/useIntervalUpdateComponent";
import Cr from "../../crs";
import Customer from "../../customers";
import Project from "../../projects";
import User from "../../users";
import CrNavSidebar from "./CrNavSidebar";
import CrPage from "./CrPage";
import CrShowDetails from "./CrShowDetails";
import CrShowNavSidebar from "./CrShowNavSidebar";
import CrShowToggles from "./CrShowToggles";

export default function CrShow() {
  const id = parseInt(useParams().id);
  const {
    cr,
    loading,
    updateOne,
    getCr,
    createComment,
    updateComment,
    createAttachment,
    lastUpdate,
    updateWatch,
  } = Cr.useOne({
    id: id,
    logActivity: true,
    redirect: false,
  });

  const isLoadingAndInvalid = loading && _.isEmpty(cr);

  const transferred =
    cr?.transferredFolder && cr?.transferredFolder !== "CURRENT";

  useIntervalUpdateComponent(15000);

  if (loading === false && !cr)
    return (
      <CrPage
        heading={`CR ${id ? id : ""}`}
        lead="Invalid CR"
        navSidebar={<CrNavSidebar />}
      />
    );

  return (
    <CrPage
      cr={cr}
      heading={`CR ${id} ${cr?.jiraKey ? " Jira " + cr.jiraKey : ""}`}
      navSidebar={<CrShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <Details
        cr={cr}
        isLoading={loading}
        transferred={transferred}
        lastUpdate={lastUpdate}
        getCr={getCr}
        update={updateOne}
        createAttachment={createAttachment}
        updateWatch={updateWatch}
      />
      <Customer.SpecialWorkConditions
        customers={cr?.customers?.map((c) => {
          return c.id;
        })}
        isLoading={isLoadingAndInvalid}
      />
      <JiraNotes jiraKey={cr?.jiraKey} notes={cr?.jiraNotes} />
      <DetailSection title="Comments" key="comments-section">
        <Row className="mb-3 border rounded border-info bg-light">
          <Col>
            <DetailField.Text
              id={`cr-${cr?.id}-internal-notes`}
              onEdit={
                transferred
                  ? null
                  : (value) => updateOne({ internalNotes: value })
              }
              isLoading={loading}
              header="Internal Notes"
              text={cr?.internalNotes}
              heightRem={2}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Comments
              id={`cr-${id}-comments`}
              comments={cr?.comments}
              isLoading={loading}
              isDisabled={transferred}
              create={createComment}
              update={updateComment}
            />
          </Col>
        </Row>
      </DetailSection>
    </CrPage>
  );
}

function Details({
  cr,
  isLoading: loadingProp,
  transferred,
  update,
  createAttachment,
  getCr,
  lastUpdate,
  updateWatch,
}) {
  const {
    project,
    getProject,
    loading: loadingProject,
  } = Project.useOne({
    id: cr?.projectId,
  });
  const isLoading = loadingProp || loadingProject;
  const isLoadingAndInvalid =
    isLoading && (_.isEmpty(cr) || _.isEmpty(project));

  const isDisabled = isLoading || transferred;
  const isDisabledOrClosed =
    isDisabled || cr?.statusId === "CL" || cr?.statusId === "FX";

  const attachments = useMemo(
    () => cr?.attachments?.concat(project?.attachments || []) || [],
    [cr, project],
  );

  return (
    <Row>
      <Col md={8} sm={12} className="border-right">
        <Cr.Alerts cr={cr} />
        <Row>
          <Col>
            <DetailField
              header={`Customers${cr?.overrideCustomerId ? "*" : ""}`}
            >
              <Project.Contacts
                project={project}
                customerId={cr?.overrideCustomerId}
                isLoading={isLoadingAndInvalid}
                mailto={
                  loadingProject
                    ? {}
                    : {
                        subject: `Project ${project?.id}: ${cr?.synopsis}`,
                      }
                }
              />
            </DetailField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DetailField.Text
              id={`cr-${cr?.id}-description`}
              onEdit={
                isDisabled ? null : (value) => update({ description: value })
              }
              isLoading={isLoading}
              header="Description"
              text={cr?.description}
              heightRem={20}
            />
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <Row>
          <Col>
            <span className="text-muted float-left">
              <Button
                variant="link"
                className="text-muted"
                size="sm"
                onClick={() => getCr()}
                title="Refresh CR data"
              >
                {isLoading
                  ? "Loading..."
                  : "Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
              </Button>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mb-1">
            <Cr.StatusEditor
              cr={cr}
              project={project}
              update={update}
              isLoading={isLoadingAndInvalid}
              isDisabled={isDisabled}
              updateWatch={updateWatch}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CrShowDetails
              cr={cr}
              project={project}
              isLoading={isLoadingAndInvalid}
              update={update}
              isDisabled={isDisabled}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CrShowToggles
              cr={cr}
              isLoading={isLoadingAndInvalid}
              update={update}
              isDisabled={isDisabledOrClosed}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-muted pt-0 pb-3">
            {isLoadingAndInvalid ? null : (
              <small>
                {`Created on ${_datetime.displayDateTime(
                  _datetime.fromStamp(cr?.createDateTime),
                )}\nby `}
                <User.Link id={cr?.createUserId} />
              </small>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoadingAndInvalid ? null : (
              <ButtonGroup vertical>
                <Cr.ShareButton cr={cr} />
                <AttachmentsButton
                  label={"Attachments"}
                  attachments={attachments}
                  source={{ source: "CR", sourceId: cr?.id }}
                  create={createAttachment}
                  isDisabled={transferred}
                  onChange={() => {
                    getCr();
                    getProject();
                  }}
                />
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function JiraNotes({ jiraKey, notes }) {
  if (!notes) return null;
  return (
    <Row className="my-3 pt-2 border table-info rounded text-center">
      <Col>
        <Row>
          <Col>
            <h5>Jira Notes</h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Row>
              <Col>
                {"Jira Issue: "} {jiraKey}
              </Col>
            </Row>
            {notes.split(/\r\n|\r|\n/).map((x) => {
              if (x.startsWith("https")) {
                return (
                  <Row key={x}>
                    <Col>
                      <a href={x} target="_blank" rel="noopener noreferrer">
                        {x}
                      </a>
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row key={x}>
                    <Col>{x}</Col>
                  </Row>
                );
              }
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
