import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import ReservationComparison from "./ReservationComparison";
import ReservationEdit from "./ReservationEdit";
import ReservationIndex from "./ReservationIndex";

export default function ReservationsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/comparison`}>
        <ReservationComparison />
      </Route>
      <Route path={`${path}/:id`}>
        <ReservationEdit />
      </Route>
      <Route exact path={`${path}`}>
        <ReservationIndex />
      </Route>
    </Switch>
  );
}
