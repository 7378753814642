import shark from "../../../apis/shark";

function getAwsAccountsAPI() {
  const payload = {
    command: "getAwsAccounts",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.awsAccounts,
  }));
}

function getAwsAccountAPI({ id }) {
  const payload = {
    command: "getAwsAccount",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createAwsAccountAPI(awsAccount) {
  const payload = {
    command: "createAwsAccount",
    ...awsAccount,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateAwsAccountAPI(awsAccount) {
  const payload = {
    command: "updateAwsAccount",
    ...awsAccount,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getAwsAccountsAPI,
  getOne: getAwsAccountAPI,
  createOne: createAwsAccountAPI,
  updateOne: updateAwsAccountAPI,
};
