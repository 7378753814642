import moment from "moment";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import JobsTable from "../../activity/jobs/JobsTable";
import useJobs from "../../activity/jobs/useJobs";
import NavSidebarItem from "../../common/navs/NavSidebarItem";
import Page from "../../common/pages/Page";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportIndex() {
  const reportJobFunctions = [
    "Activity",
    "ARYearlyCustomerSummary",
    "CustomerTime",
    "CodeChanges",
    "ContractorWork",
    "CustomAnalysis",
    "CustomerBilledByProduct",
    "CustomerCrs",
    "CustomerStatement",
    "DailyBreakdown",
    "EmployeeMeasures",
    "HostingYearBreakdown",
    "InternalTime",
    "NonbillableTime",
    "OutOfOffice",
    "Overtime",
    "PrCrTextSearch",
    "TimeOnHold",
    "UnbilledTime",
    "WorkSummary",
    "QaReviewers",
    "CustomerProducts",
  ];

  const {
    jobs,
    loading: jobsLoading,
    getMany,
    lastUpdate,
  } = useJobs({
    blocked: true,
  });

  useEffect(() => {
    getMany();
  }, [getMany]);
  return (
    <Page title="Reports" navSidebar={<IndexNavSidebar />}>
      <Row>
        <Col>
          <JobsTable
            jobs={jobs?.filter((j) =>
              reportJobFunctions.includes(j.functionName),
            )}
            isLoading={jobsLoading}
            onRefresh={() => {
              getMany();
            }}
            layout={["dateTime", "type", "description", "status"]}
            refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
          />
        </Col>
      </Row>
    </Page>
  );
}

function IndexNavSidebar() {
  return (
    <ReportNavSidebar>
      <NavSidebarItem to="/reports/activity">Activity</NavSidebarItem>
      <NavSidebarItem to="/reports/ar-yearly-customer-summary">
        AR Yearly Customer Summary
      </NavSidebarItem>
      <NavSidebarItem to="/reports/bac">
        Billable Additional Compensation
      </NavSidebarItem>
      <NavSidebarItem to="/reports/code-changes">Code Changes</NavSidebarItem>
      <NavSidebarItem to="/reports/contractor-work-summary">
        Contractor Work Summary
      </NavSidebarItem>
      <NavSidebarItem to="/reports/custom-analysis">
        Custom Analysis
      </NavSidebarItem>
      <NavSidebarItem to="/reports/customer-billed-by-product">
        Customer Billed By Product
      </NavSidebarItem>
      <NavSidebarItem to="/reports/customer-crs">Customer Crs</NavSidebarItem>
      <NavSidebarItem to="/reports/customer-statement">
        Customer Statement
      </NavSidebarItem>
      <NavSidebarItem to="/reports/customer-time">Customer Time</NavSidebarItem>
      <NavSidebarItem to="/reports/daily-breakdown">
        Daily Breakdown
      </NavSidebarItem>
      <NavSidebarItem to="/reports/employee-by-measures">
        Employee by Measures
      </NavSidebarItem>
      <NavSidebarItem to="/reports/hosting-year-breakdown">
        Hosting Year Breakdown
      </NavSidebarItem>
      <NavSidebarItem to="/reports/internal-time">Internal Time</NavSidebarItem>
      <NavSidebarItem to="/reports/module-summary">
        Module Summary
      </NavSidebarItem>
      <NavSidebarItem to="/reports/nonbillable-time">
        Nonbillable Time
      </NavSidebarItem>
      <NavSidebarItem to="/reports/out-of-office">Out of Office</NavSidebarItem>
      <NavSidebarItem to="/reports/pr-cr-text-search">
        Project/CR Text Search
      </NavSidebarItem>
      <NavSidebarItem to="/reports/time-on-hold">Time on Hold</NavSidebarItem>
      <NavSidebarItem to="/reports/unbilled-time">Unbilled Time</NavSidebarItem>
      <NavSidebarItem to="/reports/work-summary">Work Summary</NavSidebarItem>
      <NavSidebarItem to="/reports/qa-reviewers">QA Reviewers</NavSidebarItem>
      <NavSidebarItem to="/reports/customer-products">
        Customer Products
      </NavSidebarItem>
    </ReportNavSidebar>
  );
}
