import _ from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import Mailto from "../common/details/Mailto";
import Tel from "../common/details/Tel";
import { formatPhone } from "../common/functions/phone";
import { useContact } from "./hooks";

export default function ContactLink({
  id,
  source,
  sourceType = "Source",
  isLoading = false,
  mailto = {},
  canGet = "auto",
  label,
}) {
  const { contact, loading: contactLoading } = useContact({
    id,
    canGet,
  });

  const loading = contactLoading || isLoading;

  const [show, setShow] = useState(false);

  let c = {
    name: "",
    email: "",
    phones: [],
    phoneCustomer: "",
    titles: [],
  };
  if ((_.isUndefined(contact) || _.isUndefined(source)) && loading) {
    c.name = <Skeleton width={150} />;
  } else {
    c.name = `${contact?.name || "No Contact"}`;
  }

  c.email = contact?.email;

  if (contact?.phones?.length) {
    contact.phones.forEach((phone) => {
      c.phones.push({
        value: formatPhone(phone.number, phone.extention),
        type: phone.type,
      });
    });
  }

  c.phones.push({ value: formatPhone(source?.phone), type: sourceType });

  if (contact?.title) c.titles.push(contact.title);
  if (contact?.types?.length) c.titles = c.titles.concat(contact.types);

  return (
    <>
      <span onClick={() => !loading && setShow(true)}>
        <abbr title="Contact Details">
          {_.isUndefined(label) ? c.name : label}
        </abbr>
      </span>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {c?.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="lead mt-n1 mb-0">{source?.name}</p>
          <small className="font-italic">{c?.titles?.join(", ")}</small>
          {c.phones.map((phone, idx) => (
            <ContactField
              title={`Phone (${phone.type})`}
              value={phone.value}
              key={`phone-${idx}`}
            >
              <Tel phone={phone.value} />
            </ContactField>
          ))}
          <ContactField title="Email" value={c?.email || true}>
            <Mailto
              email={c?.email}
              subject={mailto?.subject}
              body={mailto?.body}
            >
              {c?.email || "Unavailable"}
            </Mailto>
          </ContactField>
        </Modal.Body>
      </Modal>
    </>
  );
}

function ContactField({ title, value, children }) {
  if (_.isUndefined(value) || !value) return null;
  return (
    <>
      <div className="dropdown-divider" />
      <small className="my-0 font-weight-bold">{title}</small>
      <p className="mt-0 mb-0">{children || value}</p>
    </>
  );
}
