import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import ServerNavSidebar from "../ServerNavSidebar";

export default function AwsAccountNavSidebar({ children }) {
  return (
    <ServerNavSidebar>
      {children}
      <div className="dropdown-divider" />
      <NavSidebarItem to="/servers/aws-accounts/new">
        New Aws Account
      </NavSidebarItem>
    </ServerNavSidebar>
  );
}
