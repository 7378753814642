import React from "react";
import { useSelector } from "react-redux";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function ServerNavSidebar({ children }) {
  const { isAdmin } = useSelector((state) => state.auth);
  return (
    <NavSidebar>
      <NavSidebarItem to="/servers">Servers</NavSidebarItem>
      <NavSidebarItem to="/servers/new">New Server</NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/servers/s3-buckets">S3 Buckets</NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/servers/reservations">Reservations</NavSidebarItem>
      <NavSidebarItem to="/servers/reservations/comparison">
        Reservation Comparison
      </NavSidebarItem>
      {isAdmin ? (
        <>
          <div className="dropdown-divider" />
          <NavSidebarItem to="/servers/aws-instance-types">
            Aws Instance Types
          </NavSidebarItem>
          <NavSidebarItem to="/servers/aws-regions">Aws Regions</NavSidebarItem>
          <NavSidebarItem to="/servers/aws-zones">Aws Zones</NavSidebarItem>
          <NavSidebarItem to="/servers/aws-accounts">
            Aws Accounts
          </NavSidebarItem>
        </>
      ) : null}
      {children}
    </NavSidebar>
  );
}
