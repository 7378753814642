import React from "react";

import { SelectColumnFilterWithOptions } from "../../common/tables/TableFilters";
import { useContractProducts } from "./hooks";

export default function ContractProductTableFilter({ column }) {
  const { filterValue } = column;
  const { contractProducts } = useContractProducts();
  const _options = contractProducts
    .filter((s) => s.isInactive === false || s.id === filterValue)
    .map((f) => {
      return { label: f.description, value: f.id };
    });
  return <SelectColumnFilterWithOptions column={column} options={_options} />;
}
