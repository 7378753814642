import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import AwsAccountEdit from "./AwsAccountEdit";
import AwsAccountIndex from "./AwsAccountIndex";
import AwsAccountNavSidebar from "./AwsAccountNavSidebar";
import AwsAccountNew from "./AwsAccountNew";

export default function AwsAccountsPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Instance Type"
        navSidebar={<AwsAccountNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <AwsAccountNew />
      </Route>
      <Route path={`${path}/:id`}>
        <AwsAccountEdit />
      </Route>
      <Route exact path={`${path}`}>
        <AwsAccountIndex />
      </Route>
    </Switch>
  );
}
