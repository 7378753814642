import { useTaxProduct, useTaxProducts } from "./hooks";
import taxProductState from "./state";
import TaxProductEditor from "./TaxProductEditor";
import TaxProductLink from "./TaxProductLink";
import TaxProductSelect from "./TaxProductSelect";
import Table from "./TaxProductTable";
import TaxProductTableFilter from "./TaxProductTableFilter";

const TaxProduct = {
  // API
  api: taxProductState.api,
  // State
  actions: taxProductState.actions,
  selectors: taxProductState.entity.selectors,
  // Hooks
  useOne: useTaxProduct,
  useAll: useTaxProducts,
  // Components
  Table,
  TableFilter: TaxProductTableFilter,
  Link: TaxProductLink,
  Editor: TaxProductEditor,
  Select: TaxProductSelect,
  // Related Entity Objects
};

export default TaxProduct;
