import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";

import useTinykeys from "../common/hooks/useTinykeys";
import Customer from "../customers";
import User from "../users";
import Folder from "./folders";
import Status from "./statuses";

export default function ProjectFilters({ values = {}, onSubmit }) {
  const [filter, setFilter] = useState(values);

  useEffect(() => {
    setFilter(values);
  }, [values]);

  function handleChange(field, opts) {
    setFilter({
      ...filter,
      [field]: opts,
    });
  }

  useTinykeys({
    "$mod+Enter": () => onSubmit(filter),
  });

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(filter);
      }}
    >
      <Row>
        <Col>
          <Folder.Select
            label="Folder"
            id="project-filter-folder"
            value={filter?.folderId || "CURRENT"}
            onChange={(opts) => handleChange("folderId", opts)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <User.Select
            label="Owner"
            id="project-filter-owner"
            value={filter?.owners}
            onChange={(opts) => handleChange("owners", opts)}
            pseudo={true}
            placeholder="Select Owner(s)..."
            isMulti
            isValid={filter?.owners?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Status.Select
            label="Status"
            id="project-filter-status"
            value={filter?.projectStatuses}
            onChange={(opts) => handleChange("projectStatuses", opts)}
            isMulti
            isValid={filter?.projectStatuses?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <User.Select
            label="Champion"
            id="project-filter-champion"
            value={filter?.champions}
            onChange={(opts) => handleChange("champions", opts)}
            pseudo={false}
            isChampion={true}
            isMulti
            isValid={filter?.champions?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <User.Group.Select
            label="Group"
            id="project-filter-group"
            value={filter?.groups}
            onChange={(opts) => handleChange("groups", opts)}
            isMulti
            isValid={filter?.groups?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Customer.Select
            label="Customers"
            id="project-filter-customer"
            value={filter?.customers}
            onChange={(opts) => handleChange("customers", opts)}
            isMulti
            isValid={filter?.customers?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>SAM Follow-Up?</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => handleChange("isSAMFollowup", e.target.value)}
              value={filter?.isSAMFollowup}
            >
              <option value="">All</option>
              <option value="false">No</option>
              <option value="true">Only</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Contract Project?</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) =>
                handleChange("isContractProject", e.target.value)
              }
              value={filter?.isContractProject}
            >
              <option value="">All</option>
              <option value="false">No</option>
              <option value="true">Only</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup>
            <Button variant="primary" type="submit">
              Search
            </Button>
            <Button
              variant="secondary"
              onClick={() => setFilter({ folderId: "CURRENT" })}
            >
              Clear
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  );
}
