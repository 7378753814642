import { createEntityHooks } from "../../common/entity";
import contractProductCategoryState from "./state";

export const {
  useEntities: useContractProductCategories,
  useEntity: useContractProductCategory,
} = createEntityHooks({
  entity: contractProductCategoryState.entity,
  actions: contractProductCategoryState.actions,
});
