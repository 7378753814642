import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import FtpUser from "../../../edi/ftpUsers";
import EdiNavSidebar from "../EdiNavSidebar";

export default function FtpUserNew() {
  const { createOne } = FtpUser.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="New Ftp User"
        lead="You do not have access to this feature"
        navSidebar={<EdiNavSidebar />}
      />
    );

  return (
    <Page title={"New Ftp User"} navSidebar={<EdiNavSidebar />}>
      <FtpUser.Editor
        isNew={true}
        isLoading={false}
        values={{ isInactive: false }}
        onSubmit={(ftpUser) =>
          createOne(ftpUser).then(({ id }) =>
            setRedirect(`/edi/ftpUsers/${id}`),
          )
        }
      />
    </Page>
  );
}
