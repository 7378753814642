import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import AwsRegionLink from "./AwsRegionLink";

export default function AwsRegionTable({
  awsRegions,
  awsRegionLink = true,
  Components: OverrideComponents,
  layout = ["Id", "Region Description"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: awsRegion } }) =>
          awsRegionLink ? (
            <AwsRegionLink id={awsRegion?.id}>
              {value || awsRegion?.id}
            </AwsRegionLink>
          ) : (
            value
          ),
      },
      {
        id: "Region Description",
        Header: "Region Description",
        accessor: "regionDescription",
        Filter: SelectColumnFilter,
        filter: "text",
      },
    ],
    [awsRegionLink],
  );
  return (
    <Table
      hasCount
      label="Aws Regions"
      columns={columns}
      layout={layout}
      data={awsRegions}
      {...props}
    />
  );
}
