import CodeTestEditor from "./CodeTestEditor";
import CodeTestFailuresTable from "./CodeTestFailuresTable";
import CodeTestSelect from "./CodeTestSelect";
import CodeTestTable from "./CodeTestTable";
import { useCodeTest, useCodeTests } from "./hooks";
import codeTestState from "./state";

const CodeTest = {
  // Backend API
  api: codeTestState.api,
  // State
  actions: codeTestState.actions,
  selectors: codeTestState.entity.selectors,
  // Hooks
  useAll: useCodeTests,
  useOne: useCodeTest,
  // Components
  Select: CodeTestSelect,
  Table: CodeTestTable,
  FailuresTable: CodeTestFailuresTable,
  Editor: CodeTestEditor,
};

export default CodeTest;
