import { combineReducers } from "redux";

import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import expenseTripUserState from "../users/state";

const api = {
  getMany: (search) => {
    return shark.post({ command: "getExpenseTrips", ...search }).then((r) => {
      return {
        data: r.responseData.expenseTrips,
        searchArgs: search,
      };
    });
  },
  getOne: ({ id }) => {
    return shark.post({ command: "getExpenseTrip", id }).then((r) => {
      return {
        data: r.responseData,
      };
    });
  },
};

const entity = createEntity({
  name: "trip",
  type: "expenses/trips",
  getState: (state) => state.expenses.trips.trips,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const tripReducer = combineReducers({
  trips: reducer,
  users: expenseTripUserState.reducer,
});

const tripState = {
  api,
  entity,
  actions,
  reducer: tripReducer,
};

export default tripState;
