import React from "react";

import NavSidebarItem from "../../common/navs/NavSidebarItem";
import CustomerNavSidebar from "./CustomerNavSidebar";

export default function CustomerShowNavSidebar({ id }) {
  return (
    <CustomerNavSidebar>
      <NavSidebarItem
        className="font-weight-bold"
        to={`/Customers/${id}`}
      >{`Customer ${id}`}</NavSidebarItem>
      <NavSidebarItem to={`/customers/${id}/projects`}>Projects</NavSidebarItem>
      <NavSidebarItem to={`/customers/${id}/crs`}>CRs</NavSidebarItem>
      <NavSidebarItem to={`/servers?customer=${id}`}>Servers</NavSidebarItem>
      <NavSidebarItem to={`/servers/s3-buckets?customer=${id}`}>
        S3 Buckets
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/third-party-licenses?customer=${id}`}>
        Third Party Licenses
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/jira-projects?customer=${id}`}>
        Jira Projects
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/hosting-agreements?customer=${id}`}>
        Hosting Agreements
      </NavSidebarItem>
    </CustomerNavSidebar>
  );
}
