import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { checkToken, login, logout, resetPassword } from "./actions";
import { setToken } from "./token";

function isRejectedAction(action) {
  return action.type.endsWith("rejected");
}

const initialState = (state = {}) => {
  state.loading = false;
  state.isLoggedIn = false;
  state.user = "";
  state.name = "";
  state.email = "";
  state.error = null;
  state.accessApprovals = false;
  state.isManager = false;
  state.isChampion = false;
  state.canBypassContractRestriction = false;
  state.allEmployeeReports = false;
  state.canEnterOtherUserExpenses = false;
  state.isAdmin = false;
  state.hostingAgreementAccess = null;
  state.canMaintainServers = false;
  state.canMaintainEdi = false;
  state.canMaintainInvoices = false;
  state.canMaintainContracts = false;
  state.canPerformCodeReview = false;
  state.experimentalFeatures = "N";

  return state;
};

function loginFulfilled(state, action) {
  const {
    user,
    name,
    email,
    accessApprovals,
    isManager,
    isChampion,
    canBypassContractRestriction,
    allEmployeeReports,
    authToken,
    canEnterOtherUserExpenses,
    isAdmin,
    hostingAgreementAccess,
    canMaintainServers,
    canMaintainEdi,
    canMaintainInvoices,
    canMaintainContracts,
    canPerformCodeReview,
    experimentalFeatures,
  } = action.payload;
  console.log(action.payload);
  state.loading = false;
  state.isLoggedIn = true;
  state.user = user;
  state.name = name;
  state.email = email;
  state.accessApprovals = accessApprovals;
  state.isManager = isManager;
  state.isChampion = isChampion;
  state.canBypassContractRestriction = canBypassContractRestriction;
  state.allEmployeeReports = allEmployeeReports;
  state.canEnterOtherUserExpenses = canEnterOtherUserExpenses;
  state.isAdmin = isAdmin;
  state.hostingAgreementAccess = hostingAgreementAccess;
  state.canMaintainServers = canMaintainServers;
  state.canMaintainEdi = canMaintainEdi;
  state.canMaintainInvoices = canMaintainInvoices;
  state.canMaintainContracts = canMaintainContracts;
  state.canPerformCodeReview = canPerformCodeReview;
  state.experimentalFeatures = experimentalFeatures;
  setToken(authToken);
}

function logoutFulfilled(state) {
  initialState(state);
  setToken(undefined);
}

function invalidToken(state, action) {
  const message = action?.payload;
  if (
    action.error.message === "Invalid authentication token" ||
    message === "Invalid authentication token"
  ) {
    logoutFulfilled(state, action);
    toast.error("Token has expired. Please relog.", {
      toastId: "token-expired",
    });
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState(),
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, loginFulfilled)
      .addCase(login.rejected, (state, action) => {
        logoutFulfilled(state, action);
        toast.error(action.error.message);
      })
      .addCase(checkToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkToken.fulfilled, loginFulfilled)
      .addCase(checkToken.rejected, (state, action) => {
        invalidToken(state, action);
        if (state.loading) {
          state.error = action.error.message;
          state.loading = false;
        }
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, logoutFulfilled)
      .addCase(logout.rejected, (state, action) => {
        logoutFulfilled(state, action);
        toast.error(action.error.message);
      })
      .addCase(resetPassword.rejected, (state, action) => {
        toast.error(action.error.message);
      })
      .addMatcher(isRejectedAction, invalidToken);
  },
});

export const reducer = authSlice.reducer;

export const actions = {
  logout,
  login,
  checkToken,
  resetPassword,
};
