import React, { useEffect } from "react";

import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import ThirdPartyLicenseNavSidebar from "./ThirdPartyLicenseNavSidebar";

export default function ThirdPartyLicenseIndex() {
  const customerId = useSearchParams("customer");
  const { customer } = Customer.useOne({ id: customerId, canGet: false });

  const { entities, reset, getMany, loading } =
    Customer.ThirdPartyLicenses.useAll({
      canGet: false,
    });

  const title = customer?.name
    ? `${customer?.name} Third Party Licenses`
    : "Customer Third Party Licenses";

  useEffect(() => {
    reset();
    getMany({ customerId });
  }, [customerId, getMany, reset]);
  return (
    <Page
      title={title}
      navSidebar={<ThirdPartyLicenseNavSidebar customerId={customerId} />}
    >
      <Customer.ThirdPartyLicenses.Table
        data={entities}
        isLoading={loading}
        onRefresh={() => {
          reset();
          getMany({ customerId });
        }}
      />
    </Page>
  );
}
