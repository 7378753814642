import { entityTableFilter } from "../../common/entity";
import { useSources } from "./hooks";

const SourceTableFilter = entityTableFilter({
  name: "projectSource",
  useEntities: useSources,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default SourceTableFilter;
