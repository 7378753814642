import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { BiPlay, BiRepost, BiStop } from "react-icons/bi";
import { useSelector } from "react-redux";

import { _datetime } from "../common/functions/dates";
import useIntervalUpdateComponent from "../common/hooks/useIntervalUpdateComponent";
import TimeNewModal from "./TimeNewModal";
import useTimeModifyModal from "./useTimeModifyModal";
import useTimer from "./useTimer";

const getTimerText = (startTime) => {
  const d = moment.duration(moment().diff(startTime));
  const hours = `${d.hours()}`.padStart(2, "0");
  const minutes = `${d.minutes()}`.padStart(2, "0");
  const seconds = `${d.seconds()}`.padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export default function NavTimer({ isLoading }) {
  const timer = useTimer();
  const [showNewModal, setShowNewModal] = useState(false);
  const hideNewModal = useCallback(() => setShowNewModal(false), []);
  const [ModifyModal, modifyModelProps, setModifyShow, modifyShow] =
    useTimeModifyModal({
      handleChange: () => {
        setModifyShow();
      },
    });

  const currentCr = useSelector((state) => state.crs.crs.currentCr);

  const ref = useRef();

  const {
    current: { status },
  } = timer;

  if (isLoading) return null;
  return (
    <Form inline>
      <OverlayTrigger
        target={ref}
        placement="bottom"
        show={isLoading || showNewModal || modifyShow ? false : undefined}
        delay={{ show: 500, hide: 400 }}
        overlay={
          <Popover id="popover-timer">
            <Popover.Title as="h3">
              {status === "running"
                ? `Started at ${_datetime.displayTime(timer.current.startTime)}`
                : timer.current.endTime
                ? `Ended at ${_datetime.displayTime(timer.current.endTime)}`
                : "No previous timer"}
            </Popover.Title>
            <Popover.Content>
              {timer.current.timerDescription || "Press Start New Timer button"}
            </Popover.Content>
          </Popover>
        }
      >
        <Form.Row className="px-4">
          <Col xs="auto">
            <div ref={ref}>
              <TimerText status={status} start={timer.current.startTime} />
            </div>
          </Col>
          <Col xs="auto">
            {status === "running" ? (
              <Button
                className="rounded-0"
                size="sm"
                variant="danger"
                onClick={() => setModifyShow(timer.current.id, true)}
                title="Stop Timer"
              >
                <BiStop />
              </Button>
            ) : (
              <>
                <Button
                  className="rounded-0"
                  size="sm"
                  variant="success"
                  onClick={() => {
                    setShowNewModal(true);
                  }}
                  title="Start New Timer"
                >
                  <BiPlay />
                </Button>
                {timer.current.id ? (
                  <Button
                    className="rounded-0"
                    size="sm"
                    variant="warning"
                    onClick={() => timer.restart(timer.current.id)}
                    title="Restart Last Timer"
                  >
                    <BiRepost />
                  </Button>
                ) : null}
              </>
            )}
            <TimeNewModal
              show={showNewModal}
              onHide={hideNewModal}
              crId={currentCr}
              // date={date}
              startTimer
              onSave={() => {
                hideNewModal();
                // onChange();
              }}
              onCancel={hideNewModal}
            />
            <ModifyModal {...modifyModelProps} />
          </Col>
        </Form.Row>
      </OverlayTrigger>
    </Form>
  );
}

function TimerText({ start, status }) {
  useIntervalUpdateComponent(1000);
  return (
    <span className="align-middle font-weight-bold text-right">
      {status === "running" ? getTimerText(start) : "None"}
    </span>
  );
}
