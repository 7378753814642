import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import JobPage from "../../activity/jobs/JobPage";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportEmployeeByMeasuress() {
  return (
    <JobPage
      title="Employee By Measures"
      functionName="EmployeeMeasures"
      Options={ReportOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.users || [], [job]);
  const columns = useMemo(
    () => [
      {
        id: "User",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Regular billable hours",
        Header: "Regular Billed Hours",
        accessor: "regBillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Regular nonbill hours",
        Header: "Regular Non-Bill Hours",
        accessor: "regNonbillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Regular total hours",
        Header: "Total Regular Hours",
        accessor: "regTotalHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "BAC billable hours",
        Header: "BAC Billed Hours",
        accessor: "bacBillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "BAC nonbill hours",
        Header: "BAC Non-Bill Hours",
        accessor: "bacNonbillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "BAC total hours",
        Header: "Total BAC Hours",
        accessor: "bacTotalHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Vacation hours",
        Header: "Vacation Hours",
        accessor: "vacationHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Float hours",
        Header: "Float Hours",
        accessor: "floatHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Sick hours",
        Header: "Sick Hours",
        accessor: "sickHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Hours needed",
        Header: "Hours Needed",
        accessor: "hoursNeeded",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Billed dollars",
        Header: "Billed Dollars",
        accessor: "billedDollars",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
      },
      {
        id: "BAC billed dollars",
        Header: "BAC Billed Dollars",
        accessor: "bacDollars",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col xs={"auto"}>{"Mininimum Work hours: "}</Col>
        <Col xs={"auto"}>
          <b>{job?.output?.minWorkHours}</b>
        </Col>
        <Col xs={"auto"}>{"Holiday hours: "}</Col>
        <Col xs={"auto"}>
          <b>{job?.output?.holidayHours}</b>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            layout={[
              "User",
              "Regular billable hours",
              "Regular nonbill hours",
              "Regular total hours",
              "BAC billable hours",
              "BAC nonbill hours",
              "BAC total hours",
              "Vacation hours",
              "Float hours",
              "Sick hours",
              "Hours needed",
              "Billed dollars",
              "BAC billed dollars",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
