import "rc-time-picker/assets/index.css";

import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Overlay,
  Popover,
  Row,
  Table as BSTable,
} from "react-bootstrap";
import { CgArrowsV } from "react-icons/cg";
import { FaRunning } from "react-icons/fa";
import { GiStopSign } from "react-icons/gi";
import { MdCallSplit } from "react-icons/md";
import { RiCheckboxBlankFill, RiCheckboxFill } from "react-icons/ri";
//import { BsXSquareFill } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";

import { _date, _datetime } from "../common/functions/dates";
import parseTime from "../common/functions/parseTime";
import {
  formatTotalSeconds,
  timerCalculation,
} from "../common/functions/timerCalculation";
import Table from "../common/tables/Table";

function TimeTimersTable({
  show,
  onOk,
  onCancel,
  timers = [],
  date = moment(),
  isLoading = false,
  disabled = false,
  splitMode = false,
}) {
  const [tableData, setTableData] = useState(timers);
  const [lastGoodData, setLastGoodData] = useState(timers);
  const [showTimeExampleModal, setShowTimeExampleModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [splitIndex, setSplitIndex] = useState(null);
  const [splitTime, setSplitTime] = useState(null);
  const validTimesButton = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let newTimers = [];
  for (let i = 0; i < tableData.length; i++) {
    let newTimer = { ...tableData[i] };
    const output = timerCalculation(date, [
      { startTime: newTimer.startTime, endTime: newTimer.endTime },
    ]);
    newTimer.hhmmss = output.hhmmss;
    newTimer.totalSeconds = output.totalSeconds;
    newTimers.push(newTimer);
  }

  let splitFrontEnd = "";
  let splitBackEnd = "";
  let splitError = "";
  if (splitTime !== null && splitIndex !== null) {
    const splitFrontEndObj = timerCalculation(date, [
      { startTime: newTimers[splitIndex].startTime, endTime: splitTime },
    ]);
    const splitBackEndObj = timerCalculation(date, [
      { startTime: splitTime, endTime: newTimers[splitIndex].endTime },
    ]);
    splitFrontEnd = splitFrontEndObj.hhmmss;
    splitBackEnd = splitBackEndObj.hhmmss;
    if (splitFrontEndObj.totalSeconds === 0) {
      splitError = "Split time cannot match the start time";
    } else if (splitBackEndObj.totalSeconds === 0) {
      splitError = "Split time cannot match the end time";
    } else if (splitFrontEndObj.totalSeconds < 0) {
      splitError = "Split time cannot be before the start time";
    } else if (splitBackEndObj.totalSeconds < 0) {
      splitError = "Split time cannot be after the end time";
    }
  }

  let futureTimer = false;
  let overlapping = false;
  let reverseTimer = false;
  let zeroSeconds = false;
  let runningCtr = 0;

  const today = _date.stamp() === _date.stamp(date);

  for (let a = 0; a < tableData.length; a++) {
    const A = tableData[a];
    if (A.endTime === "") runningCtr++;

    if (A.endTime !== "" && A.startTime > A.endTime) reverseTimer = true;

    if (
      moment().diff(
        _datetime.fromMomentDateStringTime(date, A.startTime),
        "seconds",
      ) < 0
    )
      futureTimer = true;

    if (
      A.endTime !== "" &&
      moment().diff(
        _datetime.fromMomentDateStringTime(date, A.endTime),
        "seconds",
      ) < 0
    )
      futureTimer = true;

    if (A.endTime !== "" && A.startTime === A.endTime) zeroSeconds = true;
    for (let b = 0; b < tableData.length; b++) {
      if (overlapping) break;

      if (a !== b) {
        const B = tableData[b];
        if (
          A.startTime >= B.startTime &&
          (B.endTime === "" || A.startTime < B.endTime)
        ) {
          overlapping = true;
        }
      }
    }
  }

  const cancelMe = () => {
    setTableData(lastGoodData);
    onCancel();
  };

  const errorMessage =
    runningCtr > 1
      ? "Cannot have more than one running timers"
      : futureTimer
      ? "A timer cannot exist in the future!"
      : reverseTimer
      ? "A timer cannot start after it finishes!"
      : overlapping
      ? "Timers are overlapping!"
      : zeroSeconds
      ? "Timers cannot be 0 seconds long"
      : "";

  // When our cell renderer calls updateData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateData = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      );
    },
    [setTableData],
  );

  const addRow = () => {
    const newTableData = [...tableData];
    const nowStamp = _datetime.timeOnlyStamp();
    newTableData.push({ startTime: nowStamp, endTime: nowStamp });
    setTableData(newTableData);
  };

  const deleteRow = useCallback(
    (rowIndex) => {
      setTableData((old) => old.filter((v, i) => i !== rowIndex));
    },
    [setTableData],
  );

  const hideSplitModal = () => {
    setShowSplitModal(false);
    setSplitIndex(null);
    setSplitTime(null);
  };

  const saveSplit = useCallback(() => {
    const newData = [];
    for (let i = 0; i < tableData.length; i++) {
      if (i !== splitIndex) {
        newData.push({ ...tableData[i] });
      } else {
        newData.push({ ...tableData[i], endTime: splitTime });
        newData.push({ ...tableData[i], startTime: splitTime });
      }
    }
    setTableData(newData);
    setShowSplitModal(false);
    setSplitIndex(null);
    setSplitTime(null);
  }, [splitIndex, splitTime, tableData]);

  const stopRow = useCallback(
    (rowIndex) => {
      setTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              endTime: _datetime.timeOnlyStamp(),
            };
          }
          return row;
        }),
      );
    },
    [setTableData],
  );

  const makeRunningRow = useCallback(
    (rowIndex) => {
      setTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              endTime: "",
            };
          }
          return row;
        }),
      );
    },
    [setTableData],
  );

  const changeSelected = useCallback(
    (rowIndex, selected) => {
      setTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              selected: selected,
            };
          }
          return row;
        }),
      );
    },
    [setTableData],
  );

  const clickedOk = () => {
    //    if (splitMode) {
    //      const splitArray = tableData.filter((t) => t.selected === true);
    //      const origArray = tableData.filter((t) => t.selected !== true);
    //      setTableData(origArray);
    //      setLastGoodData(origArray);
    //      onOk(origArray, splitArray);
    //    } else {
    setLastGoodData(tableData);
    onOk(tableData);
    //    }
  };

  const layout = useMemo(() => {
    return splitMode
      ? ["select", "startTime", "endTime", "stopOrRun", "split", "duration"]
      : ["startTime", "endTime", "stopOrRun", "split", "delete", "duration"];
  }, [splitMode]);

  const columns = useMemo(() => {
    const columnsArray = [];
    if (splitMode) {
      columnsArray.push({
        id: "select",
        Header: "Select",
        accessor: "selected",
        disableFilters: true,
        disableSortBy: true,

        Cell: ({ value, row: { index } }) => {
          if (value) {
            return (
              <Button
                onClick={() => changeSelected(index, false)}
                className="p-0 m-0"
                disabled={disabled}
              >
                <RiCheckboxFill className="p-0" size="28px" />
              </Button>
            );
          } else {
            return (
              <Button
                onClick={() => changeSelected(index, true)}
                className="p-0 m-0"
                disabled={disabled}
              >
                <RiCheckboxBlankFill className="p-0" size="28px" />
              </Button>
            );
          }
        },
      });
    }
    const normalArray = [
      {
        id: "startTime",
        Header: "Start",
        accessor: "startTime",
        disableFilters: true,

        Cell: ({ value, row: { index }, column: { id } }) => (
          <RangeCellMemo
            date={date}
            initialValue={value}
            disabled={disabled}
            index={index}
            updateData={updateData}
            id={id}
            setShowTimeExampleModal={setShowTimeExampleModal}
            validTimesButton={validTimesButton}
          />
        ),
      },
      {
        id: "endTime",
        Header: "End",
        accessor: "endTime",
        disableFilters: true,

        Cell: ({ value, row: { index }, column: { id } }) => (
          <RangeCellMemo
            date={date}
            initialValue={value}
            disabled={disabled}
            index={index}
            updateData={updateData}
            id={id}
            setShowTimeExampleModal={setShowTimeExampleModal}
            validTimesButton={validTimesButton}
          />
        ),
      },
      {
        id: "stopOrRun",
        Header: "",
        disableFilters: true,
        disableSortBy: true,
        accessor: "endTime",

        Cell: ({ row: { index }, value }) => {
          if (value === "") {
            return (
              <Button
                onClick={() => stopRow(index)}
                className="p-0 m-0"
                disabled={disabled}
              >
                <GiStopSign className="p-0" size="28px" />
              </Button>
            );
          } else {
            return (
              <Button
                onClick={() => {
                  makeRunningRow(index);
                }}
                className="p-0 m-0"
                disabled={disabled || !today}
              >
                <FaRunning className="p-0" size="28px" />
              </Button>
            );
          }
        },
      },
      {
        id: "split",
        Header: "",
        disableFilters: true,
        accessor: "startTime",

        Cell: ({ row: { index } }) => (
          <Button
            className="p-0 m-0"
            onClick={() => {
              setShowSplitModal(true);
              setSplitIndex(index);
              setSplitTime(newTimers[index].startTime);
            }}
            disabled={disabled}
          >
            <MdCallSplit className="p-0" size="28px" />
          </Button>
        ),
        disableSortBy: true,
      },
      {
        id: "delete",
        Header: "",
        disableFilters: true,
        accessor: "startTime",

        Cell: ({ row: { index } }) => (
          <Button
            onClick={() => deleteRow(index)}
            className="p-0 m-0"
            disabled={disabled}
          >
            <TiDelete className="p-0" size="28px" />
          </Button>
        ),
        disableSortBy: true,
      },
      {
        id: "duration",
        Header: "Duration",
        disableFilters: true,
        accessor: "totalSeconds",
        Cell: ({ value }) => formatTotalSeconds(value).hhmmss,
        disableSortBy: true,
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => {
                return parseFloat(row.values.duration) + sum;
              }, 0),
            [info.rows],
          );

          return formatTotalSeconds(total).hhmmss;
        },
      },
    ];
    return columnsArray.concat(normalArray);
  }, [
    splitMode,
    disabled,
    changeSelected,
    date,
    updateData,
    stopRow,
    today,
    makeRunningRow,
    newTimers,
    deleteRow,
  ]);

  const getRowProps = React.useCallback(
    (row) => {
      return splitMode && row?.original?.selected
        ? {
            className:
              "table-warning align-items-center justify-content-center align-self-center",
          }
        : row?.original?.endTime === ""
        ? {
            className:
              "table-success align-items-center justify-content-center align-self-center",
          }
        : {
            className:
              "table-secondary align-items-center justify-content-center align-self-center",
          };
    },
    [splitMode],
  );

  return (
    <>
      <Modal
        onEscapeKeyDown={() => setShowTimeExampleModal(false)}
        enforceFocus={false}
        show={showTimeExampleModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          className="table-dark"
          closeButton
          onHide={() => setShowTimeExampleModal(false)}
        >
          <h5>Time input examples</h5>
        </Modal.Header>
        <Modal.Body className="px-0 py-0 mx-0 my-0 table-dark">
          <ValidTimesBody />
        </Modal.Body>
      </Modal>

      <Modal
        onEscapeKeyDown={hideSplitModal}
        onHide={hideSplitModal}
        enforceFocus={false}
        show={showSplitModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          className="table-light"
          closeButton
          onHide={() => setShowTimeExampleModal(false)}
        >
          <h5>Split this timer</h5>
        </Modal.Header>
        <Modal.Body className="table-light">
          <Row className="justify-content-center align-self-center">
            <Col>
              <Row>
                <Col>
                  <RangeCellMemo
                    date={date}
                    initialValue={newTimers[splitIndex]?.startTime}
                    disabled={true}
                    index={splitIndex}
                    updateData={updateData}
                    setShowTimeExampleModal={setShowTimeExampleModal}
                    validTimesButton={validTimesButton}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <CgArrowsV size={20} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <RangeCellMemo
                    date={date}
                    initialValue={splitTime}
                    index={splitIndex}
                    updateData={(x, y, newTime) => {
                      setSplitTime(newTime);
                    }}
                    setShowTimeExampleModal={setShowTimeExampleModal}
                    validTimesButton={validTimesButton}
                    borderColor="black"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <CgArrowsV size={20} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <RangeCellMemo
                    date={date}
                    initialValue={newTimers[splitIndex]?.endTime}
                    disabled={true}
                    index={splitIndex}
                    updateData={updateData}
                    setShowTimeExampleModal={setShowTimeExampleModal}
                    validTimesButton={validTimesButton}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="">
              <Row className="text-center font-weight-bold">
                <Col>Duration</Col>
              </Row>
              <Row className="pt-3 text-center">
                <Col>{splitFrontEnd}</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row className="pt-4 text-center">
                <Col>{splitBackEnd}</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
            </Col>
          </Row>
          {splitError !== "" ? (
            <Row className="mt-1 mb-0 pb-0">
              <Col>
                <Alert variant="danger" className="mb-0">
                  {splitError}
                </Alert>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer className="justify-content-between table-light">
          <div>&nbsp;</div>
          <div>
            <Row>
              <Button
                className="mr-1"
                onClick={() => {
                  saveSplit();
                }}
                variant="primary"
                disabled={splitError !== ""}
              >
                Ok
              </Button>
              <Button
                className="mr-2"
                onClick={hideSplitModal}
                variant="secondary"
              >
                Cancel
              </Button>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        onEscapeKeyDown={cancelMe}
        enforceFocus={false}
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton onHide={cancelMe}>
          <Modal.Title id="contained-modal-title-vcenter">
            {splitMode ? "Select Timers to Split off" : "Timers"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Table
                    bordered
                    hover
                    size="sm"
                    label="Timers"
                    columns={columns}
                    layout={layout}
                    data={newTimers}
                    initialSort={{ id: "startTime", desc: false }}
                    getRowProps={getRowProps}
                    isLoading={isLoading}
                    moveFooterToTop
                    updateData={updateData}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {errorMessage !== "" ? (
                    <Alert variant="danger" block>
                      {errorMessage}
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Button
              variant="info"
              onClick={() => setTableData(timers)}
              disabled={disabled}
            >
              Reset
            </Button>{" "}
            <Button
              variant="success"
              onClick={() => addRow()}
              disabled={disabled}
            >
              Add
            </Button>
          </div>
          <div>
            <Button
              className="mr-1"
              onClick={clickedOk}
              variant="primary"
              disabled={errorMessage !== "" || disabled}
            >
              {splitMode ? "Perform Split" : "Ok"}
            </Button>
            <Button className="mr-0" onClick={cancelMe} variant="secondary">
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function convertValue(date, newValue) {
  return _datetime.displayTimeSeconds(
    _datetime.fromMomentDateStringTime(date, newValue),
  );
}

function RangeCell({
  initialValue,
  date,
  disabled,
  index,
  id,
  updateData,
  setShowTimeExampleModal,
  validTimesButton,
  borderColor = "white",
}) {
  const [value, setValue] = useState(convertValue(date, initialValue));
  const target = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);
  //If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(convertValue(date, initialValue));
  }, [date, initialValue]);

  const onChange = (x) => {
    setShowToolTip(true);
    setValue(x);
  };

  // this timeout was put in place to allow the click on show time examples
  // button to get processed
  const onBlur = (e) => {
    if (
      e.relatedTarget !== null &&
      e.relatedTarget === validTimesButton.current
    ) {
      setShowTimeExampleModal(true);
    }
    onUpdate();
    //setTimeout(onUpdate, 200);
  };

  const onUpdate = useCallback(() => {
    setShowToolTip(false);
    const newTimeStamp = parseTime(value);
    if (newTimeStamp !== "INVALID") {
      setValue(convertValue(date, newTimeStamp));
      updateData(index, id, newTimeStamp);
    } else {
      // reset to last known good value
      setValue(convertValue(date, initialValue));
      toast.error("Invalid time rejected");
    }
  }, [updateData, index, id, setValue, value, initialValue, date]);

  let validTime = parseTime(value);
  const validTimeDisplay =
    validTime === "INVALID"
      ? validTime
      : _datetime.displayTimeSeconds(
          _datetime.fromMomentDateStringTime(date, validTime),
        );
  const bgcolor = validTime === "INVALID" ? "red" : borderColor;

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      //e.preventDefault();
      //e.stopPropagation();
      onUpdate();
    }
  };
  return (
    <>
      {initialValue !== "" ? (
        <>
          <Form.Control
            type="text"
            disabled={disabled}
            onChange={(x) => onChange(x.target.value)}
            onBlur={onBlur}
            onSubmit={onUpdate}
            value={value}
            onKeyDown={onKeyDown}
            style={{ borderColor: bgcolor }}
            ref={target}
          />
          <Overlay
            xs={8}
            target={target.current}
            show={showToolTip}
            placement="bottom"
          >
            {validTime === "INVALID" ? (
              <Popover className="table-danger my-0 mx-0 py-0 px-0 align-text-center">
                <Alert
                  variant="danger"
                  className="my-0 mx-0 py-0 px-0 align-text-center w-100"
                >
                  Invalid time
                </Alert>
                <Popover.Content className="table-danger mx-0 my-0 px-0 py-0">
                  <Button
                    ref={validTimesButton}
                    size="sm"
                    onClick={() => {
                      setShowTimeExampleModal(true);
                      setShowToolTip(false);
                    }}
                  >
                    Acceptable time inputs
                  </Button>
                </Popover.Content>
              </Popover>
            ) : (
              <Popover>
                <Popover.Content variant={"success"}>
                  {validTimeDisplay}
                </Popover.Content>
              </Popover>
            )}
          </Overlay>
        </>
      ) : (
        <>Running</>
      )}
    </>
  );
}

function ValidTimesBody() {
  return (
    <BSTable striped bordered hover variant="dark" size="sm">
      <thead>
        <tr>
          <th style={{ textAlign: "center" }}>Input</th>
          <th style={{ textAlign: "center" }}>Output</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td style={{ textAlign: "right" }}>1:00:00 am</td>
        </tr>
        <tr>
          <td>10</td>
          <td style={{ textAlign: "right" }}>10:00:00 am</td>
        </tr>
        <tr>
          <td xs={3}>101</td>
          <td xs={3} style={{ textAlign: "right" }}>
            1:01:00 am
          </td>
        </tr>
        <tr>
          <td xs={3}>1010</td>
          <td xs={3} style={{ textAlign: "right" }}>
            10:10:00 am
          </td>
        </tr>
        <tr>
          <td>10101</td>
          <td style={{ textAlign: "right" }}>1:01:01 am</td>
        </tr>
        <tr>
          <td>101010</td>
          <td style={{ textAlign: "right" }}>10:10:10 am</td>
        </tr>
        <tr>
          <td>0</td>
          <td style={{ textAlign: "right" }}>12:00:00 am</td>
        </tr>
        <tr>
          <td>23:59</td>
          <td style={{ textAlign: "right" }}>11:59:00 pm</td>
        </tr>
        <tr>
          <td>23:59:02</td>
          <td style={{ textAlign: "right" }}>11:59:02 pm</td>
        </tr>
        <tr>
          <td>9p</td>
          <td style={{ textAlign: "right" }}>9:00:00 pm</td>
        </tr>
        <tr>
          <td>9:01a</td>
          <td style={{ textAlign: "right" }}>9:01:00 am</td>
        </tr>
        <tr>
          <td>9:01:32 pm</td>
          <td style={{ textAlign: "right" }}>9:01:32 pm</td>
        </tr>
      </tbody>
    </BSTable>
  );
}

const RangeCellMemo = React.memo(RangeCell);

export default React.memo(TimeTimersTable);
