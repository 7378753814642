import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import CodeTestIndex from "./CodeTestIndex";
import CodeTestShow from "./CodeTestShow";

export default function CodeTestsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <CodeTestShow />
      </Route>
      <Route exact path={`${path}`}>
        <CodeTestIndex />
      </Route>
    </Switch>
  );
}
