import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Customer from "../../customers";
import Project from "../../projects";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportCustomerTime() {
  return (
    <JobPage
      title="Customer Crs"
      functionName="CustomerCrs"
      Options={CustomerCrsOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.crs || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Cr Owner",
        Header: "Cr Owner",
        accessor: "crOwner",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrCount,
      },
      {
        id: "Project Owner",
        Header: "Project Owner",
        accessor: "prOwner",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrCount,
      },
      {
        Header: "Cr Group",
        id: "Cr Group",
        accessor: "crGroup",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Project Group",
        id: "Project Group",
        accessor: "projectGroup",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Cr Status",
        id: "Cr Status",
        accessor: "crStatus",
        Filter: Cr.Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Cr.Status.Description id={value} />,
      },
      {
        Header: "Project Status",
        id: "Project Status",
        accessor: "projectStatus",
        Filter: Project.Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Status.Description id={value} />,
      },
      {
        Header: "Cr Type",
        id: "Cr Type",
        accessor: "crType",
        Filter: Project.Type.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
      {
        Header: "Project Type",
        id: "Project Type",
        accessor: "projectType",
        Filter: Project.Type.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
      {
        Header: "Project Priority",
        id: "Project Priority",
        accessor: "projectPriority",
        Filter: Project.Priority.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Priority.Description id={value} />,
      },
      {
        id: "Folder",
        Header: "Folder",
        accessor: "folder",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Project",
        Header: "Project",
        accessor: "project",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Project.Link id={value} />,
      },
      {
        id: "Cr",
        Header: "Cr",
        accessor: "cr",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Cr.Link id={value} />,
      },
      {
        id: "Contact",
        Header: "Contact",
        accessor: "contactName",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Cr Create Date",
        Header: "Cr Create Date",
        accessor: "crCreateDate",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrEmpty,
        Aggregated: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Cr Last Updated Date",
        Header: "Cr Last Updated",
        accessor: "crLastUpdatedDate",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrEmpty,
        Aggregated: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Cr Last Updated User",
        Header: "Cr Last Updated User",
        accessor: "crLastUpdatedUser",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: allSameOrCount,
      },
      {
        id: "Cr Synopsis",
        Header: "Cr Synopsis",
        accessor: "crSynopsis",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Project Synopsis",
        Header: "Project Synopsis",
        accessor: "prSynopsis",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Cr Description",
        Header: "Cr Description",
        accessor: "crDescription",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Project Description",
        Header: "Project Description",
        accessor: "prDescription",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
      {
        id: "Fix Notes",
        Header: "Fix notes",
        accessor: "fixNotes",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: allSameOrCount,
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "Project",
              "Cr",
              "Contact",
              "Cr Synopsis",
              "Priority",
              "Cr Status",
              "Cr Type",
              "Cr Group",
              "Cr Owner",
              "Cr Description",
              "Fix Notes",
              "Cr Create Date",
              "Cr Last Updated Date",
              "Cr Last Updated User",
              "Folder",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

function CustomerCrsOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("month"),
  endDate: ed = moment().endOf("month"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      customerId: "",
      startDate: "",
      endDate: "",
      ...options,
    },
  });

  const endDate = watch("endDate");
  const startDate = watch("startDate");
  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          "from " +
          _date.display(options.startDate) +
          " to " +
          _date.display(options.endDate);

        desc = useCustomerValue?.customer?.name + " " + desc;
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}

function allSameOrCount(leafValues) {
  return _.uniq(leafValues).length === 1
    ? leafValues[0]
    : _.uniq(leafValues).length + "*";
}

function allSameOrEmpty(leafValues) {
  return _.uniq(leafValues).length === 1 ? leafValues[0] || "" : "";
}
