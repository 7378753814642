import { toast } from "react-toastify";

import { createEntityReducer } from "../../../common/entity";
import { buildAsyncThunkReducer } from "../../../common/functions/state";
import { actions } from "./actions";
import { entity } from "./entity";

export const reducer = createEntityReducer({
  entity: entity,
  actions: actions,
  persist: true,
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, actions.addMember, (state, action) => {
      state.entities[action.payload.id].members = action.payload.members;
      //userGroupingsAdapter.upsertOne(state, action.payload);

      toast.success("Member Added", {
        autoClose: 2000,
        closeButton: false,
      });
    });
    buildAsyncThunkReducer(builder, actions.deleteMember, (state, action) => {
      state.entities[action.payload.id].members = action.payload.members;
      //userGroupingsAdapter.upsertOne(state, action.payload);

      toast.success("Member Deleted", {
        autoClose: 2000,
        closeButton: false,
      });
    });
  },
});
