import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

import { Forms, FormsField } from "../../common/forms";
import User from "../../users";
import sourceCodeSchema from "./sourceCodeSchema";

export default function SourceCodeEditor({ values = {}, onSubmit, isLoading }) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: sourceCodeSchema }}
        showDevTool
        isDisabled={true}
      >
        <SourceCodeForm isLoading={isLoading} />
      </Forms>
    </>
  );
}

function SourceCodeForm() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <SourceCodeTextField id="id" label="Filename" />
          </Form.Row>

          <Form.Row className="pb-0 my-0">
            <Col>
              <Form.Group id={"groupId"} className="my-0">
                <InputGroup className="my-0">
                  <InputGroup.Prepend className="mb-2">
                    <InputGroup.Text
                      className="bg-secondary text-light"
                      style={{ minWidth: "150px" }}
                    >
                      {"Group"}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Row className="my-0">
                    <Col xs="auto">
                      <FormsField.Select
                        name="groupId"
                        as={User.Group.Select}
                        formGroupClassName="mb-2"
                        styles={{
                          input: (style) => ({
                            ...style,
                            minWidth: 243,
                          }),
                          container: (style) => ({
                            ...style,
                            width: "auto",
                          }),
                          menu: (style) => ({
                            ...style,
                            width: "150%",
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                </InputGroup>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="pb-0 my-0">
            <Col>
              <Form.Group id={"ownerId"} className="my-0">
                <InputGroup className="my-0">
                  <InputGroup.Prepend className="mb-2">
                    <InputGroup.Text
                      className="bg-secondary text-light"
                      style={{ minWidth: "150px" }}
                    >
                      {"Owner"}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Row className="my-0">
                    <Col xs="auto">
                      <FormsField.Select
                        name="ownerId"
                        as={User.Select}
                        formGroupClassName="mb-2"
                        styles={{
                          input: (style) => ({
                            ...style,
                            minWidth: 243,
                          }),
                          container: (style) => ({
                            ...style,
                            width: "auto",
                          }),
                          menu: (style) => ({
                            ...style,
                            width: "150%",
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                </InputGroup>
              </Form.Group>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SourceCodeTextField({ id, label }) {
  return (
    <Col xs="auto">
      <Form.Group id={id} className="my-0">
        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text
              className="bg-secondary text-light"
              style={{ minWidth: "150px" }}
            >
              {label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormsField.Control
            name={id}
            autoComplete="off"
            style={{ minWidth: "300px" }}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  );
}
