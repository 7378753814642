import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsArrowsCollapse,
  BsArrowsExpand,
  BsEye,
  BsEyeSlashFill,
  BsFillLockFill,
} from "react-icons/bs";

import useSettings from "../../../settings/useSettings";
import { _date } from "../../functions/dates";

export function GoogleCalendarForm({
  showMode = "MONTH",
  calendars = [],
  height = 800,
  toggleLock = false,
}) {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [modeValue, setModeValue] = useState(showMode);
  const [lastCalendarInput, setLastCalendarInput] = useState([]);
  const [calendarValues, setCalendarValues] = useState([]);
  const [currentHeight, setCurrentHeight] = useState(height);

  const colors = [
    { input: "#4285F4", actual: "#668CD9" }, //cobalt
    { input: "#33B679", actual: "#65AD89" }, //sage
    { input: "#EF6C00", actual: "#C7561E" }, //pumpkin
    { input: "#D81B60", actual: "#E67399" }, //cherry blossom
    { input: "#E4C441", actual: "#E0C240" }, //citron
    { input: "#9E69AF", actual: "#603F99" }, //amethyst
    { input: "#D50000", actual: "#AD2D2D" }, //tomato
    { input: "#A79B8E", actual: "#C4A883" }, //birch
    { input: "#616161", actual: "#737373" }, //graphite
    { input: "#F4511E", actual: "#E6804D" }, //tangerine
    { input: "#795548", actual: "#914D14" }, //cocoa
    { input: "#B39DDB", actual: "#8C66D9" }, //wisteria
    { input: "#E67C73", actual: "#B5515D" }, //flamingo
    { input: "#039BE5", actual: "#59BFB3" }, //peacock
    { input: "#F6BF26", actual: "#CF9911" }, //banana
    { input: "#0B8043", actual: "#3C995B" }, //basil
    { input: "#C0CA33", actual: "#A7B828" }, //avocado
    { input: "#7986CB", actual: "#668CB3" }, //lavendar
    { input: "#AD1457", actual: "#BE9494" }, //radicchio
    { input: "#8E24AA", actual: "#9643A5" }, //grape
    { input: "#F09300", actual: "#D47F1E" }, //mango
    { input: "#3F51B5", actual: "#536CA6" }, //blueberry
    { input: "#7CB342", actual: "#7EC225" }, //pistachio
    { input: "#009688", actual: "#41A587" }, //eucalyptus
  ];

  const offsetDate = (offset = 0) => {
    let key = "month";
    if (modeValue === "WEEK") key = "week";
    if (modeValue === "DAY") key = "day";
    setStartDate((d) => {
      return moment(d).subtract(offset, key);
    });
    setEndDate((d) => {
      return moment(d).subtract(offset, key);
    });
  };

  if (!_.isEqual(lastCalendarInput, calendars)) {
    let newCalendarsValues = [];

    // retain existing records if its Locked status or still exists
    // don't change colors, etc
    calendarValues.forEach((cv) => {
      if (cv.toggle === "L") {
        newCalendarsValues.push({ ...cv });
      } else {
        calendars.some((value) => {
          if (cv.id === value.id) {
            newCalendarsValues.push({ ...cv });
            return true;
          } else {
            return false;
          }
        });
      }
    });

    // Add new records
    for (let c = 0; c < calendars.length; c++) {
      let found = false;
      for (let n = 0; n < newCalendarsValues.length; n++) {
        if (newCalendarsValues[n].id === calendars[c].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        newCalendarsValues.push({ ...calendars[c], toggle: "V" });
      }
    }

    //color counter, try to make each ID unique color..
    //but we may have more calendars than colors?
    let colorCounters = [];
    for (let r = 0; r < colors.length; r++) {
      let counter = 0;
      for (let n = 0; n < newCalendarsValues.length; n++) {
        if (newCalendarsValues[n]?.color?.input === colors[r].input) {
          counter++;
        }
      }
      colorCounters.push(counter);
    }

    // Try to assign new calendar ids a unique color
    for (let n = 0; n < newCalendarsValues.length; n++) {
      if (!newCalendarsValues[n].color) {
        for (let pass = 0; pass < 50; pass++) {
          let found = false;
          for (let r = 0; r < colors.length; r++) {
            if (colorCounters[r] === pass) {
              colorCounters[r]++;
              newCalendarsValues[n].color = { ...colors[r] };
              found = true;
              break;
            }
          }
          if (found) break;
        }
      }
    }

    setLastCalendarInput(calendars);
    setCalendarValues(newCalendarsValues);
  }

  const toggleCalendar = (id, currentValue) => {
    let newValue = "V";
    if (currentValue === "V") newValue = "H";
    else if (currentValue === "H") newValue = toggleLock ? "L" : "V";

    let newCals = [];
    for (let c = 0; c < calendarValues.length; c++) {
      if (calendarValues[c].id === id) {
        newCals.push({ ...calendarValues[c], toggle: newValue });
      } else {
        newCals.push({ ...calendarValues[c] });
      }
    }
    setCalendarValues(newCals);
  };

  let rawCalendarData = calendarValues.filter((cal) => {
    return cal.toggle !== "H";
  });

  return (
    <>
      <Row className="justify-content-between">
        <Col>
          <ButtonGroup>
            <Button
              variant="outline-primary"
              className="px-3"
              onClick={() => offsetDate(1)}
            >
              <BsArrowLeftShort className="p-0" size="28px" />
            </Button>
            <Button
              variant="outline-primary"
              className="px-3"
              onClick={() => offsetDate(-1)}
            >
              <BsArrowRightShort className="p-0" size="28px" />
            </Button>
            <Button
              variant="primary"
              className="px-3"
              onClick={() => setStartDate(moment())}
            >
              Today
            </Button>
          </ButtonGroup>
        </Col>
        <Col>
          <ButtonGroup toggle>
            <ToggleButton
              key="modeMonth"
              type="radio"
              variant="secondary"
              value={true}
              checked={modeValue === "MONTH"}
              onChange={() => setModeValue("MONTH")}
            >
              Month
            </ToggleButton>
            <ToggleButton
              key="modeWeek"
              type="radio"
              variant="secondary"
              value={true}
              checked={modeValue === "WEEK"}
              onChange={() => setModeValue("WEEK")}
            >
              Week
            </ToggleButton>
            <ToggleButton
              key="modeDay"
              type="radio"
              variant="secondary"
              value={true}
              checked={modeValue === "DAY"}
              onChange={() => setModeValue("DAY")}
            >
              Day
            </ToggleButton>
            <ToggleButton
              key="modeAgenda"
              type="radio"
              variant="secondary"
              value={true}
              checked={modeValue === "AGENDA"}
              onChange={() => setModeValue("AGENDA")}
            >
              Agenda
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col>
          <ButtonGroup>
            <Button
              variant="danger"
              onClick={() => {
                if (currentHeight > 400) setCurrentHeight(currentHeight - 100);
              }}
            >
              <BsArrowsCollapse className="p-0" size="28px" />
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setCurrentHeight(currentHeight + 100);
              }}
            >
              <BsArrowsExpand className="p-0" size="28px" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GoogleCalendarRaw
            showNav={0}
            showTabs={0}
            showCalendars={0}
            showTitle={0}
            mode={modeValue}
            calendars={rawCalendarData}
            startDate={startDate}
            endDate={endDate}
            height={currentHeight + "px"}
          />
        </Col>
        <Col xs={"auto"}>
          <Row>
            <Col style={{ backgroundColor: "white", color: "black" }}>
              {"Calendars"}
            </Col>
          </Row>
          {calendarValues.map((c) => {
            return (
              <Row
                key={c.id}
                className="justify-content-between"
                style={{
                  backgroundColor: c.color?.actual,
                  color: "white",
                }}
                onClick={() => toggleCalendar(c.id, c.toggle)}
              >
                <Col className="p-0 pl-1 pr-1" key={c.id}>
                  {c.name ? c.name : c.id}
                </Col>
                <Col xs={"auto"} className="pl-1 pr-1">
                  {c.toggle === "V" ? (
                    <BsEye />
                  ) : c.toggle === "H" ? (
                    <BsEyeSlashFill />
                  ) : (
                    <BsFillLockFill />
                  )}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
}

export function GoogleCalendarRaw({
  calendars = [],
  mode = "MONTH",
  showPrint = 0,
  showNav = 1,
  showTabs = 1,
  showTitle = 1,
  showCalendars = 1,
  startDate = null,
  endDate = null,
  height = "800px",
}) {
  //    "&dates=20220110/20220207" +

  let dates = null;
  if (startDate !== null && endDate !== null) {
    let sd = _date.stamp(startDate);
    let ed = _date.stamp(endDate);
    dates = sd + "/" + ed;
  }
  const { settings } = useSettings({ canGet: true });

  let timeZone = settings.googleCalendarTimeZone;
  if (timeZone === "" || timeZone === null) {
    timeZone = "America/Chicago";
  }
  if (!timeZone) timeZone = "America/Chicago";
  timeZone = timeZone.replaceAll("/", "%2F");

  const urlBase = "https://calendar.google.com/calendar/embed?ctz=" + timeZone;
  let finalUrl = urlBase + "";
  finalUrl = finalUrl + "&showPrint=" + showPrint;
  finalUrl = finalUrl + "&showTitle=" + showTitle;
  finalUrl = finalUrl + "&mode=" + mode;
  finalUrl = finalUrl + "&showCalendars=" + showCalendars;
  finalUrl = finalUrl + "&showNav=" + showNav;
  finalUrl = finalUrl + "&showTabs=" + showTabs;
  if (dates !== null) {
    finalUrl = finalUrl + "&dates=" + dates;
  }

  for (let c = 0; c < calendars.length; c++) {
    let src = btoa(calendars[c].id);
    src = src.replaceAll("=", "");
    finalUrl = finalUrl + "&src=" + src;
    if (calendars[c].color) {
      finalUrl =
        finalUrl + "&color=" + calendars[c].color.input.replaceAll("#", "%23");
    }
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          src={finalUrl}
          frameBorder={"0"}
          scrolling={"no"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: height,
          }}
        ></iframe>
      </div>
    </>
  );
}
