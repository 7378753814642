import { ApprovalsFilters } from "./ApprovalsFilters";
import ApprovalsSkipped from "./ApprovalsSkipped";
import ApprovalsStatement from "./ApprovalsStatement";
import ApprovalsTable from "./ApprovalsTable";
import { approvalsActions } from "./state";

const Approvals = {
  // State
  actions: approvalsActions,
  // Components
  Filters: ApprovalsFilters,
  Statement: ApprovalsStatement,
  Skipped: ApprovalsSkipped,
  Table: ApprovalsTable,
};

export default Approvals;
