import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useUser } from "./hooks";
import Pseudo from "./pseudo";
import UserModal from "./UserModal";

export default function UserLink({ id, showId = false }) {
  const authUser = useSelector((state) => state.auth.user);
  const { user } = useUser({ id });
  const { pseudo } = Pseudo.useOne({ id });
  const [show, setShow] = useState(false);

  const _user = id?.charAt(0) !== "*" ? user : pseudo;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const text = showId || !_user ? id : _user?.name;

  if (!_user?.id)
    return <span>{text === "CUSTSUP" ? "Customer Support Portal" : text}</span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <abbr title="User Details" onClick={handleShow}>
        {id === authUser ? <b>{text}</b> : text}
      </abbr>
      <UserModal
        id={id}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      />
    </span>
  );
}
