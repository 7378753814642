import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { settingsActions } from "./state";

export default function useSettings({ canGet = false } = {}) {
  const [loaded, setLoaded] = useState(false);
  const settings = useSelector(
    (state) => state.settings.settings,
    shallowEqual,
  );
  const loading = useSelector((state) => state.settings.requests.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canGet) {
      dispatch(settingsActions.getSettings()).then(() => setLoaded(true));
    }
  }, [dispatch, canGet]);

  const _loading = canGet && (loading || !loaded);

  return { settings, loading: _loading };
}
