import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";

import JobsTable from "../activity/jobs/JobsTable";
import useJobs from "../activity/jobs/useJobs";
import NotificationsTable from "../activity/notifications/NotificationsTable";
import useNotifications from "../activity/notifications/useNotifications";
import UserActivityTable from "../activity/userActivity/UserActivityTable";
import useUserActivity from "../activity/userActivity/useUserActivity";
import { GoogleCalendarForm } from "../common/components/calendars";
import Page from "../common/pages/Page";

export default function Home() {
  return (
    <Page title="Home" tableOfContents={false} navSidebar={false}>
      <Tab.Container defaultActiveKey={"calendar"} mountOnEnter>
        <Row>
          <Col xs={8}>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="calendar">Calendar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="notifications">Notifications</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="activity">Activity</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="jobs">Jobs</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <div className="dropdown-divider" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content className={"mx-3"}>
              <Tab.Pane eventKey="calendar">
                <CalendarTab />
              </Tab.Pane>
              <Tab.Pane eventKey="notifications">
                <NotificationTab />
              </Tab.Pane>
              <Tab.Pane eventKey="activity">
                <ActivityTab />
              </Tab.Pane>
              <Tab.Pane eventKey="jobs">
                <JobsTab />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Page>
  );
}

function CalendarTab() {
  const { email, name } = useSelector((state) => state.auth);
  const calendars = [];
  const companyCalendar = {
    id: "producepro.com_44jjf4ktbs146u0mojtf7d3000@group.calendar.google.com",
    name: "Company Calendar",
  };
  const myCalendar = {
    id: email,
    name: name,
  };
  calendars.push(companyCalendar);
  calendars.push(myCalendar);

  return (
    <>
      <br />
      <GoogleCalendarForm calendars={calendars} toggleLock={false} />{" "}
    </>
  );
}

function NotificationTab() {
  const {
    entities: notifications,
    loading: notificationsLoading,
    getMany: getNotifications,
    lastUpdate: lastUpdateNotifications,
  } = useNotifications();

  return (
    <NotificationsTable
      notifications={notifications}
      isLoading={notificationsLoading}
      onRefresh={() => {
        getNotifications();
      }}
      refreshLabel={
        "Fetched " + moment(lastUpdateNotifications).fromNow() + " \u27F3"
      }
    />
  );
}

function ActivityTab() {
  const {
    entities: userActivity,
    loading: userActivityLoading,
    getMany: getUserActivity,
    lastUpdate: lastUpdateUserActivity,
  } = useUserActivity();

  const _userActivity = _.uniqBy(
    _.reverse(_.sortBy(userActivity, "dateTime")),
    (a) => a.source + a.key + a.logDescription,
  );

  return (
    <UserActivityTable
      userActivity={_userActivity}
      isLoading={userActivityLoading}
      onRefresh={() => {
        getUserActivity();
      }}
      refreshLabel={
        "Fetched " + moment(lastUpdateUserActivity).fromNow() + " \u27F3"
      }
    />
  );
}

function JobsTab() {
  const { jobs, loading, getMany, lastUpdate } = useJobs({
    canGet: false,
  });

  useEffect(() => {
    getMany();
  }, [getMany]);

  return (
    <JobsTable
      jobs={jobs}
      isLoading={loading}
      onRefresh={() => {
        getMany();
      }}
      refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
    />
  );
}
