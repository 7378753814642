import shark from "../../../apis/shark";

export const api = {
  getMany: (filters) => {
    const payload = { command: "getCustomerThirdPartyLicenses" };
    if (filters?.customerId) {
      payload.customerId = filters?.customerId;
    }

    return shark.post(payload).then((r) => {
      return { data: r.responseData.customerThirdPartyLicenses };
    });
  },

  getOne: ({ id }) => {
    const payload = {
      command: "getCustomerThirdPartyLicense",
      id: id,
    };
    return shark.post(payload).then((response) => ({
      data: response.responseData,
    }));
  },

  createOne: (license) => {
    const payload = {
      command: "createCustomerThirdPartyLicense",
      ...license,
    };
    return shark
      .post(payload)
      .then(({ responseData }) => ({ data: responseData }));
  },

  updateOne: (license) => {
    const payload = {
      command: "updateCustomerThirdPartyLicense",
      ...license,
    };
    return shark
      .post(payload)
      .then(({ responseData }) => ({ data: responseData }));
  },

  createComment: ({ customerThirdPartyLicense, title, text }) => {
    const payload = {
      command: "createCustomerThirdPartyLicenseComment",
      customerThirdPartyLicense,
      title,
      text,
    };
    return shark.post(payload).then((r) => ({ data: r.responseData }));
  },

  updateComment: ({ customerThirdPartyLicense, title, text, id }) => {
    const payload = {
      command: "updateCustomerThirdPartyLicenseComment",
      customerThirdPartyLicense,
      id,
      title,
      text,
    };
    return shark.post(payload).then((r) => ({ data: r.responseData }));
  },
};
