import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function SettingsNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/settings">Settings</NavSidebarItem>
      <NavSidebarItem to="/settings/watched">Watched</NavSidebarItem>
      <NavSidebarItem to="/settings/cache">Cache</NavSidebarItem>
      <div className="dropdown-divider"></div>
      {children}
    </NavSidebar>
  );
}
