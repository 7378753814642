import _ from "lodash";
import React from "react";

import FieldSelect from "../fields/FieldSelect";

/**
 * @typedef {Object} Opts
 * @property {string} name Entity name
 * @property {function} useEntities Hook that returns entities
 * @property {function} getHookArgs Callback to get hook arguments from props
 * @property {function} label Callback to determine entity label
 * @property {function} filter Callback to filter entities
 */

/**
 * Create Description component based on entity
 * @param {Opts} opts Options
 */
export function entitySelect({
  name,
  useEntities,
  getHookArgs = () => ({ canGet: false }),
  label = (e) => e.description || e.id,
  filter = () => true,
}) {
  const entitySelect = React.forwardRef(
    (
      {
        value,
        isMulti = false,
        placeholder = `Select ${_.startCase(name)}...`,
        shouldValuesSortLikeOptions = false,
        ...props
      } = {},
      ref,
    ) => {
      const { entities = [], loading } = useEntities(getHookArgs(props));

      const _options = entities
        .filter((e) => filter(e, value))
        .map((e) => {
          return { label: label(e), value: e.id };
        });
      let valueOption = null;
      if (value)
        if (isMulti) {
          valueOption = shouldValuesSortLikeOptions
            ? _options.filter((o) => value.includes(o.value))
            : value.map((v) => _.find(_options, (o) => o.value === v));
        } else {
          valueOption = _.find(_options, ["value", value]);
        }

      return (
        <FieldSelect
          ref={ref}
          isLoading={loading}
          isSearchable={true}
          value={valueOption}
          options={_options}
          isMulti={isMulti}
          placeholder={placeholder}
          {...props}
        />
      );
    },
  );
  entitySelect.displayName = `${_.upperFirst(name)}Select`;
  return React.memo(entitySelect);
}

export default entitySelect;
