import React from "react";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function ReusableProgramShow() {
  const id = useParams().id;
  const { reusableProgram, loading } = Dbc.ReusableProgram.useOne({
    id: id,
    canGet: true,
  });

  return (
    <Page title="DBC Reusable Program" navSidebar={<DbcNavSidebar />}>
      {reusableProgram ? (
        <Dbc.ReusableProgram.Editor
          values={reusableProgram}
          isLoading={loading}
        />
      ) : (
        <h4>Reusable Program not found.</h4>
      )}
    </Page>
  );
}
