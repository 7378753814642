import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import TaxProduct from "../../invoices/taxProducts";
import ContractProductCategory from "../contractProductCategories";
import ContractProductLink from "./ContractProductLink";

export default function ContractProductTable({
  contractProducts,
  contractProductLink = true,
  Components: OverrideComponents,
  layout = [
    "Id",
    "Description",
    "Sort Value",
    "Contract Product Category",
    "Tax Product",
  ],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: contractProduct } }) =>
          contractProductLink ? (
            <ContractProductLink id={contractProduct?.id}>
              {value || contractProduct?.id}
            </ContractProductLink>
          ) : (
            value
          ),
      },
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Sort Value",
        Header: "Sort Value",
        accessor: "sortValue",
      },
      {
        id: "Tax Product",
        Header: "Tax Product",
        accessor: "taxProduct",
        Filter: TaxProduct.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <TaxProduct.Link id={value} />,
      },
      {
        id: "Contract Product Category",
        Header: "Contract Product Category",
        accessor: "contractProductCategory",
        Filter: ContractProductCategory.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <ContractProductCategory.Link id={value} />,
      },
    ],
    [contractProductLink],
  );
  return (
    <Table
      hasCount
      label="Contract Products"
      columns={columns}
      layout={layout}
      data={contractProducts}
      {...props}
    />
  );
}
