import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import DetailSection from "../common/details/DetailSection";
import DetailTable from "../common/details/DetailTable";
import Page from "../common/pages/Page";
import { useContact } from "./hooks";

function Details({ contact, isLoading }) {
  if (_.isUndefined(contact)) {
    return (
      <Row>
        <Col>{isLoading ? <p>Loading...</p> : <p>Contact Unavailable</p>}</Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col col={2}>{contact?.name}</Col>
      <Col>
        <DetailTable
          fields={[
            {
              header: "Status",
              value: contact.inactive ? "Inactive" : "Active",
            },
            { header: "ID", value: contact.id },
          ]}
        />
      </Col>
    </Row>
  );
}

export default function ContactShow() {
  const id = parseInt(useParams().id);
  const { contact } = useContact({ id });
  return (
    <Page title={contact?.name ? contact.name : "Contact"}>
      <DetailSection title="Details" key="detail-section">
        <Details contact={contact} />
      </DetailSection>
    </Page>
  );
}
