import React from "react";
import Select from "react-select";

export default function FieldHours({
  value,
  onChange,
  id,
  disabled = false,
  max = 24,
}) {
  const _options = [];
  let foundOption = null;
  for (let i = 0.0; i <= max; i = i + 0.25) {
    const option = {
      value: i,
      label: Number(i).toFixed(2).toString(),
    };
    _options.push(option);
    if (i === value) foundOption = option;
  }

  return (
    <Select
      className={"basic-single"}
      classNamePrefix="select"
      isLoading={false}
      isClearable={false}
      isSearchable={true}
      filterOption={(option, searchText) => {
        if (option.data.label.startsWith(searchText)) return true;
        return false;
      }}
      id={id}
      name={id}
      value={foundOption}
      options={_options}
      onChange={onChange}
      isMulti={false}
      isDisabled={disabled}
    />
  );
}
