import React from "react";

import NavSidebarItem from "../../common/navs/NavSidebarItem";
import CustomerNavSidebar from "./CustomerNavSidebar";

export default function CustomerIndexNavSidebar({ children }) {
  return (
    <CustomerNavSidebar>
      <NavSidebarItem to={`/customers/third-party-licenses`}>
        Third Party Licenses
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/jira-projects`}>
        Jira Projects
      </NavSidebarItem>
      <NavSidebarItem to={`/customers/hosting-agreements`}>
        Hosting Agreements
      </NavSidebarItem>
      {children}
    </CustomerNavSidebar>
  );
}
