import { createAsyncThunk } from "@reduxjs/toolkit";

import { createEntityActions } from "../../common/entity";
import { testStateLoading } from "../../common/functions/state";
import { api } from "./api";
import { entity } from "./entity";

const entityActions = createEntityActions({ entity, api });

const getRate = createAsyncThunk(
  "users/getRate",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().users.users, requestId)) return;

    const { responseData } = await api.getRate(args);
    return responseData;
  },
);

const createServerLogin = createAsyncThunk(
  "users/createServerLogin",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().users.users, requestId)) return;

    return await api.createServerLogin(args);
  },
);
const deleteServerLogin = createAsyncThunk(
  "users/deleteServerLogin",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().users.users, requestId)) return;

    return await api.deleteServerLogin(args);
  },
);
const updateServerLoginKeys = createAsyncThunk(
  "users/updateServerLoginKeys",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().users.users, requestId)) return;

    return await api.updateServerLoginKeys(args);
  },
);
const updateServerLoginPassword = createAsyncThunk(
  "users/updateServerLoginPassword",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().users.users, requestId)) return;

    return await api.updateServerLoginPassword(args);
  },
);

export const actions = {
  ...entityActions,
  getRate,
  createServerLogin,
  deleteServerLogin,
  updateServerLoginKeys,
  updateServerLoginPassword,
};
