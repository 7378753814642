import { useNotificationGroup, useNotificationGroups } from "./hooks";
import NotificationGroupEditor from "./NotificationGroupEditor";
import NotificationGroupNew from "./NotificationGroupNew";
import NotificationGroupTable from "./NotificationGroupTable";
import notificationGroupState from "./state";

const NotificationGroup = {
  // API
  api: notificationGroupState.api,
  // State
  actions: notificationGroupState.actions,
  selectors: notificationGroupState.entity.selectors,
  // Hooks
  useOne: useNotificationGroup,
  useAll: useNotificationGroups,
  // Components
  New: NotificationGroupNew,
  Editor: NotificationGroupEditor,
  Table: NotificationGroupTable,
};

export default NotificationGroup;
