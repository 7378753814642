import { createEntityHooks } from "../common/entity";
import codeReviewState from "./state";

export const {
  useEntities: useCodeReviews,
  useEntity: useCodeReview,
  useEntitiesBySearch: useCodeReviewsSearch,
} = createEntityHooks({
  entity: codeReviewState.entity,
  actions: codeReviewState.actions,
});
