import moment from "moment";
import React, { useMemo } from "react";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";

import JobPage from "../../activity/jobs/JobPage";
import { HoursFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  filterLessThan,
  SelectColumnFilter,
} from "../../common/tables/TableFilters";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportDailyBreakdown() {
  return (
    <JobPage
      title="Daily Breakdown"
      functionName="DailyBreakdown"
      Options={ReportOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const { data, maxHours } = useMemo(() => {
    const maxHours = job?.output?.data.reduce((max, r) => {
      const total = r.nonbillHours + r.billHours;
      if (total > max) return total;
      return max;
    }, 0);
    return {
      data: job?.output?.data || [],
      maxHours: maxHours,
    };
  }, [job]);
  const columns = useMemo(
    () => [
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        aggregate: "uniqueCount",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "week start date",
        Header: "Week Start",
        accessor: "weekStartDate",
        disableFilters: true,
        aggregate: "uniqueCount",
        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "day",
        Header: "Day",
        accessor: ({ date }) => moment(_date.fromStamp(date)).format("dddd"),
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "nonbillHours (Hours)",
        Header: "Non-Billable",
        accessor: "nonbillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "billHours",
        Header: "Billable (Hours)",
        accessor: "billHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "billableDollars",
        Header: "Billable (Dollars)",
        accessor: "billableDollars",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
      },
      {
        id: "total hours",
        Header: "Hours",
        dataType: "number",
        disableFilters: true,
        format: { number: { decimals: 2 } },
        accessor: (r) => {
          return r.nonbillHours + r.billHours;
        },
        aggregate: "sum",
        Aggregated: ({ row }) => {
          let total = 0;
          row.leafRows.forEach((leaf) => {
            total =
              total + leaf.original.nonbillHours + leaf.original.billHours;
          });
          return <HoursFormatter value={total} suffix={""} />;
        },
      },
      {
        id: "hours",
        Header: "Hour Breakdown",
        filter: filterLessThan,
        Filter: SliderColumnFilter,
        accessor: (r) => {
          return r.nonbillHours + r.billHours;
        },
        Aggregated: () => {
          return <></>;
        },
        Cell: ({ row: { original: r }, value }) => {
          const hoursInADay = 8.0;
          return (
            <Row>
              <Col xs="auto">
                <ProgressBar
                  style={{ width: "600px", height: "1.25rem" }}
                  className="rounded-0"
                >
                  <ProgressBar
                    variant="success"
                    now={r.billHours}
                    key={1}
                    max={maxHours}
                    label={r.billHours.toFixed(2)}
                    title="Billable Hours"
                  />
                  <ProgressBar
                    variant="primary"
                    now={r.nonbillHours}
                    key={2}
                    max={maxHours}
                    label={r.nonbillHours.toFixed(2)}
                    title="Non-Bill Hours"
                  />
                </ProgressBar>
                <ProgressBar
                  style={{ width: "600px", height: "0.15rem" }}
                  className="rounded-0"
                >
                  <ProgressBar
                    variant="warning"
                    now={hoursInADay}
                    key={1}
                    max={maxHours}
                  />
                </ProgressBar>
              </Col>
              <Col xs="auto" className="text-right">
                <small>{value.toFixed(2)}</small>
              </Col>
            </Row>
          );
        },
      },
    ],
    [maxHours],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          layout={["user", "date", "day", "hours", "billableDollars"]}
          getRowProps={({ original: { date } = {} }) =>
            moment(_date.fromStamp(date)).week() % 2 === 0
              ? { className: "table-primary" }
              : {}
          }
        />
      </Col>
    </Row>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.floor(Math.min(row.values[id], min));
      max = Math.ceil(Math.max(row.values[id], max));
    });
    return [min || 0, max || 0];
  }, [id, preFilteredRows]);

  return (
    <>
      <Form.Text muted>
        &#8804;{parseFloat(filterValue || max).toFixed(2)}
      </Form.Text>
      <Form.Control
        className="my-0 py-0"
        type="range"
        min={min}
        max={max}
        step="0.25"
        value={filterValue || max}
        onChange={(e) => {
          setFilter(parseFloat(e.target.value));
        }}
      />
    </>
  );
}
