import React from "react";
import { Col, Row } from "react-bootstrap";

import JobPage from "../../activity/jobs/JobPage";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportOutOfOffice() {
  return (
    <JobPage
      title="Out of Office"
      functionName="OutOfOffice"
      Options={ReportOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  return (
    <Row>
      <Col>
        <Time.Table
          times={job?.output?.data}
          layout={["user", "date", "cr", "billingNote", "totalHours"]}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
