import { entityTableFilter } from "../../common/entity";
import { useEstimateStatuses } from "./hooks";

const EstimateStatusTableFilter = entityTableFilter({
  name: "estimateStatus",
  useEntities: useEstimateStatuses,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default EstimateStatusTableFilter;
