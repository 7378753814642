import { isUndefined } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";

import useUserActivityOptions from "../activity/userActivity/useUserActivityOptions";
import FieldSelect, { OptionExtended } from "../common/fields/FieldSelect";
import useReinitState from "../common/hooks/useReinitState";
import Customer from "../customers";
import ProjectSearch from "./ProjectSearch";
import useFindOption from "./useFindOption";
import useProject from "./useProject";

const selectComponents = { Option: OptionExtended };

function ProjectSelect({
  id,
  label,
  onChange,
  getCr,
  placeholder = getCr ? "Project/CR..." : "Project...",
  errors: initialErrors,
  value: initialValue,
  isLoading,
  isDisabled,
  canFetch,
  refreshActivityButton = false,
  canAllowTransferred = false,
  ...props
}) {
  const [input, setInput] = useState("");
  const [errors, setErrors] = useReinitState(initialErrors);

  const { getProject, loading: loadingProject } = useProject.getOne();

  const {
    handleValue: handleProject,
    value,
    setValue,
  } = useValue({
    get: getProject,
    errors: initialErrors,
    value: initialValue,
    typeOf: "project",
    setErrors,
    fetch: canFetch,
    canAllowTransferred,
  });

  const [showSearch, setShowSearch] = useState(false);

  let options = [];

  useFindOption({ input, options, includeCr: !!getCr });
  const filterActivity = useCallback(
    (activity) => !!getCr || activity.source !== "CR",
    [getCr],
  );
  const { loading: loadingActivity, getMany: getActivities } =
    useUserActivityOptions({
      options,
      filter: filterActivity,
    });

  const _loading = loadingProject || isLoading || loadingActivity || false;

  const handleChange = useCallback(
    async ({ typeOf, id } = {}) => {
      if (typeOf === "project")
        handleProject(id)
          .then((project) => onChange({ ...project }))
          .catch((e) => setErrors(e.message));
      else if (typeOf === "cr")
        getCr({ id })
          .then(({ data: cr }) =>
            handleProject(cr.projectId).then((project) =>
              onChange({ ...project }),
            ),
          )
          .catch((e) => setErrors(e.message));
      else if (id === undefined) {
        onChange(null);
        setValue(null);
      }
    },
    [getCr, handleProject, onChange, setErrors, setValue],
  );

  return (
    <Form.Row className="align-items-center">
      <Col>
        <Form.Row>
          {label ? <Form.Label htmlFor={id}>{label}</Form.Label> : null}
        </Form.Row>
        <Form.Row>
          <Col style={{ minWidth: "300px" }}>
            <FieldSelect
              components={selectComponents}
              placeholder={placeholder}
              isLoading={_loading}
              disabled={_loading}
              value={value}
              options={options}
              onChange={handleChange}
              onInputChange={(s) => setInput(s)}
              blurInputOnSelect
              errors={errors}
              isInvalid={errors}
              isDisabled={isDisabled}
              {...props}
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={() => setShowSearch(true)}
              variant="light"
              title="Search for Project"
              disabled={isDisabled}
            >
              <BiSearchAlt2 />
            </Button>

            <Modal
              show={showSearch}
              size="xl"
              onHide={() => setShowSearch(false)}
              className="modal2"
              backdropClassName="modal2-backdrop"
            >
              <Modal.Header closeButton>
                <Modal.Title>Project Search</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProjectSearch
                  onSelect={(id) => {
                    setShowSearch(false);
                    handleChange({ id, typeOf: "project" });
                  }}
                />
              </Modal.Body>
            </Modal>
          </Col>
        </Form.Row>
        <Form.Row className="justify-content-end mt-n3 py-0">
          <Col sm="auto">
            {refreshActivityButton ? (
              <Button
                variant="link"
                className="text-reset py-0 my-0"
                size="sm"
                onClick={() => {
                  getActivities();
                }}
              >
                Refresh Activity
              </Button>
            ) : null}
          </Col>
        </Form.Row>
      </Col>
    </Form.Row>
  );
}

function useValue({
  get,
  value: initialValue,
  setErrors,
  typeOf,
  canAllowTransferred,
  fetch: defaultFetch = true,
}) {
  const [value, setValue] = useState(null);
  const { getCustomer } = Customer.useOne();
  const [loading, setLoading] = useState(false);

  const handleValue = useCallback(
    (id, fetch = defaultFetch) => {
      setLoading(true);
      return get({ id: id, fetch })
        .then(async ({ data }) => {
          if (isUndefined(data)) {
            setValue({
              label: `${id}`,
              title: `${id}`,
              value: { id: id, typeOf },
            });
          } else {
            const customer =
              data.customers?.length > 1
                ? "Multiple"
                : await getCustomer({
                    id: data.customers[0].customerId || data.customers[0].id,
                    fetch: false,
                  }).then(({ data: customer }) => customer.name);
            setValue({
              label: `${data.id}: ${customer} ${data.synopsis}`,
              title: `${data.id}`,
              info: `${customer} ${data.synopsis}`,
              value: { id: data.id, typeOf },
            });
            if (!canAllowTransferred && data.transferredFolder !== "CURRENT") {
              throw new Error(`${id} is transferred to history`);
            }
            return data;
          }
        })
        .finally(() => setLoading(false));
    },
    [getCustomer, get, typeOf, defaultFetch, canAllowTransferred],
  );

  const shouldUseInitial = !!initialValue && initialValue !== value?.value?.id;
  useEffect(() => {
    if (shouldUseInitial)
      handleValue(initialValue).catch((e) => setErrors(e.message));
  }, [initialValue, handleValue, shouldUseInitial, setErrors]);

  return { handleValue, value, setValue, loading };
}

ProjectSelect.useValue = useValue;
export default ProjectSelect;
