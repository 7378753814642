import { entityTableFilter } from "../../common/entity";
import { useExpenseCategories } from "./hooks";

const ExpenseCategoryTableFilter = entityTableFilter({
  name: "expenseCategory",
  useEntities: useExpenseCategories,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default ExpenseCategoryTableFilter;
