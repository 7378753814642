import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";

import Auth from "../../auth";
import { Forms, FormsField, useFormContext } from "../../common/forms";
import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";

export default function ResetPassword() {
  const { loading, resetPassword } = Auth.useLogin();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const history = useHistory();
  const userId = useSearchParams("user");

  if (loading) return <Page title="Login Page" lead="Loading..." />;
  if (isLoggedIn) return <Redirect to="/home" />;
  return (
    <Page title="Reset Password">
      <Row>
        <Col>
          <Forms
            defaultValues={{
              userId,
              password: "",
            }}
            onSubmit={(values) => {
              resetPassword(values).then(() => {
                history.push("/login");
              });
            }}
          >
            <ResetPasswordForm />
          </Forms>
        </Col>
      </Row>
    </Page>
  );
}

function ResetPasswordForm() {
  const { submitForm } = useFormContext();

  return (
    <Row>
      <Col>
        <form onSubmit={submitForm}>
          <FormsField
            name="userId"
            label="User"
            autoComplete="username"
            placeholder="Enter User"
          />
          <Button variant="warning" type="submit">
            Reset Password
          </Button>
        </form>
      </Col>
    </Row>
  );
}
