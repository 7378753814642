import _ from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, ButtonGroup, Col, ListGroup, Row } from "react-bootstrap";
import { MdDelete, MdDragHandle } from "react-icons/md";

import useReinitState from "../hooks/useReinitState";
import TableColumnSelect from "./TableColumnSelect";

export default function TableLayoutEditor({
  columns: columnsInitial,
  layout = [],
  onChange,
  defaultLayout = [],
}) {
  const [columns, setColumns] = useReinitState(
    layout
      .map((c) => {
        return _.find(columnsInitial, { id: c });
      })
      .filter((c) => _.isObject(c)),
  );
  const item = columnCheckListItem(columns, onChange);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      columns,
      result.source.index,
      result.destination.index,
    );

    setColumns(items);
    onChange(items.map((i) => i.id));
  }

  return (
    <Row>
      <Col>
        <Row className="pb-2">
          <Col md={12} lg={5} className="border-right">
            <Row>
              <Col>
                <TableColumnSelect
                  label="Add column"
                  columns={columnsInitial.filter((c) => !layout.includes(c.id))}
                  onChange={(v) => {
                    return onChange(columns.map((c) => c.id).concat([v]));
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-items-end">
              <Col xs="auto">
                <ButtonGroup>
                  <Button
                    title="Clear columns"
                    variant="warning"
                    onClick={() => onChange([])}
                  >
                    Clear
                  </Button>
                  <Button
                    title="Reset columns to defaults"
                    variant="info"
                    onClick={() => onChange(defaultLayout)}
                  >
                    Defaults
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <ListGroup className="pt-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="column-toggle-list" renderClone={item}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columns.map((column, index) => (
                        <Draggable
                          key={column.id}
                          draggableId={column.id}
                          index={index}
                        >
                          {item}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ListGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const columnCheckListItem = (columns, onChange) =>
  function ColumnCheckList(provided, snapshot, rubric) {
    const column = columns[rubric.source.index];
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ListGroup.Item
          title="Drag and drop to reorder"
          className="mb-1 rounded"
        >
          <Row>
            <Col>
              <MdDragHandle /> {column.Header || column.id}
            </Col>
            <Col xs="auto">
              <Button
                title="Remove column"
                size="sm"
                variant="link"
                className="text-reset py-0"
                onClick={() =>
                  onChange(
                    columns.filter((c) => c.id !== column.id).map((c) => c.id),
                  )
                }
              >
                <MdDelete />
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      </div>
    );
  };
