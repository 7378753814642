import { isUndefined } from "lodash";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import CustomerSelect from "../CustomerSelect";
import thirdPartyLicenseSchema from "./thirdPartyLicenseSchema";
import { LICENSE_TYPES } from "./types";

export default function ThirdPartyLicenseEditor({
  values: { expirationDate, ...values } = {},
  onSubmit,
  isDisabled = false,
  isNew = false,
}) {
  return (
    <Forms
      defaultValues={{
        expirationDate: expirationDate
          ? _date.fromStamp(expirationDate).toISOString()
          : "",
        ...values,
      }}
      onSubmit={({ expirationDate, ...values }) =>
        onSubmit({
          expirationDate: expirationDate ? _date.stamp(expirationDate) : "",
          ...values,
        })
      }
      schema={{ schema: thirdPartyLicenseSchema }}
      isDisabled={isDisabled}
      showDevTool
    >
      <ThirdPartyLicenseForm isNew={isNew} />
    </Forms>
  );
}

function ThirdPartyLicenseForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto" style={{ width: "500px" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={CustomerSelect}
            isDisabled={!isNew}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <TypeSelect name="type" label="Type" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormsField.Date
            name="expirationDate"
            label="Expiration"
            includeNow={false}
            includeArrows={false}
          />
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}

function TypeSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value="" />
        {Object.values(LICENSE_TYPES).map((l, idx) => (
          <option key={`type-option${idx}`} value={l.id}>
            {l.description}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
