import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { Col, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useAppCanStateExpire from "../../app/useAppCanStateExpire";
import Cr from "../../crs";
import Time from "../../time";
import ContextMenu from "../popovers/ContextMenu";

export default function CrLinkWithContextMenu({
  id,
  onNewTime,
  label,
  readOnly,
  tooltipData: tooltipDataProp,
}) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const {
    cr,
    updateOne: updateCr,
    getCr,
  } = Cr.useOne({
    id: id,
    canGet: false,
    redirect: false,
  });

  const tooltipData = { ...tooltipDataProp, ...cr };

  const canShowTooltip = !!tooltipData?.customers || !!tooltipData?.synopsis;

  const setCanStateExpire = useAppCanStateExpire.set(
    `CrLink.ContextMenu.${id}`,
  );

  return (
    <>
      <OverlayTrigger
        placement="left"
        delay={{ show: 500, hide: 400 }}
        show={canShowTooltip ? undefined : false}
        overlay={
          <Tooltip id={`cr-{${id}}-tooltip`}>
            {tooltipData?.customers ? (
              <div className="border-bottom">
                <Row>
                  <Col>{tooltipData?.customers?.[0]?.name}</Col>
                </Row>
              </div>
            ) : null}
            <Row>
              <Col>{tooltipData?.synopsis}</Col>
            </Row>
          </Tooltip>
        }
      >
        <Link
          ref={ref}
          className="text-reset"
          to={{ pathname: `/crs/${id}` }}
          onContextMenu={(e) => {
            e.preventDefault();
            setTarget(e.target);
            setShow(true);
            setCanStateExpire(false);
          }}
        >
          {label || id}
        </Link>
      </OverlayTrigger>
      <CrContextMenu
        id={id}
        cr={cr}
        target={target}
        show={show}
        onHide={() => {
          setShow(false);
          setCanStateExpire(true);
        }}
        onNewTime={onNewTime}
        update={updateCr}
        get={getCr}
        readOnly={readOnly}
      />
    </>
  );
}

function CrContextMenu({
  id,
  cr,
  target,
  show,
  onHide,
  onNewTime = () => {},
  get,
  update,
  readOnly = false,
}) {
  const [showNewTime, setShowNewTime] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const date = useMemo(() => moment(), []);

  const setCanStateExpire = useAppCanStateExpire.set(`CrLink.NewTime.${id}`);

  const hideShowNewTime = () => {
    setShowNewTime(false);
    setCanStateExpire(true);
  };
  return (
    <>
      <ContextMenu
        title="CR Options"
        target={target}
        show={show}
        onHide={() => {
          onHide();
        }}
        placement="right"
      >
        <ListGroup>
          <ListGroup.Item
            action
            target="_blank"
            rel="noopener noreferrer"
            href={`/crs/${id}`}
          >
            Open in new tab
          </ListGroup.Item>
          <CopyToClipboard
            text={id}
            onCopy={() => toast.success("CR # copied")}
          >
            <ListGroup.Item action>
              <BiCopy /> Copy CR #
            </ListGroup.Item>
          </CopyToClipboard>
          {!readOnly && (
            <>
              <ListGroup.Item
                action
                onClick={() => {
                  setShowNewTime(true);
                  setCanStateExpire(false);
                }}
                disabled={readOnly ?? false}
              >
                Add time
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() =>
                  get().then(({ data: fetchedCr }) => {
                    if (cr?.owner && cr?.owner !== fetchedCr?.owner) {
                      toast.error(
                        <p
                          className="text-break"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          <strong>Try again</strong>
                          {`\nCurrent owner is outdated\n${cr?.owner} -> ${fetchedCr?.owner}`}
                        </p>,
                      );
                    } else update({ owner: user });
                  })
                }
                disabled={readOnly ?? false}
              >
                Assign to me
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </ContextMenu>
      <Time.NewModal
        show={showNewTime}
        onHide={hideShowNewTime}
        user={user}
        date={date}
        crId={id}
        onCancel={hideShowNewTime}
        onSave={() => {
          hideShowNewTime();
          onNewTime();
        }}
      />
    </>
  );
}
