import { useEffect, useState } from "react";
import { useHistory } from "react-router";

export default function useRedirect() {
  const [redirect, setRedirect] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
      setRedirect(null);
    }
  }, [redirect, history]);
  return [redirect, setRedirect];
}
