import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _date } from "../common/functions/dates";
import { timeActions, timeSelectors } from "./state";

export default function useTimes({
  filter: {
    crs,
    projectId,
    date,
    userId = "",
    startDate,
    endDate,
    timeType = "",
  } = {},
  blocked = false,
}) {
  const dispatch = useDispatch();
  const times = useSelector((state) => timeSelectors.selectAll(state));
  const loading = useSelector((state) => state.times.times.requests.loading);
  const lastUpdate = useSelector((state) => state.times.times.lastUpdate);

  const getTimes = useCallback(() => {
    dispatch(timeActions.clearTimes());
    const searchParams = { userId, timeType };
    const dateMoment = date ? moment(date) : undefined;
    const dateParm = dateMoment?.isValid() ? dateMoment.format("YYYYMMDD") : "";
    if (crs?.length) {
      crs.map((cr) =>
        dispatch(
          timeActions.getTimes({ date: dateParm, cr: cr, ...searchParams }),
        ),
      );
    } else if (projectId) {
      dispatch(
        timeActions.getTimes({
          date: dateParm,
          project: projectId,
          ...searchParams,
        }),
      );
    } else if (userId) {
      if (dateMoment?.isValid()) {
        dispatch(timeActions.getTimes({ date: dateParm, ...searchParams }));
      } else if (endDate) {
        dispatch(
          timeActions.getTimes({
            startDate: _date.stamp(startDate),
            endDate: _date.stamp(endDate),
            ...searchParams,
          }),
        );
      }
    }
  }, [dispatch, userId, timeType, date, crs, projectId, endDate, startDate]);

  useEffect(() => {
    if (blocked) return;
    getTimes();
  }, [getTimes, blocked]);

  return { times, loading, lastUpdate, getTimes };
}
