import { startCase } from "lodash";

import { createEntityHooks } from "../../../common/entity";
import favoriteState from "./state";

export const { useEntities: useFavorites, useEntity: useFavorite } =
  createEntityHooks({
    entity: favoriteState.entity,
    actions: favoriteState.actions,
    getToastMessage: ({ name, entity: { id, crFilterId }, type }) =>
      `${startCase(name)} ${id || crFilterId} ${
        type === "Created" ? "Added" : type === "Deleted" ? "Removed" : ""
      }`,
  });
