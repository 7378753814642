import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";

export default function UserGroupingTable({ userGroupings, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "id",
        disableFilters: true,

        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/users/user-groupings/${value}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="User Groupings"
      columns={columns}
      data={userGroupings}
      {...props}
    />
  );
}
