import React from "react";
import { Badge } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";

import { formatPhone } from "../functions/phone";

export default function Tel({ phone, ext, children, copy = true }) {
  if (!phone) return null;
  const _phone = formatPhone(phone, ext) || "";
  const _phoneNums = _phone.replace("+", "");
  return (
    <>
      <a
        href={`tel:${encodeURIComponent(_phoneNums)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children ? children : _phone}
      </a>{" "}
      {copy ? (
        <CopyToClipboard
          text={_phoneNums}
          onCopy={() => toast.success("Phone number copied")}
        >
          <Badge as="a" variant="light" title="Click to copy">
            <BiCopy />
          </Badge>
        </CopyToClipboard>
      ) : null}
    </>
  );
}
