import moment from "moment";
import React, { useMemo } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import FieldInput from "../../common/fields/FieldInput";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  DateRangeColumnFilter,
  filterDateRange,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Customer from "../../customers";
import Project from "../../projects";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportPrCrTextSearch() {
  return (
    <JobPage
      title="Project/CR Text Search"
      functionName="PrCrTextSearch"
      Options={PrCrTextSearchOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.results || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Project/CR",
        Header: "Project/CR",
        accessor: "reference",
        Filter: TextColumnFilter,
        filter: "equals",
        Cell: ({ value, row }) => (
          <>
            {row?.original?.source === "PR" ? (
              <Link
                target="_blank"
                className="text-reset"
                to={{ pathname: `/projects/${value}` }}
              >
                {row?.original?.source + " " + value}
              </Link>
            ) : (
              <Link
                target="_blank"
                className="text-reset"
                to={{ pathname: `/crs/${value}` }}
              >
                {row?.original?.source + " " + value}
              </Link>
            )}
          </>
        ),
      },
      {
        id: "Owner",
        Header: "Owner",
        accessor: "ownerId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Group",
        id: "Group",
        accessor: "groupId",
        Filter: User.Group.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Project Status",
        id: "Project Status",
        accessor: "projectStatus",
        Filter: Project.Status.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Project.Status.Description id={value} />,
      },
      {
        Header: "Type",
        id: "Type",
        accessor: "typeId",
        Filter: Project.Type.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
      {
        Header: "Create Date",
        id: "Create Date",
        accessor: "createDateTime",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value?.substring(0, 8)))}</span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Modify Date",
        id: "Modify Date",
        accessor: "lastUpdateDateTime",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
        aggregate: "uniqueCount",
      },
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerName",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => (
          <div style={{ minWidth: "300px", maxWidth: "500px" }}>{value}</div>
        ),
      },
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => (
          <div style={{ minWidth: "300px", maxWidth: "500px" }}>{value}</div>
        ),
      },
      {
        id: "Search Found In",
        Header: "Search Found In",
        accessor: "foundIn",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          {job?.response === "FAIL" ? (
            <Alert variant="danger">{job?.responseMessage}</Alert>
          ) : (
            <Table
              columns={columns}
              data={data}
              isLoading={isLoading}
              moveFooterToTop
              hasCount={true}
              layout={[
                "Project/CR",
                "Customer",
                "Synopsis",
                "Description",
                "Owner",
                "Customer",
                "Group",
                "Type",
                "Project Status",
                "Create Date",
                "Modify Date",
                "Search Found In",
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

function PrCrTextSearchOptions({
  submitJob,
  job,
  startDate = moment().startOf("month").subtract(4, "years"),
  endDate = "",
}) {
  const { options } = job;
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      customers: [],
      types: [],
      groups: [],
      createStartDate: startDate,
      createEndDate: endDate,
      lastStartDate: "",
      lastEndDate: "",
      searchInTime: true,
      searchText1: "",
      searchText2: "",
      searchText3: "",
      ...options,
    },
  });

  const createEndDate = watch("createEndDate");
  const createStartDate = watch("createStartDate");

  const lastEndDate = watch("lastEndDate");
  const lastStartDate = watch("lastStartDate");

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let searches = [];
        let counter = 0;

        let noWhitespace = options.searchText1.replace(/\s/g, "");
        if (noWhitespace.length < 3) {
          setError("searchText1", {
            type: "custom",
            message: "Please enter at least 3 non space characters",
          });
          return;
        }

        noWhitespace = options.searchText2.replace(/\s/g, "");
        if (noWhitespace.length > 0 && noWhitespace.length < 3) {
          setError("searchText2", {
            type: "custom",
            message: "Please enter at least 3 non space characters",
          });
          return;
        }
        noWhitespace = options.searchText3.replace(/\s/g, "");
        if (noWhitespace.length > 0 && noWhitespace.length < 3) {
          setError("searchText3", {
            type: "custom",
            message: "Please enter at least 3 non space characters",
          });
          return;
        }

        if (options.searchText1.trim() !== "") {
          searches.push({ val: options.searchText1, type: "v" });
          counter++;
        }
        if (options.searchText2.trim() !== "") {
          if (counter > 0) searches.push({ val: "AND", type: "o" });
          searches.push({ val: options.searchText2, type: "v" });
          counter++;
        }
        if (options.searchText3.trim() !== "") {
          if (counter > 0) searches.push({ val: "AND", type: "o" });
          searches.push({ val: options.searchText3, type: "v" });
          counter++;
        }

        let desc = "";
        for (let i = 0; i < searches.length; i++) {
          desc = desc + searches[i].val + " ";
        }

        submitJob({
          ...options,
          createStartDate: _date.stamp(options.createStartDate),
          createEndDate: _date.stamp(options.createEndDate),
          lastStartDate: _date.stamp(options.lastStartDate),
          lastEndDate: _date.stamp(options.lastEndDate),
          searches: searches,
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="createStartDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="PR/CR Created Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={createEndDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="createEndDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={createStartDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="lastStartDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="PR/CR Last Modified Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={lastEndDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="lastEndDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={lastStartDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={6} md={4}>
          <Controller
            name="owners"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <User.Select
                label="Owners"
                id="owners"
                isMulti
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                inclue
                isClearable
                pseudo={true}
              />
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Controller
            name="customers"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customers"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isMulti
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={8} md={5}>
          <Controller
            name="types"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Project.Type.Select
                label="Types"
                id="types"
                isMulti
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
              />
            )}
          />
        </Col>
        <Col xs={8} md={5}>
          <Controller
            name="groups"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <User.Group.Select
                label="Groups"
                id="groups"
                isMulti
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="searchInTime"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="searchInTime"
                label="Search in Time Entries?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={6} md={3}>
          <Controller
            name="searchText1"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldInput
                id="searchText1"
                label="Search for this"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.searchText1}
                errors={errors.searchText1?.message}
              />
            )}
          />
        </Col>
        <Col xs={6} md={3}>
          <Controller
            name="searchText2"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldInput
                id="searchText2"
                label="And must also have this"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.searchText2}
                errors={errors.searchText2?.message}
              />
            )}
          />
        </Col>
        <Col xs={6} md={3}>
          <Controller
            name="searchText3"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldInput
                id="searchText3"
                label="And must also have this"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.searchText3}
                errors={errors.searchText3?.message}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
