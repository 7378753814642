const REPOSITORIES = {
  P: {
    id: "P",
    description: "Produce (DBC)",
  },
  I: {
    id: "I",
    description: "Images (Latex)",
  },
  V: {
    id: "V",
    description: "Vocollect",
  },
  A: {
    id: "A",
    description: "Ppro-apps",
  },
};

export function useRepository({ id }) {
  return REPOSITORIES[id];
}

export function useRepositories() {
  const repositories = Object.entries(REPOSITORIES).map(([, value]) => value);
  return { repositories };
}
