import moment from "moment";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import Repository from "../../codeReviews/repositories";
import {
  Forms,
  FormsField,
  useFormContext,
  useWatch,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import useRedirect from "../../common/hooks/useRedirect";
import User from "../../users";

export default function CodeReviewOptions({
  startDate: sd = moment().startOf("month").toISOString(),
  endDate: ed = moment().endOf("month").toISOString(),
  group = "",
  fileOwner = "",
  author = "",
  authorTeam = "",
  allCommitsFromCr = true,
  repository = "",
  onSubmit = () => {},
}) {
  const [, setRedirect] = useRedirect();
  const loggedInUser = useSelector((state) => state.auth.user);
  return (
    <Forms
      defaultValues={{
        fileOwner: fileOwner,
        author: author,
        authorTeam: authorTeam,
        groupId: group,
        startDate: sd,
        endDate: ed,
        allCommitsFromCr: allCommitsFromCr,
        repository: repository,
      }}
      context={{ loggedInUser }}
      onSubmit={(options) => {
        onSubmit();
        setRedirect(
          "/code-reviews/show?startDate=" +
            _date.stamp(options.startDate) +
            "&endDate=" +
            _date.stamp(options.endDate) +
            "&allCommitsFromCr=" +
            options.allCommitsFromCr +
            "&group=" +
            options.groupId +
            "&fileOwner=" +
            options.fileOwner +
            "&author=" +
            options.author +
            "&authorTeam=" +
            options.authorTeam +
            "&repository=" +
            options.repository,
        );
      }}
      showDevTool
    >
      <CodeReviewOptionsForm />
    </Forms>
  );
}

function CodeReviewOptionsForm() {
  const { submitForm } = useFormContext();

  const endDate = useWatch({ name: "endDate" });
  const startDate = useWatch({ name: "startDate" });
  return (
    <Form onSubmit={submitForm}>
      <Form.Row>
        <Col xs="auto">
          <FormsField.Date
            name="startDate"
            label="Start Date"
            includeNow={false}
            maxDate={endDate}
          />
        </Col>
        <Col>
          <FormsField.Date
            name="endDate"
            label="End Date"
            includeNow={false}
            minDate={startDate}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="repository"
            label="Repository"
            as={Repository.Select}
            isClearable
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="groupId"
            label="Group"
            as={User.Group.Select}
            isClearable
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="fileOwner"
            label="File Owner"
            as={User.Select}
            isClearable
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="author"
            label="Author"
            as={User.Select}
            isClearable
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="authorTeam"
            label="Author's team"
            as={User.Team.Select}
            isClearable
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Check
            name="allCommitsFromCr"
            label="Include all commits from a Cr?"
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
