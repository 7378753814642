import { useOrganization, useOrganizations } from "./hooks";
import OrganizationDescription from "./OrganizationDescription";
import OrganizationSelect from "./OrganizationSelect";
import OrganizationTableFilter from "./OrganizationTableFilter";
import state from "./state";

const Organization = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useOrganization,
  useAll: useOrganizations,
  // Components
  Select: OrganizationSelect,
  Description: OrganizationDescription,
  TableFilter: OrganizationTableFilter,
};
export default Organization;
