import { unwrapResult } from "@reduxjs/toolkit";

import toastPrompt from "../common/toasts/toastPrompt";
import crState from "./state";

export default function updateCrPrompt(
  { id, message, changes, dispatch, test = () => true, getProjectAction },
  toastOptions,
) {
  dispatch(crState.actions.getOne({ id }))
    .then(unwrapResult)
    .then(({ data: cr }) => {
      if (test(cr))
        toastPrompt(
          {
            message,
            confirm: "Yes",
            cancel: "No",
            onConfirm: () => {
              dispatch(
                crState.actions.updateOne({
                  id,
                  data: changes,
                  shouldPromptToUpdateProject: false,
                }),
              ).then(() => {
                if (getProjectAction) dispatch(getProjectAction());
              });
            },
          },
          toastOptions,
        );
    });
}
