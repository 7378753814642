import BranchDescription from "./BranchDescription";
import BranchEditor from "./BranchEditor";
import BranchSelect from "./BranchSelect";
import BranchTable from "./BranchTable";
import BranchTableFilter from "./BranchTableFilter";
import { useBranch, useBranches } from "./hooks";
import branchState from "./state";

const Branch = {
  // Backend API
  api: branchState.api,
  // State
  actions: branchState.actions,
  selectors: branchState.entity.selectors,
  // Hooks
  useAll: useBranches,
  useOne: useBranch,
  // Components
  Select: BranchSelect,
  TableFilter: BranchTableFilter,
  Description: BranchDescription,
  Table: BranchTable,
  Editor: BranchEditor,
};

export default Branch;
