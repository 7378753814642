import React, { useMemo, useState } from "react";
import { Badge, Button, ButtonGroup, Modal } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { IoMdAttach } from "react-icons/io";

import AttachmentsTable from "../common/details/AttachmentsTable";
import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Customer from "../customers";
import User from "../users";
import ExpenseCategory from "./categories";
import ExpenseEditorModal from "./ExpenseEditorModal";
import { useExpense } from "./hooks";
import ExpenseTrip from "./trips";
import ExpenseType from "./types";

export default function ExpenseTable({
  expenses,
  isDisabled,
  onRefresh = () => {},
  ...props
}) {
  const [editId, setEditId] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);

  const columns = useMemo(
    () => [
      {
        id: "edit",
        Header: "",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value, row: { original: expense } }) => {
          const disabled = value === "C" || isDisabled;
          return (
            <ButtonGroup style={{ minWidth: "6rem" }}>
              <Button
                variant="light"
                size="sm"
                onClick={() => setEditId(expense?.id)}
                disabled={disabled}
              >
                <BiEdit />
              </Button>
              <Button
                variant="light"
                size="sm"
                onClick={() => setAttachmentId(expense?.id)}
              >
                <IoMdAttach />{" "}
                <Badge variant="light">{expense?.attachments?.length}</Badge>
              </Button>
            </ButtonGroup>
          );
        },
      },
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => _date.displayLong(_date.fromStamp(value)),
      },
      {
        id: "id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "source",
        Header: "Source",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ row: { original: expense } }) => {
          if (expense?.expenseTripId)
            return <ExpenseTrip.Description id={expense?.expenseTripId} />;
          else if (expense?.customerId)
            return <Customer.Name id={expense?.customerId} />;
          else return <span>Other</span>;
        },
      },
      {
        id: "expenseType",
        Header: "Type",
        accessor: "expenseTypeId",
        Filter: ExpenseType.TableFilter,
        filter: "equals",
        Cell: ExpenseType.Description,
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: "customerId",
        Cell: Customer.Name,
      },
      {
        id: "expenseCategory",
        Header: "Category",
        accessor: "expenseCategoryId",
        Filter: ExpenseCategory.TableFilter,
        filter: "equals",
        Cell: ExpenseCategory.Description,
      },
      {
        id: "overrideDescription",
        Header: "Description",
        accessor: "overrideDescription",
      },
      {
        id: "note",
        Header: "Notes",
        accessor: "note",
        Cell: ({ value }) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
              maxWidth: "400px",
              whiteSpace: "pre-line",
            }}
          >
            {value}
          </p>
        ),
      },
      {
        id: "paymentType",
        Header: "Payment Type",
        accessor: (e) =>
          e.paymentType === "E"
            ? "Employee Paid"
            : e.paymentType === "C"
            ? "Company Credit Card"
            : "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "overrideBillableFlag",
        Header: "Billable",
        accessor: "overrideBillableFlag",
      },
      {
        id: "mileage",
        Header: "Mileage",
        accessor: "miles",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: "amount",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.amount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "billCustomerAmount",
        Header: "Bill Customer Amount",
        accessor: "billCustomerAmount",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.billCustomerAmount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "reimburseAmount",
        Header: "Reimburse Amount",
        accessor: "reimburseAmount",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.reimburseAmount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
    ],
    [isDisabled],
  );
  return (
    <>
      <Table
        hasCount
        label="Expenses"
        columns={columns}
        data={expenses}
        onRefresh={onRefresh}
        {...props}
      />
      <ExpenseEditModal
        id={editId}
        onRefresh={onRefresh}
        onHide={() => setEditId(null)}
      />
      <ExpenseAttachmentsModal
        id={attachmentId}
        onHide={() => setAttachmentId(null)}
        isDisabled={isDisabled}
      />
    </>
  );
}

function ExpenseEditModal({ id, onHide, isDisabled, onRefresh }) {
  const {
    expense,
    updateOne: updateExpense,
    deleteOne: deleteExpense,
  } = useExpense({
    id,
  });
  return (
    <ExpenseEditorModal
      title="Edit Expense"
      expense={expense}
      show={!!expense}
      onHide={onHide}
      onSubmit={(expense) => {
        updateExpense(expense).then(() => {
          onHide();
          onRefresh();
        });
      }}
      onDelete={() => {
        deleteExpense().then(() => {
          onHide();
          onRefresh();
        });
      }}
      isDisabled={isDisabled}
    />
  );
}

function ExpenseAttachmentsModal({ id, onHide, isDisabled }) {
  const { expense, createAttachment, getExpense } = useExpense({
    id,
  });
  return (
    <Modal size="xl" show={!!expense} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Expense Attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AttachmentsTable
          attachments={expense?.attachments}
          create={createAttachment}
          source={{ source: "EX", sourceId: expense?.id }}
          onDelete={getExpense}
          isDisabled={expense?.status === "C" || isDisabled}
        />
      </Modal.Body>
    </Modal>
  );
}
