import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import userState from "./state";

export function useUserRate({ id, crId, projectId, canGet = false }) {
  const loading = useSelector((state) => state.users.users.requests.loading);
  const userRate = useSelector((state) => state.users.users.rate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isString(id) && canGet)
      dispatch(userState.actions.getRate({ id: id, crId, projectId }));
  }, [id, crId, projectId, canGet, dispatch]);

  return {
    loading,
    userRate,
  };
}

export default useUserRate;
