import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import tinykeys from "tinykeys";

// Don't rename modalTitle to Title..
// there is something weird going on with that prop
export default function GetReasonModal({
  modalTitle,
  onCancel,
  onOk,
  defaultReason = "",
  show,
  okText = "Ok",
  cancelText = "Cancel",
  modalBodyHeader = "",
}) {
  const [reason, setReason] = useState(defaultReason);
  const innerRef = useRef();

  useEffect(() => {
    innerRef.current && innerRef.current.focus();
  });

  const shortcutOk = () => {
    if (reason.trim() !== "" && show) onOk(reason);
  };

  useEffect(() => {
    let unsubscribe = tinykeys(window, {
      "$mod+s": shortcutOk,
      "$mod+Enter": shortcutOk,
    });
    return () => {
      unsubscribe();
    };
  });

  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBodyHeader}
        <TextareaAutosize
          type="text"
          as="textarea"
          className="form-control"
          minRows={2}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
          maxLength={256}
          autoFocus
          ref={innerRef}
          onFocus={(event) => event.target.select()}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onOk(reason)}
          variant="primary"
          disabled={reason.trim() === ""}
        >
          {okText}
        </Button>
        <Button onClick={onCancel} variant="secondary">
          {cancelText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
