import createEntityReducer from "../../common/entity/createEntityReducer";
import { buildAsyncThunkReducer } from "../../common/functions/state";
import { actions } from "./actions";
import { entity } from "./entity";

export const reducer = createEntityReducer({
  entity: entity,
  actions: actions,
  persist: true,
  initialState: { filter: {}, startAfter: "" },
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(
      builder,
      actions.createComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateComment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.createAttachment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateWatch,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );

    builder.addCase(actions.setFilter, (state, action) => {
      state.filter = action.payload;
    });
  },
});
