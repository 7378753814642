import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "tableLayout",
  type: "crs/tableLayouts",
  getState: (state) => state.crs.tableLayouts,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const tableLayoutState = {
  api,
  entity,
  actions,
  reducer,
};

export default tableLayoutState;
