import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Row, Table as BsTable } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

import Project from "../../projects";
import Time from "../../time";
import ProjectPage from "./ProjectPage";
import ProjectShowNavSidebar from "./ProjectShowNavSidebar";

export default function ProjectShowTime() {
  const id = parseInt(useParams().id);
  const [showEstimate, setShowEstimate] = useState(false);

  const { project, loading, updateOne, getProject, lastUpdate } =
    Project.useOne({
      id,
      redirect: false,
      logActivity: true,
    });
  const { times, loading: loadingTimes } = Time.useAll({
    filter: { projectId: id },
    blocked: loading,
  });

  const transferred =
    project?.transferredFolder && project?.transferredFolder !== "CURRENT";

  const estimate = {
    minCost: project?.estimateMinCost,
    maxCost: project?.estimateMaxCost,
    minHours: project?.estimateMinHours,
    maxHours: project?.estimateMaxHours,
    rate: project?.estimateRate,
  };
  const actual = {
    invoicedBlHours: project?.actualInvoicedHours,
    invoicedNbHours: project?.actualInvoicedNbHours,
    billableHours: project?.actualBillableHours,
    nonBillHours: project?.actualNonBillableHours,
    totalHours: _.sum([
      project?.actualInvoicedHours,
      project?.actualInvoicedNbHours,
      project?.actualBillableHours,
      project?.actualNonBillableHours,
    ]),
    invoicedBlCost: project?.actualInvoicedCost,
    invoicedNbCost: project?.actualInvoicedNbCost,
    billableCost: project?.actualBillableCost,
    nonBillCost: project?.actualNonBillableCost,
    totalCost: _.sum([
      project?.actualInvoicedCost,
      project?.actualBillableCost,
      project?.actualNonBillableCost,
    ]),
  };
  return (
    <ProjectPage
      project={project}
      heading={`Project ${id} Time`}
      navSidebar={<ProjectShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <Row className="justify-content-end">
        <Col xs="auto">
          <span className="text-muted float-left">
            <Button
              variant="link"
              className="text-muted"
              size="sm"
              onClick={() => getProject()}
              disabled={loading}
            >
              {loading
                ? "Loading..."
                : "Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
            </Button>
          </span>
        </Col>
      </Row>
      <Row>
        <Col lg={12} xl={8}>
          <Project.Alerts project={project} />
          <Time.EstimateTable
            estimate={estimate}
            actual={actual}
            estimateSubtitle={
              <Project.EstimateStatus.Description
                id={project?.estimateStatusId}
              />
            }
            isLoading={loading}
            onEdit={transferred ? undefined : () => setShowEstimate(true)}
          />
          <Project.EstimateEditor
            project={project}
            show={showEstimate}
            onHide={() => setShowEstimate(false)}
            update={updateOne}
            onFormsUpdate={() => getProject()}
          />
        </Col>
        <Col>
          <BsTable size="sm">
            <thead>
              <tr>
                <th colSpan="3">
                  <h4 className="text-center">CR Estimates</h4>
                </th>
              </tr>
              <tr>
                <th></th>
                <th className="border-left text-right">Hours</th>
                <th className="border-left text-right">Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text-right">Hours</td>
                <td className="border-left text-right">
                  <NumberFormat
                    value={project?.crEstimateMinHours}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </td>
                <td className="border-left text-right">
                  <NumberFormat
                    value={project?.crEstimateMaxHours}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </td>
              </tr>
              <tr>
                <td className="text-right">Cost</td>
                <td className="border-left text-right">
                  <NumberFormat
                    value={project?.crEstimateMinCost}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                  />
                </td>
                <td className="border-left text-right">
                  <NumberFormat
                    value={project?.crEstimateMaxCost}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                  />
                </td>
              </tr>
            </tbody>
          </BsTable>
        </Col>
      </Row>
      <Row>
        <Col>
          <Time.Table
            times={times}
            layout={[
              "user",
              "date",
              "cr",
              "billingNote",
              "isBillable",
              "totalHours",
              "rate",
              "amount",
              "invoiceNumber",
            ]}
            initialSort={{ id: "date", desc: true }}
            isLoading={loadingTimes}
          />
        </Col>
      </Row>
    </ProjectPage>
  );
}
