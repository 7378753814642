import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import JobPage from "../../activity/jobs/JobPage";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function QaReviewersReport() {
  return (
    <JobPage
      title="QA Reviewers"
      functionName="QaReviewers"
      Options={ReportOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.results || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Reviewer",
        Header: "Reviewer",
        accessor: "reviewer",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "QA Passed",
        Header: "QA Passed",
        accessor: "qaPassed",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaPassed) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "QA Failed",
        Header: "QA Failed",
        accessor: "qaFailed",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaFailed) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "QA Warned",
        Header: "QA Warned",
        accessor: "qaWarned",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaWarned) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "QA Code Reviewed",
        Header: "QA Code Reviewed",
        accessor: "qaCodeReviewed",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaCodeReviewed) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "QA Tests Written",
        Header: "QA Tests Written",
        accessor: "qaTestsWritten",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaTestsWritten) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "QA Front End Tested",
        Header: "QA Front End Tested",
        accessor: "qaFrontEndTested",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.qaFrontEndTested) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },

      {
        id: "File Passed",
        Header: "File Passed",
        accessor: "filePassed",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.filePassed) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "File Failed",
        Header: "File Failed",
        accessor: "fileFailed",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.fileFailed) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
      {
        id: "File Warned",
        Header: "File Warned",
        accessor: "fileWarned",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.fileWarned) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <>{total}</>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
          />
        </Col>
      </Row>
    </>
  );
}
