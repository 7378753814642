import shark from "../../../apis/shark";

export function getJobAPI({ id }) {
  const payload = {
    command: "getJob",
    id: id,
  };
  return shark.post(payload);
}

export function getJobsAPI() {
  const payload = {
    command: "getJobs",
  };
  return shark.post(payload);
}

export function submitJobAPI({ functionName, description, options = {} }) {
  const payload = {
    command: "submitJob",
    functionName: functionName,
    options: options,
    description: description,
  };
  return shark.post(payload);
}

export function resubmitJobAPI({ id }) {
  const payload = {
    command: "resubmitJob",
    id: id,
  };
  return shark.post(payload);
}
