import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import CrEdit from "./CrEdit";
import CrIndex from "./CrIndex";
import CrNew from "./CrNew";
import CrShow from "./CrShow";
import CrShowLog from "./CrShowLog";
import CrShowProject from "./CrShowProject";
import CrShowQa from "./CrShowQa";
import CrShowRevisions from "./CrShowRevisions";
import CrShowTime from "./CrShowTime";

export default function CrsRouting() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <CrNew />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <CrEdit />
      </Route>
      <Route path={`${path}/:id/project`}>
        <CrShowProject />
      </Route>
      <Route path={`${path}/:id/time`}>
        <CrShowTime />
      </Route>
      <Route path={`${path}/:id/revisions`}>
        <CrShowRevisions />
      </Route>
      <Route path={`${path}/:id/log`}>
        <CrShowLog />
      </Route>
      <Route path={`${path}/:id/qa`}>
        <CrShowQa />
      </Route>
      <Route path={`${path}/:id`}>
        <CrShow />
      </Route>
      <Route exact path={`${path}`}>
        <CrIndex />
      </Route>
    </Switch>
  );
}
