import ContractProductDefaultCrEditor from "./ContractProductDefaultCrEditor";
import ContractProductDefaultCrLink from "./ContractProductDefaultCrLink";
import Table from "./ContractProductDefaultCrTable";
import {
  useContractProductDefaultCr,
  useContractProductDefaultCrs,
} from "./hooks";
import contractProductDefaultCrState from "./state";

const ContractProductDefaultCr = {
  // API
  api: contractProductDefaultCrState.api,
  // State
  actions: contractProductDefaultCrState.actions,
  selectors: contractProductDefaultCrState.entity.selectors,
  // Hooks
  useOne: useContractProductDefaultCr,
  useAll: useContractProductDefaultCrs,
  // Components
  Table,
  Link: ContractProductDefaultCrLink,
  Editor: ContractProductDefaultCrEditor,
  // Related Entity Objects
};

export default ContractProductDefaultCr;
