import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import S3BucketNavSidebar from "./S3BucketNavSidebar";

export default function S3BucketEdit() {
  const id = String(useParams().id);
  const { s3Bucket, loading, updateOne } = Server.S3Bucket.useOne({
    id,
    canGet: true,
  });
  const { isAdmin, canMaintainServers } = useSelector((state) => state.auth);

  return (
    <Page title="S3 Bucket" navSidebar={<S3BucketNavSidebar />}>
      {s3Bucket ? (
        <>
          <Server.S3Bucket.Editor
            values={s3Bucket}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
            isDisabled={loading || !(canMaintainServers || isAdmin)}
          />
          <StampDisplay record={s3Bucket} />
        </>
      ) : (
        <h4>S3 Bucket not found.</h4>
      )}
    </Page>
  );
}
