import { entityDescription } from "../../common/entity";
import CrFilterEditor from "./CrFilterEditor";
import CrFilterSelect from "./CrFilterSelect";
import Favorite from "./favorites";
import { useFilter, useFilters } from "./hooks";
import filterState from "./state";

const FilterDescription = entityDescription({
  name: "filter",
  useEntity: useFilter,
});

const Filter = {
  // Backend API
  api: filterState.api,
  // State
  actions: filterState.actions,
  selectors: filterState.entity.selectors,
  // Hooks
  useAll: useFilters,
  useOne: useFilter,
  // Components
  Select: CrFilterSelect,
  Description: FilterDescription,
  Editor: CrFilterEditor,
  // Related
  Favorite: Favorite,
};
export default Filter;
