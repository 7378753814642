import React from "react";

import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import JiraProject from "../../../customers/jiraProjects";
import JiraProjectNavSidebar from "./JiraProjectNavSidebar";

const TABLE_COMPONENTS = {
  Customer: ({ value }) => <Customer.Link id={value}>{value}</Customer.Link>,
};

export default function JiraProjectIndex() {
  const customerId = useSearchParams("customer");
  const { customer } = Customer.useOne({ id: customerId, canGet: false });
  const { jiraProjects, reset, getMany, loading } = JiraProject.useSearch({
    canGet: true,
    search: customerId ? { customerId } : {},
  });

  const title = customer?.name
    ? `Jira Projects: ${customer?.name}`
    : "Jira Projects";

  return (
    <Page title={title} navSidebar={<JiraProjectNavSidebar />}>
      <JiraProject.Table
        jiraProjects={jiraProjects}
        Components={TABLE_COMPONENTS}
        isLoading={loading}
        onRefresh={() => {
          reset();
          getMany();
        }}
      />
    </Page>
  );
}
