import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import { CurrencyFormatter, NumberFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportHostingYearBreakdown() {
  const { isAdmin, hostingAgreementAccess } = useSelector(
    (state) => state.auth,
  );

  if (
    !(
      hostingAgreementAccess === "V" ||
      hostingAgreementAccess === "M" ||
      isAdmin
    )
  )
    return (
      <Page
        title="Customer Billed By Product"
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line

  return (
    <JobPage
      title="Customer Billed By Product"
      functionName="CustomerBilledByProduct"
      Options={CustomerBilledByProductOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function MonthlyTotal({ rows, ptr, showDollars, showHours, showCrCount }) {
  const calcRow = (total, row) => {
    if (row.isGrouped) {
      if (row.isExpanded) return total;
      else return row.subRows.reduce(calcRow, total);
    } else {
      if (ptr === 13) {
        let sum = [0, 0, 0];
        for (let p = 1; p < 13; p++) {
          let mm = String(p);
          if (p < 10) mm = "0" + p;

          sum[0] = sum[0] + parseFloat(row.original["billed" + mm]);
          sum[1] = sum[1] + parseFloat(row.original["hours" + mm]);
          sum[2] = sum[2] + parseFloat(row.original["crs" + mm]);
        }
        total[0] = total[0] + sum[0];
        total[1] = total[1] + sum[1];
        total[2] = total[2] + sum[2];
        return total;
      } else {
        let mm = String(ptr);
        if (ptr < 10) mm = "0" + ptr;
        total[0] = total[0] + parseFloat(row.original["billed" + mm]);
        total[1] = total[1] + parseFloat(row.original["hours" + mm]);
        total[2] = total[2] + parseFloat(row.original["crs" + mm]);
        return total;
      }
    }
  };

  const x = rows.reduce(calcRow, [0, 0, 0]);
  const billed = x[0];
  const hours = x[1];
  const crs = x[2];

  return (
    <>
      {showDollars ? (
        <Alert
          variant="success"
          title="Billed"
          className={["m-0", "rounded-0", "p-2"]}
        >
          <CurrencyFormatter value={billed} decimals={0} />
        </Alert>
      ) : null}
      {showHours ? (
        <Alert
          variant="warning"
          title="Hours"
          className={["m-0", "rounded-0", "p-2"]}
        >
          <NumberFormatter value={hours} decimals={0} />
        </Alert>
      ) : null}
      {showCrCount ? (
        <Alert
          variant="primary"
          title="CR Count"
          className={["m-0", "rounded-0", "p-2"]}
        >
          <NumberFormatter value={crs} decimals={0} />
        </Alert>
      ) : null}
    </>
  );
}

function MonthCell({ row, showDollars, showHours, showCrCount, ptr }) {
  let output = [0, 0, 0];

  for (let p = 1; p < 13; p++) {
    if (p === ptr || ptr === 13) {
      let mm = String(p);
      if (p < 10) mm = "0" + mm;
      output[0] = output[0] + row["billed" + mm];
      output[1] = output[1] + row["hours" + mm];
      output[2] = output[2] + row["crs" + mm];
    }
  }

  return (
    <>
      {showDollars ? (
        <div>
          &nbsp;
          <CurrencyFormatter value={output[0]} blankZero={true} decimals={0} />
        </div>
      ) : null}
      {showHours ? (
        <div>
          &nbsp;
          <NumberFormatter value={output[1]} blankZero={true} decimals={0} />
        </div>
      ) : null}
      {showCrCount ? (
        <div>
          &nbsp;
          <NumberFormatter value={output[2]} blankZero={true} decimals={0} />
        </div>
      ) : null}
    </>
  );
}

function Report({ job, isLoading }) {
  const [showCrCount, setShowCrCount] = useState(true);
  const [showHours, setShowHours] = useState(true);
  const [showDollars, setShowDollars] = useState(true);

  const data = useMemo(() => job?.output?.customer_and_proj || [], [job]);

  const columns = useMemo(() => {
    const output = [
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: ({ value }) => <Customer.Name id={value} />,
      },
      {
        id: "Product",
        Header: "Product",
        accessor: "product",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Jan",
        Header: "Jan",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={1}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={1}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Feb",
        Header: "Feb",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={2}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={2}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Mar",
        Header: "Mar",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={3}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={3}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Apr",
        Header: "Apr",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={4}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={4}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "May",
        Header: "May",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={5}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={5}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Jun",
        Header: "Jun",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={6}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={6}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Jul",
        Header: "Jul",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={7}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={7}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Aug",
        Header: "Aug",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={8}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={8}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Sep",
        Header: "Sep",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={9}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={9}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Oct",
        Header: "Oct",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={10}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={10}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Nov",
        Header: "Nov",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={11}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={11}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Dec",
        Header: "Dec",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={12}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={12}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
      {
        id: "Total",
        Header: "Total",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ row }) => (
          <MonthCell
            row={row?.original}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
            ptr={13}
          />
        ),
        Footer: ({ rows }) => (
          <MonthlyTotal
            rows={rows}
            ptr={13}
            showDollars={showDollars}
            showHours={showHours}
            showCrCount={showCrCount}
          />
        ),
      },
    ];

    return output;
  }, [showCrCount, showDollars, showHours]);

  return (
    <>
      <Row>
        <Col xs={"auto"}>
          <ButtonGroup toggle>
            <ToggleButton
              key="showDollars"
              type="checkbox"
              variant="success"
              checked={showDollars}
              onChange={() => setShowDollars(!showDollars)}
            >
              Dollars
            </ToggleButton>
            <ToggleButton
              key="showHours"
              type="checkbox"
              variant="warning"
              checked={showHours}
              onChange={() => setShowHours(!showHours)}
            >
              Hours
            </ToggleButton>
            <ToggleButton
              key="showCrCount"
              type="checkbox"
              variant="primary"
              checked={showCrCount}
              onChange={() => setShowCrCount(!showCrCount)}
            >
              CR Count
            </ToggleButton>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
          />
        </Col>
      </Row>
    </>
  );
}

function CustomerBilledByProductOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("year"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      customerId: null,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      ...options,
    },
  });

  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          customerId !== "" && customerId !== null
            ? "Customer Billed By Product for " +
              useCustomerValue?.customer?.name
            : "Customer Billed By Product for " +
              _date.stamp(options.startDate).substring(0, 4);
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          year: _date.stamp(options.startDate).substring(0, 4),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Year"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                dateOnlyFormat="yyyy"
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>&nbsp;</Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable={true}
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
