import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsQuestionSquareFill } from "react-icons/bs";

import GroupLookupByProgram from "./GroupLookupByProgram";

export function GroupLabel({ onChosen, disabled }) {
  const [show, setShow] = useState(false);

  const onSelect = useCallback(
    (id) => {
      setShow(false);
      onChosen(id);
    },
    [onChosen],
  );

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <abbr title="Group Lookup" onClick={() => !disabled && setShow(true)}>
        Group <BsQuestionSquareFill className="p-0" />
      </abbr>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Group Lookup by Program
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GroupLookupByProgram onSelect={onSelect} />
        </Modal.Body>
      </Modal>
    </span>
  );
}
