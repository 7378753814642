import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";

function JobsTable({
  jobs = [],
  layout = ["dateTime", "type", "description", "status"],
  initialSort = { id: "dateTime", desc: true },
  ...props
}) {
  const columns = React.useMemo(
    () => [
      {
        id: "dateTime",
        Header: "Date",
        accessor: "dateTime",
        disableFilters: true,

        Cell: ({ value }) => (
          <span>{_datetime.displayDateTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        id: "job",
        Header: "Job",
        accessor: "id",
        disableFilters: true,
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        disableFilters: true,
      },
      {
        id: "type",
        Header: "Type",
        disableFilters: false,
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: (job) => _.startCase(job.functionName),
        Cell: ({ row: { original: job }, value }) => {
          switch (job?.functionName) {
            case "statement":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "approvals/" + job.id }}
                >
                  Invoice approval statement
                </Link>
              );
            case "InternalTime":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/internal-time/" + job.id }}
                >
                  Internal Time Report
                </Link>
              );
            case "CodeChanges":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/code-changes/" + job.id }}
                >
                  Code Changes
                </Link>
              );
            case "CustomAnalysis":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/custom-analysis/" + job.id }}
                >
                  Custom Analysis
                </Link>
              );
            case "CustomerBilledByProduct":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/customer-billed-by-product/" + job.id,
                  }}
                >
                  Customer Billed By Product
                </Link>
              );
            case "CustomerCrs":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/customer-crs/" + job.id }}
                >
                  Customer Crs Report
                </Link>
              );
            case "ARYearlySummary":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/ar-yearly-customer-summary/" + job.id,
                  }}
                >
                  AR Yearly Summary
                </Link>
              );
            case "CustomerProducts":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/customer-products/" + job.id }}
                >
                  Customer Products
                </Link>
              );
            case "CustomerStatement":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/customer-statement/" + job.id }}
                >
                  Customer Statement
                </Link>
              );
            case "CustomerTime":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/customer-time/" + job.id }}
                >
                  Customer Time Report
                </Link>
              );
            case "DailyBreakdown":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/daily-breakdown/" + job.id }}
                >
                  Daily Breakdown Report
                </Link>
              );
            case "EmployeeMeasures":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/employee-by-measures/" + job.id }}
                >
                  Employee by Measures Report
                </Link>
              );
            case "HostingYearBreakdown":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/hosting-year-breakdown/" + job.id,
                  }}
                >
                  Hosting Year Breakdown
                </Link>
              );
            case "Activity":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/activity/" + job.id }}
                >
                  Activity Report
                </Link>
              );
            case "ModuleSummary":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/module-summary/" + job.id }}
                >
                  Module Summary Report
                </Link>
              );
            case "NonbillableTime":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/nonbillable-time/" + job.id }}
                >
                  Nonbillable Time Report
                </Link>
              );
            case "OutOfOffice":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/out-of-office/" + job.id }}
                >
                  Out of Office Report
                </Link>
              );
            case "Overtime":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/bac/" + job.id }}
                >
                  Billable Additional Compensation Report
                </Link>
              );
            case "TimeOnHold":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/time-on-hold/" + job.id }}
                >
                  Time On Hold Report
                </Link>
              );
            case "UnbilledTime":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/unbilled-time/" + job.id }}
                >
                  Unbilled Time Report
                </Link>
              );
            case "WorkSummary":
              return (
                <Link
                  className="text-decoration-none"
                  to={{ pathname: "/reports/work-summary/" + job.id }}
                >
                  Work Summary Report
                </Link>
              );
            case "ContractorWork":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/contractor-work-summary/" + job.id,
                  }}
                >
                  Contractor Work Summary Report
                </Link>
              );
            case "PrCrTextSearch":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/pr-cr-text-search/" + job.id,
                  }}
                >
                  PR/CR Text Search
                </Link>
              );
            case "QaReviewers":
              return (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: "/reports/qa-reviewers/" + job.id,
                  }}
                >
                  QA Reviewers
                </Link>
              );
            default:
              return value;
          }
        },
      },
      {
        id: "status",
        Header: "Status",
        Filter: SelectColumnFilter,
        filter: "text",
        accessor: (t) => {
          switch (t.status) {
            case "9":
              return "Waiting";
            case "7":
              return "In progress";
            case "6":
              return "Failed";
            case "5":
              return "Completed";
            default:
              return "Error: " + t.status;
          }
        },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Table
              hasCount
              bordered
              hover
              size="sm"
              label="Jobs"
              columns={columns}
              initialSort={initialSort}
              layout={layout}
              data={jobs}
              moveFooterToTop
              {...props}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default React.memo(JobsTable);
