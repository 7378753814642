import React from "react";
import { useParams } from "react-router";

import UserActivityTable from "../../activity/userActivity/UserActivityTable";
import Project from "../../projects";
import ProjectPage from "./ProjectPage";
import ProjectShowNavSidebar from "./ProjectShowNavSidebar";

export default function ProjectShowLog() {
  const id = parseInt(useParams().id);
  const { project, loading } = Project.useOne({
    id,
    logActivity: false,
    includeLogs: true,
  });

  return (
    <ProjectPage
      Project={project}
      heading={`Project ${id} Log`}
      navSidebar={<ProjectShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <UserActivityTable
        userActivity={project?.logs}
        isLoading={loading}
        layout={["source", "dateTime", "userId", "description", "type"]}
        initialFilters={{ id: "type", value: "Field Changed" }}
        initialGroupBy="source"
      />
    </ProjectPage>
  );
}
