import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";

import useLogout from "../../auth/useLogout";
import Page from "../../common/pages/Page";
import Contact from "../../contacts";
import Cr from "../../crs";
import Customer from "../../customers";
import JiraProject from "../../customers/jiraProjects";
import Dbc from "../../dbc";
import Estimate from "../../estimates";
import Expense from "../../expenses";
import Project from "../../projects";
import Server from "../../servers";
import usePreloader from "../../usePreloader";
import SettingsNavSidebar from "./SettingsNavSidebar";

function CacheItem({ entity, name }) {
  const _name = _.startCase(name);
  const {
    reset,
    loading,
    entities,
    lastUpdate,
    [_.camelCase(name)]: namedEntities,
  } = entity;
  const _entities = namedEntities || entities;
  return (
    <tr>
      <td>{_name}</td>
      <td className="text-right">
        {loading ? "Loading" : _entities ? _entities.length : null}
      </td>
      <td>{moment(lastUpdate).fromNow()}</td>
      <td>
        <Button
          size="sm"
          variant="warning"
          disabled={!_.isFunction(reset)}
          onClick={() => {
            reset();
          }}
        >
          Reload
        </Button>
      </td>
    </tr>
  );
}

export default function SettingsCache() {
  const { entities } = usePreloader({ canGet: false });
  const caches = Object.entries(entities).map(([key, e]) => {
    return <CacheItem key={key} name={key} entity={e} />;
  });
  const logout = useLogout({ canResetState: true });
  return (
    <Page title="Cache Management" navSidebar={<SettingsNavSidebar />}>
      <Row className="mb-4">
        <Col>
          <Button variant="danger" onClick={() => logout()}>
            Logout and Fully Reset Cache
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Entries</th>
                <th>Last Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{caches}</tbody>
          </Table>
        </Col>
        <Col xs="auto">
          <CacheClearables />
        </Col>
      </Row>
    </Page>
  );
}

function CacheClearableItem({ hook, name }) {
  const { entities, reset } = hook({ canGet: false });
  return (
    <tr>
      <td>{name}</td>
      <td>{entities.length}</td>
      <td>
        <Button
          size="sm"
          variant="warning"
          disabled={!_.isFunction(reset)}
          onClick={() => {
            reset();
          }}
        >
          Clear
        </Button>
      </td>
    </tr>
  );
}

function CacheClearables() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Entries</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <CacheClearableItem name="Projects" hook={Project.useAll} />
        <CacheClearableItem name="CRs" hook={Cr.useAll} />
        <CacheClearableItem name="Contacts" hook={Contact.useAll} />
        <CacheClearableItem
          name="Customer Contracts"
          hook={Customer.Contracts.useAll}
        />
        <CacheClearableItem name="Expense Trips" hook={Expense.Trip.useAll} />
        <CacheClearableItem
          name="Expense Trip Users"
          hook={Expense.Trip.User.useAll}
        />
        <CacheClearableItem name="Expenses" hook={Expense.useAll} />
        <CacheClearableItem name="DBC Branches" hook={Dbc.Branch.useAll} />
        <CacheClearableItem
          name="DBC Source Code"
          hook={Dbc.SourceCode.useAll}
        />
        <CacheClearableItem
          name="DBC Reusuable Program"
          hook={Dbc.ReusableProgram.useAll}
        />
        <CacheClearableItem name="DBC Code Tests" hook={Dbc.CodeTest.useAll} />
        <CacheClearableItem name="Servers" hook={Server.useAll} />
        <CacheClearableItem name="Estimates" hook={Estimate.useAll} />
        <CacheClearableItem name="Jira Projects" hook={JiraProject.useAll} />
      </tbody>
    </Table>
  );
}
