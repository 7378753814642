import GroupDescription from "./GroupDescription";
import { GroupLabel } from "./GroupLabel";
import GroupLookupByProgram from "./GroupLookupByProgram";
import GroupSelect from "./GroupSelect";
import GroupTableFilter from "./GroupTableFilter";
import { useGroup, useGroups } from "./hooks";
import Program from "./programs";
import groupState from "./state";

export const isTierOneGroup = (groupId) =>
  groupId === "OUTBND" ||
  groupId === "INVENT" ||
  groupId === "ORDER" ||
  groupId === "GENERL";

const Group = {
  // API
  api: groupState.api,
  // State
  actions: groupState.actions,
  selectors: groupState.entity.selectors,
  // Hooks
  useOne: useGroup,
  useAll: useGroups,
  // Functions
  isTierOne: isTierOneGroup,
  // Components
  Select: GroupSelect,
  Description: GroupDescription,
  Label: GroupLabel,
  TableFilter: GroupTableFilter,
  LookupByProgram: GroupLookupByProgram,
  // Related Entity Objects
  Program: Program,
};
export default Group;
