import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import JiraProjectIndex from "./JiraProjectIndex";
import JiraProjectNew from "./JiraProjectNew";
import JiraProjectShow from "./JiraProjectShow";

export default function JiraProjectsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <JiraProjectNew />
      </Route>
      <Route exact path={`${path}`}>
        <JiraProjectIndex />
      </Route>
      <Route exact path={`${path}/:id`}>
        <JiraProjectShow />
      </Route>
    </Switch>
  );
}
