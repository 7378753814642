import _ from "lodash";
import React from "react";

import FieldSelect from "../../common/fields/FieldSelect";
import { useRepositories } from "./hooks";

const RepositorySelect = React.forwardRef(
  (
    { value, isMulti = false, placeholder = "Select Repository...", ...props },
    ref,
  ) => {
    const { repositories } = useRepositories();

    const _options = repositories.map((f) => {
      return { label: f.description, value: f.id };
    });

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = _options.filter((o) => value.includes(o.value));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    return (
      <FieldSelect
        ref={ref}
        value={valueOption}
        options={_options}
        isMulti={isMulti}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

RepositorySelect.displayName = "RepositorySelect";
export default RepositorySelect;
