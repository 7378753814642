import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Invoice from "../../../invoices";
import TaxProduct from "../../../invoices/taxProducts";
import InvoicesNavSidebar from "../InvoicesNavSidebar";

export default function TaxProductShow() {
  const id = useParams().id.toUpperCase();
  const { taxProduct, loading, updateOne } = Invoice.TaxProduct.useOne({
    id: id,
    canGet: true,
  });
  const { canMaintainInvoices, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainInvoices || isAdmin))
    return (
      <Page
        title="Tax Product"
        navSidebar={<InvoicesNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Tax Product" navSidebar={<InvoicesNavSidebar />}>
      {taxProduct ? (
        <>
          <TaxProduct.Editor
            isNew={false}
            values={taxProduct}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={taxProduct} />
        </>
      ) : (
        <h4>Tax Product record not found.</h4>
      )}
    </Page>
  );
}
