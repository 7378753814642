import React, { useMemo, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

import GroupOwnerFields from "../common/components/forms/GroupOwnerFields";
import DetailField from "../common/details/DetailField";
import {
  Forms,
  FormsField,
  useController,
  useFormContext,
} from "../common/forms";
import Customer from "../customers";
import Project from "../projects";
import CrAlerts from "./CrAlerts";
import crSchema from "./crSchema";
import DefectOccurrence from "./defectOccurrences";
import Difficulty from "./difficulties";
import Module from "./modules";
import NotificationType from "./notificationTypes";

export default function CrEditor({ cr, isNew, onSubmit, project, error }) {
  const isTransferred =
    cr?.transferredFolder && cr?.transferredFolder !== "CURRENT";
  return (
    <Forms
      defaultValues={cr}
      schema={{ schema: crSchema }}
      context={{ project }}
      onSubmit={onSubmit}
      isDisabled={isTransferred}
      showDevTool
    >
      {error ? (
        <Row>
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      ) : null}
      <CrAlerts cr={cr} />
      <Row>
        <Col>
          <CrForm isNew={isNew} />
        </Col>
      </Row>
    </Forms>
  );
}

function CrForm({ isNew }) {
  const {
    context: { project },
    setContext,
    watch,
    submitForm,
    defaultValues: { projectId: defaultProjectId },
    formState: { isSubmitting },
    formsContext: { isDisabled },
  } = useFormContext();

  const [projectId, overrideTypeId] = watch(["projectId", "overrideTypeId"]);
  const typeId = overrideTypeId || project?.typeId;

  return (
    <Form onSubmit={submitForm}>
      <Row>
        <Col xs="auto">
          <ProjectId
            name="projectId"
            isDisabled={defaultProjectId || !isNew}
            setContext={setContext}
          />
        </Col>
      </Row>
      {projectId ? (
        <Row>
          <Col sm="8" className="border-right">
            <CustomerId name="overrideCustomerId" project={project} />

            <Row>
              <Col>
                <FormsField name="synopsis" label="Synopsis" />
                <FormsField.TextArea
                  name="description"
                  label="Description"
                  minRows={6}
                />
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <GroupOwnerFields isNew={isNew} />
            <FormsField.Select
              name="overrideTypeId"
              label="Override Type"
              as={Project.Type.Select}
              isClearable
            />
            <FormsField.Select
              name="moduleId"
              label="Module"
              as={Module.Select}
              isClearable
            />
            <FormsField.Select
              name="difficultyId"
              label="Difficulty"
              as={Difficulty.Select}
            />
            <Row>
              <Col className="py-3">
                <Button
                  type="submit"
                  className={isSubmitting ? "disabled-btn" : ""}
                  disabled={isDisabled || isSubmitting}
                  block
                >
                  {isNew ? "Create" : "Save"} CR
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <FormsField.Select
                  name="notificationTypeId"
                  label="Documentation"
                  as={NotificationType.Select}
                />
                {typeId === "DE" ? (
                  <FormsField.Select
                    name="defectOccurrenceId"
                    label="Defect Occurrence"
                    as={DefectOccurrence.Select}
                    isClearable
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
}

function ProjectId({ name, isDisabled, setContext }) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({ name });

  const { getProject, loading: projectLoading } = Project.useOne.getOne();

  return (
    <Form.Row>
      <Col style={{ minWidth: "400px" }}>
        <Project.Select
          label="Project"
          onChange={({ id: value }) => {
            getProject({ id: value, fetch: "auto" }).then(
              ({ data: project }) => {
                setContext((c) => ({ ...c, project }));
                onChange(value);
              },
            );
          }}
          value={value}
          onBlur={onBlur}
          isDisabled={isDisabled || projectLoading}
          isInvalid={error}
          errors={error?.message}
          canFetch={isDisabled ? false : "auto"}
        />
      </Col>
    </Form.Row>
  );
}

function CustomerId({ name, project }) {
  const [showOverrideCustomer, setShowOverrideCustomer] = useState(false);

  const {
    field: { value, onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled },
  } = useFormContext();

  const customers = useMemo(
    () => project?.customers.map((c) => c.customerId),
    [project],
  );

  return (
    <Row>
      <Col>
        <DetailField
          header={`Customers${value ? "*" : ""}`}
          onEditClick={
            !isDisabled
              ? () => setShowOverrideCustomer(!showOverrideCustomer)
              : undefined
          }
        >
          {showOverrideCustomer ? (
            <Row className="px-3 border-left border-primary">
              <Col>
                <Customer.Select
                  {...field}
                  value={value}
                  onChange={(c) => onChange(c || 0)}
                  label="Override Customer"
                  isInvalid={error}
                  errors={error?.message}
                  filter={(c) => customers.includes(c.id)}
                  isClearable
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Project.Contacts project={project} customerId={value} />
            </Col>
          </Row>
        </DetailField>
      </Col>
    </Row>
  );
}
