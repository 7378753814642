import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

export default function CrAlerts({ cr }) {
  const transferred =
    cr?.transferredFolder && cr?.transferredFolder !== "CURRENT";
  return (
    <>
      {cr?.lockedBy ? (
        <Row>
          <Col>
            <Alert variant="danger">{cr.lockedBy}</Alert>
          </Col>
        </Row>
      ) : null}
      {transferred ? (
        <Row>
          <Col>
            <Alert variant="warning">
              CR is transferred. Changes are disallowed.
            </Alert>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
