import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter, PercentFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Module from "../../crs/modules";
import Project from "../../projects";
import Time from "../../time";
import BillableOverrideReasons from "../../time/billableOverrideReasons";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

const estimateLayout = [
  "Group",
  "Project/Cr",
  "Owner",
  "Champion",
  "Estimate $",
  "Bill $",
  "N/B $",
  "Under Budget?",
  "Flat Gain $",
  "Flat Loss $",
  "N/B Flip Gain $",
  "Billable %",
];

const groupBy = ["Group"];

const noEstimateLayout = [
  "Group",
  "Project/Cr",
  "Owner",
  "Champion",
  "Bill $",
  "N/B $",
  "Billable %",
];

const oslLayout = [
  "Group",
  "Project/Cr",
  "Champion",
  "Bill $",
  "N/B $",
  "Billable %",
];

export default function ReportCustomAnalysis() {
  return (
    <JobPage
      title="Custom Analysis"
      functionName="CustomAnalysis"
      Options={CustomAnalysisOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const estimates = useMemo(() => job?.output?.estimates || [], [job]);
  const noEstimates = useMemo(() => job?.output?.noEstimates || [], [job]);
  const osls = useMemo(() => job?.output?.osls || [], [job]);
  const nbReasons = useMemo(() => job?.output?.nbReasons || [], [job]);
  const summary = useMemo(() => job?.output?.summary || [], [job]);

  return (
    <>
      <Tabs defaultActiveKey="summary">
        <Tab eventKey="summary" title="Summary" mountOnEnter={true}>
          <Summary data={summary} isLoading={isLoading} />
        </Tab>
        <Tab eventKey="estimates" title="Estimates">
          <Detail
            data={estimates}
            isLoading={isLoading}
            layout={estimateLayout}
          />
        </Tab>
        <Tab eventKey="no estimates" title="No Estimates">
          <Detail
            data={noEstimates}
            isLoading={isLoading}
            layout={noEstimateLayout}
          />
        </Tab>
        <Tab eventKey="osl" title="OSL">
          <Detail data={osls} isLoading={isLoading} layout={oslLayout} />
        </Tab>
        <Tab eventKey="nonbill reasons" title="N/B Reasons">
          <NbReasons data={nbReasons} isLoading={isLoading} />
        </Tab>
      </Tabs>
    </>
  );
  //initialGroupBy={groupBy}
}

function Detail({ data, isLoading, layout }) {
  const columns = useMemo(
    () => [
      {
        id: "Champion",
        Header: "Champion",
        accessor: "champion",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Owner",
        Header: "Owner",
        accessor: "ownerId",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Project/Cr",
        Header: "Project/Cr",
        accessor: "reference",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row }) =>
          row?.original?.source === "CR" ? (
            <Cr.Link id={value} />
          ) : (
            <Project.Link id={value} />
          ),
      },
      {
        id: "Status",
        Header: "Status",
        accessor: "statusId",
        Filter: Cr.Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <Cr.Status.Description id={value} />,
      },
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerName",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        disableFilters: true,
      },
      {
        id: "Custom type",
        Header: "Custom Type",
        accessor: "customType",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Group",
        id: "Group",
        accessor: "group",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        id: "Estimate $",
        Header: "Estimate $",
        accessor: "estimateDollars",
        disableFilters: true,
        aggregate: "sum",
        dataType: "currency",
        format: { number: { decimals: 0 } },
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.estimateDollars) + total;
          };

          const total = rows.reduce(calcRow, 0);
          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        id: "Bill $",
        Header: "Bill $",
        accessor: "billDollars",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else {
                return row.subRows.reduce(calcRow, total);
              }
            return parseFloat(row.original.billDollars) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },

      {
        Header: "Billable %",
        id: "Billable %",
        accessor: (values) => {
          return (
            (values.billDollars /
              (values.billDollars + values.nonbillDollars)) *
            100.0
          ).toFixed(2);
        },
        dataType: "number",
        format: { number: { decimals: 2 } },
        disableFilters: true,
        Cell: ({ value }) => <PercentFormatter value={value} decimals={2} />,
        Aggregated: ({ row }) => {
          let total = 0;
          let bill = 0;
          row.leafRows.forEach((leaf) => {
            total =
              total + leaf.original.billDollars + leaf.original.nonbillDollars;
            bill = bill + leaf.original.billDollars;
          });
          return (
            <PercentFormatter
              value={((bill / total) * 100.0).toFixed(2)}
              decimals={2}
            />
          );
        },
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return {
              bill: parseFloat(row.original.billDollars) + total.bill,
              nonbill: parseFloat(row.original.nonbillDollars) + total.nonbill,
            };
          };

          const total = rows.reduce(calcRow, { nonbill: 0, bill: 0 });

          return (
            <PercentFormatter
              value={(
                (total.bill / (total.bill + total.nonbill)) *
                100.0
              ).toFixed(2)}
              decimals={2}
            />
          );
        },
      },
      {
        id: "N/B $",
        Header: "N/B $",
        accessor: "nonbillDollars",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.nonbillDollars) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        id: "Bill Hr",
        Header: "Bill Hr",
        accessor: "billHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.billHours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "N/B Hr",
        Header: "N/B Hr",
        accessor: "nonbillHours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.nonbillHours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Under Budget?",
        Header: "Under Budget?",
        accessor: "underBudget",
        format: {
          alignment: {
            horizontal: "center",
          },
        },
        Filter: SelectColumnFilter,
        filter: "equals",

        Aggregated: ({ row, column }) => {
          let total = 0;
          let under = 0;
          row.leafRows.forEach((leaf) => {
            total++;
            if (leaf.values[column.id] === "Yes") under++;
          });
          return (
            <PercentFormatter
              value={((under / total) * 100.0).toFixed(2)}
              decimals={2}
            />
          );
        },
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);

            if (row.original.underBudget == "Yes")
              return { under: total.under + 1, total: total.total + 1 };
            else return { under: total.under, total: total.total + 1 };
          };

          const total = rows.reduce(calcRow, { under: 0, total: 0 });

          return (
            <PercentFormatter
              value={((total.under / total.total) * 100.0).toFixed(2)}
              decimals={2}
            />
          );
        },
      },
      {
        id: "Flat Gain $",
        Header: "Flat Gain $",
        accessor: "flatGain",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.flatGain) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        id: "Flat Loss $",
        Header: "Flat Loss $",
        accessor: "flatLoss",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.flatLoss) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        id: "N/B Flip Gain $",
        Header: "N/B Flip Gain $",
        accessor: "nbFlipGain",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.nbFlipGain) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
          layout={layout}
          initialGroupBy={groupBy}
        />
      </Col>
    </Row>
  );
}

function NbReasons({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        Header: "Group",
        id: "Group",
        accessor: "group",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Reason",
        id: "Reason",
        accessor: "nonbillReason",
        Filter: Time.BillableOverrideReason.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => (
          <Time.BillableOverrideReason.Description id={value} />
        ),
      },
      {
        Header: "User",
        id: "User",
        accessor: "userId",
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
      },
      {
        id: "Dollars",
        Header: "Dollars",
        accessor: "dollars",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.dollars) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        id: "Hours",
        Header: "Hours",
        accessor: "hours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
          initialGroupBy={groupBy}
        />
      </Col>
    </Row>
  );
}

function Summary({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        Header: "Group",
        id: "Group",
        accessor: "group",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "# Pr/Crs",
        id: "# Pr/Crs",
        accessor: "projectCount",
        aggregate: "sum",
        dataType: "number",
        disableFilters: true,
        format: { number: { decimals: 0 } },
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.projectCount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Billable Dollars",
        Header: "Billable Dollars",
        accessor: "billDollars",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.billDollars) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
      {
        Header: "Billable %",
        id: "Billable %",
        accessor: "billableRatio",
        dataType: "number",
        format: { number: { decimals: 2 } },
        disableFilters: true,
        Cell: ({ value }) => <PercentFormatter value={value} decimals={2} />,
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return {
              bill: parseFloat(row.original.billDollars) + total.bill,
              nonbill: parseFloat(row.original.nonbillDollars) + total.nonbill,
            };
          };

          const total = rows.reduce(calcRow, { nonbill: 0, bill: 0 });

          return (
            <PercentFormatter
              value={(
                (total.bill / (total.bill + total.nonbill)) *
                100.0
              ).toFixed(2)}
              decimals={2}
            />
          );
        },
      },
      {
        id: "Bill Dollar Avg",
        Header: "Bill Dollar Avg",
        accessor: "billDollarAverage",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return {
              bill: parseFloat(row.original.billDollars) + total.bill,
              count: row.original.projectCount + total.count,
            };
          };

          const total = rows.reduce(calcRow, { count: 0, bill: 0 });

          return (
            <CurrencyFormatter value={total.bill / total.count} decimals={0} />
          );
        },
      },
      {
        id: "N/B Dollar Avg",
        Header: "N/B Dollar Avg",
        accessor: "nonbillDollarAverage",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        Cell: ({ value }) => <CurrencyFormatter value={value} decimals={0} />,
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return {
              nonbill: parseFloat(row.original.nonbillDollars) + total.nonbill,
              count: row.original.projectCount + total.count,
            };
          };

          const total = rows.reduce(calcRow, { count: 0, nonbill: 0 });

          return (
            <CurrencyFormatter
              value={total.nonbill / total.count}
              decimals={0}
            />
          );
        },
      },
      {
        id: "% with Estimates",
        Header: "% with Estimates",
        accessor: "percentWithEstimates",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        Cell: ({ value }) => <PercentFormatter value={value} decimals={2} />,
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return {
              estimates:
                parseFloat(row.original.projectCountE) + total.estimates,
              noEstimates:
                parseFloat(row.original.projectCountN) + total.noEstimates,
            };
          };

          const total = rows.reduce(calcRow, { estimates: 0, noEstimates: 0 });

          return (
            <PercentFormatter
              value={(
                (total.estimates / (total.estimates + total.noEstimates)) *
                100.0
              ).toFixed(2)}
              decimals={2}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
        />
      </Col>
    </Row>
  );
}

function CustomAnalysisOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("year"),
  endDate: ed = moment().endOf("year"),
}) {
  const {
    options = {
      startDate: sd,
      endDate: ed,
      modules: [],
      modulesExclude: false,
      nbReasons: [],
      nbReasonsExclude: false,
      includeOpen: false,
    },
  } = job;
  const { handleSubmit, control, watch } = useForm({
    mode: "onTouched",
    defaultValues: {
      startDate: "",
      endDate: "",
      modules: [],
      modulesExclude: false,
      nbReasons: [],
      nbReasonsExclude: false,
      includeOpen: false,
      ...options,
    },
  });

  const endDate = watch("endDate");
  const startDate = watch("startDate");

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        submitJob({
          ...options,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: `${
            options?.userId ? options?.userId + " " : ""
          } from ${_date.display(options.startDate)} to ${_date.display(
            options.endDate,
          )}`,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="8">
          <div className="d-flex mt-3 justify-content-between">
            <div>Modules</div>
            <div>
              <Controller
                name="modulesExclude"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Form.Check
                    id={`${name}-exclude`}
                    inline
                    label="Exclude"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="8">
          <Controller
            name="modules"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Module.Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Select Module(s)..."
                isMulti
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="8">
          <div className="d-flex mt-3 justify-content-between">
            <div>N/B Reasons</div>
            <div>
              <Controller
                name="nbReasonsExclude"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Form.Check
                    id={`${name}-exclude`}
                    inline
                    label="Exclude"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="8">
          <Controller
            name="nbReasons"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <BillableOverrideReasons.Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Select N/B Reason(s)..."
                isMulti
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeOpen"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeOpen"
                label="Include open issues?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Col xs="auto"></Col>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
