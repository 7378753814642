import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import TradingPartnerEdit from "./TradingPartnerEdit";
import TradingPartnerIndex from "./TradingPartnerIndex";
import TradingPartnerNew from "./TradingPartnerNew";

export default function TradingPartnersPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <TradingPartnerNew />
      </Route>
      <Route path={`${path}/:id`}>
        <TradingPartnerEdit />
      </Route>
      <Route exact path={`${path}`}>
        <TradingPartnerIndex />
      </Route>
    </Switch>
  );
}
