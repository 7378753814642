import React, { useMemo } from "react";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import AwsAccount from "../awsAccounts";
import AwsInstanceTypeLink from "../awsInstanceTypes/AwsInstanceTypeLink";
import AwsZoneLink from "../awsZones/AwsZoneLink";
import ReservationLink from "./ReservationLink";

export default function ReservationTable({
  reservations,
  reservationLink = true,
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: reservation } }) =>
          reservationLink ? (
            <ReservationLink id={reservation?.id}>
              {value || reservation?.id}
            </ReservationLink>
          ) : (
            value
          ),
      },
      {
        id: "AWS Account",
        Header: "AWS Account",
        accessor: "awsAccount",
        Filter: AwsAccount.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsAccount.Link id={value} />,
      },
      {
        id: "AWS Zone",
        Header: "AWS Zone",
        accessor: "awsZone",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "AWS Instance Type",
        Header: "AWS Instance Type",
        accessor: "awsInstanceType",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
      {
        id: "Instance Count",
        Header: "Instance Count",
        accessor: "instanceCount",
      },
      {
        id: "Total Price",
        Header: "Total Price",
        accessor: "totalPrice",
      },
      {
        id: "Start Date",
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "End Date",
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
    ],
    [reservationLink],
  );

  return (
    <Table
      hasCount
      label="Reservations"
      columns={columns}
      data={reservations}
      {...props}
    />
  );
}
