import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "s3Bucket",
  type: "servers/s3Buckets",
  getState: (state) => state.servers.s3Buckets,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const s3BucketState = {
  api,
  actions,
  reducer,
  entity,
};

export default s3BucketState;
