import React from "react";

import { useRepository } from "./hooks";

export default function RepositoryDescription({ id }) {
  const { repository } = useRepository({ id });
  if (!repository) return null;

  return <>{repository.description}</>;
}
