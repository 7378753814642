import shark from "../../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../../common/entity";

const api = {
  getMany: ({ tripId }) => {
    return shark.post({ command: "getExpenseTripUsers", tripId }).then((r) => {
      return { data: r.responseData.expenseTripUsers };
    });
  },
  getOne: ({ id, find: { tripId, userId } = {} }) => {
    return shark
      .post({ command: "getExpenseTripUser", id, tripId, userId })
      .then((r) => {
        return { data: r.responseData };
      });
  },
  updateOne: (args) => {
    return shark
      .post({ command: "updateExpenseTripUser", ...args })
      .then((r) => {
        return { data: r.responseData };
      });
  },

  sendEmail: (options) => {
    const payload = {
      ...options,
      command: "emailExpenseTripUser",
    };
    return shark.post(payload);
  },
};

const entity = createEntity({
  name: "users",
  type: "expenses/trips/users",
  getState: (state) => state.expenses.trips.users,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const userState = {
  api,
  entity,
  actions,
  reducer,
};

export default userState;
