import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../common/hooks/useRedirect";
import Page from "../../common/pages/Page";
import Server from "../../servers";
import ServerNavSidebar from "./ServerNavSidebar";

export default function ServerNew() {
  const { createOne } = Server.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainServers, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainServers || isAdmin))
    return (
      <Page
        title="New Server"
        navSidebar={<ServerNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title={"New Server"} navSidebar={<ServerNavSidebar />}>
      <Server.Editor
        values={{ isActive: true, shouldGenerateSSHAlias: true }}
        onSubmit={(server) =>
          createOne(server).then(({ id }) => setRedirect(`/servers/${id}`))
        }
      />
    </Page>
  );
}
