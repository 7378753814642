import { createEntityHooks } from "../../common/entity";
import estimateStatusState from "./state";

export const {
  useEntities: useEstimateStatuses,
  useEntity: useEstimateStatus,
} = createEntityHooks({
  entity: estimateStatusState.entity,
  actions: estimateStatusState.actions,
});
