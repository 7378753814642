import React, { useMemo } from "react";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import CustomerLink from "../CustomerLink";
import CustomerTableFilter from "../CustomerTableFilter";
import HostingAgreementLink from "./HostingAgreementLink";
import { HOSTING_TYPES } from "./types";

export default function HostingAgreementTable({
  data,
  Components: OverrideComponents,
  layout = [
    "id",
    "Customer",
    "Server Type",
    "Billing Description",
    "Current $",
    "Original $",
    "Information",
    "Aws Instance Id",
    "Effective",
    "Expiration",
  ],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },
      {
        id: "id",
        Header: "Agreement Id",
        accessor: "id",
        Cell: ({ value }) => <HostingAgreementLink id={value} />,
      },
      {
        id: "Server Type",
        Header: "Type",
        accessor: (l) => HOSTING_TYPES[l?.serverType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Aws Instance Id",
        Header: "Aws Instance Id",
        accessor: "awsInstanceId",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Current $",
        Header: "Current $",
        accessor: "currentMonthlyPrice",
        dataType: "currency",
      },
      {
        id: "Original $",
        Header: "Original $",
        accessor: "originalMonthlyPrice",
        dataType: "currency",
      },
      {
        id: "Billing Description",
        Header: "Billing Description",
        accessor: "billingDescription",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Information",
        Header: "Information",
        accessor: "information",
      },
      {
        id: "Expiration",
        Header: "Expiration",
        accessor: "expirationMonth",
        Cell: ({ value }) => (
          <span>{_date.displayMM_YYYY(_date.fromMonthStamp(value))}</span>
        ),
      },
      {
        id: "Effective",
        Header: "Effective",
        accessor: "effectiveMonth",
        Cell: ({ value }) => (
          <span>{_date.displayMM_YYYY(_date.fromMonthStamp(value))}</span>
        ),
      },
    ],
    [],
  );
  return (
    <Table
      hasCount
      label="Hosting Agreements"
      columns={columns}
      layout={layout}
      data={data}
      {...props}
    />
  );
}
