import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ContractEdit from "./ContractEdit";
import ContractIndex from "./ContractIndex";
import ContractNew from "./ContractNew";
import ContractProductCategoriesPages from "./contractProductCategories";
import ContractProductDefaultCrPages from "./contractProductDefaultCrs";
import ContractProductsPages from "./contractProducts";
import ContractScheduling from "./ContractScheduling";

export default function ContractsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/contract-products`}>
        <ContractProductsPages />
      </Route>
      <Route path={`${path}/contract-product-categories`}>
        <ContractProductCategoriesPages />
      </Route>
      <Route path={`${path}/contract-product-default-crs`}>
        <ContractProductDefaultCrPages />
      </Route>
      <Route path={`${path}/scheduling`}>
        <ContractScheduling />
      </Route>
      <Route path={`${path}/new`}>
        <ContractNew />
      </Route>
      <Route path={`${path}/:id`}>
        <ContractEdit />
      </Route>
      <Route exact path={`${path}`}>
        <ContractIndex />
      </Route>
    </Switch>
  );
}
