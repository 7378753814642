export const SERVER_TYPES = {
  P: {
    id: "P",
    description: "Production",
    postfix: "",
  },
  T: {
    id: "T",
    description: "Test",
    postfix: "test",
  },
  M: {
    id: "M",
    description: "Mirror",
    postfix: "mir",
  },
  R: {
    id: "R",
    description: "Printer",
    postfix: "printer",
  },
  W: {
    id: "W",
    description: "Web",
    postfix: "web",
  },
  Y: {
    id: "Y",
    description: "Web Mirror",
    postfix: "web-mir",
  },
  A: {
    id: "A",
    description: "Analytics",
    postfix: "ana",
  },
  E: {
    id: "E",
    description: "EDI/FTP",
    postfix: "edi",
  },
  O: {
    id: "O",
    description: "Other",
    postfix: "other",
  },
};
