import { entityTableFilter } from "../../common/entity";
import { useNotificationTypes } from "./hooks";

const NotificationTypeTableFilter = entityTableFilter({
  name: "crNotificationType",
  useEntities: useNotificationTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default NotificationTypeTableFilter;
