import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

import FieldDate from "../../common/fields/FieldDate";
import { _date } from "../../common/functions/dates";
import useReinitState from "../../common/hooks/useReinitState";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import useSettings from "../../settings/useSettings";
import Time from "../../time";
import User from "../../users";
import TimeNavSidebar from "./TimeNavSidebar";

export default function TimeStats() {
  const loggedInUser = useSelector((state) => state.auth.user);
  const [key, setKey] = useState("weeks");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [userId, setUserId] = useState(loggedInUser);

  const offsetDate = (offset = 0) => {
    setStartDate((d) => {
      return moment(d).subtract(offset, key);
    });
    setEndDate((d) => {
      return moment(d).subtract(offset, key);
    });
  };

  const getDates = useCallback(() => {
    if (key !== "custom") {
      let start = moment(startDate);
      let end = moment(startDate);
      start.startOf(key);
      end.endOf(key);
      return [start.toISOString().toString(), end.toISOString().toString()];
    } else {
      return [
        startDate.startOf("days").toISOString().toString(),
        endDate.endOf("days").toISOString().toString(),
      ];
    }
  }, [key, startDate, endDate]);

  const [_startDate, _endDate] = getDates();

  const onUserChange = (userIdNew) => setUserId(userIdNew);

  return (
    <Page title="Time Statistics" navSidebar={<TimeNavSidebar />}>
      <Row className="py-1 my-1">
        <Col xs="6" className="pl-1">
          <User.Select
            id="user"
            value={userId}
            onChange={(value) => onUserChange(value)}
            isClearable={false}
            hasMeButton={true}
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            className={"py=1"}
          />
        </Col>
      </Row>
      <Row className="py-1 my-1">
        <Col xs="auto" className="pl-1">
          <Form.Group>
            <Form.Label>Date Range</Form.Label>
            <Form.Control
              defaultValue="weeks"
              as="select"
              onChange={(e) => {
                const k = e.target.value;
                setKey(k);
                if (k === "custom") {
                  if (startDate > endDate) setStartDate(moment(endDate));
                }
              }}
            >
              <option value="days">Day</option>
              <option value="weeks">Week</option>
              <option value="months">Month</option>
              <option value="quarters">Quarter</option>
              <option value="custom">Custom</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {key !== "custom" ? (
          <Col xs="auto">
            <Form.Group>
              <Form.Label>{_date.display(startDate)}</Form.Label>
              <InputGroup>
                <InputGroup.Append>
                  <Button
                    variant="outline-primary"
                    className="px-3"
                    onClick={() => offsetDate(1)}
                  >
                    <BsArrowLeftShort />
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="px-3"
                    onClick={() => offsetDate(-1)}
                  >
                    <BsArrowRightShort />
                  </Button>
                  <Button
                    variant="primary"
                    className="px-3"
                    onClick={() => setStartDate(moment())}
                  >
                    Today
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        ) : (
          <CustomDateRange
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        )}
      </Row>
      <Row>
        <Col xs="auto" className="border">
          <TimeStatsTable
            startDate={_startDate}
            endDate={_endDate}
            type={key === "custom" ? "days" : key}
            userId={userId}
            canSeeTargets={userId === loggedInUser}
          />
        </Col>
      </Row>
    </Page>
  );
}

function TimeStatsTable({ startDate, endDate, type, userId, canSeeTargets }) {
  const { stats = {}, loading } = Time.useStats({
    key: "day",
    startDate: startDate,
    endDate: endDate,
    userId: userId,
  });

  const { requiredHours } = stats;

  const { settings: { minBillableDollars } = {} } = useSettings({
    blocked: true,
  });

  const getMinBillDollars = useMemo(() => {
    if (!minBillableDollars) return 0;
    const rate = parseFloat(minBillableDollars);
    if (!rate) return 0;

    // Moment handles months/years differently since 1/1 2/1 = 1.0
    let start = moment(startDate);
    let end = moment(endDate);
    if (type === "days" && !_.isUndefined(requiredHours)) {
      // base it off of the hourly rate
      //  (yearly work hours per opm.gov minus average vacation 1 week + 2 days)
      return requiredHours * ((minBillableDollars * 12) / (2087 - 56));
    } else {
      end.add(1, type).startOf(type);

      const months = end.diff(start, "months", true);
      return months * rate;
    }
  }, [minBillableDollars, requiredHours, startDate, endDate, type]);

  const data = [
    {
      header: "Billable",
      hours: stats?.billableHours,
      overtimeHours: stats?.overtimeBillableHours,
      totalHours: _.sum([stats?.billableHours, stats?.overtimeBillableHours]),
      totalDollars: stats?.totalBillableDollars,
    },
    {
      header: "Non-Billable",
      hours: stats?.NonBillHours,
      overtimeHours: stats?.overtimeNonBillHours,
      totalHours: _.sum([stats?.NonBillHours, stats?.overtimeNonBillHours]),
    },
    {
      header: "Total",
      hours: _.sum([stats?.billableHours, stats?.NonBillHours]),
      overtimeHours: _.sum([
        stats?.overtimeBillableHours,
        stats?.overtimeNonBillHours,
      ]),
      totalHours: _.sum([
        stats?.NonBillHours,
        stats?.overtimeNonBillHours,
        stats?.billableHours,
        stats?.overtimeBillableHours,
      ]),
      totalDollars: stats?.totalBillableDollars,
    },
    {
      header: "Required",
      hours: stats?.requiredHours,
      totalDollars: getMinBillDollars,
    },
    {
      header: "Targets",
      hours:
        _.sum([stats?.billableHours, stats?.NonBillHours]) /
        stats?.requiredHours,
      totalDollars: stats?.totalBillableDollars / getMinBillDollars,
    },
  ];
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h4 className="text-center">
              {_date.display(startDate) + " - " + _date.display(endDate)}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              size="sm"
              style={{ width: "auto", minWidth: 600 }}
              isLoading={loading}
              skeletonLines={4}
              columns={[
                {
                  id: "rowHeader",
                  Header: "",
                  accessor: "header",
                  disableSortBy: true,
                  format: { alignment: { horizontal: "right" } },

                  Cell: ({ value }) => <strong>{value}</strong>,
                },
                {
                  id: "hours",
                  Header: "Hours",
                  accessor: "hours",
                  disableSortBy: true,
                  dataType: "number",

                  Cell: ({ row: { index }, value }) => {
                    let decScale = 2;
                    let suffix = "";
                    let printValue = value;
                    if (index == 4) {
                      decScale = 1;
                      suffix = "%";
                      printValue *= 100;
                    }
                    return canSeeTargets || index != 4 ? (
                      <span style={{ display: "block", minWidth: 100 }}>
                        <NumberFormat
                          value={printValue}
                          displayType={"text"}
                          decimalScale={decScale}
                          fixedDecimalScale
                          suffix={suffix}
                        />
                      </span>
                    ) : (
                      <span style={{ display: "block", minWidth: 125 }}></span>
                    );
                  },
                },
                {
                  id: "overtimeHours",
                  Header: "BAC",
                  accessor: "overtimeHours",
                  disableSortBy: true,
                  dataType: "number",
                  className: "border-right",

                  Cell: ({ value }) => (
                    <span style={{ display: "block", minWidth: 100 }}>
                      <NumberFormat
                        value={value}
                        displayType={"text"}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </span>
                  ),
                },
                {
                  id: "totalHours",
                  Header: "Total Hours",
                  accessor: "totalHours",
                  disableSortBy: true,
                  dataType: "number",

                  Cell: ({ value }) => (
                    <span style={{ display: "block", minWidth: 125 }}>
                      <NumberFormat
                        value={value}
                        displayType={"text"}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </span>
                  ),
                },
                {
                  id: "totalDollars",
                  Header: "Total Dollars",
                  accessor: "totalDollars",
                  disableSortBy: true,
                  dataType: "number",

                  Cell: ({ row: { index }, value }) => {
                    let decScale = 2;
                    let suffix = "";
                    let prefix = "$";
                    let printValue = value;
                    if (index == 4) {
                      decScale = 1;
                      suffix = "%";
                      prefix = "";
                      printValue *= 100;
                    }
                    return canSeeTargets || (index != 3 && index != 4) ? (
                      <span style={{ display: "block", minWidth: 125 }}>
                        <NumberFormat
                          value={printValue}
                          displayType={"text"}
                          thousandSeparator
                          prefix={prefix}
                          suffix={suffix}
                          decimalScale={decScale}
                          fixedDecimalScale
                        />
                      </span>
                    ) : (
                      <span style={{ display: "block", minWidth: 125 }}></span>
                    );
                  },
                },
              ]}
              data={loading ? [] : data}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function CustomDateRange({ onChange, startDate: start, endDate: end }) {
  const [startDate, setStartDate] = useReinitState(start);
  const [endDate, setEndDate] = useReinitState(end);

  return (
    <Col>
      <Row>
        <Col xs="auto">
          <FieldDate
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
              console.log(value);
            }}
            includeNow={false}
            maxDate={endDate}
          />
        </Col>
        <Col xs="auto">
          <FieldDate
            value={endDate}
            onChange={(value) => setEndDate(value)}
            includeNow={false}
            minDate={startDate}
          />
        </Col>
        <Col xs="auto align-self-end">
          <Button
            variant="success"
            onClick={() => onChange(moment(startDate), moment(endDate))}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
