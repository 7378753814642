import React from "react";

import { entityDescription } from "../../common/entity";
import { _date } from "../../common/functions/dates";
import Customer from "../../customers";
import { useExpenseTrip } from "./hooks";

const CustomerLabel = (t) => (
  <>
    <Customer.Name id={t.customerId} /> -{" "}
    {`${t.description} (${_date.display(_date.fromStamp(t.date))})`}
  </>
);

const ExpenseTripDescription = entityDescription({
  name: "expenseTrip",
  useEntity: useExpenseTrip,
  label: CustomerLabel,
  getHookArgs: ({ id, value, canGet }) => {
    return { id: id || value, canGet };
  },
});

export default ExpenseTripDescription;
