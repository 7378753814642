import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

import useRedirect from "../../common/hooks/useRedirect";
import Page from "../../common/pages/Page";
import Customer from "../../customers";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseCustomerIndex() {
  const [customerId, setCustomerId] = useState();
  const [, setRedirect] = useRedirect();
  return (
    <Page
      title={`Customer Expenses: Select`}
      navSidebar={<ExpenseIndexNavSidebar />}
    >
      <Alert variant={"warning"}>
        Expenses for go-lives, upgrades, consulting trips, etc.. should be
        entered up Trip expenses.
      </Alert>
      <Form>
        <Customer.Select
          value={customerId}
          label="Customers"
          onChange={(id) => setCustomerId(id)}
        />
        <Button
          onClick={() =>
            customerId && setRedirect(`/expenses/customers/${customerId}`)
          }
        >
          Next
        </Button>
      </Form>
    </Page>
  );
}
