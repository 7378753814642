import React from "react";
import { Route, Switch } from "react-router";

import Login from "./Login";
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";

export default function AuthRouting() {
  return (
    <Switch>
      <Route path={`/auth/logout`}>
        <Logout />
      </Route>
      <Route path={`/auth/login`}>
        <Login />
      </Route>
      <Route path={`/auth/reset-password`}>
        <ResetPassword />
      </Route>
    </Switch>
  );
}
