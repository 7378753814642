import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Customer from "../customers";
import UserLink from "../users/UserLink";
import ContractLink from "./ContractLink";

const DEFAULT_COMPONENTS = {
  Customer: ({ value }) => (
    <Link
      className="text-decoration-none"
      to={{ pathname: `/customers/${value}` }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </Link>
  ),
};

export default function ContractTable({
  contracts,
  contractLink = true,
  Components: OverrideComponents,
  layout = [
    "Customer",
    "Description",
    "Go Live Date",
    "New Install?",
    "Project Manager",
    "Projects Open / Total",
  ],
  ...props
}) {
  const Components = useMemo(
    () => ({ ...DEFAULT_COMPONENTS, ...OverrideComponents }),
    [OverrideComponents],
  );

  const columns = useMemo(
    () => [
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: contract } }) =>
          contractLink ? (
            <ContractLink customerId={contract?.customerId} id={contract?.id}>
              {value || contract?.hostname || contract?.id}
            </ContractLink>
          ) : (
            value
          ),
      },
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: Components.Customer,
      },
      {
        id: "Go Live Date",
        Header: "Go Live Date",
        accessor: "goliveDate",
        disableFilters: true,
        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "New Install?",
        Header: "New Install?",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isNewInstall }) => (isNewInstall ? "✅" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Salesperson",
        Header: "Salesperson",
        accessor: "salesperson",
        Cell: ({ value }) => <UserLink id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Project Manager",
        Header: "Project Manager",
        accessor: "projectManager",
        Cell: ({ value }) => <UserLink id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Projects Open / Total",
        Header: "Projects Open / Total",
        format: { alignment: { horizontal: "center" } },
        Cell: ({ row: { original: contract } }) =>
          contract?.openProjects +
          " / " +
          (contract?.closedProjects + contract?.openProjects),
      },
    ],
    [Components, contractLink],
  );
  return (
    <Table
      hasCount
      label="Contracts"
      columns={columns}
      layout={layout}
      data={contracts}
      {...props}
    />
  );
}
