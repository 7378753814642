import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "awsAccounts",
  type: "servers/awsAccounts",
  getState: (state) => state.servers.awsAccounts,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const awsAccountState = {
  api,
  actions,
  reducer,
  entity,
};

export default awsAccountState;
