import React from "react";

import { useJiraProjectsByCustomer } from "./hooks";
import JiraTable from "./JiraProjectTable";

const layout = ["customerId", "jiraKey", "description"];

export default function JiraProjectTableByCustomer({ customerId }) {
  const { jiraProjects, loading, reset } = useJiraProjectsByCustomer({
    search: { customerId },
    canGet: true,
  });
  return (
    <JiraTable
      jiraProjects={jiraProjects}
      layout={layout}
      isLoading={loading}
      onRefresh={() => reset({ customerId })}
      skeletonLines={8}
    />
  );
}
