import { isArray, reverse, sortBy, uniqBy } from "lodash";
import { useMemo } from "react";

import useUserActivity from "./useUserActivity";

export default function useUserActivityOptions({
  options,
  filter = () => true,
}) {
  const { entities: userActivity, loading, getMany } = useUserActivity();

  const activityOptions = useMemo(() => {
    if (!userActivity?.length) return [];

    return uniqBy(
      reverse(sortBy(userActivity, "dateTime")),
      (a) => a.source + a.key,
    )
      .filter(filter)
      .map((a) => {
        const id = parseInt(a.key);
        if (a.source === "CR")
          return {
            label: "CR: " + id + " " + a.moreInfo,
            title: "CR: " + id,
            info: a.moreInfo,
            value: { id: id, typeOf: "cr" },
          };
        else if (a.source === "PR")
          return {
            label: "Project: " + id + " " + a.moreInfo,
            title: "Project: " + id,
            info: a.moreInfo,
            value: { id: id, typeOf: "project" },
          };
        return null;
      })
      .filter(Boolean);
  }, [filter, userActivity]);

  if (isArray(options))
    options.push({
      label: "Based on Activity...",
      options: activityOptions,
    });
  return { options: activityOptions, loading, getMany };
}
