import { entityTableFilter } from "../../common/entity";
import { useStatuses } from "./hooks";

const StatusTableFilter = entityTableFilter({
  name: "crStatus",
  useEntities: useStatuses,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default StatusTableFilter;
