import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import AwsInstanceTypeLink from "./AwsInstanceTypeLink";

export default function AwsInstanceTypeTable({
  awsInstanceTypes,
  awsInstanceTypeLink = true,
  Components: OverrideComponents,
  layout = ["Id", "vCpus", "Memory", "Credits Per Hour"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: awsInstanceType } }) =>
          awsInstanceTypeLink ? (
            <AwsInstanceTypeLink id={awsInstanceType?.id}>
              {value || awsInstanceType?.id}
            </AwsInstanceTypeLink>
          ) : (
            value
          ),
      },
      {
        id: "vCpus",
        Header: "vCpus",
        accessor: "vCpus",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Memory",
        Header: "Memory (GB)",
        accessor: "memory",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Credits Per Hour",
        Header: "Credits Per Hour",
        accessor: "creditsPerHour",
        Filter: SelectColumnFilter,
        filter: "text",
      },
    ],
    [awsInstanceTypeLink],
  );
  return (
    <Table
      hasCount
      label="Aws Instance Types"
      columns={columns}
      layout={layout}
      data={awsInstanceTypes}
      {...props}
    />
  );
}
