import { bool, object, string } from "yup";

const branchSchema = object().shape({
  id: string()
    .label("Id")
    .required()
    .max(8)
    .transform((v) => `${v}`.toLowerCase()),
  description: string().label("Description").max(30).required(),
  customerId: string().label("Customer"),
  date: (string().label = "Code date"),
  liveDate: (string().label = "Live date"),
  branchType: string()
    .oneOf(["C", "R", "I"])
    .default("C")
    .required()
    .label("Branch Type"),
  inactive: bool().required().default(false),
});

export default branchSchema;
