import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import S3Bucket from "../../../servers/s3Buckets";
import S3BucketNavSidebar from "./S3BucketNavSidebar";

export default function S3BucketNew() {
  const { createOne } = S3Bucket.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainServers, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainServers || isAdmin))
    return (
      <Page
        title="New S3 Bucket"
        navSidebar={<S3BucketNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="New S3 Bucket" navSidebar={<S3BucketNavSidebar />}>
      <S3Bucket.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(s3Bucket) =>
          createOne(s3Bucket).then(({ id }) =>
            setRedirect(`/servers/s3-buckets/${id}`),
          )
        }
      />
    </Page>
  );
}
