import { useReusableProgram, useReusablePrograms } from "./hooks";
import ReusableProgramEditor from "./ReusableProgramEditor";
import ReusableProgramSelect from "./ReusableProgramSelect";
import ReusableProgramTable from "./ReusableProgramTable";
import ReusableProgramTableFilter from "./ReusableProgramTableFilter";
import reusableProgramState from "./state";

const ReusableProgram = {
  // Backend API
  api: reusableProgramState.api,
  // State
  actions: reusableProgramState.actions,
  selectors: reusableProgramState.entity.selectors,
  // Hooks
  useAll: useReusablePrograms,
  useOne: useReusableProgram,
  // Components
  Select: ReusableProgramSelect,
  TableFilter: ReusableProgramTableFilter,
  Table: ReusableProgramTable,
  Editor: ReusableProgramEditor,
};

export default ReusableProgram;
