import { number, object, string } from "yup";

const jiraProjectSchema = object().shape({
  id: string().label("Jira Project ID").required().max(6),
  jiraKey: string().label("Jira Prefix").required().max(6),
  description: string().label("Description").required(),
  customerId: number().label("Customer").required(),
  projectType: string().label("Type").required().max(1),
});

export default jiraProjectSchema;
