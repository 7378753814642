import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import {
  FaCheckCircle,
  FaCommentDots,
  FaEdit,
  FaRegComment,
  FaTimesCircle,
} from "react-icons/fa";
import { FiCircle } from "react-icons/fi";
import { MdInfo, MdWarning } from "react-icons/md";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import HelixLink from "../common/components/HelixLink";
import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  DateRangeColumnFilter,
  filterDateRange,
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Cr from "../crs";
import Project from "../projects";
import User from "../users";
import { useCodeReview } from "./hooks";
import { STATUSES } from "./statuses";

const defaultInitialSort = { id: "CR", desc: false };
const defaultLayout = [
  "Actions",
  "Last Reviewed By",
  "Assigned To",
  "CR",
  "CR Group",
  "Source Group",
  "Source Owner",
  "Revision",
  "Revision Date",
  "File",
  "Revision Comment",
  "Author",
  "CR Type",
  "CR Status",
];

const RevisionColumnMemo = React.memo(RevisionColumn);
const DateColumnMemo = React.memo(DateColum);
const SourceGroupColumnMemo = React.memo(SourceGroupColumn);
const OwnerEditableColumnMemo = React.memo(OwnerEditableColumn);
const CommentsColumnMemo = React.memo(CommentsColumn);
const ReviewedCommentsColumnMemo = React.memo(ReviewedCommentsColumn);
const CrColumnMemo = React.memo(CrColumn);
const ActionsColumnMemo = React.memo(ActionsColumn);
const UserLinkMemo = React.memo(User.Link);
const TableMemo = React.memo(Table);

export default function CodeReviewTable({
  data,
  layout = defaultLayout,
  onRefresh,
  initialSort = defaultInitialSort,
  disabled = false,
  loading = false,
  colorMethod = "last",
}) {
  const userId = useSelector((state) => state.auth.user);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showCommentsRecord, setShowCommentsRecord] = useState(null);
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [showSourceRecord, setShowSourceRecord] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showInfoApprovals, setShowInfoApprovals] = useState(null);
  const [showAssignedToModal, setShowAssignedToModal] = useState(false);
  const [showAssignedToRecord, setShowAssignedToRecord] = useState(null);

  const { updateOne } = useCodeReview({
    canGet: false,
  });

  const approveReview = useCallback(
    (codeReview) => {
      updateOne({ ...codeReview, myApprovalStatus: "P" });
    },
    [updateOne],
  );

  const saveAssignedTo = useCallback(
    (newUser) => {
      updateOne({
        ...showAssignedToRecord,
        assignedTo: newUser,
      });
      setShowAssignedToModal(false);
    },
    [showAssignedToRecord, updateOne],
  );

  const saveSourceCodeOwnership = useCallback(
    (newGroup, newOwner) => {
      updateOne({
        ...showSourceRecord,
        sourceGroup: newGroup,
        sourceOwner: newOwner,
      });
      setShowSourceModal(false);
    },
    [showSourceRecord, updateOne],
  );

  const saveCodeReviewAndComment = useCallback(
    (newComment) => {
      updateOne({ ...showCommentsRecord, myApprovalComment: newComment });
      setShowCommentsModal(false);
    },
    [showCommentsRecord, updateOne],
  );

  const clearReview = useCallback(
    (codeReview) => {
      updateOne({
        ...codeReview,
        myApprovalStatus: "O",
        myApprovalComment: "",
      });
    },
    [updateOne],
  );
  const warnReview = useCallback((codeReview) => {
    setShowCommentsModal(true);
    setShowCommentsRecord({ ...codeReview, myApprovalStatus: "W" });
  }, []);

  const failReview = useCallback((codeReview) => {
    setShowCommentsModal(true);
    setShowCommentsRecord({ ...codeReview, myApprovalStatus: "F" });
  }, []);

  const changeComments = useCallback((codeReview) => {
    setShowCommentsModal(true);
    setShowCommentsRecord({ ...codeReview });
  }, []);

  const showInfo = useCallback((approvals) => {
    setShowInfoModal(true);
    setShowInfoApprovals(approvals);
  }, []);

  const changeAssignedTo = useCallback((codeReview) => {
    setShowAssignedToModal(true);
    setShowAssignedToRecord({ ...codeReview });
  }, []);

  const changeSourceCodeOwnership = useCallback((codeReview) => {
    setShowSourceModal(true);
    setShowSourceRecord({ ...codeReview });
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "Actions",
        Header: "Actions",
        Cell: ({ row }) => (
          <ActionsColumnMemo
            codeReview={row?.original}
            approveReview={approveReview}
            failReview={failReview}
            warnReview={warnReview}
            clearReview={clearReview}
            changeComments={changeComments}
            showInfo={showInfo}
            disabled={disabled}
            userId={userId}
          />
        ),
      },
      {
        id: "CR Type",
        Header: "CR Type",
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: "crType",
        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
      {
        id: "CR Status",
        Header: "CR Status",
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: "crStatus",
        Cell: ({ value }) => <Cr.Status.Description id={value} />,
      },
      {
        id: "CR",
        Header: "CR",
        accessor: (row) => (row.crId === 0 ? " " : row.crId),
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ row, value }) => (
          <CrColumnMemo
            cr={value}
            group={row?.original.crGroup}
            type={row?.original.crType}
            status={row?.original.crStatus}
            synopsis={row?.original.synopsisNotes}
          />
        ),
      },
      {
        id: "Project",
        Header: "Project",
        accessor: (row) => (row.projectId === 0 ? "N/A" : row.projectId),
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <Project.Link projectId={value} />,
      },
      {
        id: "Revision",
        Header: "Revision",
        accessor: "revision",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row }) => (
          <RevisionColumnMemo
            date={row?.original.revisionDate}
            comment={row?.original.revisionComment}
            authorName={row?.original.authorName}
            revisionType={row?.original?.revisionType}
            revision={value}
          />
        ),
      },
      {
        id: "Revision Date",
        Header: "Revision Date",
        accessor: "revisionDate",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",

        Cell: ({ value }) => <DateColumnMemo date={value} />,
      },
      {
        id: "Author",
        Header: "Author",
        accessor: "author",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <UserLinkMemo id={value} showId={false} />,
      },
      {
        id: "Revision Comment",
        Header: "Revision Comment",
        accessor: "revisionComment",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <CommentsColumnMemo comment={value} />,
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsisNotes",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <CommentsColumnMemo comment={value} />,
      },
      {
        id: "CR Group",
        Header: "CR Group",
        accessor: "crGroup",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        id: "Source Group",
        Header: "Source Group",
        accessor: "sourceGroup",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ row, value }) => (
          <SourceGroupColumnMemo
            codeReview={row?.original}
            group={value}
            onClick={changeSourceCodeOwnership}
            disabled={disabled}
          />
        ),
      },
      {
        id: "Source Owner",
        Header: "Source Owner",
        accessor: "sourceOwner",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ row, value }) => (
          <OwnerEditableColumnMemo
            codeReview={row?.original}
            owner={value}
            onClick={changeSourceCodeOwnership}
            disabled={disabled}
          />
        ),
      },
      {
        id: "File",
        Header: "File",
        accessor: "filename",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Full Path",
        Header: "Full Path",
        accessor: "filePath",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Assigned To",
        Header: "Assigned To",
        accessor: "assignedTo",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ row, value }) => (
          <OwnerEditableColumnMemo
            codeReview={row?.original}
            owner={value}
            onClick={changeAssignedTo}
            disabled={disabled}
          />
        ),
      },
      {
        id: "Reviewed Comments",
        Header: "Reviewed Comments",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ row }) => (
          <ReviewedCommentsColumnMemo
            codeReview={row?.original}
            userId={userId}
          />
        ),
      },
      {
        id: "Last Reviewed By",
        Header: "Last Reviewed By",
        accessor: "lastApprover",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <UserLinkMemo id={value} showId={false} />,
      },
      {
        id: "Last Reviewed Date",
        Header: "Last Reviewed Date",
        accessor: "approvalDate",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",
        Cell: ({ value }) => <DateColumnMemo date={value} />,
      },
      {
        id: "Last Review Status",
        Header: "Last Review Status",
        accessor: (record) =>
          STATUSES[record?.lastApprovalStatus]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    [
      approveReview,
      failReview,
      warnReview,
      clearReview,
      changeComments,
      showInfo,
      disabled,
      userId,
      changeSourceCodeOwnership,
      changeAssignedTo,
    ],
  );

  const closeInfo = () => {
    setShowInfoModal(false);
    setShowInfoApprovals(null);
  };

  const cancelComments = () => {
    setShowCommentsModal(false);
    setShowCommentsRecord(null);
  };
  const cancelSource = () => {
    setShowSourceModal(false);
    setShowSourceRecord(null);
  };
  const cancelAssignedTo = () => {
    setShowAssignedToModal(false);
    setShowSourceRecord(null);
  };

  const getRowColors = useCallback(
    (row) => {
      if (row.isGrouped) return {};
      const statusValue =
        colorMethod === "last"
          ? row?.original.lastApprovalStatus
          : row?.original.myApprovalStatus;

      switch (statusValue) {
        case "P":
          return row?.original.isExtraCommit === true
            ? { className: "table-success font-italic font-weight-light" }
            : { className: "table-success" };
        case "W":
          return row?.original.isExtraCommit === true
            ? { className: "table-warning font-italic font-weight-light" }
            : { className: "table-warning" };
        case "F":
          return row?.original.isExtraCommit === true
            ? { className: "table-danger font-italic font-weight-light" }
            : { className: "table-danger" };
        default:
          return row?.original.isExtraCommit === true
            ? { className: "font-italic font-weight-light" }
            : {};
      }
    },
    [colorMethod],
  );

  return (
    <>
      {showInfoModal ? (
        <InfoModal approvals={showInfoApprovals} onCancel={closeInfo} />
      ) : null}
      {showCommentsModal ? (
        <CommentsModal
          record={showCommentsRecord}
          onCancel={cancelComments}
          onSave={saveCodeReviewAndComment}
        />
      ) : null}
      {showSourceModal ? (
        <SourceModal
          record={showSourceRecord}
          onCancel={cancelSource}
          onSave={saveSourceCodeOwnership}
        />
      ) : null}
      {showAssignedToModal ? (
        <AssignedToModal
          record={showAssignedToRecord}
          onCancel={cancelAssignedTo}
          onSave={saveAssignedTo}
        />
      ) : null}
      <TableMemo
        hasCount
        label="Code Reviews"
        columns={columns}
        layout={layout}
        data={data}
        getRowProps={getRowColors}
        onRefresh={onRefresh}
        initialSort={initialSort}
        isLoading={loading}
      />
    </>
  );
}

function SourceGroupColumn({ group, codeReview, onClick, disabled }) {
  return (
    <div style={{ minWidth: "100px" }}>
      <User.Group.Description id={group} />
      {!disabled ? (
        <Button
          variant="link"
          onClick={() => onClick(codeReview)}
          className="px-2"
        >
          <FaEdit className="p-0" size="15px" />
        </Button>
      ) : null}
    </div>
  );
}

function OwnerEditableColumn({ owner, codeReview, onClick, disabled }) {
  return (
    <div style={{ minWidth: "70px" }}>
      <User.Link id={owner} showId={true} />
      {!disabled ? (
        <Button
          variant="link"
          onClick={() => onClick(codeReview)}
          className="px-2"
        >
          <FaEdit className="p-0" size="15px" />
        </Button>
      ) : null}
    </div>
  );
}

function CommentsColumn({ comment }) {
  return <div style={{ minWidth: "300px" }}>{comment}</div>;
}

function ReviewedCommentsColumn(codeReview, userId) {
  let approvals = getAllApprovals(codeReview, userId);
  let comments = "";

  approvals.forEach((e) => {
    if (e.comment.trim() !== "") {
      comments = comments + "/n" + e.approver + "/n" + e.comment.trim();
    }
  });

  return <div style={{ minWidth: "300px" }}>{comments}</div>;
}

function CrColumn({ cr, group, type, synopsis, status }) {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover-postioned-right" style={{ minWidth: "400px" }}>
          <Popover.Title as="h3">
            <Row className="justify-content-between px-3">
              <div>
                {"CR "}
                {cr}
              </div>
              <div>
                <User.Group.Description id={group} />
              </div>
            </Row>
          </Popover.Title>
          <Popover.Content>{synopsis}</Popover.Content>
          <Popover.Title as="h4">
            <Row className="justify-content-between px-3">
              <div>
                <Project.Type.Description id={type} />
              </div>
              <div>
                <Cr.Status.Description id={status} />
              </div>
            </Row>
          </Popover.Title>
        </Popover>
      }
    >
      <Button variant="link">
        <Cr.Link id={cr} />
      </Button>
    </OverlayTrigger>
  );
}

function DateColum({ date }) {
  return <>{_date.display(_date.fromStamp(date))}</>;
}

function RevisionColumn({ revision, revisionType, comment, date, authorName }) {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover-basic" style={{ minWidth: "400px" }}>
          <Popover.Title as="h3">
            <Row className="justify-content-between px-3">
              <div>{"Revision: " + revision}</div>
              <div>
                <DateColumnMemo date={date} />
              </div>
            </Row>
          </Popover.Title>
          <Popover.Content>{comment}</Popover.Content>
          <Popover.Title as="h4">{authorName}</Popover.Title>
        </Popover>
      }
    >
      <HelixLink revisionType={revisionType} revision={revision} />
    </OverlayTrigger>
  );
}

function getAllApprovals(codeReview, currentUser) {
  let approvals = [];
  if (codeReview?.myApprovalDate !== "") {
    approvals.push({
      approver: currentUser,
      date: codeReview.myApprovalDate,
      status: codeReview.myApprovalStatus,
      comment: codeReview.myApprovalComment,
    });
  }
  let otherApprovals = codeReview ? [...codeReview.otherApprovals] : [];
  otherApprovals.sort((a, b) => (a?.date > b?.date ? 1 : -1));

  return approvals.concat(otherApprovals);
}

function ActionsColumn({
  codeReview,
  approveReview,
  clearReview,
  changeComments,
  showInfo,
  warnReview,
  failReview,
  disabled,
  userId,
}) {
  const iHaveComments = codeReview?.myApprovalComment.trim() !== "";
  const approvals = getAllApprovals(codeReview, userId);

  return (
    <>
      <ButtonGroup>
        <Button
          variant="success"
          onClick={() => approveReview(codeReview)}
          className="px-2"
          disabled={disabled}
        >
          <FaCheckCircle className="p-0" size="20px" />
        </Button>

        <Button
          variant="light"
          onClick={() => clearReview(codeReview)}
          className="px-2"
          disabled={disabled}
        >
          <FiCircle className="p-0" size="20px" />
        </Button>
        <Button
          variant="secondary"
          onClick={() => changeComments(codeReview)}
          className="px-2"
          disabled={disabled}
        >
          {!iHaveComments ? (
            <FaRegComment className="p-0" size="20px" />
          ) : (
            <FaCommentDots className="p-0" size="20px" />
          )}
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        <Button
          variant="warning"
          onClick={() => warnReview(codeReview)}
          className="px-2"
          disabled={disabled}
        >
          <MdWarning className="p-0" size="20px" />
        </Button>

        <Button
          variant="danger"
          onClick={() => failReview(codeReview)}
          className="px-2"
          disabled={disabled}
        >
          <FaTimesCircle className="p-0" size="20px" />
        </Button>
        {approvals.length > 0 ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="popover-basic" style={{ minWidth: "400px" }}>
                <Popover.Title as="h3">
                  <Row className="justify-content-between px-3">
                    <div>{"Approval Info: "}</div>
                  </Row>
                </Popover.Title>
                {approvals.map((x) => (
                  <React.Fragment key={x.approver}>
                    <Popover.Title as="h4">
                      <Row
                        className="justify-content-between px-3"
                        key={x.approver}
                      >
                        <div>
                          <User.Link id={x?.approver} showId={false} />
                        </div>
                        <div>
                          <DateColumnMemo date={x.date} />
                        </div>
                        <div>{STATUSES[x.status]?.description || ""}</div>
                      </Row>
                    </Popover.Title>
                    <Popover.Content>{x.comment}</Popover.Content>
                  </React.Fragment>
                ))}
              </Popover>
            }
          >
            <Button
              variant={
                codeReview.otherApprovals.length > 0 ? "primary" : "secondary"
              }
              onClick={() => {
                showInfo(approvals);
              }}
              className="px-2"
            >
              {codeReview.otherApprovals.length > 0 ? (
                "*" + approvals.length + "*"
              ) : (
                <MdInfo className="p-0" size="20px" />
              )}
            </Button>
          </OverlayTrigger>
        ) : null}
      </ButtonGroup>
    </>
  );
}

//
// Separate function desired for performance reasons.
// It was spending way too much time deciding to re-render the table or not
// as a user types in comments.
//
function InfoModal({ approvals, onCancel }) {
  return (
    <Modal size="md" show={true} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Code Reviews</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {approvals.map((x, index) => (
          <>
            {index > 0 ? <hr></hr> : null}
            <Row className={STATUSES[x.status]?.variant + " py-3"}>
              <Col>
                <Row className="justify-content-between px-3" key={x.approver}>
                  <div>
                    <User.Link id={x?.approver} showId={false} />
                  </div>
                  <div>
                    <DateColumnMemo date={x.date} />
                  </div>
                  <div>{STATUSES[x.status]?.description || ""}</div>
                  <br></br>
                </Row>
                {x.comment.trim() != "" ? (
                  <>
                    <Row key={x.approver}>
                      <Col>
                        <TextareaAutosize
                          type="text"
                          as="textarea"
                          className="form-control"
                          minRows={3}
                          value={x.comment}
                          maxLength={500}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          </>
        ))}
      </Modal.Body>
    </Modal>
  );
}

//
// Separate function desired for performance reasons.
// It was spending way too much time deciding to re-render the table or not
// as a user types in comments.
//
function CommentsModal({ record, onCancel, onSave }) {
  const [modalComment, setModalComment] = useState(record.myApprovalComment);

  const variant = STATUSES[record?.myApprovalStatus].variant;

  return (
    <Modal size="md" show={true} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton className={variant}>
        <Modal.Title>
          Code Review Comment
          <br />
          <h5>{record.filename}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={variant}>
        <Row>
          <Col>
            <TextareaAutosize
              type="text"
              as="textarea"
              className="form-control"
              minRows={3}
              onChange={(e) => setModalComment(e.target.value)}
              value={modalComment}
              maxLength={500}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={variant}>
        <Button onClick={() => onSave(modalComment)} variant="primary">
          Save
        </Button>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//
// Separate function desired for performance reasons.
// It was spending way too much time deciding to re-render the table or not
// as a user types in comments.
//
function SourceModal({ record, onCancel, onSave }) {
  const [modalGroup, setModalGroup] = useState(record.sourceGroup);
  const [modalOwner, setModalOwner] = useState(record.sourceOwner);

  const variant = STATUSES[record?.myApprovalStatus].variant;

  return (
    <Modal size="md" show={true} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton className={variant}>
        <Modal.Title>
          Source Code Ownership
          <br />
          <h5>{record.filename}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={variant}>
        <Row>
          <Col>
            <User.Group.Select
              id="groupId"
              value={modalGroup}
              onChange={(value) => setModalGroup(value)}
              pseudo={true}
              isClearable
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <User.Select
              id="ownerId"
              value={modalOwner}
              onChange={(value) => setModalOwner(value)}
              pseudo={false}
              isClearable
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={variant}>
        <Button
          onClick={() => onSave(modalGroup, modalOwner)}
          variant="primary"
        >
          Save
        </Button>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//
// Separate function desired for performance reasons.
// It was spending way too much time deciding to re-render the table or not
// as a user types in comments.
//
function AssignedToModal({ record, onCancel, onSave }) {
  const [modalAssignedTo, setModalAssignedTo] = useState(record.assignedTo);

  return (
    <Modal size="md" show={true} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Assigned To Reviewer
          <br />
          <h5>{record.filename}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <User.Select
              id="assignedTo"
              value={modalAssignedTo}
              onChange={setModalAssignedTo}
              pseudo={false}
              canPerformCodeReview={true}
              isClearable
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSave(modalAssignedTo)} variant="primary">
          Save
        </Button>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
