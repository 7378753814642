import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  DateRangeColumnFilter,
  filterDateRange,
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import { useCustomers } from "./hooks";

export default function CustomerTable({
  filter,
  initialSort,
  layout = [
    "id",
    "name",
    "championId",
    "alias",
    "liveDate",
    "lastCodeDate",
    "lastUpgradeDate",
    "engineeringResponsibilty",
  ],
}) {
  const { customers, loading, reset } = useCustomers();

  const tableData = React.useMemo(
    () =>
      customers.filter((customer) => {
        if (customer.inactive === filter.active) return false;

        if (filter.modules?.length > 0) {
          let found = false;
          for (let i = 0; i < customer.modules.length; i++) {
            if (filter.modules.includes(customer.modules[i].id)) {
              found = true;
              break;
            }
          }
          if (found === false) return false;
        }

        if (filter.organizations?.length > 0) {
          let found = false;
          for (let i = 0; i < customer.produceOrganizations.length; i++) {
            if (
              filter.organizations.includes(customer.produceOrganizations[i].id)
            ) {
              found = true;
              break;
            }
          }
          if (found === false) return false;
        }
        return true;
      }),
    [customers, filter],
  );

  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "id",
        id: "id",
        disableFilters: true,

        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/customers/${value}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        id: "name",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Champion",
        accessor: "championId",
        id: "championId",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Warranty Date",
        accessor: "warrantyExpirationDate",
        id: "warrantyExpirationDate",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
      },
      {
        Header: "Live Date",
        accessor: "liveDate",
        id: "liveDate",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
      },
      {
        Header: "Code Date",
        accessor: (customer) =>
          customer?.futureCodeDate || customer?.lastCodeDate,
        id: "lastCodeDate",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",

        Cell: ({ value, row: { original: customer } }) => {
          const a = value === customer?.futureCodeDate ? "*" : "";
          return (
            <span>
              {value
                ? _date.display(_date.fromStamp(value?.substring(0, 8))) + a
                : null}
            </span>
          );
        },
      },
      {
        Header: "Upgrade Date",
        accessor: (customer) =>
          customer?.futureUpgradeDate ||
          customer?.lastUpgradeDate ||
          customer?.liveDate,
        id: "lastUpgradeDate",
        filter: filterDateRange,
        Filter: DateRangeColumnFilter,
        sortType: "custom",

        Cell: ({ value, row: { original: customer } }) => {
          const a = value === customer?.futureUpgradeDate ? "*" : "";
          return (
            <span>
              {value
                ? _date.display(_date.fromStamp(value?.substring(0, 8))) + a
                : null}
            </span>
          );
        },
      },
      {
        Header: "Engineering",
        accessor: "engineeringResponsibilty",
        id: "engineeringResponsibilty",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Alias",
        accessor: "alias",
        id: "alias",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "organizations",
        Header: "Organizations",
        accessor: (customer) =>
          customer?.produceOrganizations?.reduce(
            (s, org) => `${s ? `${s}, ` : ""}${org.description}`,
            "",
          ),
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "modules",
        Header: "Modules",
        accessor: (customer) =>
          customer?.modules?.reduce(
            (s, mod) => `${s ? `${s}, ` : ""}${mod.description}`,
            "",
          ),
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "specialWorkConditions",
        Header: "Special Work Conditions",
        accessor: "specialWorkConditions",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "website",
        Header: "Website",
        accessor: "website",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Customers"
      columns={columns}
      data={tableData}
      isLoading={loading}
      onRefresh={reset}
      initialSort={initialSort}
      layout={layout}
      getCellProps={({
        column: { id },
        value,
        row: { original: customer },
      }) => {
        if (!value) return null;
        if (id === "lastCodeDate") {
          return value === customer?.futureCodeDate
            ? { className: "table-info" }
            : null;
        }
        if (id === "lastUpgradeDate") {
          return value === customer?.futureUpgradeDate
            ? { className: "table-info" }
            : null;
        }
      }}
    />
  );
}
