import { createEntityHooks } from "../../common/entity";
import { notificationsActions, notificationsSelectors } from "./state";

const getNotificationsState = (state) => state.activity.notifications;

const { useEntities: useNotifications } = createEntityHooks({
  entity: {
    name: "notification",
    selectors: notificationsSelectors,
    getState: getNotificationsState,
  },
  actions: {
    getMany: notificationsActions.getNotifications,
    reset: notificationsActions.clearNotifications,
  },
  expire: {
    key: "minutes",
    value: 3,
  },
});

export default useNotifications;
