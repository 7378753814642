import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import UserTableFilter from "../UserTableFilter";
import PseudoLink from "./PseudoLink";

export default function PseudoTable({
  pseudos,
  pseudoLink = true,
  Components: OverrideComponents,
  layout = ["Id", "Name", "Default Group", "Owner"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Id",
        Header: "Id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: pseudo } }) =>
          pseudoLink ? (
            <PseudoLink id={pseudo?.id}>{value || pseudo?.id}</PseudoLink>
          ) : (
            value
          ),
      },
      {
        id: "Name",
        Header: "Name",
        accessor: "name",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Owner",
        Header: "Owner",
        accessor: "owner",
        Filter: UserTableFilter,
        filter: "text",
        Cell: ({ value }) => (
          <Link className="text-reset" to={{ pathname: `/users/${value}` }}>
            {value}
          </Link>
        ),
      },
      {
        id: "Default Group",
        Header: "Group",
        accessor: "defaultGroup",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    [pseudoLink],
  );
  return (
    <Table
      hasCount
      label="Pseudos"
      columns={columns}
      layout={layout}
      data={pseudos}
      {...props}
    />
  );
}
