import _ from "lodash";
import React from "react";

import FieldSelect from "../common/fields/FieldSelect";
import parseIntId from "../common/functions/parseIntId";
import { useContactsByCustomer } from "./hooks";

const ContactSelectByCustomer = React.forwardRef(
  (
    {
      value,
      isMulti = false,
      customerId,
      placeholder = "Select Contact...",
      ...props
    },
    ref,
  ) => {
    const { contacts, loading } = useContactsByCustomer({
      search: { customerId },
      canGet: !!customerId,
    });

    const _options = _.sortBy(
      contacts
        .filter((c) => c.inactive === false || c.id === value)
        .map((f) => {
          return { label: f.name, value: parseIntId(f.id) };
        }),

      "label",
    );

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = _options.filter((o) => value.includes(o.value));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    return (
      <FieldSelect
        ref={ref}
        isLoading={loading}
        isSearchable={true}
        value={valueOption}
        options={_options}
        isMulti={isMulti}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

export default ContactSelectByCustomer;
