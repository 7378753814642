import moment from "moment";
import React from "react";

import { _date } from "../../common/functions/dates";
import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function TimeNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to={"/time?date=" + _date.urlParams(moment())}>
        Daily Time
      </NavSidebarItem>
      <NavSidebarItem to={"/time/stats"}>Statistics</NavSidebarItem>
      <NavSidebarItem to={"/time/unassigned"}>Unassigned</NavSidebarItem>
      <NavSidebarItem to={"/time/internal"}>Internal</NavSidebarItem>
      {children}
    </NavSidebar>
  );
}
