import _ from "lodash";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Filter from "./filters";
import crState from "./state";
import TableLayout from "./tableLayouts";

export default function useCrTableSettings({
  crFilterId,
  crTableLayoutId,
  canSet = false,
  canGet = false,
}) {
  const crTableSettings = useSelector(
    (state) => state.crs.crs.crTableSettings,
    shallowEqual,
  );

  const { filter, layout, loading } = useFilterAndLayout({
    crFilterId,
    crTableLayoutId,
    canGet,
  });

  const dispatch = useDispatch();

  const setCrTableSettings = useCallback(
    (settings) => dispatch(crState.actions.setCrTableSettings(settings)),
    [dispatch],
  );

  const canSetCrFilter = _.isEmpty(crTableSettings?.filter);
  const canSetCrTableLayout = _.isEmpty(crTableSettings?.layout);
  useEffect(() => {
    if (!canSet) return;
    if (filter && canSetCrFilter) {
      setCrTableSettings({ filter });
    }
    if (layout && canSetCrTableLayout) {
      setCrTableSettings({ layout });
    }
  }, [
    canSet,
    canSetCrFilter,
    canSetCrTableLayout,
    filter,
    layout,
    setCrTableSettings,
  ]);

  return {
    filter,
    layout,
    crTableSettings,
    loading,
    setCrTableSettings,
  };
}

function useFilterAndLayout({ crFilterId, crTableLayoutId, canGet = false }) {
  const { filter, loading: loadingFilter } = Filter.useOne({
    id: crFilterId,
    canGet,
  });

  const _crTableLayoutId = crTableLayoutId || filter?.layoutId;

  const { tableLayout: layout, loading: loadingTableLayout } =
    TableLayout.useOne({
      id: _crTableLayoutId,
    });

  return {
    filter,
    layout,
    loading: loadingFilter || loadingTableLayout || false,
  };
}
