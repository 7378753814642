import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import ThirdPartyLicenseNavSidebar from "./ThirdPartyLicenseNavSidebar";

export default function ThirdPartyLicenseShow() {
  const { createOne } = Customer.ThirdPartyLicenses.useOne();
  const customerId = useSearchParams("customerId");
  const [, setRedirect] = useRedirect();
  return (
    <Page
      title="New Customer Third Party License"
      navSidebar={<ThirdPartyLicenseNavSidebar customerId={customerId} />}
    >
      <Customer.ThirdPartyLicenses.Editor
        isNew
        values={{ customerId: customerId ? parseInt(customerId) : "" }}
        onSubmit={(license) =>
          createOne(license).then(({ id }) =>
            customerId
              ? setRedirect(
                  `/customers/third-party-licenses?customer=${customerId}`,
                )
              : setRedirect(`/customers/third-party-licenses/${id}`),
          )
        }
      />
    </Page>
  );
}
