import { bool, number, object, string } from "yup";

const awsInstanceTypeSchema = object().shape({
  vCpu: number().label("vCPUs").required().min(0),
  memory: number().label("Memory in GB").min(0),
  creditsPerHour: number().label("Credits per hours").min(0),
  id: string().label("Id").required().max(20),
  isInactive: bool().required().default(false),
});

export default awsInstanceTypeSchema;
