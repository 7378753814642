import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import SourceCodeIndex from "./SourceCodeIndex";
import SourceCodeShow from "./SourceCodeShow";

export default function SourceCodesPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <SourceCodeShow />
      </Route>
      <Route exact path={`${path}`}>
        <SourceCodeIndex />
      </Route>
    </Switch>
  );
}
