import { createEntityHooks } from "../../common/entity";
import state from "./state";

export const {
  useEntities: useNotificationGroups,
  useEntity: useNotificationGroup,
} = createEntityHooks({
  entity: state.entity,
  actions: state.actions,
});
