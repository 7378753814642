import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Page from "../../common/pages/Page";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseIndex() {
  return (
    <Page title={`Expenses`} navSidebar={<ExpenseIndexNavSidebar />}>
      <Row>
        <Col xs="auto">
          <ListGroup>
            <LinkContainer to="/expenses/trips">
              <ListGroup.Item action>Enter Trip Expenses</ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/expenses/customers">
              <ListGroup.Item action>Enter Customer Expenses</ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/expenses/categories">
              <ListGroup.Item action>Enter Other Expenses</ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/expenses/search">
              <ListGroup.Item action>Search</ListGroup.Item>
            </LinkContainer>
          </ListGroup>
        </Col>
      </Row>
    </Page>
  );
}
