import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "awsInstanceTypes",
  type: "servers/awsInstanceTypes",
  getState: (state) => state.servers.awsInstanceTypes,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const awsInstanceTypeState = {
  api,
  actions,
  reducer,
  entity,
};

export default awsInstanceTypeState;
