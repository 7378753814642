import { findKey, isNull } from "lodash";

import shark from "../../../apis/shark";
import sharkColumns from "../../tableLayouts/sharkColumns";
import Favorite from "../favorites";

const buildCrFilter = (f) => {
  const {
    id,
    layoutId,
    sortBy: sortByOriginal = [],
    description,
    owner,
    public: isPublic,
    ...filter
  } = f;
  const sortBy = sortByOriginal.map((s) => sharkColumns[s]);
  return {
    id,
    description,
    owner,
    public: isPublic,
    filter: { ...filter, folderId: "CURRENT" },
    layoutId,
    sortBy,
  };
};

const deconstructCrFilter = (f) => {
  const {
    filter: originalFilter,
    sortBy: sortByOriginal = [],
    ...crFilter
  } = f;

  let filter = { ...originalFilter };
  // Remove null values
  Object.entries(filter).forEach(([key, value]) => {
    if (isNull(value)) delete filter[key];
  });

  const sortBy = sortByOriginal.map((s) =>
    findKey(sharkColumns, (k) => k === s),
  );
  return { ...crFilter, ...filter, sortBy };
};

function getFiltersAPI() {
  return shark.post({ command: "getCrFilters" }).then((r) => {
    return { data: r.responseData.crFilters.map(buildCrFilter) };
  });
}

function getFilterAPI({ id }) {
  return shark.post({ command: "getCrFilter", id: id }).then((r) => {
    return { data: buildCrFilter(r.responseData) };
  });
}

function createFilterAPI(crFilter) {
  return shark
    .post({ command: "createCrFilter", filter: deconstructCrFilter(crFilter) })
    .then((r) => {
      return { data: buildCrFilter(r.responseData) };
    });
}

function updateFilterAPI(crFilter) {
  return shark
    .post({ command: "updateCrFilter", filter: deconstructCrFilter(crFilter) })
    .then((r) => {
      return { data: buildCrFilter(r.responseData) };
    });
}

function deleteFilterAPI({ id }, { dispatch }) {
  return shark.post({ command: "deleteCrFilter", id }).then(() => {
    dispatch(Favorite.actions.deleteOne({ id, shouldSendRequest: false }));
    return { data: id };
  });
}

export const api = {
  getMany: getFiltersAPI,
  getOne: getFilterAPI,
  createOne: createFilterAPI,
  updateOne: updateFilterAPI,
  deleteOne: deleteFilterAPI,
};
