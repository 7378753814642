import { combineReducers } from "@reduxjs/toolkit";

import reducer from "./reducers";

export { actions as approvalsActions } from "./slices";
export { selectors as approvalsSelectors } from "./slices";

const approvalsReducer = combineReducers({
  approvals: reducer,
});
export default approvalsReducer;
