import React from "react";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import JiraProject from "../../../customers/jiraProjects";
import JiraProjectNavSidebar from "./JiraProjectNavSidebar";

export default function JiraProjectShow() {
  const id = parseInt(useParams().id);
  const { jiraProject, updateOne, loading } = JiraProject.useOne({
    id,
    canGet: true,
  });

  const title = `Jira${
    jiraProject?.description ? ": " + jiraProject.description : ""
  }`;
  return (
    <Page
      title={title}
      lead={
        loading
          ? "Loading..."
          : !jiraProject
          ? "Jira not found"
          : jiraProject?.jiraKey
      }
      navSidebar={<JiraProjectNavSidebar />}
    >
      {jiraProject ? (
        <JiraProject.Editor
          values={jiraProject}
          onSubmit={(jiraProject) => updateOne(jiraProject)}
          isDisabled={loading}
        />
      ) : null}
    </Page>
  );
}
