import React from "react";
import { useParams } from "react-router";

import CrLinkWithContextMenu from "../../common/components/CrLinkWithContextMenu";
import Page from "../../common/pages/Page";
import Cr from "../../crs";
import Customer from "../../customers";
import CustomerShowNavSidebar from "./CustomerShowNavSidebar";

const CrTableComponents = {
  CrLink: CrLinkWithContextMenu,
};

const defLayout = [
  "createDate",
  "id",
  "project",
  "group",
  "owner",
  "status",
  "synopsis",
];

const defSort = [{ id: "createDate", desc: true }, { id: "id" }];

export default function CustomerShowCrs() {
  const id = parseInt(useParams().id);
  const { data: customer } = Customer.useOne({ id });
  const { crs, loading, hasMore, getMore, reset } = Cr.useSearch({
    filter: {
      folderId: "CURRENT",
      customers: [id],
      customersInclude: true,
    },
  });

  return (
    <Page
      title="Customer CRs"
      navSidebar={<CustomerShowNavSidebar id={id} />}
      lead={customer?.name}
    >
      <Cr.Table
        crs={crs}
        isLoading={loading}
        hasMore={hasMore}
        getMore={getMore}
        onRefresh={reset}
        layout={defLayout}
        initialSort={defSort}
        Components={CrTableComponents}
      />
    </Page>
  );
}
