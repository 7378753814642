import _ from "lodash";
import React from "react";

/**
 * @typedef {Object} Opts
 * @property {string} name Entity name
 * @property {function} useEntity Hook that returns entity based on id
 * @property {function} label Callback to determine entity label
 */

/**
 * Create Description component based on entity
 * @param {Opts} opts Options
 */
export function entityDescription({
  name,
  useEntity,
  label = (e) => e.description,
  getHookArgs = ({ id, value }) => ({ id: id || value }),
}) {
  const entityDescription = React.memo((props) => {
    const { entity } = useEntity(getHookArgs(props));
    if (!entity) return null;

    return <>{label(entity)}</>;
  });
  entityDescription.displayName = `${_.upperFirst(name)}Description`;
  return entityDescription;
}

export default entityDescription;
