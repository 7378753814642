import { combineReducers } from "@reduxjs/toolkit";

import groupState from "../groups/state";
import notificationGroupState from "../notificationGroups/state";
import pseudoState from "../pseudo/state";
import teamState from "../teams/state";
import userGroupingsState from "../userGroupings/state";
import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const userReducer = combineReducers({
  users: reducer,
  pseudo: pseudoState.reducer,
  groups: groupState.reducer,
  teams: teamState.reducer,
  notificationGroups: notificationGroupState.reducer,
  userGroupings: userGroupingsState.reducer,
});

const userState = {
  api,
  entity,
  actions,
  reducer: userReducer,
};

export default userState;
