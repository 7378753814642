import Branch from "./branches";
import CodeTest from "./codeTests";
import ReusableProgram from "./reusablePrograms";
import SourceCode from "./sourceCodes";

const Dbc = {
  // Backend API
  //  api: crState.api,
  // State
  //  actions: crState.actions,
  //  selectors: crState.entity.selectors,
  // Hooks
  // Components
  // Related Entity Objects
  Branch: Branch,
  SourceCode: SourceCode,
  ReusableProgram: ReusableProgram,
  CodeTest: CodeTest,
};

export default Dbc;
