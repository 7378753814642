import { combineReducers } from "@reduxjs/toolkit";

import taxProductCategoriesState from "../taxProductCategories/state";
import taxProductsState from "../taxProducts/state";

const invoiceReducer = combineReducers({
  taxProductCategories: taxProductCategoriesState.reducer,
  taxProducts: taxProductsState.reducer,
});

const invoiceState = {
  reducer: invoiceReducer,
};

export default invoiceState;
