import { useEffect, useRef } from "react";

export default function useInterval(callback, ms, deps = []) {
  const id = useRef(null);

  useEffect(() => {
    if (ms !== 0 && !!callback) {
      if (id.current) clearInterval(id.current);
      id.current = setInterval(callback, ms);
      return () => {
        clearInterval(id.current);
      };
    } else id.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, ...deps]);
}
