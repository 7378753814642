import shark from "../../../apis/shark";

function getContractProductsAPI() {
  const payload = {
    command: "getContractProducts",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.contractProducts,
  }));
}

function getContractProductAPI({ id }) {
  const payload = {
    command: "getContractProduct",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createContractProductAPI(contractProduct) {
  const payload = {
    command: "createContractProduct",
    ...contractProduct,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateContractProductAPI(contractProduct) {
  const payload = {
    command: "updateContractProduct",
    ...contractProduct,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getContractProductsAPI,
  getOne: getContractProductAPI,
  createOne: createContractProductAPI,
  updateOne: updateContractProductAPI,
};
