import shark from "../../../apis/shark";

function getPseudosAPI({ customerId } = {}) {
  const args = { ...(customerId ? { customerId: customerId } : null) };
  const payload = {
    command: "getPseudoUsers",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.pseudoUsers,
    searchArgs: args,
  }));
}

function getPseudoAPI({ id }) {
  const payload = {
    command: "getPseudoUser",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createPseudoAPI(pseudoUser) {
  const payload = {
    command: "createPseudoUser",
    ...pseudoUser,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updatePseudoAPI(pseudoUser) {
  const payload = {
    command: "updatePseudoUser",
    ...pseudoUser,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getPseudosAPI,
  getOne: getPseudoAPI,
  createOne: createPseudoAPI,
  updateOne: updatePseudoAPI,
};
