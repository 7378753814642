import _ from "lodash";
import React, { useEffect } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";

import FieldInput from "../common/fields/FieldInput";
import { Controller, useForms } from "../common/forms";
import Project from "../projects";
import User, { useUserRate } from "../users";
import crSchema from "./crSchema";

export default function CrEstimateEditor({ cr, show, onHide, update }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit CR Estimate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EstimateForm cr={cr} onHide={onHide} update={update} />
      </Modal.Body>
    </Modal>
  );
}

function EstimateForm({ cr, update, onHide }) {
  const {
    control,
    trigger,
    setValue,
    watch,
    submitForm,
    formState: { errors },
  } = useForms({
    schema: { schema: crSchema },
    defaultValues: cr,
    onSubmit: (modifiedCr) => {
      update(modifiedCr);
      onHide();
    },
  });

  const crId = cr.id;
  const projectId = cr.projectId;
  const { project = {} } = Project.useOne({ id: projectId });
  const { estimateRate: projectEstimateRate } = project;

  const estimateRate = watch("estimateRate");
  const estimateUserId = watch("estimateUserId");
  const estimateMinHours = watch("estimateMinHours");
  const estimateMaxHours = watch("estimateMaxHours");

  // don't pull in rate unless user exists
  // if user doesn't exist then we will use the project estimate rate.
  const { loading: userRateLoading, userRate } = useUserRate({
    id: estimateUserId,
    canGet: Number(estimateRate) === 0 && estimateRate !== "" && estimateUserId,
    crId: crId,
  });

  useEffect(() => {
    if (!userRateLoading) {
      setValue("estimateRate", userRate);
      trigger();
    }
  }, [userRate, setValue, userRateLoading, trigger]);

  useEffect(() => {
    if (
      !userRateLoading &&
      estimateUserId === "" &&
      Number(estimateRate) === 0 &&
      estimateRate !== ""
    ) {
      setValue("estimateRate", projectEstimateRate);
    }
  }, [
    projectEstimateRate,
    estimateUserId,
    userRateLoading,
    estimateRate,
    setValue,
    userRate,
  ]);

  function handleChange(onChange) {
    return (value) => {
      onChange(value);
      trigger();
    };
  }

  let estimateMin = 0;
  if (!isNaN(estimateRate) && !isNaN(estimateMinHours)) {
    estimateMin = _.multiply(estimateRate, estimateMinHours).toFixed(2);
  }
  let estimateMax = 0;
  if (!isNaN(estimateRate) && !isNaN(estimateMaxHours)) {
    estimateMax = _.multiply(estimateRate, estimateMaxHours).toFixed(2);
  }

  return (
    <Form onSubmit={submitForm}>
      <Row>
        <Col>
          <Form.Row>
            <Col>
              <Controller
                name="estimateMinHours"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FieldInput
                    label="Min Hours"
                    type="number"
                    value={value}
                    onBlur={onBlur}
                    onChange={handleChange(onChange)}
                    isInvalid={errors.estimateMinHours}
                    errors={errors.estimateMinHours?.message}
                    onChangeOnBlur={false}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="estimateMaxHours"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FieldInput
                    label="Max Hours"
                    type="number"
                    value={value}
                    onBlur={onBlur}
                    onChange={handleChange(onChange)}
                    isInvalid={errors.estimateMaxHours}
                    errors={errors.estimateMaxHours?.message}
                    onChangeOnBlur={false}
                  />
                )}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Controller
                name="estimateUserId"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <User.Select
                    label="Hourly Rate"
                    value={value}
                    onBlur={onBlur}
                    onChange={handleChange((v) => {
                      onChange(v);
                      setValue("estimateRate", 0);
                    })}
                    isInvalid={errors.estimateUserId}
                    errors={errors.estimateUserId?.message}
                    isClearable
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="estimateRate"
                control={control}
                defaultValue={0}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FieldInput
                    label="Hourly Rate"
                    type="number"
                    value={value}
                    onBlur={onBlur}
                    onChange={handleChange((v) => {
                      onChange(v);
                      setValue("estimateUserId", "");
                    })}
                    isInvalid={errors.estimateRate}
                    errors={errors.estimateRate?.message}
                    disabled={userRateLoading}
                    onChangeOnBlur={false}
                  />
                )}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FieldInput label="Min Cost" value={estimateMin} disabled />
            </Col>
            <Col>
              <FieldInput label="Max Cost" value={estimateMax} disabled />
            </Col>
          </Form.Row>
        </Col>
      </Row>
      <ButtonGroup>
        <Button type="submit">Save</Button>
        <Button
          variant="danger"
          onClick={() => {
            onHide();
          }}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
}
