import { createEntityHooks } from "../../../common/entity";
import userState from "./state";

export const {
  useEntities: useExpenseTripUsers,
  useEntity: useExpenseTripUser,
  useEntityByFind: useExpenseTripUserByFind,
} = createEntityHooks({
  entity: userState.entity,
  actions: userState.actions,
  getToastMessage: ({ entity, type }) => {
    return `${entity.userId} on Trip ${entity.expenseTripId} ${type}`;
  },
});
