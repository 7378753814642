import { createEntityHooks } from "../../common/entity";
import { userActivityActions, userActivitySelectors } from "./state";

const getUserActivitiesState = (state) => state.activity.userActivity;

const { useEntities: useUserActivity } = createEntityHooks({
  entity: {
    name: "userActivity",
    selectors: userActivitySelectors,
    getState: getUserActivitiesState,
  },
  actions: {
    getMany: userActivityActions.getUserActivity,
    reset: userActivityActions.clearUserActivity,
  },
  expire: {
    key: "minutes",
    value: 5,
  },
});

export default useUserActivity;
