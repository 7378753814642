import { sum } from "lodash";
import { boolean, number, object, string } from "yup";

const projectCustomerSchema = object()
  .shape({
    customerId: number()
      .label("Customer")
      .required()
      .positive()
      .integer()
      .test(
        "customerSettingsCheck",
        "Invalid customer due to settings",
        (_, { path, options: { context = {} }, createError }) => {
          const { canBypassContractRestriction } = context;
          const customer = context[path];

          if (!customer) return true;

          const { contractOnly = "N" } = customer;
          if (contractOnly === "S") {
            return createError({
              message: "Customer is no longer valid for new projects",
            });
          } else if (
            contractOnly === "Y" &&
            canBypassContractRestriction !== true
          ) {
            return createError({
              message: "Customer is contract only, select an existing project",
            });
          }
          return true;
        },
      ),
    contactId: number()
      .label("Contact")
      .min(0)
      .integer()
      .transform((v) => (isNaN(v) ? 0 : v)),
    poNumber: string().label("PO Number").max(30),
    waitingOnCustomer: boolean().label("Waiting on Customer").required(),
    splitType: string().label("Split Type").required().oneOf(["P", "D"]),
    splitAmount: number()
      .label("Split amount")
      .required()
      .min(0)
      .when("splitType", {
        is: "P",
        then: number().max(100, "Split percent must be less than 100"),

        otherwise: number().max(
          999999,
          "Split amount must be less than 999,999",
        ),
      }),
  })
  .test({
    name: "customers-split-amount-total",
    test: (_, { path, parent, createError }) => {
      const percents = parent
        ?.filter((c) => c.splitType === "P")
        ?.map((c) => c.splitAmount);
      if (sum(percents) !== 100) {
        return createError({
          path: `${path}.splitAmount`,
          message: "Customers total split percent must equal 100%",
        });
      }
      return true;
    },
    message: "",
  });

export default projectCustomerSchema;

export const PROJECT_CUSTOMER_INITIAL = {
  customerId: 0,
  contactId: 0,
  waitingOnCustomer: false,
  splitType: "P",
  splitAmount: 100,
  poNumber: "",
};
