import AwsZoneEditor from "./AwsZoneEditor";
import AwsZoneLink from "./AwsZoneLink";
import AwsZoneSelect from "./AwsZoneSelect";
import Table from "./AwsZoneTable";
import { useAwsZone, useAwsZones } from "./hooks";
import awsZoneState from "./state";

const AwsZone = {
  // API
  api: awsZoneState.api,
  // State
  actions: awsZoneState.actions,
  selectors: awsZoneState.entity.selectors,
  // Hooks
  useOne: useAwsZone,
  useAll: useAwsZones,
  // Components
  Table,
  Link: AwsZoneLink,
  Editor: AwsZoneEditor,
  Select: AwsZoneSelect,
  // Related Entity Objects
};

export default AwsZone;
