import shark from "../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityHooks,
  createEntityReducer,
  entityDescription,
  entitySelect,
  entityTableFilter,
} from "../../common/entity";
import TimeHoldDateSelect from "./TimeHoldDateSelect";

const api = {
  getMany: () => {
    return shark.post({ command: "getHoldReasons" }).then((r) => {
      return { data: r.responseData.holdReasons };
    });
  },
};

const entity = createEntity({
  name: "holdReason",
  type: "times/holdReasons",
  getState: (state) => state.times.holdReasons,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

export const holdReasonState = {
  api,
  entity,
  actions,
  reducer,
};

export const { useEntities: useTimeHoldReasons, useEntity: useTimeHoldReason } =
  createEntityHooks({
    entity: holdReasonState.entity,
    actions: holdReasonState.actions,
  });

const TimeHoldReasonSelect = entitySelect({
  name: "timeHoldReason",
  useEntities: useTimeHoldReasons,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeHoldReasonTableFilter = entityTableFilter({
  name: "timeHoldReason",
  useEntities: useTimeHoldReasons,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeHoldReasonDescription = entityDescription({
  name: "timeHoldReason",
  useEntity: useTimeHoldReason,
});

const TimeHoldReason = {
  // Backend API
  api: holdReasonState.api,
  // State
  actions: holdReasonState.actions,
  selectors: holdReasonState.entity.selectors,
  // Hooks
  useAll: useTimeHoldReasons,
  useOne: useTimeHoldReason,
  // Components
  Select: TimeHoldReasonSelect,
  Description: TimeHoldReasonDescription,
  TableFilter: TimeHoldReasonTableFilter,
  DateSelect: TimeHoldDateSelect,
};
export default TimeHoldReason;
