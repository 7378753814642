import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

import { Forms, FormsField } from "../../common/forms";
import codeTestSchema from "./codeTestSchema";

export default function CodeTestEditor({ values = {}, onSubmit, isLoading }) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: codeTestSchema }}
        showDevTool
        isDisabled={true}
      >
        <CodeTestForm isLoading={isLoading} />
      </Forms>
    </>
  );
}

function CodeTestForm() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <CodeTestTextField id="id" label="Date" />
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}

/*
        <Form.Row>
            <CodeTestTextField id="strt" label="Start" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="endt" label="End" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="eose" label="Eos errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="gete" label="Get/Read errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="imh2" label="IMH2 errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="it01" label="IT1 errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="mkvr" label="Makevar errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="ifer" label="If errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="nobl" label="Noblank errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="tede" label="TEDIT errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="lmod" label="LMOD errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="qury" label="Query errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="rkqy" label="Query/RK errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="meme" label="Memory errors" />
          </Form.Row>
          <Form.Row>
            <CodeTestTextField id="swtc" label="Switch errors" />
          </Form.Row>
*/

function CodeTestTextField({ id, label }) {
  return (
    <Col xs="auto">
      <Form.Group id={id} className="my-0">
        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text
              className="bg-secondary text-light"
              style={{ minWidth: "150px" }}
            >
              {label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormsField.Control
            name={id}
            autoComplete="off"
            style={{ minWidth: "300px" }}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  );
}
