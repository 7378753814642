import React from "react";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function ReusableProgramIndex() {
  const { entities, loading, reset } = Dbc.ReusableProgram.useAll({
    canGet: true,
  });
  return (
    <Page title="DBC Reusable Programs" navSidebar={<DbcNavSidebar />}>
      <Dbc.ReusableProgram.Table
        reusablePrograms={entities}
        initialSort={{ id: "Filename" }}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
