import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

import Auth from "../../auth";
import Page from "../../common/pages/Page";

export default function Logout() {
  const logout = Auth.useLogout({ canResetState: true });
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    logout();
  }, [logout]);

  if (isLoggedIn) return <Redirect to="/home" />;
  return <Page title="Logging out user" lead="Please wait..." />;
}
