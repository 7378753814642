import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import User from "../../../users";
import UserGroupingNavSidebar from "./UserGroupingNavSidebar";

export default function UserGroupingEdit() {
  const id = String(useParams().id).toUpperCase();
  const { userGrouping, loading, updateOne } = User.UserGrouping.useOne({
    id,
    canGet: true,
  });
  const { isManager, isAdmin, canMaintainContracts } = useSelector(
    (state) => state.auth,
  );

  if (!(isManager || isAdmin || canMaintainContracts))
    return (
      <Page
        title="User Grouping"
        navSidebar={<UserGroupingNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="User Grouping" navSidebar={<UserGroupingNavSidebar />}>
      {userGrouping ? (
        <User.UserGrouping.Editor
          values={userGrouping}
          isLoading={loading}
          onSubmit={(values) => updateOne(values)}
        />
      ) : (
        <h4>User Grouping not found.</h4>
      )}
    </Page>
  );
}
