import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import useCurrentTimer from "./currentTimer/useCurrentTimer";
import { timeActions } from "./state";

export default function useTimer() {
  const { currentTimer } = useCurrentTimer();
  const dispatch = useDispatch();
  const current = useMemo(() => {
    return currentTimer || { status: "none" };
  }, [currentTimer]);

  const restartTimer = useCallback(
    (id) => {
      dispatch(timeActions.restartTimer({ timeId: id }));
    },
    [dispatch],
  );

  return useMemo(() => {
    return { current, restart: restartTimer };
  }, [current, restartTimer]);
}
