import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Edi from "../../../edi";
import EdiNavSidebar from "../EdiNavSidebar";

export default function TradingPartnerShow() {
  const id = parseInt(useParams().id);
  const { tradingPartner, loading, updateOne } = Edi.TradingPartner.useOne({
    id: id,
    canGet: true,
  });
  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="Trading Partner"
        navSidebar={<EdiNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="TradingPartner" navSidebar={<EdiNavSidebar />}>
      {tradingPartner ? (
        <>
          <Edi.TradingPartner.Editor
            values={tradingPartner}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={tradingPartner} />
        </>
      ) : (
        <h4>Trading Partner not found.</h4>
      )}
    </Page>
  );
}
