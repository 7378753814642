import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { resetState } from "../reducers";
import { persistor } from "../store";
import { authActions } from "./state";

export default function useLogout({ canResetState = false }) {
  const dispatch = useDispatch();
  const logoutCallback = useCallback(
    () =>
      dispatch(authActions.logout()).then(() => {
        if (canResetState) {
          dispatch(resetState());
          persistor.purge();
        }
      }),
    [dispatch, canResetState],
  );
  return logoutCallback;
}
