import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { _date } from "../common/functions/dates";
import useTinykeys from "../common/hooks/useTinykeys";
import EstimateEditorModal from "./EstimateEditorModal";
import { useEstimate } from "./hooks";

export default function EstimateNewButton({
  label = "Add Estimate",
  onCreate,
  initialValues,
  disabled = false,
}) {
  const { createOne } = useEstimate();
  const [showAdd, setShowAdd] = useState(false);

  useTinykeys(
    showAdd
      ? {}
      : {
          "Alt+KeyA": () => setShowAdd(true),
        },
  );

  return (
    <>
      <Button
        variant="success"
        onClick={() => setShowAdd(true)}
        disabled={disabled}
      >
        {label}
      </Button>
      <EstimateEditorModal
        title="Add Estimate"
        values={initialValues}
        show={showAdd}
        onHide={() => setShowAdd(false)}
        onSubmit={(estimate) => {
          const payloadEstimate = { ...estimate };
          payloadEstimate.date = _date.stamp(estimate.date);
          createOne(payloadEstimate).then(() => {
            onCreate();
            setShowAdd(false);
          });
        }}
      />
    </>
  );
}
