import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import { Forms, FormsField, useFormContext } from "../../common/forms";
import tradingPartnerSchema from "./tradingPartnerSchema";

export default function TradingPartnerEditor({
  values = {},
  onSubmit,
  isLoading,
}) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: tradingPartnerSchema }}
        showDevTool
      >
        <TradingPartnerForm isLoading={isLoading} />
      </Forms>
    </>
  );
}

function TradingPartnerForm() {
  const { submitForm } = useFormContext();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <FormsField
            name="description"
            label="Description"
            autoComplete="off"
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormsField.TextArea
            name="notes"
            label="Notes"
            className="text-monospace"
            minRows={6}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={"auto"}>
          <FormsField.Check name="isInactive" label="Inactive?" />
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
