import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import Contract from "../../../contracts";
import ContractProductDefaultCrNew from "./ContractProductDefaultCrNew";

export default function ContractProductDefaultCrNewModal({
  contractProductId,
  show,
  onHide,
  onSave,
}) {
  const { createOne } = Contract.ContractProductDefaultCr.useOne({});
  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header className="py-1" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"You do not have access to this feature"}
          </Modal.Title>
        </Modal.Header>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {"New Default CR Record for " + contractProductId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContractProductDefaultCrNew
          values={{ contractProductId: contractProductId }}
          onSubmit={(values) => createOne(values).then(() => onSave())}
        />
      </Modal.Body>
    </Modal>
  );
}
