import React from "react";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function BranchShow() {
  const id =
    String(useParams().base).toLowerCase() +
    "/" +
    String(useParams().id).toLowerCase();
  const { branch, loading } = Dbc.Branch.useOne({
    id: id,
    canGet: true,
  });

  return (
    <Page title="Branch" navSidebar={<DbcNavSidebar />}>
      {branch ? (
        <Dbc.Branch.Editor values={branch} isLoading={loading} />
      ) : (
        <h4>Branch not found.</h4>
      )}
    </Page>
  );
}
