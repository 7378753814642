import { useCallback, useMemo, useState } from "react";

import ConfirmModal from "./ConfirmModal";

export default function useConfirmModal({
  title = "Please Confirm",
  message = "",
  buttons = [],
} = {}) {
  const [modalOptions, setModalOptions] = useState({ show: false });
  const onHide = useCallback(() => setModalOptions({ show: false }), []);

  const showConfirm = useCallback(
    (opts) => setModalOptions({ show: true, ...opts }),
    [],
  );
  const props = useMemo(() => {
    const { onHide: onHideAlso, ...opts } = modalOptions;
    return {
      title,
      message,
      buttons,
      onHide: () => {
        onHide();
        onHideAlso && onHideAlso();
      },
      ...opts,
    };
  }, [message, modalOptions, buttons, title, onHide]);
  return [ConfirmModal, props, showConfirm];
}
