import EstimateStatusDescription from "./EstimateStatusDescription";
import EstimateStatusSelect from "./EstimateStatusSelect";
import EstimateStatusTableFilter from "./EstimateStatusTableFilter";
import { useEstimateStatus, useEstimateStatuses } from "./hooks";
import estimateStatusState from "./state";

const EstimateStatus = {
  // Backend API
  api: estimateStatusState.api,
  // State
  actions: estimateStatusState.actions,
  selectors: estimateStatusState.entity.selectors,
  // Hooks
  useAll: useEstimateStatuses,
  useOne: useEstimateStatus,
  // Components
  Select: EstimateStatusSelect,
  Description: EstimateStatusDescription,
  TableFilter: EstimateStatusTableFilter,
};
export default EstimateStatus;
