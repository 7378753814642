import React from "react";
import { Redirect, useParams } from "react-router";

import Cr from "../../crs";
import CrPage from "./CrPage";
import CrShowNavSidebar from "./CrShowNavSidebar";

export default function CrShowProject() {
  const id = parseInt(useParams().id);
  const { cr, loading } = Cr.useOne({ id: id });
  if (loading || !cr?.projectId)
    return (
      <CrPage
        heading={`CR ${id} Project`}
        lead={loading ? "Loading..." : "Project Undefined"}
        navSidebar={<CrShowNavSidebar id={id} />}
      />
    );

  return <Redirect to={`/projects/${cr?.projectId}`} />;
}
