import _ from "lodash";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";

import { Forms, FormsField, useFormContext } from "../forms";
import { _date } from "../functions/dates";

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const optionsFromRows = React.useMemo(() => {
    const options = _.uniq(preFilteredRows.map((row) => row.values[id])).sort();
    // Remove blanks
    return _.remove(options, (o) => o);
  }, [id, preFilteredRows]);

  let options = optionsFromRows;
  if (filterValue && !options.includes(filterValue))
    optionsFromRows.push(filterValue);

  // Render a multi-select box
  return (
    <Form.Control
      as="select"
      size="sm"
      custom
      value={filterValue}
      isValid={!!filterValue}
      disabled={options.length <= 1}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      title="Filter table records"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list based on options objects
export function SelectColumnFilterWithOptions({
  column: { filterValue, setFilter, preFilteredRows, id },
  options,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const _options = React.useMemo(() => {
    const filteredValues = _.uniq(
      preFilteredRows.map((row) => row.values[id]),
    ).filter((o) => o);
    if (!options?.length)
      return filteredValues.map((v) => {
        return { label: v, value: v };
      });
    else return options.filter((o) => filteredValues.includes(o.value));
  }, [id, preFilteredRows, options]);

  return (
    <Form.Control
      as="select"
      size="sm"
      custom
      value={filterValue}
      isValid={!!filterValue}
      disabled={_options.length <= 1}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      title="Filter table records"
    >
      <option value="">All</option>
      {_options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
}

// Define a text based search for filtering
export function TextColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <Form.Control
      size="sm"
      type="text"
      value={filterValue || ""}
      isValid={!!filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter`}
      title="Filter table records"
    />
  );
}

export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export function filterLessThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue <= filterValue;
  });
}
filterLessThan.autoRemove = (val) => typeof val !== "number";

export function DateRangeColumnFilter({ column: { setFilter } }) {
  const [showModal, setShowModal] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");

  return (
    <>
      <Row>
        <Col>
          <Form.Text muted>
            {_date.display(dateFilterStart)}
            {dateFilterStart || dateFilterEnd ? "-" : ""}
            {_date.display(dateFilterEnd)}
          </Form.Text>
        </Col>
        <Col xs="auto">
          <Button size="sm" variant="link" onClick={() => setShowModal(true)}>
            Edit
          </Button>
        </Col>
      </Row>

      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>Edit Date Range Filter</Modal.Header>
        <Modal.Body>
          <Forms
            onSubmit={({ startDate, endDate }) => {
              setFilter({
                startDate: _date.stamp(startDate),
                endDate: _date.stamp(endDate),
              });
              setShowModal(false);
              setDateFilterStart(startDate);
              setDateFilterEnd(endDate);
            }}
            defaultValues={{
              startDate: dateFilterStart,
              endDate: dateFilterEnd,
            }}
          >
            <DateRangeForms />
          </Forms>
        </Modal.Body>
      </Modal>
    </>
  );
}

function DateRangeForms() {
  const { watch, submitForm, reset } = useFormContext();

  const endDate = watch("endDate");
  const startDate = watch("startDate");
  return (
    <Form onSubmit={submitForm}>
      <Form.Row>
        <Col xs="auto">
          <FormsField.Date
            name="startDate"
            label="Start Date"
            includeNow={false}
            maxDate={endDate}
            autoComplete="off"
          />
        </Col>
        <Col>
          <FormsField.Date
            name="endDate"
            label="End Date"
            includeNow={false}
            minDate={startDate}
            autoComplete="off"
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <ButtonGroup>
            <Button type="submit">Filter</Button>
            <Button
              variant="secondary"
              onClick={() => reset({ startDate: "", endDate: "" })}
            >
              Clear
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  );
}

export function filterDateRange(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return (
      (!filterValue?.startDate || rowValue >= filterValue?.startDate) &&
      (!filterValue?.endDate || rowValue <= filterValue?.endDate)
    );
  });
}
