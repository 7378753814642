import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default function DetailTable({ fields, isLoading = false, ...props }) {
  return (
    <Table size="sm" {...props}>
      <tbody>
        {fields.map((f, seq) => {
          if (f?.hide === true) return null;
          return (
            <tr key={`page-detail-table-${seq}`} {...f?.rowProps}>
              <th scope="row">{!isLoading ? f.header : <Skeleton />}</th>
              <td className={f?.className}>
                {!isLoading ? f.value : <Skeleton />}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
