import React, { useEffect, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";

import Watch from "../../activity/watches";
import Page from "../../common/pages/Page";
import Cr from "../../crs";
import Project from "../../projects";
import SettingsNavSidebar from "./SettingsNavSidebar";

export default function SettingsWatched() {
  const { watches, reset, getMany, loading } = Watch.useAll({ canGet: false });
  useEffect(() => {
    reset();
    getMany();
  }, [reset, getMany]);
  return (
    <Page
      title="Watched"
      navSidebar={<SettingsNavSidebar />}
      lead={loading ? "Loading..." : null}
    >
      {loading ? null : <Cards watches={watches} />}
    </Page>
  );
}

function Cards({ watches }) {
  const { projects, crs } = useMemo(() => {
    const projects = watches.filter((w) => w.type === "project");
    const crs = watches.filter((w) => w.type === "cr");
    return { projects, crs };
  }, [watches]);
  return (
    <Row>
      <Col xs="auto">
        <p className="lead">Projects</p>
        {projects?.length
          ? projects.map((p, idx) => (
              <WatchCard key={`watched-project-${idx}`} data={p} />
            ))
          : "No Projects currently being watched"}
      </Col>
      <Col xs="auto">
        <p className="lead">CRs</p>
        {crs?.length
          ? crs.map((p, idx) => (
              <WatchCard key={`watched-cr-${idx}`} data={p} />
            ))
          : "No CRs currently being watched"}
      </Col>
    </Row>
  );
}

function WatchCard({ data }) {
  let link = "";
  switch (data?.type) {
    case "project":
      link = (
        <Project.Link id={data?.key}>{`Project ${data?.key}`}</Project.Link>
      );
      break;

    case "cr":
      link = <Cr.Link id={data?.key}>{`CR ${data?.key}`}</Cr.Link>;
      break;
  }

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>{link || ""}</Card.Body>
    </Card>
  );
}
