import { entityTableFilter } from "../../common/entity";
import { useExpenseTypes } from "./hooks";

const ExpenseTypeTableFilter = entityTableFilter({
  name: "expenseType",
  useEntities: useExpenseTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default ExpenseTypeTableFilter;
