import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { object, string } from "yup";

import FieldDate from "../../common/fields/FieldDate";
import {
  Controller,
  Forms,
  FormsField,
  useFormContext,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import useRedirect from "../../common/hooks/useRedirect";
import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";
import Customer from "../../customers";
import Expense from "../../expenses";
import User from "../../users";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseSearch() {
  const startDate = useSearchParams("startDate");
  const endDate = useSearchParams("endDate");
  const userId = useSearchParams("userId");
  const customerId = useSearchParams("customerId");
  const expenseTripId = useSearchParams("expenseTripId");
  const status = useSearchParams("status");
  const mode = useSearchParams("mode");

  const {
    user: currentUserId,
    canEnterOtherUserExpenses,
    isAdmin,
  } = useSelector((state) => state.auth);

  const hasPermissionsError =
    currentUserId !== userId && !canEnterOtherUserExpenses && !isAdmin;

  const search = useMemo(() => {
    const searchParms = {
      startDate,
      endDate,
      userId,
      customerId,
      expenseTripId,
      status,
      mode,
    };
    const search = { ..._.pickBy(searchParms || {}, Boolean) };

    return _.isEmpty(search) ? null : search;
  }, [customerId, expenseTripId, endDate, startDate, status, userId, mode]);

  const { expenses, loading, reset, lastUpdate } = Expense.useSearch({
    canGet: !!search && !hasPermissionsError,
    search,
  });

  const startArr = ["edit"];
  if (!search?.userId) startArr.push("user");

  const typeArr = [
    "date",
    "source",
    "expenseType",
    "overrideDescription",
    "note",
    "paymentType",
    "mileage",
    "overrideBillableFlag",
    "amount",
    "reimburseAmount",
    "billCustomerAmount",
  ];

  const otherArray = [
    "date",
    "source",
    "expenseCategory",
    "overrideDescription",
    "note",
    "paymentType",
    "mileage",
    "amount",
    "reimburseAmount",
  ];

  const layout = startArr.concat(search?.mode === "O" ? otherArray : typeArr);

  return (
    <Page
      title={`Expenses Search`}
      navSidebar={<ExpenseIndexNavSidebar />}
      tableOfContents={false}
    >
      {hasPermissionsError ? (
        <Alert variant="danger">Invalid Search: Permissions Error</Alert>
      ) : null}
      {search ? (
        <Expense.Table
          expenses={expenses}
          isLoading={loading}
          layout={layout}
          onRefresh={reset}
          refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
        />
      ) : (
        <SearchOptions
          userId={currentUserId}
          userRequired={!(canEnterOtherUserExpenses || isAdmin)}
        />
      )}
    </Page>
  );
}

const searchSchema = object().shape({
  startDate: string().transform(_date.stamp).label("Start Date"),
  endDate: string().transform(_date.stamp).label("End Date"),
  userId: string().label("User").when("$userRequired", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
});

function SearchOptions({ userId, userRequired }) {
  const [, setRedirect] = useRedirect();

  return (
    <Forms
      onSubmit={(search) => {
        setRedirect(
          `/expenses/search?${new URLSearchParams({
            ..._.pickBy(search || {}, Boolean),
          }).toString()}`,
        );
      }}
      defaultValues={{
        userId,
        status: "C",
        mode: "T",
        startDate: "",
        endDate: "",
      }}
      schema={{ schema: searchSchema }}
      context={{ userRequired }}
      showDevTool
    >
      <SearchOptionsForms />
    </Forms>
  );
}

function SearchOptionsForms() {
  const {
    watch,
    submitForm,
    setValue,
    context: { userRequired },
  } = useFormContext();

  const [mode, startDate, endDate, userId] = watch([
    "mode",
    "startDate",
    "endDate",
    "userId",
  ]);

  return (
    <Form>
      <Form.Group>
        <Form.Row>
          <Col xs="auto">
            <Form.Group>
              <Controller
                name="startDate"
                render={({ field: { value, onChange, onBlur } }) => (
                  <FieldDate
                    label="Start Date"
                    value={value}
                    onBlur={onBlur}
                    onChange={(v) => onChange(v)}
                    includeNow={false}
                    maxDate={endDate}
                    autoComplete="off"
                    isClearable
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Controller
                name="endDate"
                render={({ field: { value, onChange, onBlur } }) => (
                  <FieldDate
                    label="End Date"
                    value={value}
                    onBlur={onBlur}
                    onChange={(v) => onChange(v)}
                    includeNow={false}
                    minDate={startDate}
                    autoComplete="off"
                    isClearable
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Row>
        <Col xs={12} lg={6}>
          <FormsField.Select
            name="userId"
            label="User"
            as={User.Select}
            includeNow={false}
            isClearable={!userRequired}
            isDisabled={userRequired}
          />
          <FormsField
            name="mode"
            label="Mode"
            as="select"
            onChange={(e) => {
              const id = e.target.value;
              setValue("mode", id);
              if (id === "T") {
                setValue("customerId", null);
              } else if (id === "C") {
                setValue("expenseTripId", null);
              } else if (id === "O") {
                setValue("expenseTripId", null);
                setValue("customerId", null);
              }
            }}
          >
            <option value="T">Trip</option>
            <option value="C">Customer</option>
            <option value="O">Other</option>
          </FormsField>
          {mode === "C" ? (
            <FormsField.Select
              name="customerId"
              label="Customer"
              as={Customer.Select}
              isClearable
            />
          ) : mode === "T" ? (
            <FormsField.Select
              name="expenseTripId"
              label="Customer Trips"
              as={Expense.Trip.Select}
              search={userId ? { userId: userId } : null}
              isClearable
            />
          ) : null}
          <FormsField name="status" label="Status" as="select">
            <option value="O">Open</option>
            <option value="C">Closed</option>
          </FormsField>
        </Col>
      </Form.Row>

      <Form.Group>
        <Button onClick={submitForm}>Search</Button>
      </Form.Group>
    </Form>
  );
}
