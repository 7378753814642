import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import BranchIndex from "./BranchIndex";
import BranchShow from "./BranchShow";

export default function BranchesPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:base/:id`}>
        <BranchShow />
      </Route>
      <Route exact path={`${path}`}>
        <BranchIndex />
      </Route>
    </Switch>
  );
}
