import storage from "localforage";
import { createMigrate } from "redux-persist";

const resetState = () => undefined;

const migrations = {
  // Reset for phones changes
  2: resetState,
};

export const contactsPersistConfig = {
  key: "contacts",
  version: 2,
  storage,
  whitelist: ["ids", "entities", "lastUpdate", "startAfter"],
  migrate: createMigrate(migrations),
};
