import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import PseudoEdit from "./PseudoEdit";
import PseudoIndex from "./PseudoIndex";
import PseudoNavSidebar from "./PseudoNavSidebar";
import PseudoNew from "./PseudoNew";

export default function PseudosPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Pseudo User"
        navSidebar={<PseudoNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <PseudoNew />
      </Route>
      <Route path={`${path}/:id`}>
        <PseudoEdit />
      </Route>
      <Route exact path={`${path}`}>
        <PseudoIndex />
      </Route>
    </Switch>
  );
}
