import _ from "lodash";
import React, { useState } from "react";
import { ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";

import { GoogleCalendarForm } from "../../common/components/calendars";
import Page from "../../common/pages/Page";
import User from "../../users";
import ContractNavSidebar from "./ContractNavSidebar";

export default function ContractScheduling() {
  const [userGroupingsChosen, setUserGroupingsChosen] = useState([]);
  const [usersChosen, setUsersChosen] = useState([]);
  const [andMode, setAndMode] = useState(false);

  const { userGroupings } = User.UserGrouping.useAll({
    canGet: true,
  });
  const { users } = User.useAll({
    canGet: true,
  });

  let calendars = [];

  for (let g = 0; g < userGroupingsChosen.length; g++) {
    let thisUserGroupCals = [];
    userGroupings.forEach((userGrouping) => {
      if (userGrouping.id === userGroupingsChosen[g]) {
        userGrouping.members?.forEach((member) => {
          users.forEach((user) => {
            if (
              !user.inactive &&
              ((user.id === member.keyField && member.type === "U") ||
                (user.teamId === member.keyField && member.type === "T"))
            )
              thisUserGroupCals.push({ id: user.email, name: user.name });
          });
        });
      }
    });
    if (andMode === true && g > 0) {
      calendars = thisUserGroupCals.filter((value) =>
        calendars.some((cVal) => value.id === cVal.id),
      );
    } else {
      calendars = calendars.concat(thisUserGroupCals);
    }
  }

  usersChosen.forEach((uc) => {
    users.some((user) => {
      if (uc === user.id) {
        calendars.push({ id: user.email, name: user.name });
        return true;
      } else {
        return false;
      }
    });
  });

  // They changed the user group
  const changeUserGrouping = (value, index) => {
    let newUserGroups = [];
    for (let ug = 0; ug < userGroupingsChosen.length; ug++) {
      if (ug === index) {
        if (value !== "") newUserGroups.push(value);
      } else {
        newUserGroups.push(userGroupingsChosen[ug]);
      }
    }
    setUserGroupingsChosen(newUserGroups);
  };
  // They changed the user group
  const addUserGrouping = (value) => {
    let newUserGroups = [];
    for (let ug = 0; ug < userGroupingsChosen.length; ug++) {
      newUserGroups.push(userGroupingsChosen[ug]);
    }
    newUserGroups.push(value);
    setUserGroupingsChosen(newUserGroups);
  };

  // They changed the user
  const changeUser = (value, index) => {
    let newUsers = [];
    for (let u = 0; u < usersChosen.length; u++) {
      if (u === index) {
        if (value !== "") newUsers.push(value);
      } else {
        newUsers.push(usersChosen[u]);
      }
    }
    setUsersChosen(newUsers);
  };
  // They changed the user group
  const addUser = (value) => {
    let newUsers = [];
    for (let u = 0; u < usersChosen.length; u++) {
      newUsers.push(usersChosen[u]);
    }
    newUsers.push(value);
    setUsersChosen(newUsers);
  };

  const companyCalendar = {
    id: "producepro.com_44jjf4ktbs146u0mojtf7d3000@group.calendar.google.com",
    name: "Company Calendar",
  };
  calendars.push(companyCalendar);
  calendars = _.uniq(calendars);
  calendars = calendars.sort((a, b) => {
    if (a.name === "Company Calendar") return -1;
    else if (b.name === "Company Calendar") return 1;

    if (a.name > b.name) return 1;
    return -1;
  });

  return (
    <Page
      title={"Scheduling"}
      navSidebar={<ContractNavSidebar />}
      tableOfContents={false}
    >
      <Row>
        {userGroupingsChosen.map((userGrouping, index) => {
          return (
            <>
              <Col key={index} style={{ maxWidth: "18em", minWidth: "12em" }}>
                <User.UserGrouping.Select
                  value={userGrouping}
                  onChange={(x) => changeUserGrouping(x, index)}
                  isClearable={true}
                />
              </Col>
              {index === 0 ? (
                <Col xs="auto">
                  <ButtonGroup toggle>
                    <ToggleButton
                      key="modeAnd"
                      type="radio"
                      variant="secondary"
                      value={true}
                      checked={andMode === true}
                      onChange={() => setAndMode(true)}
                      className={"mb-3 pt-1"}
                    >
                      {" "}
                      AND{" "}
                    </ToggleButton>
                    <ToggleButton
                      key="modeOr"
                      type="radio"
                      variant="secondary"
                      value={true}
                      checked={andMode === false}
                      onChange={() => setAndMode(false)}
                      className={"mb-3 pt-1"}
                    >
                      {" "}
                      OR{" "}
                    </ToggleButton>
                  </ButtonGroup>
                </Col>
              ) : null}
            </>
          );
        })}
        <Col style={{ maxWidth: "18em", minWidth: "12em" }}>
          <User.UserGrouping.Select onChange={(val) => addUserGrouping(val)} />
        </Col>
      </Row>
      <Row>
        {usersChosen.map((user, index) => {
          return (
            <Col key={index} style={{ maxWidth: "18em", minWidth: "12em" }}>
              <User.Select
                value={user}
                onChange={(x) => changeUser(x, index)}
                isClearable={true}
              />
            </Col>
          );
        })}
        <Col style={{ maxWidth: "18em", minWidth: "12em" }}>
          <User.Select onChange={(val) => addUser(val)} />
        </Col>
      </Row>

      <GoogleCalendarForm calendars={calendars} toggleLock={true} />
    </Page>
  );
}
