import { entitySelect } from "../../common/entity";
import { useOrganizations } from "./hooks";

const OrganizationSelect = entitySelect({
  name: "organization",
  useEntities: useOrganizations,
  getHookArgs: () => ({
    canGet: true,
  }),
});

export default OrganizationSelect;
