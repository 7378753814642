import { useTableLayout, useTableLayouts } from "./hooks";
import tableLayoutState from "./state";
import TableLayoutDescription from "./TableLayoutDescription";
import TableLayoutEditor from "./TableLayoutEditor";
import TableLayoutSelect from "./TableLayoutSelect";

const TableLayout = {
  // Backend API
  api: tableLayoutState.api,
  // State
  actions: tableLayoutState.actions,
  selectors: tableLayoutState.entity.selectors,
  // Hooks
  useAll: useTableLayouts,
  useOne: useTableLayout,
  // Components
  Select: TableLayoutSelect,
  Description: TableLayoutDescription,
  Editor: TableLayoutEditor,
};
export default TableLayout;
