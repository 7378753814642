import { isUndefined } from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import { _date } from "../common/functions/dates";
import Customer from "../customers";
import AwsAccount from "./awsAccounts";
import AwsInstanceType from "./awsInstanceTypes";
import AwsZone from "./awsZones";
import getServerAlias from "./getServerAlias";
import serverSchema from "./serverSchema";
import { SERVER_TYPES } from "./types";

export default function ServerEditor({
  values: { hardwareExpirationDate, redHatLicenseExpirationDate, ...values },
  onSubmit,
  isDisabled = false,
}) {
  AwsAccount.useAll({
    canGet: true,
  });
  AwsZone.useAll({
    canGet: true,
  });
  AwsInstanceType.useAll({
    canGet: true,
  });

  return (
    <Forms
      defaultValues={{
        hardwareExpirationDate: hardwareExpirationDate
          ? _date.fromStamp(hardwareExpirationDate).toISOString()
          : "",
        redHatLicenseExpirationDate: redHatLicenseExpirationDate
          ? _date.fromStamp(redHatLicenseExpirationDate).toISOString()
          : "",
        ...values,
      }}
      onSubmit={({
        hardwareExpirationDate,
        redHatLicenseExpirationDate,
        ...values
      }) =>
        onSubmit({
          hardwareExpirationDate: hardwareExpirationDate
            ? _date.stamp(hardwareExpirationDate)
            : "",
          redHatLicenseExpirationDate: redHatLicenseExpirationDate
            ? _date.stamp(redHatLicenseExpirationDate)
            : "",
          ...values,
        })
      }
      schema={{ schema: serverSchema }}
      isDisabled={isDisabled}
      showDevTool
    >
      <ServerForm />
    </Forms>
  );
}

function getDefaultDescription(customerName, serverType, status) {
  const serverTypeDesc =
    status === "O"
      ? SERVER_TYPES[serverType]?.description + "-OLD"
      : SERVER_TYPES[serverType]?.description;
  return customerName + " [" + serverTypeDesc + "]";
}

function ServerForm() {
  const { watch, submitForm, setValue } = useFormContext();

  const locality = watch("locality");
  const ipAddress = watch("ipAddress");
  const hostname = watch("hostname");
  const customerId = watch("customerId");
  const serverType = watch("serverType");
  const status = watch("status");
  const description = watch("description");
  const aliasPostfix = watch("aliasPostfix");
  const shouldGenerateSSHAlias = watch("shouldGenerateSSHAlias");
  const vpnRequired = watch("vpnRequired");
  const isRedHatLicenseRequired = watch("isRedHatLicenseRequired");

  const { customer } = Customer.useOne({ id: customerId });

  useEffect(() => {
    if (!!aliasPostfix && aliasPostfix !== "") {
      const x = aliasPostfix.replace(/[^\-0-9a-zA-z]/gi, "");
      if (x !== aliasPostfix) setValue("aliasPostfix", x);
    }
  }, [aliasPostfix, setValue]);

  useEffect(() => {
    const defaultDesc = getDefaultDescription(
      customer?.name,
      serverType,
      status,
    );

    if (defaultDesc === description || description === "") {
      const newDesc = getDefaultDescription(customer?.name, serverType, status);
      setValue("description", newDesc, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, status, serverType, setValue]);

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto" style={{ width: "500px" }}>
          <Form.Label>
            {"Customer"}

            {customerId ? (
              <>
                <LinkContainer to={`/customers/${customerId}`}>
                  <Button
                    variant="link"
                    size="sm"
                    className="py-0 text-muted"
                    title="Link to customer page"
                    tabIndex={-1}
                  >
                    Link
                  </Button>
                </LinkContainer>
                <LinkContainer to={`/servers?customer=${customerId}`}>
                  <Button
                    variant="link"
                    size="sm"
                    className="py-0 text-muted"
                    title="Link to customer page"
                    tabIndex={-1}
                  >
                    Servers
                  </Button>
                </LinkContainer>
              </>
            ) : null}
          </Form.Label>
          <CustomerSelect name="customerId" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <FormsField name="status" label="Status" as="select">
            <option value="A">Active</option>
            <option value="O">
              Old server. Not used but still up and running
            </option>
            <option value="I">Inactive, server no longer exists</option>
          </FormsField>
        </Col>
        <Col xs="auto">
          <TypeSelect name="serverType" label="Type" />
        </Col>
        <Col xs="auto">
          <FormsField
            name="engineeringResponsibility"
            label="Engineering"
            as="select"
          >
            <option value=""></option>
            <option value="P">Produce Pro</option>
            <option value="E">Ed White</option>
            <option value="C">Customer IT</option>
            <option value="3">3rd Party</option>
          </FormsField>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"description"}>
            <Row>
              <Col xs={"auto"}>
                <Form.Label>
                  {"Description"}
                  <Button
                    variant="link"
                    size="sm"
                    className="py-0 text-muted"
                    title="Fill with default description"
                    tabIndex={-1}
                    onClick={() => {
                      setValue(
                        "description",
                        getDefaultDescription(
                          customer?.name,
                          serverType,
                          status,
                        ),
                      );
                    }}
                  >
                    Default
                  </Button>
                </Form.Label>
              </Col>
            </Row>
            <InputGroup>
              <FormsField.Control
                name="description"
                style={{
                  color:
                    getDefaultDescription(
                      customer?.name,
                      serverType,
                      status,
                    ) !== description
                      ? "blue"
                      : "black",
                }}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Form.Row>
      <FormsRow xs="auto">
        <FormsField
          name="uuid"
          label="UUID"
          style={{ width: "400px" }}
          className="text-monospace"
        />
      </FormsRow>
      <Form.Row>
        <Col>
          <FormsField
            name="hostname"
            label="Hostname"
            className="text-monospace"
          />
        </Col>
        <Col xs="auto">
          <FormsField
            name="ipAddress"
            label="IP Address (Public)"
            style={{ width: "300px" }}
            className="text-monospace"
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md="auto" xs={12}>
          <FormsField.Check
            name="shouldGenerateSSHAlias"
            label="Generate SSH Alias"
          />
        </Col>
      </Form.Row>
      {shouldGenerateSSHAlias ? (
        <Form.Row className="px-3 border-left border-primary">
          <Col md="auto" xs={12}>
            <Row>
              <Col md="auto" xs={12} offset={1}>
                <FormsField
                  name="aliasPostfix"
                  label="Alias Postfix"
                  className="text-monospace"
                />
              </Col>
            </Row>
            <Row>
              <Col md="auto" xs={12}>
                <Form.Label style={{ color: "red" }}>
                  Generated alias:{" "}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md="auto" xs={12}>
                <Form.Label style={{ color: "red" }}>
                  {getServerAlias(
                    {
                      aliasPostfix,
                      serverType,
                      status,
                      shouldGenerateSSHAlias,
                    },
                    customer,
                  )}{" "}
                </Form.Label>
              </Col>
            </Row>
          </Col>
          <Col>
            <FormsField.TextArea
              name="sshConfig"
              label="SSH Config (Override)"
              className="text-monospace"
              minRows={6}
              placeholder={`HostName ${
                ipAddress ? ipAddress : hostname
              }\nPort 22`}
              readOnly={vpnRequired}
            />
          </Col>
        </Form.Row>
      ) : null}
      <Form.Row>
        <Col xs={"auto"}>
          <LocalitySelect name="locality" label="Location" />
        </Col>
        <Col>
          <FormsField name="localInfo" label="Connection information" />
        </Col>
      </Form.Row>
      {locality === "A" ? (
        <Form.Row>
          <Col xs={"auto"} style={{ minWidth: "250px" }}>
            <FormsField.Select
              name="awsAccount"
              label="AWS Account"
              as={AwsAccount.Select}
              isClearable
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "250px" }}>
            <FormsField.Select
              name="awsZone"
              label="AWS Zone"
              as={AwsZone.Select}
              isClearable
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "210px" }}>
            <FormsField.Select
              name="awsInstanceType"
              label="AWS Instance Type"
              as={AwsInstanceType.Select}
              isClearable
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "220px" }}>
            <FormsField name="awsInstanceId" label="AWS Instance Id" />
          </Col>
        </Form.Row>
      ) : null}
      <Form.Row>
        <Col xs={"auto"}>
          <FormsField.Check name="vpnRequired" label="VPN Required" />
        </Col>
        <Col>
          <FormsField name="remoteUrl" label="URL to Connect" />
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
      <Form.Row>
        <Col>
          <Row className="py-2">
            <Col>
              <h2>Hardware Details</h2>
            </Col>
          </Row>
          <Form.Row>
            <Col>
              <FormsField
                name="hardwareModelNumber"
                label="Model Number"
                className="text-monospace"
              />
            </Col>
            <Col>
              <FormsField
                name="hardwareSerialNumber"
                label="Serial Number"
                className="text-monospace"
              />
            </Col>
            <Col>
              <FormsField.Date
                name="hardwareExpirationDate"
                label="Warranty Expiration"
                includeNow={false}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormsField
                name="osVersion"
                label="OS Version"
                className="text-monospace"
              />
            </Col>
            <Col>
              <FormsField
                name="osKernelVersion"
                label="OS Kernel Version"
                className="text-monospace"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto">
              <FormsField.Check
                name="isRedHatLicenseRequired"
                label="RedHat License?"
              />
            </Col>
            <Col>
              {isRedHatLicenseRequired && (
                <FormsField.Date
                  name="redHatLicenseExpirationDate"
                  label="RedHat License Expiration"
                  includeNow={false}
                />
              )}
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}
// redHatLicenseExpirationDate
// isRedHatLicenseRequired

function FormsRow({ children, ...props }) {
  return (
    <Form.Row>
      <Col {...props}>{children}</Col>
    </Form.Row>
  );
}

function CustomerSelect({ name, id = name, label, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Customer.Select
        {...field}
        disabled={isDisabled}
        onChange={(v) => {
          onChange(v);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function TypeSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value=""></option>
        {Object.values(SERVER_TYPES).map((l, idx) => (
          <option key={`type-option${idx}`} value={l.id}>
            {l.description}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function LocalitySelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value=""></option>
        <option value="A">AWS</option>
        <option value="L">Local</option>
        <option value="R">Remote (Other)</option>
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
