import React from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import UserActivityTable from "../../../activity/userActivity/UserActivityTable";
import StampDisplay from "../../../common/components/StampDisplay";
import AttachmentsTable from "../../../common/details/AttachmentsTable";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import HostingAgreementNavSidebar from "./HostingAgreementNavSidebar";

export default function HostingAgreementShow() {
  const id = parseInt(useParams().id);
  const { entity, updateOne, getOne, loading, createAttachment } =
    Customer.HostingAgreements.useOne({
      id,
      canGet: true,
    });
  const title = "Hosting Agreement";

  const { hostingAgreementAccess, isAdmin } = useSelector(
    (state) => state.auth,
  );

  const canEditHostingAgreements = hostingAgreementAccess === "M" || isAdmin;

  if (
    !(
      hostingAgreementAccess === "V" ||
      hostingAgreementAccess === "M" ||
      isAdmin
    )
  )
    return (
      <Page
        title="Customer Hosting Agreement"
        navSidebar={
          <HostingAgreementNavSidebar customerId={entity?.customerId} />
        }
        lead="You do not have access to this feature"
      />
    );

  const disabled = loading || !canEditHostingAgreements;

  return (
    <Page
      title={title}
      lead={
        loading
          ? "Loading..."
          : !entity
          ? "Customer Hosting Agreement not found"
          : ""
      }
      navSidebar={
        <HostingAgreementNavSidebar customerId={entity?.customerId} />
      }
    >
      {entity ? (
        <>
          <Customer.HostingAgreements.Editor
            values={entity}
            onSubmit={(agreement) => updateOne(agreement)}
            isDisabled={disabled}
          />
          <br></br>
          <Alert variant={"info"}>
            <AttachmentsTable
              attachments={entity.attachments}
              create={createAttachment}
              source={{ source: "HA", sourceId: entity.id }}
              onDelete={getOne}
              isDisabled={disabled}
            />
          </Alert>
          <StampDisplay record={entity} />
          <Alert variant={"warning"}>
            <UserActivityTable
              userActivity={entity.logs}
              isLoading={loading}
              layout={["dateTime", "userId", "description", "type"]}
            />
          </Alert>
        </>
      ) : null}
    </Page>
  );
}
