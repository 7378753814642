import shark from "../../../apis/shark";

function getContractProductCategoriesAPI() {
  const payload = {
    command: "getContractProductCategories",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.contractProductCategories,
  }));
}

function getContractProductCategoryAPI({ id }) {
  const payload = {
    command: "getContractProductCategory",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createContractProductCategoryAPI(contractProductCategory) {
  const payload = {
    command: "createContractProductCategory",
    ...contractProductCategory,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateContractProductCategoryAPI(contractProductCategory) {
  const payload = {
    command: "updateContractProductCategory",
    ...contractProductCategory,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getContractProductCategoriesAPI,
  getOne: getContractProductCategoryAPI,
  createOne: createContractProductCategoryAPI,
  updateOne: updateContractProductCategoryAPI,
};
