import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import User from "../../../users";
import NotificationGroupNavSidebar from "./NotificationGroupNavSidebar";

export default function NotificationGroupNew() {
  const [, setRedirect] = useRedirect();
  return (
    <Page
      title="Notification Group"
      navSidebar={<NotificationGroupNavSidebar />}
    >
      <User.NotificationGroup.New
        onNew={({ id }) => setRedirect(`/users/notification-groups/${id}`)}
      />
    </Page>
  );
}
