import React, { useMemo } from "react";

import { CurrencyFormatter } from "../common/formatters";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import ProjectLink from "../projects/ProjectLink";
import ContractProductCategoryLink from "./contractProductCategories/ContractProductCategoryLink";
import ContractProductCategoryTableFilter from "./contractProductCategories/ContractProductCategoryTableFilter";
import ContractProductLink from "./contractProducts/ContractProductLink";
import ContractProductTableFilter from "./contractProducts/ContractProductTableFilter";

export default function ContractDetailTable({
  data,
  layout = [
    "Description",
    "Project",
    "Open CRs?",
    "Add On",
    "Budget",
    "Invoiced",
    "Open",
    "Writeoff",
    "Budget Difference",
    "Travel Time & Expenses",
    "License",
  ],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Contract Product",
        Header: "Contract Product",
        accessor: "contractProduct",
        Filter: ContractProductTableFilter,
        filter: "text",
        Cell: ({ value }) => (
          <ContractProductLink id={value}>{value}</ContractProductLink>
        ),
      },
      {
        id: "Contract Product Category",
        Header: "Contract Product Category",
        accessor: "contractProductCategory",
        Filter: ContractProductCategoryTableFilter,
        filter: "text",
        Cell: ({ value }) => (
          <ContractProductCategoryLink id={value}>
            {value}
          </ContractProductCategoryLink>
        ),
      },
      {
        id: "Project",
        Header: "Project",
        accessor: "project",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <ProjectLink id={value}>{value}</ProjectLink>,
      },
      {
        id: "Open CRs?",
        Header: "Open CRs?",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ openCrs }) => (openCrs !== "" ? "❗" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "License",
        Header: "License",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isLicense }) => (isLicense ? "✅" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Add On",
        Header: "Add On",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isAddOn }) => (isAddOn ? "➕" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Budget",
        Header: "Budget",
        accessor: "budgetedAmount",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.budgetedAmount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Invoiced",
        Header: "Invoiced",
        accessor: "billedAmount",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.billedAmount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Budget Difference",
        Header: "Budget Difference",
        accessor: "budgetDifference",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.budgetDifference) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Open",
        Header: "Open",
        accessor: "toBeBilled",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.toBeBilled) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Writeoff",
        Header: "Writeoff",
        accessor: "nonBillAmount",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.nonBillAmount) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Travel Time & Expenses",
        Header: "Travel Time & Expenses",
        accessor: ({ travelBillableTime, travelExpense }) =>
          travelBillableTime + travelExpense,
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return (
              parseFloat(row.original.travelBillableTime) +
              parseFloat(row.original.travelExpense) +
              total
            );
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Travel Time",
        Header: "Travel Time",
        accessor: "travelBillableTime",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.travelBillableTime) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Travel Time Writeoff",
        Header: "Travel Time Writeoff",
        accessor: "travelNonBillableTime",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.travelNonBillableTime) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
      {
        id: "Travel Expense",
        Header: "Travel Expense",
        accessor: "travelExpense",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.travelExpense) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <CurrencyFormatter value={total} blankZero={true} decimals={0} />
          );
        },
      },
    ],
    [],
  );
  return (
    <Table
      hasCount
      label="Contracts details"
      columns={columns}
      layout={layout}
      data={data}
      moveFooterToTop
      getRowProps={(row) => {
        if (row.isGrouped) return {};

        if (row?.original) {
          if (row?.original?.srce === "R") {
            return { className: "table-info" };
          } else if (
            row?.original.toBeBilled === 0 &&
            row?.original.billedAmount === 0 &&
            row?.original.nonBillAmount === 0
          ) {
            return {};
          } else if (
            row?.original.budgetedAmount >
            row?.original.toBeBilled + row?.original.billedAmount
          ) {
            return { className: "table-success" };
          } else if (
            row?.original.budgetedAmount ===
            row?.original.toBeBilled + row?.original.billedAmount
          ) {
            return { className: "table-warning" };
          } else {
            return {
              className: "table-danger",
            };
          }
        }
      }}
      {...props}
    />
  );
}
