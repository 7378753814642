import AwsAccount from "./awsAccounts";
import AwsInstanceType from "./awsInstanceTypes";
import AwsRegion from "./awsRegions";
import AwsZone from "./awsZones";
import { useServer, useServers, useServersByCustomer } from "./hooks";
import Reservation from "./reservations";
import S3Bucket from "./s3Buckets";
import ServerEditor from "./ServerEditor";
import ServerLink from "./ServerLink";
import SelectByCustomer from "./ServerSelectByCustomer";
import Table from "./ServerTable";
import ServerTableByCustomer from "./ServerTableByCustomer";
import state from "./state";

const Server = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useServer,
  useAll: useServers,
  useSearch: useServersByCustomer,
  // Components
  Table,
  Link: ServerLink,
  SelectByCustomer,
  TableByCustomer: ServerTableByCustomer,
  Editor: ServerEditor,
  // Related Entity Objects

  AwsInstanceType: AwsInstanceType,
  AwsZone: AwsZone,
  AwsRegion: AwsRegion,
  AwsAccount: AwsAccount,
  S3Bucket: S3Bucket,
  Reservation: Reservation,
};

export default Server;
