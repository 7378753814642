import { bool, object, string } from "yup";

const awsZoneSchema = object().shape({
  regionDescription: string().label("Region Description").required().max(30),
  id: string().label("Id").required().max(20),
  awsRegion: string().label("Id").required().max(19),
  isInactive: bool().required().default(false),
});

export default awsZoneSchema;
