import React from "react";
import {
  Alert,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../common/forms";

export default function ServerLoginEditor({
  values: { id, serverLogin, serverLoginKeyInfo, serverLoginPasswordInfo },
  isDisabled = false,
  onSubmit,
  serverLoginPassword,
}) {
  return (
    <Forms
      defaultValues={{
        id,
        serverLogin,
      }}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
    >
      <ServerLoginForm
        {...{
          isNew: !serverLogin,
          serverLoginKeyInfo,
          serverLoginPasswordInfo,
          serverLoginPassword,
        }}
      />
    </Forms>
  );
}

function ServerLoginForm({
  serverLoginPassword,
  serverLoginKeyInfo,
  serverLoginPasswordInfo,
  isNew,
}) {
  const { isAdmin } = useSelector((state) => state.auth);
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <InputGroup>
              <FormsField.Control
                name="serverLogin"
                disabled={!isAdmin || !isNew}
              />
              {submitForm ? (
                <DropdownButton
                  as={InputGroup.Append}
                  variant="outline-secondary"
                  title={<BiEdit />}
                  id="input-group-dropdown-2"
                >
                  {!isNew ? (
                    <>
                      <Dropdown.Item onClick={() => submitForm("key")}>
                        Generate new keys
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => submitForm("password")}>
                        Generate new password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => submitForm("delete")}>
                        Delete
                      </Dropdown.Item>
                    </>
                  ) : (
                    <Dropdown.Item onClick={() => submitForm("create")}>
                      Add
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              ) : null}
            </InputGroup>
          </Form.Group>
        </Col>
        {isNew ? null : (
          <Col>
            <Form.Row>
              <Col>
                <Form.Label>Stamp</Form.Label>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <code>{serverLoginKeyInfo}</code>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <code>{serverLoginPasswordInfo}</code>
              </Col>
            </Form.Row>
          </Col>
        )}
      </Form.Row>

      {serverLoginPassword ? (
        <Form.Row>
          <Col xs="auto">
            <Alert variant="info">
              <Form.Row>
                <Col>
                  <Form.Label>Generated Password</Form.Label>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control
                    className="text-monospace"
                    type="text"
                    value={serverLoginPassword}
                    disabled
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Text className="text-muted">
                    Please note the password, it will not be available again.
                  </Form.Text>
                </Col>
              </Form.Row>
            </Alert>
          </Col>
        </Form.Row>
      ) : null}
    </Form>
  );
}
