import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getCrNotificationTypes" }).then((r) => {
      return { data: r.responseData.crNotificationTypes };
    });
  },
};

const entity = createEntity({
  name: "notificationType",
  type: "crs/notificationTypes",
  getState: (state) => state.crs.notificationTypes,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const notificationTypeState = {
  api,
  entity,
  actions,
  reducer,
};

export default notificationTypeState;
