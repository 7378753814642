import { isUndefined } from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import EstimateStatusSelect from "../projects/estimateStatuses/EstimateStatusSelect";
import User, { useUserRate } from "../users";
import estimateSchema from "./estimateSchema";

export default function EstimateEditor({
  values,
  onSubmit,
  onSaveAsNew,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={values}
      onSubmit={onSubmit}
      onSaveAsNew={onSaveAsNew}
      schema={{ schema: estimateSchema }}
      isDisabled={isDisabled}
    >
      <EstimateForm values={values} onSaveAsNew={onSaveAsNew} />
    </Forms>
  );
}

const projectManagement = {
  label: "Project Management Hours",
  name: "projectManagementHours",
};
const design = {
  label: "Design Hours",
  name: "designHours",
};
const development = {
  label: "Development Hours",
  name: "developmentHours",
};
const testing = {
  label: "Testing Hours",
  name: "testingHours",
};
const installation = {
  label: "Installation Hours",
  name: "installationHours",
};
const instruction = {
  label: "Instruction Hours",
  name: "instructionHours",
};
const support = {
  label: "Support Hours",
  name: "supportHours",
};
const codeCheckin = {
  label: "Code Checkin Hours",
  name: "codeCheckinHours",
};
const scopeAddition = {
  label: "Scope Addition Hours",
  name: "scopeAdditionHours",
};

const buckets = [
  projectManagement,
  design,
  development,
  testing,
  installation,
  instruction,
  support,
  codeCheckin,
  scopeAddition,
];

const currencyFormatter = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
});

function EstimateForm({ values: initialValues, onSaveAsNew }) {
  const { submitForm, watch, setValue, getValues } = useFormContext();
  const source = getValues("source");
  const reference = getValues("reference");
  const estimateId = getValues("id");
  const crId = source === "CR" ? reference : "";
  const projectId = source === "PR" ? reference : "";
  const rateUserId = watch("rateUserId");
  const rate = watch("rate");
  const projectManagementHours = watch("projectManagementHours");
  const designHours = watch("designHours");
  const developmentHours = watch("developmentHours");
  const testingHours = watch("testingHours");
  const installationHours = watch("installationHours");
  const instructionHours = watch("instructionHours");
  const supportHours = watch("supportHours");
  const codeCheckinHours = watch("codeCheckinHours");
  const scopeAdditionHours = watch("scopeAdditionHours");

  const { loading: userRateLoading, userRate } = useUserRate({
    id: rateUserId,
    projectId: projectId,
    crId: crId,
    canGet: Number(rate) === 0 && rate !== "",
  });

  useEffect(() => {
    if (!userRateLoading) {
      setValue("rate", userRate);
    }
  }, [userRate, setValue, userRateLoading]);

  useEffect(() => {
    let totalHours = 0;
    buckets.forEach((b) => {
      const hours = parseFloat(eval(b.name));
      totalHours = totalHours + hours;
      const cost = hours * rate;
      if (!isNaN(cost)) {
        setValue(b.name + "Cost", currencyFormatter.format(cost));
      }
    });

    if (!isNaN(totalHours)) {
      setValue("totalHours", totalHours);
    }
    const totalCost = totalHours * parseFloat(rate);
    if (!isNaN(totalCost))
      setValue("totalCost", currencyFormatter.format(totalCost));
  }, [
    designHours,
    developmentHours,
    testingHours,
    setValue,
    projectManagementHours,
    installationHours,
    instructionHours,
    supportHours,
    codeCheckinHours,
    scopeAdditionHours,
    rate,
    initialValues,
  ]);

  const easyButtonClicked = () => {
    setValue(projectManagement.name, "0.25");
    setValue(design.name, "0.25");
    setValue(development.name, "0.75");
    setValue(testing.name, "0.25");
    setValue(installation.name, "0.25");
    setValue(instruction.name, "0.00");
    setValue(support.name, "0.00");
    setValue(codeCheckin.name, "0.25");
    setValue(scopeAddition.name, "0.00");
  };

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <TypeSelect name="type" label="Type" />
        </Col>
        <Col xs="auto">
          <ActiveStatusSelect name="activeStatus" label="Active Status" />
        </Col>
        <Col xs="md">
          <OwnerSelect name="owner" label="Estimator" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={"auto"}>
          <FormsField.Date
            name="date"
            label="Estimate Date"
            includeArrows={false}
            customInput={<input size={10} />}
          />
        </Col>
        <Col className="flex-grow-1">
          <ProjectEstimateStatusSelect
            name="projectEstimateStatus"
            label="Project Estimate Status"
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="md">
          <RateUserIdSelect name="rateUserId" label="Rate Lookup User" />
        </Col>
        <Col>
          <RateInput
            name="rate"
            label="Rate"
            userRateLoading={userRateLoading}
          />
        </Col>
      </Form.Row>
      <Form.Row className="my-0">
        <Form.Label column="lg" lg={5} className="pb-1 my-0">
          {"Total"}
        </Form.Label>
        <Col>
          <FormsField
            name="totalHours"
            disabled={true}
            type="number"
            formGroupClassName="pb-1 my-0"
            className="text-right"
          />
        </Col>
        <Col>
          <FormsField
            name={"totalCost"}
            disabled={true}
            formGroupClassName="pb-1 my-0"
            className="text-right"
          />
        </Col>
      </Form.Row>
      {buckets.map((b) => (
        <HoursBucket label={b.label} name={b.name} key={b.name} />
      ))}
      <Form.Row className={"justify-content-between border-top pt-2"}>
        <Button variant="success" onClick={submitForm}>
          Save
        </Button>
        {estimateId ? (
          <Button variant="primary" onClick={() => onSaveAsNew(getValues())}>
            Save as new record
          </Button>
        ) : null}
        <Button variant="info" onClick={easyButtonClicked}>
          Easy 2 hour project
        </Button>
      </Form.Row>
    </Form>
  );
}

function HoursBucket({ label, name }) {
  return (
    <Form.Row className="my-0">
      <Form.Label column="sm" lg={5} className="pb-1 my-0">
        {label}
      </Form.Label>
      <Col>
        <FormsField
          name={name}
          type="number"
          size="sm"
          formGroupClassName="pb-1 my-0"
          className="text-right"
        />
      </Col>
      <Col>
        <FormsField
          name={name + "Cost"}
          size="sm"
          disabled={true}
          formGroupClassName="pb-1 my-0"
          className="text-right"
        />
      </Col>
    </Form.Row>
  );
}

function RateUserIdSelect({ name, id = name, label, setChanged, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
    setValue,
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}

      <User.Select
        {...field}
        disabled={isDisabled}
        onChange={(v) => {
          onChange(v);
          setValue("rate", 0);
        }}
        {...props}
        isClearable
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function RateInput({ name, id = name, label, userRateLoading }) {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name });
  const { setValue } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <FormsField
        name={name}
        type="number"
        readOnly={userRateLoading}
        onChange={(v) => {
          onChange(v || 0);
          setValue("rateUserId", "");
        }}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function OwnerSelect({ name, id = name, label, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <User.Select
        {...field}
        disabled={isDisabled}
        onChange={(v) => {
          onChange(v);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function TypeSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value="B">Ballpark</option>
        <option value="A">Actual</option>
        <option value="I">Internal</option>
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function ActiveStatusSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Status"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value="D">Draft</option>
        <option value="P">Published</option>
        <option value="A">Archived</option>
        <option value="V">Voided</option>
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function ProjectEstimateStatusSelect({ name, id = name, label, isInvalid }) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();
  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <EstimateStatusSelect
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        errors={error?.message}
      />
    </Form.Group>
  );
}
