import { useUserGrouping, useUserGroupings } from "./hooks";
import userGroupingState from "./state";
import UserGroupingEditor from "./UserGroupingEditor";
import UserGroupingNew from "./UserGroupingNew";
import UserGroupingSelect from "./UserGroupingSelect";
import UserGroupingTable from "./UserGroupingTable";

const UserGrouping = {
  // API
  api: userGroupingState.api,
  // State
  actions: userGroupingState.actions,
  selectors: userGroupingState.entity.selectors,
  // Hooks
  useOne: useUserGrouping,
  useAll: useUserGroupings,
  // Components
  New: UserGroupingNew,
  Editor: UserGroupingEditor,
  Table: UserGroupingTable,
  Select: UserGroupingSelect,
};

export default UserGrouping;
