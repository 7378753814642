import React from "react";

import Page from "../../common/pages/Page";
import DbcNavSidebar from "./DbcNavSidebar";

export default function DbcIndex() {
  return (
    <div>
      <Page
        title="DBC Source Code Management"
        navSidebar={<DbcNavSidebar />}
      ></Page>
    </div>
  );
}
