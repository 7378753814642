import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

export default function ProjectAlerts({ project }) {
  const transferred =
    project?.transferredFolder && project?.transferredFolder !== "CURRENT";
  return (
    <>
      {project?.lockedBy ? (
        <Row>
          <Col>
            <Alert variant="danger">{project.lockedBy}</Alert>
          </Col>
        </Row>
      ) : null}
      {transferred ? (
        <Row>
          <Col>
            <Alert variant="warning">
              Project is transferred. Changes are disallowed.
            </Alert>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
