import _ from "lodash";

import timeTypes from "./timeTypes";

export const TIME_LISTING_TYPES = {
  INTERNAL: "Internal",
  TBD: "TBD",
  BILLABLE: "Billable",
  NON_BILLABLE_SHOWN: "Non-Billable",
  NON_BILLABLE_HIDDEN: "Non-Billable (Hidden)",
};

export function getTimeType(time) {
  if (_.isUndefined(time) || _.isNull(time)) return null;

  if (time.timeType === timeTypes.internal.value) {
    return TIME_LISTING_TYPES.INTERNAL;
  } else if (time.timeType === timeTypes.customer.value) {
    return TIME_LISTING_TYPES.TBD;
  } else if (time.isBillable) {
    return TIME_LISTING_TYPES.BILLABLE;
  } else if (time.isInternalCr || time.showOnInvoice) {
    return TIME_LISTING_TYPES.NON_BILLABLE_SHOWN;
  } else {
    return TIME_LISTING_TYPES.NON_BILLABLE_HIDDEN;
  }
}
