import shark from "../../../apis/shark";

function getContractProductDefaultCrsAPI({ contractProductId } = {}) {
  const args = {
    ...(contractProductId ? { contractProductId: contractProductId } : null),
  };

  const payload = {
    command: "getContractProductDefaultCrs",
    ...args,
  };

  return shark.post(payload).then((response) => ({
    data: response.responseData.contractProductDefaultCrs,
    searchArgs: args,
  }));
}

function getContractProductDefaultCrAPI({ id }) {
  const payload = {
    command: "getContractProductDefaultCr",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createContractProductDefaultCrAPI(contractProductDefaultCr) {
  const payload = {
    command: "createContractProductDefaultCr",
    ...contractProductDefaultCr,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateContractProductDefaultCrAPI(contractProductDefaultCr) {
  const payload = {
    command: "updateContractProductDefaultCr",
    ...contractProductDefaultCr,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getContractProductDefaultCrsAPI,
  getOne: getContractProductDefaultCrAPI,
  createOne: createContractProductDefaultCrAPI,
  updateOne: updateContractProductDefaultCrAPI,
};
