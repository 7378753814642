import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { checkTokenApi, loginApi, logoutApi, resetPasswordApi } from "./api";

export const login = createAsyncThunk(
  "auth/login",
  async (args, { rejectWithValue }) => {
    try {
      const { responseData } = await loginApi(args);
      toast.success("Successfully logged in.");
      return responseData;
    } catch (err) {
      return rejectWithValue(err?.message);
    }
  },
);

export const checkToken = createAsyncThunk(
  "auth/checkToken",
  async (_, { rejectWithValue }) => {
    try {
      const { responseData } = await checkTokenApi();
      return responseData;
    } catch (err) {
      return rejectWithValue(err?.message);
    }
  },
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const { responseMessage } = await logoutApi();
  toast.success("Successfully logged out.");
  return responseMessage;
});

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (args) => {
    const { responseMessage } = await resetPasswordApi(args);
    toast.success("New password sent to applicable email.");
    return responseMessage;
  },
);
