import { combineReducers } from "@reduxjs/toolkit";

import branchesState from "../branches/state";
import codeTestsState from "../codeTests/state";
import reusableProgramsState from "../reusablePrograms/state";
import sourceCodesState from "../sourceCodes/state";

const dbcReducer = combineReducers({
  branches: branchesState.reducer,
  codeTests: codeTestsState.reducer,
  sourceCodes: sourceCodesState.reducer,
  reusablePrograms: reusableProgramsState.reducer,
});

const dbcState = {
  reducer: dbcReducer,
};

export default dbcState;
