import { useTeam, useTeams } from "./hooks";
import state from "./state";
import TeamDescription from "./TeamDescription";
import TeamSelect from "./TeamSelect";
import TeamTableFilter from "./TeamTableFilter";

const Team = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useTeam,
  useAll: useTeams,
  // Components
  Select: TeamSelect,
  Description: TeamDescription,
  TableFilter: TeamTableFilter,
};
export default Team;
