import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Time from "../../time";
import TimeNavSidebar from "./TimeNavSidebar";

export default function TimeUnassigned() {
  const loggedInUser = useSelector((state) => state.auth.user);
  const [startDate, endDate] = useMemo(
    () => [moment().month(-1).startOf("month"), moment().endOf("month")],
    [],
  );
  const { times, loading, getTimes } = Time.useAll({
    filter: {
      userId: loggedInUser,
      startDate,
      endDate,
      timeType: "Customer",
    },
  });
  return (
    <Page
      title="Unassigned Time"
      lead={`${_date.display(startDate)} - ${_date.display(endDate)}`}
      navSidebar={<TimeNavSidebar />}
    >
      <Time.Table
        times={times}
        layout={[
          "date",
          "customer",
          "billingNote",
          "isBillable",
          "totalHours",
          "nonTimerHours",
        ]}
        isLoading={loading}
        onRefresh={getTimes}
      />
    </Page>
  );
}
