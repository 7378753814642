import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import queryToString from "../../common/functions/queryToString";
import useIntervalUpdateComponent from "../../common/hooks/useIntervalUpdateComponent";
import Page from "../../common/pages/Page";
import Project from "../../projects";
import ProjectFilters from "../../projects/ProjectFilters";
import useSettings from "../../settings/useSettings";
import ProjectNavSidebar from "./ProjectNavSidebar";

export default function ProjectIndex() {
  const { settings, loading: loadingSettings } = useSettings();

  const filter = useSelector(
    (state) => state.projects.projects.filter,
    shallowEqual,
  );
  const { projects, loading, hasMore, getMore, reset, lastUpdate } =
    Project.useSearch({
      filter,
      blocked: _.isEmpty(filter) || loadingSettings,
    });

  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(filter) && !_.isUndefined(settings) && !loadingSettings) {
      const {
        projectFilterOwner,
        projectFilterStatusId,
        projectFilterChampionId,
        projectFilterGroupId,
      } = settings;
      const filterDefault = {
        folderId: "CURRENT",
        owners: projectFilterOwner ? [projectFilterOwner] : [],
        projectStatuses: projectFilterStatusId ? [projectFilterStatusId] : [],
        champions: projectFilterChampionId ? [projectFilterChampionId] : [],
        groups: projectFilterGroupId ? [projectFilterGroupId] : [],
      };
      dispatch(Project.actions.setFilter(filterDefault));
    }
  }, [filter, settings, loadingSettings, dispatch]);

  useIntervalUpdateComponent(15000);

  return (
    <Page
      title="Projects"
      lead={queryToString(filter)}
      navSidebar={<IndexNavSidebar filter={filter} />}
      tableOfContents={false}
    >
      <Row>
        <Col>
          <Project.Table
            projects={projects}
            layout={[
              "id",
              "date",
              "customer",
              "owner",
              "statusExtended",
              "synopsis",
            ]}
            isLoading={loading}
            hasMore={hasMore}
            getMore={getMore}
            onRefresh={reset}
            refreshLabel={"Fetched " + moment(lastUpdate).fromNow()}
          />
        </Col>
      </Row>
    </Page>
  );
}

function IndexNavSidebar({ filter }) {
  const dispatch = useDispatch();

  function submitHandler(filter) {
    dispatch(Project.actions.setFilter(filter));
    setShow(false);
  }
  const [show, setShow] = useState(false);
  return (
    <ProjectNavSidebar>
      <Button
        variant="link"
        className="text-secondary text-decoration-none px-0"
        onClick={() => setShow(true)}
        title="Project Query Filters"
      >
        Filters
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Project Query Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectFilters values={filter} onSubmit={submitHandler} />
        </Modal.Body>
      </Modal>
    </ProjectNavSidebar>
  );
}
