import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
} from "../../common/forms";
import TableLayoutEditorCom from "../../common/tables/TableLayoutEditor";
import useCrTableColumns from "../useCrTableColumns";
import crTableLayoutSchema from "./tableLayoutSchema";

export default function TableLayoutEditor({
  crTableLayout: { id, ...layout } = {},
  onSubmit,
  onSearch,
  isLoading,
  defaultLayout,
}) {
  return (
    <Forms
      onSubmit={({ formSubmitType, formSubmitDirty, ...values }) => {
        const func = formSubmitType === "search" ? onSearch : onSubmit;
        return func(values, {
          isNew: formSubmitType === "new",
          isDirty: formSubmitDirty,
        });
      }}
      defaultValues={{ id, ...layout }}
      schema={{ schema: crTableLayoutSchema }}
      isDisabled={isLoading}
    >
      <LayoutForm id={id} isLoading={isLoading} defaultLayout={defaultLayout} />
    </Forms>
  );
}

function LayoutForm({ id: currentId, isLoading, defaultLayout }) {
  const {
    watch,
    setValue,
    submitForm,
    formState: { dirtyFields },
  } = useFormContext();

  const isFieldsDirty = !isEmpty(dirtyFields);

  const [showSave, setShowSave] = useState(false);

  const id = watch("id");
  const isNew = id !== currentId;

  return (
    <Row>
      <Col>
        <Form
          onSubmit={(e) => {
            setValue("formSubmitType", "search");
            setValue("formSubmitDirty", isFieldsDirty);
            submitForm(e);
          }}
        >
          <Row className="justify-content-end pt-2">
            <Col xs="auto">
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onClick={() => setShowSave(!showSave)}
                  tabIndex={-1}
                >
                  Save
                </Button>
                <Button variant="primary" type="submit">
                  Search
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ColumnEditor name="columns" defaultLayout={defaultLayout} />
            </Col>
          </Row>
          <Modal
            show={showSave}
            onHide={() => setShowSave(false)}
            className="modal2"
            backdropClassName="modal2-backdrop"
          >
            <Modal.Header className="lead" closeButton>
              Save CR Table Layout
            </Modal.Header>
            <Modal.Body>
              <SaveForms
                onHide={() => setShowSave(false)}
                isNew={isNew}
                isLoading={isLoading}
                isFieldsDirty={isFieldsDirty}
              />
            </Modal.Body>
          </Modal>
        </Form>
      </Col>
    </Row>
  );
}

function ColumnEditor({ name }) {
  const { columns, defaultLayout } = useCrTableColumns();
  const {
    field: { value, onChange },
  } = useController({ name });
  return (
    <TableLayoutEditorCom
      columns={columns}
      layout={value?.map((c) => c.column)}
      onChange={(layout) =>
        onChange(layout.map((c, idx) => ({ id: idx, column: c })))
      }
      defaultLayout={defaultLayout}
    />
  );
}

function SaveForms({ onHide, isNew, isLoading, isFieldsDirty }) {
  const {
    submitForm,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  // useSubmitKeys({
  //   onSubmit: () => {
  //     onHide();
  //     setFieldValue("formSubmitType", isNew ? "new" : "save");
  //   },
  // });

  return (
    <Row>
      <Col>
        <FormsField
          name="id"
          label="Layout"
          autoComplete="off"
          placeholder="Filter ID..."
        />
        <FormsField
          label="Description"
          name="description"
          placeholder="Filter Description..."
        />

        <Button
          variant="success"
          disabled={isLoading || isSubmitting || !isFieldsDirty}
          onClick={() => {
            onHide();
            setValue("formSubmitType", isNew ? "new" : "save");
            setValue("formSubmitDirty", isFieldsDirty);
            submitForm();
          }}
        >
          {isNew ? "Save as" : "Save"}
        </Button>
      </Col>
    </Row>
  );
}
