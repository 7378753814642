import moment from "moment";

import { _datetime } from "../common/functions/dates";

export default function getCrPriorityClassName(cr) {
  function getMoment(hours) {
    let t = moment().add(hours, "hours");
    const dayStart = moment().hours(8).minutes(0);
    const dayEnd = moment().hours(17).minutes(0);
    if (t < dayStart) return dayStart;
    else if (t > dayEnd) return dayStart.add(1, "days");
    else return t;
  }

  if (!cr) return "";
  const {
    statusId,
    projectPriorityId,
    targetStartWorkDateTime,
    targetFirstContactDateTime,
    actualStartWorkDateTime,
    actualFirstContactDateTime,
    isPracticeUpgradeCr,
  } = cr;

  if (statusId === "CL" || statusId === "FX") return "";
  const now = getMoment(0);
  const nowHour = getMoment(1);

  const startWorkDateTime = _datetime.fromStamp(targetStartWorkDateTime);
  const firstContactDateTime = _datetime.fromStamp(targetFirstContactDateTime);

  if (projectPriorityId === "EM" || projectPriorityId === "EF")
    return "bg-dark text-white";
  else if (projectPriorityId === "CR") return "bg-danger text-white";
  else if (projectPriorityId === "PR" || projectPriorityId === "IM")
    return "table-danger";
  else if (isPracticeUpgradeCr) return "table-primary";
  else if (
    (!actualStartWorkDateTime && startWorkDateTime < now) ||
    (!actualFirstContactDateTime && firstContactDateTime < now)
  )
    return "table-warning";
  else if (
    (!actualStartWorkDateTime && startWorkDateTime < nowHour) ||
    (!actualFirstContactDateTime && firstContactDateTime < nowHour)
  )
    return "bg-warning";
  else return "";
}
