import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsRegionNavSidebar from "./AwsRegionNavSidebar";

export default function AwsRegionEdit() {
  const id = String(useParams().id).toLowerCase();
  const { awsRegion, loading, updateOne } = Server.AwsRegion.useOne({
    id,
    canGet: true,
  });
  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Region"
        navSidebar={<AwsRegionNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Aws Region" navSidebar={<AwsRegionNavSidebar />}>
      {awsRegion ? (
        <>
          <Server.AwsRegion.Editor
            values={awsRegion}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={awsRegion} />
        </>
      ) : (
        <h4>Aws Region not found.</h4>
      )}
    </Page>
  );
}
