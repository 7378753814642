import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";

export default function UserActivityTable({
  userActivity,
  layout = ["dateTime", "source", "info", "description"],
  initialSort = { id: "dateTime", desc: true },
  ...props
}) {
  const columns = React.useMemo(
    () => [
      {
        id: "dateTime",
        Header: "Date",
        accessor: "dateTime",
        disableFilters: true,

        Cell: ({ value }) => (
          <span>{_datetime.displayDateTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        id: "source",
        Header: "Log Source",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: (t) => {
          switch (t.source) {
            case "PR":
              return "Project " + t.key;
            case "CR":
              return "CR " + t.key;
            default:
              return t.key || null;
          }
        },
        aggregate: "uniqueCount",
        Cell: ({ row: { original: { key: id, source } = {} }, value }) => {
          if (source === "CR")
            return (
              <Link className="text-reset" to={{ pathname: `/crs/${id}` }}>
                {value || null}
              </Link>
            );
          else if (source === "PR")
            return (
              <Link className="text-reset" to={{ pathname: `/projects/${id}` }}>
                {value || null}
              </Link>
            );
          else {
            return <span>{value || null}</span>;
          }
        },
      },
      {
        id: "info",
        Header: "Information",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: "moreInfo",

        Cell: ({ value }) => {
          return (
            <div style={{ maxWidth: "400px", wordWrap: "break-word" }}>
              {value || null}
            </div>
          );
        },
      },
      {
        id: "description",
        Header: "Log Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "type",
        Header: "Log Type",
        accessor: (l) => l.type || null,
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "count",
      },
      {
        id: "userId",
        Header: "User",
        accessor: "userId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: "duration",
        disableFilters: true,
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Table
              hasCount
              bordered
              hover
              size="sm"
              label="User Activity"
              columns={columns}
              initialSort={initialSort}
              layout={layout}
              data={userActivity}
              moveFooterToTop
              getRowProps={(row) => {
                if (row?.original?.read) {
                  return { className: "table-secondary" };
                }
              }}
              {...props}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
