import { entitySelect } from "../../common/entity";
import { useGroups } from "./hooks";

const GroupSelect = entitySelect({
  name: "group",
  useEntities: useGroups,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default GroupSelect;
