import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import User from "../../../users";
import UserGroupingNavSidebar from "./UserGroupingNavSidebar";

export default function UserGroupingNew() {
  const [, setRedirect] = useRedirect();
  const { isManager, isAdmin, canMaintainContracts } = useSelector(
    (state) => state.auth,
  );

  if (!(isManager || isAdmin || canMaintainContracts))
    return (
      <Page
        title="User Grouping"
        navSidebar={<UserGroupingNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="User Grouping" navSidebar={<UserGroupingNavSidebar />}>
      <User.UserGrouping.New
        onNew={({ id }) => setRedirect(`/users/user-groupings/${id}`)}
      />
    </Page>
  );
}
