import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import Type from "../../projects/types";
import Group from "../groups";
import RealUserSelect from "../RealUserSelect";
import pseudoUserSchema from "./pseudoSchema";

export default function PseudoEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: pseudoUserSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <PseudoUserForm isNew={isNew} />
    </Forms>
  );
}

function PseudoUserForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="inactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField name="name" label="Name" autoComplete="off" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField.Select
                name="defaultGroup"
                label="Default Group"
                as={Group.Select}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField.Select
                name="owner"
                label="Owner"
                as={RealUserSelect}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField.Select
                name="allowedTypes"
                label="Allowed Types (No longer in use)"
                as={Type.Select}
                isMulti={true}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
