import _ from "lodash";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";

function selectComponents({ isInvalid, isValid }) {
  let classNames = [];
  if (isInvalid) classNames.push("is-invalid border border-danger rounded");
  if (isValid) classNames.push("is-valid border border-success rounded");
  const Control = React.memo(({ className, ...props }) => (
    <components.Control
      className={[className].concat(classNames).join(" ")}
      {...props}
    />
  ));
  Control.displayName = "FieldSelectControl";
  const DropdownIndicator = React.memo(({ ...props }) => (
    <components.DropdownIndicator
      className={isInvalid ? "text-danger" : isValid ? "text-success" : ""}
      {...props}
    />
  ));
  DropdownIndicator.displayName = "FieldSelectDropdownIndicator";
  return { Control, DropdownIndicator };
}

const FieldSelect = React.forwardRef(
  (
    {
      id,
      name,
      isInvalid = false,
      isValid = false,
      isMulti = false,
      className = "",
      onChange,
      label,
      errors,
      disabled,
      formGroupClassName = "",
      menuPlacement = "auto",
      components: componentsInitial,
      ...props
    },
    ref,
  ) => {
    let classNames = [];
    if (className) classNames.push(className);
    if (isInvalid) classNames.push("is-invalid");
    if (isValid) classNames.push("is-valid");
    if (isMulti) classNames.push("basic-multi-select");
    else classNames.push("basic-select");

    const components = useMemo(
      () => ({
        ...selectComponents({ isInvalid, isValid }),
        ...componentsInitial,
      }),
      [isInvalid, isValid, componentsInitial],
    );

    function handleChange(opt) {
      const getValue = (v) => (_.isNull(v) || _.isUndefined(v) ? "" : v);
      if (isMulti)
        if (opt === null) onChange([]);
        else onChange(opt.map((o) => getValue(o.value)));
      else onChange(getValue(opt?.value));
    }

    return (
      <Form.Group className={formGroupClassName}>
        {label ? <Form.Label htmlFor={id}>{label}</Form.Label> : null}
        <Select
          ref={ref}
          id={id}
          name={name ? name : id}
          className={classNames.join(" ")}
          classNamePrefix="select"
          onChange={handleChange}
          isMulti={isMulti}
          isDisabled={disabled}
          menuPlacement={menuPlacement}
          innerProps={{ isInvalid, isValid }}
          components={components}
          {...props}
        />
        {errors ? (
          <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
        ) : null}
      </Form.Group>
    );
  },
);

FieldSelect.displayName = "FieldSelect";
export default FieldSelect;

export function OptionExtended(props) {
  const { data } = props;
  return (
    <components.Option {...props}>
      <ul className="list-unstyled mb-0">
        <li>{data.title ? data.title : data.label}</li>
        {data?.info ? (
          <li className="mt-0" style={{ lineHeight: "10px" }}>
            <small>{data.info}</small>
          </li>
        ) : null}
      </ul>
    </components.Option>
  );
}
