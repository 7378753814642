import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import useSearchParams from "../../common/hooks/useSearchParams";
import NavSidebarItem from "../../common/navs/NavSidebarItem";
import Page from "../../common/pages/Page";
import Time from "../../time";
import TimeNavSidebar from "./TimeNavSidebar";

export default function TimeEdit() {
  const id = useParams().id;
  const openTimers = useSearchParams("openTimers");
  let history = useHistory();
  return (
    <Page
      title={"Time: " + id}
      navSidebar={
        <TimeNavSidebar>
          <div className="dropdown-divider" />
          <NavSidebarItem
            className="font-weight-bold"
            to={`/time/${id}`}
          >{`Time ${id}`}</NavSidebarItem>
        </TimeNavSidebar>
      }
    >
      <Row>
        <Col>
          <Time.Modify
            timeId={id}
            openTimers={openTimers}
            onSaveSuccess={() => history.push("/home")}
            onCancel={() => history.push("/home")}
            onDelete={() => history.push("/home")}
          />
        </Col>
      </Row>
    </Page>
  );
}
