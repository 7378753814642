import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import CustomerNavSidebar from "../CustomerNavSidebar";

export default function ThirdPartyLicenseNavSidebar({ children, customerId }) {
  return (
    <CustomerNavSidebar>
      <NavSidebarItem to={`/customers/third-party-licenses`}>
        Third Party Licenses
      </NavSidebarItem>
      <NavSidebarItem
        to={`/customers/third-party-licenses/new${
          customerId ? `?customerId=${customerId}` : ""
        }`}
      >
        New License
      </NavSidebarItem>
      <div className="dropdown-divider"></div>
      {children}
    </CustomerNavSidebar>
  );
}
