import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";

function NotificationsTable({
  notifications = [],
  layout = ["dateTime", "creator", "message"],
  initialSort = { id: "dateTime", desc: true },
  ...props
}) {
  const columns = React.useMemo(
    () => [
      {
        id: "dateTime",
        Header: "Date",
        accessor: "createDateTime",
        disableFilters: true,

        Cell: ({ value }) => (
          <span>{_datetime.displayDateTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        id: "type",
        Header: "Type",
        disableFilters: false,
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: (t) => {
          switch (t.notifyPartySource) {
            case "CM":
              return "Champion";
            case "EMP":
              return "User";
            case "PSU":
              return "Team";
            case "EMG":
              return "Emergency";
            default:
              return "Error: " + t.notifyPartySource;
          }
        },
      },
      {
        id: "message",
        Header: "Message",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: "message",
        Cell: (o) =>
          (function (o) {
            return (
              <Link
                className="text-decoration-none"
                to={{ pathname: `${o?.row?.original?.link}` }}
                //onClick={e => {
                //  e.preventDefault();
                //  onSelect(o?.row?.original?.id);
                //}}
              >
                {o.value}
              </Link>
            );
          })(o),
      },
      {
        Header: "Cause",
        id: "creator",
        accessor: "creator",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "read",
        Header: "Read",
        accessor: (t) => {
          if (t.read) {
            return "\u2714";
          } else {
            return "";
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Table
              hasCount
              bordered
              hover
              size="sm"
              label="Notifications"
              columns={columns}
              initialSort={initialSort}
              layout={layout}
              data={notifications}
              moveFooterToTop
              getRowProps={(row) => {
                if (row?.original?.read) {
                  return { className: "table-secondary" };
                }
              }}
              {...props}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default React.memo(NotificationsTable);
