import moment from "moment";
import React from "react";
import { useParams } from "react-router";

import Page from "../../common/pages/Page";
import Customer from "../../customers";
import Project from "../../projects";
import CustomerShowNavSidebar from "./CustomerShowNavSidebar";

export default function CustomerShowProjects() {
  const id = parseInt(useParams().id);
  const { customer } = Customer.useOne({ id });
  const { projects, loading, hasMore, getMore, lastUpdate, reset } =
    Project.useSearch({
      filter: {
        folderId: "CURRENT",
        customers: [id.toString().padStart(6, "0")],
      },
    });

  return (
    <Page
      title="Customer Projects"
      navSidebar={<CustomerShowNavSidebar id={id} />}
      lead={customer?.name}
    >
      <Project.Table
        projects={projects}
        isLoading={loading}
        hasMore={hasMore}
        getMore={getMore}
        onRefresh={reset}
        layout={["date", "id", "group", "status", "synopsis", "owner"]}
        initialSort={[{ id: "date", desc: true }, { id: "id" }]}
        refreshLabel={"Fetched " + moment(lastUpdate).fromNow()}
      />
    </Page>
  );
}
