import shark from "../../../apis/shark";

function getTaxProductsAPI() {
  const payload = {
    command: "getTaxProducts",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.taxProducts,
  }));
}

function getTaxProductAPI({ id }) {
  const payload = {
    command: "getTaxProduct",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createTaxProductAPI(taxProduct) {
  const payload = {
    command: "createTaxProduct",
    ...taxProduct,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateTaxProductAPI(taxProduct) {
  const payload = {
    command: "updateTaxProduct",
    ...taxProduct,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getTaxProductsAPI,
  getOne: getTaxProductAPI,
  createOne: createTaxProductAPI,
  updateOne: updateTaxProductAPI,
};
