import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "favorites",
  type: "crs/filters/favorites",
  getState: (state) => state.crs.filters.favorites,
  adapterConfig: { selectId: (e) => e.crFilterId },
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const favoriteState = {
  api,
  entity,
  actions,
  reducer,
};

export default favoriteState;
