import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Settings from "./Settings";
import SettingsCache from "./SettingsCache";
import SettingsWatched from "./SettingsWatched";

export default function SettingsRouting() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/watched`}>
        <SettingsWatched />
      </Route>
      <Route path={`${path}/cache`}>
        <SettingsCache />
      </Route>
      <Route exact path={`${path}`}>
        <Settings />
      </Route>
    </Switch>
  );
}
