import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getCrModules" }).then((r) => {
      return { data: r.responseData.crModules };
    });
  },
};

const entity = createEntity({
  name: "module",
  type: "crs/modules",
  getState: (state) => state.crs.modules,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const modulesState = {
  api,
  entity,
  actions,
  reducer,
};

export default modulesState;
