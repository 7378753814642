import React from "react";
import { Modal } from "react-bootstrap";

import ExpenseEditor from "./ExpenseEditor";

export default function ExpenseEditorModal({ title, show, onHide, ...props }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExpenseEditor onCancel={onHide} {...props} />
      </Modal.Body>
    </Modal>
  );
}
