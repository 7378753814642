import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import app from "../../app/index";
import { _datetime } from "../../common/functions/dates";
import { currentTimerActions } from "./state";

export default function useCurrentTimer({ canGet = false } = {}) {
  const dispatch = useDispatch();
  const currentTimerX = useSelector((state) => state.times.currentTimer.timer);
  const loading = useSelector((state) => state.times.currentTimer.loading);
  const lastUpdate = useSelector(
    (state) => state.times.currentTimer.lastUpdate,
  );
  const canExpire = app.useCanStateExpire();

  const getCurrentTimer = useCallback(() => {
    return dispatch(currentTimerActions.getCurrentTimer());
  }, [dispatch]);

  const shouldGet =
    canGet === true &&
    (lastUpdate === null ||
      (moment().diff(lastUpdate, "seconds") > 90 && canExpire));

  useEffect(() => {
    if (shouldGet) getCurrentTimer();
  }, [shouldGet, getCurrentTimer]);

  let currentTimer = {};

  if (currentTimerX !== undefined && currentTimerX.startTime !== undefined) {
    currentTimer = { ...currentTimerX };
    currentTimer.startTime = _datetime.fromStamp(currentTimer.startTime);

    if (currentTimer.endTime === "") {
      currentTimer.minutes = moment(new Date()).diff(
        currentTimer.startTime,
        "minutes",
      );
      currentTimer.status = "running";
    } else {
      currentTimer.minutes = _datetime
        .fromStamp(currentTimer.endTime)
        .diff(currentTimer.startTime, "minutes");
      currentTimer.status = "stopped";
      currentTimer.endTime = _datetime.fromStamp(currentTimer.endTime);
    }
  }
  return { currentTimer, loading, lastUpdate, getCurrentTimer };
}
