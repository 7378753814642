import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getBranches" }).then((response) => {
      return { data: response.responseData.branches };
    });
  },
  getOne: ({ id }) => {
    return shark.post({ command: "getBranch", id: id }).then((response) => {
      return { data: response.responseData };
    });
  },
};

const entity = createEntity({
  name: "branches",
  type: "dbc/branches",
  getState: (state) => state.dbc.branches,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const branchState = {
  api,
  entity,
  actions,
  reducer,
};

export default branchState;
