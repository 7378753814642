import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import InvoicesIndex from "./InvoicesIndex";
import TaxProductCategoriesPages from "./taxProductCategories";
import TaxProductsPages from "./taxProducts";

export default function InvoicesRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/tax-product-categories`}>
        <TaxProductCategoriesPages />
      </Route>
      <Route path={`${path}/tax-products`}>
        <TaxProductsPages />
      </Route>
      <Route exact path={`${path}`}>
        <InvoicesIndex />
      </Route>
    </Switch>
  );
}
