import { useSourceCode, useSourceCodes } from "./hooks";
import SourceCodeEditor from "./SourceCodeEditor";
import SourceCodeSelect from "./SourceCodeSelect";
import SourceCodeTable from "./SourceCodeTable";
import SourceCodeTableFilter from "./SourceCodeTableFilter";
import sourceCodeState from "./state";

const SourceCode = {
  // Backend API
  api: sourceCodeState.api,
  // State
  actions: sourceCodeState.actions,
  selectors: sourceCodeState.entity.selectors,
  // Hooks
  useAll: useSourceCodes,
  useOne: useSourceCode,
  // Components
  Select: SourceCodeSelect,
  TableFilter: SourceCodeTableFilter,
  Table: SourceCodeTable,
  Editor: SourceCodeEditor,
};

export default SourceCode;
