import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import Mailto from "../common/details/Mailto";
import Tel from "../common/details/Tel";
import { formatPhone } from "../common/functions/phone";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Team from "./teams";
import UserLink from "./UserLink";

export default function UserTable({ users, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value }) => <UserLink id={value} showId={true} />,
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Team",
        accessor: "teamId",
        Filter: Team.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Team.Description id={value} />,
      },
      {
        Header: "Manager",
        accessor: "managerId",
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ value }) => <UserLink id={value} showId={true} />,
      },
      {
        Header: "Location",
        accessor: "locationDescription",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Phones",
        Header: "Phone(s)",
        disableFilter: true,
        accessor: (contact) => {
          const phones = [
            {
              phone: formatPhone(
                contact?.phoneHome,
                contact?.phoneHomeExtension,
              ),
              type: "Home",
            },

            {
              phone: formatPhone(
                contact?.phoneWork,
                contact?.phoneWorkExtension,
              ),
              type: "Work",
            },
            {
              phone: formatPhone(
                contact?.phoneCell,
                contact?.phoneCellExtension,
              ),
              type: "Cell",
            },
            {
              phone: formatPhone(
                contact?.phoneOther,
                contact?.phoneOtherExtension,
              ),
              type: "Other",
            },
          ];
          return phones.filter((p) => p.phone);
        },

        Cell: ({ value }) => (
          <Row>
            <Col>
              {value.map((p, index) => (
                <Row key={`phone${index}`}>
                  <Col>
                    <Tel phone={p.phone} />
                    <small> ({p.type})</small>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        ),
      },
      {
        id: "Email",
        Header: "Email",
        accessor: "email",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value }) => <Mailto email={value} />,
      },
    ],
    [],
  );

  return (
    <Table hasCount label="Users" columns={columns} data={users} {...props} />
  );
}
