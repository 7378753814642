import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getTradingPartners" }).then((response) => {
      return { data: response.responseData.tradingPartners };
    });
  },
  getOne: ({ id }) => {
    return shark
      .post({ command: "getTradingPartner", id: id })
      .then((response) => {
        return { data: response.responseData };
      });
  },
  createOne: (tradingPartner) => {
    return shark
      .post({ command: "createTradingPartner", ...tradingPartner })
      .then((response) => {
        return { data: response.responseData };
      });
  },
  updateOne: (tradingPartner) => {
    return shark
      .post({ command: "updateTradingPartner", ...tradingPartner })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "tradingPartners",
  type: "edi/tradingPartners",
  getState: (state) => state.edi.tradingPartners,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const tradingPartnerState = {
  api,
  entity,
  actions,
  reducer,
};

export default tradingPartnerState;
