import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getExpenseTypes" }).then((response) => {
      return { data: response.responseData.expenseTypes };
    });
  },
  getOne: ({ id }) => {
    return shark
      .post({ command: "getExpenseType", id: id })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "types",
  type: "expenses/types",
  getState: (state) => state.expenses.types,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const typeState = {
  api,
  entity,
  actions,
  reducer,
};

export default typeState;
