import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { createEntityHooks } from "../../common/entity";
import state from "./state";

const { useEntities: useThirdPartyLicenses, useEntity } = createEntityHooks({
  entity: state.entity,
  actions: state.actions,
});

const useThirdPartyLicense = ({ id, ...args } = {}) => {
  const dispatch = useDispatch();
  return {
    createComment: useCallback(
      (args) =>
        dispatch(
          state.actions.createComment({
            customerThirdPartyLicense: id,
            ...args,
          }),
        ).then(unwrapResult),
      [dispatch, id],
    ),
    updateComment: useCallback(
      (args) =>
        dispatch(
          state.actions.updateComment({
            customerThirdPartyLicense: id,
            ...args,
          }),
        ).then(unwrapResult),
      [dispatch, id],
    ),
    ...useEntity({ id, ...args }),
  };
};

export { useThirdPartyLicense, useThirdPartyLicenses };
