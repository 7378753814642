import _, { sortBy } from "lodash";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

import FieldSelect from "../common/fields/FieldSelect";
import { useUsers } from "./hooks";

const RealUserSelect = React.forwardRef(
  (
    {
      value,
      realUser = true,
      internal = false,
      isChampion,
      isContractor,
      isMulti = false,
      placeholder = "Select User...",
      hasMeButton = false,
      onChange,
      label,
      shouldValuesSortLikeOptions = false,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    const { users, loading: loadingUsers } = useUsers();

    const user = useSelector((state) => state.auth.user);

    const _options = (realUser ? sortBy(users, "id") : [])
      .filter((e) => {
        if (!_.isUndefined(isChampion) && isChampion !== e.isChampion)
          return false;
        if (!_.isUndefined(isContractor) && isContractor !== e.isContractor)
          return false;
        if (e?.isInternalUser === true && internal === false) return false;

        return e.inactive === false || e.id === value;
      })
      .map((f) => {
        return { label: f.id + ": " + f.name, value: f.id };
      });

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = shouldValuesSortLikeOptions
          ? _options.filter((o) => value.includes(o.value))
          : value.map((v) => _.find(_options, (o) => o.value === v));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    let fs = (
      <FieldSelect
        ref={ref}
        isLoading={loadingUsers}
        isSearchable={true}
        value={valueOption}
        options={_options}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={onChange}
        isDisabled={isDisabled}
        {...props}
      />
    );

    return (
      <>
        {hasMeButton ? (
          <>
            {label ? <Form.Label>{label}</Form.Label> : null}
            <Form.Row className="p-0 m-0">
              <Col className="p-0 m-0">{fs}</Col>
              <Col xs="auto" className="p-0 m-0">
                {hasMeButton ? (
                  <Button onClick={() => onChange(user)} disabled={isDisabled}>
                    Me
                  </Button>
                ) : null}
              </Col>
            </Form.Row>
          </>
        ) : (
          <>
            {label ? <Form.Label>{label}</Form.Label> : null}
            {fs}
          </>
        )}
      </>
    );
  },
);

RealUserSelect.displayName = "RealUserSelect";
export default RealUserSelect;
