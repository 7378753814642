import _, { isEmpty } from "lodash";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import TableColumnSelect from "../../common/tables/TableColumnSelect";
import TableLayout from "../tableLayouts";
import useCrTableColumns from "../useCrTableColumns";
import CrFilterParameters from "./CrFilterParameters";
import crFilterSchema from "./crFilterSchema";
import Favorite from "./favorites";

export default function CrFilterEditor({
  crFilter: { id, ...filter },
  onSubmit,
  onSearch,
  onDelete,
  isLoading,
}) {
  return (
    <Forms
      onSubmit={({ formSubmitType, formSubmitDirty, ...crFilterNew }) => {
        const func = formSubmitType === "search" ? onSearch : onSubmit;
        return func(crFilterNew, {
          isNew: formSubmitType === "new",
          isDirty: formSubmitDirty,
        });
      }}
      defaultValues={{ id, ...filter }}
      schema={{ schema: crFilterSchema }}
    >
      <>{<CrFilterForm id={id} isLoading={isLoading} onDelete={onDelete} />}</>
    </Forms>
  );
}

function CrFilterForm({ id: currentId, isLoading, onDelete }) {
  const {
    watch,
    setValue,
    submitForm,
    formState: { dirtyFields },
  } = useFormContext();
  const isFieldsDirty = !isEmpty(dirtyFields);

  const [showSave, setShowSave] = useState(false);
  const id = watch("id");
  const isNew = id !== currentId;

  const { columns: crTableColumns } = useCrTableColumns();

  return (
    <Row>
      <Col>
        <Form
          onSubmit={(e) => {
            setValue("formSubmitDirty", isFieldsDirty);
            setValue("formSubmitType", "search");
            submitForm(e);
          }}
        >
          <Row className="justify-content-end pt-2">
            <Col xs="auto">
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onClick={() => setShowSave(!showSave)}
                  tabIndex={-1}
                >
                  {isNew ? "Save" : "Modify"}
                </Button>
                <Button variant="primary" type="submit">
                  Search
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <Form.Label>Sort By</Form.Label>
            </Col>
          </Row>
          <Row className="border-bottom mb-3 justify-content-between">
            <Col md={12} lg={5}>
              <FormsField.Select
                name="sortBy"
                as={TableColumnSelect}
                columns={crTableColumns}
                isMulti
              />
            </Col>
            <Col xs="auto">
              <FavoriteToggle id={id} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CrFilterForms name="filter" />
            </Col>
          </Row>
          <Modal
            show={showSave}
            onHide={() => setShowSave(false)}
            className="modal2"
            backdropClassName="modal2-backdrop"
          >
            <Modal.Header className="lead" closeButton>
              Save CR Query Filter
            </Modal.Header>
            <Modal.Body>
              <SaveForms
                show={showSave}
                onHide={() => setShowSave(false)}
                isNew={isNew}
                isLoading={isLoading}
                onDelete={() => {
                  if (confirm("Are you sure you want to delete filter?")) {
                    onDelete().then(() => setShowSave(false));
                  }
                }}
              />
            </Modal.Body>
          </Modal>
        </Form>
      </Col>
    </Row>
  );
}

function CrFilterForms({ name, id = name, rules, ...props }) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, rules });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <CrFilterParameters
      id={id}
      values={value}
      onChange={(x) => {
        return onChange(x);
      }}
      isDisabled={isDisabled}
      isInvalid={!!error}
      errors={error?.message}
      {...props}
    />
  );
}

function SaveForms({ onHide, isNew, isLoading, onDelete }) {
  const {
    watch,
    register,
    setValue,
    submitForm,
    formState: { isFieldsDirty, isSubmitting },
  } = useFormContext();

  useFormsKeys({
    beforeSubmit: () => {
      onHide();
      setValue("formSubmitDirty", isFieldsDirty);
      setValue("formSubmitType", isNew ? "new" : "save");
    },
  });

  const layoutId = watch("layoutId");

  const { tableLayout } = TableLayout.useOne({ id: layoutId });
  return (
    <Row>
      <Col>
        <Form.Row>
          <Col>
            <Form.Label>Filter</Form.Label>
          </Col>
          <Col xs="auto">
            <Form.Check label="Public" {...register("public")} />
          </Col>
        </Form.Row>
        <FormsField name="id" autoComplete="off" placeholder="Filter ID..." />
        <FormsField
          name="description"
          autoComplete="off"
          placeholder="Filter Description..."
        />
        <Form.Row>
          <Col>
            <TableLayout.Select
              formGroupClassName="pb-0 mb-0"
              label="Layout (Default)"
              value={layoutId}
              onChange={(v) => setValue("layoutId", v)}
              isClearable
            />
            <Form.Text>
              <OverlayTrigger
                trigger="click"
                placement="left"
                rootClose
                overlay={
                  <Popover className="popover2">
                    <Popover.Title>Layout Details</Popover.Title>
                    <Popover.Content>
                      <ListGroup variant="flush">
                        {tableLayout?.columns?.length
                          ? tableLayout?.columns?.map((c, index) => (
                              <ListGroup.Item key={`column${index}`}>
                                {_.startCase(c?.column || c)}
                              </ListGroup.Item>
                            ))
                          : null}
                      </ListGroup>
                    </Popover.Content>
                  </Popover>
                }
              >
                <Button variant="link" className="mt-n1 pt-0">
                  Details
                </Button>
              </OverlayTrigger>
            </Form.Text>
          </Col>
        </Form.Row>

        <Row className="justify-content-end pt-2">
          <Col xs="auto">
            <ButtonGroup>
              <Button
                variant="success"
                disabled={isLoading || isSubmitting}
                onClick={() => {
                  onHide();
                  setValue("formSubmitDirty", isFieldsDirty);
                  setValue("formSubmitType", isNew ? "new" : "save");
                  submitForm();
                }}
              >
                {isNew ? "Save as" : "Save"}
              </Button>
              {onDelete ? (
                <Button
                  variant="danger"
                  disabled={isNew || isLoading || isSubmitting}
                  onClick={onDelete}
                >
                  Delete
                </Button>
              ) : null}
            </ButtonGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function FavoriteToggle({ id }) {
  const { favorite, createOne, deleteOne } = Favorite.useOne({ id });
  const isFavorite = !!favorite;
  return (
    <Button
      variant={isFavorite ? "success" : "outline-success"}
      onClick={() => {
        return isFavorite ? deleteOne(id) : createOne(id);
      }}
      disabled={!id}
    >
      {isFavorite ? <AiFillStar /> : <AiOutlineStar />}
      {` Favorite `}
    </Button>
  );
}
