import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import { resettableRootReducer } from "./reducers";

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      warnAfter: 500,
    },
    immutableCheck: {
      warnAfter: 500,
    },
  }),
  devTools: {
    // actionSanitizer,
    stateSanitizer,
  },
});

export const persistor = persistStore(store, { manualPersist: true });

function stateSanitizer(state) {
  const hiddenString = (o) => {
    const oStr = _.isArray(o) ? `${o.length} items` : `${o}`;
    return `Hidden for performance (${oStr})`;
  };
  const {
    activity,
    crs,
    customers,
    projects,
    times,
    users,
    dbc,
    servers,
    contacts,
  } = state;
  return {
    ...state,
    activity: {
      ...activity,
      notifications: hiddenString(activity?.notifications?.ids),
      userActivity: hiddenString(activity?.userActivity?.ids),
    },
    crs: {
      ...crs,
      crs: hiddenString(crs?.crs?.ids),
      filters: hiddenString(crs?.filters?.ids),
      statuses: hiddenString(crs?.statuses?.ids),
      notificationTypes: hiddenString(crs?.notificationTypes?.ids),
      difficulties: hiddenString(crs?.difficulties?.ids),
      modules: hiddenString(crs?.modules?.ids),
      tableLayouts: hiddenString(crs?.tableLayouts?.ids),
    },
    customers: {
      ...customers,
      customers: hiddenString(customers?.customers?.ids),
    },
    projects: {
      ...projects,
      projects: hiddenString(projects?.projects?.ids),
      statuses: hiddenString(projects?.statuses?.ids),
      priorities: hiddenString(projects?.priorities?.ids),
      sources: hiddenString(projects?.sources?.ids),
      types: hiddenString(projects?.types?.ids),
      folders: hiddenString(projects?.folders?.ids),
      estimateStatuses: hiddenString(projects?.estimateStatuses?.ids),
    },
    times: {
      ...times,
      times: hiddenString(times?.times?.ids),
      holdReasons: hiddenString(times?.holdReasons?.ids),
      internalProjects: hiddenString(times?.internalProjects?.ids),
      billableOverrideReasons: hiddenString(
        times?.billableOverrideReasons?.ids,
      ),
    },
    users: {
      ...users,
      users: hiddenString(users?.users?.ids),
      pseudo: hiddenString(users?.pseudo?.ids),
      groups: hiddenString(users?.groups?.ids),
      teams: hiddenString(users?.teams?.ids),
    },
    dbc: {
      ...dbc,
      branches: hiddenString(dbc?.branches?.ids),
      codeTests: hiddenString(dbc?.codeTests?.ids),
      sourceCodes: hiddenString(dbc?.sourceCodes?.ids),
      reusablePrograms: hiddenString(dbc?.reusablePrograms?.ids),
    },
    servers: {
      ...servers,
      servers: hiddenString(servers?.servers?.ids),
    },
    contacts: {
      ...contacts,
      contacts: hiddenString(contacts?.contacts?.ids),
    },
  };
}
