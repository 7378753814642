import { isUndefined } from "lodash";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import AwsInstanceType from "../../servers/awsInstanceTypes";
import AwsRegion from "../../servers/awsRegions";
import AwsZone from "../../servers/awsZones";
import { useS3Buckets } from "../../servers/s3Buckets/hooks";
import S3BucketSelect from "../../servers/s3Buckets/S3BucketSelect";
import CustomerSelect from "../CustomerSelect";
import hostingAgreementSchema from "./hostingAgreementSchema";
import { HOSTING_TYPES } from "./types";

export default function HostingAgreementEditor({
  values: { ...values } = {},
  onSubmit,
  isDisabled = false,
  isNew = false,
}) {
  useS3Buckets({
    canGet: true,
  });

  AwsZone.useAll({
    canGet: true,
  });
  AwsRegion.useAll({
    canGet: true,
  });
  AwsInstanceType.useAll({
    canGet: true,
  });

  return (
    <>
      <Forms
        defaultValues={{
          currentMonthlyPrice: 0,
          originalMonthlyPrice: 0,
          ...values,
        }}
        onSubmit={(values) => {
          const effectiveMonth = _date.monthStamp(values.effectiveMonth);
          const expirationMonth = values.expirationMonth
            ? _date.monthStamp(values.expirationMonth)
            : "";
          const payload = { ...values, effectiveMonth, expirationMonth };
          onSubmit(payload);
        }}
        schema={{ schema: hostingAgreementSchema }}
        isDisabled={isDisabled}
        showDevTool
      >
        <HostingAgreementForm isNew={isNew} isDisabled={isDisabled} />
      </Forms>
    </>
  );
}

// eslint-disable-next-line unused-imports/no-unused-vars
function HostingAgreementForm({ isNew, isDisabled }) {
  const { watch, submitForm } = useFormContext();
  const serverType = watch("serverType");

  const monthlyLabel =
    serverType === "F" ? "Monthly $ (per user)" : "Monthly $";

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto" style={{ width: "100px" }}>
          <FormsField name="id" label="Id" disabled={true} />
        </Col>
        <Col xs="auto" style={{ width: "400px" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={CustomerSelect}
            isDisabled={!isNew || isDisabled}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto" style={{ width: "320px" }}>
          <FormsField.Date
            name="effectiveMonth"
            label="Effective Month"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            includeArrows={false}
          />
        </Col>
        <Col xs="auto" style={{ width: "320px" }}>
          <FormsField.Date
            name="expirationMonth"
            label="Expiration Month"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            includeArrows={false}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <TypeSelect name="serverType" label="Server Type" />
        </Col>
        <Col xs="auto" style={{ width: "250px" }}>
          <FormsField name="diskSpace" label="Disk Space in GB" />
        </Col>
      </Form.Row>
      {serverType === "F" ? null : serverType === "3" ? (
        <Form.Row>
          <Col xs={"auto"} style={{ minWidth: "250px" }}>
            <FormsField.Select
              name="awsRegion"
              label="AWS Region"
              as={AwsRegion.Select}
              isClearable
              key="awsRegion"
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "220px" }}>
            <FormsField.Select
              name="s3Bucket"
              label="S3 Bucket"
              as={S3BucketSelect}
              key="s3Bucket"
              isClearable
            />
          </Col>
        </Form.Row>
      ) : (
        <Form.Row>
          <Col xs={"auto"} style={{ minWidth: "250px" }}>
            <FormsField.Select
              name="awsZone"
              label="AWS Zone"
              as={AwsZone.Select}
              isClearable
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "250px" }}>
            <FormsField.Select
              name="awsInstanceType"
              label="AWS Instance Type"
              as={AwsInstanceType.Select}
              isClearable
            />
          </Col>
          <Col xs={"auto"} style={{ minWidth: "220px" }}>
            <FormsField name="awsInstanceId" label="AWS Instance Id" />
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        <Col xs={"auto"}>
          <FormsField
            name="currentMonthlyPrice"
            label={"Current " + monthlyLabel}
          />
        </Col>
        {serverType === "F" ? (
          <Col xs={"auto"}>
            <FormsField name="maxPrice" label="Maximum Monthly $" />
          </Col>
        ) : null}
        <Col xs={"auto"}>
          <FormsField
            name="originalMonthlyPrice"
            label={"Original " + monthlyLabel}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormsField.TextArea
            name="billingDescription"
            label="Billing Description"
            minRows={2}
          />
        </Col>
        <Col>
          <FormsField.TextArea
            name="information"
            label="Information"
            minRows={2}
          />
        </Col>
      </Form.Row>

      <Button variant="success" onClick={submitForm} disabled={isDisabled}>
        Submit
      </Button>
    </Form>
  );
}
function TypeSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value=""></option>
        {Object.values(HOSTING_TYPES).map((l, idx) => (
          <option key={`type-option${idx}`} value={l.id}>
            {l.description}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
