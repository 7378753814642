import Group from "./groups";
import { useUser, useUsers } from "./hooks";
import NotificationGroup from "./notificationGroups";
import Pseudo from "./pseudo";
import ServerLoginEditor from "./ServerLoginEditor";
import userState from "./state";
import Team from "./teams";
import UserDescription from "./UserDescription";
import UserEditor from "./UserEditor";
import UserEmailSelect from "./UserEmailSelect";
import UserGrouping from "./userGroupings";
import UserLink from "./UserLink";
import UserModal from "./UserModal";
import UserSelect from "./UserSelect";
import UserTable from "./UserTable";
import UserTableFilter from "./UserTableFilter";

export { useUserRate } from "./useUserRate";

const User = {
  // API
  api: userState.api,
  // State
  actions: userState.actions,
  selectors: userState.entity.selectors,
  // Hooks
  useOne: useUser,
  useAll: useUsers,
  // Components
  Table: UserTable,
  Link: UserLink,
  Modal: UserModal,
  Select: UserSelect,
  EmailSelect: UserEmailSelect,
  Name: UserDescription,
  TableFilter: UserTableFilter,
  Editor: UserEditor,
  ServerLoginEditor: ServerLoginEditor,
  // Related Entity Objects
  Team: Team,
  Group: Group,
  Pseudo: Pseudo,
  NotificationGroup: NotificationGroup,
  UserGrouping: UserGrouping,
};

export default User;
