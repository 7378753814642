import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "./state";

function useAppCanStateExpire() {
  const canStateExpire = useSelector(
    (state) => state.app.canStateExpire.canStateExpire,
  );

  return canStateExpire;
}

function useSet(key) {
  const dispatch = useDispatch();
  const setCanStateExpire = useCallback(
    (value) => dispatch(appActions.setCanStateExpire({ key, value })),
    [dispatch, key],
  );
  return setCanStateExpire;
}

useAppCanStateExpire.set = useSet;
export default useAppCanStateExpire;
