import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import TimeEdit from "./TimeEdit";
import TimeIndex from "./TimeIndex";
import TimeInternal from "./TimeInternal";
import TimeNew from "./TimeNew";
import TimeStats from "./TimeStats";
import TimeUnassigned from "./TimeUnassigned";

export default function TimeRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <TimeNew />
      </Route>
      <Route path={`${path}/stats`}>
        <TimeStats />
      </Route>
      <Route path={`${path}/unassigned`}>
        <TimeUnassigned />
      </Route>
      <Route path={`${path}/internal`}>
        <TimeInternal />
      </Route>
      <Route path={`${path}/:id`}>
        <TimeEdit />
      </Route>
      <Route exact path={`${path}`}>
        <TimeIndex />
      </Route>
    </Switch>
  );
}
