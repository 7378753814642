export const JIRA_PROJECT_TYPES = {
  W: {
    id: "W",
    description: "WMS",
  },
  L: {
    id: "L",
    description: "Go live",
  },
  U: {
    id: "U",
    description: "Upgrade",
  },
  M: {
    id: "M",
    description: "Merger",
  },
  Q: {
    id: "Q",
    description: "QA",
  },
  C: {
    id: "C",
    description: "Consulting trip",
  },
};
