import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";

import Auth from "../../auth";
import { Forms, FormsField, useFormContext } from "../../common/forms";
import useSearchParams from "../../common/hooks/useSearchParams";
import Page from "../../common/pages/Page";

export default function Login() {
  const { loading, login, resetPassword } = Auth.useLogin();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const redirect = useSearchParams("redirect");
  const history = useHistory();

  if (loading) return <Page title="Login Page" lead="Loading..." />;
  if (isLoggedIn) return <Redirect to="/home" />;
  return (
    <Page title="Login Page">
      <Row>
        <Col>
          <Forms
            defaultValues={{
              userId: "",
              password: "",
            }}
            onSubmit={(values) => {
              login(values).then(() => {
                history.push(redirect || "/");
              });
            }}
          >
            <LoginForm isLoading={loading} resetPassword={resetPassword} />
          </Forms>
        </Col>
      </Row>
    </Page>
  );
}

function LoginForm({ isLoading }) {
  const { submitForm, watch } = useFormContext();

  const userId = watch("userId");

  return (
    <Row>
      <Col>
        <form onSubmit={submitForm}>
          <FormsField
            name="userId"
            label="User"
            autoComplete="username"
            placeholder="Enter User"
          />
          <FormsField
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
          <Button variant="primary" type="submit" disabled={isLoading}>
            Submit
          </Button>
          <LinkContainer to={`/auth/reset-password?user=${userId}`}>
            <Button variant="link">Reset Password</Button>
          </LinkContainer>
        </form>
      </Col>
    </Row>
  );
}
