import { createEntityHooks } from "../../common/entity";
import taxProductCategoryState from "./state";

export const {
  useEntities: useTaxProductCategories,
  useEntity: useTaxProductCategory,
} = createEntityHooks({
  entity: taxProductCategoryState.entity,
  actions: taxProductCategoryState.actions,
});
