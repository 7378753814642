import { createEntityHooks } from "../../common/entity";
import contractProductState from "./state";

export const {
  useEntities: useContractProducts,
  useEntity: useContractProduct,
} = createEntityHooks({
  entity: contractProductState.entity,
  actions: contractProductState.actions,
});
