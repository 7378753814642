import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "pseudo",
  type: "users/pseudos",
  getState: (state) => state.users.pseudo,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const pseudoState = {
  api,
  actions,
  reducer,
  entity,
};

export default pseudoState;
