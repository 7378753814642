import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import CustomerEdiSetupPages from "./customerEdiSetups";
import EdiIndex from "./EdiIndex";
import FtpUsersPages from "./ftpUsers";
import TradingPartnersPages from "./tradingPartners";

export default function EdiRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/TradingPartners`}>
        <TradingPartnersPages />
      </Route>
      <Route path={`${path}/ftpUsers`}>
        <FtpUsersPages />
      </Route>
      <Route path={`${path}/customerEdiSetups`}>
        <CustomerEdiSetupPages />
      </Route>
      <Route exact path={`${path}`}>
        <EdiIndex />
      </Route>
    </Switch>
  );
}
