import shark from "../../../apis/shark";

function getJiraProjectsAPI({ customerId } = {}) {
  const args = { ...(customerId ? { customerId: customerId } : null) };
  const payload = {
    command: "getJiraProjects",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.jiraProjects,
    searchArgs: args,
  }));
}

function getJiraAPI({ id }) {
  const payload = {
    command: "getJiraProject",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createJiraAPI(jira) {
  const payload = {
    command: "createJiraProject",
    ...jira,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateJiraAPI(jira) {
  const payload = {
    command: "updateJiraProject",
    ...jira,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getJiraProjectsAPI,
  getOne: getJiraAPI,
  createOne: createJiraAPI,
  updateOne: updateJiraAPI,
};
