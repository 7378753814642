import React, { useMemo } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";

export default function Address({ address: adr, className }) {
  const { href, adrString } = useMemo(() => {
    const adrString = `${adr.address} ${
      adr.address2 ? adr.address2 + " " : ""
    }${adr.city}, ${adr.state} ${adr.zipCode}`;
    const href = `https://maps.google.com/?q=${encodeURIComponent(adrString)}`;
    return { href, adrString };
  }, [adr]);
  return (
    <Row>
      <Col>
        <a
          href={href}
          style={{ whiteSpace: "pre-line" }}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${adr.address}\n${adr.address2 ? adr.address2 + "\n" : ""}${
            adr.city
          }, ${adr.state} ${adr.zipCode}`}
        </a>
      </Col>
      <Col xs="auto">
        <CopyToClipboard
          text={adrString}
          onCopy={() => toast.success("Address copied")}
        >
          <Badge as="a" variant="light" title="Click to copy">
            <BiCopy />
          </Badge>
        </CopyToClipboard>
      </Col>
    </Row>
  );
}
