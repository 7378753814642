import React from "react";
import { Col, Form } from "react-bootstrap";

import User from "../../../users";
import { useController, useFormContext } from "../../forms";
import useOwnerGroupFields from "../../hooks/forms/useGroupOwnerFields";

export default function GroupOwnerFields({ label = true, isNew }) {
  const { handleGroupChange, handleOwnerChange } = useOwnerGroupFields({
    isNew,
  });

  return (
    <>
      <GroupId
        name="groupId"
        label={label}
        onChange={(value) => handleGroupChange(value)}
      />
      <Owner
        name="owner"
        label={label ? "Owner" : null}
        onChange={(value) => handleOwnerChange(value)}
        isClearable
        hasMeButton
      />
    </>
  );
}

function GroupId({ name, label, onChange }) {
  const {
    field: { onChange: _, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled },
  } = useFormContext();

  return (
    <Form.Row>
      <Col style={{ minWidth: "300px" }}>
        <User.Group.Select
          label={
            label ? (
              <User.Group.Label onChosen={onChange} disabled={isDisabled} />
            ) : null
          }
          id="groupId"
          name="groupId"
          onChange={onChange}
          {...field}
          isInvalid={!!error}
          errors={error?.message}
          isDisabled={isDisabled}
        />
      </Col>
    </Form.Row>
  );
}

function Owner({ name, label, onChange, ...props }) {
  const {
    field: { onChange: _, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled },
  } = useFormContext();

  return (
    <Form.Row>
      <Col style={{ minWidth: "300px" }}>
        <User.Select
          label={label ? "Owner" : null}
          pseudo={true}
          onChange={onChange}
          {...field}
          isInvalid={!!error}
          errors={error?.message}
          isDisabled={isDisabled}
          {...props}
        />
      </Col>
    </Form.Row>
  );
}
