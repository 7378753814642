import { createAttachmentAPI } from "../../../apis/attachments";
import shark from "../../../apis/shark";

export const api = {
  getMany: (filters) => {
    const payload = { command: "getHostingAgreements" };
    if (filters?.customerId) {
      payload.customerId = filters?.customerId;
    }
    return shark.post(payload).then((r) => {
      return { data: r.responseData.hostingAgreements };
    });
  },

  getOne: ({ id }) => {
    const payload = {
      command: "getHostingAgreement",
      id: id,
    };
    return shark.post(payload).then((response) => ({
      data: response.responseData,
    }));
  },

  createOne: (license) => {
    const payload = {
      command: "createHostingAgreement",
      ...license,
    };
    return shark
      .post(payload)
      .then(({ responseData }) => ({ data: responseData }));
  },

  updateOne: (license) => {
    const payload = {
      command: "updateHostingAgreement",
      ...license,
    };
    return shark
      .post(payload)
      .then(({ responseData }) => ({ data: responseData }));
  },

  createAttachment: (args) => {
    return createAttachmentAPI({
      command: "createHostingAgreementAttachment",
      ...args,
    });
  },
};
