import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

export default function TimeBillingNoteLink({
  timeId,
  value,
  setModifyShow,
  onSelect,
}) {
  const textVal = (
    <>
      <p
        className="mb-0"
        style={{ minWidth: "150px", maxWidth: "400px", whiteSpace: "pre-line" }}
      >
        {value}
      </p>
      {value?.trim().length < 2 ? (
        <p>
          <strong>
            Billing note is too short
            <br /> Please fix
          </strong>
        </p>
      ) : null}
    </>
  );

  if (_.isNil(timeId) || timeId === "") {
    return <>{textVal}</>;
  } else {
    return (
      <Link
        className="text-decoration-none text-break"
        to={{ pathname: `/time/${timeId}/edit` }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (_.isFunction(onSelect)) onSelect(timeId);
          else setModifyShow(timeId);
        }}
      >
        {textVal}
      </Link>
    );
  }
}
