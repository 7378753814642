import React from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import awsAccountSchema from "./awsAccountSchema";

export default function AwsAccountEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: awsAccountSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <AwsAccountForm isNew={isNew} />
    </Forms>
  );
}

function AwsAccountForm({ isNew }) {
  const { submitForm, setValue } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="description"
                label="Description"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check
                name="isInternalAccount"
                label="Internal account?"
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs="auto">
              <Alert variant="warning">
                Key information will not be displayed. However, entering of data
                will set/update these fields.
              </Alert>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="accessKey"
                label="Access Key"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="secretKey"
                label="Secret Key"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button
        variant="success"
        onClick={() => {
          submitForm();
          setValue("accessKey", "");
          setValue("secretKey", "");
        }}
      >
        Submit
      </Button>
    </Form>
  );
}
