import AwsInstanceTypeEditor from "./AwsInstanceTypeEditor";
import AwsInstanceTypeLink from "./AwsInstanceTypeLink";
import AwsInstanceTypeSelect from "./AwsInstanceTypeSelect";
import Table from "./AwsInstanceTypeTable";
import { useAwsInstanceType, useAwsInstanceTypes } from "./hooks";
import awsInstanceTypeState from "./state";

const AwsInstanceType = {
  // API
  api: awsInstanceTypeState.api,
  // State
  actions: awsInstanceTypeState.actions,
  selectors: awsInstanceTypeState.entity.selectors,
  // Hooks
  useOne: useAwsInstanceType,
  useAll: useAwsInstanceTypes,
  // Components
  Table,
  Link: AwsInstanceTypeLink,
  Editor: AwsInstanceTypeEditor,
  Select: AwsInstanceTypeSelect,
  // Related Entity Objects
};

export default AwsInstanceType;
