import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../common/entity";
import contractProductCategoriesState from "../contractProductCategories/state";
import contractProductsState from "../contractProducts/state";
import contractProductDefaultCrsState from "../contractProductsDefaultCrs/state";
import { api } from "./api";

const entity = createEntity({
  name: "contract",
  type: "contracts",
  getState: (state) => state.contracts.contracts,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const contractReducer = combineReducers({
  contracts: reducer,
  contractProductCategories: contractProductCategoriesState.reducer,
  contractProducts: contractProductsState.reducer,
  contractProductDefaultCrs: contractProductDefaultCrsState.reducer,
});

const contractState = {
  api,
  actions,
  reducer: contractReducer,
  entity,
};

export default contractState;
