import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";

export default function BranchTable({ branches, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Branch",
        accessor: "id",
        disableFilters: true,

        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/dbc/branches/${value}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Customer",
        accessor: "customerId",
        disableFilters: true,
        Cell: ({ value }) => {
          return <Customer.Link id={value} />;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Code Date",
        accessor: "date",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        Header: "Live Date",
        accessor: "liveDate",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Branches"
      columns={columns}
      data={branches}
      {...props}
    />
  );
}
