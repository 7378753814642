import { createEntityHooks } from "../common/entity";
import state from "./state";

export const { useEntities: useCustomers, useEntity: useCustomer } =
  createEntityHooks({
    entity: state.entity,
    actions: state.actions,
    expire: {
      key: "days",
      value: 3,
    },
  });
