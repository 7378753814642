import React from "react";

import Page from "../../common/pages/Page";
import Time from "../../time";
import TimeNavSidebar from "./TimeNavSidebar";

export default function TimeNew() {
  return (
    <Page title="New Time" navSidebar={<TimeNavSidebar />}>
      <Time.New />
    </Page>
  );
}
