import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Mailto from "../common/details/Mailto";
import Tel from "../common/details/Tel";
import { formatPhone } from "../common/functions/phone";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import CustomerTableFilter from "../customers/CustomerTableFilter";
import ContactLink from "./ContactLink";

const DEFAULT_COMPONENTS = {
  Customer: ({ value }) => (
    <Link
      className="text-decoration-none"
      to={{ pathname: `/customers/${value}` }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </Link>
  ),
};

export default function ContactTable({
  contacts,
  layout,
  contactLink = true,
  Components: OverrideComponents,
  ...props
}) {
  const Components = useMemo(
    () => ({ ...DEFAULT_COMPONENTS, ...OverrideComponents }),
    [OverrideComponents],
  );

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: contact } }) =>
          contactLink ? (
            <ContactLink customerId={contact?.customerId} id={contact?.id} />
          ) : (
            value
          ),
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "equals",
        Cell: Components.Customer,
      },
      {
        id: "title",
        Header: "Title",
        accessor: "title",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "type",
        id: "Types",
        disablefilter: true,
        accessor: "types",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value }) => (
          <Row>
            <Col>
              {value.map((p, index) => (
                <Row key={`type${index}`}>
                  <Col>{p}</Col>
                </Row>
              ))}
            </Col>
          </Row>
        ),
      },
      {
        id: "phones",
        Header: "Phone(s)",
        disableFilter: true,
        accessor: (contact) => {
          const phones =
            contact?.phones?.map((phone) => ({
              value: formatPhone(phone.number, phone.extention),
              type: phone.type,
            })) || [];
          return phones.filter((p) => p.value);
        },

        Cell: ({ value }) => (
          <Row>
            <Col>
              {value.map((p, index) => (
                <Row key={`phone${index}`}>
                  <Col>
                    <Tel phone={p.value} />
                    <small> ({p.type})</small>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        ),
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value }) => <Mailto email={value} />,
      },
    ],
    [Components, contactLink],
  );
  return (
    <Table
      hasCount
      label="Contacts"
      columns={columns}
      layout={layout}
      data={contacts}
      {...props}
    />
  );
}
