import { createReducer, current } from "@reduxjs/toolkit";
import stringify from "json-stable-stringify";
import storage from "localforage";
import {
  isFunction,
  isObject,
  isString,
  isUndefined,
  remove,
  uniq,
} from "lodash";
import { persistReducer } from "redux-persist";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
} from "../functions/state";

/**
 * @typedef {Object} Opts
 * @property {Object} entity Entity object
 * @property {Object} actions Redux actions
 * @property {Object=} initialState Additional initial state
 * @property {boolean=} persist Persist entities state
 * @property {PersistConfig=} persistConfig Persist reducer configuration (redux-persist)
 * @property {function=} extraReducers Callback that takes RTK reducer builder to add cases
 */

/**
 * Create Redux reducer using Redux-Toolkit entities
 * @param {Opts} opts Options
 */
export function createEntityReducer({
  entity: { name, type, adapter, getState: getEntityState },
  actions,
  initialState = {},
  persist = false,
  persistConfig = {
    key: type,
    version: 1,
    storage,
    whitelist: ["ids", "entities", "lastUpdate", "startAfter"],
  },
  extraReducers = () => {},
}) {
  if (!isFunction(getEntityState) || isUndefined(adapter) || !isString(type)) {
    throw new Error(`entity is required`);
  }
  if (isUndefined(actions.getMany)) {
    throw new Error(`getMany action is required`);
  }
  if (isUndefined(actions.reset)) {
    throw new Error(`reset action is required`);
  }

  const getInitialState = () =>
    adapter.getInitialState({
      ...INITIAL_LOADING_STATE,
      lastUpdate: null,
      startAfter: null,
      idsBySearch: {},
      ...initialState,
    });

  const searchInitialState = () => ({
    ids: [],
    lastUpdate: null,
    startAfter: null,
  });

  const reducer = createReducer(getInitialState(), (builder) => {
    builder.addCase(actions.reset, (state, action) => {
      if (action.payload) {
        const search = stringify(action.payload);
        state.idsBySearch[search] = searchInitialState();
      } else {
        adapter.removeAll(state);
        state.requests = INITIAL_LOADING_STATE.requests;
        state.lastUpdate = null;
        state.startAfter = null;
        state.idsBySearch = {};
      }
    });

    buildAsyncThunkReducer(builder, actions.getMany, (state, action) => {
      if (isUndefined(action?.payload)) {
        throw new Error(`${name} getMany payload undefined`);
      }
      if (action?.payload === null) return;
      const {
        data,
        startAfter = "",
        searchArgs,
        updateState = true,
      } = action.payload;
      if (updateState && isObject(data)) {
        adapter.upsertMany(state, data);
        state.lastUpdate = new Date().toISOString();
        state.startAfter = startAfter;
        if (!isUndefined(searchArgs)) {
          const search = stringify(searchArgs);
          const { ids: searchIds } =
            state.idsBySearch[search] || searchInitialState();
          state.idsBySearch[search] = {
            ids: uniq(searchIds.concat(data.map(adapter.selectId))),
            lastUpdate: new Date().toISOString(),
            startAfter: startAfter,
          };
        }
      }
    });
    if (actions.getOne) {
      buildAsyncThunkReducer(builder, actions.getOne, (state, action) => {
        if (isUndefined(action?.payload)) {
          throw new Error(`${name} get payload undefined`);
        }
        if (action?.payload === null) return;
        const { data, updateState = true } = action.payload;
        if (updateState && isObject(data)) adapter.upsertOne(state, data);
      });
    }
    if (actions.createOne) {
      buildAsyncThunkReducer(builder, actions.createOne, (state, action) => {
        if (isUndefined(action?.payload)) {
          throw new Error(`${name} create payload undefined`);
        }
        if (action?.payload === null) return;
        const { data, updateState = true } = action.payload;
        if (updateState && isObject(data)) adapter.upsertOne(state, data);
      });
    }
    if (actions.updateOne) {
      buildAsyncThunkReducer(builder, actions.updateOne, (state, action) => {
        if (isUndefined(action?.payload)) {
          throw new Error(`${name} update payload undefined`);
        }
        if (action?.payload === null) return;
        const { data, updateState = true } = action.payload;
        if (updateState && isObject(data)) adapter.upsertOne(state, data);
      });
    }
    if (actions.deleteOne) {
      buildAsyncThunkReducer(builder, actions.deleteOne, (state, action) => {
        if (isUndefined(action?.payload)) {
          throw new Error(`${name} delete payload undefined`);
        }
        if (action?.payload === null) return;
        const { data, updateState = true } = action.payload;
        if (updateState && data) {
          adapter.removeOne(state, data);
          const idsBySearch = current(state.idsBySearch);
          Object.entries(idsBySearch).forEach(([key, value]) => {
            if (value.ids.includes(data))
              state.idsBySearch[key].ids = remove(value.ids, data);
          });
        }
      });
    }
    extraReducers(builder, adapter);
  });

  return persist ? persistReducer(persistConfig, reducer) : reducer;
}

export default createEntityReducer;
