import React from "react";

import Page from "../../common/pages/Page";
import InvoicesNavSidebar from "./InvoicesNavSidebar";

export default function InvoicesIndex() {
  return (
    <div>
      <Page
        title="Invoice management"
        navSidebar={<InvoicesNavSidebar />}
      ></Page>
    </div>
  );
}
