import React from "react";
import { Link } from "react-router-dom";

import { useTradingPartner } from "./hooks";

export default function TradingPartnerLink({ id }) {
  const { tradingPartner } = useTradingPartner({ id });

  if (!tradingPartner?.id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Link
        className="text-reset"
        to={{ pathname: `/edi/tradingPartners/${id}` }}
      >
        {tradingPartner?.description}
      </Link>
    </span>
  );
}
