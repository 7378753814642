import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Prompt } from "react-router";
import TextareaAutosize from "react-textarea-autosize";

import useAppFormsState from "../../app/useAppFormsState";
import useTinykeys from "../hooks/useTinykeys";

export default function DetailField({
  header,
  children,
  forwardedRef,
  style,
  onEditClick,
  isLoading = false,
}) {
  const [hover, setHover] = useState(false);

  function handleMouse(enter) {
    if (!_.isFunction(onEditClick) || isLoading) return;
    if (enter) setHover(true);
    else setHover(false);
  }
  return (
    <Row
      onMouseEnter={() => handleMouse(true)}
      onMouseLeave={() => handleMouse(false)}
    >
      <Col>
        <Row>
          <Col>
            <h6>
              {header}{" "}
              {hover ? (
                <Button
                  className="my-n2 py-0"
                  size="sm"
                  variant="link"
                  onClick={onEditClick}
                  tabIndex={-1}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row ref={forwardedRef} style={style}>
          <Col>{children}</Col>
        </Row>
      </Col>
    </Row>
  );
}

function FieldText({
  id,
  header,
  text,
  isLoading = false,
  heightRem = 0,
  isDisabled = false,
  onEdit,
}) {
  const fieldRef = useRef(null);
  const [overflow, setOverflow] = useState(false);
  const [showall, setShowall] = useState(false);
  const [editField, setEditField] = useState(false);

  const isLoadingAndInvalid = _.isUndefined(text) && isLoading;

  const handleOverflow = useCallback(() => {
    if (heightRem && !isLoadingAndInvalid && fieldRef?.current) {
      const e = fieldRef.current;
      if (e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth)
        setOverflow(true);
    }
  }, [heightRem, isLoadingAndInvalid]);

  useEffect(() => {
    window.addEventListener("resize", handleOverflow);
  }, [handleOverflow]);

  useEffect(() => {
    handleOverflow();
  }, [text, handleOverflow]);

  const onClickHandler = (s) => {
    if (overflow) {
      setShowall(s);
    }
  };

  const onEditClick =
    _.isFunction(onEdit) && !isDisabled ? () => setEditField(true) : null;

  let style = {};
  if (!showall && heightRem) {
    style = { height: `${heightRem + 1}rem`, overflow: "hidden" };
  }

  let displayText = _.trim(text);
  let pStyle = { whiteSpace: "pre-wrap" };
  if (!showall && overflow && !isLoadingAndInvalid) {
    pStyle = { height: `${heightRem - 1}rem`, overflow: "hidden" };
  }

  return (
    <DetailField
      header={header}
      forwardedRef={fieldRef}
      style={{ ...style, whiteSpace: "pre-line" }}
      onEditClick={onEditClick}
      isLoading={isLoadingAndInvalid}
    >
      <p
        className="mb-0 text-break"
        onClick={() => onClickHandler(true)}
        style={pStyle}
      >
        {!isLoadingAndInvalid ? displayText : <Skeleton count={30} />}
      </p>
      {overflow ? (
        <Button
          variant="link"
          onClick={() => onClickHandler(!showall)}
          disabled={isLoadingAndInvalid}
          className="y-0"
          size="sm"
        >
          {showall ? "Less" : "... More"}
        </Button>
      ) : null}
      <FieldTextFormModal
        id={id}
        value={text}
        show={editField}
        header={header}
        onSubmit={(value) => {
          setEditField(false);
          return onEdit(value);
        }}
        onHide={() => setEditField(false)}
      />
    </DetailField>
  );
}

DetailField.Text = FieldText;

function FieldTextFormModal({ id, value, show, header, onSubmit, onHide }) {
  const {
    state: savedValue,
    initial: initialSavedValue,
    setState: setSavedValue,
    loading: formsLoading,
  } = useAppFormsState({
    key: id,
    value: null,
  });

  const nextValue = savedValue === null ? value || "" : savedValue;

  const handleSubmit = () => {
    onSubmit(nextValue).then(() => setSavedValue(null, true));
  };

  const dirty = savedValue !== null && value?.trim() !== savedValue?.trim();
  const dirtyHeader = `${header}${dirty ? "*" : ""}`;

  useEffect(() => {
    if (!dirty) setSavedValue(null, true);
  }, [dirty, setSavedValue]);

  return (
    <>
      <Prompt
        when={dirty && savedValue !== initialSavedValue}
        message={() => {
          console.log({
            isChanged: dirty,
            Current: value,
            Saved: savedValue,
          });
          return `Edited ${header} may not be saved, are you sure you want to leave?`;
        }}
      />
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit {dirtyHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <TextareaAutosize
                name="text"
                type="text"
                placeholder={header + "..."}
                as="textarea"
                className="form-control"
                minRows={6}
                onChange={(e) => {
                  setSavedValue(e.target.value);
                }}
                value={nextValue}
                readOnly={formsLoading}
              />
            </Form.Group>
            <FieldTextFormSubmitKeys handleSubmit={handleSubmit} />
            <ButtonGroup>
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="secondary" onClick={onHide}>
                Cancel
              </Button>
              <Button
                variant="warning"
                onClick={() => {
                  setSavedValue(null);
                }}
              >
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

function FieldTextFormSubmitKeys({ handleSubmit }) {
  useTinykeys({
    "$mod+Enter": () => {
      handleSubmit();
    },
  });
  return null;
}
