import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import app from "../app/index";
import projectState from "./state";

export default function useProjectsByFilter({ filter, blocked }) {
  const maxRecs = 100;
  const projectsToLoadInterval = maxRecs * 5;
  const [projectsToLoad, setProjectsToLoad] = useState(projectsToLoadInterval);

  const dispatch = useDispatch();
  const [projects, lastUpdate, startAfter] = useSelector((state) =>
    projectState.entity.selectors.selectBySearch(state, filter),
  );
  const loading = useSelector(
    (state) => state.projects.projects.requests.loading,
  );
  const [error, setError] = useState(null);
  const canExpire = app.useCanStateExpire();

  const hasMore = startAfter !== "" && error === null && !_.isEmpty(filter);
  const totalProjects = projects.length;

  const getMore = useCallback(() => {
    if (totalProjects >= projectsToLoad)
      setProjectsToLoad(projectsToLoad + projectsToLoadInterval);
    return dispatch(
      projectState.actions.getMany({
        filter: filter,
        maxRecs: maxRecs,
        startAfter: startAfter,
      }),
    ).then(unwrapResult);
  }, [
    dispatch,
    filter,
    maxRecs,
    startAfter,
    totalProjects,
    projectsToLoad,
    projectsToLoadInterval,
  ]);

  const reset = useCallback(() => {
    dispatch(projectState.actions.reset(filter));
    setError(null);
    setProjectsToLoad(projectsToLoadInterval);
  }, [dispatch, filter, projectsToLoadInterval]);

  useEffect(() => {
    if (blocked) return;
    if (totalProjects < projectsToLoad && hasMore) {
      getMore().catch(setError);
    }
  }, [totalProjects, hasMore, getMore, projectsToLoad, blocked]);

  useEffect(() => {
    if (blocked) return;
    if (
      lastUpdate !== null &&
      moment().diff(lastUpdate, "hours") > 1 &&
      canExpire
    ) {
      reset();
    }
  }, [reset, lastUpdate, blocked, canExpire]);

  return {
    projects,
    loading,
    lastUpdate,
    hasMore,
    getMore,
    reset,
    error,
  };
}
