import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportInternalTime() {
  return (
    <JobPage
      title="Internal Time"
      functionName="InternalTime"
      Options={Options}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  return (
    <Row>
      <Col>
        <Time.Table
          times={job?.output?.data}
          layout={[
            "user",
            "date",
            "project",
            "cr",
            "customer",
            "billingNote",
            "totalHours",
          ]}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}

function Options(props) {
  return (
    <ReportOptions
      {...props}
      options={{
        tbdOnly: true,
      }}
    >
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="tbdOnly"
            render={({ field: { value, onChange } }) => (
              <Form.Check
                id="tbdOnly"
                label="TBD Only?"
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}
