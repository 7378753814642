import React from "react";
import { Button } from "react-bootstrap";

export default function QaReviewLink({ id, children, setModifyShow }) {
  return (
    <Button
      variant="link"
      onClick={() => {
        setModifyShow(id);
      }}
    >
      {children || id}
    </Button>
  );
}
