import React, { useEffect } from "react";
import { Button, Col, Form } from "react-bootstrap";

import { Forms, FormsField, useFormContext } from "../../common/forms";
import Customer from "../../customers";
import ftpUserSchema from "./ftpUserSchema";

export default function FtpUserEditor({
  values = {},
  onSubmit,
  isLoading,
  isNew,
}) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: ftpUserSchema }}
        isLoadin={isLoading}
        showDevTool
      >
        <FtpUserForm isNew={isNew} />
      </Forms>
    </>
  );
}

function FtpUserForm({ isNew }) {
  const { submitForm, watch, setValue } = useFormContext();
  const customerId = watch("customerId");
  const tradingPartnerPrefix = watch("tradingPartnerPrefix");
  const overridePostfix = watch("overridePostfix");

  let username = tradingPartnerPrefix + customerId;
  if (overridePostfix?.trim() !== "")
    username = tradingPartnerPrefix + overridePostfix;
  if (isNaN(customerId)) username = "(Choose a customer)";
  else if (tradingPartnerPrefix?.trim() === "") username = "(Enter the prefix)";

  useEffect(() => {
    if (!!tradingPartnerPrefix && tradingPartnerPrefix !== "") {
      const x = tradingPartnerPrefix.replace(/[^a-zA-z]/gi, "");
      if (x !== tradingPartnerPrefix) setValue("tradingPartnerPrefix", x);
    }
  }, [tradingPartnerPrefix, setValue]);

  useEffect(() => {
    if (!!overridePostfix && overridePostfix !== "") {
      const x = overridePostfix.replace(/[^0-9a-zA-z]/gi, "");
      if (x !== overridePostfix) setValue("overridePostfix", x);
    }
  }, [overridePostfix, setValue]);

  const existingUsename = watch("username");

  return (
    <Form>
      <Form.Row>
        <Col xs={"auto"} style={{ minWidth: "250px" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={Customer.Select}
            isDisabled={!isNew}
          />
        </Col>
        {!isNew && (
          <Col xs="auto" style={{ width: "100px" }}>
            <FormsField
              name="id"
              label="ID"
              className="text-monospace"
              readOnly
            />
          </Col>
        )}
      </Form.Row>

      <Form.Row>
        <Col>
          <FormsField
            name="description"
            label="Description"
            className="text-monospace"
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs="auto">
          <FormsField
            name="tradingPartnerPrefix"
            label="Username prefix (trading partner)"
            className="text-monospace"
            disabled={!isNew}
          />
        </Col>
        <Col xs="auto">
          <FormsField
            name="overridePostfix"
            label="Override postfix"
            className="text-monospace"
            disabled={!isNew}
          />
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label className="font-weight-bold text-monospace">
              Username
            </Form.Label>
            <Form.Control
              value={isNew ? username : existingUsename}
              readOnly
              className="text-monospace"
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row></Form.Row>

      <Form.Row>
        <Col>
          <FormsField.TextArea
            name="exclusionaryFileList"
            label="Exclusionary file list"
            className="text-monospace"
            minRows={6}
            placeholder={
              "Enter full paths of file(s) separate by carriage returns. These files will not be deleted on the FTP server."
            }
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={"auto"}>
          <FormsField.Check name="isInactive" label="Inactive?" />
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>

      <Form.Row>
        <Col>
          <br />
          <Form.Group id={"username"} className="my-0">
            <Form.Label style={{ color: "red" }}>
              {isNew ? (
                <>Username: {username}</>
              ) : (
                <>Username: {existingUsename}</>
              )}
            </Form.Label>
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}
