import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import HostingAgreementNavSidebar from "./HostingAgreementNavSidebar";

export default function HostingAgreementShow() {
  const { createOne } = Customer.HostingAgreements.useOne();
  const customerId = useSearchParams("customerId");
  const [, setRedirect] = useRedirect();

  const { hostingAgreementAccess, isAdmin } = useSelector(
    (state) => state.auth,
  );

  if (!(hostingAgreementAccess === "M" || isAdmin))
    return (
      <Page
        title="Customer Hosting Agreement"
        navSidebar={<HostingAgreementNavSidebar customerId={customerId} />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page
      title="New Customer Hosting Agreement"
      navSidebar={<HostingAgreementNavSidebar customerId={customerId} />}
    >
      <Customer.HostingAgreements.Editor
        isNew
        values={{ customerId: customerId ? parseInt(customerId) : "" }}
        onSubmit={(values) => {
          createOne(values).then(({ id }) =>
            customerId
              ? setRedirect(
                  `/customers/hosting-agreements?customer=${customerId}`,
                )
              : setRedirect(`/customers/hosting-agreements/${id}`),
          );
        }}
      />
    </Page>
  );
}
