import { entityTableFilter } from "../../common/entity";
import { useFolders } from "./hooks";

const FolderTableFilter = entityTableFilter({
  name: "projectFolder",
  useEntities: useFolders,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default FolderTableFilter;
