import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsInstanceTypeNavSidebar from "./AwsInstanceTypeNavSidebar";

export default function AwsInstanceTypeEdit() {
  const id = String(useParams().id).toLowerCase();
  const { awsInstanceType, loading, updateOne } = Server.AwsInstanceType.useOne(
    {
      id,
      canGet: true,
    },
  );
  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Instance Type"
        navSidebar={<AwsInstanceTypeNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Aws Instance Type" navSidebar={<AwsInstanceTypeNavSidebar />}>
      {awsInstanceType ? (
        <>
          <Server.AwsInstanceType.Editor
            values={awsInstanceType}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={awsInstanceType} />
        </>
      ) : (
        <h4>Aws Instance Type not found.</h4>
      )}
    </Page>
  );
}
