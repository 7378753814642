import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SHARK_URL } from "../apis/shark";
import isDev from "../common/isDev";
import { persistor } from "../store";
import { authActions } from "./state";
import { getToken } from "./state/token";

/**
 * Check Token authentication and set state persistence
 * @param {object} options
 * @param {boolean} options.blocked Blocked from performing actions
 */
export default function useToken({ blocked = false } = {}) {
  const [checked, setChecked] = useState(false);
  const authToken = getToken();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const error = useSelector((state) => state.auth.error);
  const experimentalFeatures = useSelector(
    (state) => state.auth.experimentalFeatures,
  );

  // Check login status and enable persistence
  useEffect(() => {
    if (blocked) return;
    if (
      !_.isEmpty(authToken) &&
      !_.isUndefined(authToken) &&
      authToken !== "undefined"
    ) {
      dispatch(authActions.checkToken(authToken)).then(() => {
        const versionBuild = localStorage.getItem("versionBuild");
        if (!isDev() && SHARK_URL === "https://shark.producepro.com/services") {
          if (experimentalFeatures === "B" && versionBuild !== "beta") {
            let url = new URL(window.location.href);
            url.hostname = `beta.t3.producepro.com`;
            window.location.replace(url.href);
          }
        }
        setChecked(true);
        persistor.persist();
      });
    } else {
      setChecked(true);
    }
  }, [dispatch, authToken, blocked, experimentalFeatures]);

  return { loading: loading || !checked, isLoggedIn, error };
}
