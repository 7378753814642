import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";

import Page from "../../common/pages/Page";
import Expense from "../../expenses";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseTripIndex() {
  const [tripId, setTripId] = useState();
  const user = useSelector((state) => state.auth.user);
  return (
    <Page title={`Enter Trip Expenses`} navSidebar={<ExpenseIndexNavSidebar />}>
      <Form>
        <Expense.Trip.Select
          value={tripId}
          search={{ userId: user, status: "Open" }}
          label="Select a Customer Trip"
          onChange={(tripId) => setTripId(tripId)}
        />
        <LinkContainer to={`/expenses/trips/${tripId}`}>
          <Button disabled={!tripId}>Next</Button>
        </LinkContainer>
      </Form>
    </Page>
  );
}
