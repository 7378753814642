import React from "react";
import { Col, Form } from "react-bootstrap";

import { Forms, FormsField } from "../../common/forms";
import AwsAccount from "../awsAccounts";
import AwsInstanceType from "../awsInstanceTypes";
import AwsZone from "../awsZones";
import reservationSchema from "./reservationSchema";

export default function ReservationEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: reservationSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <ReservationForm isNew={isNew} />
    </Forms>
  );
}

function ReservationForm({ isNew }) {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto" style={{ width: "400px" }}>
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ width: "300px" }}>
              <FormsField.Date
                name="startDate"
                label="Start Date"
                includeArrows={false}
                readOnly={true}
                includeNow={false}
              />
            </Col>
            <Col xs="auto" style={{ width: "300px" }}>
              <FormsField.Date
                name="endDate"
                label="End Date"
                includeArrows={false}
                readOnly={true}
                includeNow={false}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "500px" }}>
              <FormsField.Select
                name="awsAccount"
                label="AWS Account"
                as={AwsAccount.Select}
                isDisabled={true}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField.Select
                name="awsZone"
                label="AWS Zone"
                as={AwsZone.Select}
                isDisabled={true}
              />
            </Col>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField.Select
                name="awsInstanceType"
                label="AWS InstanceType"
                as={AwsInstanceType.Select}
                isDisabled={true}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField name="unitPrice" label="Unit Price" readOnly={true} />
            </Col>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField
                name="instanceCount"
                label="Instance Count"
                readOnly={true}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}
