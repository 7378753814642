import { bool, object, string } from "yup";

const taxProductCategorySchema = object().shape({
  id: string()
    .label("Id")
    .required()
    .max(12)
    .transform((v) => `${v}`.toUpperCase()),
  description: string().label("Description").max(30).required(),
  isInactive: bool().required().default(false),
  avalaraTaxCodeSaleOfSoftware: string()
    .label("Avalara tax code (With Sale of Software)")
    .max(8),
  avalaraTaxCode: string().label("Avalara tax code").max(8),
});

export default taxProductCategorySchema;
