import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function DbcNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/edi">EDI Home</NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/edi/tradingPartners">
        Trading Partners
      </NavSidebarItem>
      <NavSidebarItem to="/edi/tradingPartners/new">
        New Trading Partner
      </NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/edi/customerEdiSetups">
        Customer EDI Setups
      </NavSidebarItem>
      <NavSidebarItem to="/edi/customerEdiSetups/new">
        New Customer EDI Setup
      </NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/edi/ftpUsers">FTP Users</NavSidebarItem>
      <NavSidebarItem to="/edi/ftpUsers/new">New FTP User</NavSidebarItem>
      <div className="dropdown-divider" />
      {children}
    </NavSidebar>
  );
}
