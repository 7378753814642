import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import ReusableProgramIndex from "./ReusableProgramIndex";
import ReusableProgramShow from "./ReusableProgramShow";

export default function ReusableProgramsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <ReusableProgramShow />
      </Route>
      <Route exact path={`${path}`}>
        <ReusableProgramIndex />
      </Route>
    </Switch>
  );
}
