import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authActions } from "./state";

export default function useLogin() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const login = useCallback(
    (args) => dispatch(authActions.login(args)),
    [dispatch],
  );
  const resetPassword = useCallback(
    (args) => dispatch(authActions.resetPassword(args)),
    [dispatch],
  );
  const updatePassword = useCallback(
    (args) => dispatch(authActions.updatePassword(args)),
    [dispatch],
  );
  return { loading, login, resetPassword, updatePassword };
}
