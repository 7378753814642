import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import CrLinkWithContextMenu from "../../common/components/CrLinkWithContextMenu";
import queryToString from "../../common/functions/queryToString";
import useIntervalUpdateComponent from "../../common/hooks/useIntervalUpdateComponent";
import useRedirect from "../../common/hooks/useRedirect";
import useSearchParams from "../../common/hooks/useSearchParams";
import NavSidebarItem from "../../common/navs/NavSidebarItem";
import Page from "../../common/pages/Page";
import Cr from "../../crs";
import useSettings from "../../settings/useSettings";
import CrNavSidebar from "./CrNavSidebar";

const CrTableComponents = {
  CrLink: CrLinkWithContextMenu,
};

export default function CrIndex() {
  const {
    settings: { crFilterId: defaultFilterId },
  } = useSettings();

  const urlFilterId = useSearchParams("filter");
  const {
    crTableSettings: { filter, layout },
    setCrTableSettings,
  } = Cr.useTableSettings({
    crFilterId: urlFilterId,
    canSet: true,
  });

  const [, setRedirect] = useRedirect();

  // Set the URL including the filterId
  useEffect(() => {
    if (filter?.id && urlFilterId !== filter?.id) {
      setCrTableSettings({ filter: null, layout: null });
    }
    if (urlFilterId) return;
    if (filter?.id) {
      setRedirect(`/crs?filter=${filter.id}`);
    } else if (defaultFilterId) {
      setRedirect(`/crs?filter=${defaultFilterId}`);
    }
  }, [
    defaultFilterId,
    filter?.id,
    setCrTableSettings,
    setRedirect,
    urlFilterId,
  ]);

  const isFilterValid = !_.isEmpty(filter?.filter);
  const { crs, loading, hasMore, getMore, reset, lastUpdate } = Cr.useSearch({
    filter: filter?.filter,
    canGet: isFilterValid,
  });

  useIntervalUpdateComponent(15000);

  const _loading = loading;

  const layoutColumns = layout?.columns?.map((c) => c.column);

  return (
    <Page
      title={`CRs${
        filter?.id ? ": " + (filter?.description || filter?.id) : ""
      }${filter?.hasChanges === true ? "*" : ""}`}
      lead={
        isFilterValid
          ? `${queryToString(filter.filter)}`
          : "Please select a filter"
      }
      navSidebar={
        <IndexNavSidebar
          filter={filter}
          layout={layout}
          defaultCrFilterId={defaultFilterId}
          setCrTableSettings={setCrTableSettings}
          setRedirect={setRedirect}
        />
      }
      tableOfContents={false}
    >
      {isFilterValid ? (
        <Cr.Table
          crs={crs}
          isLoading={_loading}
          hasMore={hasMore}
          getMore={getMore}
          onRefresh={reset}
          refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
          layout={layoutColumns}
          initialSort={filter.sortBy}
          Components={CrTableComponents}
        />
      ) : null}
    </Page>
  );
}

function IndexNavSidebar({ filter, layout, setCrTableSettings, setRedirect }) {
  function submitHandler(crTableSettings) {
    setShow(false);
    setCrTableSettings(crTableSettings);
    const crFilterId = crTableSettings?.filter?.id;
    setRedirect(`/crs${crFilterId ? `?filter=${crFilterId}` : ""}`);
  }
  const [show, setShow] = useState(false);
  return (
    <CrNavSidebar>
      <Button
        variant="link"
        className="text-secondary text-decoration-none px-0"
        onClick={() => setShow(true)}
        title="CR Query Filters"
      >
        Filters
      </Button>
      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            CR Query Filters
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cr.TableSettingsEditor
            values={{ filter, layout }}
            onSubmit={submitHandler}
          />
        </Modal.Body>
      </Modal>
      <CrFilterFavorites
        onClick={() => setCrTableSettings({ filter: null, layout: null })}
      />
    </CrNavSidebar>
  );
}

function CrFilterFavorites({ onClick }) {
  const { favorites } = Cr.Filter.Favorite.useAll();
  if (favorites?.length === 0) return null;
  return (
    <>
      <div className="dropdown-divider" />
      {favorites.map(({ crFilterId }) => (
        <NavSidebarItem
          key={`fav-filter-${crFilterId}`}
          to={`/crs?filter=${crFilterId}`}
          onClick={onClick}
        >
          {crFilterId}
        </NavSidebarItem>
      ))}
    </>
  );
}
