import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import useConfirmModal from "../../common/modals/useConfirmModal";
import Page from "../../common/pages/Page";
import Expense from "../../expenses";
import { ExpenseIndexNavSidebar } from "./ExpenseNavSidebar";

export default function ExpenseTripEntry() {
  const tripId = parseInt(useParams().id);
  const user = useSelector((state) => state.auth.user);
  const { expenses, loading, lastUpdate, reset } = Expense.useSearch({
    canGet: !!tripId,
    search: { userId: user, expenseTripId: tripId },
  });

  const {
    user: expenseTripUser,
    updateOne: updateExpenseTripUser,
    loading: loadingExpenseTripUser,
  } = Expense.Trip.User.useOneByFind({ find: { tripId, userId: user } });

  const isDisabled = expenseTripUser?.isExpensesFinished || loading;

  return (
    <Page
      heading={`Expenses: Trip Entry`}
      lead={<Expense.Trip.Description id={tripId} canGet />}
      title={`Expenses: Trip ${tripId}`}
      navSidebar={<ExpenseIndexNavSidebar />}
      tableOfContents={false}
    >
      <Row>
        <Col>
          <Expense.NewButton
            onCreate={reset}
            initialValues={{ expenseTripId: tripId }}
            disabled={isDisabled}
          />
        </Col>
        <Col>
          <Button
            onClick={() => {
              Expense.Trip.User.api
                .sendEmail({ userId: user, tripId: tripId })
                .then(() => toast.info("Confirmation email sent."));
            }}
          >
            Send Email Confirmation
          </Button>
        </Col>
        <Col xs="auto">
          <FinishCheck
            expenses={expenses}
            expenseTripUser={expenseTripUser}
            isLoading={loadingExpenseTripUser}
            update={updateExpenseTripUser}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Expense.Table
            expenses={expenses}
            isLoading={loading}
            layout={[
              "edit",
              "date",
              "expenseType",
              "overrideDescription",
              "note",
              "amount",
              "reimburseAmount",
              "billCustomerAmount",
              "paymentType",
              "overrideBillableFlag",
              "mileage",
            ]}
            onRefresh={reset}
            refreshLabel={"Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
    </Page>
  );
}

function FinishCheck({ expenseTripUser, isLoading, update, expenses = [] }) {
  const { types: expenseTypes } = Expense.Type.useAll();
  const missingTypes = useMemo(() => {
    const requiredTypes = expenseTypes
      .filter((t) => t.shouldShowReminder)
      .map((t) => t.id);
    const currentTypes = _.uniq(expenses.map((e) => e.expenseTypeId));
    const missing = _.difference(requiredTypes, currentTypes);
    return missing;
  }, [expenseTypes, expenses]);

  const [ConfirmModal, confirmModalProps, showConfirm] = useConfirmModal();

  return (
    <>
      <Form.Check
        id="isExpensesFinished"
        label="Trip Expenses Finished"
        checked={expenseTripUser?.isExpensesFinished || false}
        disabled={isLoading}
        onChange={(e) => {
          const newExpensesFinished = e.target.checked;
          const performUpdate = () =>
            update({
              ...expenseTripUser,
              isExpensesFinished: newExpensesFinished,
            });

          if (newExpensesFinished)
            showConfirm({
              title: `Finish Trip (${expenseTripUser?.userId})`,
              message: (
                <Row>
                  <Col>
                    {missingTypes.length > 0 ? (
                      <Row>
                        <Col>
                          <Alert variant="warning">
                            <strong>Warning: Common expenses missing</strong>

                            <ul>
                              {missingTypes.map((t, idx) => (
                                <li
                                  variant="warning"
                                  key={`missing-alert-${idx}`}
                                >
                                  <Expense.Type.Description id={t} />
                                </li>
                              ))}
                            </ul>
                          </Alert>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col>{"Mark trip expenses as finished?"}</Col>
                    </Row>
                  </Col>
                </Row>
              ),
              buttons: [
                {
                  label: "Finished",
                  onClick: performUpdate,
                  variant: "success",
                },
                {
                  label: "Cancel",
                  variant: "secondary",
                },
              ],
            });
          else performUpdate();
        }}
      />
      <ConfirmModal {...confirmModalProps} />
    </>
  );
}
