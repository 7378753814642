import shark from "../../../../apis/shark";

function getFavoritesAPI() {
  return shark.post({ command: "getCrFilterFavorites" }).then((r) => {
    return { data: r.responseData.crFilterFavorites };
  });
}

function addFavoriteApi(crFilterId) {
  return shark.post({ command: "addCrFilterFavorite", crFilterId }).then(() => {
    return { data: { crFilterId } };
  });
}

function removeFavoriteAPI({ id: crFilterId, shouldSendRequest = true }) {
  const returnValue = { data: crFilterId };
  return shouldSendRequest === false
    ? returnValue
    : shark
        .post({ command: "removeCrFilterFavorite", crFilterId })
        .then(() => returnValue);
}

export const api = {
  getMany: getFavoritesAPI,
  createOne: addFavoriteApi,
  deleteOne: removeFavoriteAPI,
};
