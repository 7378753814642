import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { _datetime } from "../../common/functions/dates";
import OkCancelModal from "../../common/modals/OkCancelModal";
import SavingModal from "../../common/modals/SavingModal";
import Cr from "../../crs";
import Project from "../../projects";
import Time from "../../time";
import CrPage from "./CrPage";
import CrShowNavSidebar from "./CrShowNavSidebar";
import CrShowTimeSetAll from "./CrShowTimeSetAll";

export default function CrShowTime() {
  const id = parseInt(useParams().id);
  const { cr, loading, updateOne, getCr } = Cr.useOne({
    id,
    redirect: false,
    logActivity: true,
  });
  const crs = useMemo(() => {
    return [id];
  }, [id]);
  const {
    times,
    getTimes,
    loading: timesLoading,
  } = Time.useAll({
    filter: { crs },
  });
  const { project } = Project.useOne({
    id: cr?.projectId,
  });
  const [showEstimate, setShowEstimate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  const onRefresh = useCallback(() => {
    getCr();
    getTimes();
  }, [getCr, getTimes]);

  const onSelectedRowsChange = useCallback(
    (x) => {
      if (x === selectedRows) return;

      if (x == null || selectedRows == null) {
        setSelectedRows(x);
        return;
      } else if (x.length !== selectedRows.length) {
        setSelectedRows(x);
        return;
      } else {
        for (let a = 0; a < x.length; a++) {
          if (x[a].id !== selectedRows[a].id) {
            setSelectedRows(x);
            return;
          }
        }
      }
    },
    [selectedRows],
  );

  const transferred =
    cr?.transferredFolder && cr?.transferredFolder !== "CURRENT";
  const estimate = {
    minCost: _.multiply(cr?.estimateRate, cr?.estimateMinHours),
    maxCost: _.multiply(cr?.estimateRate, cr?.estimateMaxHours),
    minHours: cr?.estimateMinHours,
    maxHours: cr?.estimateMaxHours,
    rate: cr?.estimateRate,
  };
  const actual = {
    invoicedBlHours: cr?.actualInvoicedHours,
    invoicedNbHours: cr?.actualInvoicedNbHours,
    billableHours: cr?.actualBillableHours,
    nonBillHours: cr?.actualNonBillableHours,
    totalHours: _.sum([
      cr?.actualInvoicedHours,
      cr?.actualBillableHours,
      cr?.actualNonBillableHours,
    ]),
    invoicedBlCost: cr?.actualInvoicedCost,
    invoicedNbCost: cr?.actualInvoicedNbCost,
    billableCost: cr?.actualBillableCost,
    nonBillCost: cr?.actualNonBillableCost,
    totalCost: _.sum([
      cr?.actualInvoicedCost,
      cr?.actualBillableCost,
      cr?.actualNonBillableCost,
    ]),
  };

  return (
    <CrPage
      cr={cr}
      heading={`CR ${id} Time`}
      navSidebar={<CrShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <Row>
        <Col>
          <Cr.Alerts cr={cr} />
        </Col>
      </Row>
      <Row>
        <Col lg={12} xl={8}>
          <Time.EstimateTable
            estimate={estimate}
            actual={actual}
            estimateSubtitle={
              <Project.EstimateStatus.Description
                id={project?.estimateStatusId}
              />
            }
            isLoading={loading}
            onEdit={transferred ? undefined : () => setShowEstimate(true)}
          />
          <Cr.EstimateEditor
            cr={cr}
            show={showEstimate}
            onHide={() => setShowEstimate(false)}
            update={updateOne}
          />
        </Col>
        <Col>
          <Cr.TargetDatesTable cr={cr} isLoading={loading} update={updateOne} />
        </Col>
      </Row>
      <TimeButtons
        cr={cr}
        id={id}
        getTimes={getTimes}
        onChange={onRefresh}
        setMultiSelect={setIsMultiSelect}
        selectedRows={selectedRows}
        isLoading={loading}
      />
      <Row>
        <Col>
          <Time.Table
            times={times}
            isLoading={timesLoading}
            layout={[
              "user",
              "date",
              "billingNote",
              "isBillable",
              "timer",
              "totalHours",
              "rate",
              "amount",
              "invoiceNumber",
            ]}
            onRefresh={onRefresh}
            initialSort={{ id: "date", desc: true }}
            isMultiSelect={isMultiSelect}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </Col>
      </Row>
    </CrPage>
  );
}

function TimeButtons({
  cr,
  id,
  onChange,
  getTimes,
  setMultiSelect,
  selectedRows,
  isLoading = false,
}) {
  const dispatch = useDispatch();
  const timer = Time.useTimer();
  const [showSetall, setShowSetall] = useState(false);
  const [showStartMove, setShowStartMove] = useState(false);
  const [showMoveFinish, setShowMoveFinish] = useState(false);
  const [moveStarted, setMoveStarted] = useState(false);
  const [moveCr, setMoveCr] = useState(null);
  const [moveTheRecords, setMoveTheRecords] = useState(false);

  const [ModifyModal, modifyModelProps, setModifyShow] = Time.useModifyModal({
    handleChange: () => {
      setModifyShow();
      onChange();
    },
  });

  const clickedMoveTimeButton = () => {
    if (moveStarted === false) setShowStartMove(true);
    else if (selectedRows.length === 0) {
      setMoveStarted(false);
      setMultiSelect(false);
    } else {
      let errorMessage = "";
      for (let i = 0; i < selectedRows.length; i++) {
        if (selectedRows[i].invoiceNumber !== "") {
          errorMessage = "You can not move time that is already on an invoice";
          break;
        }
      }
      if (errorMessage === "") setShowMoveFinish(true);
      else toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (moveTheRecords === true) {
      const times = selectedRows.map((single) => single.id);
      dispatch(Time.actions.moveTime({ crId: moveCr.id, times: times }))
        .then(unwrapResult)
        .then(() => {
          setMoveTheRecords(false);
          setMoveStarted(false);
          setMultiSelect(false);
          onChange();
        })
        .catch((result) => {
          console.log(result);
          setMoveTheRecords(false);
        });
    }
  }, [
    dispatch,
    moveCr,
    moveTheRecords,
    onChange,
    selectedRows,
    setMultiSelect,
  ]);

  const moveMessage =
    selectedRows.length > 1
      ? "Are you sure you want to move these " +
        selectedRows.length +
        " records?"
      : "Are you sure you want to move this time record?";

  return (
    <>
      <CrShowTimeSetAll
        cr={cr}
        show={showSetall}
        onHide={() => setShowSetall(false)}
        onSaveSuccess={() => {
          setShowSetall(false);
          getTimes();
        }}
      />
      {cr ? (
        <CrStartMoveTime
          cr={cr}
          show={showStartMove}
          onHide={() => setShowStartMove(false)}
          onOk={(x) => {
            setShowStartMove(false);
            setMoveStarted(true);
            setMoveCr(x);
            setMultiSelect(true);
          }}
        />
      ) : null}
      <SavingModal show={moveTheRecords} />
      <OkCancelModal
        size={"md"}
        show={showMoveFinish}
        onCancel={() => {
          setShowMoveFinish(false);
        }}
        onOk={() => {
          setShowMoveFinish(false);
          setMoveTheRecords(true);
        }}
        okText={"Yes"}
        modalTitle={"Move time records"}
        body={
          <>
            <Row>
              <Col>{"Move to CR " + moveCr?.id}</Col>
            </Row>
            <Row>
              <Col>{moveCr?.customers[0].name}</Col>
            </Row>
            <Row>
              <Col>{moveCr?.synopsis}</Col>
            </Row>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col>{moveMessage}</Col>
            </Row>
          </>
        }
      />
      <Row>
        <Col className="d-flex justify-content-between">
          <div>
            <NewTimeButton
              crId={id}
              startTimer={false}
              onNewTime={onChange}
              disabled={isLoading}
            />
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={clickedMoveTimeButton}
              disabled={isLoading}
            >
              {moveStarted && selectedRows.length > 0
                ? "Move finish"
                : moveStarted
                ? "Move cancel"
                : "Move time"}
            </Button>{" "}
            <Button
              variant="secondary"
              onClick={() => setShowSetall(true)}
              disabled={isLoading}
            >
              Set All
            </Button>
          </div>
          <div>
            {timer.current.status !== "running" ? (
              <NewTimeButton
                crId={id}
                startTimer={true}
                onNewTime={onChange}
                disabled={isLoading}
              />
            ) : (
              <Button
                className="danger text-decoration-none px-0"
                onClick={() => setModifyShow(timer.current.id, true)}
                variant="danger"
                disabled={isLoading}
              >
                <Row>
                  <Col>{"Stop timer"}</Col>
                </Row>
                <Row>
                  <Col>
                    {"Started on: " +
                      _datetime.displayTime(timer.current.startTime)}
                  </Col>
                </Row>
                <Row>
                  <Col>{timer.current.timeProject}</Col>
                </Row>
              </Button>
            )}
          </div>
          <ModifyModal {...modifyModelProps} />
        </Col>
      </Row>
    </>
  );
}

function NewTimeButton({ crId, onNewTime, startTimer = false }) {
  const [showNewModal, setShowNewModal] = useState(false);
  const onCancelModify = useCallback(() => setShowNewModal(false), []);

  const user = useSelector((state) => state.auth.user);
  const date = useMemo(() => moment(), []);

  const desc = startTimer === true ? "Start New Timer" : "New Time Entry";
  const variant = startTimer === true ? "success" : "primary";
  return (
    <>
      <Button variant={variant} onClick={() => setShowNewModal(true)}>
        {desc}
      </Button>
      <Time.NewModal
        show={showNewModal}
        onHide={onCancelModify}
        user={user}
        date={date}
        crId={crId}
        startTimer={startTimer}
        onSave={() => {
          onCancelModify();
          onNewTime();
        }}
        onCancel={onCancelModify}
      />
    </>
  );
}

function CrStartMoveTime({ cr, onHide, onOk, show }) {
  const [newCr, setNewCr] = useState(null);
  const [, setNewCrId] = useState("");
  const [crIsValid, setCrIsValid] = useState(false);

  const handleCrChange = (p) => {
    if (p.setallHold || p.setallBillability) {
      toast.warning("'Set All Time' settings were found on this new Cr.");
    }
    setNewCrId(p.id);
    setNewCr(p);
    setCrIsValid(true);
  };

  return (
    <Modal size="lg" show={show} onHide={() => onHide()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Move time to another CR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item variant={crIsValid ? "primary" : "warning"}>
            <Time.CrEditor
              defaultCustomerId={cr?.customers[0]?.id}
              onChange={(cr) => {
                if (cr) handleCrChange(cr);
                else {
                  setCrIsValid(false);
                  setNewCr(null);
                  setNewCrId(null);
                }
              }}
            />
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onOk(newCr)}
          disabled={!crIsValid}
          variant="primary"
        >
          Ok
        </Button>
        <Button onClick={() => onHide()} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
