import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { timeActions } from "./state";
import TimeForm from "./TimeForm";

export default function TimeModify({
  timeId,
  onSaveSuccess,
  onCancel,
  onDelete,
  openTimers = false,
  stopTimer = false,
  onSplit = () => {},
}) {
  const [time, setTime] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (timeId != null) {
      dispatch(timeActions.getTime({ id: timeId }))
        .then(unwrapResult)
        .then((result) => {
          setTime(result);
        })
        .catch(() => {
          onCancel();
        });
    }
  }, [timeId, dispatch, onCancel]);

  return (
    <>
      {time != null ? (
        <TimeForm
          mode="modify"
          time={time}
          onCancel={onCancel}
          onDelete={onDelete}
          onSaveSuccess={onSaveSuccess}
          openTimers={openTimers}
          stopTimer={stopTimer}
          onSplit={onSplit}
        />
      ) : (
        <center>
          <Alert>
            Loading...
            <Spinner animation="grow" />
          </Alert>
        </center>
      )}
    </>
  );
}
