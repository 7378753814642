import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Form, Row, Tab, Table as BsTable, Tabs } from "react-bootstrap";
import { Controller } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import CrLinkWithContextMenu from "../../common/components/CrLinkWithContextMenu";
import AttachmentsTable from "../../common/details/AttachmentsTable";
import FieldSelect from "../../common/fields/FieldSelect";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter } from "../../common/formatters";
import useReinitState from "../../common/hooks/useReinitState";
import LoadingModal from "../../common/modals/LoadingModal";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import Project from "../../projects";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportWorkSummary() {
  return (
    <JobPage
      title="Work Summary"
      functionName="WorkSummary"
      Options={WorkSummaryOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
      tableOfContents={false}
    />
  );
}

function Report({ job, isLoading }) {
  const { output: { users = [], requiredHours = 0 } = {} } = job;
  const formattedVersion = job?.output?.formattedVersion;
  const attachments = useMemo(() => job?.output?.attachments || [], [job]);

  const buildTimes = (user) => (crs, cr) => {
    const data = crs.concat(
      cr?.time?.map((t) => {
        return {
          ...t,
          userId: user.userId,
          totalHours: t.hours,
          crId: cr.id,
          crTypeId: cr.typeId,
          synopsis: cr.synopsis,
          customers: [
            {
              name: cr.customerName,
            },
          ],
          billingNoteAggregate: (
            <p
              style={{ maxWidth: "400px", whiteSpace: "pre-line" }}
            >{`${cr.synopsis}\n${cr.estimate}`}</p>
          ),
        };
      }),
    );
    return data;
  };
  const { summary, details } = useMemo(
    () =>
      users?.reduce(
        (usersData, { TimebyCR, BillableTime, NonbillableTime, ...user }) => {
          const times = []
            .concat(TimebyCR?.crs?.reduce(buildTimes(user), []) || [])
            .concat(BillableTime?.crs?.reduce(buildTimes(user), []) || [])
            .concat(NonbillableTime?.crs?.reduce(buildTimes(user), []) || []);
          const details = usersData.details.concat({
            userId: user.userId,
            times,
          });
          const summary = usersData.summary.concat([
            {
              userId: user.userId,
              type: "BAC Non-Bill",
              ...user.BACNonbillTimeTotal,
            },
            {
              userId: user.userId,
              type: "BAC Billable",
              ...user.BACBillableTimeTotal,
            },
            {
              userId: user.userId,
              type: "Regular Non-Bill",
              ...user.RegularNonbillTimeTotal,
            },
            {
              userId: user.userId,
              type: "Regular Billable",
              ...user.RegularBillableTimeTotal,
            },
          ]);
          return { summary, details };
        },
        { summary: [], details: [] },
      ) || [],
    [users],
  );

  const [currentTab, setCurrentTab] = useState("summary");

  if (isLoading) {
    return <LoadingModal show={isLoading} />;
  } else if (formattedVersion) {
    return (
      <AttachmentsTable
        attachments={attachments}
        source={{ source: "JB", sourceId: job.id }}
        isDisabled={true}
      />
    );
  } else {
    return (
      <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k)}>
        <Tab eventKey="summary" title="Summary">
          <Row>
            <Col>
              <BsTable size="sm" style={{ width: "auto", minWidth: 200 }}>
                <tbody>
                  <tr>
                    <th type="row">Required hours</th>
                    <td>{requiredHours}</td>
                  </tr>
                </tbody>
              </BsTable>
            </Col>
          </Row>
          <Row>
            <Col>
              <Summary data={summary} isLoading={isLoading} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="details" title="Details" mountOnEnter={true}>
          <Detail data={details} isLoading={isLoading} />
        </Tab>
      </Tabs>
    );
  }
}

function WorkSummaryOptions(props) {
  return (
    <ReportOptions {...props} options={{ formattedVersion: false }}>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="formattedVersion"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <FieldYesNoSelect
                id="formattedVersion"
                label="Run formatted version?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={error}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}

function Summary({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "count",
      },
      {
        id: "type",
        Header: "Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "count",
      },
      {
        id: "hours",
        Header: "Hours",
        accessor: "hours",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: "total",
        dataType: "currency",
        format: { number: { decimals: 0 } },
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <CurrencyFormatter value={value} decimals={0} />
        ),
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else {
                return row.subRows.reduce(calcRow, total);
              }
            // some records have missing data so check if its a number
            return isNaN(row.original.total)
              ? total
              : parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);
          return <CurrencyFormatter value={total} decimals={0} />;
        },
      },
    ],
    [],
  );
  return (
    <Table
      bordered
      hover
      size="sm"
      style={{ width: "auto", minWidth: 600 }}
      columns={columns}
      data={data}
      isLoading={isLoading}
      moveFooterToTop
      initialGroupBy={["user"]}
      getRowProps={(row) => {
        if (row.isGrouped) return { className: "table-secondary" };
        else return {};
      }}
    />
  );
}

const TimeTableComponents = {
  CrLink: CrLink,
};

function Detail({ data = [], isLoading }) {
  const users = data
    .filter((user) => user?.times.length)
    .map((user) => {
      return { value: user.userId, label: user.userId };
    });
  const [user, setUser] = useReinitState(users?.[0]?.value);
  const userValue = _.find(users, ["value", user]);
  const times = useMemo(
    () => _.find(data, (u) => u.userId === user)?.times || [],
    [data, user],
  );

  const columns = useMemo(
    () => [
      {
        Header: "CR Type",
        id: "crType",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        accessor: "crTypeId",
        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
    ],
    [],
  );
  return (
    <Row>
      <Col>
        <FieldSelect
          label="Select User"
          value={userValue}
          options={users}
          onChange={setUser}
        />
        <Row>
          <Col>
            <Time.Table
              times={times}
              isLoading={isLoading && !data?.length}
              layout={[
                "cr",
                "crType",
                "customer",
                "isBillable",
                "date",
                "billingNote",
                "totalHours",
                "amount",
              ]}
              getRowProps={(row) => {
                if (row.isGrouped) return { className: "table-secondary" };
                else return {};
              }}
              additionalColumns={columns}
              Components={TimeTableComponents}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function CrLink(props) {
  return <CrLinkWithContextMenu {...props} readOnly />;
}
