import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../common/entity";
import awsAccountState from "../awsAccounts/state";
import awsInstanceTypeState from "../awsInstanceTypes/state";
import awsRegionState from "../awsRegions/state";
import awsZoneState from "../awsZones/state";
import reservationState from "../reservations/state";
import s3BucketState from "../s3Buckets/state";
import { api } from "./api";

const entity = createEntity({
  name: "server",
  type: "servers",
  getState: (state) => state.servers.servers,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const serverReducer = combineReducers({
  servers: reducer,
  awsInstanceTypes: awsInstanceTypeState.reducer,
  awsZones: awsZoneState.reducer,
  awsAccounts: awsAccountState.reducer,
  awsRegions: awsRegionState.reducer,
  s3Buckets: s3BucketState.reducer,
  reservations: reservationState.reducer,
});

const serverState = {
  api,
  actions,
  reducer: serverReducer,
  entity,
};

export default serverState;
