import { useCallback, useState } from "react";

import TimeModifyModal from "./TimeModifyModal";

const DEFAULT_STATE = { id: null, stopTimer: false };

export default function useTimeModifyModal({
  handleChange = () => {},
  showTimers = false,
}) {
  const [state, setState] = useState(DEFAULT_STATE);
  const updateState = useCallback(
    (id, stopTimer = false) => setState(id ? { id, stopTimer } : DEFAULT_STATE),
    [],
  );

  const resetState = useCallback(() => {
    updateState();
  }, [updateState]);

  const modalProps = {
    id: state?.id,
    show: Boolean(state?.id),
    openTimers: showTimers,
    stopTimer: state?.stopTimer,
    onHide: resetState,
    onSave: handleChange,
    onDelete: handleChange,
    onCancel: resetState,
  };

  return [TimeModifyModal, modalProps, updateState, Boolean(state?.id)];
}
