import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import Customer from "../../customers";
import AwsAccount from "../awsAccounts";
import AwsRegion from "../awsRegions";
import s3BucketSchema from "./s3BucketSchema";

export default function S3BucketEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: s3BucketSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <S3BucketForm isNew={isNew} />
    </Forms>
  );
}

function S3BucketForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ width: "500px" }}>
              <FormsField.Select
                name="customerId"
                label="Customer"
                as={Customer.Select}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "250px" }}>
              <FormsField.Select
                name="awsAccount"
                label="AWS Account"
                as={AwsAccount.Select}
                isDisabled={true}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "250px" }}>
              <FormsField.Select
                name="awsRegion"
                label="AWS Region"
                as={AwsRegion.Select}
                isDisabled={true}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "250px" }}>
              <FormsField name="size" label="Size used in GB" readOnly={true} />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
