import {
  useJiraProject,
  useJiraProjects,
  useJiraProjectsByCustomer,
} from "./hooks";
import JiraProjectEditor from "./JiraProjectEditor";
import JiraProjectLink from "./JiraProjectLink";
import JiraProjectSelectByCustomer from "./JiraProjectSelectByCustomer";
import JiraProjectTable from "./JiraProjectTable";
import JiraProjectTableByCustomer from "./JiraProjectTableByCustomer";
import state from "./state";

const JiraProject = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useJiraProject,
  useAll: useJiraProjects,
  useSearch: useJiraProjectsByCustomer,
  // Components
  Table: JiraProjectTable,
  Link: JiraProjectLink,
  SelectByCustomer: JiraProjectSelectByCustomer,
  TableByCustomer: JiraProjectTableByCustomer,
  Editor: JiraProjectEditor,
  // Related Entity Objects
};

export default JiraProject;
