import { createEntityHooks } from "../../common/entity";
import s3BucketState from "./state";

export const {
  useEntities: useS3Buckets,
  useEntity: useS3Bucket,
  useEntitiesBySearch: useS3BucketsByCustomer,
} = createEntityHooks({
  entity: s3BucketState.entity,
  actions: s3BucketState.actions,
});
