import _ from "lodash";
import React from "react";
import { Alert, Badge, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _date } from "../common/functions/dates";
import Customer from "../customers";

export default function ProjectContacts({
  project,
  isLoading = false,
  mailto,
  customerId,
}) {
  const isLoadingAndInvalid = _.isEmpty(project?.customers) && isLoading;
  const _contacts = isLoadingAndInvalid
    ? _.times(1, _.stubObject)
    : project?.customers.filter(
        (c) => !customerId || customerId === c.customerId,
      );

  return (
    <>
      {_.map(_contacts, (c, index) => (
        <Alert variant="primary" key={"project-contact-" + index}>
          <Row className="justify-content-between">
            <Col xs="auto">
              <Row>
                <Col xs="auto">
                  <Link
                    to={`/customers/${c?.customerId}`}
                    className="alert-link"
                    tabIndex={-1}
                  >
                    <Customer.Name id={c?.customerId} />
                  </Link>
                </Col>
                <Col xs="auto">
                  <Customer.ContactLink
                    contactId={c?.contactId}
                    id={c?.customerId}
                    isLoading={isLoadingAndInvalid}
                    mailto={mailto}
                    canGet
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              {c?.poNumber !== "" && !_.isNil(c?.poNumber) ? (
                <Col xs={"auto"}>
                  <code>{"PO: " + c.poNumber}</code>
                </Col>
              ) : null}
            </Col>
            <CustomerBadges id={c?.customerId} />
          </Row>
          <Row></Row>
        </Alert>
      ))}
    </>
  );
}

function CustomerBadges({ id }) {
  const { customer } = Customer.useOne({ id });
  const codeDate = _date.fromStamp(customer?.lastCodeDate);
  return (
    <Col xs="auto">
      <Badge variant="success" title="Alias">
        {customer?.alias}
      </Badge>
      <Badge variant="light" title="Champion">
        {customer?.championId}
        {customer?.otherChampions.map((ch) => "/" + ch?.userId).join("")}
      </Badge>
      <Badge variant="primary" title="Code Date">
        {codeDate.isValid() ? <>{codeDate.format("MM/DD/YY")} </> : null}
      </Badge>
      <Badge variant="warning" title="Engineering Responsibility">
        {customer?.engineeringResponsibilty}
      </Badge>
    </Col>
  );
}
