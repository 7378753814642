import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import AwsInstanceType from "../../../servers/awsInstanceTypes";
import AwsInstanceTypeNavSidebar from "./AwsInstanceTypeNavSidebar";

export default function AwsInstanceTypeNew() {
  const { createOne } = AwsInstanceType.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title="Aws Instance Type" navSidebar={<AwsInstanceTypeNavSidebar />}>
      <AwsInstanceType.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(awsInstanceType) =>
          createOne(awsInstanceType).then(({ id }) =>
            setRedirect(`/servers/aws-instance-types/${id}`),
          )
        }
      />
    </Page>
  );
}
