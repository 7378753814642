import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useRedirect from "../common/hooks/useRedirect";
import crState from "./state";

export default function useCr({
  id,
  logActivity = false,
  shouldSetCurrent = logActivity,
  redirect = true,
  includeLogs = false,
  canGet = true,
} = {}) {
  const cr = useSelector((state) =>
    crState.entity.selectors.selectById(state, id),
  );
  const dispatch = useDispatch();
  const [redirecting, setRedirect] = useRedirect();
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const { loading, lastUpdate, getCr } = useGetOne({
    id,
    logActivity,
    includeLogs,
  });

  const shouldGet =
    !!id &&
    !isNaN(id) &&
    (canGet === true || (canGet !== false && _.isUndefined(cr)));

  useEffect(() => {
    if (shouldGet)
      getCr()
        .catch(setError)
        .finally(() => setLoaded(true));
  }, [getCr, shouldGet]);

  useEffect(() => {
    if (!shouldSetCurrent) return;
    dispatch(crState.actions.setCurrentCr(id));
    return () => {
      dispatch(crState.actions.setCurrentCr(null));
    };
  }, [id, shouldSetCurrent, dispatch]);

  return {
    cr,
    loading: shouldGet && (loading || !loaded || !!redirecting),
    error,
    lastUpdate,
    getCr,
    createOne: (cr) =>
      dispatch(crState.actions.createOne(cr))
        .then(unwrapResult)
        .then(({ data: result } = {}) => {
          if (redirect) setRedirect("/crs/" + result.id);
          return result;
        }),
    updateOne: (changes) =>
      dispatch(crState.actions.updateOne({ id: id, data: changes }))
        .then(unwrapResult)
        .then(({ data: result } = {}) => {
          if (redirect) setRedirect("/crs/" + id);
          return result;
        })
        .catch((err) => {
          if (err?.message === "Sync error") {
            getCr().then(() => {
              throw new Error("CR out of date, please try again");
            });
          } else {
            throw err;
          }
        }),

    createComment: ({ title, text }) =>
      dispatch(crState.actions.createComment({ cr: cr?.id, title, text })),
    updateComment: ({ id, title, text }) =>
      dispatch(crState.actions.updateComment({ cr: cr?.id, id, title, text })),
    createQaComment: ({ title, text }) =>
      dispatch(crState.actions.createQaComment({ cr: cr?.id, title, text })),
    updateQaComment: ({ id, title, text }) =>
      dispatch(
        crState.actions.updateQaComment({ cr: cr?.id, id, title, text }),
      ),
    createAttachment: (args) =>
      dispatch(crState.actions.createAttachment({ id, ...args })).then(
        unwrapResult,
      ),
    updateWatch: (args) =>
      dispatch(crState.actions.updateWatch({ id: cr?.id, ...args })).then(
        unwrapResult,
      ),
  };
}

useCr.getOne = useGetOne;
useCr.moveOne = useMoveOne;

function useGetOne({ id, logActivity = false, includeLogs = false } = {}) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.crs.crs.requests.loading);
  const [lastUpdate, setLastUpdate] = useState(null);

  const getCr = useCallback(
    (args) =>
      dispatch(
        crState.actions.getOne({
          id: id,
          logActivity: logActivity,
          includeLogs: includeLogs,
          ...args,
        }),
      )
        .then(unwrapResult)
        .then((result) => {
          setLastUpdate(moment());
          return result;
        }),
    [id, logActivity, includeLogs, dispatch],
  );
  return { loading, lastUpdate, getCr };
}

function useMoveOne() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.crs.crs.requests.loading);

  const moveOne = useCallback(
    (args) =>
      dispatch(
        crState.actions.moveOne({
          ...args,
        }),
      )
        .then(unwrapResult)
        .then((result) => {
          const { data: cr } = result;
          toast.success(`CR ${cr.id} moved to ${cr.projectId}`);
          return result;
        }),
    [dispatch],
  );
  return { loading, moveOne };
}
