import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Customer from "../customers";
import AwsAccount from "./awsAccounts/";
import AwsInstanceTypeLink from "./awsInstanceTypes/AwsInstanceTypeLink";
import AwsZoneLink from "./awsZones/AwsZoneLink";
import getServerAlias from "./getServerAlias";
import ServerLink from "./ServerLink";
import { SERVER_TYPES } from "./types";

export default function ServerTable({
  servers,
  serverLink = true,
  layout = [
    "Description",
    "Customer",
    "Hostname",
    "Type",
    "Alias",
    "Engineering Responsibility",
    "Locality",
  ],
  ...props
}) {
  const customers = useSelector((state) =>
    Customer.selectors.selectEntities(state),
  );

  const columns = useMemo(
    () => [
      {
        id: "Description",
        Header: "Description",
        accessor: "description",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: server } }) =>
          serverLink ? (
            <ServerLink customerId={server?.customerId} id={server?.id}>
              {value || server?.hostname || server?.id}
            </ServerLink>
          ) : (
            value
          ),
      },
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <Customer.Link id={value} />,
      },

      {
        id: "Type",
        Header: "Type",
        accessor: (server) =>
          SERVER_TYPES[server?.serverType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Engineering Responsibility",
        Header: "Engineering",
        accessor: (server) => {
          switch (server?.engineeringResponsibility) {
            case "P":
              return "Produce Pro";
            case "E":
              return "Ed White";
            case "C":
              return "Customer IT";
            case "3":
              return "3rd Party";
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Locality",
        Header: "Locality",
        accessor: (server) => {
          switch (server?.locality) {
            case "A":
              return "AWS hosted by Ppro";
            case "L":
              return "Local hardware";
            case "R":
              return "Other-remote";
            default:
              return "";
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "AWS Account",
        Header: "AWS Account",
        accessor: "awsAccount",
        Filter: AwsAccount.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsAccount.Link id={value} />,
      },
      {
        id: "AWS Zone",
        Header: "AWS Zone",
        accessor: "awsZone",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "AWS Instance Type",
        Header: "AWS Instance Type",
        accessor: "awsInstanceType",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
      {
        id: "AWS Instance Id",
        Header: "AWS Instance Id",
        accessor: "awsInstanceId",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Hostname",
        Header: "Hostname",
        accessor: "hostname",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "IP Address",
        Header: "IP Address",
        accessor: "ipAddress",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "OS Version",
        Header: "OS Version",
        accessor: "osVersion",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Hardware Expiration Date",
        Header: "Hardware Warranty Expiration",
        accessor: "hardwareExpirationDate",
        Cell: ({ value }) => _date.display(_date.fromStamp(value)),
      },
      {
        id: "redHatLicenseExpirationDate",
        Header: "RedHat License Expiration",
        accessor: "redHatLicenseExpirationDate",
        Cell: ({ value }) => _date.display(_date.fromStamp(value)),
      },
      {
        id: "sshOptions",
        Header: "SSH Options",
        accessor: "sshOptions",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "sshConfig",
        Header: "SSH Config",
        accessor: "sshConfig",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Alias",
        Header: "Alias",
        accessor: (server) =>
          getServerAlias(server, customers?.[server.customerId]),
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [serverLink, customers],
  );
  return (
    <Table
      hasCount
      label="Servers"
      columns={columns}
      layout={layout}
      data={servers}
      {...props}
    />
  );
}
