import DefectOccurrenceDescription from "./DefectOccurrenceDescription";
import DefectOccurrenceSelect from "./DefectOccurrenceSelect";
import { useDefectOccurrence, useDefectOccurrences } from "./hooks";

const DefectOccurrence = {
  // Hooks
  useAll: useDefectOccurrences,
  useOne: useDefectOccurrence,
  // Components
  Select: DefectOccurrenceSelect,
  Description: DefectOccurrenceDescription,
};

export default DefectOccurrence;
