import React from "react";
import { Alert, Modal } from "react-bootstrap";

import StampDisplay from "../../common/components/StampDisplay";
import AttachmentsTable from "../../common/details/AttachmentsTable";
import QaReviewEditor from "./QaReviewEditor";

export default function QaReviewEditorModal({
  title,
  show,
  onHide,
  isNew,
  values,
  createAttachment,
  refreshRecord,
  id,
  ...props
}) {
  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QaReviewEditor
          onCancel={onHide}
          isNew={isNew}
          values={values}
          {...props}
        />
        <br></br>
        {!isNew ? (
          <>
            <Alert variant={"info"}>
              <AttachmentsTable
                attachments={values.attachments}
                isDisabled={true}
              />
            </Alert>
            <StampDisplay record={values} />
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
