import CodeReviewTable from "./CodeReviewTable";
import { useCodeReview, useCodeReviews, useCodeReviewsSearch } from "./hooks";
import Repository from "./repositories/";
import state from "./state";

const CodeReview = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useCodeReview,
  useAll: useCodeReviews,
  useSearch: useCodeReviewsSearch,
  // Components
  Table: CodeReviewTable,
  Repository,
};
export default CodeReview;
