import { useS3Bucket, useS3Buckets, useS3BucketsByCustomer } from "./hooks";
import S3BucketEditor from "./S3BucketEditor";
import S3BucketLink from "./S3BucketLink";
import S3BucketSelect from "./S3BucketSelect";
import Table from "./S3BucketTable";
import s3BucketState from "./state";

const S3Bucket = {
  // API
  api: s3BucketState.api,
  // State
  actions: s3BucketState.actions,
  selectors: s3BucketState.entity.selectors,
  // Hooks
  useOne: useS3Bucket,
  useAll: useS3Buckets,
  useSearch: useS3BucketsByCustomer,
  // Components
  Table,
  Link: S3BucketLink,
  Editor: S3BucketEditor,
  Select: S3BucketSelect,
  // Related Entity Objects
};

export default S3Bucket;
