import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import AwsZone from "../../../servers/awsZones";
import AwsZoneNavSidebar from "./AwsZoneNavSidebar";

export default function AwsZoneNew() {
  const { createOne } = AwsZone.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title="Aws Zone" navSidebar={<AwsZoneNavSidebar />}>
      <AwsZone.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(awsZone) =>
          createOne(awsZone).then(({ id }) =>
            setRedirect(`/servers/aws-zones/${id}`),
          )
        }
      />
    </Page>
  );
}
