import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "./state";

export default function useAppFormsState({ key, value }) {
  if (!key) throw new Error("Invalid key");

  const formState = useSelector((state) => state.app.forms[key]);
  const loading = useSelector((state) => state.app.requests.loading);
  const lastRehydrate = useSelector((state) => state.app.lastRehydrate);

  const dispatch = useDispatch();

  const _formState = formState || value;
  const [state, setState] = useState(_formState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFormState = useCallback(
    debounce((value) => dispatch(appActions.setForms({ key, value })), 300),
    [dispatch, key],
  );

  useEffect(() => debouncedSetFormState(state), [state, debouncedSetFormState]);

  const [initial, setInitial] = useState(state);

  const _setState = useCallback((value, shouldUpdateInitial = false) => {
    setState(value);
    if (shouldUpdateInitial) setInitial(value);
  }, []);

  // Update initial state on rehydrate
  useEffect(() => {
    _setState(_formState, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRehydrate]);

  return { state, setState: _setState, loading, initial };
}
