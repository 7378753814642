import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import HelixLink from "../../common/components/HelixLink";
import FieldDate from "../../common/fields/FieldDate";
import { _date, _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Customer from "../../customers";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportCodeChanges() {
  return (
    <JobPage
      title="Code changes"
      functionName="CodeChanges"
      Options={CodeChangesOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.revisions || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Cr Owner",
        Header: "CR Owner",
        accessor: "crOwner",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        id: "Revision",
        Header: "Revision",
        accessor: "revision",

        Cell: ({ value, row }) => (
          <HelixLink revisionType={row?.original?.type} revision={value} />
        ),
      },
      {
        id: "Author",
        Header: "Author",
        accessor: "authorId",

        Cell: ({ value }) => (
          <User.Link id={value?.toUpperCase()} showId={true} />
        ),
      },
      {
        id: "File Path",
        Header: "File Path",
        accessor: "filepath",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Comment",
        Header: "Comment",
        accessor: "comment",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Commit Time",
        Header: "Commit Time",
        accessor: "datetime",
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ value }) => (
          <>{_datetime.displayDateTime(_datetime.fromStamp(value))}</>
        ),
      },
      {
        id: "Cr",
        Header: "Cr",
        accessor: "crId",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => <Cr.Link id={value} />,
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "File Path",
              "Revision",
              "Author",
              "Commit Time",
              "Cr",
              "Cr Owner",
              "Comment",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

function CodeChangesOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("month"),
  endDate: ed = moment().endOf("month"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const { handleSubmit, control, watch, errors } = useForm({
    mode: "onTouched",
    defaultValues: {
      customerId: "",
      startDate: "",
      endDate: "",
      hiddenTime: false,
      projectType: "",
      ...options,
    },
  });

  const endDate = watch("endDate");
  const startDate = watch("startDate");
  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          "from " +
          _date.display(options.startDate) +
          " to " +
          _date.display(options.endDate);

        if (_.isNil(customerId) || customerId === "") {
          desc = "All customers " + desc;
        } else {
          desc = useCustomerValue?.customer?.name + " " + desc;
        }
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          hiddenTime: _.isNil(options.hiddenTime) ? false : options.hiddenTime,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={errors?.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
