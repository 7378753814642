import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import FieldDate from "../../common/fields/FieldDate";
import FieldInput from "../../common/fields/FieldInput";
import FieldSelect from "../../common/fields/FieldSelect";
import { _date } from "../../common/functions/dates";
import useReinitState from "../../common/hooks/useReinitState";
import Customer from "../../customers";
import Project from "../../projects";
import User from "../../users";
import Difficulty from "../difficulties";
import Module from "../modules";
import NotificationType from "../notificationTypes";
import Status from "../statuses";

const DateInput = ({ onChange, value }) => (
  <FieldDate
    label={null}
    value={
      _.isNull(value)
        ? value
        : moment.isMoment(value)
        ? value
        : _date.fromStamp(value)
    }
    onChange={(d) => onChange(d ? _date.stamp(d) : d)}
    includeNow={false}
    isClearable
  />
);

const NumberInput = ({ onChange, value }) => (
  <FieldInput type="number" onChange={onChange} value={value} min="0" />
);

const CheckInput = ({ onChange, value, name }) => (
  <Form.Check
    label={name}
    inline
    checked={value}
    onChange={(e) => onChange(e.target.checked)}
  />
);

const fields = {
  owners: {
    name: "Owner",
    include: "ownersInclude",
    Input: ({ onChange, value }) => (
      <User.Select
        value={value}
        onChange={onChange}
        pseudo={true}
        placeholder="Select Owner(s)..."
        isMulti
      />
    ),
  },
  crStatuses: {
    name: "Status",
    include: "crStatusesInclude",
    Input: ({ onChange, value }) => (
      <Status.Select
        value={value}
        onChange={onChange}
        placeholder="Select Status(es)..."
        isMulti
      />
    ),
  },
  champions: {
    name: "Champion",
    include: "championsInclude",
    Input: ({ onChange, value }) => (
      <User.Select
        value={value}
        onChange={onChange}
        placeholder="Select Champion(s)..."
        isChampion
        isMulti
      />
    ),
  },
  closedStart: {
    name: "Closed Date (Start)",
    Input: DateInput,
  },
  closedEnd: {
    name: "Closed Date (End)",
    Input: DateInput,
  },
  closedUsers: {
    name: "Closed by User",
    include: "closedUsersInclude",
    Input: ({ onChange, value }) => (
      <User.Select
        value={value}
        onChange={onChange}
        placeholder="Select User(s)..."
        isMulti
      />
    ),
  },
  crDifficulties: {
    name: "Difficulty",
    include: "crDifficultiesInclude",
    Input: ({ onChange, value }) => (
      <Difficulty.Select
        value={value}
        onChange={onChange}
        placeholder="Select Difficulties(s)..."
        isMulti
      />
    ),
  },
  createStart: {
    name: "Create Date (Start)",
    Input: DateInput,
  },
  createEnd: {
    name: "Create Date (End)",
    Input: DateInput,
  },
  createUsers: {
    name: "Created by User",
    include: "createUsersInclude",
    Input: ({ onChange, value }) => (
      <User.Select
        value={value}
        onChange={onChange}
        placeholder="Select User(s)..."
        isMulti
      />
    ),
  },
  modifyStart: {
    name: "Modify Date (Start)",
    Input: DateInput,
  },
  modifyEnd: {
    name: "Modify Date (End)",
    Input: DateInput,
  },
  modifyUsers: {
    name: "Modify by User",
    include: "modifyUsersInclude",
    Input: ({ onChange, value }) => (
      <User.Select
        value={value}
        onChange={onChange}
        placeholder="Select User(s)..."
        isMulti
      />
    ),
  },
  crModules: {
    name: "Module",
    include: "crModulesInclude",
    Input: ({ onChange, value }) => (
      <Module.Select
        value={value}
        onChange={onChange}
        placeholder="Select Module(s)..."
        isMulti
      />
    ),
  },
  crNotifications: {
    name: "Notification Type",
    include: "crNotificationsInclude",
    Input: ({ onChange, value }) => (
      <NotificationType.Select
        value={value}
        onChange={onChange}
        placeholder="Select Notification Type(s)..."
        isMulti
      />
    ),
  },
  customers: {
    name: "Customer",
    include: "customersInclude",
    Input: ({ onChange, value }) => (
      <Customer.Select
        value={value}
        onChange={onChange}
        placeholder="Select Customer(s)..."
        isMulti
      />
    ),
  },
  estimateMaxHoursStart: {
    name: "Estimate Hours Maximum (Start)",
    Input: NumberInput,
  },
  estimateMaxHoursEnd: {
    name: "Estimate Hours Maximum (End)",
    Input: NumberInput,
  },
  estimateMinHoursStart: {
    name: "Estimate Hours Minimum (Start)",
    Input: NumberInput,
  },
  estimateMinHoursEnd: {
    name: "Estimate Hours Minimum (End)",
    Input: NumberInput,
  },
  groups: {
    name: "Groups",
    include: "groupsInclude",
    Input: ({ onChange, value }) => (
      <User.Group.Select
        value={value}
        onChange={onChange}
        placeholder="Select Group(s)..."
        isMulti
      />
    ),
  },
  isAssigned: {
    name: "Assigned?",
    label: null,
    defaultValue: false,
    Input: CheckInput,
  },
  isMultiCustomer: {
    name: "Multiple Customers?",
    label: null,
    defaultValue: false,
    Input: CheckInput,
  },
  isReadyForCodeCheckin: {
    name: "Ready for code check in?",
    label: null,
    defaultValue: false,
    Input: CheckInput,
  },
  isWaitingOnCustomer: {
    name: "Waiting?",
    label: null,
    defaultValue: false,
    Input: CheckInput,
  },
  isSAMFollowup: {
    name: "SAM follow-up?",
    label: null,
    defaultValue: false,
    Input: CheckInput,
  },
  projectEstimateStatuses: {
    name: "Project estimate status",
    include: "projectEstimateStatusesInclude",
    Input: ({ onChange, value }) => (
      <Project.EstimateStatus.Select
        value={value}
        onChange={onChange}
        placeholder="Select Estimate Status(s)..."
        isMulti
      />
    ),
  },
  projectPriorities: {
    name: "Project priority",
    include: "projectPrioritiesInclude",
    Input: ({ onChange, value }) => (
      <Project.Priority.Select
        value={value}
        onChange={onChange}
        placeholder="Select Priority(s)..."
        isMulti
      />
    ),
  },
  projectSources: {
    name: "Project sources",
    include: "projectSourcesInclude",
    Input: ({ onChange, value }) => (
      <Project.Source.Select
        value={value}
        onChange={onChange}
        placeholder="Select Source(s)..."
        isMulti
      />
    ),
  },
  projectStatuses: {
    name: "Project status",
    include: "projectStatusesInclude",
    Input: ({ onChange, value }) => (
      <Project.Status.Select
        value={value}
        onChange={onChange}
        placeholder="Select Status(s)..."
        isMulti
      />
    ),
  },
  targetDeliveryStart: {
    name: "Target Delivery Date (Start)",
    Input: DateInput,
  },
  targetDeliveryEnd: {
    name: "Target Delivery Date (End)",
    Input: DateInput,
  },
  targetFirstContactStart: {
    name: "Target First Contact Date (Start)",
    Input: DateInput,
  },
  targetFirstContactEnd: {
    name: "Target First Contact Date (End)",
    Input: DateInput,
  },
  targetStartWorkStart: {
    name: "Target Start Work Date (Start)",
    Input: DateInput,
  },
  targetStartWorkEnd: {
    name: "Target Start Work Date (End)",
    Input: DateInput,
  },
  types: {
    name: "Type",
    include: "typesInclude",
    Input: ({ onChange, value }) => (
      <Project.Type.Select
        value={value}
        onChange={onChange}
        placeholder="Select Type(s)..."
        isMulti
      />
    ),
  },
  projects: {
    name: "Project",
    Input: ({ onChange, value }) => (
      <Project.Select
        value={value?.[0]}
        onChange={(project) => onChange([project?.id])}
        placeholder="Select Project..."
      />
    ),
  },

  //TODO
  // contacts: {},
  // contactsInclude: {},
  // crModuleGroups: {},
  // crModuleGroupsInclude: {},
  // qaOwners: {},
  // qaOwnersInclude: {},
  // qaStatuses: {},
  // qaStatusesInclude: {},
};

const fieldsOptions = Object.entries(fields).map(([key, value]) => ({
  value: key,
  label: value?.label || value?.name || key,
}));

export default function CrFilterParameters({
  values: initialValues,
  onChange,
}) {
  const [values, setValues] = useReinitState(initialValues);
  const [changed, setChanged] = useState(false);
  function handleChange(field, opts) {
    setValues((values) => ({
      ...values,
      [field]: opts,
    }));
    setChanged(true);
  }

  useEffect(() => {
    if (changed) {
      onChange(values);
      setChanged(false);
    }
  }, [changed, onChange, values]);

  return (
    <Row>
      <Col>
        <Row className="pb-0">
          <Col md={12} lg={5} className="border-right">
            <Row>
              <Col>
                <Project.Folder.Select
                  label="Folder"
                  value={values?.folderId || "CURRENT"}
                  onChange={(opts) => handleChange("folderId", opts)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>Add parameter</Form.Label>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <FieldSelect
                        options={fieldsOptions}
                        onChange={(opt) => {
                          const includeField = fields[opt]?.include;
                          const defaultValue = fields[opt]?.defaultValue;
                          handleChange(
                            opt,
                            _.isUndefined(defaultValue) ? null : defaultValue,
                          );
                          if (includeField) handleChange(includeField, true);
                        }}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col>
            {values &&
              Object.entries(values).map(([key, value]) => {
                const field = fields?.[key];
                if (!field) {
                  // console.warn("Unknown filter key", key);
                  return null;
                }
                const Input = field.Input;
                const includeField = field.include;
                return (
                  <Form.Group key={key} className="my-0">
                    <Form.Row>
                      <Col>
                        {_.isNull(field.label) ? null : (
                          <Form.Label>{field.label || field.name}</Form.Label>
                        )}
                      </Col>
                      {includeField ? (
                        <ExcludeFlag
                          name={field.name}
                          id={`${key}-exclude`}
                          value={values[includeField]}
                          onChange={(v) => handleChange(includeField, v)}
                        />
                      ) : null}
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Input
                          onChange={(v) => handleChange(key, v)}
                          value={value}
                          name={field?.name || field?.key}
                        />
                      </Col>
                      <Col xs="auto">
                        <Button
                          variant="danger"
                          onClick={() => {
                            let newFilter = { ...values };
                            delete newFilter[key];
                            if (includeField) delete newFilter[includeField];
                            onChange(newFilter);
                          }}
                          title="Remove parameter"
                        >
                          -
                        </Button>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                );
              })}
          </Col>
        </Row>
        <Row className="pt-3"></Row>
      </Col>
    </Row>
  );
}

function ExcludeFlag({ value, onChange, name }) {
  return (
    <Col xs="auto">
      <Form.Check
        id={`${name}-exclude`}
        inline
        label="Exclude"
        checked={!value}
        onChange={(e) => onChange(!e.target.checked)}
      />
    </Col>
  );
}
