import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import Pseudo from "../../../users/pseudo";
import PseudoNavSidebar from "./PseudoNavSidebar";

export default function PseudoNew() {
  const { createOne } = Pseudo.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title="Pseudo User" navSidebar={<PseudoNavSidebar />}>
      <Pseudo.Editor
        isNew={true}
        values={{ isActive: true }}
        onSubmit={(pseudo) =>
          createOne(pseudo).then(({ id }) =>
            setRedirect(`/users/pseudos/${id}`),
          )
        }
      />
    </Page>
  );
}
