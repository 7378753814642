import shark from "../../apis/shark";

function getContractsAPI(searchArgs) {
  const payload = {
    command: "getContracts",
    ...searchArgs,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.contracts,
    searchArgs: searchArgs,
  }));
}

function getContractAPI({ id }) {
  const payload = {
    command: "getContract",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createContractAPI(contract) {
  const payload = {
    command: "createContract",
    ...contract,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateContractAPI(contract) {
  const payload = {
    command: "updateContract",
    ...contract,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getContractsAPI,
  getOne: getContractAPI,
  createOne: createContractAPI,
  updateOne: updateContractAPI,
};
