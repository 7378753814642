import _ from "lodash";
import React from "react";
import { createFilter } from "react-select";

import FieldSelect from "../common/fields/FieldSelect";
import useSettings from "../settings/useSettings";
import { useCustomers } from "./hooks";

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFromStart: true,
  stringify: ({ data }) => {
    return `${data?.sortName?.replace(/[-']/g, "") || ""} ${data.label}`;
  },
  trim: true,
});

const CustomersSelect = React.forwardRef(
  (
    {
      value,
      isMulti = false,
      champion,
      placeholder = "Select Customer...",
      shouldValuesSortLikeOptions = false,
      filter = (c) =>
        (c.inactive === false || c.id === value) &&
        (!champion || champion === c.championId),
      ...props
    },
    ref,
  ) => {
    const { customers, loading } = useCustomers();
    const { settings: { customerSort } = {} } = useSettings();

    const _options = customers
      .filter(filter)
      .map((f) => {
        return { label: f.name, value: f.id, sortName: f.sortName };
      })
      .sort((a, b) => {
        const getSortValue = (opt) =>
          customerSort === "A" || !opt?.sortName ? opt.label : opt.sortName;
        const sortValueA = getSortValue(a);
        const sortValueB = getSortValue(b);
        return sortValueA.toLowerCase().localeCompare(sortValueB.toLowerCase());
      });

    let valueOption = null;
    if (value)
      if (isMulti) {
        valueOption = shouldValuesSortLikeOptions
          ? _options.filter((o) => value.includes(o.value))
          : value.map((v) => _.find(_options, (o) => o.value === v));
      } else {
        valueOption = _.find(_options, ["value", value]);
      }

    return (
      <FieldSelect
        ref={ref}
        isLoading={loading}
        value={valueOption}
        options={_options}
        isMulti={isMulti}
        placeholder={placeholder}
        filterOption={filterOption}
        {...props}
      />
    );
  },
);

export default CustomersSelect;
