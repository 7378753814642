// TODO: Display is still messed up for practice upgrade / onsite project
// fields. The variables appear to be getting filled in... the form
// just isn't displaying them.

import { isUndefined } from "lodash";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import DetailTable from "../../common/details/DetailTable";
import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import Project from "../../projects";
import User from "../../users";
import CustomersSelect from "../CustomerSelect";
import jiraSchema from "./jiraProjectSchema";

export default function JiraProjectEditor({
  values: { startDate, endDate, ...values } = {},
  onSubmit,
  isDisabled = false,
  isNew = false,
}) {
  return (
    <Form.Row>
      <Col>
        <Forms
          defaultValues={{
            startDate: startDate
              ? _date.fromStamp(startDate).toISOString()
              : "",
            endDate: endDate ? _date.fromStamp(endDate).toISOString() : "",
            ...values,
          }}
          onSubmit={({ startDate, endDate, ...values }) =>
            onSubmit({
              startDate: startDate ? _date.stamp(startDate) : "",
              endDate: endDate ? _date.stamp(endDate) : "",
              ...values,
            })
          }
          schema={{ schema: jiraSchema }}
          isDisabled={isDisabled}
          showDevTool
        >
          <JiraForm isNew={isNew} />
        </Forms>
      </Col>
      <Col xs="auto" style={{ width: 250 }}>
        <DetailTable
          fields={[
            {
              header: "Send to T3",
              value: values?.sendCount,
              className: "text-right",
            },
            {
              header: "Open Defects",
              value: values?.openDefectCount,
              className: "text-right",
            },
            {
              header: "Closed Defects",
              value: values?.closedDefectCount,
              className: "text-right",
            },
            {
              header: "Open Custom",
              value: values?.openCustomCount,
              className: "text-right",
            },
            {
              header: "Closed Custom",
              value: values?.closedCustomCount,
              className: "text-right",
            },
          ]}
        />
      </Col>
    </Form.Row>
  );
}

function JiraForm({ isNew }) {
  const { submitForm } = useFormContext();
  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <FormsField name="jiraKey" label="Jira Prefix" />
        </Col>
        <Col xs="auto">
          <FormsField name="id" label="Jira Project Number" readOnly={!isNew} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto" style={{ width: "500px" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={CustomersSelect}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto" style={{ width: "710px" }}>
          <FormsField name="description" label="Description" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <FormsField.Date
            name="startDate"
            label="Start Date"
            includeNow={false}
            includeArrows={false}
            autoComplete="off"
          />
        </Col>
        <Col xs="auto">
          <FormsField.Date
            name="endDate"
            label="End Date"
            includeNow={false}
            includeArrows={false}
            autoComplete="off"
          />
        </Col>
        <Col xs="auto" style={{ width: "290px" }}>
          <FormsField.Select
            as={User.Select}
            name="techLead"
            label="Tech Lead"
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <ProjectId
            name="upgradeProject"
            label="Practice Upgrade Issues Project"
          />
        </Col>
        <Col xs="auto">
          <ProjectId name="onsiteProject" label="Onsite Project" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto" className="pt-3">
          <JiraTypeSelect name="projectType" label="Type" />
        </Col>
      </Form.Row>
      <Form.Row className="pt-3">
        <Button variant="success" onClick={submitForm}>
          Submit
        </Button>
      </Form.Row>
    </Form>
  );
}

function ProjectId({ name, label, isDisabled }) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({ name });

  return (
    <Form.Row>
      <Col style={{ minWidth: "400px" }}>
        <Project.Select
          label={label}
          onChange={(v) => {
            const value = v === null ? "" : v?.id;
            onChange(value);
          }}
          value={value || null}
          onBlur={onBlur}
          isInvalid={error}
          isDisabled={isDisabled}
          errors={error?.message}
          canFetch={"auto"}
          isClearable
          canAllowTransferred
        />
      </Col>
    </Form.Row>
  );
}

function JiraTypeSelect({ name, id = name, label, isInvalid, ...props }) {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        name={name}
        label="Type"
        as="select"
        onChange={(v) => {
          onChange(v);
        }}
        disabled={isDisabled}
        isInvalid={isUndefined(isInvalid) ? !!error : isInvalid}
        {...field}
        {...props}
      >
        <option value=""></option>
        <option value="L">Go live</option>
        <option value="W">WMS</option>
        <option value="U">Upgrade</option>
        <option value="M">Merger</option>
        <option value="Q">QA</option>
        <option value="C">Consulting trip</option>
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
