import _ from "lodash";
import React from "react";

import FieldSelect from "../common/fields/FieldSelect";
import { useCustomer } from "./hooks";

export default function CustomerLocationSelect({
  value,
  isMulti = false,
  customerId,
  placeholder = "Select Location...",
  onChange = () => {},
  addOther = false,
  addInvoice = false,
  ...props
}) {
  const { customer, loading } = useCustomer({ id: customerId, canGet: true });

  const _options = customer?.locations
    .filter((f) => !(f.type === "invoice" && addInvoice === false))
    .map((f) => {
      return { label: f.description, value: f.code };
    })
    .sort((a, b) => {
      var nameA = a.label.toUpperCase(); // ignore upper and lowercase
      var nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
  if (addOther) {
    _options.push({ label: "other", value: "other" });
  }

  const handleOnChange = (v) => {
    let valueOption = null;
    if (v) {
      if (isMulti) {
        valueOption = _options.filter((o) => v.includes(o.value));
      } else {
        valueOption = _.find(_options, ["value", v]);
      }
    }
    onChange(valueOption);
  };

  let valueOption = null;
  if (value)
    if (isMulti) {
      valueOption = _options.filter((o) => value.includes(o.value));
    } else {
      valueOption = _.find(_options, ["value", value]);
    }

  return (
    <FieldSelect
      isLoading={loading}
      value={valueOption}
      options={_options}
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={handleOnChange}
      {...props}
    />
  );
}
