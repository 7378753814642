import FtpUserEditor from "./FtpUserEditor";
import FtpUserSelect from "./FtpUserSelect";
import FtpUserTable from "./FtpUserTable";
import { useFtpUser, useFtpUsers } from "./hooks";
import ftpUserState from "./state";

const FtpUser = {
  // Backend API
  api: ftpUserState.api,
  // State
  actions: ftpUserState.actions,
  selectors: ftpUserState.entity.selectors,
  // Hooks
  useAll: useFtpUsers,
  useOne: useFtpUser,
  // Components
  Select: FtpUserSelect,
  Table: FtpUserTable,
  Editor: FtpUserEditor,
};

export default FtpUser;
