import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import ContactLink from "./ContactLink";
import ContactLookupModal from "./ContactLookupModal";
import SelectByCustomer from "./ContactSelectByCustomer";
import Show from "./ContactShow";
import Table from "./ContactTable";
import ContactTableByCustomer from "./ContactTableByCustomer";
import ContactType from "./contactTypes";
import { useContact, useContacts, useContactsByCustomer } from "./hooks";
import state from "./state";

export function Routing() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <Show />
      </Route>
      <Route exact path={`${path}/`}>
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
}

const Contact = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useContact,
  useAll: useContacts,
  useSearch: useContactsByCustomer,
  // Components
  Show,
  Table,
  Link: ContactLink,
  SelectByCustomer,
  TableByCustomer: ContactTableByCustomer,
  LookupModal: ContactLookupModal,
  ContactType: ContactType,
  // Related Entity Objects
};

export default Contact;
