import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import FtpUserEdit from "./FtpUserEdit";
import FtpUserIndex from "./FtpUserIndex";
import FtpUserNew from "./FtpUserNew";

export default function FtpUsersPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <FtpUserNew />
      </Route>
      <Route path={`${path}/:id`}>
        <FtpUserEdit />
      </Route>
      <Route exact path={`${path}`}>
        <FtpUserIndex />
      </Route>
    </Switch>
  );
}
