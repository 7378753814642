import shark from "../../apis/shark";

export function getSettingsAPI() {
  const payload = {
    command: "getSettings",
  };

  return shark.post(payload);
}

export function updateSettingsAPI(changes) {
  const payload = {
    command: "updateSettings",
    ...changes,
  };

  return shark.post(payload);
}
