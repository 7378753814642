import { yupResolver as yupResolverDefault } from "@hookform/resolvers/yup";
import { isFunction, isUndefined } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import useMemoCompare from "../hooks/useMemoCompare";
import useReinitState from "../hooks/useReinitState";

export function useForms({
  mode = "onChange",
  defaultValues: initialDefaultValues,
  context: initialContext,
  schema: schemaOptions,
  onSubmit,
  ...props
}) {
  const [context, setContext] = useReinitState(initialContext);
  const defaultValues = useMemoCompare(
    () => initialDefaultValues || {},
    [initialDefaultValues],
  );

  const resolver = !isUndefined(schemaOptions)
    ? yupResolver(schemaOptions)
    : undefined;
  const formProps = useForm({
    resolver,
    ...props,
    mode,
    defaultValues,
    context,
  });

  const { reset } = formProps;
  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  return {
    ...formProps,
    context,
    setContext,
    defaultValues,
    submitForm: isFunction(onSubmit)
      ? (args = {}) =>
          formProps.handleSubmit((data, event) => onSubmit(data, event, args))(
            args,
          )
      : undefined,
  };
}

// Wrapper around the yup resolver to only return errors that are
//    valid fields for the form
const yupResolver =
  ({
    schema: schemaInitial,
    options: schemaOptions = {
      abortEarly: false,
    },
    reduce: shouldReduceSchema = true,
  }) =>
  async (values, context, resolverOptions = {}) => {
    const fields = Object.entries(resolverOptions.fields).map(([name]) => name);

    const schema =
      shouldReduceSchema !== true ? schemaInitial : schemaInitial.pick(fields);

    // console.log(schema, resolverOptions, values);

    // try {
    //   const castValues = await schema.validate(
    //     values,
    //     Object.assign({}, schemaOptions, { context }),
    //   );
    //   console.log("yupResolver.cast", castValues);
    // } catch (e) {
    //   console.log("yupResolver.error", e);
    // }

    return await yupResolverDefault(schema, schemaOptions)(
      values,
      context,
      resolverOptions,
    );
  };
