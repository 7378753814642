import { combineReducers } from "@reduxjs/toolkit";

import { billableOverrideReasonState } from "../billableOverrideReasons";
import currentTimerReducer from "../currentTimer/state";
import { holdReasonState } from "../holdReasons";
import { internalProjectState } from "../internalProjects";
import sliceReducer from "./reducers";

export { actions as timeActions } from "./slices";
export { selectors as timeSelectors } from "./slices";

const timeReducer = combineReducers({
  times: sliceReducer,
  holdReasons: holdReasonState.reducer,
  internalProjects: internalProjectState.reducer,
  billableOverrideReasons: billableOverrideReasonState.reducer,
  currentTimer: currentTimerReducer,
});
export default timeReducer;
