import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { Controller } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Project from "../../projects";
import Time from "../../time";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportTimeOnHold() {
  return (
    <JobPage
      title="Time On Hold"
      functionName="TimeOnHold"
      Options={TimeOnHoldOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState(null);
  const data = useMemo(() => job?.output?.time || [], [job]);

  const clickedBillingNote = useCallback(
    (timeId) => {
      setShowTimeId(timeId);
      setShowModifyModal(true);
    },
    [setShowTimeId, setShowModifyModal],
  );

  const columns = useMemo(
    () => [
      {
        id: "User",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Owner",
        Header: "Owner",
        accessor: "ownerId",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "CR",
        Header: "CR",
        accessor: "crId",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "date",
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Billing note",
        Header: "Billing note",
        accessor: "billingNote",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value, row }) => {
          return (
            <Time.BillingNoteLink
              timeId={row?.original?.id}
              value={value}
              setModifyShow={clickedBillingNote}
            />
          );
        },
      },
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerName",
        Filter: TextColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        id: "Invoice Project",
        Header: "Invoice Project",
        accessor: "invoiceType",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
      },
      {
        id: "Rate",
        Header: "Rate",
        accessor: "rate",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Hours",
        Header: "Hours",
        accessor: "hours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} />;
        },
      },
    ],
    [clickedBillingNote],
  );

  return (
    <>
      <Time.ModifyModal
        id={showTimeId}
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        onCancel={() => setShowModifyModal(false)}
      />
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "Customer",
              "CR",
              "Billing note",
              "Invoice Project",
              "User",
              "Date",
              "Rate",
              "Held",
              "Hours",
              "Total",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

function TimeOnHoldOptions(props) {
  return (
    <ReportOptions
      {...props}
      options={{
        owner: "",
        champion: "",
        projectType: "",
      }}
    >
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="projectType"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Project.Type.Select
                label="Type"
                id="projectType"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={error}
                errors={"Invalid Type"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="owner"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <User.Select
                id="owner"
                label="CR Owner"
                pseudo={true}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={error}
                errors={"Invalid User"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="champion"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <User.Select
                label="Champion"
                isChampion={true}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable
                isInvalid={error}
                errors={"Invalid Champion"}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}
