import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import ApprovalsIndex from "./ApprovalsIndex";

export default function ApprovalsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <ApprovalsIndex />
      </Route>
      <Route exact path={`${path}`}>
        <ApprovalsIndex />
      </Route>
    </Switch>
  );
}
