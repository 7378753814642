import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import createEntityActions from "../../common/entity/createEntityActions";
import { testStateLoading } from "../../common/functions/state";
import { api } from "./api";
import { entity } from "./entity";

const entityActions = createEntityActions({ entity, api });

const createComment = createAsyncThunk(
  "projects/comments/create",
  async ({ project, title, text }, { getState, requestId }) => {
    if (!testStateLoading(getState().projects.projects, requestId)) return;

    return await api.createComment({
      project: project,
      title,
      text,
    });
  },
);

const updateComment = createAsyncThunk(
  "projects/comments/update",
  async ({ project, id, title, text }, { getState, requestId }) => {
    if (!testStateLoading(getState().projects.projects, requestId)) return;
    return await api.updateComment({
      project: project,
      id: id,
      title,
      text,
    });
  },
);

const createAttachment = createAsyncThunk(
  "projects/attachments/create",
  async (args) => {
    return await api.createAttachment(args);
  },
);

const updateWatch = createAsyncThunk(
  "projects/watch/update",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().projects.projects, requestId)) return;

    const response = await api.updateWatch(args);
    return response;
  },
);

const setFilter = createAction("projects/setFilter");

export const actions = {
  ...entityActions,
  updateComment,
  createComment,
  createAttachment,
  setFilter,
  updateWatch,
};
