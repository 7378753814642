import { createAsyncThunk } from "@reduxjs/toolkit";

import { createEntityActions } from "../../../common/entity";
import { testStateLoading } from "../../../common/functions/state";
import { api } from "./api";
import { entity } from "./entity";

const entityActions = createEntityActions({
  entity,
  api: api,
});

export const addMember = createAsyncThunk(
  "userGroupings/addMember",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().users.userGroupings, requestId)) return;

    const response = await api.addMember({
      ...payload,
    });
    return response;
  },
);

export const deleteMember = createAsyncThunk(
  "userGroupings/deleteMember",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().users.userGroupings, requestId)) return;

    const response = await api.deleteMember({
      ...payload,
    });
    return response;
  },
);

export const actions = {
  ...entityActions,
  addMember,
  deleteMember,
};
