import { createEntityHooks } from "../../common/entity";
import customerEdiSetupState from "./state";

export const {
  useEntities: useCustomerEdiSetups,
  useEntity: useCustomerEdiSetup,
} = createEntityHooks({
  entity: customerEdiSetupState.entity,
  actions: customerEdiSetupState.actions,
});
