import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { BiDotsHorizontalRounded, BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import Watch from "../activity/watches";
import GroupOwnerFields from "../common/components/forms/GroupOwnerFields";
import {
  ErrorIcon,
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import ContextMenu from "../common/popovers/ContextMenu";
import toastPrompt from "../common/toasts/toastPrompt";
import Customer from "../customers";
import Project from "../projects";
import CrNew from "./CrNew";
import crSchema from "./crSchema";
import DefectOccurrence from "./defectOccurrences";
import Module from "./modules";
import NotificationType from "./notificationTypes";
import Status from "./statuses";
import useCr from "./useCr";

const isClosed = (statusId) => statusId === "CL" || statusId === "FX";

function CrStatusEditor({
  cr,
  project,
  update,
  isLoading,
  isDisabled,
  updateWatch,
  shouldOnlyShowEditButton = false,
  statusId,
  modalProps,
}) {
  const history = useHistory();
  const [statusPrompt, setStatusPrompt] = useState(false);

  return (
    <Form.Row>
      <Forms
        defaultValues={cr}
        schema={{ schema: crSchema }}
        context={{ project }}
        isDisabled={isLoading || isDisabled}
        onSubmit={(crVals) => {
          update(crVals).then((result) => {
            setStatusPrompt(false);
            if (isClosed(result?.statusId)) {
              if (
                project?.statusId !== "CL" &&
                !project?.crs.filter(
                  (c) => c.id !== cr.id && !isClosed(c.statusId),
                ).length
              ) {
                toastPrompt({
                  message: "Last CR closed on Project",
                  confirm: "Close Project",
                  cancel: "Ignore",
                  onConfirm: () => {
                    history.push(`/projects/${result.projectId}?status=CL`);
                  },
                });
              }
            }
          });
        }}
        shouldUnregister
      >
        <CrStatusForms
          isLoading={isLoading}
          prompt={statusPrompt}
          onShow={() => setStatusPrompt(true)}
          onHide={() => setStatusPrompt(false)}
          modalProps={modalProps}
          statusId={statusId}
        />
      </Forms>
      <Col>
        <ButtonGroup>
          <Button
            onClick={() => setStatusPrompt(true)}
            variant="light"
            title="Edit CR"
          >
            <BiEdit />
          </Button>
          {shouldOnlyShowEditButton ? null : (
            <>
              <ActionContextMenu cr={cr} update={update} />
              <Watch.Button
                label="CR"
                value={cr?.isWatching}
                onChange={(v) => updateWatch({ isWatching: v })}
              />
            </>
          )}
        </ButtonGroup>
      </Col>
    </Form.Row>
  );
}

function CrStatusForms({
  isLoading,
  prompt,
  onShow,
  onHide,
  statusId: statusIdParam,
  modalProps,
}) {
  const {
    register,
    setValue,
    watch,
    reset,
    submitForm,
    formsContext: { isDisabled },
  } = useFormContext();

  // Register inputs that need to be available when modal is hidden
  register("overrideTypeId");
  register("notificationTypeId");
  register("owner");
  register("overrideTypeId");
  register("jiraKey");
  register("isInternalCr");
  register("actualFirstContactDateTime");
  register("actualStartWorkDateTime");
  register("code_revisions");

  const [statusId, owner] = watch(["statusId", "owner"]);

  const onStatusChange = useCallback(
    (value, valueNext) => {
      setValue("statusId", valueNext, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (value !== valueNext && isClosed(valueNext)) {
        setValue("isWaitingOnCustomer", false);
        setValue("isTestingRequired", false);
        setValue("isReadyForCodeCheckin", false);
        setValue("isSAMFollowup", false);
        if (owner?.charAt(0) === "*") setValue("owner", "");
        onShow();
      }
    },
    [onShow, setValue, owner],
  );

  useEffect(() => {
    if (!isDisabled && statusIdParam && statusId !== statusIdParam) {
      onStatusChange(statusId, statusIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, statusIdParam]);

  const handleHide = () => {
    reset();
    onHide();
  };

  return (
    <Col xs={8}>
      <StatusId
        name="statusId"
        onChange={(valueNext) => {
          onStatusChange(statusId, valueNext);
          if (statusId !== valueNext && !isClosed(valueNext)) {
            submitForm();
          }
        }}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <Modal show={prompt} onHide={handleHide} {...modalProps}>
        <Modal.Header>
          <Modal.Title>Edit CR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StatusForm onHide={handleHide} />
        </Modal.Body>
      </Modal>
    </Col>
  );
}

function StatusId({ name, ...props }) {
  const { field } = useController({ name });

  return (
    <Status.Select
      id="cr-status"
      {...field}
      placeholder="Status..."
      {...props}
    />
  );
}

function StatusForm({ isDisabled, onHide }) {
  const {
    setValue,
    watch,
    submitForm,
    context: { project },
    formState: { isSubmitting, isValid, errors },
  } = useFormContext();

  const [overrideTypeId, notificationTypeId, statusId] = watch([
    "overrideTypeId",
    "notificationTypeId",
    "statusId",
  ]);
  const typeId = overrideTypeId || project?.typeId;

  useEffect(() => {
    if (!notificationTypeId && isClosed(statusId)) {
      if (typeId === "CC")
        setValue("notificationTypeId", "C", {
          shouldValidate: true,
          shouldDirty: true,
        });
      else
        setValue("notificationTypeId", "N", {
          shouldValidate: true,
          shouldDirty: true,
        });
    }
  }, [notificationTypeId, typeId, statusId, setValue]);

  useFormsKeys();

  return (
    <Form>
      <GroupOwnerFields />
      <FormsField.Select
        name="overrideTypeId"
        label="Override Type"
        as={Project.Type.Select}
        isClearable
      />
      {typeId === "DE" ? (
        <FormsField.Select
          name="defectOccurrenceId"
          label="Defect Occurrence"
          as={DefectOccurrence.Select}
          isClearable
        />
      ) : null}

      <FormsField.Select
        name="notificationTypeId"
        label="Documentation"
        as={NotificationType.Select}
      />
      <FormsField.Select
        name="moduleId"
        label="Module"
        as={Module.Select}
        isClearable
      />

      <CrWarnings project={project} />

      <Row>
        <Col>
          <ButtonGroup>
            <Button
              onClick={() => {
                if (isValid === false) console.log("CR Invalid", errors);
                submitForm();
              }}
              disabled={isSubmitting || isDisabled}
            >
              Save
            </Button>
            <Button
              variant="danger"
              onClick={onHide}
              disabled={isSubmitting || isDisabled}
            >
              Cancel
            </Button>
          </ButtonGroup>

          <Form.Text className="text-muted">
            Waiting, RFCC, and Required Testing will be cleared.
          </Form.Text>
        </Col>
        <Col xs="auto">
          <ErrorIcon className="text-danger" />
        </Col>
      </Row>
    </Form>
  );
}

function CrWarnings() {
  const {
    watch,
    context: { project },
  } = useFormContext();

  const [
    overrideTypeId,
    jiraKey,
    isInternalCr,
    actualFirstContactDateTime,
    actualStartWorkDateTime,
    code_revisions,
  ] = watch([
    "overrideTypeId",
    "jiraKey",
    "isInternalCr",
    "actualFirstContactDateTime",
    "actualStartWorkDateTime",
    "code_revisions",
  ]);
  const typeId = overrideTypeId || project?.typeId;

  const canCheckTime = project?.statusId !== "DU" && !jiraKey && !isInternalCr;
  const hasMissingFirstContact = canCheckTime && !actualFirstContactDateTime;
  const hasMissingStartWork = canCheckTime && !actualStartWorkDateTime;

  const hasMissingCommitsAsCustom = typeId === "CC" && !code_revisions?.length;
  const hasCommitsAsSupport =
    (typeId === "SU" || typeId === "QU") && code_revisions?.length;
  return (
    <Row>
      <Col>
        {hasMissingFirstContact || hasMissingStartWork ? (
          <Alert variant="warning">
            <Row>
              <Col>Missing Time Information</Col>
            </Row>

            <Row>
              <Col>
                <ul>
                  {hasMissingFirstContact ? (
                    <li>Cannot determine when customer was contacted</li>
                  ) : null}
                  {hasMissingStartWork ? (
                    <li>Cannot determine when work was started</li>
                  ) : null}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>Update time records with appropriate worklog settings</Col>
            </Row>
          </Alert>
        ) : null}
        {hasCommitsAsSupport || hasMissingCommitsAsCustom ? (
          <Alert variant="warning">
            {hasCommitsAsSupport
              ? "Support/Question CR with linked revisions"
              : hasMissingCommitsAsCustom
              ? "Custom Change CR does not include any linked revisions"
              : ""}
          </Alert>
        ) : null}
      </Col>
    </Row>
  );
}

function ActionContextMenu({ cr, update }) {
  const user = useSelector((state) => state.auth.user);
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [showMove, setShowMove] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);

  const location = useLocation();
  // Close modal on navigate (link)
  useEffect(() => {
    setShowMove(false);
  }, [location]);

  return (
    <>
      <Button
        ref={ref}
        onClick={() => setShow(true)}
        variant="light"
        title="Actions"
      >
        <BiDotsHorizontalRounded />
      </Button>

      <ContextMenu
        title={"CR Actions"}
        target={ref?.current}
        show={show}
        onHide={() => setShow(false)}
        placement="bottom"
      >
        <ListGroup onClick={() => setShow(false)}>
          <ListGroup.Item action onClick={() => update({ owner: user })}>
            Assign to me
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => setShowMove(true)}>
            Move
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => setShowDuplicate(true)}>
            Duplicate
          </ListGroup.Item>
        </ListGroup>
      </ContextMenu>
      <CrMoveModal show={showMove} onHide={() => setShowMove(false)} cr={cr} />
      <DuplicateCrModal
        show={showDuplicate}
        onHide={() => setShowDuplicate(false)}
        cr={cr}
      />
    </>
  );
}

function CrMoveModal({ cr, show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Move CR to project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CrMoveForm cr={cr} onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
}

function CrMoveForm({ cr, onHide }) {
  const [projectId, setProjectId] = useState(cr.projectId);
  const { project } = Project.useOne({ id: projectId, canGet: "auto" });
  const { getCr } = useCr.getOne();
  const { moveOne, loading } = useCr.moveOne();

  const currentCustomer = cr.customers[0].id;
  const projectCustomer = project?.customers[0].customerId;

  return (
    <>
      <Row>
        <Col>
          <Project.Select
            label="Project"
            onChange={({ id }) => setProjectId(id)}
            value={projectId}
            getCr={getCr}
          />
          <Customer.ChangedAlert
            current={currentCustomer}
            next={projectCustomer}
          />
        </Col>
      </Row>
      <ButtonGroup>
        <Button
          variant="primary"
          onClick={() =>
            moveOne({ crId: cr.id, projectId: projectId }).then(onHide)
          }
          disabled={loading}
        >
          {loading ? "Moving..." : "Move"}
        </Button>
        <Button onClick={onHide} variant="danger">
          Cancel
        </Button>
      </ButtonGroup>
    </>
  );
}

function DuplicateCrModal({ show, onHide, cr = {} }) {
  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title id="new-cr-modal">New CR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CrNew
          onNew={onHide}
          cr={{
            ...cr,
            id: undefined,
            priorityId: undefined,
            statusId: undefined,
            // projectId: undefined,
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

CrStatusEditor.Form = StatusForm;
export default CrStatusEditor;
