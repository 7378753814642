export const ESTIMATE_ACTIVE_STATUSES = {
  D: {
    id: "D",
    description: "Draft",
  },
  P: {
    id: "P",
    description: "Published",
  },
  A: {
    id: "A",
    description: "Archived",
  },
  V: {
    id: "V",
    description: "Voided",
  },
};
