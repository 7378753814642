import { createEntityHooks } from "../../common/entity";
import notificationTypeState from "./state";

export const {
  useEntities: useNotificationTypes,
  useEntity: useNotificationType,
} = createEntityHooks({
  entity: notificationTypeState.entity,
  actions: notificationTypeState.actions,
});
