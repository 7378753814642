import React from "react";
import { Col, Row } from "react-bootstrap";

export default function DetailSection({ title, children }) {
  return (
    <Row className="py-2">
      <Col>
        <Row>
          <Col>
            <h2 id={title.toLowerCase()}>{title}</h2>
          </Col>
        </Row>
        {children}
      </Col>
    </Row>
  );
}
