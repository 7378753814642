import React, { useCallback, useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";

import { useController, useFormContext } from "../common/forms";
import Customer from "../customers";
import ProjectTable from "./ProjectTable";

export default function ProjectCustomerSelect({ name, isDisabled }) {
  const {
    watch,
    setValue,
    setContext,
    context: { canBypassContractRestriction },
  } = useFormContext();

  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name });

  const [projectPrompt, setProjectPrompt] = useState({});

  const { getOne: getCustomer } = Customer.useOne();
  const { getMany: getCustomerContracts, loading: contractLoading } =
    Customer.Contracts.useAll();

  const checkContracts = useCallback(
    (customerId) =>
      getCustomerContracts({
        customerId: customerId,
      }).then(({ data: contracts } = {}) => {
        if (contracts?.length) {
          const warningContracts = contracts?.filter(
            (c) => !c.inactive && c.projectCreateWarning,
          );

          const projects = warningContracts?.map((c) =>
            c?.projects.map((p) => p),
          )[0];

          setProjectPrompt((prompt) =>
            prompt.message
              ? { ...prompt, projects }
              : warningContracts?.length
              ? { message: "Warning: Customer contracts exist", projects }
              : {},
          );
        }
      }),
    [getCustomerContracts],
  );

  const priorityId = watch("priorityId");

  const onProjectCustomerChange = useCallback(
    (customerId) => {
      getCustomer({
        id: customerId,
        fetch: true,
      }).then(({ data: customer } = {}) => {
        if (!priorityId || priorityId === "RG" || priorityId === "PM")
          customer?.supportPlan === "P"
            ? setValue("priorityId", "PM")
            : setValue("priorityId", "RG");
        setContext((c) => ({ ...c, [name]: customer }));
        if (!customer) return;

        const { contractOnly = "N" } = customer;
        if (contractOnly === "Y") {
          if (canBypassContractRestriction !== true) {
            setProjectPrompt({
              message: "Customer is contract only, select an existing project",
            });
          } else {
            setProjectPrompt({
              message:
                "Customer is contract only, but you have security to bypass this",
            });
          }
        }
        checkContracts(customerId);
        setValue(name, customerId, { shouldValidate: true, shouldDirty: true });
      });
    },
    [
      canBypassContractRestriction,
      checkContracts,
      getCustomer,
      name,
      priorityId,
      setContext,
      setValue,
    ],
  );

  return (
    <Form.Group>
      <Customer.Select
        name={name}
        onChange={onProjectCustomerChange}
        isDisabled={isDisabled}
        isInvalid={!!error}
        errors={error?.message}
        {...field}
      />
      <Modal
        show={!!projectPrompt?.message}
        onHide={() => setProjectPrompt({})}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Customer Projects
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={error ? "danger" : "warning"}>
            {projectPrompt.message}
          </Alert>
          <ProjectTable
            projects={projectPrompt.projects || []}
            isLoading={contractLoading}
            layout={["id", "date", "group", "owner", "status", "synopsis"]}
          />
        </Modal.Body>
      </Modal>
    </Form.Group>
  );
}
