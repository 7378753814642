import { isUndefined, map, sortBy, stubObject, times } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { BiLinkAlt } from "react-icons/bi";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Prompt } from "react-router";
import TextareaAutosize from "react-textarea-autosize";
import { object, string } from "yup";

import useAppFormsState from "../../app/useAppFormsState";
import User from "../../users";
import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsAutoSubmit,
} from "../forms";
import { _datetime } from "../functions/dates";
import useTinykeys from "../hooks/useTinykeys";

const INITIAL_COMMENT = {
  text: "",
  title: "",
};

export default function Comments({
  comments,
  isLoading = false,
  isDisabled = false,
  create,
  id,
  update,
}) {
  const [isReversed, setIsReversed] = useState(true);

  if (isUndefined(comments) && !isLoading) return null;
  const isLoadingAndInvalid = isUndefined(comments) && isLoading;

  const _comments = isLoadingAndInvalid
    ? times(3, stubObject)
    : sortBy(comments, (c) =>
        isReversed ? 9999 - parseInt(c.id) : parseInt(c.id),
      );

  return (
    <>
      {create ? (
        <CreateComment
          isLoading={isLoadingAndInvalid}
          create={create}
          id={id ? `${id}-new` : undefined}
          isDisabled={isDisabled}
        />
      ) : null}
      <Row>
        <Col>
          <Button
            size="sm"
            variant="link"
            title="Click to toggle sort"
            onClick={() => setIsReversed(!isReversed)}
          >
            {isReversed ? (
              <>
                {"Newest first "}
                <FaSortDown />
              </>
            ) : (
              <>
                {"Oldest first "}
                <FaSortUp />{" "}
              </>
            )}
          </Button>
        </Col>
      </Row>
      {map(_comments, (c, i) => (
        <Comment
          key={`comment${i}`}
          comment={c}
          isLoading={isLoadingAndInvalid}
          isDisabled={isDisabled}
          update={update}
        />
      ))}
    </>
  );
}

function Comment({ comment, isLoading, update, isDisabled }) {
  const [isEdit, setIsEdit] = useState(false);

  if (isUndefined(comment)) return null;

  let onEdit = null;
  if (update && !isDisabled) {
    onEdit = () => setIsEdit(true);
    if (isEdit)
      return (
        <EditComment
          comment={comment}
          isLoading={isLoading}
          onSubmit={(values) => {
            update({ ...values, id: comment.id });
            setIsEdit(false);
          }}
          onCancel={() => setIsEdit(false)}
        />
      );
  }

  return (
    <ShowComment
      comment={comment}
      isLoading={isLoading}
      onEdit={onEdit}
      isDisabled={isDisabled}
    />
  );
}

function ShowComment({ comment, isLoading, onEdit, isDisabled }) {
  const { title, author, createDateTime, text, updateDateTime, updateUser } =
    comment;
  const createString = _datetime.displayDateTime(
    _datetime.fromStamp(createDateTime),
  );
  const editString = _datetime.displayDateTime(
    _datetime.fromStamp(updateDateTime),
  );

  const id = `comment-${comment?.id}`;

  return (
    <div className="border-top" id={id}>
      <Row className="pt-0">
        <Col xs="auto">
          <small className="text-muted ">
            {!isLoading ? <User.Link id={author} /> : <Skeleton />}
            {" " + createString + " "}
            <a href={`#${id}`} title="Link to comment">
              <BiLinkAlt />
            </a>
          </small>
        </Col>
        <Col>
          {editString && !isLoading ? (
            <small className="text-muted font-italic">
              Edited by <User.Link id={updateUser} /> {editString}
            </small>
          ) : null}
          {!isLoading && !isDisabled && onEdit ? (
            <Button
              variant="link"
              className="text-muted float-right"
              size="sm"
              onClick={onEdit}
            >
              Edit
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="mt-0">{!isLoading ? title : <Skeleton />}</h6>
        </Col>
      </Row>
      <Row className="pt-0">
        <Col>
          <p className="text-break" style={{ whiteSpace: "pre-wrap" }}>
            {!isLoading ? text : <Skeleton count={6} />}
          </p>
        </Col>
      </Row>
    </div>
  );
}

function CreateComment({ isLoading = false, create, id, isDisabled }) {
  const [focus, setFocus] = useState(false);
  const {
    state: savedComment,
    initial: savedCommentInitial,
    setState: setSavedComment,
    loading: formsLoading,
  } = useAppFormsState({
    key: id,
  });
  const user = useSelector((state) => state.auth.user);

  const shouldWarnOnLeave = !!(savedComment?.title || savedComment?.text);

  const comment = useMemo(
    () => ({ ...INITIAL_COMMENT, ...savedCommentInitial, author: user }),
    [savedCommentInitial, user],
  );

  return (
    <>
      <Prompt
        when={shouldWarnOnLeave}
        message="New comment may not be saved, are you sure you want to leave?"
      />

      {focus ? (
        <EditComment
          comment={comment}
          isLoading={isLoading || formsLoading}
          focus={focus}
          onSubmit={async ({ title, text, ...values }) => {
            setFocus(false);
            if (title || text)
              return create({ title, text, ...values }).then(() => {
                setSavedComment(null, true);
              });
          }}
          onCancel={() => {
            setFocus(false);
          }}
          onChange={(values) => {
            setSavedComment(values?.text || values?.title ? values : null);
          }}
          isNew
        />
      ) : (
        <Form className="border-top">
          <Row className="pt-0 text-muted">
            <Col>
              <small>
                {!isLoading ? <User.Link id={user} /> : <Skeleton />}
              </small>
            </Col>
          </Row>
          <Row className="pt-0">
            <Col>
              <Form.Group controlId="CreateComment.TextReactive">
                <Form.Control
                  type="text"
                  placeholder="Add a comment..."
                  onFocus={() => {
                    if (!(isDisabled || isLoading)) {
                      setFocus(true);
                      setSavedComment(savedComment, true);
                    }
                  }}
                  disabled={isLoading || isDisabled}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

const commentSchema = object().shape({
  title: string().label("Title").max(100),
});

function EditComment({
  comment,
  isDisabled = false,
  isLoading = false,
  focus = true,
  onSubmit,
  onCancel,
  onChange,
  isNew = false,
}) {
  return (
    <Forms
      onSubmit={onSubmit}
      defaultValues={{
        ...INITIAL_COMMENT,
        ...comment,
      }}
      isDisabled={isDisabled}
      schema={{ schema: commentSchema }}
    >
      <EditCommentForms
        isLoading={isLoading}
        focus={focus}
        onChange={onChange}
        onCancel={onCancel}
        isNew={isNew}
      />
    </Forms>
  );
}

function EditCommentForms({ focus, onCancel, onChange, isNew, isLoading }) {
  const {
    reset,
    watch,
    submitForm,
    setFocus,
    formsContext: { isDisabled },
  } = useFormContext();

  const values = watch();

  useFormsAutoSubmit({
    blocked: !onChange,
    onSubmit: (values) => {
      if (!isDisabled && !isLoading) onChange(values);
    },
  });

  const [textFocus, setTextFocus] = useState(false);

  useTinykeys({
    Escape: () => {
      if (textFocus && onCancel) onCancel(values);
    },
    "$mod+Enter": () => {
      if (textFocus) submitForm();
    },
  });

  useEffect(() => {
    if (!focus) return;
    setFocus("text");
  }, [setFocus, focus]);

  return (
    <Form className="border-top" onSubmit={submitForm}>
      <Row className="pt-0 text-muted">
        <Col>
          <small>
            {!isLoading ? <User.Link id={values?.author} /> : <Skeleton />}
          </small>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="EditComment.Title">
            <FormsField
              name="title"
              placeholder="Title..."
              readOnly={isLoading}
              onBlur={() => setTextFocus(false)}
              onFocus={() => setTextFocus(true)}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="EditComment.Text">
            <CommentTextField
              name="text"
              placeholder="Comment..."
              minRows={6}
              setFocus={setTextFocus}
              readOnly={isLoading}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>
          <ButtonGroup aria-label="Create Comment Submit">
            <Button variant="primary" type="submit" disabled={isDisabled}>
              Submit
            </Button>
            <Button
              variant="secondary"
              onClick={() => onCancel(values)}
              disabled={isDisabled}
            >
              Cancel
            </Button>
            <Button
              variant="warning"
              onClick={() => {
                const onReset = (values) => {
                  reset(values);
                  onChange && onChange(null);
                };
                isNew
                  ? onReset({ ...INITIAL_COMMENT, author: values?.author })
                  : onReset();
              }}
              disabled={isDisabled}
            >
              Reset
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  );
}

function CommentTextField({ name, id = name, setFocus, ...props }) {
  const {
    field: { onBlur, ...field },
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled = false },
  } = useFormContext();

  return (
    <Form.Group controlId={id}>
      <TextareaAutosize
        {...field}
        onBlur={(e) => {
          onBlur(e);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        className="form-control"
        readOnly={isDisabled}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
