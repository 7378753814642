import shark from "../../apis/shark";

function getContactsAPI({ customerId }) {
  const payload = {
    command: "getCustomerContacts",
    customer: customerId,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.customerContacts,
    searchArgs: { customerId },
  }));
}

function getContactAPI({ id }) {
  const payload = {
    command: "getCustomerContact",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

export const api = { getMany: getContactsAPI, getOne: getContactAPI };
