import { entitySelect } from "../../common/entity";
import { useModules } from "./hooks";

const ModuleSelect = entitySelect({
  name: "module",
  useEntities: useModules,
  getHookArgs: () => ({
    canGet: true,
  }),
});

export default ModuleSelect;
