import React from "react";
import { Link } from "react-router-dom";

import { useTaxProduct } from "./hooks";

export default function TaxProductLink({ id, children }) {
  const { taxProduct } = useTaxProduct({ id });

  if (!taxProduct?.id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Link
        className="text-reset"
        to={{ pathname: `/invoices/tax-products/${id}` }}
      >
        {children || taxProduct?.description}
      </Link>
    </span>
  );
}
