import { useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";

export default function useReinitState(args) {
  const argsRef = useRef(args);
  const [state, setState] = useState(args);
  useEffect(() => {
    if (!isEqual(argsRef.current, args)) {
      argsRef.current = args;
      setState(args);
    }
  }, [args]);
  return [state, setState];
}
