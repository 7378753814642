import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import HelixLink from "../../common/components/HelixLink";
import { _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import Cr from "../../crs";
import User from "../../users";
import CrPage from "./CrPage";
import CrShowNavSidebar from "./CrShowNavSidebar";

export default function CrShowRevisions() {
  const id = parseInt(useParams().id);
  const { cr, loading } = Cr.useOne({ id, logActivity: true });

  const data = cr?.code_revisions.reduce((acc, r) => {
    const recs = r?.files.map((rf) => {
      return {
        revision: r.revision,
        type: r.type,
        comment: r.comment,
        authorId: r.authorId,
        filename: rf.filename,
        datetime: r.datetime,
      };
    });
    return acc.concat(recs);
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "revision",
        Header: "Revisions",
        accessor: "revision",
        Cell: ({ value, row }) => (
          <HelixLink revisionType={row?.original?.type} revision={value} />
        ),
      },
      {
        id: "author",
        Header: "Author",
        accessor: "authorId",

        Cell: ({ value }) => (
          <User.Link id={value?.toUpperCase()} showId={true} />
        ),
      },
      {
        id: "date",
        Header: "Date",
        accessor: "datetime",

        Cell: ({ value }) => {
          if (!value) return "";
          return (
            <span>{_datetime.displayDateTime(_datetime.fromStamp(value))}</span>
          );
        },
      },
      {
        id: "comment",
        Header: "Comment",
        accessor: "comment",
      },
      {
        id: "filename",
        Header: "File",
        accessor: "filename",
      },
    ],
    [],
  );
  return (
    <CrPage
      cr={cr}
      heading={`CR ${id} Revisions`}
      navSidebar={<CrShowNavSidebar id={id} />}
      isLoading={loading}
    >
      <Row>
        <Col>
          <Table
            hasCount
            label="Changes"
            data={data}
            columns={columns}
            isLoading={loading}
          />
        </Col>
      </Row>
    </CrPage>
  );
}
