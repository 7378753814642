import React, { useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _date, _datetime } from "../common/functions/dates";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Project from "../projects";
import User from "../users";
import CrLink from "./CrLink";
import Difficulty from "./difficulties";
import Module from "./modules";
import NotificationType from "./notificationTypes";
import QaStatus from "./qaStatuses";
import Status from "./statuses";

const DEFAULT_COMPONENTS = {
  CrLink: CrLink,
};

const defaultLayout = [
  "id",
  "project",
  "createDate",
  "customer",
  "owner",
  "status",
  "synopsis",
  "waiting",
  "priority",
  "group",
];

export default function useCrTableColumns({
  selectRow = false,
  Components: OverrideComponents,
} = {}) {
  const Components = useMemo(
    () => ({
      ...DEFAULT_COMPONENTS,
      ...OverrideComponents,
    }),
    [OverrideComponents],
  );

  const columns = useMemo(
    () => [
      {
        Header: "CR",
        id: "id",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ row: { original: cr }, value }) =>
          selectRow ? value : <Components.CrLink id={cr?.id} />,
      },
      {
        Header: "Project",
        id: "project",
        accessor: "projectId",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: ({ value }) =>
          selectRow ? (
            value
          ) : (
            <Link
              className="text-reset"
              to={{ pathname: `/projects/${value}` }}
            >
              {value}
            </Link>
          ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Create Date",
        id: "createDate",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value?.substring(0, 8)))}</span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Create Time",
        id: "createTime",
        accessor: "createDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>{_datetime.displayTime(_datetime.fromStamp(value))}</span>
        ),
      },
      {
        Header: "Modify Date",
        id: "modifyDate",
        accessor: "lastUpdateDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value
              ? _date.display(_date.fromStamp(value?.substring(0, 8)))
              : null}
          </span>
        ),
        aggregate: "uniqueCount",
      },
      {
        Header: "Modify Time",
        id: "modifyTime",
        accessor: "lastUpdateDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => (
          <span>
            {value ? _datetime.displayTime(_datetime.fromStamp(value)) : null}
          </span>
        ),
      },
      {
        Header: "Closed Date",
        id: "closedDate",
        accessor: "closedDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return (
              <span>
                {_date.display(_date.fromStamp(value?.substring(0, 8)))}
              </span>
            );
          }
        },
      },
      {
        Header: "Closed Time",
        id: "closedTime",
        accessor: "closedDateTime",
        disableFilters: true,
        sortType: "custom",

        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return (
              <span>{_datetime.displayTime(_datetime.fromStamp(value))}</span>
            );
          }
        },
      },
      {
        Header: "Target Delivery Date",
        id: "targetDeliveryDate",
        disableFilters: true,
        accessor: "targetDeliveryDate",
        sortType: "custom",

        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return <span>{_date.display(_date.fromStamp(value))}</span>;
          }
        },
      },
      {
        Header: "Target First Contact",
        id: "targetFirstContact",
        disableFilters: true,
        accessor: "targetFirstContactDateTime",
        sortType: "custom",

        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return (
              <span>
                {_datetime.displayDateTime(_datetime.fromStamp(value))}
              </span>
            );
          }
        },
      },
      {
        Header: "Target Start Work",
        id: "targetStartWork",
        disableFilters: true,
        accessor: "targetStartWorkDateTime",
        sortType: "custom",

        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return (
              <span>
                {_datetime.displayDateTime(_datetime.fromStamp(value))}
              </span>
            );
          }
        },
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: ({ customers = [] }) =>
          customers
            ? customers.length > 1
              ? "Multiple"
              : customers[0]?.name || ""
            : "",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "champion",
        Header: "Champion",
        accessor: (cr) => {
          let championId = "";
          for (let c = 0; c < cr?.customers?.length; c++) {
            if (championId === "" || championId === cr.customers[c].championId)
              championId = cr.customers[c].championId;
            else championId = "Multiple";
          }
          return championId;
        },

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        Header: "Owner",
        id: "owner",
        accessor: "owner",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Created By",
        id: "createdBy",
        accessor: "createUserId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Modified By",
        id: "modifiedBy",
        accessor: "lastUpdateUserId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Closed By",
        id: "closedBy",
        accessor: "closedUserId",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Status",
        id: "status",
        accessor: "statusId",
        Filter: Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        sortInverted: true,

        Cell: ({ value }) => <Status.Description id={value} />,
      },
      {
        Header: "Priority",
        id: "priority",
        accessor: "projectPriorityId",
        Filter: Project.Priority.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <Project.Priority.Description id={value} />,
      },
      {
        Header: "Estimate Status",
        id: "estimateStatus",
        accessor: "projectEstimateStatus",
        Filter: Project.EstimateStatus.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <Project.EstimateStatus.Description id={value} />,
      },
      {
        Header: "Group",
        id: "group",
        accessor: "groupId",
        Filter: User.Group.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Documentation",
        id: "documentation",
        accessor: "notificationTypeId",
        Filter: NotificationType.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <NotificationType.Description id={value} />,
      },
      {
        Header: "Difficulty",
        id: "difficulty",
        accessor: "difficultyId",
        Filter: Difficulty.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <Difficulty.Description id={value} />,
      },
      {
        Header: "Module",
        id: "module",
        accessor: "moduleId",
        Filter: Module.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Module.Description id={value} />,
      },
      {
        Header: "Type",
        id: "type",
        Filter: Project.Type.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        accessor: ({ overrideTypeId, projectTypeId }) =>
          overrideTypeId !== "" ? overrideTypeId : projectTypeId,
        Cell: ({ value }) => <Project.Type.Description id={value} />,
      },
      {
        Header: "Synopsis",
        id: "synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Fix Notes",
        id: "projectFixNotes",
        accessor: "projectFixNotes",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Waiting",
        id: "waiting",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isWaitingOnCustomer }) =>
          isWaitingOnCustomer ? "⏳" : " ",
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "RFCC",
        id: "rfcc",
        accessor: ({ isReadyForCodeCheckin }) =>
          isReadyForCodeCheckin ? "Y" : " ",
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "SAM Follow-Up?",
        id: "isSAMFollowup",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ isSAMFollowup }) => (isSAMFollowup ? "🆘" : " "),
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "Status*",
        id: "statusExtended",
        accessor: "statusId",
        Filter: Status.TableFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        sortInverted: true,

        Cell: ({ value, row: { original: cr } }) => (
          <Form.Row>
            <Col>
              <Status.Description id={value} />
            </Col>
            <Col>
              {cr?.isWaitingOnCustomer ? "⏳" : ""}
              {cr?.isSAMFollowup ? "🆘" : ""}
              {QaStatus.entities?.[cr?.qaStatusId]?.emojii || ""}
            </Col>
          </Form.Row>
        ),
      },
      {
        Header: "QA Status",
        id: "qaStatus",
        format: { alignment: { horizontal: "center" } },
        accessor: ({ qaStatusId }) =>
          QaStatus.entities?.[qaStatusId]?.emojii || "",
        Filter: SelectColumnFilter,
        filter: "text",
        aggregate: "uniqueCount",
      },
      {
        Header: "Reviewer",
        id: "reviewer",
        accessor: "qaOwner",
        aggregate: "uniqueCount",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Jira",
        id: "jira",
        accessor: "jiraKey",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: cr } }) => {
          let href = null;
          let notesArray = [];
          notesArray = cr?.jiraNotes.split(/\r\n|\r|\n/);

          if (notesArray?.length > 0) {
            for (let i = 0; i < notesArray.length; i++) {
              if (notesArray[i].startsWith("https")) {
                href = notesArray[i];
                break;
              }
            }
          }

          if (href === null) {
            return <>{value}</>;
          }
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        },
      },
    ],
    [selectRow, Components],
  );

  return { columns, defaultLayout };
}
