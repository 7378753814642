const CR_DEFECT_OCCURENCES = {
  N: {
    id: "N",
    description: "New bug (first occurrence)",
  },
  H: {
    id: "H",
    description: "Already fixed on Hera",
  },
  C: {
    id: "C",
    description: "Already fixed at another customer",
  },
  O: {
    id: "O",
    description: "Only this customer",
  },
  X: {
    id: "X",
    description: "N/A: No code change",
  },
};

export function useDefectOccurrence({ id }) {
  return CR_DEFECT_OCCURENCES[id];
}

export function useDefectOccurrences() {
  const crDefectOccurrences = Object.entries(CR_DEFECT_OCCURENCES).map(
    ([, value]) => value,
  );
  return { crDefectOccurrences };
}
