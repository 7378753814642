import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";

import Filter from "./filters";
import TableLayout from "./tableLayouts";
import useCrTableSettings from "./useCrTableSettings";

const defaultFilter = {
  public: false,
  filter: {
    folderId: "CURRENT",
  },
  sortBy: [],
};

export default function CrTableSettingsEditor({
  values: { filter: crFilterInitial, layout: crLayoutInitial } = {},
  onSubmit,
}) {
  const [crFilter, setCrFilter] = useState({
    ...defaultFilter,
    ...crFilterInitial,
  });
  const [crTableLayout, setCrTableLayout] = useState({
    ...crLayoutInitial,
  });
  const [filterId, setFilterId] = useState(null);
  const [layoutId, setLayoutId] = useState();

  const loggedInUser = useSelector((state) => state.auth.user);

  const {
    filter: loadedCrFilter,
    layout: loadedCrTableLayout,
    defaultCrTableLayout,
    loading,
  } = useCrTableSettings({
    crTableLayoutId: layoutId,
    crFilterId: filterId,
    canGet: true,
  });

  const {
    createOne: createCrFilter,
    updateOne: updateCrFilter,
    deleteOne: deleteCrFilter,
  } = Filter.useOne({
    id: crFilter?.id,
  });

  const { createOne: createCrTableLayout, updateOne: updateCrTableLayout } =
    TableLayout.useOne({
      id: crTableLayout?.id,
    });

  useEffect(() => {
    if (loadedCrFilter) setCrFilter({ ...loadedCrFilter });
  }, [loadedCrFilter]);

  useEffect(() => {
    if (loadedCrTableLayout && !_.isNull(layoutId)) {
      setCrTableLayout({ ...loadedCrTableLayout });
    }
  }, [loadedCrTableLayout, layoutId]);

  function handleSearch({ filter, layout }) {
    let adjFilter = { ...filter.filter };
    // Remove null values
    Object.entries(adjFilter).forEach(([key, value]) => {
      if (_.isNull(value)) delete adjFilter[key];
    });
    onSubmit({
      filter: { ...filter, filter: adjFilter },
      layout,
    });
    setFilterId(null);
    setLayoutId(null);
  }

  return (
    <Tabs defaultActiveKey="filter">
      <Tab eventKey="filter" title="Filter">
        <Row className="justify-content-between align-items-end pt-2">
          <Col>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch({ filter: crFilter, layout: crTableLayout });
              }}
            >
              <Filter.Select
                label="Saved Filter"
                value={crFilter?.id}
                onChange={(value) => {
                  setFilterId(value);
                  if (!value) setCrFilter(({ id, ...f }) => ({ ...f }));
                }}
                formGroupClassName="mb-0"
                isClearable
              />
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <Filter.Editor
              crFilter={crFilter}
              isLoading={loading}
              onSearch={(crFilterNew, { isDirty }) =>
                handleSearch({
                  filter: {
                    ...crFilterNew,
                    hasChanges: crFilter?.hasChanges || isDirty,
                    // Clear ID if changed but not saved
                    id:
                      crFilter?.id !== crFilterNew?.id
                        ? undefined
                        : crFilterNew?.id,
                  },
                  layout: crTableLayout,
                })
              }
              onSubmit={(
                { formSubmitType, formSubmitDirty, ...crFilterNew },
                { isNew },
              ) => {
                setCrFilter({ ...crFilterNew, hasChanges: false });
                return isNew
                  ? createCrFilter({ ...crFilterNew, owner: loggedInUser })
                  : updateCrFilter(crFilterNew);
              }}
              onDelete={() =>
                deleteCrFilter().then(() =>
                  setCrFilter(({ id, ...f }) => ({ ...f })),
                )
              }
            />
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="layout" title="Layout">
        <Row className="pt-2 align-items-end">
          <Col>
            <TableLayout.Select
              formGroupClassName="pb-0 mb-0"
              label="Saved Layout"
              value={crTableLayout?.id}
              onChange={(value) => {
                if (!value) {
                  setCrTableLayout(({ id, ...l }) => ({ ...l }));
                  setLayoutId(null);
                } else {
                  setLayoutId(value);
                }
              }}
              isClearable
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TableLayout.Editor
              crTableLayout={crTableLayout}
              defaultLayout={defaultCrTableLayout}
              onSubmit={(crTableLayoutNew, { isNew }) => {
                setCrTableLayout(crTableLayoutNew);
                return isNew
                  ? createCrTableLayout(crTableLayoutNew)
                  : updateCrTableLayout(crTableLayoutNew);
              }}
              onSearch={(crTableLayoutNew) =>
                handleSearch({
                  filter: crFilter,
                  layout: crTableLayoutNew,
                })
              }
            />
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );
}
