import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import TimeModifyModal from "../time/TimeModifyModal";

function ApprovalsStatement({ data, loading, project = "" }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState();
  const [, setTimeRecordsChanged] = useState(false);

  /*
  const { approvals, loading, reset } = useApprovals({
    customer: paramsCustomer,
    projectCategory: "O",
  });
  */

  const popupTimeModal = (timeId) => {
    setShowModifyModal(true);
    setShowTimeId(timeId);
  };

  const handleChange = () => {
    setShowModifyModal(false);
    setTimeRecordsChanged(true);
  };

  let activeLines = [];

  let foundMode = false;
  let currentSectionPtr = -1;
  if (project !== "" && project !== null)
    for (let i = 0; i < data?.length; i++) {
      if (data[i].type === "section") {
        if (foundMode === true) {
          foundMode = false;
        }
        currentSectionPtr = i;
      } else if (data[i].type === "project") {
        if (project === data[i].project) {
          foundMode = true;
          activeLines.push(data[currentSectionPtr]);
        } else {
          foundMode = false;
        }
      }
      if (foundMode) activeLines.push(data[i]);
    }
  else activeLines = data;

  return (
    <>
      <TimeModifyModal
        id={showTimeId}
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        onSave={handleChange}
        onDelete={handleChange}
        onCancel={() => setShowModifyModal(false)}
      />
      <Row>
        <Col xs={6} className="font-weight-bold">
          Description
        </Col>
        <Col xs={2} className="text-right font-weight-bold">
          Quantity
        </Col>
        <Col xs={2} className="text-right font-weight-bold">
          Price
        </Col>
        <Col xs={2} className="text-right font-weight-bold">
          Total
        </Col>
      </Row>
      {loading ? (
        <h3>loading</h3>
      ) : (
        activeLines?.map((x, idx) => (
          <Line data={x} key={`line${idx}`} onSelect={popupTimeModal} />
        ))
      )}
    </>
  );
}

function Line({ data: x, onSelect }) {
  switch (x.type) {
    case "section":
      return (
        <Row className="bg-dark text-light text-center">
          <Col>{x.description}</Col>
        </Row>
      );
    case "project":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left pl-1">
              {"Project "}
              <Link
                className="text-decoration-none"
                to={{ pathname: `/projects/${x.project}` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x.project}
              </Link>
            </Col>
            <Col className="text-center">{x.contact}</Col>
            <Col className="text-right">{x.cspo}</Col>
          </Row>
          <Row className="table-secondary">
            <Col className="text-left pl-1">{x.synopsis}</Col>
          </Row>
          {x.fixNotes !== "" ? (
            <Row className="table-secondary">
              <Col className="text-left font-weight-bold text-success pl-1">
                {"SOLUTION: " + x.fixNotes}
              </Col>
            </Row>
          ) : null}
        </>
      );
    case "travel":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left">{"TRAVEL"}</Col>
          </Row>
        </>
      );
    case "cr":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left">
              {"CR "}
              <Link
                className="text-decoration-none"
                to={{ pathname: `/crs/${x.cr}` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x.cr}
              </Link>
            </Col>
          </Row>
          <Row className="table-secondary">
            <Col className="text-left">{x.synopsis}</Col>
          </Row>
        </>
      );
    case "timeLine":
      // eslint-disable-next-line no-case-declarations
      const rowClassName = x.total === "<HIDDEN>" ? "text-danger" : "";

      return (
        <Row className={rowClassName}>
          <Col xs={6} className="">
            <Row className="justify-content-between">
              <Col>{x.description}</Col>
              <Col>{x.dateRange}</Col>
            </Row>
          </Col>
          <Col xs={2} className="text-right">
            {x.quantity}
          </Col>
          <Col xs={2} className="text-right">
            {x.price}
          </Col>
          <Col xs={2} className="text-right">
            {x.total}
          </Col>
        </Row>
      );
    case "timeDetail":
    case "discount":
      // eslint-disable-next-line no-case-declarations
      const rowClassName2 = x.total === "<HIDDEN>" ? "text-danger" : "";
      // eslint-disable-next-line no-case-declarations
      const rowClassName3 = x.type === "timeDetail" ? "font-italic" : "";
      return (
        <Row className={"pb-2 " + rowClassName2 + " " + rowClassName3}>
          <Col xs={6} className="pl-4">
            {x.type === "timeDetail" ? (
              <Link
                className={"text-decoration-none " + rowClassName2}
                to={{ pathname: `/time/${x.timeId}/edit` }}
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(x.timeId);
                }}
              >
                {x.description}
              </Link>
            ) : (
              x.description
            )}
          </Col>
          <Col xs={2} className="text-right">
            {x.quantity}
          </Col>
          <Col xs={2} className="text-right">
            {x.price}
          </Col>
          <Col xs={2} className="text-right">
            {x.total}
          </Col>
        </Row>
      );
    case "sectionTotal":
    case "sectionTotalTax":
      return (
        <Row className={"pb-2 font-weight-bold"}>
          <Col xs={6}>{x.description}</Col>
          <Col xs={2} className="text-right"></Col>
          <Col xs={2} className="text-right"></Col>
          <Col xs={2} className="text-right">
            {x.total}
          </Col>
        </Row>
      );
    case "sectionTotalPreviousBilled":
    case "sectionTotalEstimate":
      return (
        <Row className={"pb-2 font-weight-bold text-danger"}>
          <Col xs={12}>{x.description}</Col>
        </Row>
      );
    default:
  }
  return <></>;
}
export default React.memo(ApprovalsStatement);
