import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Page from "../../../common/pages/Page";
import User from "../../../users";
import UserGroupingNavSidebar from "./UserGroupingNavSidebar";

export default function UserGroupingIndex() {
  const [active, setActive] = useState(true);
  const { entities, loading, reset } = User.UserGrouping.useAll({
    canGet: true,
  });
  const data = useMemo(
    () => entities.filter((e) => e.isInactive === !active),
    [active, entities],
  );
  return (
    <Page title="User Groupings" navSidebar={<UserGroupingNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <User.UserGrouping.Table
        userGroupings={data}
        initialSort={{ id: "name" }}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
