import React from "react";

import NavSidebarItem from "../../common/navs/NavSidebarItem";
import ProjectNavSidebar from "./ProjectNavSidebar";

export default function ProjectShowNavSidebar({ id }) {
  return (
    <ProjectNavSidebar>
      <NavSidebarItem
        className="font-weight-bold"
        to={`/projects/${id}`}
      >{`Project ${id}`}</NavSidebarItem>
      <NavSidebarItem to={`/projects/${id}/edit`}>Edit</NavSidebarItem>
      <NavSidebarItem to={`/projects/${id}/time`}>Time</NavSidebarItem>
      <NavSidebarItem to={`/projects/${id}/log`}>Log</NavSidebarItem>
    </ProjectNavSidebar>
  );
}
