import React, { useMemo } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";

import {
  Forms,
  FormsField,
  useController,
  useFormContext,
} from "../../common/forms";
import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import Group from "../groups";
import UserDescription from "../UserDescription";
import UserLink from "../UserLink";
import UserSelect from "../UserSelect";
import notificationGroupSchema from "./notificationGroupSchema";
import notificationGroupState from "./state";

export default function NotificationGroupEditor({
  values = {},
  onSubmit,
  isNew = false,
  isLoading,
}) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: notificationGroupSchema }}
        showDevTool
      >
        <NotificationGroupForms isNew={isNew} isLoading={isLoading} />
      </Forms>
      {!isNew ? (
        <Form.Row>
          <Col>
            <Form.Row>
              <Col className={"mt-3"}>
                <h3>Members</h3>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <MemberTable id={values?.id} values={values?.members} />
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
      ) : null}
    </>
  );
}

function NotificationGroupForms({ isNew }) {
  const { submitForm } = useFormContext();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="inactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField
                name="description"
                label="Description"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <FormsField.Select
                name="groupId"
                label="Group"
                as={Group.Select}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="auto" style={{ minWidth: "30rem" }}>
              <PseudoUser name="pseudoUserId" label="Psuedo User" />
            </Col>
          </Form.Row>
        </Col>
        <Col>
          <Form.Row>
            <Col>
              <h3>Settings</h3>
            </Col>
          </Form.Row>
          <NotificationSelect
            name="newProjectSetting"
            label="Notify on new issue"
          />
          <NotificationSelect
            name="leaveTeamSetting"
            label="Notify when issue leaves team to another"
          />
          <NotificationSelect
            name="assignedSetting"
            label="Notify when issues are assigned"
          />
          <NotificationSelect
            name="priorityChangedSetting"
            label="Notify when priority changes"
          />
          <Form.Row>
            <Col xs="auto">
              <Form.Group id="qaStatusChangedSetting">
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {"Notify when QA status changes"}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormsField.Control as="select" name="qaStatusChangedSetting">
                    <option value="N">No</option>
                    <option value="Y">Yes</option>
                    <option value="F">Failed Only</option>
                  </FormsField.Control>
                </InputGroup>
              </Form.Group>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Button variant="success" onClick={submitForm}>
            {isNew ? "Create Group" : "Save Group"}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

function PseudoUser({ name, ...props }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name });
  const {
    formsContext: { isDisabled },
  } = useFormContext();

  return (
    <Form.Row>
      <Col style={{ minWidth: "400px" }}>
        <UserSelect
          pseudo={true}
          realUser={false}
          {...field}
          isInvalid={!!error}
          errors={error?.message}
          isDisabled={isDisabled}
          {...props}
        />
      </Col>
    </Form.Row>
  );
}

function MemberTable({ id, values, isLoading }) {
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        id: "type",
        Header: "Type",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: (t) => {
          switch (t.type) {
            case "U":
              return "User";
            case "E":
              return "Email";
            default:
              return "Error: " + t.type;
          }
        },
      },
      {
        id: "key",
        Header: "Member",
        accessor: "key",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value, row: { original: member } }) =>
          member.type === "U" ? (
            <>
              <UserLink id={value} showId={true} />{" "}
              <UserDescription id={value} />
            </>
          ) : (
            value
          ),
      },
      {
        id: "edit",
        Header: "",
        accessor: "key",
        disableSortBy: true,
        Cell: ({ row: { original: member } }) => {
          return (
            <Button
              onClick={() =>
                dispatch(
                  notificationGroupState.actions.deleteMember({
                    notificationGroupId: id,
                    ...member,
                  }),
                )
              }
              disabled={isLoading}
              title="Delete member"
              size="sm"
              variant="link"
              className="text-reset py-0"
            >
              <MdDelete />
            </Button>
          );
        },
      },
    ],
    [dispatch, id, isLoading],
  );

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Table
                hasCount
                label="Members"
                columns={columns}
                data={values}
                layout={["key", "edit"]}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Forms
            onSubmit={(values) =>
              dispatch(
                notificationGroupState.actions.addMember({
                  notificationGroupId: id,
                  ...values,
                }),
              )
            }
            isDisabled={isLoading}
            defaultValues={{ type: "U" }}
          >
            <AddMemberForms />
          </Forms>
        </Col>
      </Form.Row>
    </>
  );
}

function AddMemberForms() {
  const { watch, submitForm } = useFormContext();
  const type = watch("type");
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <FormsField as="select" name="type" label="Type">
            <option value="U">User</option>
            <option value="E">Email</option>
          </FormsField>
        </Col>
        <Col xs="auto" style={{ minWidth: "30rem" }}>
          {type === "U" ? (
            <FormsField.Select name="key" label="User" as={UserSelect} />
          ) : (
            <FormsField name="key" label="Email" placeholder="Enter email" />
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Button variant="success" onClick={submitForm}>
            Add Member
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

function NotificationSelect({ name, id = name, label, ...props }) {
  return (
    <Form.Row>
      <Col xs="auto">
        <Form.Group controlId={id}>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{label}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormsField.Control as="select" name={name} {...props}>
              <option value="N">No</option>
              <option value="A">All</option>
              <option value="P">Priority issues only</option>
            </FormsField.Control>
          </InputGroup>
        </Form.Group>
      </Col>
    </Form.Row>
  );
}
