import shark from "../../apis/shark";

function getServersAPI({ customerId } = {}) {
  const args = { ...(customerId ? { customerId: customerId } : null) };
  const payload = {
    command: "getServers",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.servers,
    searchArgs: args,
  }));
}

function getServerAPI({ id }) {
  const payload = {
    command: "getServer",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createServerAPI(server) {
  const payload = {
    command: "createServer",
    ...server,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateServerAPI(server) {
  const payload = {
    command: "updateServer",
    ...server,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getServersAPI,
  getOne: getServerAPI,
  createOne: createServerAPI,
  updateOne: updateServerAPI,
};
