import { createEntityHooks } from "../common/entity";
import projectState from "./state";

const { useEntities: useProjects } = createEntityHooks({
  entity: projectState.entity,
  actions: {
    getMany: projectState.actions.getMany,
    reset: projectState.actions.reset,
  },
});

export default useProjects;
