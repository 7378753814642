import React, { useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Form, ListGroup, Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { HiShare } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import shark from "../apis/shark";
import Mailto from "../common/details/Mailto";
import FieldInput from "../common/fields/FieldInput";
import { Controller, useForms } from "../common/forms";
import ContextMenu from "../common/popovers/ContextMenu";
import User from "../users";

async function shareProjectAPI(options) {
  const payload = {
    ...options,
    command: "emailProject",
  };
  return await shark.post(payload);
}

export default function ProjectShareButton({ project }) {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const ref = useRef(null);

  return (
    <>
      <Button ref={ref} variant="light" onClick={() => setShow(true)}>
        <HiShare /> Share
      </Button>
      <ContextMenu
        title={
          <>
            <HiShare /> Share Project
          </>
        }
        target={ref?.current}
        show={show}
        onHide={() => setShow(false)}
        placement="top"
      >
        <ListGroup onClick={() => setShow(false)}>
          <ListGroup.Item action onClick={() => setShowDetails(true)}>
            <MdEmail /> Email
          </ListGroup.Item>
          <CopyToClipboard
            text={project?.id}
            onCopy={() => toast.success("Project # copied")}
          >
            <ListGroup.Item action>
              <BiCopy /> Copy Project #
            </ListGroup.Item>
          </CopyToClipboard>
        </ListGroup>
      </ContextMenu>
      <Modal show={showDetails} onHide={() => setShowDetails(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Email Project ${project?.id}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectShareDetailsForm
            project={project}
            onHide={() => setShowDetails(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function ProjectShareDetailsForm({ project, onHide }) {
  const email = useSelector((state) => state.auth.email);
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForms({
    defaultValues: { to: email, notesBefore: "" },
  });

  const emails = watch("to");
  const notes = watch("notesBefore");

  const toUsers = useMemo(() => {
    let users = [];
    if (project?.owner && project?.owner?.charAt(0) !== "*")
      users.push({ label: "Owner", id: project.owner });
    return users;
  }, [project]);

  const [isCompose, setIsCompose] = useState(false);

  return (
    <Form>
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <User.EmailSelect
            label="To"
            placeholder="Recipients..."
            value={value.split(",")}
            onChange={(opts) => onChange(opts.join(","))}
            isInvalid={errors.to}
            errors={errors.to && "Required"}
            users={toUsers}
          />
        )}
      />
      <Controller
        name="notesBefore"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FieldInput
            type="textarea"
            as="textarea"
            label="Notes"
            value={value}
            onChangeEvent={(e) => onChange(e.target.value)}
          />
        )}
      />

      <Form.Check
        id="composeLink"
        checked={isCompose}
        label="Compose Email with link"
        onChange={(e) => setIsCompose(e.target.checked)}
      />

      <ButtonGroup className="py-3" style={{ minWidth: "300px" }}>
        {isCompose ? (
          <Mailto
            block
            as={Button}
            email={emails}
            subject={`Project ${project?.id}: ${project?.synopsis}`}
            body={notes}
            copy={false}
            onClick={onHide}
          >
            Compose Email
          </Mailto>
        ) : (
          <Button
            onClick={handleSubmit((data) => {
              toast.info("Sending project " + project.id + "...");
              onHide();
              shareProjectAPI({ id: project.id, ...data }).then(() =>
                toast.info("Project " + project.id + " sent."),
              );
            })}
            block
          >
            Send Formatted Email
          </Button>
        )}
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
      </ButtonGroup>
      {!isCompose ? (
        <>
          <Form.Check
            label="Include Attachments"
            {...register("includeAttachments")}
          />
          <Form.Check
            label="Extra CR information"
            {...register("extraCrInfo")}
          />
        </>
      ) : null}
    </Form>
  );
}
