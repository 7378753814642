const sharkColumns = {
  CUST: "customer",
  OWNR: "owner",
  CRDT: "createDate",
  CRTM: "createTime",
  MODT: "modifyDate",
  MOTM: "modifyTime",
  CLDT: "closedDate",
  CLTM: "closedTime",
  CROP: "createdBy",
  MOOP: "modifiedBy",
  CLBY: "closedBy",
  STAT: "status",
  DIFF: "difficulty",
  MODU: "module",
  GRUP: "group",
  PRIO: "priority",
  TYPE: "type",
  RFCC: "rfcc",
  PJRF: "project",
  TDDT: "targetDeliveryDate",
  TSDT: "targetStartWork",
  TFDT: "targetFirstContact",
  NOTF: "documentation",
  CHMP: "champion",
  WTFC: "waiting",
  REFR: "id",
  DESC: "description",
  SYNP: "synopsis",
  ESST: "estimateStatus",
  SAMF: "isSAMFollowup",
  STAX: "statusExtended",
  QOWN: "reviewer",
  QAST: "qaStatus",
  JIRA: "jira",
  FIXN: "projectFixNotes",

  // Unimplemented
  EST1: "Low estimate",
  EST2: "High estimate",
  PRST: "Project status",
  CLSR: "Call source",
  ASGN: "Assigned",
  QAAI: "Addressed in build",
  CONT: "Contact",
  // Unsupported
  MDGR: "Module group",
  MLTC: "Multi-customer",
  PONO: "Customer PO",
  CNOT: "Customer note",
};

export default sharkColumns;
