import React from "react";
import { Link } from "react-router-dom";

import { useContractProduct } from "./hooks";

export default function ContractProductLink({ id, children }) {
  const { contractProduct } = useContractProduct({ id });

  if (!contractProduct?.id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Link
        className="text-reset"
        to={{ pathname: `/contracts/contract-products/${id}` }}
      >
        {children || contractProduct?.description}
      </Link>
    </span>
  );
}
