import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import FieldDate from "../common/fields/FieldDate";
import { CurrencyFormatter } from "../common/formatters";
import {
  Forms,
  FormsField,
  useController,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import { _date } from "../common/functions/dates";
import useConfirmModal from "../common/modals/useConfirmModal";
import Customer from "../customers";
import ExpenseCategory from "./categories";
import expenseSchema from "./expenseSchema";
import expenseState from "./state";
import ExpenseTrip from "./trips";
import ExpenseType from "./types";

const DEFAULT_VALUES = {
  paymentType: "C",
  amount: 0,
  miles: "",
  mileageRate: 0,
  perDiem: {
    max: 0,
    breakfast: 0,
    lunch: 0,
    dinner: 0,
  },
  overrideBillableFlag: "",
};

export default function ExpenseEditor({
  expense = {},
  onSubmit,
  onCancel,
  onDelete,
}) {
  const today = useMemo(() => _date.stamp(), []);

  return (
    <Forms
      onSubmit={onSubmit}
      defaultValues={{ ...DEFAULT_VALUES, date: today, ...expense }}
      schema={{ schema: expenseSchema }}
      showDevTool
    >
      <ExpenseForms onCancel={onCancel} onDelete={onDelete} />
    </Forms>
  );
}

function ExpenseForms({ onCancel, onDelete }) {
  const { watch, setValue, reset, submitForm } = useFormContext();

  const [
    id,
    expenseTypeId,
    expenseCategoryId,
    expenseDate,
    expenseMiles,
    expenseMileageRate,
    perDiem,
    expenseTripId,
    customerId,
  ] = watch([
    "id",
    "expenseTypeId",
    "expenseCategoryId",
    "date",
    "miles",
    "mileageRate",
    "perDiem",
    "expenseTripId",
    "customerId",
  ]);
  const { type: { isMileage: typeIsMileage } = {} } = ExpenseType.useOne({
    id: expenseTypeId,
  });
  const { category: { isMileage: categoryIsMileage } = {} } =
    ExpenseCategory.useOne({
      id: expenseCategoryId,
    });

  const isExpenseType = expenseTripId || customerId;
  const isMileage =
    (isExpenseType && typeIsMileage) || (!isExpenseType && categoryIsMileage);
  const isPerDiem = isExpenseType && expenseTypeId === "ME";

  useEffect(() => {
    expenseState.api.getDateSettings({ date: expenseDate }).then((response) => {
      setValue("mileageRate", response.mileageRate);
      setValue("perDiem", {
        breakfast: response.breakfast,
        lunch: response.lunch,
        dinner: response.dinner,
        max: response.maxPerDiem,
      });
    });
  }, [expenseDate, setValue]);

  useEffect(() => {
    if (isMileage)
      setValue("amount", (expenseMileageRate * expenseMiles).toFixed(2), {
        shouldDirty: true,
        shouldValidate: true,
      });
  }, [expenseMiles, isMileage, expenseMileageRate, setValue]);

  useFormsKeys();

  const [ConfirmModal, confirmModalProps, showConfirm] = useConfirmModal();

  return (
    <Form>
      <Form.Group>
        <p className="lead">
          {expenseTripId ? (
            <>
              Trip <ExpenseTrip.Description id={expenseTripId} /> Expense
            </>
          ) : customerId ? (
            <>
              Customer <Customer.Name id={customerId} /> Expense
            </>
          ) : (
            <>Other Expense</>
          )}
        </p>
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Col>
            <ExpenseDate name="date" showConfirm={showConfirm} />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Col xs="auto" style={{ minWidth: "300px" }}>
            {customerId || expenseTripId ? (
              <FormsField.Select
                name="expenseTypeId"
                label="Type"
                as={ExpenseType.Select}
              />
            ) : (
              <FormsField.Select
                name="expenseCategoryId"
                label="Category"
                as={ExpenseCategory.Select}
              />
            )}
          </Col>
          <Col>
            <FormsField
              name="overrideDescription"
              label="Description"
              placeholder={"Override Description"}
              autoComplete="off"
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Col xs="auto">
            <FormsField name="paymentType" label="Payment Type" as="select">
              <option value="C">Company Credit Card</option>
              <option value="E">Employee Paid</option>
            </FormsField>
          </Col>
          <Col>
            <FormsField
              name="miles"
              label="Miles"
              type="number"
              min="0"
              max="9999"
              readOnly={!isMileage}
              autoComplete="off"
            />
          </Col>
          <Col>
            <FormsField
              name="amount"
              label="Amount"
              type="number"
              min="0"
              max="999999"
              readOnly={isMileage}
              autoComplete="off"
            />
          </Col>
          <Col xs="auto">
            <Form.Label>Billable</Form.Label>
            <FormsField
              name="overrideBillableFlag"
              id="overrideBillableFlag"
              as="select"
            >
              <option value="">Default</option>
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </FormsField>
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Row className="justify-content-between">
              <Col xs="auto">
                <ButtonGroup>
                  <Button variant="success" onClick={submitForm}>
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      reset();
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Col>

              <Col xs="auto">
                {id && onDelete ? (
                  <Button variant="danger" onClick={() => onDelete({ id: id })}>
                    Delete
                  </Button>
                ) : null}
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Col>
                <FormsField.TextArea
                  name="note"
                  label="Notes"
                  placeholder="Notes..."
                  minRows={6}
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto">
                {isMileage ? (
                  <MileageTable rate={expenseMileageRate} />
                ) : isPerDiem ? (
                  <>
                    <PerDiemTable values={perDiem} />
                    <PerDiemCalculator
                      startDate={_date.fromStamp(expenseDate)}
                      perDiemAmounts={perDiem}
                      setValue={setValue}
                    />
                  </>
                ) : null}
              </Col>
            </Form.Row>
          </Form.Group>
        </Col>
      </Form.Row>

      <ConfirmModal {...confirmModalProps} />
    </Form>
  );
}

function PerDiemTable({ values }) {
  return (
    <Row>
      <Col>
        <Form.Label className="text-center">Per Diem Values</Form.Label>
        <Table size="sm" className="text-right">
          <tbody>
            <tr>
              <td>Breakfast</td>
              <td>
                <CurrencyFormatter value={values?.breakfast} />
              </td>
            </tr>
            <tr>
              <td>Lunch</td>
              <td>
                <CurrencyFormatter value={values?.lunch} />
              </td>
            </tr>
            <tr>
              <td>Dinner</td>
              <td>
                <CurrencyFormatter value={values?.dinner} />
              </td>
            </tr>
            <tr>
              <td>Max</td>
              <td>
                <CurrencyFormatter value={values?.max} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

function MileageTable({ rate }) {
  return (
    <Row>
      <Col>
        <Form.Label className="text-center">Mileage Values</Form.Label>
        <Table size="sm" className="text-right">
          <tbody>
            <tr>
              <td>Rate / Mile</td>
              <td>
                <CurrencyFormatter value={rate} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

function ExpenseDate({ name, showConfirm }) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name });

  return (
    <FieldDate
      name="date"
      label="Date"
      value={value && _date.fromStamp(value)}
      onBlur={onBlur}
      onChange={(v) => {
        const updateField = () => onChange(_date.stamp(v));
        if (moment(v) < moment().subtract(60, "days"))
          showConfirm({
            title: "Date is older than 60 days",
            message: "Are you sure you want to use this date?",
            buttons: [
              {
                label: "Confirm",
                onClick: updateField,
                variant: "success",
              },
              {
                label: "Cancel",
                variant: "secondary",
              },
            ],
          });
        else updateField();
      }}
      includeNow={false}
      isInvalid={!!error}
      errors={error?.message}
    />
  );
}

const initialValue = {
  breakfast: true,
  lunch: true,
  dinner: true,
};
function PerDiemCalculator({ startDate, perDiemAmounts, setValue }) {
  const [show, setShow] = useState(false);
  const [days, setDays] = useState(1);
  const [values, setValues] = useState([initialValue]);
  const [totals, setTotals] = useState([]);
  const [shouldChangeNotes, setShouldChangeNotes] = useState(true);
  useEffect(() => {
    setTotals(
      values.map((v) => {
        let total = 0;
        if (v.breakfast) total += perDiemAmounts.breakfast;
        if (v.lunch) total += perDiemAmounts.lunch;
        if (v.dinner) total += perDiemAmounts.dinner;
        return total;
      }),
    );
  }, [values, perDiemAmounts]);
  return (
    <Form.Row>
      <Col>
        <Button
          size="sm"
          onClick={() => setShow(true)}
          variant="outline-secondary"
          block
        >
          Calculator
        </Button>
      </Col>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="modal2"
        backdropClassName="modal2-backdrop"
      >
        <Modal.Header>Per Diem Calculator</Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{`${days} Days starting on ${_date.display(
              startDate,
            )}`}</Form.Label>
            <Form.Control
              value={days}
              type="range"
              min={1}
              max={21}
              onChange={(e) => {
                const d = e.target.value;
                setDays(d);
                setValues((old) => {
                  const newValues = [...old];
                  if (old.length > d) {
                    return old.slice(0, d);
                  } else {
                    for (var i = 0; i < d - old.length; i++)
                      newValues.push(initialValue);
                    return newValues;
                  }
                });
              }}
            />
          </Form.Group>
          {values.map((v, idx) => (
            <Form.Group key={idx}>
              <Form.Row className="justify-content-between">
                <Col xs="auto">
                  <Form.Label>
                    {_date.display(moment(startDate).add(idx, "days"))}
                  </Form.Label>
                </Col>
                <Col xs="auto">
                  <Form.Check
                    id={`breakfast-${idx}`}
                    inline
                    label="Breakfast"
                    checked={v.breakfast}
                    onChange={(e) =>
                      setValues((old) => {
                        old[idx] = { ...old[idx], breakfast: e.target.checked };
                        return [...old];
                      })
                    }
                  />
                  <Form.Check
                    id={`lunch-${idx}`}
                    inline
                    label="Lunch"
                    checked={v.lunch}
                    onChange={(e) =>
                      setValues((old) => {
                        old[idx] = { ...old[idx], lunch: e.target.checked };
                        return [...old];
                      })
                    }
                  />
                  <Form.Check
                    id={`dinner-${idx}`}
                    inline
                    label="Dinner"
                    checked={v.dinner}
                    onChange={(e) =>
                      setValues((old) => {
                        old[idx] = { ...old[idx], dinner: e.target.checked };
                        return [...old];
                      })
                    }
                  />
                </Col>
                <Col xs="auto">
                  <CurrencyFormatter
                    value={totals[idx]}
                    className="font-weight-bold"
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          ))}
          <Form.Group>
            <Form.Row className="justify-content-between">
              <Col>
                <Form.Row>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        setShow(false);
                        setValue(
                          "amount",
                          totals.reduce((a, b) => a + b, 0),
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          },
                        );
                        if (shouldChangeNotes)
                          setValue(
                            "note",
                            totals.reduce((notes, total, idx) => {
                              return (
                                notes +
                                `${_date.display(
                                  moment(startDate).add(idx, "days"),
                                )} - $${total.toFixed(2)}\n`
                              );
                            }, ""),
                          );
                      }}
                    >
                      Set Amount
                    </Button>
                  </Col>
                  <Col>
                    <Form.Check
                      id="change-notes"
                      inline
                      label="Change Notes"
                      checked={shouldChangeNotes}
                      onChange={(e) => setShouldChangeNotes(e.target.checked)}
                    />
                  </Col>
                </Form.Row>
              </Col>
              <Col className="font-weight-bold" xs="auto">
                Total{" "}
                <CurrencyFormatter
                  value={totals.reduce((a, b) => a + b, 0)}
                  className="font-weight-bold"
                />
              </Col>
            </Form.Row>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Form.Row>
  );
}
