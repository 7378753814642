import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import HostingAgreementIndex from "./HostingAgreementIndex";
import HostingAgreementMonthNew from "./HostingAgreementMonthNew";
import HostingAgreementMonthShow from "./HostingAgreementMonthShow";
import HostingAgreementNew from "./HostingAgreementNew";
import HostingAgreementShow from "./HostingAgreementShow";

export default function HostingAgreementsPages({ NavSidebar }) {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/month/:id`}>
        <HostingAgreementMonthShow
          navSidebar={<HostingAgreementNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route path={`${path}/month`}>
        <HostingAgreementMonthNew
          navSidebar={<HostingAgreementNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route path={`${path}/new`}>
        <HostingAgreementNew
          navSidebar={<HostingAgreementNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route path={`${path}/:id`}>
        <HostingAgreementShow
          navSidebar={<HostingAgreementNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
      <Route exact path={`${path}`}>
        <HostingAgreementIndex
          navSidebar={<HostingAgreementNavSidebar NavSidebar={NavSidebar} />}
        />
      </Route>
    </Switch>
  );
}

function HostingAgreementNavSidebar({ NavSidebar }) {
  return (
    <NavSidebar>
      <div className="dropdown-divider"></div>
      <NavSidebarItem to="/customers/hosting-agreements/new">
        New License
      </NavSidebarItem>
    </NavSidebar>
  );
}
