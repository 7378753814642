import { combineReducers } from "redux";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import favoriteState from "../favorites/state";
import { api } from "./api";

const entity = createEntity({
  name: "filter",
  type: "crs/filters",
  getState: (state) => state.crs.filters.filters,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const filterReducer = combineReducers({
  filters: reducer,
  favorites: favoriteState.reducer,
});

const filterState = {
  api,
  entity,
  actions,
  reducer: filterReducer,
};

export default filterState;
