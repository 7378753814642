import { number, object, string } from "yup";

const estimateSchema = object().shape({
  owner: string().label("Owner").required(),
  date: string().label("Date").required(),
  activeStatus: string().label("Status").required().max(1),
  projectEstimateStatus: string().label("Project Estimate Status").required(),
  type: string().label("Type").required().max(1),
  rate: number().label("Hourly Rate").min(0),
  rateUserId: string().label("Rate user"),
  projectManagementHours: number()
    .label("Project Management Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  designHours: number()
    .label("Design Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  developmentHours: number()
    .label("Development Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  testingHours: number()
    .label("Development Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  installationHours: number()
    .label("Installation Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  instructionHours: number()
    .label("Instruction Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  supportHours: number()
    .label("Support Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  codeCheckinHours: number()
    .label("Code checkin Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
  scopeAdditionHours: number()
    .label("Added to Scope Hours")
    .min(0)
    .test(
      "quarterHoursOnly",
      "Only quarter hour increments are allowed",
      (number) => /^(?!\.?$)\d*\.?(?:[27]5|[50]0?)?$/.test(number),
    ),
});

export default estimateSchema;
