import { isNaN } from "lodash";

export default function useFindOption({
  input,
  options,
  includeCr = true,
  includeProject = true,
  getValue = (input, typeOf) => ({ typeOf, id: input }),
}) {
  const inputInt = parseInt(input);
  if (!isNaN(inputInt)) {
    if (inputInt < 10000000 && includeCr)
      options.push({
        label: "Find from CR...",
        options: [
          {
            label: "CR: " + input,
            value: getValue(input, "cr"),
          },
        ],
      });
    if (inputInt >= 10000000 && includeProject)
      options.push({
        label: "Find...",
        options: [
          {
            label: "Project: " + input,
            value: getValue(input, "project"),
          },
        ],
      });
  }
}
