import ContractProductEditor from "./ContractProductEditor";
import ContractProductLink from "./ContractProductLink";
import ContractProductSelect from "./ContractProductSelect";
import Table from "./ContractProductTable";
import ContractProductTableFilter from "./ContractProductTableFilter";
import { useContractProduct, useContractProducts } from "./hooks";
import contractProductState from "./state";

const ContractProduct = {
  // API
  api: contractProductState.api,
  // State
  actions: contractProductState.actions,
  selectors: contractProductState.entity.selectors,
  // Hooks
  useOne: useContractProduct,
  useAll: useContractProducts,
  // Components
  Table,
  TableFilter: ContractProductTableFilter,
  Link: ContractProductLink,
  Editor: ContractProductEditor,
  Select: ContractProductSelect,
  // Related Entity Objects
};

export default ContractProduct;
