import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsAccountNavSidebar from "./AwsAccountNavSidebar";

export default function AwsAccountEdit() {
  const id = String(useParams().id).toLowerCase();
  const { awsAccount, loading, updateOne } = Server.AwsAccount.useOne({
    id,
    canGet: true,
  });
  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Account"
        navSidebar={<AwsAccountNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Aws Account" navSidebar={<AwsAccountNavSidebar />}>
      {awsAccount ? (
        <>
          <Server.AwsAccount.Editor
            values={awsAccount}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={awsAccount} />
        </>
      ) : (
        <h4>Aws Account not found.</h4>
      )}
    </Page>
  );
}
