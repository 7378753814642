import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import User from "../../../users";
import NotificationGroupNavSidebar from "./NotificationGroupNavSidebar";

export default function NotificationGroupEdit() {
  const id = String(useParams().id).toUpperCase();
  const { notificationGroup, loading, updateOne } =
    User.NotificationGroup.useOne({ id, canGet: true });
  const { isManager, isAdmin } = useSelector((state) => state.auth);

  if (!(isManager || isAdmin))
    return (
      <Page
        title="Notification Group"
        navSidebar={<NotificationGroupNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page
      title="Notification Group"
      navSidebar={<NotificationGroupNavSidebar />}
    >
      {notificationGroup ? (
        <User.NotificationGroup.Editor
          values={notificationGroup}
          isLoading={loading}
          onSubmit={(values) => updateOne(values)}
        />
      ) : (
        <h4>Notification group not found.</h4>
      )}
    </Page>
  );
}
