import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "contractProductDefaultCrs",
  type: "contracts/contractProductDefaultCrs",
  getState: (state) => state.contracts.contractProductDefaultCrs,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const contractProductDefaultCrState = {
  api,
  actions,
  reducer,
  entity,
};

export default contractProductDefaultCrState;
