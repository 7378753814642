import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import TaxProductCategory from "../../../invoices/taxProductCategories";
import InvoicesNavSidebar from "../InvoicesNavSidebar";

export default function TaxProductCategoryShow() {
  const id = useParams().id.toUpperCase();
  const { taxProductCategory, loading, updateOne } = TaxProductCategory.useOne({
    id: id,
    canGet: true,
  });
  const { canMaintainInvoices, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainInvoices || isAdmin))
    return (
      <Page
        title="Tax Product Category"
        navSidebar={<InvoicesNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Tax Product Category" navSidebar={<InvoicesNavSidebar />}>
      {taxProductCategory ? (
        <>
          <TaxProductCategory.Editor
            values={taxProductCategory}
            isNew={false}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={taxProductCategory} />
        </>
      ) : (
        <h4>Tax product category not found.</h4>
      )}
    </Page>
  );
}
