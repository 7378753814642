import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AwsAccountsPages from "./awsAccounts";
import AwsInstanceTypePages from "./awsInstanceTypes";
import AwsRegionsPages from "./awsRegions";
import AwsZonesPages from "./awsZones";
import ReservationsPages from "./reservations";
import S3BucketsPages from "./s3Buckets";
import ServerIndex from "./ServerIndex";
import ServerNew from "./ServerNew";
import ServerShow from "./ServerShow";

export default function ServersRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/aws-instance-types`}>
        <AwsInstanceTypePages />
      </Route>
      <Route path={`${path}/aws-regions`}>
        <AwsRegionsPages />
      </Route>
      <Route path={`${path}/aws-zones`}>
        <AwsZonesPages />
      </Route>
      <Route path={`${path}/aws-accounts`}>
        <AwsAccountsPages />
      </Route>
      <Route path={`${path}/s3-buckets`}>
        <S3BucketsPages />
      </Route>
      <Route path={`${path}/reservations`}>
        <ReservationsPages />
      </Route>
      <Route path={`${path}/new`}>
        <ServerNew />
      </Route>
      <Route path={`${path}/:id`}>
        <ServerShow />
      </Route>
      <Route exact path={`${path}`}>
        <ServerIndex />
      </Route>
    </Switch>
  );
}
