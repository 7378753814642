export const CR_QA_STATUSES = {
  FA: {
    id: "FA",
    description: "Failed",
    emojii: "❌",
  },
  PA: {
    id: "PA",
    description: "Passed",
    emojii: "✅",
  },
  PW: {
    id: "PW",
    description: "Passed (with Warning)",
    emojii: "✔️",
  },
  WA: {
    id: "WA",
    description: "Open (Assigned)",
    emojii: "❎",
  },
};

export function useQaStatus({ id }) {
  return { ...CR_QA_STATUSES[id] };
}

export function useQaStatuses() {
  const crQaStatuses = Object.entries(CR_QA_STATUSES).map(([, value]) => value);
  return { crQaStatuses };
}
