import { entitySelect } from "../../common/entity";
import { useBranches } from "./hooks";

const BranchSelect = entitySelect({
  name: "branch",
  useEntities: useBranches,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default BranchSelect;
