import { bool, object, string } from "yup";

const userGroupingSchema = object().shape({
  id: string()
    .label("Id")
    .required()
    .max(8)
    .transform((v) => `${v}`.toUpperCase()),
  description: string().label("Description").max(30).required(),
  isInactive: bool().required().default(false),
});

export default userGroupingSchema;
