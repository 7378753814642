import { useModule, useModules } from "./hooks";
import ModuleDescription from "./ModuleDescription";
import ModuleSelect from "./ModuleSelect";
import ModuleTableFilter from "./ModuleTableFilter";
import modulesState from "./state";

const Module = {
  // Backend API
  api: modulesState.api,
  // State
  actions: modulesState.actions,
  selectors: modulesState.entity.selectors,
  // Hooks
  useAll: useModules,
  useOne: useModule,
  // Components
  Select: ModuleSelect,
  Description: ModuleDescription,
  TableFilter: ModuleTableFilter,
};
export default Module;
