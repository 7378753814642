import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import useLogout from "./auth/useLogout";
import useRedirect from "./common/hooks/useRedirect";
import Page from "./common/pages/Page";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const logout = useLogout({ canResetState: true });
  const [, setRedirect] = useRedirect();
  return (
    <Page
      title="⚠️ Something went wrong ⚠️"
      lead="It's likely part of a larger conspiracy to undermine your productivity"
    >
      <Row>
        <Col>
          <Button onClick={resetErrorBoundary}>Retry</Button>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col>
          <strong>
            Reach out to the{" "}
            <a href="https://chat.google.com/room/AAAAB6qgxFU">
              T3 Support Chat Room
            </a>{" "}
            with the following error information
          </strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <pre>
            <code>{error.stack}</code>
          </pre>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <Button
            variant="danger"
            onClick={() => {
              logout();
              setRedirect("/");
              setTimeout(() => {
                resetErrorBoundary();
              }, 1500);
            }}
          >
            Logout and Fully Reset Cache
          </Button>
        </Col>
      </Row>
    </Page>
  );
}
