import moment from "moment";
import React, { useMemo } from "react";

import JobPage from "../../activity/jobs/JobPage";
import UserActivityTable from "../../activity/userActivity/UserActivityTable";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportActivity() {
  return (
    <JobPage
      title="Activity"
      functionName="Activity"
      Options={ReportOptions}
      defaultOptions={{ startDate: moment(), endDate: moment() }}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.data || [], [job]);
  return (
    <UserActivityTable
      userActivity={data}
      loading={isLoading}
      layout={["dateTime", "description", "source", "info", "duration"]}
    />
  );
}
