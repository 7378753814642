import shark from "../../../apis/shark";

function getS3BucketsAPI({ customerId } = {}) {
  const args = { ...(customerId ? { customerId: customerId } : null) };
  const payload = {
    command: "getS3Buckets",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.s3Buckets,
    searchArgs: args,
  }));
}

function getS3BucketAPI({ id }) {
  const payload = {
    command: "getS3Bucket",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createS3BucketAPI(s3Bucket) {
  const payload = {
    command: "createS3Bucket",
    ...s3Bucket,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateS3BucketAPI(s3Bucket) {
  const payload = {
    command: "updateS3Bucket",
    ...s3Bucket,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getS3BucketsAPI,
  getOne: getS3BucketAPI,
  createOne: createS3BucketAPI,
  updateOne: updateS3BucketAPI,
};
