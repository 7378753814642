import { useTaxProductCategories, useTaxProductCategory } from "./hooks";
import taxProductCategoryState from "./state";
import TaxProductCategoryEditor from "./TaxProductCategoryEditor";
import TaxProductCategoryLink from "./TaxProductCategoryLink";
import TaxProductCategorySelect from "./TaxProductCategorySelect";
import Table from "./TaxProductCategoryTable";
import TaxProductCategoryTableFilter from "./TaxProductCategoryTableFilter";

const TaxProductCategory = {
  // API
  api: taxProductCategoryState.api,
  // State
  actions: taxProductCategoryState.actions,
  selectors: taxProductCategoryState.entity.selectors,
  // Hooks
  useOne: useTaxProductCategory,
  useAll: useTaxProductCategories,
  // Components
  Table,
  TableFilter: TaxProductCategoryTableFilter,
  Link: TaxProductCategoryLink,
  Editor: TaxProductCategoryEditor,
  Select: TaxProductCategorySelect,
  // Related Entity Objects
};

export default TaxProductCategory;
