import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import app from "../app/index";
import crState from "./state";

export default function useCrsByFilter({ filter, canGet = true }) {
  const maxRecs = 100;
  const crsToLoadInterval = maxRecs * 5;
  const [crsToLoad, setCrsToLoad] = useState(crsToLoadInterval);

  const dispatch = useDispatch();
  const [crs, lastUpdate, startAfter] = useSelector((state) =>
    crState.entity.selectors.selectBySearch(state, filter),
  );
  const loading = useSelector((state) => state.crs.crs.requests.loading);
  const [error, setError] = useState(null);

  const canExpire = app.useCanStateExpire();

  const hasMore = startAfter !== "" && error === null && !_.isEmpty(filter);
  const totalCrs = crs.length;

  const getMore = useCallback(() => {
    if (totalCrs >= crsToLoad) setCrsToLoad(crsToLoad + crsToLoadInterval);
    return dispatch(
      crState.actions.getMany({
        filter: filter,
        maxRecs: maxRecs,
        startAfter: startAfter,
      }),
    ).then(unwrapResult);
  }, [crsToLoad, crsToLoadInterval, dispatch, filter, startAfter, totalCrs]);

  const reset = useCallback(() => {
    dispatch(crState.actions.reset(filter));
    setError(null);
    setCrsToLoad(crsToLoadInterval);
  }, [dispatch, crsToLoadInterval, filter]);

  const isExpired =
    moment().diff(moment(lastUpdate), "minutes") > 15 && canExpire;
  const shouldGet =
    loading === false &&
    (canGet === true || (canGet !== false && _.isEmpty(crs))) &&
    ((totalCrs < crsToLoad && hasMore) || isExpired || lastUpdate === null) &&
    error === null;

  useEffect(() => {
    if (!shouldGet) return;
    if (isExpired) reset();

    getMore().catch(setError);
  }, [getMore, isExpired, reset, shouldGet]);

  return {
    crs,
    loading,
    lastUpdate,
    hasMore,
    getMore,
    reset,
  };
}
