import { entitySelect } from "../../common/entity";
import { useTradingPartners } from "./hooks";

const TradingPartnerSelect = entitySelect({
  name: "tradingPartner",
  useEntities: useTradingPartners,
  filter: (e, value) => e.isInactive === false || e.id === value,
});

export default TradingPartnerSelect;
