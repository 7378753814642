import moment from "moment";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  Forms,
  FormsField,
  useFormContext,
  useWatch,
} from "../../common/forms";
import { _date } from "../../common/functions/dates";
import User from "../../users";

export default function ReportOptions({
  children,
  submitJob,
  job,
  startDate: sd = moment().startOf("month").toISOString(),
  endDate: ed = moment().endOf("month").toISOString(),
  options,
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options: jobOptions = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const allEmployeeReports = useSelector(
    (state) => state.auth.allEmployeeReports,
  );

  return (
    <Forms
      defaultValues={{
        userId: "",
        startDate: "",
        endDate: "",
        ...jobOptions,
        ...options,
      }}
      context={{ allEmployeeReports, loggedInUser }}
      onSubmit={(options) => {
        submitJob({
          ...options,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: `${
            options?.userId ? options?.userId + " " : ""
          } from ${_date.display(options.startDate)} to ${_date.display(
            options.endDate,
          )}`,
        });
      }}
      showDevTool
    >
      <ReportOptionsForms>{children}</ReportOptionsForms>
    </Forms>
  );
}

function ReportOptionsForms({ children }) {
  const {
    submitForm,
    context: { allEmployeeReports, loggedInUser },
  } = useFormContext();

  const endDate = useWatch({ name: "endDate" });
  const startDate = useWatch({ name: "startDate" });

  return (
    <Form onSubmit={submitForm}>
      <Form.Row>
        <Col xs="auto">
          <FormsField.Date
            name="startDate"
            label="Start Date"
            includeNow={false}
            maxDate={endDate}
          />
        </Col>
        <Col>
          <FormsField.Date
            name="endDate"
            label="End Date"
            includeNow={false}
            minDate={startDate}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <FormsField.Select
            name="userId"
            label="User"
            as={User.Select}
            rules={{
              validate: {
                allowed: (value) =>
                  value === loggedInUser || allEmployeeReports,
              },
            }}
            isClearable
            isDisabled={!allEmployeeReports}
          />
        </Col>
      </Form.Row>
      {children}
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
