import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import Customer from "../../customers";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportHostingYearBreakdown() {
  const { isAdmin, hostingAgreementAccess } = useSelector(
    (state) => state.auth,
  );

  if (
    !(
      hostingAgreementAccess === "V" ||
      hostingAgreementAccess === "M" ||
      isAdmin
    )
  )
    return (
      <Page
        title="Hosting Year Breakdown"
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line

  return (
    <JobPage
      title="Hosting Year Breakdown"
      functionName="HostingYearBreakdown"
      Options={HostingYearBreakdownOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function RevenueTotal({
  rows,
  expectedStart,
  ptr,
  reservationCosts,
  planCosts,
  diskCosts,
  serverCosts,
  otherCosts,
  showCosts,
}) {
  const calcRow = (total, row) => {
    if (row.isGrouped) {
      if (row.isExpanded) return total;
      else return row.subRows.reduce(calcRow, total);
    } else {
      if (ptr === 13) {
        let sum = 0;
        for (let p = 1; p < 13; p++) {
          sum = sum + parseFloat(row.original["revenue" + p]);
        }
        return sum + total;
      } else {
        return parseFloat(row.original["revenue" + ptr]) + total;
      }
    }
  };

  const total = rows.reduce(calcRow, 0);

  let reservationCostSum = 0;
  let planCostSum = 0;
  let diskCostSum = 0;
  let serverCostSum = 0;
  let otherCostSum = 0;

  for (let p = 1; p < 13; p++) {
    if (ptr === 13 || ptr === p) {
      reservationCostSum = reservationCostSum + reservationCosts[p - 1];
      planCostSum = planCostSum + planCosts[p - 1];
      diskCostSum = diskCostSum + diskCosts[p - 1];
      serverCostSum = serverCostSum + serverCosts[p - 1];
      otherCostSum = otherCostSum + otherCosts[p - 1];
    }
  }

  if (showCosts) {
    return (
      <>
        <Alert
          variant="success"
          title="Revenue"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={total} decimals={0} />
          {ptr >= expectedStart ? "*" : null}
        </Alert>
        <Alert
          variant="warning"
          title="Reservations prorated"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={reservationCostSum} decimals={0} />
        </Alert>
        <Alert
          variant="warning"
          title="Savings Plans prorated"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={planCostSum} decimals={0} />
        </Alert>
        <Alert
          variant="danger"
          title="Server Costs"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={serverCostSum} decimals={0} />
        </Alert>
        <Alert
          variant="secondary"
          title="Disk Space Costs"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={diskCostSum} decimals={0} />
        </Alert>
        <Alert
          variant="secondary"
          title="Other Costs"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter value={otherCostSum} decimals={0} />
        </Alert>
        <Alert
          variant="info"
          title="Net Margin"
          className={["m-0", "rounded-0"]}
        >
          <CurrencyFormatter
            value={
              total -
              otherCostSum -
              reservationCostSum -
              planCostSum -
              serverCostSum -
              diskCostSum -
              otherCostSum
            }
            decimals={0}
          />
        </Alert>
      </>
    );
  } else {
    return <CurrencyFormatter value={total} decimals={0} />;
  }
}

function revenueCell(value, expectedStart, ptr) {
  return (
    <div>
      <CurrencyFormatter value={value} blankZero={true} decimals={0} />
      {ptr >= expectedStart ? "*" : null}
    </div>
  );
}

function Report({ job, isLoading }) {
  const [showCosts, setShowCosts] = useState(true);
  const data = useMemo(() => job?.output?.customers || [], [job]);
  const expectedStart = job?.output?.expectedStart;
  const reservationCosts = useMemo(
    () => (job?.output?.reservationCosts ? job?.output?.reservationCosts : []),
    [job?.output?.reservationCosts],
  );
  const planCosts = useMemo(
    () => (job?.output?.savingsPlanCosts ? job?.output?.savingsPlanCosts : []),
    [job?.output?.savingsPlanCosts],
  );
  const diskCosts = useMemo(
    () =>
      job?.output?.serverDiskSpaceCosts
        ? job?.output?.serverDiskSpaceCosts
        : [],
    [job?.output?.serverDiskSpaceCosts],
  );
  const serverCosts = useMemo(
    () =>
      job?.output?.serverHostingCosts ? job?.output?.serverHostingCosts : [],
    [job?.output?.serverHostingCosts],
  );
  const otherCosts = useMemo(
    () => (job?.output?.otherCosts ? job?.output?.otherCosts : []),
    [job?.output?.otherCosts],
  );

  const columns = useMemo(() => {
    const output = [
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: ({ value }) => <Customer.Name id={value} />,
      },
      {
        id: "Jan",
        Header: "Jan",
        accessor: "revenue1",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 1),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={1}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Feb",
        Header: "Feb",
        accessor: "revenue2",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 2),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={2}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Mar",
        Header: "Mar",
        accessor: "revenue3",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 3),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={3}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Apr",
        Header: "Apr",
        accessor: "revenue4",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 4),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={4}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "May",
        Header: "May",
        accessor: "revenue5",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 5),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={5}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Jun",
        Header: "Jun",
        accessor: "revenue6",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 6),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={6}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Jul",
        Header: "Jul",
        accessor: "revenue7",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 7),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={7}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Aug",
        Header: "Aug",
        accessor: "revenue8",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 8),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={8}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Sep",
        Header: "Sep",
        accessor: "revenue9",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 9),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={9}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Oct",
        Header: "Oct",
        accessor: "revenue10",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 10),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={10}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Nov",
        Header: "Nov",
        accessor: "revenue11",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 11),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={11}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Dec",
        Header: "Dec",
        accessor: "revenue12",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 12),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={12}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "revenue13",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => revenueCell(value, expectedStart, 13),
        Footer: ({ rows }) => (
          <RevenueTotal
            rows={rows}
            expectedStart={expectedStart}
            ptr={13}
            reservationCosts={reservationCosts}
            planCosts={planCosts}
            diskCosts={diskCosts}
            serverCosts={serverCosts}
            otherCosts={otherCosts}
            showCosts={showCosts}
          />
        ),
      },
    ];

    return output;
  }, [
    diskCosts,
    expectedStart,
    otherCosts,
    planCosts,
    reservationCosts,
    serverCosts,
    showCosts,
  ]);

  return (
    <>
      <Row>
        <Col xs={"auto"}>
          <ButtonGroup toggle>
            <ToggleButton
              key="show"
              type="radio"
              variant="secondary"
              value={true}
              checked={showCosts === true}
              onChange={(e) => setShowCosts(e.currentTarget.value === "true")}
            >
              Revenue and Costs
            </ToggleButton>
            <ToggleButton
              key="activeFals"
              type="radio"
              variant="secondary"
              value={false}
              checked={showCosts === false}
              onChange={(e) => setShowCosts(e.currentTarget.value !== "false")}
            >
              Revenue Only
            </ToggleButton>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
          />
        </Col>
      </Row>
    </>
  );
}

function HostingYearBreakdownOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("year"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      customerId: null,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      ...options,
    },
  });

  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          customerId !== "" && customerId !== null
            ? "Hosting year breakdown for " + useCustomerValue?.customer?.name
            : "Hosting year breakdown for " +
              _date.stamp(options.startDate).substring(0, 4);
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          year: _date.stamp(options.startDate).substring(0, 4),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Year"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                dateOnlyFormat="yyyy"
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>&nbsp;</Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isClearable={true}
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
