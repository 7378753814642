import { DevTool } from "@hookform/devtools";
import React from "react";
import { FormProvider } from "react-hook-form";

import { useForms } from "./useForms";

export function Forms({
  children,
  onSubmit,
  isDisabled,
  showDevTool = false,
  ...props
}) {
  const formProps = useForms({ onSubmit, ...props });

  return (
    <FormProvider formsContext={{ onSubmit, isDisabled }} {...formProps}>
      {children}
      {showDevTool ? <DevTool /> : null}
    </FormProvider>
  );
}
