import { getToken } from "../auth/state/token";
import fileBase64 from "../common/functions/fileBase64";
import shark, { SHARK_URL } from "./shark";

export async function getAttachmentAPI({ id }) {
  const payload = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      appId: "T3",
      command: "getAttachment",
      id,
      authToken: getToken(),
    }),
  };

  // console.log(payload);

  // Step 1: start the fetch and obtain a reader
  let response = await fetch(SHARK_URL + "/services", payload);

  const type = response.headers.get("Content-Type");

  if (type === "application/json") {
    const json = await response.json();
    return Promise.reject(json.responseMessage);
  }

  return response.blob();
}

export async function deleteAttachmentAPI({ id }) {
  const payload = {
    id,
    command: "deleteAttachment",
  };
  return shark.post(payload);
}

export async function createAttachmentAPI({ command, id, files }) {
  const lcFilesToDecode = await files.reduce(async (lcFiles, file) => {
    const content = await fileBase64(file);
    return await lcFiles.concat([{ content, originalFilename: file.name }]);
  }, []);
  const payload = {
    command,
    id,
    lcFilesToDecode,
  };
  return shark
    .post(payload, { config: { timeout: 0 } })
    .then((r) => ({ data: r.responseData }));
}
