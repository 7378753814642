import moment from "moment";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import List from "./List";

function RefreshButton({ onRefresh, isLoading, lastUpdate }) {
  if (!onRefresh) return null;
  const text = lastUpdate
    ? `Last Updated ${
        isLoading ? "N/A" : moment(lastUpdate).format("dddd, h:mm:ss a")
      }`
    : "Refresh";
  return (
    <Button
      variant="link"
      className="text-muted float-right"
      onClick={onRefresh}
    >
      {text}
    </Button>
  );
}

export default function InfiniteList({
  data,
  name,
  fields,
  idField = "id",
  link,
  getMore,
  isLoading = false,
  hasMore,
  lastUpdate,
  onRefresh,
}) {
  const endMessage = <p className="text-muted text-center">End of List.</p>;
  const loaderMessage = <p className="text-muted text-center">Loading...</p>;

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <p className="text-muted float-left">{`${data.length}${
              hasMore ? "+" : ""
            } ${name}`}</p>
            <RefreshButton
              onRefresh={onRefresh}
              isLoading={isLoading}
              lastUpdate={lastUpdate}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfiniteScroll
              dataLength={data.length}
              next={() => getMore(data)}
              hasMore={hasMore}
              loader={loaderMessage}
              endMessage={endMessage}
            >
              <List
                list={data}
                fields={fields}
                idField={idField}
                link={link}
                isLoading={isLoading}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
