import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";

export default function CodeTestFailuresTable({ values, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "type",
        accessor: "type",
        id: "Type",
        Filter: SelectColumnFilter,
        filter: "equals",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
          let text = "?";
          if (value == "E") text = "RETURN.EOS";
          else if (value == "F") text = "Bad Ifs";
          else if (value == "G") text = "Get/Read";
          else if (value == "H") text = "Help msg";
          else if (value == "I") text = "IT1";
          else if (value == "L") text = "LMOD";
          else if (value == "M") text = "IMH2";
          else if (value == "N") text = "NOBLANK";
          else if (value == "Q") text = "Query";
          else if (value == "R") text = "RK/RP with Query";
          else if (value == "S") text = "Duplicate CASE";
          else if (value == "T") text = "TEDIT";
          else if (value == "V") text = "MAKEVAR";
          else if (value == "Y") text = "Memory";
          else if (value == "Z") text = "Reusable verbs";

          return <span>{text}</span>;
        },
      },
      {
        // Dont include self loading reusable reviews.
        Header: "Filename",
        id: "Filename",
        accessor: "flnm",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{
              pathname: `/dbc/sourceCode/${value
                .split("\\")
                .pop()
                .split("/")
                .pop()}`,
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Line",
        id: "Line",
        accessor: "line",
        Cell: ({ value }) => {
          if (value === 0) return "";
          else {
            return <span>{value}</span>;
          }
        },
      },
      {
        Header: "Code",
        id: "Code",
        accessor: "text",
        Cell: ({ value }) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
              maxWidth: "400px",
              whiteSpace: "pre-line",
            }}
          >
            {value}
          </p>
        ),
      },
      {
        Header: "Blamed User",
        id: "Blamed User",
        accessor: "blmu",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
      {
        Header: "Blamed Date",
        id: "Blamed Date",
        accessor: "blmd",
        Cell: ({ value }) => {
          if (value === "") return "";
          else {
            return (
              <span>
                {_date.display(_date.fromStamp(value?.substring(0, 8)))}
              </span>
            );
          }
        },
      },
      {
        Header: "Blamed Comment",
        id: "Blamed Comment",
        accessor: "blmc",
        Cell: ({ value }) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
              maxWidth: "400px",
              whiteSpace: "pre-line",
            }}
          >
            {value}
          </p>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Failures"
      columns={columns}
      data={values}
      {...props}
    />
  );
}
