import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import Contact from "./contacts";
import Cr from "./crs";
import Customer from "./customers";
import ExpenseCategory from "./expenses/categories";
import ExpenseType from "./expenses/types";
import Project from "./projects";
import useSettings from "./settings/useSettings";
import { persistor } from "./store";
import Time from "./time";
import User from "./users";

export default function usePreloader({ canGet = false }) {
  const opts = { canGet };
  const preloadedEntities = {
    settings: useSettings(opts),
    customers: Customer.useAll(opts),

    users: User.useAll(opts),
    pseudoUser: User.Pseudo.useAll(opts),
    groups: User.Group.useAll(opts),
    groupPrograms: User.Group.Program.useAll(opts),
    teams: User.Team.useAll(opts),

    projectStatuses: Project.Status.useAll(opts),
    projectPriorities: Project.Priority.useAll(opts),
    projectSources: Project.Source.useAll(opts),
    projectTypes: Project.Type.useAll(opts),
    projectFolders: Project.Folder.useAll(opts),
    projectEstimateStatuses: Project.EstimateStatus.useAll(opts),

    crFilters: Cr.Filter.useAll(opts),
    crFilterFavorites: Cr.Filter.Favorite.useAll(opts),
    crStatuses: Cr.Status.useAll(opts),
    crNotificationTypes: Cr.NotificationType.useAll(opts),
    crDifficulties: Cr.Difficulty.useAll(opts),
    crModules: Cr.Module.useAll(opts),
    crTableLayouts: Cr.TableLayout.useAll(opts),

    timeHoldReasons: Time.HoldReason.useAll(opts),
    timeInternalProjects: Time.InternalProject.useAll(opts),
    timeBillableOverrideReasons: Time.BillableOverrideReason.useAll(opts),

    expenseCategories: ExpenseCategory.useAll(opts),
    expenseTypes: ExpenseType.useAll(opts),

    contactTypes: Contact.ContactType.useAll(opts),
  };

  const toastId = React.useRef(null);

  const loadingArgs = Object.entries(preloadedEntities).map(
    ([, e]) => e.loading,
  );

  const [loading, isLoading, progress] = useMemo(() => {
    const loaded = loadingArgs.reduce((sum, l) => (l ? sum : sum + 1), 0);
    const isLoading = loaded !== loadingArgs.length;
    const progress = loaded / loadingArgs.length;
    return [loadingArgs, isLoading, progress];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, loadingArgs);

  useEffect(() => {
    // console.log(progress);
    if (!canGet) return;
    if (isLoading) {
      if (toastId.current === null && progress < 0.8) {
        toastId.current = toast("Loading cache from server...", {
          type: toast.TYPE.INFO,
          hideProgressBar: false,
          progress: 0.01,
          autoClose: 0,
        });
        console.log("Master files loading...");
      } else {
        toast.update(toastId.current, {
          progress: progress,
        });
      }
    } else if (!isLoading && toastId.current !== null) {
      console.log("Master files loaded.");
      persistor.flush().then(() => {
        console.log("Master files saved.");
        toast.update(toastId.current, {
          render: "Cache loaded.",
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
          progress: undefined,
          autoClose: 5000,
        });
      });
    }
  }, [loading, isLoading, progress, canGet]);

  return { loading: isLoading, progress, entities: preloadedEntities };
}
