import React, { useMemo } from "react";

import { _datetime } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";

export default function FtpUserDeleteLogTable({ logs, ...props }) {
  const columns = useMemo(
    () => [
      {
        id: "When",
        Header: "When",
        accessor: "datetimeStamp",
        Cell: ({ value }) =>
          _datetime.displayDateTime(_datetime.fromStamp(value)),
      },
      {
        id: "filename",
        Header: "Filename",
        accessor: "filename",
        Filter: TextColumnFilter,
        filter: "text",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Delete Logs"
      columns={columns}
      data={logs}
      {...props}
    />
  );
}
