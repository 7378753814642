import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { buildAsyncThunkReducer } from "../../../common/functions/state";
import { api } from "./api";

const entity = createEntity({
  name: "hostingAgreement",
  type: "customers/hostingAgreements",
  getState: (state) => state.customers.hostingAgreements,
});

const entityActions = createEntityActions({ entity, api });

const createAttachment = createAsyncThunk(
  "customers/hostingAgreements/attachments/create",
  async (args) => {
    const response = await api.createAttachment(args);
    return response;
  },
);

const actions = { ...entityActions, createAttachment };

const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(
      builder,
      actions.createAttachment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
  },
});

const hostingAgreementsState = {
  api,
  entity,
  actions,
  reducer,
};

export default hostingAgreementsState;
