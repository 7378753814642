import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

import { CurrencyFormatter } from "../common/formatters";
import { Forms, FormsField, useFormsKeys } from "../common/forms";
import Customer from "../customers";
import RealUserSelect from "../users/RealUserSelect";
import contractSchema from "./contractSchema";

export default function ContractEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={({ ...values }) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: contractSchema }}
      isDisabled={isDisabled}
      showDevTool
    >
      <ContractForm isNew={isNew} values={values} />
    </Forms>
  );
}

function ContractForm({ isNew, values }) {
  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col xs="auto">
          <FormsField name="id" label="Id" readOnly={true} autoComplete="off" />
        </Col>
        <Col xs="auto" className="align-self-center">
          <FormsField.Check name="isInactive" label="Inactive" />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={"auto"} style={{ minWidth: "30em" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={Customer.Select}
            isClearable={false}
            isDisabled={!isNew && !values.canChangeCustomer}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"description"}>
            <Row>
              <Col xs={"auto"}>
                <Form.Label>{"Description"}</Form.Label>
              </Col>
            </Row>
            <InputGroup>
              <FormsField.Control name="description" />
            </InputGroup>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto" style={{ width: "320px" }}>
          <FormsField.Date
            name="goliveDate"
            label="Go live date"
            dateFormat="MM/dd/yyyy"
            includeArrows={false}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={"auto"} style={{ minWidth: "25em" }}>
          <FormsField.Select
            name="projectManager"
            label="Project Manager"
            as={RealUserSelect}
            isClearable={true}
          />
        </Col>
        <Col xs={"auto"} style={{ minWidth: "25em" }}>
          <FormsField.Select
            name="salesperson"
            label="Salesperson"
            as={RealUserSelect}
            isClearable={true}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Group controlId={"blendedRate"}>
            <Row>
              <Col xs={"auto"}>
                <Form.Label>{"Blended Rate"}</Form.Label>
              </Col>
            </Row>
            <InputGroup>
              <FormsField.Control name="blendedRate" />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"blendedTravelRate"}>
            <Row>
              <Col xs={"auto"}>
                <Form.Label>{"Blended Travel Rate"}</Form.Label>
              </Col>
            </Row>
            <InputGroup>
              <FormsField.Control name="blendedTravelRate" />
            </InputGroup>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <FormsField
            name="totalContractAmount"
            label="Total Contract Amount"
            autoComplete="off"
            type="number"
          />
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Total Prepay Expected</Form.Label>
            <CurrencyFormatter
              value={values.totalPrepayExpected}
              displayType="input"
              className={"form-control"}
              disabled={true}
            />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Total Prepay Received</Form.Label>
            <CurrencyFormatter
              value={values.totalPrepayReceived}
              displayType="input"
              className={"form-control"}
              disabled={true}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Total Billed</Form.Label>
            <CurrencyFormatter
              value={values.totalBilled}
              displayType="input"
              className={"form-control"}
              disabled={true}
            />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Total Tax</Form.Label>
            <CurrencyFormatter
              value={values.totalTax}
              displayType="input"
              className={"form-control"}
              disabled={true}
            />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>Total Travel</Form.Label>
            <CurrencyFormatter
              value={
                values.totalTravelExpenses + values.totalTravelBillableTime
              }
              displayType="input"
              className={"form-control"}
              disabled={true}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}

// function CustomerSelect({ name, id = name, label, ...props }) {
//   const {
//     field: { onChange, ...field },
//     fieldState: { error },
//   } = useController({ name });
//   const {
//     formsContext: { isDisabled = false },
//   } = useFormContext();

//   return (
//     <Form.Group controlId={id}>
//       {label ? <Form.Label>{label}</Form.Label> : null}
//       <Customer.Select
//         {...field}
//         disabled={isDisabled}
//         onChange={(v) => {
//           onChange(v);
//         }}
//         {...props}
//       />
//       <Form.Control.Feedback type="invalid">
//         {error?.message}
//       </Form.Control.Feedback>
//     </Form.Group>
//   );
// }
