import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import ContractProductCategory from "../../../contracts/contractProductCategories";
import ContractNavSidebar from "../ContractNavSidebar";

export default function ContractProductCategoryNew() {
  const { createOne } = ContractProductCategory.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page
      title="Contract Product Categories"
      navSidebar={<ContractNavSidebar />}
    >
      <ContractProductCategory.Editor
        isNew={true}
        values={{ isInactive: false }}
        onSubmit={(contractProductCategory) =>
          createOne(contractProductCategory).then(({ id }) =>
            setRedirect(`/contracts/contract-product-categories/${id}`),
          )
        }
      />
    </Page>
  );
}
