import _ from "lodash";
import moment from "moment";
import React, { useMemo, useRef } from "react";
import { Badge, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

import useIntervalUpdateComponent from "../common/hooks/useIntervalUpdateComponent";
import Project from "../projects";
import useSettings from "../settings/useSettings";
import Cr from ".";
import Filter from "./filters";

export default function CrNav() {
  const {
    settings: { crFilterId: defaultFilterId },
  } = useSettings();

  const { filter } = Filter.useOne({
    id: defaultFilterId,
  });

  const isFilterValid = !_.isEmpty(filter?.filter);
  const { crs, loading, lastUpdate } = Cr.useSearch({
    filter: filter?.filter,
    canGet: isFilterValid,
  });

  const { types } = Project.Type.useAll();

  const [totals, prioritiesTotals, typeTotals] = useMemo(() => {
    const totals = crs.length;
    const prioritiesTotals = crs.filter((cr) =>
      ["PR", "IM", "EM", "EF"].includes(cr?.projectPriorityId),
    ).length;
    const typeTotals = types
      .map((t) => ({
        type: t.id,
        description: t.description,
        count: crs
          .filter((cr) => {
            const typeId = cr?.overrideTypeId || cr?.projectTypeId;
            return typeId === t.id;
          })
          .reduce(
            (counts, cr) => {
              counts[0]++;
              if (["PR", "IM", "EM", "EF"].includes(cr?.projectPriorityId))
                counts[1]++;
              return counts;
            },
            [0, 0],
          ),
      }))
      .filter((t) => t.count[0]);
    return [totals, prioritiesTotals, typeTotals];
  }, [crs, types]);

  // TODO: THE UPDATES STILL DON'T APPEAR TO BE WORKING. I found some code in
  // useCrsByFilter that seems to check for 15 minute intervals as well, but
  // setting that, the below update interval, and useCrs intervals to
  // 15 seconds still didn't fix it.
  useIntervalUpdateComponent(30000);
  const ref = useRef();

  return (
    <Form inline>
      <OverlayTrigger
        target={ref}
        placement="bottom"
        delay={{ show: 500, hide: 400 }}
        overlay={
          <Popover id="popover-cr">
            <Popover.Title as="h3">
              CR Totals - {filter?.description}
            </Popover.Title>
            <Popover.Content>
              <table>
                <thead>
                  <tr>
                    <th>CR Type</th>
                    <th>Total/</th>
                    <th>Priorities</th>
                  </tr>
                </thead>
                <tbody>
                  {typeTotals.map((t) => (
                    <tr key={t.type}>
                      <td>{t.description}</td>
                      <td className="text-right">{t.count[0]}/</td>
                      <td className="text-right">{t.count[1]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <small className="text-muted">
                {loading
                  ? "Loading..."
                  : "Last updated " + moment(lastUpdate).fromNow()}
              </small>
            </Popover.Content>
          </Popover>
        }
      >
        <Link to={`/crs?filter=${filter?.id}`}>
          <Badge variant="secondary" style={{ fontSize: "1rem" }} ref={ref}>
            {!isFilterValid ? "" : `${totals}/${prioritiesTotals}`}
          </Badge>
        </Link>
      </OverlayTrigger>
    </Form>
  );
}
