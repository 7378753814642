import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { _date } from "../../../common/functions/dates";
import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../../common/functions/state";
import { getCurrentTimerAPI } from "./api";

const getCurrentTimer = createAsyncThunk(
  "time/currentTimer/get",
  async (_, thunkApi) => {
    const { getState, requestId } = thunkApi;
    if (!testStateLoading(getState().times.currentTimer, requestId)) return;
    const { responseData } = await getCurrentTimerAPI();
    return responseData.currentTimer;
  },
);

/*
const stopCurrentTimer = createAsyncThunk(
  "timers/currentTimer/stop",
  async (_, thunkApi) => {
    const { getState, requestId } = thunkApi;
    if (!testStateLoading(getState().times.currentTimer, requestId)) return;

    const { responseData } = await stopCurrentTimerAPI({
      timeId: getState().times.currentTimer.ids[0],
    });
    return responseData.currentTimer;
  },
);
const restartTimer = createAsyncThunk(
  "timers/currentTimer/restart",
  async ({ timeId }, thunkApi) => {
    const { getState, requestId } = thunkApi;
    if (!testStateLoading(getState().times.currentTimer, requestId)) return;

    const { responseData } = await restartTimerAPI({
      timeId: timeId,
    });
    return responseData.currentTimer;
  },
);
*/
const currentTimerSlice = createSlice({
  name: "time/currentTimer",
  initialState: {
    ...INITIAL_LOADING_STATE,
    lastUpdate: moment().subtract(30, "days").toISOString(),
    timer: {},
  },
  reducers: {
    setCurrentTimer(state, action) {
      if (action.id === "setCurrentTimer") {
        state.timer = action.payload;
      }
    },
    updateCurrentTimer(state, action) {
      if (action.payload.action === "create") {
        let t = action.payload;

        // if you added a record for someone else then clearly
        // we shouldn't be updating the current timer for you.
        if (t.userId !== t.loggedInUser) return;

        let ts = t.timers;
        let newCurrentTimer = getNewest(ts, t.date, t.timerDescription);
        if (
          newCurrentTimer.startTime !== "" &&
          (state.timer?.startTime === undefined ||
            state.timer?.startTime < newCurrentTimer.startTime)
        ) {
          newCurrentTimer.id = t.id;
          state.timer = newCurrentTimer;
        }
      } else if (action.payload.action === "update") {
        updateOrRestart(state, action);
      } else if (action.payload.action === "delete") {
        if (state.timer?.id === action.payload.id) {
          state.timer = {};
        }
      }
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getCurrentTimer, (state, action) => {
      state.timer = action.payload;
      state.lastUpdate = new Date().toISOString();
    });
  },
});

function updateOrRestart(state, action) {
  let t = action.payload;
  let newCurrentTimer = getNewest(t.timers, t.date, t.timerDescription);
  newCurrentTimer.id = t.id;

  // if we updated a record, you could have changed user to someone else
  // if so then delete the current timer.
  if (state.timer?.id === t.id) {
    if (newCurrentTimer.startTime === "" || t.userId !== t.loggedInUser)
      state.timer = {};
    else state.timer = newCurrentTimer;
  } else {
    if (t.userId !== t.loggedInUser) return;

    if (
      newCurrentTimer.startTime !== "" &&
      (state.timer?.startTime === undefined ||
        state.timer?.startTime < newCurrentTimer.startTime)
    ) {
      state.timer = newCurrentTimer;
    }
  }
}

function getNewest(timers, date, timerDescription) {
  let startTime = "";
  let endTime = "";
  const today = _date.stamp();

  for (let i = 0; i < timers.length; i++) {
    if (timers[i].endTime === "") {
      startTime = date + timers[i].startTime;
      endTime = timers[i].endTime;
      break;
    } else if (startTime < date + timers[i].startTime && date === today) {
      startTime = date + timers[i].startTime;
      endTime = date + timers[i].endTime;
    }
  }
  return {
    startTime: startTime,
    endTime: endTime,
    timerDescription: timerDescription,
  };
}

export const reducer = currentTimerSlice.reducer;

const { setCurrentTimer, updateCurrentTimer } = currentTimerSlice.actions;
export const actions = { getCurrentTimer, setCurrentTimer, updateCurrentTimer };
