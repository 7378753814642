import shark from "../../apis/shark";

function getUserAPI({ id }) {
  const payload = {
    command: "getUser",
    id: id,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}
function updateUserAPI(args) {
  const payload = {
    command: "getUser",
    ...args,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function getUsersAPI() {
  const payload = {
    command: "getUsers",
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData.users }));
}

function getUserRateAPI({ id, projectId, crId }) {
  const payload = {
    command: "getUserRate",
    id: id,
    projectId: projectId,
    crId: crId,
  };
  return shark.post(payload);
}

function createServerLoginAPI(args) {
  const payload = {
    command: "createServerLogin",
    ...args,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function deleteServerLoginAPI(args) {
  const payload = {
    command: "deleteServerLogin",
    ...args,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateServerLoginKeysAPI(args) {
  const payload = {
    command: "updateServerLoginKeys",
    ...args,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateServerLoginPasswordAPI(args) {
  const payload = {
    command: "updateServerLoginPassword",
    ...args,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getUsersAPI,
  getOne: getUserAPI,
  updateOne: updateUserAPI,
  getRate: getUserRateAPI,
  createServerLogin: createServerLoginAPI,
  deleteServerLogin: deleteServerLoginAPI,
  updateServerLoginKeys: updateServerLoginKeysAPI,
  updateServerLoginPassword: updateServerLoginPasswordAPI,
};
