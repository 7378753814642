import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import ExpenseCustomerEntry from "./ExpenseCustomerEntry";
import ExpenseCustomerIndex from "./ExpenseCustomerIndex";
import ExpenseIndex from "./ExpenseIndex";
import ExpenseOtherEntry from "./ExpenseOtherEntry";
import ExpenseSearch from "./ExpenseSearch";
import ExpenseTripEntry from "./ExpenseTripEntry";
import ExpenseTripIndex from "./ExpenseTripIndex";

export default function ExpensesRouting() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/trips/:id`}>
        <ExpenseTripEntry />
      </Route>
      <Route exact path={`${path}/trips`}>
        <ExpenseTripIndex />
      </Route>
      <Route exact path={`${path}/customers/:id`}>
        <ExpenseCustomerEntry />
      </Route>
      <Route exact path={`${path}/customers`}>
        <ExpenseCustomerIndex />
      </Route>
      <Route exact path={`${path}/categories`}>
        <ExpenseOtherEntry />
      </Route>
      <Route exact path={`${path}/search`}>
        <ExpenseSearch />
      </Route>
      <Route exact path={`${path}`}>
        <ExpenseIndex />
      </Route>
    </Switch>
  );
}
