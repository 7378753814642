import { findKey, sortBy } from "lodash";

import shark from "../../../apis/shark";
import sharkColumns from "../sharkColumns";

const buildCrTableLayout = (layout) => {
  const columns = layout.columns.map((c) => {
    return { ...c, column: sharkColumns[c.column] };
  });
  return { ...layout, columns };
};

const deconstructCrTableLayout = ({
  columns: columnsOriginal,
  ...crTableLayout
}) => {
  const columns = sortBy(columnsOriginal, "id").map((c, idx) => ({
    column: findKey(sharkColumns, (k) => k === c.column),
    id: idx + 1,
  }));
  return { ...crTableLayout, columns };
};

function getCrTableLayoutsAPI() {
  return shark.post({ command: "getCrTableLayouts" }).then((r) => {
    return { data: r.responseData.crTableLayouts.map(buildCrTableLayout) };
  });
}

function getCrTableLayoutAPI({ id }) {
  return shark.post({ command: "getCrTableLayout", id }).then((r) => {
    return { data: buildCrTableLayout(r.responseData) };
  });
}

function createCrTableLayoutAPI(crTableLayout) {
  return shark
    .post({
      command: "createCrTableLayout",
      ...deconstructCrTableLayout(crTableLayout),
    })
    .then((r) => {
      return { data: buildCrTableLayout(r.responseData) };
    });
}

function updateCrTableLayoutAPI(crTableLayout) {
  return shark
    .post({
      command: "updateCrTableLayout",
      ...deconstructCrTableLayout(crTableLayout),
    })
    .then((r) => {
      return { data: buildCrTableLayout(r.responseData) };
    });
}

export const api = {
  getMany: getCrTableLayoutsAPI,
  getOne: getCrTableLayoutAPI,
  createOne: createCrTableLayoutAPI,
  updateOne: updateCrTableLayoutAPI,
};
