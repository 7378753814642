import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { CurrencyFormatter, HoursFormatter } from "../common/formatters";
import { _date } from "../common/functions/dates";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Project from "../projects";
import User from "../users";

function ApprovalsTable({
  projects = [],
  layout,
  isLoading,
  initialSort,
  onRefresh,
  clickedProject = () => {},
  approveProject = () => {},
  approveAll,
  customerId,
  showTheDiscountModal,
}) {
  const projectAlertClasses = "py-1 my-0 mx-0 px-1 rounded-0";
  const alertClassName = "py-1 my-0 mx-0 px-1 rounded-0 text-right";

  const columns = React.useMemo(
    () => [
      {
        id: "project",
        Header: "Project",
        accessor: "project",

        Cell: (o) => {
          return (
            <>
              <Row>
                <Col>
                  <Link
                    className="text-decoration-none"
                    to={{ pathname: `/projects/${o?.value}` }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      clickedProject(o?.value);
                    }}
                  >
                    {o?.value}
                  </Link>
                </Col>
              </Row>
              {o?.row?.original?.alertOverBudget ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  Over Budget
                </Alert>
              ) : null}
              {o?.row?.original?.alertFutureClosed ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  Closed prj has future time
                </Alert>
              ) : null}
              {o?.row?.original?.alertHeldTime ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  Held Time
                </Alert>
              ) : null}
              {o?.row?.original?.alertHoldingOlder ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  Odd mix of held/unheld time
                </Alert>
              ) : null}
              {o?.row?.original?.alertCrsClosed ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  All Crs closed Prj not
                </Alert>
              ) : null}
              {o?.row?.original?.alertSeparateInvoice ? (
                <Alert variant="warning" className={projectAlertClasses}>
                  Separate Invoice
                </Alert>
              ) : null}
              <Row>
                <Col>
                  {o?.row?.original?.approved === "Y" ? (
                    <Button
                      variant="danger"
                      onClick={() => approveProject(o?.value, customerId, "U")}
                      disabled={isLoading}
                    >
                      Unapprove
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      onClick={() => approveProject(o?.value, customerId, "A")}
                      disabled={isLoading}
                    >
                      Approve
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          );
        },
        disableFilters: true,
      },
      {
        id: "owner",
        Header: "Owner",
        accessor: "owner",

        Cell: ({ value }) => <User.Link id={value} showId={true} />,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "status",
        Header: "Status",
        accessor: "statusId",
        Filter: Project.Status.TableFilter,
        filter: "equals",

        Cell: ({ value }) => <Project.Status.Description id={value} />,
      },
      {
        id: "service",
        Header: "Service",
        accessor: "product",
        Filter: TextColumnFilter,
        filter: "text",

        Cell: (o) => {
          return (
            <>
              <div>
                {o?.row?.original?.product}
                <br />
                <div className="small">{o?.row?.original?.projectType}</div>
              </div>
            </>
          );
        },
      },
      {
        id: "synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "billHours",
        Header: "Bill Hours",
        accessor: "billHours",
        format: {
          alignment: { horizontal: "right" },
        },
        disableFilters: true,
      },
      {
        id: "nonbillHours",
        Header: "Non-bill Hours",
        accessor: "nonbillHours",
        format: {
          alignment: { horizontal: "right" },
        },
        disableFilters: true,
      },
      {
        id: "billTotal",
        Header: "Bill Total",
        accessor: "billTotal",
        format: {
          alignment: { horizontal: "right" },
        },
        disableFilters: true,
      },
      {
        id: "billThruDate",
        Header: "Approved thru",
        accessor: "billThruDate",
        disableFilters: true,

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "past",
        Header: "Past",
        disableFilters: true,
        accessor: (t) => t.pastTotal,
        format: {
          alignment: { horizontal: "right" },
        },

        Cell: (o) => {
          return (
            <>
              {o?.row?.original?.pastTotal !== ".00" ? (
                <Alert variant="success" className={alertClassName}>
                  {"$" + o?.row?.original?.pastTotal}
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
              {o?.row?.original?.pastBillHours !== ".00" ? (
                <Alert variant="primary" className={alertClassName}>
                  {o?.row?.original?.pastBillHours + " hr"}
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
              {o?.row?.original?.pastNonbillHours !== ".00" ? (
                <Alert variant="danger" className={alertClassName}>
                  {o?.row?.original?.pastNonbillHours + " hr"}
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
            </>
          );
        },
      },
      {
        id: "current",
        Header: "Current",
        disableFilters: true,
        accessor: (t) => t.billTotal,
        format: {
          alignment: { horizontal: "right" },
        },

        Cell: (o) => {
          return (
            <>
              <Alert variant="success" className={alertClassName}>
                {"$" + o?.row?.original?.billTotal}
              </Alert>
              {o?.row?.original?.billHours !== ".00" ? (
                <Alert variant="primary" className={alertClassName}>
                  {o?.row?.original?.billHours + " hr"}
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
              {o?.row?.original?.nonbillHours !== ".00" ? (
                <Alert variant="danger" className={alertClassName}>
                  {o?.row?.original?.nonbillHours + " hr"}
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
            </>
          );
        },
      },
      {
        id: "skipped",
        Header: "Skipped",
        disableFilters: true,
        accessor: (t) => t.futureTotal,
        format: {
          alignment: { horizontal: "right" },
        },

        Cell: (o) => {
          return (
            <>
              {o?.row?.original?.futureTotal !== ".00" ? (
                <Alert variant="success" className={alertClassName}>
                  <CurrencyFormatter value={o?.row?.original?.futureTotal} />
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
              {o?.row?.original?.futureBillHours !== ".00" ? (
                <Alert variant="primary" className={alertClassName}>
                  <HoursFormatter value={o?.row?.original?.futureBillHours} />
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
              {o?.row?.original?.futureNonbillHours !== ".00" ? (
                <Alert variant="danger" className={alertClassName}>
                  <HoursFormatter
                    value={o?.row?.original?.futureNonbillHours}
                  />
                </Alert>
              ) : (
                <Alert className={alertClassName}>&nbsp;</Alert>
              )}
            </>
          );
        },
      },
      {
        id: "nonbillTotal",
        Header: "Non-bill Total",
        accessor: "nonbillTotal",
        format: {
          alignment: { horizontal: "right" },
        },

        Cell: ({ value }) => <CurrencyFormatter value={value} />,
        disableFilters: true,
      },
      {
        id: "discount",
        Header: "Discount",
        accessor: "discount",
        format: {
          alignment: { horizontal: "center" },
        },

        Cell: (o) => {
          if (o?.row?.original?.discountAmount === 0) {
            return (
              <Button
                variant="secondary"
                onClick={() =>
                  showTheDiscountModal(
                    o?.row?.original?.project,
                    o?.row?.original?.discountAmount,
                    o?.row?.original?.discountComment,
                  )
                }
                disabled={isLoading}
              >
                None
              </Button>
            );
          } else {
            return (
              <Button
                variant="success"
                onClick={() =>
                  showTheDiscountModal(
                    o?.row?.original?.project,
                    o?.row?.original?.discountAmount,
                    o?.row?.original?.discountComment,
                  )
                }
                disabled={isLoading}
              >
                <CurrencyFormatter value={o?.row?.original?.discountAmount} />
              </Button>
            );
          }
        },
        disableFilters: true,
      },
      {
        id: "estimate",
        Header: "Estimate",
        accessor: "estimate",
        format: {
          alignment: { horizontal: "right" },
        },

        Cell: ({ value }) => <CurrencyFormatter value={value} />,
        disableFilters: true,
      },
    ],
    [
      isLoading,
      clickedProject,
      approveProject,
      customerId,
      showTheDiscountModal,
      projectAlertClasses,
      alertClassName,
    ],
  );

  return (
    <>
      <hr />
      <Row>
        <Col xs={"auto"}>
          <Button
            variant="success"
            onClick={() => approveAll(projects, customerId, "A")}
            disabled={isLoading}
          >
            Approve All
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="danger"
            onClick={() => approveAll(projects, customerId, "U")}
            disabled={isLoading}
          >
            Unapprove All
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Table
          hasCount
          bordered
          hover
          size="sm"
          label="Approvals"
          columns={columns}
          initialSort={initialSort}
          layout={layout}
          data={projects}
          onRefresh={onRefresh}
          isLoading={isLoading}
          moveFooterToTop
          getRowProps={(row) => {
            if (row.isGrouped) return {};
            switch (row?.original.approved) {
              case "Y":
                return {
                  className: "table-success",
                };
              case "P":
                return {
                  className: "table-warning",
                };
              default:
                if (row?.original.statusId !== "OP") {
                  return {
                    className: "table-secondary",
                  };
                }
            }
          }}
        />
      </Row>
    </>
  );
}

/*
function comparison(prev, next) {
  console.log("******start comparison");
  if (prev.projects !== next.projects) {
    console.log("projects are not equal");
    return false;
  }
  if (prev.layout !== next.layout) {
    console.log("layout are not equal");
    return false;
  }
  if (prev.isLoading !== next.isLoading) {
    console.log("isloading are not equal");
    console.log(prev.isLoading);
    console.log(next.isLoading);
    return false;
  }
  if (prev.initialSort !== next.initialSort) {
    console.log("initialsort are not equal");
    return false;
  }
  if (prev.onRefresh !== next.onRefresh) {
    console.log("onrefresh are not equal");
    return false;
  }
  if (prev.clickedProject !== next.clickedProject) {
    console.log("clickedproject are not equal");
    return false;
  }
  if (prev.approveProject !== next.approveProject) {
    console.log("approveproject are not equal");
    return false;
  }
  if (prev.approveAll !== next.approveAll) {
    console.log("approveall are not equal");
    return false;
  }
  if (prev.customerId !== next.customerId) {
    console.log("customerid are not equal");
    return false;
  }
  if (prev.showTheDiscountModal !== next.showTheDiscountModal) {
    console.log("sd m not equal");
    return false;
  }

  console.log("******end comparison");
  return true;
}
*/
export default React.memo(ApprovalsTable);
