import React from "react";

import { SelectColumnFilterWithOptions } from "../../common/tables/TableFilters";
import { useTaxProducts } from "./hooks";

export default function TaxProductTableFilter({ column }) {
  const { filterValue } = column;
  const { taxProducts } = useTaxProducts();
  const _options = taxProducts
    .filter((s) => s.isInactive === false || s.id === filterValue)
    .map((f) => {
      return { label: f.description, value: f.id };
    });
  return <SelectColumnFilterWithOptions column={column} options={_options} />;
}
