import shark from "../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityHooks,
  createEntityReducer,
  entityDescription,
  entitySelect,
  entityTableFilter,
} from "../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getInternalTimeProjects" }).then((r) => {
      return { data: r.responseData.internalTimeProjects };
    });
  },
};

const entity = createEntity({
  name: "internalProject",
  type: "times/internalProjects",
  getState: (state) => state.times.internalProjects,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

export const internalProjectState = {
  api,
  entity,
  actions,
  reducer,
};

const { useEntities: useInternalProjects, useEntity: useInternalProject } =
  createEntityHooks({
    entity: internalProjectState.entity,
    actions: internalProjectState.actions,
  });

const TimeInternalProjectSelect = entitySelect({
  name: "timeInternalProject",
  useEntities: useInternalProjects,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeInternalProjectTableFilter = entityTableFilter({
  name: "timeInternalProject",
  useEntities: useInternalProjects,
  filter: (e, value) => e.inactive === false || e.id === value,
});

const TimeInternalProjectDescription = entityDescription({
  name: "timeInternalProject",
  useEntity: useInternalProject,
});

const TimeInternalProject = {
  // Backend API
  api: internalProjectState.api,
  // State
  actions: internalProjectState.actions,
  selectors: internalProjectState.entity.selectors,
  // Hooks
  useAll: useInternalProjects,
  useOne: useInternalProject,
  // Components
  Select: TimeInternalProjectSelect,
  Description: TimeInternalProjectDescription,
  TableFilter: TimeInternalProjectTableFilter,
};
export default TimeInternalProject;
