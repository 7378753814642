import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Page from "../../../common/pages/Page";
import TaxProduct from "../../../invoices/taxProducts";
import InvoicesNavSidebar from "../InvoicesNavSidebar";

export default function TaxProductIndex() {
  const [active, setActive] = useState(true);
  const { taxProducts, loading, reset } = TaxProduct.useAll({
    canGet: true,
  });
  const data = useMemo(
    () => taxProducts.filter((e) => e.isInactive === !active),
    [active, taxProducts],
  );
  return (
    <Page title="Tax Products" navSidebar={<InvoicesNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <TaxProduct.Table
        taxProducts={data}
        initialSort={{ id: "Tax Product" }}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
