import AwsRegionEditor from "./AwsRegionEditor";
import AwsRegionLink from "./AwsRegionLink";
import AwsRegionSelect from "./AwsRegionSelect";
import Table from "./AwsRegionTable";
import { useAwsRegion, useAwsRegions } from "./hooks";
import awsRegionState from "./state";

const AwsRegion = {
  // API
  api: awsRegionState.api,
  // State
  actions: awsRegionState.actions,
  selectors: awsRegionState.entity.selectors,
  // Hooks
  useOne: useAwsRegion,
  useAll: useAwsRegions,
  // Components
  Table,
  Link: AwsRegionLink,
  Editor: AwsRegionEditor,
  Select: AwsRegionSelect,
  // Related Entity Objects
};

export default AwsRegion;
