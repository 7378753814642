import React from "react";
import Skeleton from "react-loading-skeleton";

import Page from "../../common/pages/Page";

export default function CrPage({
  cr,
  heading,
  lead,
  isLoading,
  children,
  navSidebar,
  tableOfContents,
}) {
  const title = cr
    ? `${heading}: ${cr.customers[0] ? cr.customers[0].name : ""} - ${
        cr.synopsis
      }`
    : heading;

  const _lead = lead ? (
    lead
  ) : cr?.synopsis ? (
    cr?.synopsis
  ) : isLoading ? (
    <Skeleton />
  ) : (
    ""
  );
  return (
    <Page
      title={title}
      heading={heading}
      lead={_lead}
      navSidebar={navSidebar}
      tableOfContents={tableOfContents}
    >
      {children}
    </Page>
  );
}
