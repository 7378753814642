import { usePriorities, usePriority } from "./hooks";
import PriorityDescription from "./PriorityDescription";
import PrioritySelect from "./PrioritySelect";
import PriorityTableFilter from "./PriorityTableFilter";
import priorityState from "./state";

const Priority = {
  // Backend API
  api: priorityState.api,
  // State
  actions: priorityState.actions,
  selectors: priorityState.entity.selectors,
  // Hooks
  useAll: usePriorities,
  useOne: usePriority,
  // Components
  Select: PrioritySelect,
  Description: PriorityDescription,
  TableFilter: PriorityTableFilter,
};
export default Priority;
