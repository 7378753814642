import React from "react";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function SourceCodeIndex() {
  const { entities, loading, reset } = Dbc.SourceCode.useAll({
    canGet: true,
  });
  return (
    <Page title="DBC Source Code" navSidebar={<DbcNavSidebar />}>
      <Dbc.SourceCode.Table
        sourceCode={entities}
        initialSort={{ id: "Filename" }}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
