import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Select, { createFilter } from "react-select";
import { toast } from "react-toastify";

import useUserActivity from "./activity/userActivity/useUserActivity";
import { OptionExtended } from "./common/fields/FieldSelect";
import useTinykeys from "./common/hooks/useTinykeys";
import Contact from "./contacts";
import Customer from "./customers";
import Project from "./projects";
import useSettings from "./settings/useSettings";
import Time from "./time";
import User from "./users";

const prCrTextSearchUrl = "/reports/pr-cr-text-search/";

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFromStart: true,
  stringify: ({ data }) => {
    return `${data.label} ${data?.info || ""}`.replace(/[-']/g, "");
  },
  trim: true,
});

const ContactTableComponents = {
  Customer: ({ value }) => <Customer.Link id={value} />,
};

const SelectComponents = { Option: OptionExtended };

export default function NavSearch({ isLoading, links }) {
  const [input, setInput] = useState("");
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(null);

  const [showNewTime, setShowNewTime] = useState(false);
  const hideShowNewTime = () => setShowNewTime(false);
  const [showNewProject, setShowNewProject] = useState(false);
  const [showContactLookup, setShowContactLookup] = useState(false);
  const [showGroupLookup, setShowGroupLookup] = useState(false);

  const { entities: userActivity } = useUserActivity();

  const history = useHistory();

  let ref = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const date = moment();
  const currentCr = useSelector((state) => state.crs.crs.currentCr);

  const getFocus = (e) => {
    e.preventDefault();
    ref.current.focus();
    setFocus(true);
  };

  let keyCfg = {
    "Alt+KeyC": () => setShowNewProject(true),
    "Alt+KeyT": () => setShowNewTime(true),
    "Alt+KeyL": () => setShowContactLookup(true),
    "Alt+KeyS": () => {
      var win = window.open(prCrTextSearchUrl, "_blank");
      win.focus();
    },
  };
  if (focus) {
    keyCfg["Escape"] = () => {
      if (!_.isFunction(ref.current.blur())) return;
      ref.current.blur();
      setFocus(false);
    };
  } else if (_.isFunction(ref?.current?.focus)) {
    keyCfg["Alt+Slash"] = getFocus;
    keyCfg["$mod+k"] = getFocus;
  }
  useTinykeys(keyCfg);

  useEffect(() => {
    if (!value) return;
    setInput("");
    if (value?.redirect) {
      history.push(value?.redirect);
    } else if (value?.openInNewTab) {
      var win = window.open(value?.openInNewTab, "_blank");
      win.focus();
    } else if (_.isFunction(value?.action)) {
      value.action();
    } else if (value?.toast) {
      toast.info(value?.toast);
    }
    setValue(null);
  }, [value, history]);

  const location = useLocation();
  useEffect(() => {
    setShowNewProject(false);
    setShowNewTime(false);
    setShowContactLookup(false);
    setShowGroupLookup(false);
  }, [location]);

  let options = [];

  const actions = [
    {
      label: (
        <Row>
          <Col>New Project </Col>
          <Col className="text-right">
            <small>
              <kbd>alt + c</kbd>
            </small>
          </Col>
        </Row>
      ),
      value: { action: () => setShowNewProject(true) },
    },
    {
      label: (
        <Row>
          <Col>New Time Entry </Col>
          <Col className="text-right">
            <small>
              <kbd>alt + t</kbd>
            </small>
          </Col>
        </Row>
      ),
      value: { action: () => setShowNewTime(true) },
    },
    {
      label: (
        <Row>
          <Col>Contact Lookup </Col>
          <Col className="text-right">
            <small>
              <kbd>alt + l</kbd>
            </small>
          </Col>
        </Row>
      ),
      value: { action: () => setShowContactLookup(true) },
    },
    {
      label: "Group Lookup by Program",
      value: { action: () => setShowGroupLookup(true) },
    },
    {
      label: (
        <Row>
          <Col>Project/CR Text Search</Col>
          <Col className="text-right">
            <small>
              <kbd>alt + s</kbd>
            </small>
          </Col>
        </Row>
      ),
      value: { openInNewTab: prCrTextSearchUrl },
    },
  ];
  options.push({ label: "Perform Action...", options: actions });

  const routes = useMemo(
    () =>
      links
        .filter((l) => l.to)
        .map((l) => ({ label: l.label, value: { redirect: l.to } })),
    [links],
  );
  options.push({ label: "Navigate to...", options: routes });

  Project.useFindOption({
    input,
    options,
    getValue: (input, typeOf) => ({ openInNewTab: `/${typeOf}s/${input}` }),
  });

  if (userActivity?.length) {
    const _userActivity = _.uniqBy(
      _.reverse(_.sortBy(userActivity, "dateTime")),
      (a) => a.source + a.key,
    ).map((a) => {
      if (a.source === "CR")
        return {
          label: "CR: " + a.key,
          info: a.moreInfo,
          value: { openInNewTab: "/crs/" + a.key },
        };
      else if (a.source === "PR")
        return {
          label: "Project: " + a.key,
          info: a.moreInfo,
          value: { openInNewTab: "/projects/" + a.key },
        };
      return {
        label: "Unknown: " + a.key,
        info: a.moreInfo,
        value: { toast: "Unknown search result" },
      };
    });
    options.push({
      label: "Based on Activity...",
      options: _userActivity,
    });
  }

  const { customers } = Customer.useAll();
  const { settings: { customerSort } = {} } = useSettings();

  const _customers = customers
    .filter((c) => c.inactive === false)
    .map((f) => {
      return {
        label: customerSort === "A" || !f?.sortName ? f.name : f.sortName,
        title: f.name,
        info: f.name,
        value: { openInNewTab: "/customers/" + f.id },
      };
    })
    .sort((a, b) => {
      const sortValueA = a.label;
      const sortValueB = b.label;
      return sortValueA.toLowerCase().localeCompare(sortValueB.toLowerCase());
    });

  options.push({
    label: "Open Customer...",
    options: _customers,
  });

  return (
    <Form inline>
      <Select
        ref={ref}
        components={SelectComponents}
        filterOption={filterOption}
        id="nav-search"
        name="nav-search"
        placeholder="Search..."
        value={null}
        styles={{
          input: (style) => ({
            ...style,
            minWidth: 150,
          }),
          container: (style) => ({
            ...style,
            width: "auto",
          }),
          menu: (style) => ({
            ...style,
            width: "150%",
          }),
        }}
        options={options}
        onChange={(opt) => opt?.value && setValue(opt.value)}
        onInputChange={(s) => setInput(s)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        maxMenuHeight={600}
        blurInputOnSelect
        isDisabled={isLoading}
      />
      <Time.NewModal
        show={showNewTime}
        onHide={hideShowNewTime}
        user={user}
        date={date}
        crId={currentCr}
        onCancel={hideShowNewTime}
        onSave={hideShowNewTime}
      />
      <NewProjectModal
        show={showNewProject}
        onHide={() => setShowNewProject(false)}
      />
      <Contact.LookupModal
        show={showContactLookup}
        onHide={() => setShowContactLookup(false)}
        TableComponents={ContactTableComponents}
      />
      <GroupLookupByProgramModal
        show={showGroupLookup}
        onHide={() => setShowGroupLookup(false)}
      />
    </Form>
  );
}

function NewProjectModal({ show, onHide }) {
  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title id="new-project-modal">New Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Project.New onNew={onHide} onCancel={onHide} />
      </Modal.Body>
    </Modal>
  );
}

function GroupLookupByProgramModal({ show, onHide }) {
  const ref = useRef();

  useEffect(() => {
    if (show) ref.current.focus();
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Group Lookup by Program
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <User.Group.LookupByProgram ref={ref} />
      </Modal.Body>
    </Modal>
  );
}
