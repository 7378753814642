import { array, bool, object, string } from "yup";

const pseudoSchema = object().shape({
  name: string().label("Description").required(),
  defaultGroup: string().label("Default Group"),
  owner: string().label("Owner"),
  id: string().label("Id").required().max(5),
  inactive: bool().required().default(false),
  allowedTypes: array().of(string()).max(10).nullable(),
});

export default pseudoSchema;
