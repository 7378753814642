import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import AttachmentsTable from "../../common/details/AttachmentsTable";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import LoadingModal from "../../common/modals/LoadingModal";
import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import Cr from "../../crs";
import Time from "../../time";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportContractorWorkSummary() {
  return (
    <JobPage
      title="Contractor Work Summary"
      functionName="ContractorWork"
      Options={ContractorWorkOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState(null);
  const formattedVersion = job?.output?.formattedVersion;
  const attachments = useMemo(() => job?.output?.attachments || [], [job]);
  const data = useMemo(() => job?.output?.time || [], [job]);

  const clickedBillingNote = useCallback(
    (timeId) => {
      setShowTimeId(timeId);
      setShowModifyModal(true);
    },
    [setShowTimeId, setShowModifyModal],
  );

  const columns = useMemo(
    () => [
      {
        id: "User",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "date",
        Filter: SelectColumnFilter,
        filter: "equals",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => <>{_date.display(_date.fromStamp(value))}</>,
      },
      {
        id: "Billable",
        Header: "Bill?",
        accessor: "isBillable",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "Show on Invoice",
        Header: "Show on Invoice",
        accessor: "showOnInvoice",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Held",
        Header: "Held",
        accessor: "held",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Contractor Billable",
        Header: "Contractor Bill?",
        accessor: "isContractorBillable",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
      },
      {
        id: "CR",
        Header: "CR",
        accessor: (row) => (row.crId === 0 ? "N/A" : row.crId),
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value }) => <Cr.Link crId={value} />,
      },
      {
        id: "Synopsis",
        Header: "Synopsis",
        accessor: "synopsis",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Estimate",
        Header: "Estimate",
        accessor: "estimate",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerName",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Billing note",
        Header: "Billing note",
        accessor: "billingNote",
        Filter: TextColumnFilter,
        filter: "text",
        // eslint-disable-next-line react/display-name
        Cell: ({ value, row }) => {
          return (
            <Time.BillingNoteLink
              timeId={row?.original?.id}
              value={value}
              setModifyShow={clickedBillingNote}
            />
          );
        },
      },
      {
        id: "Invoice Project Type",
        Header: "Invoice Project Type",
        accessor: "invoiceType",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
        aggregate: "uniqueCount",
      },
      {
        id: "Project Type",
        Header: "Project Type",
        accessor: "projectTypeDescription",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
        aggregate: "uniqueCount",
      },
      {
        id: "Rate",
        Header: "Rate",
        accessor: "rate",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
      },
      {
        id: "Hours",
        Header: "Hours",
        accessor: "hours",
        disableFilters: true,
        dataType: "number",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        // eslint-disable-next-line react/display-name
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.hours) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <div className="text-right">{total.toFixed(2)}</div>;
        },
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        dataType: "currency",
        format: { number: { decimals: 2 } },
        aggregate: "sum",
        // eslint-disable-next-line react/display-name
        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.total) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return <CurrencyFormatter value={total} />;
        },
      },
    ],
    [clickedBillingNote],
  );

  if (isLoading) {
    return <LoadingModal show={isLoading} />;
  } else if (formattedVersion) {
    return (
      <AttachmentsTable
        attachments={attachments}
        source={{ source: "JB", sourceId: job.id }}
        isDisabled={true}
      />
    );
  } else {
    return (
      <>
        <Time.ModifyModal
          id={showTimeId}
          show={showModifyModal}
          onHide={() => setShowModifyModal(false)}
          onCancel={() => setShowModifyModal(false)}
        />
        <Row>
          <Col>
            <Table
              columns={columns}
              data={data}
              isLoading={isLoading}
              moveFooterToTop
              layout={[
                "Contractor Billable",
                "CR",
                "Customer",
                "Synopsis",
                "Project Type",
                "Estimate",
                "Billing note",
                "Invoice Project Type",
                "Date",
                "Rate",
                "Billable",
                "Held",
                "Show on Invoice",
                "Hours",
                "Total",
              ]}
            />
          </Col>
        </Row>
      </>
    );
  }
}

function ContractorWorkOptions(props) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const { user } = User.useOne({ id: loggedInUser });
  return (
    <ReportOptions
      {...props}
      options={{
        userId: user.isContractor ? loggedInUser : "",
        formattedVersion: false,
      }}
    >
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="formattedVersion"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <FieldYesNoSelect
                id="formattedVersion"
                label="Run formatted version?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={error}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}
