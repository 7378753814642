import { bool, object, string } from "yup";

const awsAccountSchema = object().shape({
  description: string().label("Description").required().max(30),
  id: string().label("Id").required().max(20),
  isInactive: bool().required().default(false),
  isInternalAccount: bool().required().default(false),
  accessKey: string().label("Access Key"),
  secretKey: string().label("Secret Key"),
});

export default awsAccountSchema;
