import { bool, object, string } from "yup";

const taxProductSchema = object().shape({
  id: string()
    .label("Id")
    .required()
    .max(12)
    .transform((v) => `${v}`.toUpperCase()),
  description: string().label("Description").max(60).required(),
  longDescription: string().label("Description").max(500),
  isInactive: bool().required().default(false),
  productTaxCategory: string().label("Product Tax Category").max(12).required(),
});

export default taxProductSchema;
