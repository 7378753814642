import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "jiraProject",
  type: "jiraProjects",
  getState: (state) => state.customers.jiraProjects.jiraProjects,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const jiraProjectReducer = combineReducers({
  jiraProjects: reducer,
});

const jiraProjectState = {
  api,
  actions,
  reducer: jiraProjectReducer,
  entity,
};

export default jiraProjectState;
