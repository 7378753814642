import { useNotificationType, useNotificationTypes } from "./hooks";
import NotificationTypeDescription from "./NotificationTypeDescription";
import NotificationTypeSelect from "./NotificationTypeSelect";
import NotificationTypeTableFilter from "./NotificationTypeTableFilter";
import notificationTypeState from "./state";

const NotificationType = {
  // Backend API
  api: notificationTypeState.api,
  // State
  actions: notificationTypeState.actions,
  selectors: notificationTypeState.selectors,
  // Hooks
  useAll: useNotificationTypes,
  useOne: useNotificationType,
  // Components
  Select: NotificationTypeSelect,
  Description: NotificationTypeDescription,
  TableFilter: NotificationTypeTableFilter,
};
export default NotificationType;
