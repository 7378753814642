import React from "react";

import ContactTable from "./ContactTable";
import { useContactsByCustomer } from "./hooks";

const layout = ["name", "title", "types", "phones", "email"];

export default function ContactTableByCustomer({ customerId }) {
  const { contacts, loading, reset } = useContactsByCustomer({
    search: { customerId },
    canGet: true,
  });

  return (
    <ContactTable
      contacts={contacts}
      layout={layout}
      isLoading={loading}
      onRefresh={() => reset({ customerId })}
      skeletonLines={8}
    />
  );
}
