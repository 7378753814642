import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsZoneNavSidebar from "./AwsZoneNavSidebar";

export default function AwsZoneIndex() {
  const [active, setActive] = useState(true);
  const { awsZones, loading, reset } = Server.AwsZone.useAll({
    canGet: true,
  });

  const data = useMemo(() => {
    return awsZones.filter((e) => {
      return e.isInactive === !active;
    });
  }, [active, awsZones]);
  return (
    <Page title="Aws Zones" navSidebar={<AwsZoneNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Server.AwsZone.Table
        awsZones={data}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
