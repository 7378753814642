import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";

import CrLinkWithContextMenu from "../../common/components/CrLinkWithContextMenu";
import AttachmentsButton from "../../common/details/AttachmentsButton";
import Comments from "../../common/details/Comments";
import DetailField from "../../common/details/DetailField";
import DetailSection from "../../common/details/DetailSection";
import { _datetime } from "../../common/functions/dates";
import useIntervalUpdateComponent from "../../common/hooks/useIntervalUpdateComponent";
import Cr from "../../crs";
import Customer from "../../customers";
import Project from "../../projects";
import User from "../../users";
import ProjectNavSidebar from "./ProjectNavSidebar";
import ProjectPage from "./ProjectPage";
import ProjectShowDetails from "./ProjectShowDetails";
import ProjectShowNavSidebar from "./ProjectShowNavSidebar";

const CrTableComponents = {
  CrLink: CrLinkWithContextMenu,
};

export default function ProjectShow() {
  const id = parseInt(useParams().id);
  const {
    project,
    loading: isLoading,
    updateOne,
    getProject,
    createComment,
    updateComment,
    createAttachment,
    lastUpdate,
    updateWatch,
  } = Project.useOne({ id, logActivity: true, redirect: false });

  const transferred =
    project?.transferredFolder && project?.transferredFolder !== "CURRENT";

  useIntervalUpdateComponent(15000);

  if (isLoading === false && !project)
    return (
      <ProjectPage
        heading={`Project ${id ? id : ""}`}
        lead="Invalid project"
        navSidebar={<ProjectNavSidebar />}
      />
    );

  const isLoadingAndInvalid = isLoading && _.isEmpty(project);

  return (
    <ProjectPage
      project={project}
      heading={`Project ${id}`}
      navSidebar={<ProjectShowNavSidebar id={id} />}
      isLoading={isLoadingAndInvalid}
    >
      <Details
        project={project}
        isLoading={isLoading}
        transferred={transferred}
        update={updateOne}
        createAttachment={createAttachment}
        getProject={getProject}
        lastUpdate={lastUpdate}
        updateWatch={updateWatch}
      />
      <Customer.SpecialWorkConditions
        customers={project?.customers?.map((c) => {
          return c.customerId;
        })}
        isLoading={isLoadingAndInvalid}
      />
      <DetailSection title="CRs" key="crs-section">
        <Row>
          <Col xl={8} lg={12} className="border-right">
            <Cr.Table
              crs={project?.crs}
              isLoading={isLoadingAndInvalid}
              layout={["id", "synopsis", "owner", "statusExtended"]}
              skeletonLines={3}
              Components={CrTableComponents}
            />
          </Col>
          <Col xs={2}>
            <LinkContainer to={`/crs/new?project=${id}`}>
              <Button
                variant="success"
                block
                disabled={
                  project?.owner === "*WEB*" ||
                  project?.statusId !== "OP" ||
                  isLoading
                }
              >
                Add CR
              </Button>
            </LinkContainer>
            <Form.Text className="text-muted">
              {project?.statusId !== "OP" ? "Project is closed" : ""}
            </Form.Text>
          </Col>
        </Row>
      </DetailSection>
      <DetailSection title="Comments" key="comments-section">
        <Row className="mb-3 border rounded border-success bg-light ">
          <Col>
            <DetailField.Text
              id={`project-${project?.id}-fix-notes`}
              onEdit={(value) => updateOne({ fixNotes: value })}
              isDisabled={transferred}
              isLoading={isLoading}
              header="Fix Notes"
              text={project?.fixNotes}
              heightRem={2}
            />
          </Col>
        </Row>
        <Row className="mb-3 border rounded border-info bg-light ">
          <Col>
            <DetailField.Text
              id={`project-${project?.id}-internal-notes`}
              onEdit={(value) => updateOne({ internalNotes: value })}
              isDisabled={transferred}
              isLoading={isLoading}
              header="Internal Notes"
              text={project?.internalNotes}
              heightRem={2}
            />
          </Col>
        </Row>

        <Comments
          id={`project-${id}-comments`}
          comments={project?.comments}
          isLoading={isLoading}
          create={createComment}
          update={updateComment}
        />
      </DetailSection>
    </ProjectPage>
  );
}

function Details({
  project,
  isLoading,
  transferred,
  update,
  createAttachment,
  getProject,
  lastUpdate,
  updateWatch,
}) {
  const isLoadingAndInvalid = isLoading && _.isEmpty(project);

  const isDisabled = isLoading || transferred;
  const isDisabledOrClosed = isDisabled || project?.statusId != "OP";

  const attachments = useMemo(
    () =>
      project?.crs?.reduce(
        (attachments, cr) => attachments?.concat(cr?.attachments),
        project?.attachments || [],
      ),
    [project],
  );

  return (
    <Row>
      <Col md={8} sm={12} className="border-right">
        <Project.Alerts project={project} />
        <Row>
          <Col>
            <DetailField header="Customers">
              <Project.Contacts
                project={project}
                isLoading={isLoadingAndInvalid}
                mailto={{
                  subject: `Project ${project?.id}: ${project?.synopsis}`,
                }}
              />
            </DetailField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DetailField.Text
              id={`project-${project?.id}-description`}
              onEdit={
                isDisabled ? null : (value) => update({ description: value })
              }
              isLoading={isLoading}
              header="Description"
              text={project?.description}
              heightRem={14}
            />
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <Row>
          <Col>
            <span className="text-muted float-left">
              <Button
                variant="link"
                className="text-muted"
                size="sm"
                onClick={() => getProject()}
                disabled={isLoadingAndInvalid}
              >
                {isLoading
                  ? "Loading..."
                  : "Fetched " + moment(lastUpdate).fromNow() + " \u27F3"}
              </Button>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mb-1">
            <Project.StatusEditor
              project={project}
              update={(changes) => update(changes, Cr.updatePrompt)}
              isLoading={isLoading}
              isDisabled={isDisabled}
              updateWatch={updateWatch}
              AdditionalForms={ProjectCrStatusEditor}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ProjectShowDetails
              project={project}
              isLoading={isLoadingAndInvalid}
              isDisabled={isDisabledOrClosed}
              update={update}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-muted pt-0 pb-3">
            {isLoadingAndInvalid ? null : (
              <small>
                {`Created on ${_datetime.displayDateTime(
                  _datetime.fromStamp(project?.createDateTime),
                )}\nby `}
                <User.Link id={project?.createUserId} />
              </small>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            {isLoadingAndInvalid ? null : (
              <ButtonGroup vertical>
                <Project.ShareButton project={project} />
                <AttachmentsButton
                  attachments={attachments}
                  source={{ source: "PR", sourceId: project?.id }}
                  create={createAttachment}
                  isDisabled={isDisabled}
                  onChange={getProject}
                />
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function ProjectCrStatusEditor({ project }) {
  const canShow = project?.crs?.length === 1;
  const { cr, updateOne: updateCr } = Cr.useOne({
    id: canShow ? project?.crs?.[0].id : null,
    redirect: false,
  });

  if (!canShow) return null;

  return (
    <Row className="pt-4">
      <Col>
        <Form.Label>CR {cr?.id} Status</Form.Label>
        <Cr.StatusEditor
          project={project}
          update={updateCr}
          cr={cr}
          modalProps={{
            className: "modal2",
            backdropClassName: "modal2-backdrop",
          }}
          shouldOnlyShowEditButton
        />
      </Col>
    </Row>
  );
}
