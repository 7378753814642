import { createEntityHooks } from "../../common/entity";
import parseIntId from "../../common/functions/parseIntId";
import watchState from "./state";

export const { useEntities: useWatches } = createEntityHooks({
  entity: watchState.entity,
  actions: watchState.actions,
  parseId: (id) => {
    const nextId = parseIntId(id);
    return nextId === 0 ? undefined : nextId;
  },
});
