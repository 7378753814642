import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportCustomerStatement() {
  const { isAdmin, isChampion } = useSelector((state) => state.auth);

  if (!(isChampion || isAdmin))
    return (
      <Page
        title="Customer Statement"
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line

  return (
    <JobPage
      title="Customer Statement"
      functionName="CustomerStatement"
      Options={CustomerStatementOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.invoices || [], [job]);

  const columns = useMemo(
    () => [
      {
        id: "Invoice Number",
        Header: "Invoice #",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "equals",
        format: { alignment: { horizontal: "center" } },
      },
      {
        id: "Aging Current",
        Header: "Current",
        accessor: (inv) => (inv?.agingBucketPtr === 1 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 1
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Aging 1-30",
        Header: "1-30",
        accessor: (inv) => (inv?.agingBucketPtr === 2 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 2
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Aging 31-60",
        Header: "31-60",
        accessor: (inv) => (inv?.agingBucketPtr === 3 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 3
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Aging 61-90",
        Header: "61-90",
        accessor: (inv) => (inv?.agingBucketPtr === 4 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 4
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Aging 91-120",
        Header: "91-120",
        accessor: (inv) => (inv?.agingBucketPtr === 5 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 5
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Over 120",
        Header: "Over 120",
        accessor: (inv) => (inv?.agingBucketPtr === 6 ? inv.balance : ""),
        disableFilters: true,
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return row?.original?.agingBucketPtr === 6
              ? parseFloat(row.original.balance) + total
              : parseFloat(total) + 0;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
      {
        id: "Total",
        Header: "Total",
        accessor: "balance",
        disableFilters: true,
        dataType: "currency",
        aggregate: "sum",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} />
        ),

        Footer: ({ rows }) => {
          const calcRow = (total, row) => {
            if (row.isGrouped)
              if (row.isExpanded) return total;
              else return row.subRows.reduce(calcRow, total);
            return parseFloat(row.original.balance) + total;
          };

          const total = rows.reduce(calcRow, 0);

          return (
            <div className="text-right">
              <CurrencyFormatter value={total} />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={[
              "Invoice Number",
              "Aging Current",
              "Aging 1-30",
              "Aging 31-60",
              "Aging 61-90",
              "Aging 91-120",
              "Over 120",
              "Total",
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

/*
"Aging 31-60 ",
  "Aging 61-90",
  "Aging 91-120",
  "Aging Over 120",
*/

function CustomerStatementOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("month"),
  endDate: ed = moment().endOf("month"),
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      customerId: "",
      startDate: "",
      endDate: "",
      hiddenTime: false,
      projectType: "",
      ...options,
    },
  });

  const customerId = watch("customerId");
  const useCustomerValue = Customer.useOne({ id: customerId });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc = "Statement for " + useCustomerValue?.customer?.name;
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          hiddenTime: _.isNil(options.hiddenTime) ? false : options.hiddenTime,
          startDate: _date.stamp(options.startDate),
          endDate: _date.stamp(options.endDate),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
