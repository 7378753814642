import FileSaver from "file-saver";
import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

import { SHARK_URL } from "../../apis/shark";
import { getToken } from "../../auth/state/token";

export default function useGetPuttyKey() {
  const [loading, setLoading] = useState(false);
  const toastId = useRef(null);

  const getPuttyKey = useCallback(async (filename) => {
    setLoading(true);

    toastId.current = toast.info("Downloading " + filename + "...", {
      autoClose: false,
    });
    getUserPuttyKeyAPI()
      .then((file) => {
        FileSaver.saveAs(file, filename);
        setLoading(false);
        toast.update(toastId.current, {
          render: "Downloaded (" + filename + ")",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.update(toastId.current, {
          render: `Download Failed (${filename}): ${error}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
        console.log(error);
        return Promise.reject(error);
      });
  }, []);
  return { loading, getPuttyKey };
}

export async function getUserPuttyKeyAPI() {
  const payload = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      appId: "T3",
      command: "getServerLoginPuttyKey",
      authToken: getToken(),
    }),
  };

  // console.log(payload);

  // Step 1: start the fetch and obtain a reader
  let response = await fetch(SHARK_URL + "/services", payload);

  const type = response.headers.get("Content-Type");

  if (type === "application/json") {
    const json = await response.json();
    return Promise.reject(json.responseMessage);
  }

  return response.blob();
}
