/* eslint-disable react/jsx-key */

import FileSaver from "file-saver";
import React, { useEffect, useMemo, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import TableHeaders from "./TableHeaders";
import { sortCustom, sortNumeric, sortString } from "./tableSortTypes";
import useColumns from "./useColumns";

const sortTypes = {
  string: sortString,
  custom: sortCustom,
  numeric: sortNumeric,
};

const defaultCellProps = (cell) => {
  return cell.row.isGrouped && cell.row.isExpanded
    ? { className: "font-weight-bold " + cell.column.cellClassName }
    : {};
};

const doNothingFunction = () => {};
const emptyArray = [];

export default function Table({
  columns: columnsDef,
  layout: layoutDef = emptyArray,
  initialSort: sortByDef,
  initialGroupBy: groupByDef,
  initialExpanded: expandedDef,
  initialFilters: filtersDef,
  data,
  isLoading,
  skeletonLines = 15,
  onRowDoubleClick = doNothingFunction,
  getRowProps = doNothingFunction,
  getCellProps = defaultCellProps,
  moveFooterToTop = false,
  autoResetSort = false,
  autoResetFilters = false,
  autoResetExpanded = false,
  autoResetSelected = false,
  hasCount = false,
  label = "Records",
  getMore,
  hasMore,
  onRefresh,
  refreshLabel,
  isMultiSelect,
  onSelectedRowsChange = doNothingFunction,
  ...props
}) {
  const tableRef = useRef(null);

  const isLoadingForUseColumns = useMemo(
    () => isLoading && !data?.length,
    [data?.length, isLoading],
  );

  const { columns, hasFooter, hasFilter, initialState, setGroupBy } =
    useColumns({
      columns: columnsDef,
      layout: layoutDef,
      sortBy: sortByDef,
      filters: filtersDef,
      groupBy: groupByDef,
      expanded: expandedDef,
      select: isMultiSelect,
      isLoading: isLoadingForUseColumns,
    });

  const _data = useMemo(() => {
    const d = data || [];
    return isLoading && !d.length
      ? d.concat(Array(skeletonLines).fill({ _skeleton: true }))
      : d;
  }, [isLoading, data, skeletonLines]);

  const table = useTable(
    {
      columns,
      data: _data,
      initialState,
      autoResetSortBy: autoResetSort,
      autoResetFilters,
      autoResetExpanded,
      autoResetSelectedRows: autoResetSelected,
      sortTypes,
    },
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  const {
    getTableProps,
    rows,
    flatRows,
    prepareRow,
    footerGroups,
    setColumnOrder,
    setHiddenColumns,
    setSortBy,
    selectedFlatRows,
  } = table;

  const { hiddenColumns, columnOrder, sortBy } = initialState;
  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    setColumnOrder(columnOrder);
  }, [columnOrder, setColumnOrder]);

  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useEffect(() => {
    const getRowData = (rows, row) => {
      const rowData = row.original ? [row.original] : [];
      return rows.concat(rowData, row?.subRows.reduce(getRowData, []));
    };
    onSelectedRowsChange(selectedFlatRows?.reduce(getRowData, []));
  }, [onSelectedRowsChange, selectedFlatRows]);

  function exportTableToCSV() {
    // Add Byte Order Mark to specify UTF-8 for Excel
    // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
    // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
    var csv = ["\ufeff"];
    var rows = tableRef.current.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      if (rows[i].classList.contains("table-filters")) continue;
      let row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        const text = cols[j].innerText
          .replaceAll('"', '""')
          .replaceAll("'", "''")
          .trim();
        row.push(`"${text}"`);
      }

      csv.push(row.join(","));
    }
    const csvBlob = new Blob([csv.join("\n")], {
      type: "text/csv",
      encoding: "UTF-8",
    });
    // Download CSV file
    FileSaver.saveAs(csvBlob, "table-data.csv");
  }

  return (
    <Row>
      <Col>
        {hasCount ? (
          <Row>
            <Col>
              <CountInfoButton
                label={label}
                isLoading={isLoading}
                getMore={getMore}
                hasMore={hasMore}
                records={data?.length || 0}
                shown={flatRows.filter((r) => !r.isGrouped).length}
              />
            </Col>
            <Col>
              <RefreshButton
                onRefresh={onRefresh}
                refreshLabel={refreshLabel}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col>
            <BTable
              {...getTableProps()}
              bordered
              hover
              {...props}
              ref={tableRef}
            >
              <TableHeaders
                isLoading={isLoading}
                table={table}
                hasFilter={hasFilter}
                exportTableToCSV={exportTableToCSV}
                setGroupBy={setGroupBy}
              >
                {hasFooter && moveFooterToTop ? (
                  <TableFooters {...{ footerGroups }} />
                ) : null}
              </TableHeaders>
              <tbody>
                {rows.map((row, idx) => (
                  <TableRow
                    key={`tableRow${idx}`}
                    {...{
                      row,
                      prepareRow,
                      isLoading,
                      isMultiSelect,
                      onRowDoubleClick,
                      getRowProps,
                      getCellProps,
                    }}
                  />
                ))}
              </tbody>
              {hasFooter && !moveFooterToTop ? (
                <tfoot>
                  <TableFooters {...{ footerGroups }} />
                </tfoot>
              ) : null}
            </BTable>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function RefreshButton({ onRefresh, refreshLabel, isLoading }) {
  if (!onRefresh || isLoading) return null;
  const text = refreshLabel ? refreshLabel : "Refresh \u27F3";
  return (
    <Button
      variant="link"
      className="text-muted float-right"
      size="sm"
      onClick={onRefresh}
      title="Clear and refresh table"
    >
      {text}
    </Button>
  );
}

function CountInfoButton({
  label,
  records,
  shown,
  isLoading,
  hasMore,
  getMore,
}) {
  const text = `${records} ${label}${
    records > 0 && records !== shown ? ` (${shown} shown)` : ""
  }${isLoading ? ", loading..." : hasMore ? "+" : ""}`;

  const onClick = getMore ? getMore : null;
  const disabled = getMore ? false : true;

  return (
    <span className="text-muted float-left">
      <Button
        variant="link"
        className="text-muted"
        size="sm"
        onClick={onClick}
        disabled={disabled}
        title="Fetch more records"
      >
        {text}
      </Button>
    </span>
  );
}

function TableRow({
  row,
  getCellProps,
  isLoading,
  prepareRow,
  getRowProps,
  isMultiSelect,
  onRowDoubleClick,
}) {
  prepareRow(row);

  return (
    <tr
      onClick={() => {
        if (isMultiSelect) row.toggleRowSelected(!row.isSelected);
      }}
      onDoubleClick={() => onRowDoubleClick(row.original)}
      {...row.getRowProps()}
      {...getRowProps(row)}
    >
      {row.cells.map((cell, idx) => (
        <TableCell
          key={`tableCell${idx}`}
          {...{ row, cell, getCellProps, isLoading }}
        />
      ))}
    </tr>
  );
}

function TableCell({ row, cell, getCellProps, isLoading }) {
  const value =
    isLoading && row.original?._skeleton ? (
      <Skeleton />
    ) : cell.isGrouped ? (
      // Cell is grouped, add an expander and row count
      <>
        <span
          {...row.getToggleRowExpandedProps()}
          onClick={(e) => {
            e.stopPropagation();
            row.getToggleRowExpandedProps().onClick(e);
          }}
        >
          {row.isExpanded ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
        </span>{" "}
        {cell.render("Cell")} ({row.subRows.length})
      </>
    ) : cell.isAggregated ? (
      // Cell is aggregated, use the Aggregated renderer for cell
      cell.render("Aggregated")
    ) : // Row is grouped, but this cell isn't aggregated, show nothing
    cell.row.isGrouped ? null : (
      // Regular cell
      cell.render("Cell")
    );

  return (
    <td
      {...cell.getCellProps()}
      className={cell.column.cellClassName}
      {...getCellProps(cell)}
    >
      {value}
    </td>
  );
}

function TableFooters({ footerGroups }) {
  return (
    <>
      {footerGroups.map((group) => (
        <tr {...group.getFooterGroupProps({ className: "table-footers" })}>
          {group.headers.map((column) => (
            <th {...column.getFooterProps()} className={column.headerClassName}>
              {column.render("Footer")}
            </th>
          ))}
        </tr>
      ))}
    </>
  );
}
