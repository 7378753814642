import { bool, number, object, string } from "yup";

const ftpUserSchema = object().shape({
  tradingPartnerPrefix: string().max(15).required(),
  overridePostfix: string().max(15),
  customerId: number().required(),
  notes: string().max(500).label("Notes"),
  isInactive: bool().required().default(false),
});

export default ftpUserSchema;
