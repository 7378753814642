import _ from "lodash";
import React from "react";
import Select from "react-select";

import timeTypes from "./timeTypes";

export default function TimeTypesSelect({
  defaultValue,
  onChange,
  id,
  isClearable = true,
  isMulti = false,
  disabled = false,
}) {
  const _options = [];
  _options.push({
    label: timeTypes.internal.label,
    value: timeTypes.internal.value,
  });
  _options.push({
    label: timeTypes.cr.label,
    value: timeTypes.cr.value,
  });
  _options.push({
    label: timeTypes.onsite.label,
    value: timeTypes.onsite.value,
  });
  _options.push({
    label: timeTypes.travel.label,
    value: timeTypes.travel.value,
  });
  _options.push({
    label: timeTypes.customer.label,
    value: timeTypes.customer.value,
  });

  let value;
  if (defaultValue)
    if (isMulti) {
      value = _options.filter((o) => defaultValue.includes(o.value));
    } else {
      value = _.find(_options, ["value", defaultValue]);
    }

  return (
    <Select
      className={isMulti ? "basic-multi-select" : "basic-single"}
      classNamePrefix="select"
      isLoading={false}
      isClearable={isClearable}
      isSearchable={true}
      id={id}
      name={id}
      value={value}
      options={_options}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  );
}
