import _ from "lodash";
import React from "react";

import FieldSelect from "../../common/fields/FieldSelect";
import { useQaStatuses } from "./hooks";

export default function CrQaStatusSelect({
  value,
  isMulti = false,
  placeholder = "Select QA Status...",
  isLoading = false,
  ...props
}) {
  const { crQaStatuses } = useQaStatuses();

  const _options = crQaStatuses.map((f) => {
    return { label: `${f.emojii} ${f.description}`, value: f.id };
  });

  const _loading = isLoading;

  let valueOption = null;
  if (value)
    if (isMulti) {
      valueOption = _options.filter((o) => value.includes(o.value));
    } else {
      valueOption = _.find(_options, ["value", value]);
    }

  return (
    <FieldSelect
      isLoading={_loading}
      value={valueOption}
      options={_options}
      isMulti={isMulti}
      placeholder={placeholder}
      {...props}
    />
  );
}
