import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import contractProductCategorySchema from "./contractProductCategorySchema";

export default function ContractProductCategoryEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: contractProductCategorySchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <ContractProductCategoryForm isNew={isNew} />
    </Forms>
  );
}

function ContractProductCategoryForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="4">
              <FormsField
                name="description"
                label="Description"
                className="text-monospace"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="6">
              <FormsField.TextArea
                name="longDescription"
                label="Long Description"
                className="text-monospace"
                autoComplete="off"
                minRows={4}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
