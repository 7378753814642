import FolderDescription from "./FolderDescription";
import FolderSelect from "./FolderSelect";
import FolderTableFilter from "./FolderTableFilter";
import { useFolder, useFolders } from "./hooks";
import folderState from "./state";

const Folder = {
  // Backend API
  api: folderState.api,
  // State
  actions: folderState.actions,
  selectors: folderState.entity.selectors,
  // Hooks
  useAll: useFolders,
  useOne: useFolder,
  // Components
  Select: FolderSelect,
  Description: FolderDescription,
  TableFilter: FolderTableFilter,
};
export default Folder;
