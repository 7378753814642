import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Dropdown, Form, Row } from "react-bootstrap";

import useReinitState from "../hooks/useReinitState";
import ContextMenu from "../popovers/ContextMenu";

export default function TableHeaderContextMenu({
  show,
  isLoading,
  target,
  columns,
  onHide,
  onSortChange,
  onGroupBy,
  onExportToCSV,
}) {
  return (
    <ContextMenu
      title="Table Options"
      show={show}
      target={target}
      closeOnClick={false}
      placement="auto"
      onHide={onHide}
    >
      {isLoading ? (
        <Row>
          <Col>
            <Dropdown.Header>Loading...</Dropdown.Header>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <Dropdown.Item onClick={onExportToCSV}>
                Export to CSV
              </Dropdown.Item>
            </Col>
          </Row>
          {onGroupBy ? (
            <Row>
              <Col>
                <Dropdown.Divider />
                <Dropdown.Item onClick={onGroupBy}>
                  Toggle Group By
                </Dropdown.Item>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Dropdown.Divider />
              <TableLayout columns={columns} onChange={onSortChange} />
            </Col>
          </Row>
        </>
      )}
    </ContextMenu>
  );
}

function TableLayout({ columns: columnsStart, onChange }) {
  const [columns, setColumns] = useReinitState(
    columnsStart.filter((c) => c.id !== "tableSelect"),
  );
  const item = columnCheckListItem(columns);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      columns,
      result.source.index,
      result.destination.index,
    );

    setColumns(items);
    onChange(items.map((i) => i.id));
  }

  return (
    <>
      <Dropdown.Header>Edit Columns</Dropdown.Header>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column-toggle-list" renderClone={item}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {columns.map((column, index) => (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                >
                  {item}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

const columnCheckListItem = (columns) =>
  function ColumnCheckList(provided, snapshot, rubric) {
    const column = columns[rubric.source.index];
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Form.Check
          type="checkbox"
          id={`column-toggle-${column.id}`}
          label={column.render("Header")}
          {...column.getToggleHiddenProps()}
        />
      </div>
    );
  };
