import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import stringify from "json-stable-stringify";
import _, { camelCase, isFunction, isString } from "lodash";
import pluralize from "pluralize";

/**
 * @typedef {Object} Opts
 * @property {function} name Entity name
 * @property {function} type Entity redux action type prefix
 * @property {function} getState Function to return state slice
 * @property {Object=} adapterConfig createEntityAdapter configuration
 */

/**
 * Create entity object using Redux-Toolkit entities
 * @param {Opts} opts Options
 */
export function createEntity({
  name,
  type,
  getState: getEntityState,
  adapterConfig = { selectId: (e) => e.id },
}) {
  if (!isString(name)) {
    throw new Error(`name is required`);
  }
  if (!isString(type)) {
    throw new Error(`type is required`);
  }
  if (!isFunction(getEntityState)) {
    throw new Error(`getState is required`);
  }

  const adapter = createEntityAdapter(adapterConfig);

  const selectors = adapter.getSelectors(getEntityState);
  const searchInitialState = () => ({
    ids: [],
    lastUpdate: null,
    startAfter: null,
  });
  const selectBySearch = createSelector(
    selectors.selectEntities,
    (state, args) => {
      const search = stringify(args);
      return getEntityState(state).idsBySearch[search] || searchInitialState();
    },
    (entities, { ids, lastUpdate, startAfter }) => {
      return [_.at(entities, ids) || [], lastUpdate, startAfter];
    },
  );
  const selectByIds = createSelector(
    (state, ids) => {
      return _.at(selectors.selectEntities(state), ids) || [];
    },
    (ids) => ids,
  );

  return {
    name: camelCase(pluralize(name, 1)),
    type: type,
    adapter,
    getState: getEntityState,
    selectors: { ...selectors, selectBySearch, selectByIds },
  };
}

export default createEntity;
