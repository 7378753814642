import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import createEntityActions from "../../common/entity/createEntityActions";
import { testStateLoading } from "../../common/functions/state";
import { api } from "./api";
import { entity } from "./entity";

const entityActions = createEntityActions({ entity, api });

const createComment = createAsyncThunk(
  "crs/comments/create",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;

    const response = await api.createComment(args);
    return response;
  },
);

const updateComment = createAsyncThunk(
  "crs/comments/update",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;

    const response = await api.updateComment(args);
    return response;
  },
);

const createAttachment = createAsyncThunk(
  "crs/attachments/create",
  async (args) => {
    const response = await api.createAttachment(args);
    return response;
  },
);

const updateSetAll = createAsyncThunk(
  "crs/update/setAll",
  async ({ id, setAll }, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;
    const response = await api.updateSetAll({
      id: id,
      setAll: setAll,
    });
    return response;
  },
);

const moveOne = createAsyncThunk(
  "crs/update/moveCr",
  async ({ crId, projectId }, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;
    const response = await api.moveOne({
      crId: crId,
      projectId: projectId,
    });
    return response;
  },
);

const updateWatch = createAsyncThunk(
  "crs/watch/update",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;

    const response = await api.updateWatch(args);
    return response;
  },
);

const createQaComment = createAsyncThunk(
  "crs/qaComments/create",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;

    const response = await api.createQaComment(args);
    return response;
  },
);

const updateQaComment = createAsyncThunk(
  "crs/qaComments/update",
  async (args, { getState, requestId }) => {
    if (!testStateLoading(getState().crs.crs, requestId)) return;

    const response = await api.updateQaComment(args);
    return response;
  },
);

const setCrTableSettings = createAction("crs/setCrTableSettings");
const setCurrentCr = createAction("crs/setCurrentCr");

export const actions = {
  ...entityActions,
  createComment,
  updateComment,
  createAttachment,
  updateSetAll,
  moveOne,
  setCrTableSettings,
  setCurrentCr,
  updateWatch,
  createQaComment,
  updateQaComment,
};
