export const LICENSE_TYPES = {
  domainRegistration: {
    id: "domainRegistration",
    description: "Domain Registration",
  },
  sslCertWildcard: {
    id: "sslCertWildcard",
    description: "SSL Certification: Wildcard",
  },
  sslCertMulti: {
    id: "sslCertMulti",
    description: "SSL Certification: Multi",
  },
  sslCertSingle: {
    id: "sslCertSingle",
    description: "SSL Certification: Single",
  },
  sslLetsEncrypt: {
    id: "sslLetsEncrypt",
    description: "SSL Let's Encrypt",
  },
};
