import { createEntityReducer } from "../../common/entity";
import { buildAsyncThunkReducer } from "../../common/functions/state";
import { actions } from "./actions";
import { entity } from "./entity";

export const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(
      builder,
      actions.createAttachment,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
  },
});
