import { debounce } from "lodash";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import useTinykeys from "../hooks/useTinykeys";

export function useFormsKeys({
  debounceMs = 500,
  blocked = false,
  beforeSubmit = () => {},
  afterSubmit = (x) => x,
} = {}) {
  const {
    handleSubmit,
    formsContext: { onSubmit },
  } = useFormContext();

  const [last, setLast] = useState({ values: null, datetime: null });

  useTinykeys({
    "$mod+Enter": () => {
      const debouncedSubmit = debounce(
        () => {
          beforeSubmit();
          return handleSubmit((v) =>
            Promise.resolve(onSubmit(v)).then((data) => {
              setLast({ datetime: new Date().toISOString(), values: v });
              return afterSubmit(data);
            }),
          )();
        },
        debounceMs,
        { leading: true, trailing: false },
      );
      if (!blocked) debouncedSubmit();
    },
  });

  return last;
}
