// Takes text input
// Returns string in hhmmss military time
// Returns "INVALID" if invalid
export default function parseTime(timeInput) {
  let t = timeInput.replace(/ /g, "");
  t = t.toLowerCase();

  //Military pm HH:MM:SS
  if (RegExp(/^(1[2-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/).test(t))
    return t.replace(/:/g, "");
  //Military pm HHMMSS
  if (RegExp(/^(1[2-9]|2[0-3])[0-5][0-9][0-5][0-9]$/).test(t)) return t;
  //Military pm HH:MM
  if (RegExp(/^(1[2-9]|2[0-3])[0-5][0-9]$/).test(t))
    return t.replace(/:/g, "") + "00";
  //Military pm HHMM
  if (RegExp(/^(1[2-9]|2[0-3])[0-5][0-9]$/).test(t)) return t + "00";
  //Military pm HH
  if (RegExp(/^(1[2-9]|2[0-3])$/).test(t)) return t + "0000";
  //Military am  hh:mm:ss
  if (RegExp(/^(0[0-9]|1[0-1]):[0-5][0-9]:[0-5][0-9]$/).test(t))
    return t.replace(/:/g, "");
  //Military am  hhmmss
  if (RegExp(/^(0[0-9]|1[0-1])[0-5][0-9][0-5][0-9]$/).test(t)) return t;
  //Military am  h:mm:ss
  if (RegExp(/^[0-9]:[0-5][0-9]:[0-5][0-9]$/).test(t))
    return "0" + t.replace(/:/g, "");
  //Military am hmmss
  if (RegExp(/^[0-9][0-5][0-9][0-5][0-9]$/).test(t)) return "0" + t;
  //Military am  hh:mm
  if (RegExp(/^(0[0-9]|1[0-1]):[0-5][0-9]$/).test(t))
    return t.replace(/:/g, "") + "00";
  //Military am  hhmm
  if (RegExp(/^(0[0-9]|1[0-1])[0-5][0-9]$/).test(t)) return t + "00";
  //Military am  h:mm
  if (RegExp(/^[0-9]:[0-5][0-9]$/).test(t))
    return "0" + t.replace(/:/g, "") + "00";
  //Military am hmm
  if (RegExp(/^[0-9][0-5][0-9]$/).test(t)) return "0" + t + "00";
  //Military am  hh
  if (RegExp(/^(0[0-9]|1[0-1])$/).test(t)) return t + "0000";
  //Military am  h
  if (RegExp(/^[0-9]$/).test(t)) return "0" + t + "0000";

  const ampm = RegExp(/(am|a)$/).test(t)
    ? 0
    : RegExp(/(pm|p)$/).test(t)
    ? 12
    : null;

  if (ampm !== null) {
    let hhmmss = "";

    //Regular am  hh:mm:ss am
    if (RegExp(/^(0[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = t.replace(/:/g, "");

    //Military am  hhmmss am
    if (RegExp(/^(0[1-9]|1[0-2])[0-5][0-9][0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = t;

    //Military am  h:mm:ss am
    if (RegExp(/^[1-9]:[0-5][0-9]:[0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = "0" + t.replace(/:/g, "");

    //Military am hmmss am
    if (RegExp(/^[1-9][0-5][0-9][0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = "0" + t;

    //Military am  hh:mm am
    if (RegExp(/^(0[1-9]|1[0-2]):[0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = t.replace(/:/g, "") + "00";

    //Military am  hhmm am
    if (RegExp(/^(0[1-9]|1[0-2])[0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = t + "00";

    //Military am  h:mm am
    if (RegExp(/^[1-9]:[0-5][0-9](am|a|p|pm)$/).test(t))
      hhmmss = "0" + t.replace(/:/g, "") + "00";

    //Military am hmm am
    if (RegExp(/^[1-9][0-5][0-9](am|a|p|pm)$/).test(t)) hhmmss = "0" + t + "00";

    //Military am  hh am
    if (RegExp(/^(0[1-9]|1[0-2])(am|a|p|pm)$/).test(t)) hhmmss = t + "0000";

    //Military am  h am
    if (RegExp(/^[1-9](am|a|p|pm)$/).test(t)) hhmmss = "0" + t + "0000";

    if (hhmmss !== "") {
      hhmmss = hhmmss.replace(/am/g, "");
      hhmmss = hhmmss.replace(/pm/g, "");
      hhmmss = hhmmss.replace(/a/g, "");
      hhmmss = hhmmss.replace(/p/g, "");
      let hours = Number.parseInt(hhmmss.substring(0, 2));
      if (hours === 12) hours = 0 + ampm;
      else hours = hours + ampm;
      if (hours >= 10) return hours.toString() + hhmmss.substring(2, 6);
      else return "0" + hours.toString() + hhmmss.substring(2, 6);
    }
  }

  return "INVALID";
}
