import React from "react";

import Contact from "../contacts";
import { useCustomer } from "./hooks";

export default function CustomerContactLink({
  id,
  contactId,
  isLoading = false,
  canGet = false,
  ...props
}) {
  const { customer, loading: customerLoading } = useCustomer({ id, canGet });

  const loading = customerLoading || isLoading;

  return (
    <Contact.Link
      sourceType="Customer"
      id={contactId}
      source={customer}
      isLoading={loading}
      canGet={canGet}
      {...props}
    />
  );
}
