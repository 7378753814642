import shark from "../../apis/shark";

function getEstimatesAPI({ source, reference } = {}) {
  const args = {
    ...(source ? { source: source, reference: reference } : null),
  };
  const payload = {
    command: "getEstimates",
    ...args,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.estimates,
    searchArgs: args,
  }));
}

function getEstimateAPI({ id }) {
  const payload = {
    command: "getEstimate",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createEstimateAPI(estimate) {
  const payload = {
    command: "createEstimate",
    ...estimate,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateEstimateAPI(estimate) {
  const payload = {
    command: "updateEstimate",
    ...estimate,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getEstimatesAPI,
  getOne: getEstimateAPI,
  createOne: createEstimateAPI,
  updateOne: updateEstimateAPI,
};
