import { combineReducers } from "@reduxjs/toolkit";

import estimateStatusState from "../estimateStatuses/state";
import folderState from "../folders/state";
import priorityState from "../priorities/state";
import sourceState from "../sources/state";
import statusState from "../statuses/state";
import typeState from "../types/state";
import { actions } from "./actions";
import { api } from "./api";
import { entity } from "./entity";
import { reducer } from "./reducer";

const projectReducer = combineReducers({
  projects: reducer,
  statuses: statusState.reducer,
  priorities: priorityState.reducer,
  sources: sourceState.reducer,
  types: typeState.reducer,
  folders: folderState.reducer,
  estimateStatuses: estimateStatusState.reducer,
});

const projectState = {
  api,
  entity,
  actions,
  reducer: projectReducer,
};

export default projectState;
