import shark from "../../../apis/shark";

function getWatchesAPI() {
  const payload = {
    command: "getWatches",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.watches,
  }));
}

export const api = { getMany: getWatchesAPI };
