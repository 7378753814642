import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import CustomerLink from "../CustomerLink";
import CustomerTableFilter from "../CustomerTableFilter";
import { LICENSE_TYPES } from "./types";

export default function ThirdPartyLicenseTable({
  data,
  Components: OverrideComponents,
  layout = ["customer", "type", "expirationDate"],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },

      {
        id: "type",
        Header: "Type",
        accessor: (l) => LICENSE_TYPES[l?.type]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ row: { original: l }, value }) => (
          <Link
            className="text-reset"
            to={{ pathname: `/customers/third-party-licenses/${l?.id}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        id: "expirationDate",
        Header: "Expiration Date",
        accessor: "expirationDate",
        Cell: ({ value }) => _date.display(_date.fromStamp(value)),
      },
    ],
    [],
  );
  return (
    <Table
      hasCount
      label="Third Party Licenses"
      columns={columns}
      layout={layout}
      data={data}
      {...props}
    />
  );
}
