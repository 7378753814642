import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import Server from "../../../servers";
import S3BucketNavSidebar from "./S3BucketNavSidebar";

export default function S3BucketIndex() {
  const customerId = useSearchParams("customer");
  const { customer } = Customer.useOne({ id: customerId, canGet: false });
  const [active, setActive] = useState(true);
  const { s3Buckets, loading, reset } = Server.S3Bucket.useSearch({
    canGet: true,
    search: customerId ? { customerId } : {},
  });

  const data = useMemo(() => {
    return s3Buckets.filter((e) => {
      return e.isInactive === !active;
    });
  }, [active, s3Buckets]);

  const title = customer?.name ? `S3 Buckets: ${customer?.name}` : "S3 Buckets";

  return (
    <Page title={title} navSidebar={<S3BucketNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Server.S3Bucket.Table
        s3Buckets={data}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
