import _ from "lodash";
import moment from "moment";

import { _datetime } from "./dates";

// Take an array of timers from a time object
// Calculate the time in T3 hours as well as formatted versions
export function timerCalculation(dateTime, timersArray) {
  let totalSeconds = 0;

  if (timersArray !== null && !_.isUndefined(timersArray)) {
    for (let i = 0; i < timersArray.length; i++) {
      const start = _datetime.fromMomentDateStringTime(
        dateTime,
        timersArray[i].startTime,
      );
      const end =
        timersArray[i].endTime === ""
          ? moment()
          : _datetime.fromMomentDateStringTime(
              dateTime,
              timersArray[i].endTime,
            );
      totalSeconds = totalSeconds + end.diff(start, "seconds");
    }
  }

  return formatTotalSeconds(totalSeconds);
}

export function formatTotalSeconds(totalSeconds) {
  const h = Math.floor(totalSeconds / (60 * 60));
  const m = Math.floor(totalSeconds / 60) - h * 60;
  const s = totalSeconds - h * 60 * 60 - m * 60;

  // T2 will round up to another qurarter hour. If its 36 seconds
  //or more after the break even point.
  // example: 15 minutes 35 seconds => 0.25
  //          15 minutes 36 seconds => 0.50
  const roundedHours =
    s >= 36
      ? Math.ceil(totalSeconds / (15 * 60)) / 4
      : Math.ceil((totalSeconds - s) / (15 * 60)) / 4;

  let hhmmss = [h, m > 9 ? m : "0" + m, s > 9 ? s : "0" + s].join(":");

  let hhmm = [h, m > 9 ? m : h ? "0" + m : m || "0"].filter(Boolean).join(":");

  return {
    roundedHours: roundedHours,
    hhmmss: hhmmss,
    hhmm: hhmm,
    totalSeconds: totalSeconds,
  };
}
