import React, { useMemo, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import queryToString from "../common/functions/queryToString";
import ProjectFilters from "./ProjectFilters";
import ProjectTable from "./ProjectTable";
import useProjectsByFilter from "./useProjectsByFilter";

export default function ProjectSearch({
  defaultCustomerId,
  defaultOwnerId,
  onSelect,
}) {
  const [defaultFilter, initialSearch] = useMemo(() => {
    let initialSearch = false;
    let defaultFilter = { folderId: "CURRENT" };
    if (defaultCustomerId) {
      defaultFilter.customers = [defaultCustomerId];
      initialSearch = true;
    }
    if (defaultOwnerId) {
      defaultFilter.owners = [defaultOwnerId];
      initialSearch = true;
    }
    return [defaultFilter, initialSearch];
  }, [defaultCustomerId, defaultOwnerId]);

  const [filter, setFilter] = useState(defaultFilter);
  const [search, setSearch] = useState(initialSearch);

  const { projects, loading, hasMore, getMore } = useProjectsByFilter({
    filter,
    blocked: !search,
  });

  const user = useSelector((state) => state.auth.user);

  // we want to only send records once
  // the refresh functionality doesn't keep in line the default
  // sort that I want to do here
  const tableData = useMemo(() => {
    return projects?.sort((a, b) => {
      const aval = a.owner === user ? " " : a.owner;
      const bval = b.owner === user ? " " : b.owner;
      if (aval > bval) return 1;
      if (aval < bval) return -1;
      return 0;
    });
  }, [projects, user]);

  return (
    <>
      <Row>
        <Col>
          <Collapse in={!search}>
            <div>
              <ProjectFilters
                values={filter}
                onSubmit={(f) => {
                  setFilter(f);
                  setSearch(true);
                }}
              />
            </div>
          </Collapse>
          <Collapse in={search}>
            <div>
              <Row className="justify-content-between">
                <Col xs="auto">
                  <p className="lead">{queryToString(filter)}</p>
                </Col>
                <Col xs="auto">
                  <Button variant="link" onClick={() => setSearch(false)}>
                    Edit Search
                  </Button>
                </Col>
              </Row>
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse in={search}>
            <div>
              <ProjectTable
                projects={tableData}
                isLoading={loading}
                hasMore={hasMore}
                getMore={getMore}
                onSelect={onSelect}
                layout={[
                  "id",
                  "modifyDate",
                  "owner",
                  "status",
                  "type",
                  "synopsis",
                ]}
              />
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}
