import React from "react";
import { Link } from "react-router-dom";

export default function JiraProjectLink({ id, children }) {
  return (
    <Link
      className="text-reset"
      to={{ pathname: `/customers/jira-projects/${id}` }}
    >
      {children || id}
    </Link>
  );
}
