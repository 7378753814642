import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router";

import { _datetime } from "../../common/functions/dates";
import NavSidebarItem from "../../common/navs/NavSidebarItem";
import Page from "../../common/pages/Page";
import useJob from "./useJob";

export default function JobPage(props) {
  let { path } = useRouteMatch();
  const pageProps = {
    path,
    ...props,
  };
  return (
    <Switch>
      <Route path={`${path}/:id/options`}>
        <JobPageContent {...pageProps} doOptions />
      </Route>
      <Route path={`${path}/:id`}>
        <JobPageContent {...pageProps} />
      </Route>
      <Route>
        <JobPageContent {...pageProps} />
      </Route>
    </Switch>
  );
}

function JobPageContent({
  title,
  functionName,
  path,
  doOptions = false,
  Options,
  defaultOptions = {},
  Report,
  NavSidebar,
  tableOfContents,
}) {
  const jobId = parseInt(useParams().id);
  const loggedInUser = useSelector((state) => state.auth.user);
  const { job, loading, submitJob, error, getJob } = useJob({
    id: jobId,
    functionName: functionName,
    redirect: path,
  });

  useEffect(() => {
    if (job?.isDone && !job?.output && !loading) getJob();
  }, [job, getJob, loading]);

  const jobString = `Report ${jobId || "N/A"}: ${
    job
      ? job?.isWaiting
        ? "Waiting..."
        : job?.isInProgress
        ? "Running..."
        : `${
            job?.isErrored ? "Failed" : "Completed"
          } @ ${_datetime.displayDateTime(_datetime.fromStamp(job?.dateTime))}`
      : "Report not found"
  }`;

  const navSidebarProps = { id: jobId, NavSidebar, path, title };
  const pageProps = {
    title: `${title}: ${jobId ? `${jobId} - ${job?.description}` : "New"}`,
    heading: title,
    navSidebar: <JobNavSidebar {...navSidebarProps} />,
    tableOfContents: tableOfContents,
  };
  if (!jobId || doOptions)
    return (
      <Page {...pageProps} lead={`Options`}>
        <Row className="pb-4 pt-0 mt-n3">
          <Col>
            <small>{jobId ? jobString : "Report: New"}</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              submitJob={submitJob}
              job={job || {}}
              {...defaultOptions}
            />
          </Col>
        </Row>
      </Page>
    );

  return (
    <Page {...pageProps} lead={job?.description}>
      <Row className="pb-4 pt-0 mt-n3">
        <Col>
          <small>{jobId ? jobString : "Report: New"}</small>
        </Col>
      </Row>
      <Row>
        <Col>
          {job ? (
            loggedInUser !== job?.userId ? (
              "Unauthorized"
            ) : (
              <Report
                job={job}
                isLoading={loading || (!job?.isDone && !job?.isErrored)}
              />
            )
          ) : (
            error?.message || "Failed to fetch report"
          )}
        </Col>
      </Row>
    </Page>
  );
}

function JobNavSidebar({ title, id, NavSidebar, path }) {
  return (
    <NavSidebar>
      <NavSidebarItem to={`${path}`}>
        <strong title="New Report">{title}</strong>
      </NavSidebarItem>
      {id ? (
        [
          <NavSidebarItem to={`${path}/${id}/options`} key="options">
            Options
          </NavSidebarItem>,
          <NavSidebarItem to={`${path}/${id}`} key="results">
            Report
          </NavSidebarItem>,
        ]
      ) : (
        <NavSidebarItem to={`${path}`} key="options">
          Options
        </NavSidebarItem>
      )}
    </NavSidebar>
  );
}
