import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import { createAttachmentAPI } from "../../apis/attachments";
import shark from "../../apis/shark";
import { _date } from "../../common/functions/dates";
import { getChangesExcludingStamps } from "../../common/functions/state/reducers";
import toastMessage from "../../common/toasts/toastMessage";
import toastPrompt from "../../common/toasts/toastPrompt";
import Customer from "../../customers";
import updateProjectPrompt from "../../projects/updateProjectPrompt";
import { isClosed } from "../crSchema";

function getCrsAPI({
  filter: { folderId, ...filter } = {},
  maxRecs,
  startAfter = "",
}) {
  const payload = {
    ...filter,
    command: "getCrs",
    maxRecs: maxRecs,
    startAfter: startAfter || "",
    transferredFolder: folderId,
  };
  return shark.post(payload).then(({ responseData = {} }) => ({
    data: responseData.crs,
    startAfter: responseData.startAfter,
    searchArgs: { folderId, ...filter },
  }));
}

function getCrAPI(
  { id, logActivity, includeLogs, fetch = true },
  { getState },
  { selectors },
) {
  const payload = {
    command: "getCr",
    id: id,
    logActivity,
    includeLogs: includeLogs,
  };
  if (fetch === false || fetch === "auto") {
    const cr = selectors.selectById(getState(), id);
    if (cr || fetch === false)
      return {
        data: cr,
        updateState: false,
      };
  }
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createCrAPI(cr) {
  function postProcess(nextCr) {
    toast.success(`CR ${nextCr.id} created`);
    return nextCr;
  }
  const payload = {
    command: "createCr",
    cr: cr,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: postProcess(responseData) }));
}

function updateCrAPI(
  { id, data: crFields, shouldPromptToUpdateProject = true },
  { getState, dispatch },
  { selectors },
) {
  if (!id) return;
  const currentCr = selectors.selectById(getState(), id);

  function postProcess(nextCr) {
    toast.success(`CR ${id} saved`);

    if (
      nextCr.overrideTypeId === "DE" &&
      nextCr.overrideTypeId !== currentCr?.overrideTypeId
    ) {
      const customer = Customer.selectors.selectById(
        getState(),
        nextCr?.customers?.[0]?.id,
      );
      if (
        customer?.warrantyExpirationDate &&
        _date.fromStamp(customer?.warrantyExpirationDate) < moment()
      )
        toastMessage(
          {
            title: "CR updated to Defect",
            message: `${customer.name} Warranty Expired\n${_date.display(
              customer?.warrantyExpirationDate,
            )}`,
          },
          { type: "warning", autoClose: 15000 },
        );
    }
    if (
      isClosed(nextCr?.statusId) &&
      (!isClosed(currentCr?.statusId) ||
        currentCr?.notificationTypeId !== nextCr?.notificationTypeId)
    ) {
      if (nextCr.notificationTypeId === "C") {
        toastPrompt({
          message:
            "CR documentation required. Please complete form to assist with documentation.",
          confirm: "Open Form",
          cancel: "Ignore",
          onConfirm: () => {
            window.open(
              `https://docs.google.com/forms/d/e/1FAIpQLSdjRKwxe_3D9xIbVcPzDWcgbj-5-yvrqwdtTwLRNTYR35I36A/viewform?entry.1462877314=${nextCr.id}`,
              "_blank",
            );
          },
        });
      }
    }

    if (shouldPromptToUpdateProject) {
      const projectId = nextCr.projectId;
      const compareAndProjectPrompt = (message, properties) => {
        const compareProperties = (current) => {
          let changes = {};
          properties.forEach((property) => {
            if (nextCr[property] !== current?.[property]) {
              changes[property] = nextCr[property];
            }
          });
          if (_.isEmpty(changes)) return null;
          else return changes;
        };
        const projectChanges = compareProperties(currentCr);
        if (projectChanges)
          updateProjectPrompt(
            {
              id: projectId,
              message: message,
              dispatch,
              test: (p) => p?.crs?.length === 1 && compareProperties(p),
              changes: {
                noemail: true,
                ...projectChanges,
              },
            },
            { toastId: `updateCrAPI-${id}-${properties.join("-")}` },
          );
      };
      compareAndProjectPrompt("Update Project owner & group", [
        "owner",
        "groupId",
      ]);
      compareAndProjectPrompt("Update Project synopsis", ["synopsis"]);

      const compareTypes = (typeId) =>
        nextCr.overrideTypeId && nextCr.overrideTypeId !== typeId;
      if (compareTypes(currentCr?.overrideTypeId))
        updateProjectPrompt(
          {
            id: projectId,
            message: "Update Project type",
            dispatch,
            test: (project) =>
              project?.crs?.length === 1 && compareTypes(project?.typeId),
            changes: {
              noemail: true,
              typeId: nextCr.overrideTypeId,
            },
          },
          { toastId: `updateCrAPI-${id}-typeId` },
        );
    }

    return nextCr;
  }

  const { createDateTime, modifyDateTime } = currentCr;
  //payload will represent the new settings, check if they are the different
  let changes = getChangesExcludingStamps(crFields, currentCr);
  if (_.isEmpty(changes)) {
    toast.info(`CR ${id} No changes needed`);
    return { data: currentCr };
  }
  const payload = {
    command: "updateCr",
    id,
    cr: { ...changes, createDateTime, modifyDateTime },
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: postProcess(responseData) }));
}

function updateCrSetAllAPI({ id, setAll }) {
  const payload = {
    command: "updateCrSetAll",
    crId: id,
    ...setAll,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function moveCrAPI({ crId, projectId }) {
  const payload = {
    command: "moveCr",
    crId: crId,
    projectId: projectId,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createCrCommentAPI({ cr, title, text }) {
  const payload = {
    command: "createCrComment",
    cr: cr,
    title: title,
    text: text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function updateCrCommentAPI({ cr, title, text, id }) {
  const payload = {
    command: "updateCrComment",
    cr: cr,
    id: id,
    title: title,
    text: text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createCrAttachmentAPI(args) {
  return createAttachmentAPI({ command: "createCrAttachment", ...args });
}

function updateCrWatchAPI({ id, isWatching }) {
  const payload = {
    command: "updateCrWatch",
    id,
    isWatching,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function createCrQaCommentAPI({ cr, title, text }) {
  const payload = {
    command: "createCrQaComment",
    cr: cr,
    title: title,
    text: text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

function updateCrQaCommentAPI({ cr, title, text, id }) {
  const payload = {
    command: "updateCrQaComment",
    cr: cr,
    id: id,
    title: title,
    text: text,
  };
  return shark.post(payload).then((r) => ({ data: r.responseData }));
}

export const api = {
  getMany: getCrsAPI,
  getOne: getCrAPI,
  createOne: createCrAPI,
  updateOne: updateCrAPI,
  updateSetAll: updateCrSetAllAPI,
  moveOne: moveCrAPI,
  createComment: createCrCommentAPI,
  updateComment: updateCrCommentAPI,
  createAttachment: createCrAttachmentAPI,
  updateWatch: updateCrWatchAPI,
  createQaComment: createCrQaCommentAPI,
  updateQaComment: updateCrQaCommentAPI,
};
