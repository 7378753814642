import React from "react";

import Page from "../../common/pages/Page";
import EdiNavSidebar from "./EdiNavSidebar";

export default function EdiIndex() {
  return (
    <div>
      <Page title="EDI management" navSidebar={<EdiNavSidebar />}></Page>
    </div>
  );
}
