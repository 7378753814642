import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DetailSection from "../../common/details/DetailSection";
import FieldInput from "../../common/fields/FieldInput";
import FieldSelect from "../../common/fields/FieldSelect";
import useGetPuttyKey from "../../common/hooks/useGetPuttyKey";
import Page from "../../common/pages/Page";
import Cr from "../../crs";
import Project from "../../projects";
import { settingsActions } from "../../settings/state";
import useSettings from "../../settings/useSettings";
import User from "../../users";
import SettingsNavSidebar from "./SettingsNavSidebar";

const timeZoneOptions = [
  { label: "America/New_York", value: "America/New_York" },
  { label: "America/Chicago", value: "America/Chicago" },
  { label: "America/Denver", value: "America/Denver" },
  { label: "America/Phoenix", value: "America/Phoenix" },
  { label: "America/Los_Angeles", value: "America/Los_Angeles" },
  { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
];

export default function Settings() {
  const { settings: settingsServer } = useSettings({ canGet: true });
  const [settings, setSettings] = useState(settingsServer);

  const dispatch = useDispatch();

  useEffect(() => {
    setSettings(settingsServer);
  }, [settingsServer]);

  useEffect(() => {
    dispatch(settingsActions.updateSettings(settings));
  }, [settings, dispatch]);

  const handleChange = useCallback(
    (field, value) => {
      setSettings({
        ...settings,
        [field]: _.isNull(value) || _.isUndefined(value) ? "" : value,
      });
    },
    [settings],
  );

  return (
    <Page title="Settings" navSidebar={<SettingsNavSidebar />}>
      <DetailSection title="General" key="general-section">
        <SettingsGeneral settings={settings} handleChange={handleChange} />
      </DetailSection>
      <DetailSection title="Defaults" key="defaults-section">
        <SettingsDefaults settings={settings} handleChange={handleChange} />
      </DetailSection>
      <DetailSection title="Time" key="time-section">
        <SettingsTime settings={settings} handleChange={handleChange} />
      </DetailSection>
      <DetailSection title="Server Login" key="server-login-section">
        <ServerLogin settings={settings} handleChange={handleChange} />
      </DetailSection>
    </Page>
  );
}

function SettingsGeneral({ settings, handleChange }) {
  const { isManager } = useSelector((state) => state.auth);

  let timeZoneOption = null;
  if (settings?.googleCalendarTimeZone)
    timeZoneOption = _.find(timeZoneOptions, [
      "value",
      settings?.googleCalendarTimeZone,
    ]);

  return (
    <>
      <Form.Group>
        <Form.Label>Customer Sorting</Form.Label>
        <Form.Control
          id="customer-sort"
          name="customer-sort"
          as="select"
          onChange={(e) => handleChange("customerSort", e.target.value)}
          value={settings.customerSort}
        >
          <option value="S">Sort Name</option>
          <option value="A">Alphabetical</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <FieldInput
          label="Specific Gmail Account to use for Email links"
          id="gmailEmailFromAccount"
          onChange={(value) => handleChange("gmailEmailFromAccount", value)}
          value={settings?.gmailEmailFromAccount}
        ></FieldInput>
      </Form.Group>
      <Form.Group>
        <FieldSelect
          label="Google Calendar Time Zone"
          id="googleCalendarTimeZone"
          onChange={(value) => handleChange("googleCalendarTimeZone", value)}
          value={timeZoneOption}
          options={timeZoneOptions}
          placeholder={"Select your time zone"}
        ></FieldSelect>
      </Form.Group>
      <Form.Group>
        <Form.Label>Experimental Features</Form.Label>
        <Form.Control
          id="experimentalFeatures"
          name="experimentalFeatures"
          as="select"
          onChange={(e) => handleChange("experimentalFeatures", e.target.value)}
          value={settings.experimentalFeatures || "N"}
        >
          <option value="N">No</option>
          <option value="B">Beta</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label className="h5">New Project/CR Settings</Form.Label>
        <Form.Group>
          <Form.Label>Owner</Form.Label>
          <User.Select
            id="default-owner"
            value={settings?.defaultOwner}
            onChange={(value) => handleChange("defaultOwner", value)}
            pseudo={true}
            isClearable={true}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Group</Form.Label>
          <User.Group.Select
            id="default-group-id"
            value={settings?.defaultGroupId}
            onChange={(value) => handleChange("defaultGroupId", value)}
            isClearable={true}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            id="email-on-owner-create"
            label="Send CR/Project notification email when you are the owner"
            checked={settings?.emailOnOwnerCreate}
            onChange={(e) =>
              handleChange("emailOnOwnerCreate", e.target.checked)
            }
          />
        </Form.Group>

        {isManager ? (
          <Form.Group>
            <Form.Check
              id="email-on-new-direct"
              label="Send emails when your employees are directly assigned to new issues"
              checked={settings?.emailOnNewDirect}
              onChange={(e) =>
                handleChange("emailOnNewDirect", e.target.checked)
              }
            />
          </Form.Group>
        ) : null}
      </Form.Group>
    </>
  );
}

function SettingsDefaults({ settings, handleChange }) {
  return (
    <>
      <Form.Group>
        <Form.Label>Default CR Filter</Form.Label>
        <Cr.Filter.Select
          id="cr-filterId"
          value={settings?.crFilterId}
          onChange={(value) => handleChange("crFilterId", value)}
          isClearable={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="h5">Default Project Filter</Form.Label>
        <Form.Group>
          <Form.Label>Owner</Form.Label>
          <User.Select
            id="project-filter-owner"
            value={settings?.projectFilterOwner}
            onChange={(value) => handleChange("projectFilterOwner", value)}
            pseudo={true}
            isClearable={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <Project.Status.Select
            id="project-filter-status-id"
            value={settings?.projectFilterStatusId}
            onChange={(value) => handleChange("projectFilterStatusId", value)}
            isClearable={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Champion</Form.Label>
          <User.Select
            id="project-filter-champion-id"
            value={settings?.projectFilterChampionId}
            onChange={(value) => handleChange("projectFilterChampionId", value)}
            pseudo={false}
            isChampion={true}
            isClearable={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Group</Form.Label>
          <User.Group.Select
            id="project-filter-group-id"
            value={settings?.projectFilterGroupId}
            onChange={(value) => handleChange("projectFilterGroupId", value)}
            isClearable={true}
          />
        </Form.Group>
      </Form.Group>
    </>
  );
}

function SettingsTime({ settings, handleChange }) {
  return (
    <>
      <Form.Group>
        <FieldInput
          label="Monthly Billable Minimum (Hours)"
          id="min-billable-hours"
          type="number"
          onChange={(value) => handleChange("minBillableHours", value)}
          value={settings?.minBillableHours}
          min="0"
          max="999"
        ></FieldInput>
      </Form.Group>
      <Form.Group>
        <FieldInput
          label="Monthly Billable Minimum (Dollars)"
          id="min-billable-dollars"
          type="number"
          onChange={(value) => handleChange("minBillableDollars", value)}
          value={settings?.minBillableDollars}
          min="0"
          max="99999"
        ></FieldInput>
      </Form.Group>
    </>
  );
}

function ServerLogin() {
  const { user: id } = useSelector((state) => state.auth);
  const { user } = User.useOne({
    id,
    canGet: true,
  });
  const serverLogin = user?.serverLogin || "";

  const { getPuttyKey } = useGetPuttyKey();
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <User.ServerLoginEditor values={user} />
          </Col>
        </Row>
        <Row>
          <Col>
            {serverLogin ? (
              <Button
                variant="secondary"
                onClick={() => getPuttyKey(`${serverLogin}.ppk`)}
              >
                Download Putty Key
              </Button>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
