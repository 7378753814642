import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "awsRegions",
  type: "servers/awsRegions",
  getState: (state) => state.servers.awsRegions,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const awsRegionState = {
  api,
  actions,
  reducer,
  entity,
};

export default awsRegionState;
