import React from "react";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function CodeTestShow() {
  const id = useParams().id;
  const { codeTest, loading } = Dbc.CodeTest.useOne({
    id: id,
    canGet: true,
  });

  return (
    <Page title="DBC Code Test" navSidebar={<DbcNavSidebar />}>
      {codeTest ? (
        <>
          <Dbc.CodeTest.Editor values={codeTest} isLoading={loading} />
          <Dbc.CodeTest.FailuresTable
            values={codeTest?.failures}
            isLoading={loading}
          />
        </>
      ) : (
        <h4>Code Test not found.</h4>
      )}
    </Page>
  );
}
