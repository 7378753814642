import { createEntityHooks } from "../common/entity";
import crState from "./state";

const { useEntities: useCrs } = createEntityHooks({
  entity: crState.entity,
  actions: {
    getMany: crState.actions.getMany,
    reset: crState.actions.reset,
  },
});

export default useCrs;
