import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../common/entity";
import contractTypesState from "../contactTypes/state";
import { api } from "./api";
import { contactsPersistConfig } from "./persist";

const entity = createEntity({
  name: "contact",
  type: "contacts",
  getState: (state) => state.contacts.contacts,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  persistConfig: contactsPersistConfig,
});

const contactReducer = combineReducers({
  contacts: reducer,
  contactTypes: contractTypesState.reducer,
});

const contactState = {
  api,
  actions,
  reducer: contactReducer,
  entity,
};

export default contactState;
