import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../common/hooks/useRedirect";
import Page from "../../common/pages/Page";
import Contract from "../../contracts";
import ContractNavSidebar from "./ContractNavSidebar";

export default function ContractNew() {
  const { createOne } = Contract.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin))
    return (
      <Page
        title="New Contract"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line no-constant-condition
  else if (true) {
    return (
      <Page
        title="New Contract"
        navSidebar={<ContractNavSidebar />}
        lead="This is not supported yet"
      />
    );
  } else {
    return (
      <Page title={"New Contract"} navSidebar={<ContractNavSidebar />}>
        <Contract.Editor
          values={{ isActive: true }}
          onSubmit={(server) =>
            createOne(server).then(({ id }) => setRedirect(`/contracts/${id}`))
          }
        />
      </Page>
    );
  }
}
