import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { timeActions } from "./state";

export default function useTimeStats({
  key,
  blocked = false,
  startDate,
  endDate,
  userId,
}) {
  const stats = useSelector(
    (state) => state.times.times.stats[key],
    shallowEqual,
  );
  const loading = useSelector((state) => state.times.times.requests.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (blocked && startDate && endDate) return;
    dispatch(timeActions.getStats({ key, startDate, endDate, userId }));
  }, [dispatch, blocked, key, startDate, endDate, userId]);

  return { stats, loading };
}
