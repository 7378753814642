import React from "react";

import Table from "../common/tables/Table";
import getCrPriorityClassName from "./getCrPriorityClassName";
import useCrTableColumns from "./useCrTableColumns";

export default function CrTable({
  crs,
  selectRow,
  crChosen = () => {},
  layout,
  Components,
  ...props
}) {
  const onRowDoubleClick = (rowInfo) => {
    if (rowInfo.id != null) crChosen(rowInfo.id);
  };

  const onRowDoubleClickProp = selectRow
    ? { onRowDoubleClick: onRowDoubleClick }
    : {};

  const { columns, defaultLayout } = useCrTableColumns({
    selectRow,
    Components,
  });

  return (
    <Table
      hasCount
      label="CRs"
      layout={layout || defaultLayout}
      columns={columns}
      data={crs}
      getRowProps={(row) => {
        if (!row.original) return {};
        const className = getCrPriorityClassName(row.original);
        if (!className) return {};
        return { className: className };
      }}
      {...onRowDoubleClickProp}
      {...props}
      moveFooterToTop
    />
  );
}
