import React from "react";

import Page from "../../common/pages/Page";
import Project from "../../projects";
import ProjectNavSidebar from "./ProjectNavSidebar";

export default function ProjectNew() {
  return (
    <Page title={`New Project`} navSidebar={<ProjectNavSidebar />}>
      <Project.New />
    </Page>
  );
}
