import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { _date } from "../common/functions/dates";
import TimeModifyModal from "../time/TimeModifyModal";
import UserLink from "../users/UserLink";

function ApprovalsSkipped({ data, loading, project = "" }) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showTimeId, setShowTimeId] = useState();
  const [, setTimeRecordsChanged] = useState(false);

  /*
  const { approvals, loading, reset } = useApprovals({
    customer: paramsCustomer,
    projectCategory: "O",
  });
  */

  const popupTimeModal = (timeId) => {
    setShowModifyModal(true);
    setShowTimeId(timeId);
  };

  const handleChange = () => {
    setShowModifyModal(false);
    setTimeRecordsChanged(true);
  };

  let activeLines = [];
  let foundMode = false;
  let currentSectionPtr = -1;
  if (project !== "" && project !== null)
    for (let i = 0; i < data?.length; i++) {
      if (data[i].type === "section") {
        if (foundMode === true) {
          foundMode = false;
        }
        currentSectionPtr = i;
      } else if (data[i].type === "project") {
        if (project === data[i].project) {
          foundMode = true;
          if (currentSectionPtr !== -1)
            activeLines.push(data[currentSectionPtr]);
        } else {
          foundMode = false;
        }
      }
      if (foundMode) activeLines.push(data[i]);
    }
  else activeLines = data;

  return (
    <>
      <TimeModifyModal
        id={showTimeId}
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        onSave={handleChange}
        onDelete={handleChange}
        onCancel={() => setShowModifyModal(false)}
      />
      <Row>
        <Col md={6} className="font-weight-bold">
          Description
        </Col>
        <Col md={1} className="text-right font-weight-bold">
          Hours
        </Col>
        <Col md={2} className="text-right font-weight-bold">
          Total
        </Col>
        <Col md={1} className="text-center font-weight-bold">
          Date
        </Col>
        <Col md={2} className="text-center font-weight-bold">
          Reason
        </Col>
      </Row>
      <Row className="bg-dark text-light text-center">
        <Col>Skipped charges</Col>
      </Row>
      {loading ? (
        <h3>loading</h3>
      ) : (
        activeLines?.map((x, idx) => (
          <SkippedLine
            key={`skipped${idx}`}
            data={x}
            popupTimeModal={popupTimeModal}
          />
        ))
      )}
    </>
  );
}

function SkippedLine({ data: x, popupTimeModal }) {
  switch (x.type) {
    case "project":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left pl-1">
              {"Project "}
              <Link
                className="text-decoration-none"
                to={{ pathname: `/projects/${x.project}` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x.project}
              </Link>
            </Col>
          </Row>
          <Row className="table-secondary">
            <Col className="text-left pl-1">{x.synopsis}</Col>
          </Row>
        </>
      );
    case "cr":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left">
              {"CR "}
              <Link
                className="text-decoration-none"
                to={{ pathname: `/crs/${x.cr}` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {x.cr}
              </Link>
            </Col>
          </Row>
          <Row className="table-secondary">
            <Col className="text-left">{x.synopsis}</Col>
          </Row>
        </>
      );
    case "user":
      return (
        <>
          <Row className="table-secondary font-weight-bold">
            <Col className="text-left">
              <UserLink id={x.userId} />
            </Col>
          </Row>
        </>
      );
    case "T":
      return (
        <Row className={"pb-2 font-italic"}>
          <Col md={6} className="pl-4">
            <Link
              className="text-decoration-none"
              to={{ pathname: `/time/${x.keyfield}/edit` }}
              onClick={(e) => {
                e.preventDefault();
                popupTimeModal(x.keyfield);
              }}
            >
              {x.description}
            </Link>
          </Col>
          <Col md={1} className="text-right">
            {x.hours}
          </Col>
          <Col md={2} className="text-right">
            {x.total}
          </Col>
          <Col md={1} className="text-center">
            {_date.display(_date.fromStamp(x.date))}
          </Col>
          <Col md={2} className="text-center">
            {x.reason}
          </Col>
        </Row>
      );
    case "C":
      return (
        <Row className={"pb-2 font-italic"}>
          <Col md={6} className="pl-4">
            {"Charge: "}
            {x.description}
          </Col>
          <Col md={1} className="text-right"></Col>
          <Col md={2} className="text-right">
            {x.total}
          </Col>
          <Col md={1} className="text-center">
            {_date.display(_date.fromStamp(x.date))}
          </Col>
          <Col md={2} className="text-center">
            {x.reason}
          </Col>
        </Row>
      );
    default:
  }
  return <></>;
}

export default React.memo(ApprovalsSkipped);
