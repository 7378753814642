import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getProjectTypes" }).then((r) => {
      return { data: r.responseData.projectTypes };
    });
  },
};

const entity = createEntity({
  name: "type",
  type: "projects/types",
  getState: (state) => state.projects.types,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const typeState = {
  api,
  entity,
  actions,
  reducer,
};

export default typeState;
