import React from "react";
import { Link } from "react-router-dom";

import { useCustomer } from "./hooks";

export default function CustomerLink({ id }) {
  const { customer } = useCustomer({ id });

  if (!customer?.id) return <span> </span>;

  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Link className="text-reset" to={{ pathname: `/customers/${id}` }}>
        {customer?.name}
      </Link>
    </span>
  );
}
