import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import TaxProductEdit from "./TaxProductEdit";
import TaxProductIndex from "./TaxProductIndex";
import TaxProductNew from "./TaxProductNew";

export default function TaxProductsPages() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <TaxProductNew />
      </Route>
      <Route path={`${path}/:id`}>
        <TaxProductEdit />
      </Route>
      <Route exact path={`${path}`}>
        <TaxProductIndex />
      </Route>
    </Switch>
  );
}
