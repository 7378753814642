import React, { useMemo } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import Page from "../../common/pages/Page";
//import { CurrencyFormatter, PercentFormatter } from "../../common/formatters";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportCustomerProducts() {
  const { isManager, isChampion, isAdmin } = useSelector((state) => state.auth);

  if (!(isManager || isChampion || isAdmin))
    return (
      <Page
        title="Customer Products"
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line

  return (
    <JobPage
      title="Customer Products"
      functionName="CustomerProducts"
      Options={CustomerProductsOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const products = useMemo(() => job?.output?.products || [], [job]);
  const details = useMemo(() => job?.output?.details || [], [job]);
  const busTypes = useMemo(() => job?.output?.busTypes || [], [job]);

  return (
    <>
      <Tabs defaultActiveKey="products">
        <Tab eventKey="products" title="Products" mountOnEnter={true}>
          <Products data={products} isLoading={isLoading} />
        </Tab>
        <Tab eventKey="busTypes" title="Business Types breakdown">
          <BusTypes data={busTypes} isLoading={isLoading} />
        </Tab>
        <Tab eventKey="details" title="Details">
          <Detail data={details} isLoading={isLoading} />
        </Tab>
      </Tabs>
    </>
  );
  //initialGroupBy={groupBy}
}

function Detail({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: ({ value }) => <Customer.Name id={value} />,
      },
      {
        id: "Business Type",
        Header: "Business Type",
        accessor: "busType",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Product",
        Header: "Product",
        accessor: "product",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "License Type",
        Header: "License Type",
        accessor: "licenseType",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "License Count",
        Header: "License Count",
        accessor: "licenseCount",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 0 } },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
        />
      </Col>
    </Row>
  );
}

function Products({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        id: "Product",
        Header: "Product",
        accessor: "product",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "License Type",
        Header: "License Type",
        accessor: "licenseType",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "License Count",
        Header: "License Count",
        accessor: "licenseCount",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 0 } },
      },
      {
        id: "Customer Count",
        Header: "Customer Count",
        accessor: "customerCount",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 0 } },
      },
      {
        id: "Customer Percent",
        Header: "Customer Percent",
        accessor: "customerPercent",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 2 } },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
        />
      </Col>
    </Row>
  );
}

function BusTypes({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        id: "Business Type",
        Header: "Business Type",
        accessor: "busType",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Customer Count",
        Header: "Customer Count",
        accessor: "customerCount",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 0 } },
      },
      {
        id: "Customer Percent",
        Header: "Customer Percent",
        accessor: "customerPercent",
        Filter: SelectColumnFilter,
        filter: "equals",
        format: { number: { decimals: 2 } },
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          moveFooterToTop
        />
      </Col>
    </Row>
  );
}

function CustomerProductsOptions({ submitJob, job }) {
  const { options = {} } = job;
  const { handleSubmit } = useForm({
    mode: "onTouched",
    defaultValues: {
      ...options,
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        submitJob({
          ...options,
        });
      })}
    >
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
