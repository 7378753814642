import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../common/functions/state";
import { actions as currentTimerActions } from "../currentTimer/state/slices";
import {
  createTimeAPI,
  deleteTimeAPI,
  getTimeAPI,
  getTimesAPI,
  getTimeStatsAPI,
  moveTimeAPI,
  restartTimerAPI,
  updateTimeAPI,
} from "./api";

const timesAdapter = createEntityAdapter();

const getTimes = createAsyncThunk(
  "times/getMany",
  async (filter, { getState, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await getTimesAPI(filter);
    return responseData.times;
  },
);

const getTime = createAsyncThunk(
  "times/get",
  async (filter, { getState, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await getTimeAPI(filter);
    return responseData;
  },
);

export const createTime = createAsyncThunk(
  "time/create",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await createTimeAPI(payload);
    dispatch({
      type: currentTimerActions.updateCurrentTimer.type,
      payload: {
        ...responseData,
        action: "create",
        loggedInUser: getState().auth.user,
      },
    });
    return responseData;
  },
);

export const updateTime = createAsyncThunk(
  "time/update",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await updateTimeAPI(payload);
    dispatch({
      type: currentTimerActions.updateCurrentTimer.type,
      payload: {
        ...responseData,
        action: "update",
        loggedInUser: getState().auth.user,
      },
    });
    return responseData;
  },
);

export const deleteTime = createAsyncThunk(
  "time/delete",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await deleteTimeAPI(payload);
    dispatch({
      type: currentTimerActions.updateCurrentTimer.type,
      payload: {
        ...responseData,
        action: "delete",
        loggedInUser: getState().auth.user,
      },
    });
    return responseData;
  },
);

export const moveTime = createAsyncThunk(
  "time/move",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;

    const { responseData } = await moveTimeAPI(payload);
    return responseData;
  },
);

export const restartTimer = createAsyncThunk(
  "time/restartTimer",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().times.times, requestId)) return;
    const { responseData } = await restartTimerAPI(payload);
    dispatch({
      type: currentTimerActions.updateCurrentTimer.type,
      payload: {
        ...responseData,
        action: "update",
        loggedInUser: getState().auth.user,
      },
    });
    return responseData;
  },
);

export const getStats = createAsyncThunk(
  "time/getStats",
  async (
    { startDate, endDate, userId, key = "default" },
    { getState, requestId },
  ) => {
    if (!testStateLoading(getState().times.times, requestId)) return;
    const { responseData } = await getTimeStatsAPI({
      startDate,
      endDate,
      userId,
    });
    return {
      key: key,
      stats: responseData.stats,
    };
  },
);

const timesSlice = createSlice({
  name: "times",
  initialState: timesAdapter.getInitialState({
    ...INITIAL_LOADING_STATE,
    lastUpdate: moment().subtract(30, "days").toISOString(),
    stats: {},
  }),
  reducers: {
    clearTimes(state) {
      timesAdapter.removeAll(state);
      state.requests = INITIAL_LOADING_STATE.requests;
      state.lastUpdate = moment().subtract(30, "days").toISOString();
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getTimes, (state, action) => {
      if (action.payload.length !== 0) {
        timesAdapter.upsertMany(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, getTime, (state, action) => {
      if (action.payload.length !== 0) {
        timesAdapter.upsertOne(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, createTime, (state, action) => {
      if (action.payload) {
        // timesAdapter.upsertOne(state, action.payload);
        toast.success("Time record saved", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
    buildAsyncThunkReducer(builder, moveTime, (state, action) => {
      if (action.payload.times.length !== 0) {
        timesAdapter.upsertMany(state, action.payload.times);
        if (action.payload.errors.length !== 0) {
          toast.warning(
            action.payload.errors.length +
              " time records were not able to be moved",
          );
        } else {
          toast.success("Time entries moved");
        }
      } else {
        toast.error("Time records were not able to be moved");
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, updateTime, (state, action) => {
      if (action.payload) {
        timesAdapter.updateOne(state, action.payload);
        toast.success("Time record saved", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
    buildAsyncThunkReducer(builder, deleteTime, (state, action) => {
      if (action.payload) {
        timesAdapter.removeOne(state, action.payload.id);
        toast.success("Time record deleted", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
    buildAsyncThunkReducer(builder, restartTimer, (state, action) => {
      if (action.payload) {
        timesAdapter.updateOne(state, action.payload);
        toast.success("Timer restarted", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
    buildAsyncThunkReducer(builder, getStats, (state, action) => {
      if (action.payload) {
        const { key, stats } = action.payload;
        state.stats[key] = stats;
      }
    });
  },
});

export const sliceReducer = timesSlice.reducer;

const { clearTimes } = timesSlice.actions;
export const actions = {
  getTimes,
  getTime,
  clearTimes,
  createTime,
  updateTime,
  deleteTime,
  moveTime,
  restartTimer,
  getStats,
};

export const selectors = timesAdapter.getSelectors(
  (state) => state.times.times,
);
