import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import Team from "./teams";
import userSchema from "./userSchema";
import UserSelect from "./UserSelect";

export default function UserEditor({ values, onSubmit, isDisabled = false }) {
  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={onSubmit}
      schema={{ schema: userSchema }}
      isDisabled={isDisabled}
      showDevTool
    >
      <UserForm />
    </Forms>
  );
}

function UserForm() {
  const { isAdmin } = useSelector((state) => state.auth);
  const { submitForm } = useFormContext();
  useFormsKeys();
  const isDisabled = true;
  return (
    <Form>
      <Form.Row>
        <Col xs={12} md={10} lg={8} xl={6}>
          <FormsField name="name" label="Name" disabled={isDisabled} />
          <Form.Row>
            <Col>
              <FormsField.Select
                name="managerId"
                label="Manager"
                as={UserSelect}
                isClearable
                isDisabled={isDisabled}
              />
            </Col>
            <Col>
              <FormsField.Select
                name="mentorId"
                label="Mentor"
                as={UserSelect}
                isClearable
                isDisabled={isDisabled}
              />
            </Col>
          </Form.Row>
          <FormsField.Select
            name="teamId"
            label="Team"
            as={Team.Select}
            isClearable
            isDisabled={isDisabled}
          />
          <FormsField
            name="email"
            label="Email Address"
            disabled={isDisabled}
          />
          <FormsField name="locationId" label="Location" disabled={true} />
          <Form.Row>
            <Col>
              <FormsField
                name="phoneWork"
                label="Phone (Work)"
                disabled={isDisabled}
              />
            </Col>
            <Col>
              <FormsField
                name="extension"
                label="Phone Extension (Work)"
                disabled={isDisabled}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormsField
                name="phoneCell"
                label="Phone (Cell)"
                disabled={isDisabled}
              />
            </Col>
            <Col>
              <FormsField
                name="phoneHome"
                label="Phone (Home)"
                disabled={isDisabled}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormsField.Check
                name="inactive"
                label="Inactive?"
                disabled={!isAdmin}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Button variant="success" onClick={submitForm}>
            Submit
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
