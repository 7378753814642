import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import { Forms, FormsField, useFormContext } from "../../common/forms";
import Customer from "../../customers";
import TradingPartner from "../tradingPartners";
import customerEdiSetupSchema from "./customerEdiSetupSchema";

export default function CustomerEdiSetupEditor({
  values = {},
  onSubmit,
  isLoading,
}) {
  TradingPartner.useAll({
    canGet: true,
  });

  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: customerEdiSetupSchema }}
        showDevTool
      >
        <CustomerEdiSetupForm isLoading={isLoading} />
      </Forms>
    </>
  );
}

function CustomerEdiSetupForm() {
  const { submitForm } = useFormContext();

  return (
    <Form>
      <Form.Row>
        <Col xs={"auto"} style={{ minWidth: "250px" }}>
          <FormsField.Select
            name="tradingPartnerId"
            label="Trading Partner"
            as={TradingPartner.Select}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={"auto"} style={{ minWidth: "250px" }}>
          <FormsField.Select
            name="customerId"
            label="Customer"
            as={Customer.Select}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormsField
            name="subscriber"
            label="Subscriber"
            className="text-monospace"
          />
        </Col>
        <Col>
          <FormsField
            name="company"
            label="Company"
            className="text-monospace"
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormsField.TextArea
            name="notes"
            label="Notes"
            className="text-monospace"
            minRows={6}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={"auto"}>
          <FormsField.Check name="isInactive" label="Inactive?" />
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
