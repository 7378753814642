import EstimateStatus from "./estimateStatuses";
import ProjectFolder from "./folders";
import getProjectPriorityClassName from "./getProjectPriorityClassName";
import ProjectPriority from "./priorities";
import ProjectAlerts from "./ProjectAlerts";
import ProjectContacts from "./ProjectContacts";
import ProjectEditor from "./ProjectEditor";
import ProjectEstimateEditor from "./ProjectEstimateEditor";
import ProjectFilters from "./ProjectFilters";
import ProjectLink from "./ProjectLink";
import ProjectNew from "./ProjectNew";
import projectSchema from "./projectSchema";
import ProjectSearch from "./ProjectSearch";
import ProjectSelect from "./ProjectSelect";
import ProjectShareButton from "./ProjectShareButton";
import ProjectStatusEditor from "./ProjectStatusEditor";
import ProjectTable from "./ProjectTable";
import ProjectSource from "./sources";
import projectState from "./state";
import ProjectStatus from "./statuses";
import ProjectType from "./types";
import updateProjectPrompt from "./updateProjectPrompt";
import useFindOption from "./useFindOption";
import useProject from "./useProject";
import useProjects from "./useProjects";
import useProjectsByFilter from "./useProjectsByFilter";

const Project = {
  // Backend API
  api: projectState.api,
  // State
  actions: projectState.actions,
  selectors: projectState.entity.selectors,
  // Hooks
  useAll: useProjects,
  useOne: useProject,
  useSearch: useProjectsByFilter,
  useFindOption: useFindOption,
  // Functions
  updatePrompt: updateProjectPrompt,
  schema: projectSchema,
  getPriorityClassName: getProjectPriorityClassName,
  // Components
  New: ProjectNew,
  Editor: ProjectEditor,
  Contacts: ProjectContacts,
  Table: ProjectTable,
  Select: ProjectSelect,
  Search: ProjectSearch,
  Link: ProjectLink,
  StatusEditor: ProjectStatusEditor,
  ShareButton: ProjectShareButton,
  Filters: ProjectFilters,
  EstimateEditor: ProjectEstimateEditor,
  Alerts: ProjectAlerts,
  // Related Entity Objects
  Priority: ProjectPriority,
  Source: ProjectSource,
  Status: ProjectStatus,
  Type: ProjectType,
  Folder: ProjectFolder,
  EstimateStatus: EstimateStatus,
};

export default Project;
