import CustomerContractDescription from "./CustomerContractDescription";
import CustomerContractSelect from "./CustomerContractSelect";
import CustomerContractTableFilter from "./CustomerContractTableFilter";
import {
  useCustomerContract,
  useCustomerContracts,
  useCustomerContractsByCustomer,
} from "./hooks";
import customerContractState from "./state";

const CustomerContract = {
  // API
  api: customerContractState.api,
  // State
  actions: customerContractState.actions,
  selectors: customerContractState.entity.selectors,
  // Hooks
  useOne: useCustomerContract,
  useAll: useCustomerContracts,
  useSearch: useCustomerContractsByCustomer,
  // Components
  Select: CustomerContractSelect,
  Description: CustomerContractDescription,
  TableFilter: CustomerContractTableFilter,
};
export default CustomerContract;
