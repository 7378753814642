import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { createEntityHooks } from "../common/entity";
import state from "./state";

const { useEntities: useUsers, useEntity } = createEntityHooks({
  entity: state.entity,
  actions: state.actions,
  expire: {
    key: "days",
    value: 1,
  },
});

const useUser = ({ id, ...args } = {}) => {
  const dispatch = useDispatch();
  return {
    createServerLogin: useCallback(
      ({ ...entity }) =>
        dispatch(state.actions.createServerLogin({ ...entity, id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success("Server login created");
            return result;
          }),
      [dispatch, id],
    ),
    updateServerLoginKeys: useCallback(
      ({ ...entity }) =>
        dispatch(state.actions.updateServerLoginKeys({ ...entity, id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success("Server login keys updated");
            return result;
          }),
      [dispatch, id],
    ),
    updateServerLoginPassword: useCallback(
      ({ ...entity }) =>
        dispatch(state.actions.updateServerLoginPassword({ ...entity, id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success("Server login password updated");
            return result;
          }),
      [dispatch, id],
    ),
    deleteServerLogin: useCallback(
      ({ ...entity }) =>
        dispatch(state.actions.deleteServerLogin({ ...entity, id: id }))
          .then(unwrapResult)
          .then(({ data: result } = {}) => {
            toast.success("Server login delete");
            return result;
          }),
      [dispatch, id],
    ),
    ...useEntity({ id, ...args }),
  };
};

export { useUser, useUsers };
