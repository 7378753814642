import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import FieldDate from "../common/fields/FieldDate";
import { _date } from "../common/functions/dates";
import User from "../users";

export default function TimeFilters({ user, date, showTimers, onRefresh }) {
  const history = useHistory();

  const updateUrl = useCallback(
    ({ user, date }) => {
      history.push(
        "/time?user=" +
          user +
          "&date=" +
          _date.urlParams(date) +
          "&showTimers=" +
          showTimers,
      );
    },
    [history, showTimers],
  );

  const onDateChange = (newDate) => {
    if (_date.stamp(date) === _date.stamp(newDate)) onRefresh();
    else {
      updateUrl({
        user,
        date: newDate,
      });
    }
  };

  return (
    <Form>
      <Row>
        <Col>
          <FieldDate onChange={onDateChange} value={date} />
        </Col>
        <Col>
          <User.Select
            label="User"
            id="time-user"
            value={user}
            onChange={(value) => updateUrl({ user: value, date })}
            hasMeButton
          />
        </Col>
      </Row>
    </Form>
  );
}
