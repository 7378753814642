const travel = {
  value: "Travel",
  label: "Travel",
  toString: () => {
    return this.value;
  },
};

const onsite = {
  value: "On-site",
  label: "On-site",
  toString: () => {
    return this.value;
  },
};

const internal = {
  value: "Internal",
  label: "Internal Project",
  toString: () => {
    return this.value;
  },
};

const customer = {
  value: "Customer",
  label: "Customer, CR tbd",
  toString: () => {
    return this.value;
  },
};

const cr = {
  value: "CR",
  label: "CR",
  toString: () => {
    return this.value;
  },
};
const timeTypes = { cr, customer, internal, onsite, travel };
export default timeTypes;
