import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Col,
  ListGroup,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";

import useJob from "../../activity/jobs/useJob";
import Approvals from "../../approvals";
import FieldInput from "../../common/fields/FieldInput";
import { _date } from "../../common/functions/dates";
import LoadingModal from "../../common/modals/LoadingModal";
import SavingModal from "../../common/modals/SavingModal";
import Page from "../../common/pages/Page";
import Customer from "../../customers";
import User from "../../users";

// don't move this inside the function unless you memo it
const layout = [
  "project",
  "service",
  "owner",
  "status",
  "synopsis",
  "past",
  "current",
  "skipped",
  "discount",
  "estimate",
];

export default function ApprovalsIndex() {
  const [jobSubmitted, setJobSubmitted] = useState(false);
  const [showProject, setShowProject] = useState("");
  const [showStatementModal, setShowStatementModal] = useState(false);
  const [showSavingModal, setShowSavingModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountComment, setDiscountComment] = useState(" ");
  const [discountProject, setDiscountProject] = useState(null);
  const [newJobId, setNewJobId] = useState(null);
  const [activeCustomerId, setActiveCustomerId] = useState(null);
  const jobId = parseInt(useParams().id);
  const history = useHistory();
  const dispatch = useDispatch();
  const x = useSelector((state) => state.auth.user);
  const { user: loggedInUser } = User.useOne({ id: x });
  const { customer: activeCustomer } = Customer.useOne({
    id: activeCustomerId,
  });

  const {
    job,
    loading,
    finishedGetJobId,
    inProgressJobId,
    submitJob,
    resubmitJob,
  } = useJob({
    id: jobId,
    redirect: false,
    functionName: "statement",
  });

  const dispatchApprove = useCallback(
    (project, customer, action) => {
      setShowSavingModal(true);
      dispatch(
        Approvals.actions.approveProject({
          jobId: jobId,
          projectId: project,
          action: action,
          date: job.options.billThruDate,
          customerId: customer,
        }),
      )
        .then(() => {
          setShowSavingModal(false);
        })
        .catch(() => {
          setShowSavingModal(false);
        });
    },
    [dispatch, jobId, job],
  );

  const refreshProjects = useCallback(
    (theJob) => {
      let reqCusts = [];
      for (let c = 0; c < theJob?.output.customers.length; c++) {
        const custProjs = theJob?.output.customers[c].projects.map(
          (p) => p.project,
        );
        const custObj = {
          customerId: theJob?.output.customers[c].customerId,
          projects: custProjs,
        };
        reqCusts.push(custObj);
      }
      setShowRefreshModal(true);
      dispatch(
        Approvals.actions.refreshProjects({
          jobId: jobId,
          customers: reqCusts,
          billThruDate: theJob.options.billThruDate,
        }),
      )
        .then(() => {
          setShowRefreshModal(false);
        })
        .catch(() => {
          setShowRefreshModal(false);
        });
    },
    [dispatch, jobId],
  );

  useEffect(() => {
    if (
      !_.isNil(finishedGetJobId) &&
      inProgressJobId !== finishedGetJobId &&
      newJobId !== finishedGetJobId
    ) {
      setNewJobId(finishedGetJobId);
      refreshProjects(job);
    }
  }, [finishedGetJobId, inProgressJobId, job, refreshProjects, newJobId]);

  useEffect(() => {
    if (loading && _.isNil(newJobId)) setShowLoadingModal(true);
    else setShowLoadingModal(false);
  }, [loading, newJobId]);

  const submitSuccess = useCallback(
    (result) => {
      setJobSubmitted(false);
      setNewJobId(result.id);
      setShowProject("");
      setShowSavingModal(false);
      setShowLoadingModal(false);
      setShowDiscountModal(false);
      setDiscountAmount(0);
      setDiscountComment(" ");
      setActiveCustomerId(null);
      history.push("/approvals/" + result.id);
    },
    [history],
  );

  const submitFail = useCallback(() => {
    setJobSubmitted(false);
  }, []);

  const dispatchApproveAll = useCallback(
    (projects, customer, action) => {
      setShowSavingModal(true);

      const prjs = projects.map((v) => v.project);

      dispatch(
        Approvals.actions.approveProjects({
          jobId: jobId,
          projects: prjs,
          action: action,
          date: job.options.billThruDate,
          customerId: customer,
        }),
      )
        .then(() => {
          setShowSavingModal(false);
        })
        .catch(() => {
          setShowSavingModal(false);
        });
    },
    [dispatch, jobId, job],
  );

  const showTheDiscountModal = useCallback((projectId, amount, comment) => {
    setDiscountComment(comment);
    setDiscountAmount(amount);
    setDiscountProject(projectId);
    setShowDiscountModal(true);
  }, []);

  const saveDiscount = useCallback(() => {
    setShowSavingModal(true);

    dispatch(
      Approvals.actions.discountProject({
        projectId: discountProject,
        amount: discountAmount,
        comment: discountComment,
        jobId: jobId,
      }),
    )
      .then(() => {
        setShowSavingModal(false);
        setShowDiscountModal(false);
      })
      .catch(() => {
        setShowSavingModal(false);
      });
  }, [dispatch, discountProject, discountAmount, discountComment, jobId]);

  const clickedProject = useCallback((project) => {
    setShowProject(project);
    setShowStatementModal(true);
  }, []);

  //const champion = _.isEmpty(paramsChampion) ? loggedInUser : paramsChampion;

  const handleSubmit = (options, description) => {
    setActiveCustomerId(null);
    setJobSubmitted(true);
    setNewJobId("unknown");
    submitJob(options, description).then(submitSuccess).catch(submitFail);
  };

  const clickedRebuild = () => {
    setJobSubmitted(true);
    resubmitJob()
      .then((result) => {
        setJobSubmitted(false);
        history.push("/approvals/" + result.id);
      })
      .catch(() => {
        setJobSubmitted(false);
      });
  };

  const isJob = jobId !== null && jobId !== undefined && !isNaN(jobId);
  const jobHasData =
    isJob && job !== null && job !== undefined && jobId === job.id;
  const jobRunning = jobHasData && job.status > "6";

  let championDefault = loggedInUser?.isChampion ? loggedInUser.id : null;
  let customerDefault = null;
  let showOpenDefault = "Y";
  let showApprovedDefault = "B";
  let projectTypeDefault = "O";
  let projectOwnerDefault = null;
  let defaultBillThruDate = _date.stamp(moment().endOf("months"));
  defaultBillThruDate = "20221231";

  if (jobHasData) {
    championDefault = job.options.champion;
    customerDefault = job.options.customer;
    showOpenDefault = job.options.showOpen;
    showApprovedDefault = job.options.showApproved;
    projectTypeDefault = job.options.projectType;
    projectOwnerDefault = job.options.projectOwner;
    defaultBillThruDate = job.options.billThruDate;
  }

  const ApprovalFiltersJsx = (
    <Approvals.Filters
      championDefault={championDefault}
      customerDefault={customerDefault}
      projectTypeDefault={projectTypeDefault}
      projectOwnerDefault={projectOwnerDefault}
      showOpenDefault={showOpenDefault}
      showApprovedDefault={showApprovedDefault}
      billThruDateDefault={defaultBillThruDate}
      onSubmit={handleSubmit}
      loading={loading}
      disableAll={jobRunning || jobSubmitted}
    />
  );

  const { customers, currentActiveCustomerIndex, currentActiveCustomer } =
    parseJobsCustomers(job, activeCustomerId);

  // job info was set/changed and we only have 1 customer
  // so auto set the customer
  if (
    customers.length === 1 &&
    activeCustomerId !== customers[0].value &&
    jobSubmitted === false &&
    (newJobId === null || newJobId === jobId)
  ) {
    setActiveCustomerId(customers[0].value);
  }

  const projects = useMemo(() => {
    const prjs = [];
    const data = job?.output?.customers[currentActiveCustomerIndex]?.projects;
    if (data !== undefined) {
      for (let i = 0; i < data.length; i++) {
        prjs.push(data[i]);
      }
    }
    return prjs;
  }, [currentActiveCustomerIndex, job]);

  return (
    <Page title="Approvals" navSidebar={false} tableOfContents={false}>
      <SavingModal show={showSavingModal} />
      <LoadingModal show={showLoadingModal} />
      <LoadingModal
        show={showRefreshModal}
        bodyHeader={"Refreshing statuses"}
      />
      <Modal
        dialogClassName="modal-95-percent"
        show={showStatementModal}
        onHide={() => setShowStatementModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Statement for Project {showProject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Approvals.Statement
            data={job?.output?.customers[currentActiveCustomerIndex]?.lines}
            loading={loading}
            project={showProject}
          />
          <Approvals.Skipped
            data={
              job?.output?.customers[currentActiveCustomerIndex]?.unbilledLines
            }
            loading={loading}
            project={showProject}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showDiscountModal}
        onHide={() => setShowDiscountModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Discount for Project {discountProject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="auto">Amount</Col>
            <Col xs="3">
              <FieldInput
                type="number"
                value={discountAmount}
                onChange={(v) => setDiscountAmount(v)}
                onChangeOnBlur={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>Internal comments</Col>
          </Row>
          <Row>
            <Col>
              <TextareaAutosize
                type="text"
                as="textarea"
                className="form-control"
                minRows={2}
                onChange={(e) => setDiscountComment(e.target.value)}
                value={discountComment}
                maxLength={256}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={saveDiscount}
            variant="primary"
            disabled={discountComment.trim() === ""}
          >
            Save
          </Button>
          <Button
            onClick={() => setShowDiscountModal(false)}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {isJob ? (
        jobHasData ? (
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Row>
                <Col xs="auto">
                  {job.isErrored || job.isDone ? (
                    <Button
                      variant="success"
                      onClick={clickedRebuild}
                      disabled={jobRunning || jobSubmitted}
                    >
                      Rebuild
                    </Button>
                  ) : null}
                </Col>
                <Col>
                  <h4>{job.description}</h4>
                </Col>
                {activeCustomerId ? (
                  <Col>
                    <Alert variant="info">
                      {"Current support plan: " +
                        (activeCustomer?.supportPlan === "P"
                          ? "Premium"
                          : "Standard")}
                    </Alert>
                  </Col>
                ) : null}
              </Row>

              {job.isWaiting ? (
                <Row>
                  <h1>Job is waiting...</h1>
                  <Spinner animation={"grow"} vaiant={"primary"} />
                </Row>
              ) : job.isInProgress ? (
                <Row>
                  <h1>Job is in progress...</h1>
                  <Spinner animation={"grow"} vaiant={"primary"} />
                </Row>
              ) : job.isErrored ? (
                <Row>
                  <h1>Job has encountered an error</h1>
                </Row>
              ) : null}
            </ListGroup.Item>
            {job.isDone && job.options.customer === "" ? (
              <>
                <ListGroup.Item>
                  <Select
                    className={"basic-single"}
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    id={"activeCustomer"}
                    name={"activeCustomer"}
                    value={currentActiveCustomer}
                    options={customers}
                    onChange={(newValue) => setActiveCustomerId(newValue.value)}
                    isMulti={false}
                    placeholder="Select customer"
                  />
                </ListGroup.Item>
                {activeCustomerId === null && customers.length > 0 ? (
                  <Alert variant="warning">Choose a customer above</Alert>
                ) : activeCustomerId === null && customers.length === 0 ? (
                  <Alert variant="danger">No customers found</Alert>
                ) : null}
              </>
            ) : null}
            {job.isDone && activeCustomerId !== null ? (
              <ListGroup.Item>
                <Tab.Container defaultActiveKey={"projects"}>
                  <Row>
                    <Col xs={7}>
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                          <Nav.Link eventKey="projects">Projects</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="statement">Statement</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="skipped">Skipped</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="options">Options</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="colors">Colors</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tab.Content>
                        <Tab.Pane eventKey="projects">
                          <Approvals.Table
                            projects={projects}
                            layout={layout}
                            customerId={activeCustomerId}
                            isLoading={loading}
                            approveAll={dispatchApproveAll}
                            clickedProject={clickedProject}
                            approveProject={dispatchApprove}
                            showTheDiscountModal={showTheDiscountModal}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="statement">
                          <Approvals.Statement
                            data={
                              job?.output?.customers[currentActiveCustomerIndex]
                                ?.lines
                            }
                            loading={loading}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="skipped">
                          <Approvals.Skipped
                            data={
                              job?.output?.customers[currentActiveCustomerIndex]
                                ?.unbilledLines
                            }
                            loading={loading}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="options">
                          {ApprovalFiltersJsx}
                        </Tab.Pane>
                        <Tab.Pane eventKey="colors">
                          <ListGroup className="container">
                            <ListGroup.Item className="py-1" variant="success">
                              Approved project
                            </ListGroup.Item>
                            <ListGroup.Item className="py-1" variant="warning">
                              Partially approved
                            </ListGroup.Item>
                            <ListGroup.Item
                              className="py-1"
                              variant="secondary"
                            >
                              Not approved but project is closed
                            </ListGroup.Item>
                            <ListGroup.Item className="py-1">
                              Not approved but project is open
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </ListGroup.Item>
            ) : job.isDone && customers.length === 0 ? (
              <> {ApprovalFiltersJsx} </>
            ) : null}
          </ListGroup>
        ) : (
          <span>Job not found</span>
        )
      ) : (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col>{ApprovalFiltersJsx}</Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      )}
    </Page>
  );
}

function parseJobsCustomers(job, activeCustomerId) {
  let currentActiveCustomerIndex = -1;
  let customers = [];
  if (job?.output?.customers.length === 1) {
    customers.push({
      value: job?.output?.customers[0].customerId,
      label: job?.output?.customers[0].customerName,
    });
    currentActiveCustomerIndex = 0;
  } else if (job?.output?.customers.length > 1)
    for (let i = 0; i < job?.output?.customers.length; i++)
      if (
        job?.output?.customers[i].lines.length > 0 ||
        job?.output?.customers[i].unbilledLines.length > 0
      ) {
        customers.push({
          value: job?.output?.customers[i].customerId,
          label:
            job?.output?.customers[i].customerName +
            " [" +
            job?.output?.customers[i].projects.length +
            "]",
        });
        if (job?.output?.customers[i].customerId === activeCustomerId) {
          currentActiveCustomerIndex = i;
        }
      }

  let currentActiveCustomer = null;
  for (let i = 0; i < customers.length; i++) {
    if (customers[i].value === activeCustomerId) {
      currentActiveCustomer = customers[i];
      break;
    }
  }

  return {
    currentActiveCustomerIndex: currentActiveCustomerIndex,
    currentActiveCustomer: currentActiveCustomer,
    customers: customers,
  };
}
