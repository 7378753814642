import React, { useState } from "react";
import { useParams } from "react-router";

import Cr from "../../crs";
import Project from "../../projects";
import ProjectPage from "./ProjectPage";
import ProjectShowNavSidebar from "./ProjectShowNavSidebar";

export default function ProjectEdit() {
  const id = parseInt(useParams().id);
  const { project, loading, updateOne } = Project.useOne({ id });
  const [error, setError] = useState(null);

  return (
    <ProjectPage
      project={project}
      heading={`Edit Project ${id}`}
      lead={loading ? "Loading..." : null}
      isLoading={loading}
      navSidebar={<ProjectShowNavSidebar id={id} />}
    >
      {project && !loading ? (
        <Project.Editor
          project={project}
          onSubmit={(projectModified) => {
            updateOne(projectModified, Cr.updatePrompt).catch((err) =>
              setError(err?.message),
            );
          }}
          error={error}
        />
      ) : null}
    </ProjectPage>
  );
}
