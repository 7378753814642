import { SERVER_TYPES } from "./types";

export default function getServerAlias(
  { aliasPostfix, serverType, status, shouldGenerateSSHAlias } = {},
  { alias } = {},
) {
  let generatedAlias = "";
  if (alias && shouldGenerateSSHAlias === true) {
    generatedAlias = alias;
    if (!!aliasPostfix && aliasPostfix.trim() !== "")
      generatedAlias = generatedAlias + "-" + aliasPostfix.trim();
    else {
      if (serverType !== "P")
        generatedAlias =
          generatedAlias + "-" + SERVER_TYPES[serverType]?.postfix;
    }
    if (status === "O") generatedAlias = generatedAlias + "-old";
  }
  return generatedAlias;
}
