import { createEntityHooks } from "../../common/entity";
import reusableProgramState from "./state";

export const {
  useEntities: useReusablePrograms,
  useEntity: useReusableProgram,
} = createEntityHooks({
  entity: reusableProgramState.entity,
  actions: reusableProgramState.actions,
});
