import { unwrapResult } from "@reduxjs/toolkit";
import { isNaN } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInterval from "../../common/hooks/useInterval";
import useRedirect from "../../common/hooks/useRedirect";
import { jobsActions, jobsSelectors } from "./state";

export default function useJob({
  id,
  functionName,
  blocked = false,
  redirect,
} = {}) {
  const job = useSelector((state) => jobsSelectors.selectById(state, id));
  const loadingSelection = useSelector(
    (state) => state.activity.jobs.requests.loading,
  );
  const dispatch = useDispatch();
  const [finishedGetJobId, setFinishedGetJobId] = useState(null);
  const [inProgressJobId, setInProgessJobId] = useState(null);
  const [redirecting, setRedirect] = useRedirect();
  const [error, setError] = useState(null);

  const getJob = useCallback(() => {
    setFinishedGetJobId(null);
    return dispatch(jobsActions.getJob({ id: id }))
      .then(unwrapResult)
      .then((result) => {
        if (result.isDone) setFinishedGetJobId(id);
        if (result.isWaiting || result.isInProgress) setInProgessJobId(id);
        return result;
      });
  }, [id, dispatch]);

  const submitJob = useCallback(
    (options, description = "") => {
      setFinishedGetJobId(null);
      return dispatch(
        jobsActions.submitJob({
          functionName,
          options,
          description: description,
        }),
      )
        .then(unwrapResult)
        .then((result) => {
          if (redirect) setRedirect(redirect + "/" + result.id);
          return result;
        });
    },
    [dispatch, functionName, redirect, setRedirect],
  );

  const resubmitJob = useCallback(
    () =>
      dispatch(jobsActions.resubmitJob({ id: id }))
        .then(unwrapResult)
        .then((result) => {
          if (redirect) setRedirect(redirect + "/" + result.id);

          return result;
        }),
    [id, dispatch, redirect, setRedirect],
  );

  const shouldGet = !isNaN(id) && !blocked && error === null;
  const shouldGetInterval = shouldGet && !job?.isDone && !job?.isErrored;

  useInterval(() => getJob().catch(setError), shouldGetInterval ? 3000 : 0, [
    getJob,
    shouldGetInterval,
  ]);

  const loading = useMemo(() => {
    return loadingSelection || redirecting !== null;
  }, [loadingSelection, redirecting]);

  return {
    job,
    loading,
    error,
    getJob,
    submitJob,
    resubmitJob,
    ...(job?.functionName === functionName
      ? { finishedGetJobId, inProgressJobId }
      : {}),
  };
}
