import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";

import { _date } from "../../common/functions/dates";
import SavingModal from "../../common/modals/SavingModal";
import Cr from "../../crs";
import Time from "../../time";

export default function CrShowTimeSetAll({
  cr,
  show,
  onHide,
  onSaveSuccess = () => {},
}) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [internalComments, setInternalComments] = useState(
    cr?.setAllInternalComments,
  );
  const [hold, setHold] = useState(cr?.setallHold);
  const [holdReasonId, setHoldReasonId] = useState(cr?.setallHoldReasonId);
  const holdReasonsState = useSelector((state) => state.times.holdReasons);
  const holdReasonRecord = holdReasonsState.entities[holdReasonId];
  const [holdDate, setHoldDate] = useState(null);

  const [billability, setBillability] = useState(cr?.setallBillability);
  const [invoiceFlag, setInvoiceFlag] = useState(cr?.setallInvoiceFlag);
  const [showOnInvoice, setShowOnInvoice] = useState(cr?.setallShowOnInvoice);
  const [overrideBillableFlag, setOverrideBillableFlag] = useState(
    cr?.setallOverrideBillableFlag,
  );
  const [billableOverrideReasonId, setBillableOverrideReasonId] = useState(
    cr?.setallBillableOverrideReasonId,
  );

  const borsState = useSelector((state) => state.times.billableOverrideReasons);
  const borsRecord = borsState.entities[billableOverrideReasonId];

  const changeBillableOverrideReasonId = (value) => {
    if (billableOverrideReasonId === value) return;

    const oldBors = billableOverrideReasonId;
    setBillableOverrideReasonId(value);
    if (borsState.entities[value]?.billFlagDefault === "N") {
      changeOverrideBillableFlag(false);
      if (value === "HELP" || value === "FIXN" || value === "QA")
        setShowOnInvoice(false);
      else if (oldBors === "HELP" || oldBors === "FIXN" || oldBors === "QA")
        setShowOnInvoice(true);
    } else {
      changeOverrideBillableFlag(true);
      if (showOnInvoice !== true) setShowOnInvoice(true);
    }
  };
  const changeOverrideBillableFlag = (v) => {
    if (showOnInvoice !== true && v === true) setShowOnInvoice(true);
    setOverrideBillableFlag(v);
  };

  const changeHoldReasonId = (value) => {
    setHoldReasonId(value);
  };
  const changeHoldDate = (v) => {
    setHoldDate(v.value);
  };

  const billableSectionVariant = !billability ? "secondary" : "primary";
  const holdSectionVariant = !hold ? "secondary" : "primary";

  const invoiceFlagYesButtonVariant = invoiceFlag
    ? "primary"
    : "outline-primary";
  const invoiceFlagNoButtonVariant = invoiceFlag
    ? "outline-primary"
    : "primary";

  const holdYesButtonVariant = hold ? "primary" : "outline-primary";
  const holdNoButtonVariant = hold ? "outline-primary" : "primary";

  const billabilityYesButtonVariant = billability
    ? "primary"
    : "outline-primary";
  const billabilityNoButtonVariant = billability
    ? "outline-primary"
    : "primary";

  const billableOverrideYesProps = overrideBillableFlag
    ? { variant: "success", disabled: true }
    : {
        variant: "outline-success",
        disabled:
          !billability ||
          (billableOverrideReasonId !== "OTHR" &&
            billableOverrideReasonId !== "SWTS"),
      };

  const billableOverrideNoProps = overrideBillableFlag
    ? { variant: "outline-danger", disabled: false || !billability }
    : { variant: "danger", disabled: true };

  const showOnInvoiceYesProps = showOnInvoice
    ? { variant: "success", disabled: true }
    : { variant: "outline-success", disabled: false || !billability };

  const showOnInvoiceNoProps = showOnInvoice
    ? {
        variant: "outline-danger",
        disabled: false || !billability || overrideBillableFlag,
      }
    : { variant: "danger", disabled: true };

  const internalCommentsVariant =
    _.isEmpty(internalComments) || internalComments.trim() === ""
      ? "warning"
      : "primary";

  useEffect(() => {
    setInternalComments(cr?.setallInternalComments);
    setHold(cr?.setallHold);
    setHoldReasonId(cr?.setallHoldReasonId);

    const nextMonth = moment().startOf("month").add(1, "months");

    const defaultHoldDate =
      !_.isEmpty(cr?.setallHoldDate) && cr?.setallHoldDate !== ""
        ? _date.fromStamp(cr?.setallHoldDate)
        : nextMonth;
    setHoldDate(defaultHoldDate);

    setBillability(cr?.setallBillability);
    setInvoiceFlag(cr?.setallInvoiceFlag);
    setShowOnInvoice(cr?.setallShowOnInvoice);
    setOverrideBillableFlag(cr?.setallOverrideBillableFlag);
    setBillableOverrideReasonId(cr?.setallBillableOverrideReasonId);
  }, [cr]);

  const setAllPayload = {
    setallInternalComments: internalComments,
    setallHold: hold,
    setallHoldReasonId: holdReasonId,
    setallHoldDate: _date.stamp(holdDate),
    setallBillability: billability,
    setallInvoiceFlag: invoiceFlag,
    setallShowOnInvoice: showOnInvoice,
    setallBillableOverrideReasonId: billableOverrideReasonId,
    setallOverrideBillableFlag: overrideBillableFlag,
  };

  useEffect(() => {
    if (saving === true) {
      dispatch(
        Cr.actions.updateSetAll({
          id: cr?.id,
          setAll: setAllPayload,
        }),
      )
        .then(unwrapResult)
        .then(({ data: result }) => {
          setSaving(false);
          onSaveSuccess(result);
        })
        .catch(() => {
          setSaving(false);
        });
    }
    //TODO review dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saving]);

  const saveDisabled =
    (billability || hold) && internalCommentsVariant === "warning";

  const clickSave = () => {
    if (saveDisabled) {
      toast.error("Cannot save, please fully fill out the form");
      return;
    }
    setSaving(true);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Set All Time Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <SavingModal show={saving} />
        <ListGroup variant="flush">
          <ListGroup.Item variant={billableSectionVariant}>
            <Row>
              <Col>
                <Form.Label>
                  {"Override billability settings?\u00A0"}
                </Form.Label>
                <Button
                  variant={billabilityYesButtonVariant}
                  className={"rounded-0"}
                  onClick={() => setBillability(true)}
                >
                  Yes
                </Button>
                <Button
                  variant={billabilityNoButtonVariant}
                  className={"rounded-0"}
                  onClick={() => setBillability(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
            {billability ? (
              <>
                <Row>
                  <Col>
                    <Form.Label className="mb-0">
                      Billable override reason
                    </Form.Label>
                    <Time.BillableOverrideReason.Select
                      value={billableOverrideReasonId}
                      onChange={changeBillableOverrideReasonId}
                      disabled={!billability}
                      isClearable={true}
                    />
                  </Col>
                  <Col>
                    {borsRecord != null ? (
                      <>
                        <Row>
                          <Col>
                            <Form.Label className="mb-0">
                              Override Billability
                            </Form.Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              className="rounded-0"
                              {...billableOverrideYesProps}
                              onClick={() => changeOverrideBillableFlag(true)}
                            >
                              Bill
                            </Button>
                            <Button
                              className="rounded-0"
                              {...billableOverrideNoProps}
                              onClick={() => changeOverrideBillableFlag(false)}
                            >
                              {"Don't"}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : billability ? (
                      <>
                        <Row>
                          <Col>{"Note:"}</Col>
                        </Row>
                        <Row>
                          <Col>
                            {
                              "By not selecting a reason, all existing time records' reason would be cleared"
                            }
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                {borsRecord != null ? (
                  <>
                    <Row>
                      <Col>
                        <Row>
                          <Col>{"\u00A0"}</Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label>
                              {"Override show on invoice?\u00A0"}
                            </Form.Label>
                            <Button
                              variant={invoiceFlagYesButtonVariant}
                              className={"rounded-0"}
                              onClick={() => setInvoiceFlag(true)}
                            >
                              Yes
                            </Button>
                            <Button
                              variant={invoiceFlagNoButtonVariant}
                              className={"rounded-0"}
                              onClick={() => setInvoiceFlag(false)}
                            >
                              No
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {invoiceFlag ? (
                        <Col>
                          <Row>
                            <Col>
                              <Form.Label className="mb-0">
                                Show on Invoice
                              </Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                className="rounded-0"
                                {...showOnInvoiceYesProps}
                                onClick={() => setShowOnInvoice(true)}
                              >
                                Show
                              </Button>
                              <Button
                                className="rounded-0"
                                {...showOnInvoiceNoProps}
                                onClick={() => setShowOnInvoice(false)}
                              >
                                Hide
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    <Row></Row>{" "}
                  </>
                ) : null}
              </>
            ) : null}
          </ListGroup.Item>

          <ListGroup.Item variant={holdSectionVariant}>
            <Row>
              <Col>
                <Form.Label>{"Override hold settings?\u00A0"}</Form.Label>
                <Button
                  variant={holdYesButtonVariant}
                  className={"rounded-0"}
                  onClick={() => setHold(true)}
                >
                  Yes
                </Button>
                <Button
                  variant={holdNoButtonVariant}
                  className={"rounded-0"}
                  onClick={() => setHold(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
            {hold ? (
              <Row>
                <Col>
                  <Form.Label className="mb-0">Hold Reason</Form.Label>
                  <Time.HoldReason.Select
                    value={holdReasonId}
                    onChange={changeHoldReasonId}
                    disabled={!hold}
                    isClearable={true}
                  />
                </Col>
                <Col>
                  {holdReasonRecord != null ? (
                    <>
                      <Row>
                        <Col>
                          <Form.Label className="mb-0">Hold until</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Time.HoldReason.DateSelect
                            value={holdDate}
                            onChange={changeHoldDate}
                            date={null}
                            defaultFirstMonth={true}
                            isClearable={false}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </ListGroup.Item>
          {billability || hold ? (
            <ListGroup.Item variant={internalCommentsVariant}>
              <Row>
                <Col>
                  <Form.Label>Internal Comments</Form.Label>
                  <TextareaAutosize
                    type="text"
                    as="textarea"
                    className="form-control"
                    minRows={2}
                    onChange={(e) => setInternalComments(e.target.value)}
                    value={internalComments}
                    maxLength={120}
                    autoFocus
                  />
                </Col>
              </Row>
            </ListGroup.Item>
          ) : null}
          <ListGroup.Item className="mx-0 my-0 py-1">
            <Row className="mb-0 pb-0">
              <Col className="px-1">
                <Button
                  variant={saveDisabled ? "dark" : "primary"}
                  disabled={saveDisabled}
                  block
                  className="rounded-0"
                  type="submit"
                  onClick={() => clickSave()}
                >
                  Save
                </Button>
              </Col>
              <Col className="px-1">
                <Button
                  variant={saving ? "dark" : "secondary"}
                  disabled={saving}
                  block
                  className="rounded-0"
                  type="submit"
                  onClick={() => onHide()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}
