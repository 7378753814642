import { createEntityHooks } from "../../common/entity";
import contractProductDefaultCrState from "./state";

export const {
  useEntities: useContractProductDefaultCrs,
  useEntity: useContractProductDefaultCr,
} = createEntityHooks({
  entity: contractProductDefaultCrState.entity,
  actions: contractProductDefaultCrState.actions,
});
