import shark from "../../../apis/shark";
import { _date, _datetime } from "../../../common/functions/dates";

export function getCurrentTimerAPI() {
  return shark.post({ command: "getCurrentTimer", date: _date.stamp() });
}

export function stopCurrentTimerAPI({ timeId }) {
  return shark.post({
    command: "stopCurrentTimer",
    timeId: timeId,
    datetime: _datetime.stamp(),
  });
}
