import { useHostingAgreement } from "./hooks";
import HostingAgreementMonthExceptionTable from "./HostingAgreementMonthExceptionsTable";
import HostingAgreementMonthTable from "./HostingAgreementMonthTable";
import state from "./state";

const HostingAgreementMonth = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useHostingAgreement,
  // Components
  Table: HostingAgreementMonthTable,
  ExceptionsTable: HostingAgreementMonthExceptionTable,
};
export default HostingAgreementMonth;
