import TaxProductCategory from "./taxProductCategories";
import TaxProduct from "./taxProducts";

const Invoice = {
  // API
  // Hooks
  // Components
  // Related Entity Objects

  TaxProduct: TaxProduct,
  TaxProductCategory: TaxProductCategory,
};

export default Invoice;
