import React from "react";
import { Modal, Spinner } from "react-bootstrap";

// Don't rename modalTitle to Title..
// there is something weird going on with that prop
export default function NoEscapeModal({
  modalTitle = "",
  bodyHeader,
  body,
  show,
  spinnerAnimation = "grow",
  spinnerVariant = "primary",
}) {
  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      {modalTitle !== "" ? (
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <></>
      )}
      <Modal.Body>
        <h4>{bodyHeader}</h4>
        <center>
          <Spinner animation={spinnerAnimation} vaiant={spinnerVariant} />
        </center>
        <p>{body}</p>
      </Modal.Body>
    </Modal>
  );
}
