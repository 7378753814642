import React from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import UserActivityTable from "../../activity/userActivity/UserActivityTable";
import StampDisplay from "../../common/components/StampDisplay";
import Page from "../../common/pages/Page";
import Server from "../../servers";
import ServerNavSidebar from "./ServerNavSidebar";

export default function ServerShow() {
  const id = parseInt(useParams().id);
  const { server, updateOne, loading } = Server.useOne({ id, canGet: true });
  const title = `Server${server?.description ? ": " + server.description : ""}`;

  const { isAdmin, canMaintainServers } = useSelector((state) => state.auth);

  return (
    <Page
      title={title}
      lead={
        loading ? "Loading..." : !server ? "Server not found" : server?.hostname
      }
      navSidebar={<ServerNavSidebar />}
    >
      {server ? (
        <>
          <Server.Editor
            values={server}
            onSubmit={(server) => updateOne(server)}
            isDisabled={loading || !(canMaintainServers || isAdmin)}
          />
          <StampDisplay record={server} />
          <Alert variant={"warning"}>
            <UserActivityTable
              userActivity={server.logs}
              isLoading={loading}
              layout={["dateTime", "userId", "description", "type"]}
            />
          </Alert>
        </>
      ) : null}
    </Page>
  );
}
