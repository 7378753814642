import React, { useState } from "react";
import { Button } from "react-bootstrap";

import useTinykeys from "../common/hooks/useTinykeys";
import ExpenseEditorModal from "./ExpenseEditorModal";
import { useExpense } from "./hooks";

export default function ExpenseNewButton({
  label = "Add Expense",
  onCreate,
  initialValues,
  disabled = false,
}) {
  const { createOne } = useExpense();
  const [showAdd, setShowAdd] = useState(false);

  useTinykeys(
    showAdd
      ? {}
      : {
          "Alt+KeyA": () => setShowAdd(true),
        },
  );
  return (
    <>
      <Button
        variant="success"
        onClick={() => setShowAdd(true)}
        disabled={disabled}
      >
        {label}
      </Button>
      <ExpenseEditorModal
        title="Add Expense"
        expense={initialValues}
        show={showAdd}
        onHide={() => setShowAdd(false)}
        onSubmit={(expense) =>
          createOne(expense).then(() => {
            onCreate();
            setShowAdd(false);
          })
        }
      />
    </>
  );
}
