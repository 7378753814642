import shark from "../../apis/shark";

function getCustomersAPI() {
  const payload = {
    command: "getCustomers",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.customers,
  }));
}

function getCustomerAPI({ id, fetch = true }, { getState }, { selectors }) {
  const payload = {
    command: "getCustomer",
    id: id,
  };
  if (fetch)
    return shark.post(payload).then((response) => ({
      data: response.responseData,
    }));
  else {
    return {
      data: selectors.selectById(getState(), id),
      updateState: false,
    };
  }
}

export const api = { getMany: getCustomersAPI, getOne: getCustomerAPI };
