import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import AppStateHandler from "./app/AppStateHandler";
import ErrorFallback from "./ErrorFallback";
import NavBarCommon from "./NavBarCommon";
import Routing from "./pages";
import Preloader from "./Preloader";
import useVersion from "./useVersion";

export default function App({ isLoading = false }) {
  return (
    <Router>
      <AppStateHandler />
      <NavBarCommon isLoading={isLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        transition={Slide}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VersionChecker />
        <Preloader isLoading={isLoading}>
          <Routing />
        </Preloader>
      </ErrorBoundary>
    </Router>
  );
}

function VersionChecker({ isLoading }) {
  useVersion({ blocked: isLoading });

  return null;
}
