import Fuse from "fuse.js";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import Table from "../../common/tables/Table";
import { TextColumnFilter } from "../../common/tables/TableFilters";
import GroupDescription from "./GroupDescription";
import Program from "./programs";

const GroupLookupByProgram = React.forwardRef(({ onSelect }, ref) => {
  const [results, setResults] = useState([]);
  const { programs } = Program.useAll();

  const onRowDoubleClick = (rowInfo) => {
    if (rowInfo.groupId !== null && rowInfo.groupId !== "")
      if (_.isFunction(onSelect)) onSelect(rowInfo.groupId);
  };

  const onRowDoubleClickProp = onRowDoubleClick
    ? { onRowDoubleClick: onRowDoubleClick }
    : {};

  const fuse = new Fuse(programs, {
    keys: ["programCode", "programDescription"],
    minMatchCharLength: 3,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Program",
        accessor: "programCode",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Description",
        accessor: "programDescription",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        Header: "Group",
        accessor: "groupId",

        Cell: ({ value }) => <GroupDescription id={value} />,
      },
    ],
    [],
  );

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Form.Group>
              <Form.Text className="text-muted pb-2">
                Type at least 3 characters then press <kbd>Enter</kbd>
              </Form.Text>
              <SearchInput
                onEnter={(q) => setResults(fuse.search(q).map((p) => p.item))}
                ref={ref}
              />
              {_.isFunction(onSelect) ? (
                <Form.Text className="text-muted">
                  <kbd>Double click</kbd> a row to select the group
                </Form.Text>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              hasCount
              label="Programs"
              columns={columns}
              data={results}
              {...onRowDoubleClickProp}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

const SearchInput = React.forwardRef(({ onEnter }, ref) => {
  const [query, setQuery] = useState("");
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      onEnter(query);
    }
  };
  return (
    <Form.Control
      ref={ref}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      onKeyDown={handleEnter}
    />
  );
});

export default GroupLookupByProgram;
