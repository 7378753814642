import shark from "../../../apis/shark";

function getNotificationGroupsAPI() {
  const payload = {
    command: "getNotificationGroups",
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData.notificationGroups }));
}

function getNotificationGroupAPI({ id }) {
  const payload = {
    command: "getNotificationGroup",
    id: id,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createNotificationGroupAPI(payload1) {
  const payload = {
    command: "createNotificationGroup",
    ...payload1,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateNotificationGroupAPI(payload1) {
  const payload = {
    command: "updateNotificationGroup",
    ...payload1,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function createNotificationMemberAPI(payload1) {
  const payload = {
    command: "createNotificationMember",
    ...payload1,
  };
  return shark.post(payload).then(({ responseData }) => responseData);
}

function deleteNotificationMemberAPI(payload1) {
  const payload = {
    command: "deleteNotificationMember",
    ...payload1,
  };
  return shark.post(payload).then(({ responseData }) => responseData);
}

export const api = {
  getMany: getNotificationGroupsAPI,
  getOne: getNotificationGroupAPI,
  createOne: createNotificationGroupAPI,
  updateOne: updateNotificationGroupAPI,
  addMember: createNotificationMemberAPI,
  deleteMember: deleteNotificationMemberAPI,
};
