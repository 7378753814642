import { createEntityHooks } from "../common/entity";
import parseIntId from "../common/functions/parseIntId";
import contactState from "./state";

export const {
  useEntities: useContacts,
  useEntity: useContact,
  useEntitiesBySearch: useContactsByCustomer,
} = createEntityHooks({
  entity: contactState.entity,
  actions: contactState.actions,
  parseId: (id) => {
    const nextId = parseIntId(id);
    return nextId === 0 ? undefined : nextId;
  },
});
