export const STATUSES = {
  P: {
    id: "P",
    description: "Passed",
    variant: "table-success",
  },
  W: {
    id: "W",
    description: "Warned",
    variant: "table-warning",
  },
  F: {
    id: "F",
    description: "Failed",
    variant: "table-danger",
  },
  O: {
    id: "O",
    description: "Open",
    variant: "table-light",
  },
};
