import Category from "./categories";
import ExpenseNewButton from "./ExpenseNewButton";
import ExpenseTable from "./ExpenseTable";
import { useExpense, useExpenses, useExpensesBySearch } from "./hooks";
import state from "./state";
import ExpenseTrip from "./trips";
import ExpenseType from "./types";

const Expense = {
  // Backend API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useAll: useExpenses,
  useSearch: useExpensesBySearch,
  useOne: useExpense,
  // Components
  Table: ExpenseTable,
  NewButton: ExpenseNewButton,
  // Related Entity Objects
  Trip: ExpenseTrip,
  Category: Category,
  Type: ExpenseType,
};
export default Expense;
