import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import { actions as jobActions } from "../../activity/jobs/state/slices";
import fs from "../../common/functions";
import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../common/functions/state";
import {
  approveProjectAPI,
  approveProjectsAPI,
  discountProjectAPI,
  refreshProjectsAPI,
} from "./api";

const approvalsAdapter = createEntityAdapter({
  selectId: (e) => fs.parseIntId(e.id),
});

const approveProject = createAsyncThunk(
  "approvals/approveProject",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().approvals.approvals, requestId)) return;
    const { responseData } = await approveProjectAPI(payload);
    // Clone the job object, then update a couple variables then dispatch
    // to a job's related job to properly update state.
    let myjob = JSON.parse(
      JSON.stringify(getState().activity.jobs.entities[responseData.jobId]),
    );

    const customers = myjob.output.customers;
    for (let c = 0; c < customers.length; c++) {
      if (payload.customerId === customers[c].customerId) {
        for (let i = 0; i < customers[c].projects.length; i++) {
          let x = customers[c].projects[i];
          if (x.project === responseData.projectId) {
            x.approved = responseData.approved;
            x.billThruDate = responseData.billThruDate;
          }
        }
        break;
      }
    }
    dispatch({
      type: jobActions.updateJob.type,
      payload: {
        ...myjob,
      },
    });
    return responseData;
  },
);

const discountProject = createAsyncThunk(
  "approvals/discountProject",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().approvals.approvals, requestId)) return;
    const { responseData } = await discountProjectAPI(payload);
    // Clone the job object, then update a couple variables then dispatch
    // to a job's related job to properly update state.
    let myjob = JSON.parse(
      JSON.stringify(getState().activity.jobs.entities[responseData.jobId]),
    );

    const customers = myjob.output.customers;
    for (let c = 0; c < customers.length; c++) {
      for (let i = 0; i < customers[c].projects.length; i++) {
        let x = customers[c].projects[i];
        if (x.project === responseData.projectId) {
          x.discountAmount = responseData.discountAmount;
          x.discountComment = responseData.discountComment;
        }
      }
      break;
    }
    dispatch({
      type: jobActions.updateJob.type,
      payload: {
        ...myjob,
      },
    });
    return responseData;
  },
);

const refreshProjects = createAsyncThunk(
  "approvals/refreshProjects",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().approvals.approvals, requestId)) return;
    const { responseData } = await refreshProjectsAPI(payload);
    // Clone the job object, then update a couple variables then dispatch
    // to a job's related job to properly update state.
    let myjob = JSON.parse(
      JSON.stringify(getState().activity.jobs.entities[responseData.jobId]),
    );

    const jobCusts = myjob.output.customers;
    const newCusts = responseData.customers;

    // run through the response data customers
    // for each one find it in the job clone
    // then loop thru projects in the job clone and find the "Fresh"
    // project data in the response and update the variables
    for (let n = 0; n < newCusts.length; n++) {
      const nc = newCusts[n];
      const jc = jobCusts.find((v) => nc.customerId === v.customerId);
      if (jc === undefined) continue;
      for (let p = 0; p < jc.projects.length; p++) {
        const jp = jc.projects[p];
        const np = nc.projects.find((v) => jp.project === v.projectId);
        if (np === undefined) continue;
        jp.discountAmount = np.discountAmount;
        jp.discountComment = np.discountComment;
        jp.billThruDate = np.billThruDate;
        jp.approved = np.approved;
      }
    }
    dispatch({
      type: jobActions.updateJob.type,
      payload: {
        ...myjob,
      },
    });
    return responseData;
  },
);

const approveProjects = createAsyncThunk(
  "approvals/approveProjects",
  async (payload, { getState, dispatch, requestId }) => {
    if (!testStateLoading(getState().approvals.approvals, requestId)) return;
    const { responseData } = await approveProjectsAPI(payload);
    // Clone the job object, then update a couple variables then dispatch
    // to a job's related job to properly update state.
    let myjob = JSON.parse(
      JSON.stringify(getState().activity.jobs.entities[responseData.jobId]),
    );

    const customers = myjob.output.customers;
    for (let c = 0; c < customers.length; c++) {
      if (payload.customerId === customers[c].customerId) {
        for (let j = 0; j < responseData.projects.length; j++) {
          let y = responseData.projects[j];
          if (!_.isEmpty(y.errorMessage)) {
            toast.error("Project: " + y.projectId + " " + y.errorMessage);
          }
          for (let i = 0; i < customers[c].projects.length; i++) {
            let x = customers[c].projects[i];
            if (x.project === y.projectId) {
              x.approved = y.approved;
              x.billThruDate = y.billThruDate;
              break;
            }
          }
        }
        break;
      }
    }
    dispatch({
      type: jobActions.updateJob.type,
      payload: {
        ...myjob,
      },
    });
    return responseData;
  },
);

const approvalsSlice = createSlice({
  name: "aprovals",
  initialState: approvalsAdapter.getInitialState({
    ...INITIAL_LOADING_STATE,
    hasMore: true,
    lastUpdate: moment().subtract(30, "days").toISOString(),
    filter: {},
  }),
  reducers: {
    clearApprovals(state) {
      approvalsAdapter.removeAll(state);
      state.requests = INITIAL_LOADING_STATE.requests;
      state.hasMore = true;
      state.lastUpdate = moment().subtract(30, "days").toISOString();
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, approveProject, (state) => {
      //const { hasMore, approvals } = action.payload;
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, approveProjects, (state) => {
      //const { hasMore, approvals } = action.payload;
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, discountProject, (state) => {
      //const { hasMore, approvals } = action.payload;
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, refreshProjects, (state) => {
      //const { hasMore, approvals } = action.payload;
      state.lastUpdate = new Date().toISOString();
    });
  },
});

export const sliceReducer = approvalsSlice.reducer;

const { clearApprovals } = approvalsSlice.actions;
export const actions = {
  approveProject,
  approveProjects,
  discountProject,
  refreshProjects,
  clearApprovals,
};

export const selectors = approvalsAdapter.getSelectors(
  (state) => state.approvals.approvals,
);
