import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "codeReview",
  type: "codeReviews",
  getState: (state) => state.codeReviews.codeReviews,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const codeReviewReducer = combineReducers({
  codeReviews: reducer,
});

const codeReviewState = {
  api,
  actions,
  reducer: codeReviewReducer,
  entity,
};

export default codeReviewState;
