import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { _date } from "../../common/functions/dates";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";

export default function CodeTestTable({ codeTests, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "id",
        id: "Date",
        Filter: SelectColumnFilter,
        filter: "equals",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/dbc/codeTests/${value}` }}
          >
            {_date.display(_date.fromStamp(value))}
          </Link>
        ),
      },
      {
        // Dont include self loading reusable reviews.
        Header: "Total Errors",
        id: "Total Errors",
        accessor: (r) => {
          return (
            r.eose +
            r.gete +
            r.imh2 +
            r.it01 +
            r.mkvr +
            r.ifer +
            r.nobl +
            r.tede +
            r.lmod +
            r.qury +
            r.rkqy +
            r.meme +
            r.swtc
          );
        },
      },
      {
        Header: "Reusable Reviews Needed",
        id: "Reusable Reviews Needed",
        accessor: "slre",
      },
      {
        Header: "EOS Errors",
        id: "Eos Errors",
        accessor: "eose",
      },
      {
        Header: "Get/Read Errors",
        id: "Get Errors",
        accessor: "gete",
      },
      {
        Header: "IMH2 Errors",
        id: "IMH2 Errors",
        accessor: "imh2",
      },
      {
        Header: "IT1 Errors",
        id: "IT1 Errors?",
        accessor: "it01",
      },
      {
        Header: "Makevar Errors",
        id: "Makevar Errors",
        accessor: "mkvr",
      },
      {
        Header: "IF Errors",
        id: "IF Errors",
        accessor: "ifer",
      },
      {
        Header: "Noblank Errors",
        id: "Noblank Errors",
        accessor: "nobl",
      },
      {
        Header: "TEDIT Errors",
        id: "TEDIT Errors",
        accessor: "tede",
      },
      {
        Header: "LMOD Errors",
        id: "LMOD Errors",
        accessor: "lmod",
      },
      {
        Header: "Query Errors",
        id: "Query Errors",
        accessor: "qury",
      },
      {
        Header: "Query/RK Errors",
        id: "Query/RK Errors",
        accessor: "rkqy",
      },
      {
        Header: "Memory Errors",
        id: "Memory Errors",
        accessor: "meme",
      },
      {
        Header: "Switch errors",
        id: "Switch Errors",
        accessor: "swtc",
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Code Test"
      columns={columns}
      data={codeTests}
      initialSort={{ id: "Date", desc: true }}
      {...props}
    />
  );
}
