import React from "react";
import { Link } from "react-router-dom";

export default function HostingAgreementLink({ id, children }) {
  return (
    <Link
      className="text-reset"
      to={{ pathname: `/customers/hosting-agreements/${id}` }}
    >
      {children || id}
    </Link>
  );
}
