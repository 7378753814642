import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import CustomerEdiSetup from "../../../edi/customerEdiSetups";
import EdiNavSidebar from "../EdiNavSidebar";

export default function CustomerEdiSetupNew() {
  const { createOne } = CustomerEdiSetup.useOne();
  const [, setRedirect] = useRedirect();

  const { canMaintainEdi, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainEdi || isAdmin))
    return (
      <Page
        title="New Customer Edi Setup"
        navSidebar={<EdiNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title={"New Customer Edi Setup"} navSidebar={<EdiNavSidebar />}>
      <CustomerEdiSetup.Editor
        values={{ isInactive: false }}
        onSubmit={(customerEdiSetup) =>
          createOne(customerEdiSetup).then(({ id }) =>
            setRedirect(`/edi/customerEdiSetups/${id}`),
          )
        }
      />
    </Page>
  );
}
