import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

import { Forms, FormsField } from "../../common/forms";
import Customer from "../../customers";
import branchSchema from "./branchSchema";

export default function BranchEditor({ values = {}, onSubmit, isLoading }) {
  return (
    <>
      <Forms
        defaultValues={values}
        onSubmit={onSubmit}
        schema={{ schema: branchSchema }}
        showDevTool
        isDisabled={true}
      >
        <BranchForm isLoading={isLoading} />
      </Forms>
    </>
  );
}

function BranchForm() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <BranchTextField id="id" label="Id" />
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="inactive" label="Inactive" />
            </Col>
          </Form.Row>

          <Form.Row>
            <BranchTextField id="description" label="Description" />
          </Form.Row>

          <Form.Row className="pb-0 my-0">
            <Col>
              <Form.Group id={"customerId"} className="my-0">
                <InputGroup className="my-0">
                  <InputGroup.Prepend className="mb-2">
                    <InputGroup.Text
                      className="bg-secondary text-light"
                      style={{ minWidth: "150px" }}
                    >
                      {"Customer"}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Row className="my-0">
                    <Col xs="auto">
                      <FormsField.Select
                        name="customerId"
                        as={Customer.Select}
                        formGroupClassName="mb-2"
                        styles={{
                          input: (style) => ({
                            ...style,
                            minWidth: 243,
                          }),
                          container: (style) => ({
                            ...style,
                            width: "auto",
                          }),
                          menu: (style) => ({
                            ...style,
                            width: "150%",
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                </InputGroup>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <BranchTextField id="folder" label="Folder" />
          </Form.Row>
          <Form.Row>
            <BranchTextField id="setsys" label="System" />
          </Form.Row>
          <Form.Row>
            <BranchTextField id="server" label="Server" />
          </Form.Row>
          <Form.Row>
            <BranchTextField id="gate" label="Gateway server" />
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}

function BranchTextField({ id, label }) {
  return (
    <Col xs="auto">
      <Form.Group id={id} className="my-0">
        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text
              className="bg-secondary text-light"
              style={{ minWidth: "150px" }}
            >
              {label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormsField.Control
            name={id}
            autoComplete="off"
            style={{ minWidth: "300px" }}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  );
}
