import shark from "../../../apis/shark";

function getTaxProductCategoriesAPI() {
  const payload = {
    command: "getTaxProductCategories",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.taxProductCategories,
  }));
}

function getTaxProductCategoryAPI({ id }) {
  const payload = {
    command: "getTaxProductCategory",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createTaxProductCategoryAPI(taxProductCategory) {
  const payload = {
    command: "createTaxProductCategory",
    ...taxProductCategory,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateTaxProductCategoryAPI(taxProductCategory) {
  const payload = {
    command: "updateTaxProductCategory",
    ...taxProductCategory,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getTaxProductCategoriesAPI,
  getOne: getTaxProductCategoryAPI,
  createOne: createTaxProductCategoryAPI,
  updateOne: updateTaxProductCategoryAPI,
};
