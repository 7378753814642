import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import CustomerNavSidebar from "../CustomerNavSidebar";

export default function JiraProjectNavSidebar({ children }) {
  return (
    <CustomerNavSidebar>
      <NavSidebarItem to={`/customers/jira-projects`}>
        Jira Projects
      </NavSidebarItem>
      <NavSidebarItem to="/customers/jira-projects/new">
        New Jira Project
      </NavSidebarItem>
      <div className="dropdown-divider" />
      {children}
    </CustomerNavSidebar>
  );
}
