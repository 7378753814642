import storage from "localforage";
import { persistReducer } from "redux-persist";

import { reducer } from "./slices";

const persistConfig = {
  key: "auth",
  version: 1,
  storage,
  whitelist: [],
};

export default persistReducer(persistConfig, reducer);
