import { combineReducers } from "@reduxjs/toolkit";

import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "estimate",
  type: "estimates",
  getState: (state) => state.estimates.estimates,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const estimateReducer = combineReducers({
  estimates: reducer,
});

const estimateState = {
  api,
  actions,
  reducer: estimateReducer,
  entity,
};

export default estimateState;
