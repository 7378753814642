import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";
import { api } from "./api";

const entity = createEntity({
  name: "hostingAgreementMonth",
  type: "customers/hostingAgreementMonths",
  getState: (state) => state.customers.hostingAgreementMonths,
});

const actions = {
  ...createEntityActions({ entity, api }),
};
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const hostingAgreementMonthsState = {
  api,
  entity,
  actions,
  reducer,
};

export default hostingAgreementMonthsState;
