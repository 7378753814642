import { isEmpty } from "lodash";
import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Prompt } from "react-router";

import GroupOwnerFields from "../common/components/forms/GroupOwnerFields";
import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../common/forms";
import Priority from "./priorities";
import ProjectAlerts from "./ProjectAlerts";
import ProjectCustomersEdit from "./ProjectCustomersEdit";
import projectSchema from "./projectSchema";
import Source from "./sources";
import Type from "./types";

export default function ProjectEditor({
  project,
  isNew = false,
  onSubmit,
  error = "",
  onCancel,
}) {
  const transferred =
    project?.transferredFolder && project?.transferredFolder !== "CURRENT";
  const isWeb = project?.owner === "*WEB*";

  const canBypassContractRestriction = useSelector(
    (state) => state.auth.canBypassContractRestriction,
  );
  return (
    <Forms
      onSubmit={onSubmit}
      defaultValues={project}
      schema={{ schema: projectSchema }}
      context={{ canBypassContractRestriction }}
      isDisabled={transferred}
      showDevTool
    >
      <>
        {error ? (
          <Row>
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        ) : null}
        <ProjectAlerts project={project} />
        <ProjectForms isNew={isNew} isWeb={isWeb} onCancel={onCancel} />
      </>
    </Forms>
  );
}

function ProjectForms({ isNew, isWeb, onCancel }) {
  const {
    register,
    submitForm,
    formState: { isSubmitting, dirtyFields },
    formsContext: { isDisabled },
  } = useFormContext();
  const isFieldsDirty = !isEmpty(dirtyFields);

  useFormsKeys();

  return (
    <Form onSubmit={submitForm}>
      <Prompt
        when={isFieldsDirty && !isNew}
        message="Changes may be unsaved, are you sure you want to leave?"
      />
      <ProjectCustomersEdit name="customers" isNew={isNew} />
      <Row>
        <Col sm="8" className="border-right">
          <Row>
            <Col>
              <FormsField name="synopsis" label="Synopsis" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormsField.TextArea
                name="description"
                label="Description"
                minRows={6}
              />
            </Col>
          </Row>
        </Col>
        <Col sm="4">
          <GroupOwnerFields isNew={isWeb || isNew} />
          <FormsField.Select name="typeId" label="Type" as={Type.Select} />
          <FormsField.Select
            name="priorityId"
            label="Priority"
            as={Priority.Select}
          />
          <FormsField.Select
            name="sourceId"
            label="Source"
            as={Source.Select}
          />
          <Row>
            <Col>
              <Button
                type="submit"
                className={isSubmitting ? "disabled-btn" : ""}
                disabled={isSubmitting || isDisabled}
                block
              >
                {isNew ? "Create" : "Save"} Project
              </Button>
            </Col>
            <Col>
              {isNew ? (
                <Form.Check
                  id="createCr"
                  label="Create matching CR?"
                  {...register("createCr")}
                />
              ) : null}
            </Col>
          </Row>
          {onCancel ? (
            <Row>
              <Col>
                <Button
                  variant="danger"
                  onClick={onCancel}
                  className={isSubmitting ? "disabled-btn" : ""}
                  disabled={isSubmitting || isDisabled}
                  block
                >
                  Cancel
                </Button>
              </Col>
              <Col></Col>
            </Row>
          ) : null}
          {!isNew ? (
            <Row className="mt-3">
              <Col>
                <FormsField.TextArea
                  name="fixNotes"
                  label="Description"
                  minRows={12}
                  placeholder="Fix notes..."
                />
                <Form.Check
                  id="isSeparateInvoice"
                  label="Separate Invoice?"
                  {...register("isSeparateInvoice")}
                />
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Form>
  );
}
