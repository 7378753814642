import React from "react";
import { Badge } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";

import useSettings from "../../settings/useSettings";

export default function Mailto({
  email = "",
  subject = "",
  body = "",
  as: Component = "a",
  children,
  copy = true,
  ...props
}) {
  const {
    settings: { gmailEmailFromAccount },
  } = useSettings();
  if (!email && !subject && !body) return null;

  const mailto = `mailto:${email}?subject=${
    encodeURIComponent(subject) || ""
  }&body=${encodeURIComponent(body) || ""}`;
  const href = gmailEmailFromAccount
    ? `https://mail.google.com/mail/b/${gmailEmailFromAccount}/?extsrc=mailto&url=${encodeURIComponent(
        mailto,
      )}`
    : mailto;

  return (
    <>
      <Component
        {...props}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children ? children : email || "Email"}
      </Component>{" "}
      {copy ? (
        <CopyToClipboard
          text={email}
          onCopy={() => toast.success("Email copied")}
        >
          <Badge as="a" variant="light" title="Click to copy">
            <BiCopy />
          </Badge>
        </CopyToClipboard>
      ) : null}
    </>
  );
}
