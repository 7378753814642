import React from "react";
import { Button } from "react-bootstrap";

import helixUrl from "../functions/helixUrl";

export default function HelixLink({ revisionType, revision }) {
  return (
    <Button
      variant="link"
      className="py-0 my-0"
      onClick={() => {
        let url = helixUrl(revisionType, revision);
        window.open(url, "_blank");
      }}
    >
      {revision}
    </Button>
  );
}
