import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";

import Page from "../../../common/pages/Page";
import AwsZoneEdit from "./AwsZoneEdit";
import AwsZoneIndex from "./AwsZoneIndex";
import AwsZoneNavSidebar from "./AwsZoneNavSidebar";
import AwsZoneNew from "./AwsZoneNew";

export default function AwsZonesPages() {
  let { path } = useRouteMatch();

  const { isAdmin } = useSelector((state) => state.auth);

  if (!isAdmin)
    return (
      <Page
        title="Aws Zone"
        navSidebar={<AwsZoneNavSidebar />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <AwsZoneNew />
      </Route>
      <Route path={`${path}/:id`}>
        <AwsZoneEdit />
      </Route>
      <Route exact path={`${path}`}>
        <AwsZoneIndex />
      </Route>
    </Switch>
  );
}
