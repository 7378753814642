import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";

export default function ReusableProgramTable({ reusablePrograms, ...props }) {
  const columns = useMemo(
    () => [
      {
        Header: "Program",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/dbc/reusablePrograms/${value}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Reviewed?",
        id: "Reviewed?",
        accessor: ({ isReviewed }) =>
          isReviewed ? String.fromCodePoint("0x1f44d") : " ",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Reviewed By",
        id: "Reviewed By",
        accessor: "reviewedBy",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Source Code"
      columns={columns}
      data={reusablePrograms}
      {...props}
    />
  );
}
