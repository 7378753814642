import { entitySelect } from "../../common/entity";
import { useTypes } from "./hooks";

const TypeSelect = entitySelect({
  name: "projectType",
  useEntities: useTypes,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default TypeSelect;
