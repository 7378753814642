import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ProjectEdit from "./ProjectEdit";
import ProjectIndex from "./ProjectIndex";
import ProjectNew from "./ProjectNew";
import ProjectShow from "./ProjectShow";
import ProjectShowLog from "./ProjectShowLog";
import ProjectShowTime from "./ProjectShowTime";

export default function ProjectsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <ProjectNew />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <ProjectEdit />
      </Route>
      <Route path={`${path}/:id/time`}>
        <ProjectShowTime />
      </Route>
      <Route path={`${path}/:id/log`}>
        <ProjectShowLog />
      </Route>
      <Route path={`${path}/:id`}>
        <ProjectShow />
      </Route>
      <Route exact path={`${path}`}>
        <ProjectIndex />
      </Route>
    </Switch>
  );
}
