import ExpenseTripUserDescription from "./ExpenseTripUserDescription";
import ExpenseTripUserSelect from "./ExpenseTripUserSelect";
import ExpenseTripUserTableFilter from "./ExpenseTripUserTableFilter";
import {
  useExpenseTripUser,
  useExpenseTripUserByFind,
  useExpenseTripUsers,
} from "./hooks";
import expenseTripUserState from "./state";

const ExpenseTripUser = {
  // Backend API
  api: expenseTripUserState.api,
  // State
  actions: expenseTripUserState.actions,
  selectors: expenseTripUserState.entity.selectors,
  //Hooks
  useAll: useExpenseTripUsers,
  useOne: useExpenseTripUser,
  useOneByFind: useExpenseTripUserByFind,
  //Components
  Select: ExpenseTripUserSelect,
  Description: ExpenseTripUserDescription,
  TableFilter: ExpenseTripUserTableFilter,
};
export default ExpenseTripUser;
