import React from "react";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Dbc from "../../../dbc";
import DbcNavSidebar from "../DbcNavSidebar";

export default function SourceCodeShow() {
  const id = useParams().id;
  const { sourceCode, loading } = Dbc.SourceCode.useOne({
    id: id,
    canGet: true,
  });

  return (
    <Page title="Source Code" navSidebar={<DbcNavSidebar />}>
      {sourceCode ? (
        <Dbc.SourceCode.Editor values={sourceCode} isLoading={loading} />
      ) : (
        <h4>Source Code not found.</h4>
      )}
    </Page>
  );
}
