import _ from "lodash";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import NavSidebar from "../navs/NavSidebar";

export default function Page({
  title,
  heading,
  lead,
  navSidebar,
  children,
  tableOfContents,
}) {
  const contents = _.isUndefined(tableOfContents)
    ? React.Children.map(children, (c) => c?.props?.title)
    : tableOfContents;

  if (title) document.title = title;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if they don't want a nav side bar, then LG and XL viewing formats
  // will be closer to 12.
  // if they don't want table of contents then XL side will be closer to 12.
  // LG already ignores Table of contents.
  // MD ignores both.
  const lgFactor = navSidebar === false ? 0 : 2;
  const xlFactor = tableOfContents === false ? 0 : 2;
  const colSizes = { xl: 12 - lgFactor - xlFactor, lg: 12 - lgFactor, md: 12 };

  return (
    <Container fluid>
      <Row className="page">
        {navSidebar !== false ? (
          <Col className="page-nav-sidebar" lg={2}>
            {navSidebar}
          </Col>
        ) : null}
        <Col {...colSizes} className="content">
          <Row>
            <Col>
              <h1>{heading ? heading : title}</h1>
            </Col>
          </Row>

          <Row className="content body pb-5">
            <Col>
              <Row>
                <Col>
                  <p className="lead">{lead}</p>
                </Col>
              </Row>
              <Row>
                <Col>{children}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {contents ? <TableOfContents contents={contents} /> : null}
      </Row>
    </Container>
  );
}

function TableOfContents({ contents }) {
  return (
    <Col
      as="nav"
      xl={2}
      className="page-nav-toc"
      // style={{
      //   position: ["-webkit-sticky", "sticky"],
      //   top: "4rem",
      //   height: "100%",
      //   overflowY: "auto",
      // }}
    >
      <NavSidebar id="nav-toc">
        {contents?.map((c) => (
          <li className="pb-2" key={`toc-${c}`}>
            <a
              href={`#${c.toLowerCase()}`}
              className="text-muted text-decoration-none"
            >
              {c}
            </a>
          </li>
        ))}
      </NavSidebar>
    </Col>
  );
}
