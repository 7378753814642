import React, { useMemo } from "react";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import AwsInstanceTypeLink from "../../servers/awsInstanceTypes/AwsInstanceTypeLink";
import AwsZoneLink from "../../servers/awsZones/AwsZoneLink";
import CustomerLink from "../CustomerLink";
import CustomerTableFilter from "../CustomerTableFilter";
import HostingAgreementLink from "../hostingAgreements/HostingAgreementLink";
import { HOSTING_TYPES } from "../hostingAgreements/types";

export default function HostingAgreementMonthTable({
  data,
  Components: OverrideComponents,
  layout = [
    "Customer",
    "Hosting Agreement",
    "Server Type",
    "Billing Description",
    "Amount",
    "Status",
    "AWS Instance Id",
    "AWS Zone",
    "AWS Instance Type",
    "Information",
    "Status",
  ],
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        id: "Customer",
        Header: "Customer Name",
        accessor: "customerId",
        Filter: CustomerTableFilter,
        filter: "text",
        Cell: ({ value }) => <CustomerLink id={value} />,
      },
      {
        id: "Hosting Agreement",
        Header: "Hosting Agreement",
        accessor: "hostingAgreementId",
        Cell: ({ value }) => <HostingAgreementLink id={value} />,
      },
      {
        id: "Status",
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value === "C" ? "Charge created" : "Open"),
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Server Type",
        Header: "Server Type",
        accessor: (l) => HOSTING_TYPES[l?.serverType]?.description || "",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "Amount",
        Header: "Amount $",
        accessor: "amount",
      },
      {
        id: "Billing Description",
        Header: "Billing Description",
        accessor: "billingDescription",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "Information",
        Header: "Information",
        accessor: "information",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "AWS Instance Id",
        Header: "AWS Instance Id",
        accessor: "awsInstanceId",
        Filter: TextColumnFilter,
        filter: "text",
      },
      {
        id: "AWS Zone",
        Header: "AWS Zone",
        accessor: "awsZone",
        Cell: ({ value }) => <AwsZoneLink id={value} />,
      },
      {
        id: "AWS Instance Type",
        Header: "AWS Instance Type",
        accessor: "awsInstanceType",
        Cell: ({ value }) => <AwsInstanceTypeLink id={value} />,
      },
    ],
    [],
  );
  return (
    <Table
      hasCount
      label="Hosting Agreements"
      columns={columns}
      layout={layout}
      data={data}
      getRowProps={(row) => {
        if (row.isGrouped) return {};
        if (row?.original.status === "C") {
          return {
            className: "table-success",
          };
        } else if (row?.original.exceptionsExist) {
          return {
            className: "table-warning",
          };
        } else {
          return {};
        }
      }}
      {...props}
    />
  );
}
