import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useRedirect from "../common/hooks/useRedirect";
import projectState from "./state";

export default function useProject({
  id,
  logActivity = false,
  redirect = true,
  includeLogs = false,
  canGet = true,
} = {}) {
  const project = useSelector((state) =>
    projectState.entity.selectors.selectById(state, id),
  );

  const dispatch = useDispatch();
  const [redirecting, setRedirect] = useRedirect();
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const { getProject, lastUpdate, loading } = useProjectGetOne({
    id,
    logActivity,
    includeLogs,
  });

  const shouldGet =
    !isNaN(id) &&
    (canGet === true || (canGet !== false && _.isUndefined(project)));

  useEffect(() => {
    if (shouldGet)
      getProject()
        .catch(setError)
        .finally(() => setLoaded(true));
  }, [getProject, shouldGet]);

  return {
    project,
    loading: shouldGet && (loading || !loaded || !!redirecting),
    error,
    lastUpdate,
    getProject,
    createOne: (project) =>
      dispatch(projectState.actions.createOne(project))
        .then(unwrapResult)
        .then(({ data: result } = {}) => {
          const projectId = result.id;
          if (redirect) {
            if (project?.createCr && result?.crs[0].id)
              setRedirect("/crs/" + result?.crs[0].id);
            else setRedirect("/projects/" + projectId);
          }
          return result;
        }),
    updateOne: (changes, updateCrPrompt) =>
      dispatch(
        projectState.actions.updateOne({
          id: id,
          data: changes,
          updateCrPrompt,
        }),
      )
        .then(unwrapResult)
        .then(({ data: result } = {}) => {
          if (redirect) setRedirect("/projects/" + id);
          return result;
        })
        .catch((err) => {
          if (err?.message === "Sync error") {
            getProject().then(() => {
              throw new Error("CR out of date, please try again");
            });
          } else {
            throw err;
          }
        }),
    createComment: ({ title, text }) =>
      dispatch(
        projectState.actions.createComment({
          project: project?.id,
          title,
          text,
        }),
      ),
    updateComment: ({ id, title, text }) =>
      dispatch(
        projectState.actions.updateComment({
          project: project?.id,
          id,
          title,
          text,
        }),
      ),
    createAttachment: (args) =>
      dispatch(
        projectState.actions.createAttachment({
          id,
          ...args,
        }),
      ).then(unwrapResult),

    updateWatch: (args) =>
      dispatch(
        projectState.actions.updateWatch({ id: project?.id, ...args }),
      ).then(unwrapResult),
  };
}

useProject.getOne = useProjectGetOne;

function useProjectGetOne({
  id,
  logActivity = false,
  includeLogs = false,
} = {}) {
  const loading = useSelector(
    (state) => state.projects.projects.requests.loading,
  );
  const dispatch = useDispatch();
  const [lastUpdate, setLastUpdate] = useState(null);
  const getProject = useCallback(
    (args) =>
      dispatch(
        projectState.actions.getOne({
          id: id,
          logActivity,
          includeLogs,
          ...args,
        }),
      )
        .then(unwrapResult)
        .then((result) => {
          setLastUpdate(moment());
          return result;
        }),
    [id, dispatch, includeLogs, logActivity],
  );

  return { getProject, lastUpdate, loading };
}
