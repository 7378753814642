import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

import useReinitState from "../hooks/useReinitState";

export default function FieldInput({
  id,
  as = "input",
  type = "text",
  value,
  label,
  onChange = () => {},
  onChangeEvent = () => {},
  onChangeOnBlur,
  onBlur,
  name,
  errors,
  ...props
}) {
  const [fieldValue, setFieldValue] = useReinitState(value);
  const [focus, setFocus] = useState(false);

  const canOnChangeOnBlur = _.isUndefined(onChangeOnBlur)
    ? as !== "select"
    : onChangeOnBlur;

  const formValue =
    _.isUndefined(fieldValue) || _.isNull(fieldValue) ? "" : fieldValue;

  function parseValue(v) {
    switch (type) {
      case "number":
        return parseFloat(v);
      case "currency":
        return parseFloat(v);
      default:
        return `${v}`;
    }
  }

  function handleOnBlur(e) {
    if (onBlur) onBlur(e);
    if (!canOnChangeOnBlur) return;
    setFocus(false);
    if (focus && parseValue(fieldValue) !== parseValue(value))
      onChange(parseValue(fieldValue));
  }

  return (
    <Form.Group>
      {label ? <Form.Label htmlFor={id}>{label}</Form.Label> : null}
      <Form.Control
        {...props}
        as={as}
        type={type}
        value={formValue}
        onChange={(e) => {
          canOnChangeOnBlur
            ? setFieldValue(e.target.value)
            : onChange(e.target.value);
          onChangeEvent(e);
        }}
        onFocus={() => setFocus(true)}
        onBlur={handleOnBlur}
        id={id}
        name={name ? name : id}
      />
      {errors ? (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}
