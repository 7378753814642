import { createEntityHooks } from "../common/entity";
import parseIntId from "../common/functions/parseIntId";
import estimateState from "./state";

export const {
  useEntities: useEstimates,
  useEntity: useEstimate,
  useEntitiesBySearch: useEstimatesBySource,
} = createEntityHooks({
  entity: estimateState.entity,
  actions: estimateState.actions,
  parseId: (id) => {
    const nextId = parseIntId(id);
    return nextId === 0 ? undefined : nextId;
  },
});
