import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../../common/tables/TableFilters";
import User from "../../users";

export default function SourceCodeTable({
  sourceCode,
  hasMore,
  getMore,
  isLoading,
  ...props
}) {
  const columns = useMemo(
    () => [
      {
        Header: "Filename",
        accessor: "id",
        Filter: TextColumnFilter,
        filter: "text",
        Cell: ({ value }) => (
          <Link
            className="text-decoration-none"
            to={{ pathname: `/dbc/sourceCode/${value}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Group",
        id: "Group",
        accessor: "groupId",
        Filter: User.Group.TableFilter,
        filter: "equals",
        Cell: ({ value }) => <User.Group.Description id={value} />,
      },
      {
        Header: "Owner",
        id: "Owner",
        accessor: "ownerId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "uniqueCount",
        Cell: ({ value }) => <User.Link id={value} showId={true} />,
      },
    ],
    [],
  );

  return (
    <Table
      hasCount
      label="Source Code"
      columns={columns}
      data={sourceCode}
      isLoading={isLoading}
      {...props}
    />
  );
}
