import shark from "../../../apis/shark";
import {
  createEntity,
  createEntityActions,
  createEntityReducer,
} from "../../../common/entity";

const api = {
  getMany: () => {
    return shark.post({ command: "getReusablePrograms" }).then((response) => {
      return { data: response.responseData.reusablePrograms };
    });
  },
  getOne: ({ id }) => {
    return shark
      .post({ command: "getReusableProgram", id: id })
      .then((response) => {
        return { data: response.responseData };
      });
  },
};

const entity = createEntity({
  name: "reusablePrograms",
  type: "dbc/reusablePrograms",
  getState: (state) => state.dbc.reusablePrograms,
});
const actions = createEntityActions({ entity, api });
const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
});

const reusableProgramState = {
  api,
  entity,
  actions,
  reducer,
};

export default reusableProgramState;
