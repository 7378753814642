import { createEntityReducer } from "../../common/entity";
import { buildAsyncThunkReducer } from "../../common/functions/state";
import { actions } from "./actions";
import { entity } from "./entity";

export const reducer = createEntityReducer({
  entity,
  actions,
  persist: true,
  extraReducers: (builder, adapter) => {
    buildAsyncThunkReducer(builder, actions.getRate, (state, action) => {
      if (action?.payload?.rate) state.rate = action?.payload?.rate;
    });
    buildAsyncThunkReducer(
      builder,
      actions.createServerLogin,
      (state, { payload: { data } = {} }) => {
        const { serverLoginPassword, ...dataNew } = data || {};
        adapter.upsertOne(state, dataNew);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.deleteServerLogin,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateServerLoginKeys,
      (state, { payload: { data } = {} }) => {
        adapter.upsertOne(state, data);
      },
    );
    buildAsyncThunkReducer(
      builder,
      actions.updateServerLoginPassword,
      (state, { payload: { data } = {} }) => {
        const { serverLoginPassword, ...dataNew } = data || {};
        adapter.upsertOne(state, dataNew);
      },
    );
  },
});
