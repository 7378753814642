import React from "react";

import { useServersByCustomer } from "./hooks";
import ServerTable from "./ServerTable";

const layout = ["name", "title", "types", "phones", "email"];

export default function ServerTableByCustomer({ customerId }) {
  const { servers, loading, reset } = useServersByCustomer({
    search: { customerId },
    canGet: true,
  });

  return (
    <ServerTable
      servers={servers}
      layout={layout}
      isLoading={loading}
      onRefresh={() => reset({ customerId })}
      skeletonLines={8}
    />
  );
}
