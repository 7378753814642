import CrAlerts from "./CrAlerts";
import CrEditor from "./CrEditor";
import CrEstimateEditor from "./CrEstimateEditor";
import CrLink from "./CrLink";
import List from "./CrList";
import CrNew from "./CrNew";
import crSchema, { crSchemaInitial } from "./crSchema";
import CrSearch from "./CrSearch";
import CrSelect from "./CrSelect";
import CrShareButton from "./CrShareButton";
import CrStatusEditor from "./CrStatusEditor";
import Table from "./CrTable";
import CrTableSettingsEditor from "./CrTableSettingsEditor";
import CrTargetDatesTable from "./CrTargetDatesTable";
import DefectOccurrence from "./defectOccurrences";
import Difficulty from "./difficulties";
import CrFilter from "./filters";
import getCrPriorityClassName from "./getCrPriorityClassName";
import Module from "./modules";
import NotificationType from "./notificationTypes";
import QaStatus from "./qaStatuses";
import crState from "./state";
import CrStatus from "./statuses";
import TableLayout from "./tableLayouts";
import updateCrPrompt from "./updateCrPrompt";
import useCr from "./useCr";
import useCrs from "./useCrs";
import useCrsByFilter from "./useCrsByFilter";
import useCrTableColumns from "./useCrTableColumns";
import useCrTableSettings from "./useCrTableSettings";

const Cr = {
  // Backend API
  api: crState.api,
  // State
  actions: crState.actions,
  selectors: crState.entity.selectors,
  // Hooks
  useAll: useCrs,
  useOne: useCr,
  useSearch: useCrsByFilter,
  useTableSettings: useCrTableSettings,
  useTableColumns: useCrTableColumns,
  // Functions,
  initialSchema: crSchemaInitial,
  schema: crSchema,
  getPriorityClassName: getCrPriorityClassName,
  updatePrompt: updateCrPrompt,
  // Components
  New: CrNew,
  Editor: CrEditor,
  Table,
  List,
  Link: CrLink,
  Alerts: CrAlerts,
  StatusEditor: CrStatusEditor,
  Select: CrSelect,
  Search: CrSearch,
  TableSettingsEditor: CrTableSettingsEditor,
  ShareButton: CrShareButton,
  EstimateEditor: CrEstimateEditor,
  TargetDatesTable: CrTargetDatesTable,
  // Related Entity Objects
  Module: Module,
  Status: CrStatus,
  Difficulty: Difficulty,
  DefectOccurrence: DefectOccurrence,
  NotificationType: NotificationType,
  QaStatus: QaStatus,
  TableLayout: TableLayout,
  Filter: CrFilter,
};

export default Cr;
