import ContractProductCategoryEditor from "./ContractProductCategoryEditor";
import ContractProductCategoryLink from "./ContractProductCategoryLink";
import ContractProductCategorySelect from "./ContractProductCategorySelect";
import Table from "./ContractProductCategoryTable";
import ContractProductCategoryTableFilter from "./ContractProductCategoryTableFilter";
import {
  useContractProductCategories,
  useContractProductCategory,
} from "./hooks";
import contractProductCategoryState from "./state";

const ContractProductCategory = {
  // API
  api: contractProductCategoryState.api,
  // State
  actions: contractProductCategoryState.actions,
  selectors: contractProductCategoryState.entity.selectors,
  // Hooks
  useOne: useContractProductCategory,
  useAll: useContractProductCategories,
  // Components
  TableFilter: ContractProductCategoryTableFilter,
  Table,
  Link: ContractProductCategoryLink,
  Editor: ContractProductCategoryEditor,
  Select: ContractProductCategorySelect,
  // Related Entity Objects
};

export default ContractProductCategory;
