import React from "react";
import { Modal } from "react-bootstrap";

import TimeNew from "./TimeNew";

export default function TimeNewModal({
  show,
  onHide,
  onCancel,
  onSave,
  userId,
  date,
  crId,
  startTimer = false,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Time Record
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <TimeNew
          defaultUser={userId}
          defaultDate={date}
          defaultCrId={crId}
          startTimer={startTimer}
          onSaveSuccess={onSave}
          onCancel={onCancel}
        />
      </Modal.Body>
    </Modal>
  );
}
