import { createAsyncThunk } from "@reduxjs/toolkit";

import { createEntityActions } from "../../common/entity";
import { api } from "./api";
import { entity } from "./entity";

const entityActions = createEntityActions({ entity, api });

const createAttachment = createAsyncThunk(
  "expenses/attachments/create",
  async (args) => {
    const response = await api.createAttachment(args);
    return response;
  },
);

export const actions = { ...entityActions, createAttachment };
