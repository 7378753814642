import React from "react";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import JiraProject from "../../../customers/jiraProjects";
import JiraProjectNavSidebar from "./JiraProjectNavSidebar";

export default function JiraProjectNew() {
  const { createOne } = JiraProject.useOne();
  const [, setRedirect] = useRedirect();
  return (
    <Page title={"New Jira Project"} navSidebar={<JiraProjectNavSidebar />}>
      <JiraProject.Editor
        isNew
        values={{ isActive: true }}
        onSubmit={(jiraProject) =>
          createOne(jiraProject).then(({ id }) =>
            setRedirect(`/jira-projects/${id}`),
          )
        }
      />
    </Page>
  );
}
