import _ from "lodash";
import React, { useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";

import Table from "../common/tables/Table";

function toFixed(value, d) {
  const valueFloat = parseFloat(value);
  if (!value || _.isNaN(valueFloat) || valueFloat === 0) return null;
  return parseFloat(value).toFixed(d);
}

function NumberFormatter({ row, value, variant }) {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix={row?.original?.prefix}
      renderText={(value) =>
        variant ? (
          <div className={"table-" + variant + " w-100"}>{value}</div>
        ) : (
          <span> {value}</span>
        )
      }
    />
  );
}

export default function TimeEstimateTable({
  estimate,
  actual,
  estimateSubtitle,
  isLoading,
  onEdit,
}) {
  const hasEstimate = estimate?.minCost || estimate?.maxCost;
  const data = isLoading
    ? []
    : [
        {
          prefix: "$",
          header: "Bill $",
          estimateMin: toFixed(estimate?.minCost, 2),
          estimateMax: toFixed(estimate?.maxCost, 2),
          actualInvoiced: toFixed(actual?.invoicedBlCost, 2),
          actualTotal: toFixed(
            _.sum([actual?.billableCost, actual?.invoicedBlCost]),
            2,
          ),
          actualOpen: toFixed(actual?.billableCost, 2),
          actualVariant:
            _.sum([actual?.billableCost, actual?.invoicedBlCost]) >
            estimate?.maxCost
              ? "warning"
              : "success",
        },
        {
          prefix: "$",
          header: "N/B $",
          estimateMin: toFixed(0, 2),
          estimateMax: toFixed(0, 2),
          actualInvoiced: toFixed(actual?.invoicedNbCost, 2),
          actualTotal: toFixed(
            _.sum([actual?.nonBillCost, actual?.invoicedNbCost]),
            2,
          ),
          actualOpen: toFixed(actual?.nonBillCost, 2),
          actualVariant:
            _.sum([actual?.billableCost, actual?.invoicedBlCost]) <
              estimate?.maxCost &&
            _.sum([actual?.nonBillCost, actual?.invoicedNbCost]) > 0
              ? "warning"
              : "danger",
        },
        {
          header: "Bill Hours",
          estimateMin: toFixed(estimate?.minHours, 2),
          estimateMax: toFixed(estimate?.maxHours, 2),
          actualInvoiced: toFixed(actual?.invoicedBlHours, 2),
          actualTotal: toFixed(
            _.sum([actual?.billableHours, actual?.invoicedBlHours]),
            2,
          ),
          actualOpen: toFixed(actual?.billableHours, 2),
        },
        {
          header: "N/B Hours",
          estimateMin: toFixed(0, 2),
          estimateMax: toFixed(0, 2),
          actualInvoiced: toFixed(actual?.invoicedNbHours, 2),
          actualTotal: toFixed(
            _.sum([actual?.nonBillHours, actual?.invoicedNbHours]),
            2,
          ),
          actualOpen: toFixed(actual?.nonBillHours, 2),
        },
        {
          header: "Rate",
          estimateMin: toFixed(estimate?.rate, 2),
          actualTotal: toFixed(
            _.sum([
              actual?.nonBillHours,
              actual?.invoicedNbHours,
              actual?.billableHours,
              actual?.invoicedBlHours,
            ]),
            2,
          ),
        },
      ];

  const columns = useMemo(
    () => [
      {
        id: "estimate",
        Header: (
          <Row>
            <Col>
              <Row className="mt-n1 justify-content-center">
                <Col xs="auto">
                  <h4>Estimate</h4>
                </Col>
              </Row>
              <Row className="mb-0 mt-n3 justify-content-center">
                <Col xs="auto">
                  <small>{estimateSubtitle}</small>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row className="justify-content-end">
                <Col xs="auto">
                  {onEdit && !isLoading ? (
                    <Button variant="link" size="sm" onClick={onEdit}>
                      {hasEstimate ? "Edit" : "Add"}
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        ),
        columns: [
          {
            id: "rowHeader",
            Header: "",
            accessor: "header",
            disableFilters: true,
            disableSortBy: true,
            format: { alignment: { horizontal: "right" } },
          },
          {
            id: "estimateMin",
            Header: "Min",
            accessor: "estimateMin",
            disableFilters: true,
            disableSortBy: true,
            dataType: "number",
            Cell: NumberFormatter,
          },
          {
            id: "estimateMax",
            Header: "Max",
            accessor: "estimateMax",
            disableFilters: true,
            disableSortBy: true,
            dataType: "number",
            className: "border-right",
            Cell: NumberFormatter,
          },
        ],
      },
      {
        id: "actual",
        Header: <h4 className="text-center">Actual</h4>,
        className: "border-left",
        columns: [
          {
            id: "actualTotal",
            Header: "Total",
            accessor: "actualTotal",
            disableFilters: true,
            disableSortBy: true,
            dataType: "number",
            Cell: (props) => (
              <NumberFormatter
                variant={props?.row?.original?.actualVariant}
                {...props}
              />
            ),
          },
          {
            id: "actualOpen",
            Header: "Open",
            accessor: "actualOpen",
            disableFilters: true,
            disableSortBy: true,
            dataType: "number",
            Cell: NumberFormatter,
          },
          {
            id: "actualInvoiced",
            Header: "Invoiced",
            accessor: "actualInvoiced",
            disableFilters: true,
            disableSortBy: true,
            dataType: "number",
            className: "border-right font-italic",
            Cell: NumberFormatter,
          },
        ],
      },
    ],
    [estimateSubtitle, isLoading, onEdit, hasEstimate],
  );
  return (
    <Table
      bordered={false}
      hover={false}
      size="sm"
      style={{ maxWidth: "auto" }}
      isLoading={isLoading}
      skeletonLines={3}
      columns={columns}
      data={data}
    />
  );
}
