import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
  testStateLoading,
} from "../../../common/functions/state";
import { getJobAPI, getJobsAPI, resubmitJobAPI, submitJobAPI } from "./api";

const jobsAdapter = createEntityAdapter();

const getJobs = createAsyncThunk(
  "jobs/getMany",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.jobs, requestId)) return;

    const { responseData } = await getJobsAPI(payload);
    return responseData.jobs;
  },
);

const getJob = createAsyncThunk(
  "jobs/get",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.jobs, requestId)) return;

    const { responseData } = await getJobAPI(payload);
    return responseData;
  },
);

export const submitJob = createAsyncThunk(
  "job/submit",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.jobs, requestId)) return;

    const { responseData } = await submitJobAPI(payload);
    return responseData;
  },
);

export const resubmitJob = createAsyncThunk(
  "job/resubmit",
  async (payload, { getState, requestId }) => {
    if (!testStateLoading(getState().activity.jobs, requestId)) return;
    const { responseData } = await resubmitJobAPI(payload);
    return responseData;
  },
);

const jobsSlice = createSlice({
  name: "jobs",
  initialState: jobsAdapter.getInitialState({
    ...INITIAL_LOADING_STATE,
    lastUpdate: moment().subtract(30, "days").toISOString(),
  }),
  reducers: {
    clearJobs(state) {
      jobsAdapter.removeAll(state);
      state.requests = INITIAL_LOADING_STATE.requests;
      state.lastUpdate = moment().subtract(30, "days").toISOString();
    },
    updateJob(state, action) {
      jobsAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    buildAsyncThunkReducer(builder, getJobs, (state, action) => {
      if (action.payload.length !== 0) {
        jobsAdapter.upsertMany(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, getJob, (state, action) => {
      if (action.payload.length !== 0) {
        jobsAdapter.upsertOne(state, action.payload);
      }
      state.lastUpdate = new Date().toISOString();
    });
    buildAsyncThunkReducer(builder, submitJob, (state, action) => {
      if (action.payload) {
        jobsAdapter.upsertOne(state, action.payload);
        toast.success("Job submitted", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
    buildAsyncThunkReducer(builder, resubmitJob, (state, action) => {
      if (action.payload) {
        jobsAdapter.upsertOne(state, action.payload);
        toast.success("Job resubmitted", {
          autoClose: 2000,
          closeButton: false,
        });
      }
    });
  },
});

export const reducer = jobsSlice.reducer;

const { clearJobs, updateJob } = jobsSlice.actions;
export const actions = {
  getJobs,
  getJob,
  clearJobs,
  updateJob,
  submitJob,
  resubmitJob,
};

export const selectors = jobsAdapter.getSelectors(
  (state) => state.activity.jobs,
);
