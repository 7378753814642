import React from "react";
import { Button, Modal } from "react-bootstrap";

// Don't rename modalTitle to Title..
// there is something weird going on with that prop
export default function OkCancelModal({
  modalTitle,
  onCancel,
  onOk,
  bodyHeader,
  body,
  show,
  okText = "OK",
  size = "sm",
}) {
  return (
    <Modal
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{bodyHeader}</h4>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk} variant="primary">
          {okText}
        </Button>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
