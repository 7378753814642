import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import CodeReviewNew from "./CodeReviewNew";
import CodeReviewShow from "./CodeReviewShow";

export default function CodeReviewsRouting() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/show`}>
        <CodeReviewShow />
      </Route>
      <Route path={`${path}`}>
        <CodeReviewNew />
      </Route>
    </Switch>
  );
}
