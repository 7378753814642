import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { createEntityHooks } from "../../common/entity";
import state from "./state";

const {
  useEntities: useQaReviews,
  useEntity,
  useEntitiesBySearch: useQaReviewsSearch,
} = createEntityHooks({
  entity: state.entity,
  actions: state.actions,
});

const useQaReview = ({ id, ...args } = {}) => {
  const dispatch = useDispatch();
  return {
    createAttachment: useCallback(
      (args) =>
        dispatch(state.actions.createAttachment({ id, ...args })).then(
          unwrapResult,
        ),
      [dispatch, id],
    ),
    ...useEntity({ id, ...args }),
  };
};

export { useQaReview, useQaReviews, useQaReviewsSearch };
