import { object, string } from "yup";

const reusableProgramSchema = object().shape({
  id: string().label("Id").required().max(20),
  isReviewed: string().label("Reviewed?"),
  reviewedBy: string().label("Reviewed by"),
  createdOn: string().label("Created On"),
});

export default reusableProgramSchema;
