import { boolean, number, object, ref, string } from "yup";

import { _date, _datetime } from "../common/functions/dates";

export const isClosed = (statusId) => statusId === "CL" || statusId === "FX";

const crSchema = object().shape({
  synopsis: string().label("Synopsis").required().max(200),
  description: string().label("Description").required().max(5000),
  projectId: number()
    .label("Project")
    .required()
    .test(
      "projectIsOpen",
      "Project must be Open",
      (_, { options: { parent, context = {} } }) => {
        const { statusId } = parent;
        const { project } = context;
        return !project || isClosed(statusId) || project?.statusId === "OP";
      },
    ),
  groupId: string().label("Group").required(),
  difficultyId: string().label("Difficulty").required(),
  statusId: string().label("Status").required(),
  owner: string()
    .label("Owner")
    .required()
    .when("statusId", {
      is: (v) => v !== "OP",
      then: string().test(
        "is-not-pseudo",
        "Pseudo user is only valid on open CRs",
        (value) => value?.charAt(0) !== "*",
      ),
    }),
  overrideTypeId: string().label("Override Type"),
  overrideCustomerId: number().label("Override Customer"),
  defectOccurrenceId: string()
    .label("Defect Occurrence")
    .when("statusId", {
      is: isClosed,
      then: string().test(
        "defectOccurrenceIdIsRequiredOnDefects",
        "Defect Occurrence is required on defect type CRs",
        (value, { parent, options: { context = {} } }) => {
          const { project } = context;
          const { overrideTypeId } = parent;
          const typeId = overrideTypeId || project?.typeId;
          return typeId !== "DE" || value;
        },
      ),
    }),
  moduleId: string().label("Module").when("statusId", {
    is: isClosed,
    then: string().required(),
  }),
  notificationTypeId: string().label("Notification Type").when("statusId", {
    is: isClosed,
    then: string().required(),
  }),
  isWaitingOnCustomer: boolean()
    .label("Blocked")
    .when("statusId", {
      is: isClosed,
      then: boolean().oneOf([false], "CR cannot be closed while blocked"),
    }),
  isTestingRequired: boolean()
    .label("Testing required")
    .when("statusId", {
      is: isClosed,
      then: boolean().oneOf(
        [false],
        "CR cannot be closed while testing is required",
      ),
    }),
  isReadyForCodeCheckin: boolean()
    .label("Ready for code check-in")
    .when("statusId", {
      is: isClosed,
      then: boolean().oneOf(
        [false],
        "CR cannot be closed while ready for code check-in",
      ),
    }),
  estimateMinHours: number()
    .label("Estimate Hours (Minimum)")
    .min(0)
    .max(
      ref("estimateMaxHours"),
      "Estimate (hours) minimum must be less than/equal to maximum",
    ),
  estimateMaxHours: number()
    .label("Estimate Hours (Maximum)")
    .min(0)
    .min(
      ref("estimateMinHours"),
      "Estimate (hours) maximum must be greater than/equal to minimum",
    ),
  estimateRate: number()
    .label("Estimate Rate")
    .when("estimateMaxHours", { is: (v) => v > 0, then: number().positive() }),
  estimateUserId: string().label("Estimate Rate (User)"),
  targetFirstContactDateTime: string()
    .transform(_datetime.stamp)
    .label("Target First Contact Date/Time"),
  actualFirstContactDateTime: string()
    .transform(_datetime.stamp)
    .label("Actual First Contact Date/Time"),
  targetStartWorkDateTime: string()
    .transform(_datetime.stamp)
    .label("Target Start Work Date/Time"),
  actualStartWorkDateTime: string()
    .transform(_datetime.stamp)
    .label("Actual Start Work Date/Time"),
  targetDeliveryDate: string()
    .transform(_date.stamp)
    .label("Target Delivery Date"),
  actualDeliveryDate: string()
    .transform(_date.stamp)
    .label("Actual Delivery Date"),
});

export default crSchema;

export function crSchemaInitial({
  owner = "",
  groupId = "",
  projectId = 0,
  synopsis = "",
  description = "",
}) {
  return {
    projectId,
    groupId,
    statusId: "OP",
    owner,
    difficultyId: "0",
    isWaitingOnCustomer: false,
    isTestingRequired: false,
    isReadyForCodeCheckin: false,
    synopsis,
    description,
  };
}
