import React from "react";
import { Button } from "react-bootstrap";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";

export default function WatchButton({ value, onChange, label = "" }) {
  return (
    <Button
      variant="light"
      title={value ? `Watching ${label}` : `Watch ${label}`}
      onClick={() => {
        onChange(!value);
      }}
    >
      {value ? <BsEyeFill /> : <BsEyeSlash />}
    </Button>
  );
}
