import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import JobPage from "../../activity/jobs/JobPage";
import FieldDate from "../../common/fields/FieldDate";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { CurrencyFormatter } from "../../common/formatters";
import { _date } from "../../common/functions/dates";
import Page from "../../common/pages/Page";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import Customer from "../../customers";
import User from "../../users";
import ReportNavSidebar from "./ReportNavSidebar";

export default function ReportARYearlyCustomerSummary() {
  const { isAdmin, isChampion } = useSelector((state) => state.auth);

  if (!(isChampion || isAdmin))
    return (
      <Page
        title="AR Yearly Customer Summary"
        lead="You do not have access to this feature"
      />
    );
  // eslint-disable-next-line

  return (
    <JobPage
      title="AR Yearly Customer Summary"
      functionName="ARYearlySummary"
      Options={ARYearlyCustomerSummaryOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const data = useMemo(() => job?.output?.customers || [], [job]);

  const columns = useMemo(() => {
    const output = [
      {
        id: "Customer",
        Header: "Customer",
        accessor: "customerId",
        Filter: Customer.TableFilter,
        filter: "text",
        Cell: ({ value }) => <Customer.Name id={value} />,
      },
      {
        id: "Champion",
        Header: "Champion",
        accessor: "champion",
        Filter: SelectColumnFilter,
        filter: "text",
      },
      {
        id: "Net Yearly Avg",
        Header: "Net Yearly Avg",
        accessor: "netInvoicedYearlyAvg",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
      {
        id: "Invoiced Yearly Avg",
        Header: "Invoiced Yearly Avg",
        accessor: "invoicedYearlyAvg",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
      {
        id: "Credited Yearly Avg",
        Header: "Credited Yearly Avg",
        accessor: "creditedYearlyAvg",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
      {
        id: "Net Total",
        Header: "Net Total",
        accessor: "netInvoicedTotal",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
      {
        id: "Invoiced Total",
        Header: "Invoiced Total",
        accessor: "invoicedTotal",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
      {
        id: "Credited Total",
        Header: "Credited Total",
        accessor: "creditedTotal",
        format: { alignment: { horizontal: "right" } },
        Cell: ({ value }) => (
          <CurrencyFormatter value={value} blankZero={true} decimals={0} />
        ),
      },
    ];

    if (job.options?.startYear)
      for (
        let i = parseInt(job.options.startYear);
        i <= parseInt(job.options.endYear);
        i++
      ) {
        output.push({
          id: "Net Invoiced " + i,
          Header: "Net Invoiced " + i,
          accessor: "netInvoiced" + i,
          format: { alignment: { horizontal: "right" } },
          Cell: ({ value }) => (
            <CurrencyFormatter value={value} blankZero={true} decimals={0} />
          ),
        });
        output.push({
          id: "Invoiced " + i,
          Header: "Invoiced " + i,
          accessor: "invoiced" + i,
          format: { alignment: { horizontal: "right" } },
          Cell: ({ value }) => (
            <CurrencyFormatter value={value} blankZero={true} decimals={0} />
          ),
        });
        output.push({
          id: "Credited " + i,
          Header: "Credited " + i,
          accessor: "credited" + i,
          format: { alignment: { horizontal: "right" } },
          Cell: ({ value }) => (
            <CurrencyFormatter value={value} blankZero={true} decimals={0} />
          ),
        });
      }
    return output;
  }, [job]);

  return (
    <>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            moveFooterToTop
            layout={["Customer", "Champion", "Net Yearly Avg", "Net Total"]}
          />
        </Col>
      </Row>
    </>
  );
}

function ARYearlyCustomerSummaryOptions({
  submitJob,
  job,
  startDate: sd = moment().startOf("year"),
  endDate: ed = moment().startOf("year"),
  includeOther: incO = true,
  includeMaintenance: incM = false,
  includeHardware: incH = false,
  includeLicenses: incL = false,
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {
    options = {
      userId: loggedInUser,
      startDate: sd,
      endDate: ed,
      customerId: null,
      accountManager: "",
      includeHardware: incH,
      includeMaintenance: incM,
      includeOther: incO,
      includeLicenses: incL,
    },
  } = job;
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      ...options,
    },
  });

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const customerId = watch("customerId");
  const accountManager = watch("accountManager");
  const useCustomerValue = Customer.useOne({ id: customerId });
  const useUserValue = User.useOne({ id: accountManager });

  return (
    <Form
      onSubmit={handleSubmit((options) => {
        let desc =
          customerId !== "" && customerId !== null
            ? "AR Yearly summary for " + useCustomerValue?.customer?.name
            : accountManager !== "" && accountManager !== null
            ? "AR Yearly summary for " + useUserValue?.user?.name
            : "AR Yearly summary from " +
              _date.stamp(options.startDate).substring(0, 4) +
              " - " +
              _date.stamp(options.endDate).substring(0, 4);
        submitJob({
          ...options,
          customerId: _.isNil(options.customerId) ? "" : options.customerId,
          accountManager: _.isNil(options.accountManager)
            ? ""
            : options.accountManager,
          startYear: _date.stamp(options.startDate).substring(0, 4),
          endYear: _date.stamp(options.endDate).substring(0, 4),
          description: desc,
        });
      })}
    >
      <Form.Row>
        <Col xs="auto">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="Start Year"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                dateOnlyFormat="yyyy"
                maxDate={endDate}
              />
            )}
          />
        </Col>
        <Col xs="auto">
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldDate
                label="End Year"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                includeNow={false}
                dateOnlyFormat="yyyy"
                minDate={startDate}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>&nbsp;</Col>
      </Form.Row>

      <Form.Row>
        <Col xs={4} md={4}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, onBlur } }) => (
              <Customer.Select
                label="Customer"
                value={value}
                onBlur={onBlur}
                onChange={(v) => {
                  onChange(v);
                  setValue("accountManager", "");
                  trigger();
                }}
                isClearable={true}
                isInvalid={errors.customerId}
                errors={"Invalid Customer"}
              />
            )}
          />
        </Col>
        <Col xs={4} md={4}>
          <Controller
            name="accountManager"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, onBlur } }) => (
              <User.Select
                label="Account Manager"
                value={value}
                onBlur={onBlur}
                onChange={(v) => {
                  onChange(v);
                  setValue("customerId", "");
                  trigger();
                }}
                isClearable={true}
                isChampion={true}
                isInvalid={errors.accountManager}
                errors={"Invalid Account Manager"}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeMaintenance"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeMaintenance"
                label="Include Annual Maintenance?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeHardware"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeHardware"
                label="Include Hardware Invoices?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeLicenses"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeLicenses"
                label="Include PPro licenses?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="includeOther"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <FieldYesNoSelect
                id="includeOther"
                label="Include all others?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Form.Row>
      <Row className="pt-3">
        <Col>
          <Button type="submit">Run</Button>
        </Col>
      </Row>
    </Form>
  );
}
