import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import FieldInput from "../common/fields/FieldInput";
import { useForms } from "../common/forms";
import EstimateModifyModal from "../estimates/EstimateModifyModal";
import EstimateNewButton from "../estimates/EstimateNewButton";
import EstimateTable from "../estimates/EstimateTable";
import { useEstimatesBySource } from "../estimates/hooks";
import User, { useUserRate } from "../users";
import EstimateStatus from "./estimateStatuses";
import projectSchema from "./projectSchema";

export default function ProjectEstimateEditor({
  project,
  show,
  onHide,
  update,
  onFormsUpdate,
}) {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Project Estimate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EstimateForm
          project={project}
          onHide={onHide}
          update={update}
          method={project.estimateMethod}
          onFormsUpdate={onFormsUpdate}
        />
      </Modal.Body>
    </Modal>
  );
}

function EstimateForm({ project, update, onHide, method, onFormsUpdate }) {
  const [estimateMethod, setEstimateMethod] = useState(method);
  const {
    submitForm,
    control,
    watch,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useForms({
    schema: { schema: projectSchema },
    defaultValues: project,
    onSubmit: (modifiedProject) => {
      let _project = {};
      if (estimateMethod === "hours") {
        _project = { ...modifiedProject };
      } else {
        // Clear estimateRate if type is dollars
        _project = {
          ...modifiedProject,
          estimateMinHours: 0,
          estimateMaxHours: 0,
        };
      }
      update(_project);
      onHide();
    },
  });

  const [showEstimateModifyModalId, setShowEstimateModifyModalId] =
    useState(null);
  const userId = useSelector((state) => state.auth.user);

  const projectId = project.id;
  const estimateRate = watch("estimateRate");
  const estimateUserId = watch("estimateUserId");
  const estimateMinHours = watch("estimateMinHours");
  const estimateMaxHours = watch("estimateMaxHours");

  const { loading: userRateLoading, userRate } = useUserRate({
    id: estimateUserId,
    canGet: Number(estimateRate) === 0 && estimateRate !== "",
    projectId,
  });

  useEffect(() => {
    if (!userRateLoading) {
      setValue("estimateRate", userRate);
      trigger();
    }
  }, [userRate, setValue, userRateLoading, trigger]);

  //Automatically call getEstimates with the project and store in estimates
  //Also send back reset command to allow for re-querying on-demand
  const {
    estimates,
    loading: estimatesLoading,
    reset: resetEstimates,
  } = useEstimatesBySource({
    search: { source: "PR", reference: projectId },
    canGet: true,
  });

  function handleChange(onChange) {
    return (value) => {
      onChange(value);
      trigger();
    };
  }

  const onFormsUpdateLocal = () => {
    resetEstimates({ source: "PR", reference: projectId });
    onFormsUpdate();
  };

  const showEstimateModify = (id) => {
    setShowEstimateModifyModalId(id);
  };

  const closeEstimateModify = () => {
    setShowEstimateModifyModalId(null);
  };

  //This is needed to prevent rerenders causing data to be wiped
  //out on the new estimate form
  const estimateNewInitVals = useMemo(() => {
    return {
      source: "PR",
      reference: project.id,
      owner: userId,
      activeStatus: "D",
      type: "A",
      projectEstimateStatus: "NOQUOT",
      rate: project.estimateRate,
      rateUserId: estimateUserId,
      date: moment(),
    };
  }, [project.id, project.estimateRate, estimateUserId, userId]);

  let estimateMin = 0;
  if (!isNaN(estimateRate) && !isNaN(estimateMinHours)) {
    estimateMin = _.multiply(estimateRate, estimateMinHours).toFixed(2);
  }
  let estimateMax = 0;
  if (!isNaN(estimateRate) && !isNaN(estimateMaxHours)) {
    estimateMax = _.multiply(estimateRate, estimateMaxHours).toFixed(2);
  }

  return (
    <Form onSubmit={submitForm}>
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Estimate Method</Form.Label>
            <Form.Control
              as="select"
              value={estimateMethod}
              onChange={(e) => {
                setEstimateMethod(e.target.value);
                reset(project);
              }}
              disabled={project.estimates.length > 0}
            >
              <option value="hours">Hours</option>
              <option value="dollars">Dollars</option>
              <option value="forms">Forms</option>
            </Form.Control>
          </Col>
          <Col>
            <Controller
              name="estimateStatusId"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <EstimateStatus.Select
                  label="Estimate Status"
                  value={value}
                  onBlur={onBlur}
                  onChange={handleChange(onChange)}
                  isInvalid={errors.estimateStatusId}
                  errors={errors.estimateStatusId?.message}
                  disabled={project.estimates.length > 0}
                />
              )}
            />
          </Col>
        </Row>
      </Form.Group>

      {estimateMethod === "forms" ? (
        <>
          <Row>
            <Col>
              <FieldInput
                label="Hours"
                type="number"
                value={project.estimateMaxHours}
                disabled={true}
                onChangeOnBlur={false}
              />
            </Col>
            <Col>
              <FieldInput
                label="Cost"
                type="number"
                value={project.estimateMaxCost}
                disabled={true}
                onChangeOnBlur={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EstimateModifyModal
                onUpdate={onFormsUpdateLocal}
                estimateId={showEstimateModifyModalId}
                onClose={closeEstimateModify}
              />
              <EstimateTable
                estimates={estimates}
                loading={estimatesLoading}
                setModifyShow={showEstimateModify}
                onRefresh={() =>
                  resetEstimates({ source: "PR", reference: projectId })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EstimateNewButton
                initialValues={estimateNewInitVals}
                onCreate={onFormsUpdateLocal}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="border-top pt-3">
            <Col>
              {estimateMethod === "hours" ? (
                <>
                  <Form.Row>
                    <Col>
                      <Controller
                        name="estimateMinHours"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <FieldInput
                            label="Min Hours"
                            type="number"
                            value={value}
                            onBlur={onBlur}
                            onChange={handleChange(onChange)}
                            isInvalid={errors.estimateMinHours}
                            errors={errors.estimateMinHours?.message}
                            onChangeOnBlur={false}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        name="estimateMaxHours"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <FieldInput
                            label="Max Hours"
                            type="number"
                            value={value}
                            onBlur={onBlur}
                            onChange={handleChange(onChange)}
                            isInvalid={errors.estimateMaxHours}
                            errors={errors.estimateMaxHours?.message}
                            onChangeOnBlur={false}
                          />
                        )}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Controller
                        name="estimateUserId"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <User.Select
                            label="Hourly Rate"
                            value={value}
                            onBlur={onBlur}
                            onChange={handleChange((v) => {
                              onChange(v);
                              setValue("estimateRate", 0);
                            })}
                            isInvalid={errors.estimateUserId}
                            errors={errors.estimateUserId?.message}
                            isClearable
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        name="estimateRate"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <FieldInput
                            label="Hourly Rate"
                            type="number"
                            value={value}
                            onBlur={onBlur}
                            onChange={handleChange((v) => {
                              onChange(v);
                              setValue("estimateUserId", "");
                            })}
                            isInvalid={errors.estimateRate}
                            errors={errors.estimateRate?.message}
                            disabled={userRateLoading}
                            onChangeOnBlur={false}
                          />
                        )}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <FieldInput
                        label="Min Cost"
                        value={estimateMin}
                        disabled
                      />
                    </Col>
                    <Col>
                      <FieldInput
                        label="Max Cost"
                        value={estimateMax}
                        disabled
                      />
                    </Col>
                  </Form.Row>
                </>
              ) : (
                <Form.Row>
                  <Col>
                    <Controller
                      name="estimateMinCost"
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <FieldInput
                          label="Min Cost"
                          type="number"
                          value={value}
                          onBlur={onBlur}
                          onChange={handleChange(onChange)}
                          isInvalid={errors.estimateMinCost}
                          errors={errors.estimateMinCost?.message}
                          onChangeOnBlur={false}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <Controller
                      name="estimateMaxCost"
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <FieldInput
                          label="Max Cost"
                          type="number"
                          value={value}
                          onBlur={onBlur}
                          onChange={handleChange(onChange)}
                          isInvalid={errors.estimateMaxCost}
                          errors={errors.estimateMaxCost?.message}
                          onChangeOnBlur={false}
                        />
                      )}
                    />
                  </Col>
                </Form.Row>
              )}
            </Col>
          </Row>
          <ButtonGroup>
            <Button type="submit">Save</Button>
            <Button
              variant="danger"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
