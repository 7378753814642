import { useState } from "react";

import app from "../../app/index";
import useInterval from "./useInterval";

/**
 * Update component every x ms
 * @param {integer} ms Miliseconds internal
 */
export default function useIntervalUpdateComponent(ms) {
  const [, setTime] = useState(Date.now());

  const appIsActive = app.useIsActive();

  useInterval(() => setTime(Date.now()), appIsActive ? ms : 0, [
    ms,
    appIsActive,
  ]);
}
