import { createEntityHooks } from "../../common/entity";
import jiraState from "./state";

export const {
  useEntities: useJiraProjects,
  useEntity: useJiraProject,
  useEntitiesBySearch: useJiraProjectsByCustomer,
} = createEntityHooks({
  entity: jiraState.entity,
  actions: jiraState.actions,
});
