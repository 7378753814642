import React from "react";

import NavSidebarItem from "../../../common/navs/NavSidebarItem";
import UserNavSidebar from "../UserNavSidebar";

export default function PseudoNavSidebar({ children }) {
  return (
    <UserNavSidebar>
      <NavSidebarItem to="/users/pseudos/new">New Pseudo User</NavSidebarItem>
      {children}
    </UserNavSidebar>
  );
}
