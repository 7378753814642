import { createEntityHooks } from "../../common/entity";
import categoryState from "./state";

export const {
  useEntities: useExpenseCategories,
  useEntity: useExpenseCategory,
} = createEntityHooks({
  entity: categoryState.entity,
  actions: categoryState.actions,
});
