import { entitySelect } from "../../common/entity";
import { useS3Buckets } from "./hooks";

const S3BucketSelect = entitySelect({
  name: "s3Bucket",
  useEntities: useS3Buckets,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.id,
});

export default S3BucketSelect;
