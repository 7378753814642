import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useDocumentFocus from "../common/hooks/useDocumentFocus";
import useDocumentVisibility from "../common/hooks/useDocumentVisibility";
import useIntervalUpdateComponent from "../common/hooks/useIntervalUpdateComponent";
import app from "./index";
import { appActions } from "./state";

/**
 * Component to update app slice of redux state
 * @returns null
 */
export default function AppStateHandler() {
  const dispatch = useDispatch();
  const setActive = useCallback(
    (isActive) => dispatch(appActions.setIsActive(isActive)),
    [dispatch],
  );

  const documentFocus = useDocumentFocus();
  const documentVisible = useDocumentVisibility();

  const [lastDocumentFocus, setLastDocumentFocus] = useState(moment());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRehydrate = useCallback(
    debounce(() => dispatch(appActions.rehydrate()), 250),
    [dispatch],
  );

  // Update component every 60 seconds
  useIntervalUpdateComponent(60000);

  useEffect(() => {
    if (documentFocus) {
      setLastDocumentFocus(moment());
      debouncedRehydrate();
    }
  }, [debouncedRehydrate, documentFocus]);

  // App is active if visible & focused in the last 30 minutes
  const appIsActive = app.useIsActive();
  const appIsActiveNext =
    documentVisible && moment().diff(lastDocumentFocus, "minutes", true) < 30;
  useEffect(() => {
    if (appIsActive !== appIsActiveNext) setActive(appIsActiveNext);
  }, [setActive, appIsActive, appIsActiveNext]);

  return null;
}
