import React from "react";
import { useSelector } from "react-redux";

import { useNotificationGroup } from "./hooks";
import NotificationGroupEditor from "./NotificationGroupEditor";

export default function NotificationGroupNew({ onNew = () => {} }) {
  const { isManager, isAdmin } = useSelector((state) => state.auth);

  const { createOne } = useNotificationGroup();

  if (!(isManager || isAdmin)) return null;

  return (
    <NotificationGroupEditor
      isNew
      onSubmit={(values) => createOne(values).then(onNew)}
    />
  );
}
