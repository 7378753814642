import { useFavorite, useFavorites } from "./hooks";
import filterState from "./state";

const Favorite = {
  // Backend API
  api: filterState.api,
  // State
  actions: filterState.actions,
  selectors: filterState.entity.selectors,
  // Hooks
  useAll: useFavorites,
  useOne: useFavorite,
  // Components
};
export default Favorite;
