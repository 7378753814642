import React, { useMemo, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Page from "../../../common/pages/Page";
import Server from "../../../servers";
import AwsInstanceTypeNavSidebar from "./AwsInstanceTypeNavSidebar";

export default function AwsInstanceTypeIndex() {
  const [active, setActive] = useState(true);
  const { awsInstanceTypes, loading, reset } = Server.AwsInstanceType.useAll({
    canGet: true,
  });
  const data = useMemo(() => {
    return awsInstanceTypes.filter((e) => {
      return e.isInactive === !active;
    });
  }, [active, awsInstanceTypes]);
  return (
    <Page title="Aws Instance Type" navSidebar={<AwsInstanceTypeNavSidebar />}>
      <ButtonGroup toggle>
        <ToggleButton
          key="activeTrue"
          type="radio"
          variant="secondary"
          value={true}
          checked={active === true}
          onChange={(e) => setActive(e.currentTarget.value === "true")}
        >
          Active
        </ToggleButton>
        <ToggleButton
          key="activeFals"
          type="radio"
          variant="secondary"
          value={false}
          checked={active === false}
          onChange={(e) => setActive(e.currentTarget.value !== "false")}
        >
          Inactive
        </ToggleButton>
      </ButtonGroup>

      <Server.AwsInstanceType.Table
        awsInstanceTypes={data}
        isLoading={loading}
        onRefresh={reset}
      />
    </Page>
  );
}
