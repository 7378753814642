import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function DbcNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/dbc">DBC Home</NavSidebarItem>
      <NavSidebarItem to="/dbc/branches">Branches</NavSidebarItem>
      <NavSidebarItem to="/dbc/sourceCode">Source Code</NavSidebarItem>
      <NavSidebarItem to="/dbc/codeTests">Code Tests</NavSidebarItem>
      <NavSidebarItem to="/dbc/reusablePrograms">
        Reusable Programs
      </NavSidebarItem>
      <div className="dropdown-divider" />
      {children}
    </NavSidebar>
  );
}
