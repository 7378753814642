import React, { useState } from "react";
import { useParams } from "react-router";

import Cr from "../../crs";
import Project from "../../projects";
import CrPage from "./CrPage";
import CrShowNavSidebar from "./CrShowNavSidebar";

export default function CrEdit() {
  const id = parseInt(useParams().id);
  const { cr, loading, updateOne } = Cr.useOne({ id });
  const [error, setError] = useState(null);
  const { project } = Project.useOne({
    id: cr?.projectId,
    logActivity: true,
  });

  return (
    <CrPage
      cr={cr}
      heading={`Edit CR ${id}`}
      lead={loading ? "Loading..." : null}
      isLoading={loading}
      navSidebar={<CrShowNavSidebar id={id} />}
    >
      {cr && !loading ? (
        <Cr.Editor
          cr={cr}
          onSubmit={(nextCr) =>
            updateOne(nextCr).catch((error) => setError(error?.message))
          }
          error={error}
          project={project}
        />
      ) : null}
    </CrPage>
  );
}
