import React from "react";
import { Button, Col, Form } from "react-bootstrap";

import {
  Forms,
  FormsField,
  useFormContext,
  useFormsKeys,
} from "../../common/forms";
import TaxProductCategory from "../taxProductCategories";
import taxProductSchema from "./taxProductSchema";

export default function TaxProductEditor({
  values,
  onSubmit,
  isNew = false,
  isDisabled = false,
}) {
  TaxProductCategory.useAll({
    canGet: true,
  });

  return (
    <Forms
      defaultValues={{
        ...values,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
        })
      }
      schema={{ schema: taxProductSchema }}
      isDisabled={isDisabled}
      isNew={isNew}
      showDevTool
    >
      <TaxProductForm isNew={isNew} />
    </Forms>
  );
}

function TaxProductForm({ isNew }) {
  const { submitForm } = useFormContext();

  useFormsKeys();
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col xs="auto">
              <FormsField
                name="id"
                label="Id"
                readOnly={!isNew}
                autoComplete="off"
              />
            </Col>
            <Col xs="auto" className="align-self-center">
              <FormsField.Check name="isInactive" label="Inactive" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="4">
              <FormsField
                name="description"
                label="Description"
                className="text-monospace"
                autoComplete="off"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="6">
              <FormsField.TextArea
                name="longDescription"
                label="Long Description"
                className="text-monospace"
                autoComplete="off"
                minRows={4}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={"auto"} style={{ minWidth: "300px" }}>
              <FormsField.Select
                name="taxProductCategory"
                label="Tax Product Category"
                as={TaxProductCategory.Select}
              />
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Button variant="success" onClick={submitForm}>
        Submit
      </Button>
    </Form>
  );
}
