import React from "react";

import { _date } from "../../common/functions/dates";
import { useQaReview } from "./hooks";
import QaReviewEditorModal from "./QaReviewEditorModal";

//When no qaReviewId is given then we want to throw away the modal.
//Then when an qaReviewID is given it will be initiliazed again
//Which will get our qaReview record a new.
export default function QaReviewModifyModal({ qaReviewId, ...props }) {
  return (
    <>
      {qaReviewId ? <ShowTheModal qaReviewId={qaReviewId} {...props} /> : null}
    </>
  );
}

// Get the qaReview record and show the modal.
function ShowTheModal({ onUpdate, qaReviewId, onClose }) {
  const { qaReview, updateOne, getOne, loading } = useQaReview({
    id: qaReviewId,
    canGet: true,
  });

  return (
    <QaReviewEditorModal
      title={"QA Review #" + qaReview?.id}
      values={{ ...qaReview }}
      onHide={onClose}
      show={true}
      loading={loading}
      isNew={false}
      onRefresh={getOne}
      onSubmit={(qaReview) => {
        const payloadQaReview = { ...qaReview };
        payloadQaReview.reviewDate = _date.stamp(qaReview.reviewDate);
        updateOne(payloadQaReview).then(() => {
          onUpdate();
          onClose();
        });
      }}
    />
  );
}
