import _ from "lodash";

export default function queryToString(filter) {
  let filters = [];

  const excludeFlag = (flag) => {
    return flag === false || (_.isArray(flag) && flag.includes(false))
      ? "-"
      : "";
  };

  if (!_.isEmpty(filter?.owners)) {
    if (filter.owners.length === 1)
      filters.push(
        `Owners (${excludeFlag(filter?.ownersInclude)}${filter.owners[0]})`,
      );
    else
      filters.push(
        `Owners (${excludeFlag(filter?.ownersInclude)}${filter.owners.length})`,
      );
  }
  let statusCount = 0;
  if (!_.isEmpty(filter?.projectStatuses))
    statusCount += filter?.projectStatuses.length;
  if (!_.isEmpty(filter?.projectEstimateStatuses))
    statusCount += filter?.projectEstimateStatuses.length;

  if (!_.isEmpty(filter?.crStatuses)) statusCount += filter.crStatuses.length;
  if (!_.isEmpty(filter?.qaStatuses)) statusCount += filter.qaStatuses.length;
  if (statusCount)
    filters.push(
      `Statuses (${excludeFlag([
        filter?.crStatusesInclude,
        filter?.projectStatusesInclude,
        filter?.projectEstimateStatusesInclude,
      ])}${statusCount})`,
    );

  if (!_.isEmpty(filter?.champions)) {
    filters.push(
      `Champions (${excludeFlag(filter?.championsInclude)}${
        filter.champions.length
      })`,
    );
  }

  if (!_.isEmpty(filter?.groups)) {
    filters.push(
      `Groups (${excludeFlag(filter?.groupsInclude)}${filter.groups.length})`,
    );
  }

  if (!_.isEmpty(filter?.customers)) {
    filters.push(
      `Customers (${excludeFlag(filter?.customersInclude)}${
        filter.customers.length
      })`,
    );
  }

  if (!_.isEmpty(filter?.types)) {
    filters.push(
      `Types (${excludeFlag(filter?.typesInclude)}${filter.types.length})`,
    );
  }

  if (!_.isEmpty(filter?.projectPriorities)) {
    filters.push(
      `Priorities (${excludeFlag(filter?.projectPrioritiesInclude)}${
        filter.projectPriorities.length
      })`,
    );
  }

  if (!_.isEmpty(filter?.projectSources)) {
    filters.push(
      `Sources (${excludeFlag(filter?.projectSourcesInclude)}${
        filter.projectSources.length
      })`,
    );
  }
  if (!_.isEmpty(filter?.crDifficulties)) {
    filters.push(
      `Difficulties (${excludeFlag(filter?.crDifficultiesInclude)}${
        filter.crDifficulties.length
      })`,
    );
  }
  if (!_.isEmpty(filter?.crNotifications)) {
    filters.push(
      `Notifications (${excludeFlag(filter?.crNotificationsInclude)}${
        filter.crNotifications.length
      })`,
    );
  }
  if (!_.isEmpty(filter?.crModules)) {
    filters.push(
      `Modules (${excludeFlag(filter?.crModulesInclude)}${
        filter.crModules.length
      })`,
    );
  }

  let userCount = 0;
  if (!_.isEmpty(filter?.createUsers)) userCount += filter?.createUsers.length;
  if (!_.isEmpty(filter?.modifyUsers)) userCount += filter?.modifyUsers.length;
  if (!_.isEmpty(filter?.closedUsers)) userCount += filter?.closedUsers.length;
  if (userCount)
    filters.push(
      `Users (${userCount})${excludeFlag([
        filter?.createUsersInclude,
        filter?.closedUsersInclude,
        filter?.modifyUsersInclude,
      ])}`,
    );

  // qaOwners
  // contacts
  // crModuleGroups

  let flags = 0;
  if (_.isBoolean(filter?.isMultiCustomer)) flags += 1;
  if (_.isBoolean(filter?.isReadyForCodeCheckin)) flags += 1;
  if (_.isBoolean(filter?.isWaitingOnCustomer)) flags += 1;
  if (_.isBoolean(filter?.isAssigned)) flags += 1;
  if (flags) filters.push(`Flags (${flags})`);

  let dates = 0;
  if (filter?.closedStart || filter?.closedEnd) dates += 1;
  if (filter?.createStart || filter?.createEnd) dates += 1;
  if (filter?.modifyStart || filter?.modifyEnd) dates += 1;
  if (filter?.targetDeliveryStart || filter?.targetDeliveryEnd) dates += 1;
  if (filter?.targetStartWorkStart || filter?.targetStartWorkEnd) dates += 1;
  if (filter?.targetFirstContactStart || filter?.targetFirstContactEnd)
    dates += 1;
  if (dates) filters.push(`Dates (${dates})`);

  // estimateMinHoursStart
  // estimateMinHoursEnd
  // estimateMaxHoursStart
  // estimateMaxHoursEnd

  if (filters.length > 0)
    return _.capitalize(filter.folderId) + ": " + filters.join(", ");
  return "";
}
