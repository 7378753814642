import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "localforage";
import { isEmpty } from "lodash";
import { getStoredState, persistReducer, REHYDRATE } from "redux-persist";

import {
  buildAsyncThunkReducer,
  INITIAL_LOADING_STATE,
} from "../../common/functions/state";

const persistConfig = {
  key: "app",
  version: 1,
  storage,
  whitelist: ["forms"],
};

export const rehydrate = createAsyncThunk(
  "app/REHYDRATE",
  async (_, { dispatch }) => {
    const state = await getStoredState(persistConfig);

    dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  },
);

const appSlice = createSlice({
  name: "app",
  initialState: {
    ...INITIAL_LOADING_STATE,
    isActive: true,
    canStateExpire: { canStateExpire: true, keys: {} },
    forms: {},
    lastRehydrate: null,
  },
  reducers: {
    setIsActive(state, action) {
      console.log(`App ${action.payload ? "active" : "inactive"}`);
      state.isActive = action.payload;
    },
    setCanStateExpire(state, action) {
      const { key, value } = action.payload;
      if (!value) {
        state.canStateExpire.keys[key] = true;
      } else {
        delete state.canStateExpire.keys[key];
      }
      const canStateExpire = isEmpty(state.canStateExpire.keys);
      if (state.canStateExpire.canStateExpire !== canStateExpire) {
        console.log(`App state expiration: ${canStateExpire}`);
        state.canStateExpire.canStateExpire = canStateExpire;
      }
    },
    setForms(state, action) {
      const { key, value } = action.payload;
      if (isEmpty(value)) {
        delete state.forms[key];
      } else {
        state.forms[key] = value;
      }
    },
  },
  extraReducers: (builder) =>
    buildAsyncThunkReducer(
      builder,
      rehydrate,
      (state) => (state.lastRehydrate = new Date().toISOString()),
    ),
});

const appReducer = persistReducer(persistConfig, appSlice.reducer);

export default appReducer;
export const appActions = { ...appSlice.actions, rehydrate };
