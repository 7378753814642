import { entitySelect } from "../../common/entity";
import { useFolders } from "./hooks";

const FolderSelect = entitySelect({
  name: "projectFolder",
  useEntities: useFolders,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default FolderSelect;
