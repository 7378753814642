import React, { useMemo } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Controller } from "react-hook-form";

import JobPage from "../../activity/jobs/JobPage";
import AttachmentsTable from "../../common/details/AttachmentsTable";
import FieldYesNoSelect from "../../common/fields/FieldYesNoSelect";
import { _date } from "../../common/functions/dates";
import LoadingModal from "../../common/modals/LoadingModal";
import Table from "../../common/tables/Table";
import { SelectColumnFilter } from "../../common/tables/TableFilters";
import Time from "../../time";
import ReportNavSidebar from "./ReportNavSidebar";
import ReportOptions from "./ReportOptions";

export default function ReportOvertime() {
  return (
    <JobPage
      title="Billable Additional Compensation"
      functionName="Overtime"
      Options={OvertimeOptions}
      Report={Report}
      NavSidebar={ReportNavSidebar}
    />
  );
}

function Report({ job, isLoading }) {
  const { output: { users = [] } = {} } = job;
  const formattedVersion = job?.output?.formattedVersion;
  const attachments = useMemo(() => job?.output?.attachments || [], [job]);
  const { summary, details } = useMemo(
    () =>
      users?.reduce(
        (usersData, user) => {
          const data = user.days.reduce((daysData, day) => {
            const data = {
              details: daysData.details.concat(
                day?.time?.map((t) => {
                  return {
                    ...t,
                    userId: user.userId,
                    date: day.date,
                    totalHours: t.hours,
                  };
                }),
              ),
              summary: daysData.summary.concat([
                { ...day, userId: user.userId, expanded: true },
              ]),
            };
            return data;
          }, usersData);
          return data;
        },
        { details: [], summary: [] },
      ) || [],
    [users],
  );
  if (isLoading) {
    return <LoadingModal show={isLoading} />;
  } else if (formattedVersion) {
    return (
      <AttachmentsTable
        attachments={attachments}
        source={{ source: "JB", sourceId: job.id }}
        isDisabled={true}
      />
    );
  } else {
    return (
      <Row>
        <Col>
          <Tabs defaultActiveKey="summary">
            <Tab eventKey="summary" title="Summary">
              <Summary data={summary} isLoading={isLoading} />
            </Tab>
            <Tab eventKey="details" title="Details" mountOnEnter={true}>
              <Time.Table
                times={details}
                isLoading={isLoading}
                layout={[
                  "user",
                  "date",
                  "cr",
                  "customer",
                  "billingNote",
                  "totalHours",
                ]}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

function OvertimeOptions(props) {
  return (
    <ReportOptions {...props} options={{ formattedVersion: false }}>
      <Form.Row>
        <Col xs={12} md={6}>
          <Controller
            name="formattedVersion"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <FieldYesNoSelect
                id="formattedVersion"
                label="Run formatted version?"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={error}
                errors={"Invalid Answer"}
              />
            )}
          />
        </Col>
      </Form.Row>
    </ReportOptions>
  );
}

function Summary({ data, isLoading }) {
  const columns = useMemo(
    () => [
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        Filter: SelectColumnFilter,
        filter: "equals",
        aggregate: "count",
      },
      {
        id: "date",
        Header: "Date",
        accessor: ({ date }) => _date.display(_date.fromStamp(date)),
        aggregate: "uniqueCount",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ value, row: { original } }) =>
          `${value} ${original.holidayDescription}`,
      },
      {
        id: "bacHours",
        Header: "BAC Hours",
        accessor: "bacHours",
        dataType: "number",
        format: {
          number: { decimals: 2 },
        },
        aggregate: "sum",

        Aggregated: ({ value, column: { getFormattedValue, format } }) => {
          const formattedValue = getFormattedValue(value, format);
          return <strong>{`${formattedValue}`}</strong>;
        },
      },
    ],
    [],
  );
  return (
    <Table
      bordered
      hover
      size="sm"
      columns={columns}
      data={data}
      isLoading={isLoading}
      initialGroupBy={["user"]}
      initialExpanded={true}
      getRowProps={(row) => {
        if (row.isGrouped) return { className: "table-secondary" };
        else return {};
      }}
    />
  );
}
