import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import useRedirect from "../../../common/hooks/useRedirect";
import Page from "../../../common/pages/Page";
import Contract from "../../../contracts";
import ContractNavSidebar from "../ContractNavSidebar";
import ContractProductDefaultCrNew from "./ContractProductDefaultCrNew";

export default function ContractProductDefaultCrNewPage() {
  const contractProductId = String(useParams().id).toUpperCase();
  const { contractProduct } = Contract.ContractProduct.useOne({
    id: contractProductId,
    canGet: true,
  });

  const { loading, createOne } = Contract.ContractProductDefaultCr.useOne({
    canGet: true,
  });
  const [, setRedirect] = useRedirect();
  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Page
        title="New Contract Product Default Cr"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  return (
    <Page
      title="Contract Product Default Cr"
      navSidebar={<ContractNavSidebar />}
    >
      {contractProduct ? (
        <ContractProductDefaultCrNew
          values={{ contractProductId: contractProductId }}
          loading={loading}
          onSubmit={(contractProduct) =>
            createOne(contractProduct).then(({ id }) =>
              setRedirect(`/contracts/contract-product-default-crs/${id}`),
            )
          }
        />
      ) : (
        <h4>Contract Product Not found.</h4>
      )}
    </Page>
  );
}
