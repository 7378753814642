import shark from "../../../apis/shark";

function getAwsInstanceTypesAPI() {
  const payload = {
    command: "getAwsInstanceTypes",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.awsInstanceTypes,
  }));
}

function getAwsInstanceTypeAPI({ id }) {
  const payload = {
    command: "getAwsInstanceType",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createAwsInstanceTypeAPI(awsInstanceType) {
  const payload = {
    command: "createAwsInstanceType",
    ...awsInstanceType,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateAwsInstanceTypeAPI(awsInstanceType) {
  const payload = {
    command: "updateAwsInstanceType",
    ...awsInstanceType,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getAwsInstanceTypesAPI,
  getOne: getAwsInstanceTypeAPI,
  createOne: createAwsInstanceTypeAPI,
  updateOne: updateAwsInstanceTypeAPI,
};
