import { createEntityHooks } from "../../common/entity";
import customerContractState from "./state";

export const {
  useEntities: useCustomerContracts,
  useEntitiesBySearch: useCustomerContractsByCustomer,
  useEntity: useCustomerContract,
} = createEntityHooks({
  entity: customerContractState.entity,
  actions: customerContractState.actions,
});
