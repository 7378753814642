import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import { _date } from "../../../common/functions/dates";
import useRedirect from "../../../common/hooks/useRedirect";
import useSearchParams from "../../../common/hooks/useSearchParams";
import Page from "../../../common/pages/Page";
import HostingAgreementNavSidebar from "./HostingAgreementNavSidebar";

export default function HostingAgreementMonthNew() {
  const customerId = useSearchParams("customerId");
  const [, setRedirect] = useRedirect();
  var x = new Date();
  x.setDate(1);
  x.setMonth(x.getMonth());
  const [date, setDate] = useState(x);

  const { hostingAgreementAccess, isAdmin } = useSelector(
    (state) => state.auth,
  );

  if (
    !(
      hostingAgreementAccess === "M" ||
      hostingAgreementAccess === "V" ||
      isAdmin
    )
  )
    return (
      <Page
        title="Customer Hosting Agreement Monthly Run"
        navSidebar={<HostingAgreementNavSidebar customerId={customerId} />}
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page
      title="Hosting Agreement Monthly Run"
      navSidebar={<HostingAgreementNavSidebar />}
    >
      <Row>
        <Col xs="auto">{"Month"}</Col>
        <Col xs={3}>
          <ReactDatePicker
            selected={date}
            onChange={(x) => setDate(x)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </Col>
        <Col xs="auto">
          <Button
            onClick={() =>
              setRedirect(
                `/customers/hosting-agreements/month/${_date.monthStamp(date)}`,
              )
            }
          >
            Go
          </Button>
        </Col>
      </Row>
    </Page>
  );
}
