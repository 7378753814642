import { entitySelect } from "../../common/entity";
import { useContractProductCategories } from "./hooks";

const ContractProductCategorySelect = entitySelect({
  name: "contractProductCategory",
  useEntities: useContractProductCategories,
  filter: (e, value) => e.isInactive === false || e.id === value,
  label: (e) => e.description + "(" + e.id + ")",
});

export default ContractProductCategorySelect;
