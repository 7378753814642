import { useThirdPartyLicense, useThirdPartyLicenses } from "./hooks";
import state from "./state";
import ThirdPartyLicenseEditor from "./ThirdPartyLicenseEditor";
import ThirdPartyLicenseTable from "./ThirdPartyLicenseTable";

const ThirdPartyLicense = {
  // API
  api: state.api,
  // State
  actions: state.actions,
  selectors: state.entity.selectors,
  // Hooks
  useOne: useThirdPartyLicense,
  useAll: useThirdPartyLicenses,
  // Components
  Editor: ThirdPartyLicenseEditor,
  Table: ThirdPartyLicenseTable,
};
export default ThirdPartyLicense;
