/* eslint-disable react/jsx-key */

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

import TableHeaderContextMenu from "./TableHeaderContextMenu";

export default function TableHeaders({
  isLoading,
  hasFilter,
  exportTableToCSV,
  table: {
    headerGroups,
    allColumns,
    setColumnOrder,
    toggleGroupBy,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
  },
  setGroupBy,
  children,
}) {
  const [showContextMenu, setShowContextMenu] = useState(null);
  const [target, setTarget] = useState(null);

  function handleHeaderContextMenu(e, column) {
    e.preventDefault();
    setTarget(e.target);
    setShowContextMenu(column);
  }

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps()}
              className={column.headerClassName}
              onContextMenu={(e) => handleHeaderContextMenu(e, column)}
            >
              <Row className="justify-content-between">
                <Col>
                  {column.render("Header")}
                  {column.isGrouped ? (
                    isAllRowsExpanded ? (
                      <IoMdArrowDropdown {...getToggleAllRowsExpandedProps()} />
                    ) : (
                      <IoMdArrowDropright
                        {...getToggleAllRowsExpandedProps()}
                      />
                    )
                  ) : (
                    ""
                  )}
                </Col>
                {column.canSort ? (
                  <Col
                    xs="auto"
                    {...column.getSortByToggleProps()}
                    title="Toggle Sort"
                  >
                    <span>
                      {column.canSort ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )
                        ) : (
                          <FaSort />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </th>
          ))}
        </tr>
      ))}
      {hasFilter
        ? headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                className: "table-filters",
              })}
              key={headerGroup.getHeaderGroupProps().key + "_Filter"}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={column.headerClassName}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))
        : null}
      {children}
      <TableHeaderContextMenu
        show={showContextMenu ? true : false}
        isLoading={isLoading}
        target={target}
        columns={allColumns}
        onHide={() => {
          setShowContextMenu(null);
        }}
        onSortChange={setColumnOrder}
        onGroupBy={
          showContextMenu?.canGroupBy &&
          showContextMenu.disableGroupBy === false
            ? () => {
                const id = showContextMenu?.id;
                setGroupBy((gb) => {
                  if (gb.includes(id)) {
                    return gb.filter((g) => g !== id);
                  } else return gb.concat([id]);
                });
                toggleGroupBy(id);
                setShowContextMenu(null);
              }
            : null
        }
        onExportToCSV={exportTableToCSV}
      />
    </thead>
  );
}
