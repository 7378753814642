import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { _date, _datetime } from "../common/functions/dates";
import {
  formatTotalSeconds,
  timerCalculation,
} from "../common/functions/timerCalculation";
import Table from "../common/tables/Table";
import {
  SelectColumnFilter,
  TextColumnFilter,
} from "../common/tables/TableFilters";
import Cr from "../crs";
import { getTimeType, TIME_LISTING_TYPES } from "./timeListingTypes";
import useTimeModifyModal from "./useTimeModifyModal";

function TimersTable({
  times = [],
  layout,
  isLoading,
  onSelect,
  initialSort,
  onRefresh,
}) {
  const loggedInUser = useSelector((state) => state.auth.user);

  const today = useMemo(() => moment(), []);

  const [ModifyModal, modifyModelProps, setModifyShow] = useTimeModifyModal({
    showTimers: true,
    handleChange: () => {
      setModifyShow();
      onRefresh();
    },
  });

  let timers = [];
  for (let i = 0; i < times.length; i++) {
    for (let r = 0; r < times[i].timers.length; r++) {
      const timer = { ...times[i] };
      timer.startTime = times[i].timers[r].startTime;
      timer.endTime = times[i].timers[r].endTime;
      timer.gap = false;
      timers.push(timer);
    }
  }
  timers = timers.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
  const tableData = [];
  let lastEndTime = "";
  let lastDate = "";
  let lastMoment = null;

  for (let i = 0; i < timers.length; i++) {
    if (i !== 0) {
      const startMoment = _datetime.fromStamp(
        timers[i].date + timers[i].startTime,
      );
      const gapEnd = moment(startMoment).subtract(1, "seconds");
      if (lastEndTime !== "") {
        lastMoment = _datetime.fromStamp(lastDate + lastEndTime);
        const secondsDiff = startMoment.diff(lastMoment, "seconds");
        if (secondsDiff >= 60) {
          const gapStart = moment(lastMoment).add(1, "seconds");
          const gapTimer = {
            startTime: _datetime.timeOnlyStamp(gapStart),
            endTime: _datetime.timeOnlyStamp(gapEnd),
            date: _date.stamp(lastMoment),
            timeType: "Gap",
            projectId: "",
            crId: "",
            customers: [],
            billingNote: "Gap in timer records",
          };
          tableData.push(gapTimer);
        }
      }
      lastEndTime = timers[i].endTime;
      lastDate = timers[i].date;
    }
    tableData.push(timers[i]);
  }

  for (let d = 0; d < tableData.length; d++) {
    const dateMoment = _date.fromStamp(tableData[d].date);
    const timerArray = [];
    timerArray.push({
      startTime: tableData[d].startTime,
      endTime: tableData[d].endTime,
    });
    const output = timerCalculation(dateMoment, timerArray);
    tableData[d].totalSeconds = output.totalSeconds;
    tableData[d].formattedSeconds = output.hhmmss;
  }

  const columns = React.useMemo(
    () => [
      {
        id: "user",
        Header: "User",
        accessor: "userId",
        disableFilters: true,
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        disableFilters: true,

        Cell: ({ value }) => (
          <span>{_date.display(_date.fromStamp(value))}</span>
        ),
      },
      {
        id: "project",
        Header: "Project",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return "TBD";
            case TIME_LISTING_TYPES.INTERNAL:
              return "Internal";
            default:
              return t.projectId;
          }
        },
        Cell: (o) =>
          (function (o) {
            if (o?.row?.original?.timeType === "CR") {
              return (
                <Link
                  className="text-reset"
                  to={{ pathname: `/projects/${o?.value}` }}
                >
                  {o?.value}
                </Link>
              );
            } else return <>{o?.value}</>;
          })(o),
        disableFilters: true,
      },
      {
        id: "cr",
        Header: "CR",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return "TBD";
            case TIME_LISTING_TYPES.INTERNAL:
              return t.internalProjectName || t.internalProjectId;
            default:
              return t.crId;
          }
        },
        Filter: SelectColumnFilter,
        filter: "equals",

        Cell: ({ row: { original: time }, value }) => {
          if (
            time?.timeType === "CR" ||
            time?.timeType === "Travel" ||
            time?.timeType === "On-site"
          ) {
            return <Cr.Link id={time?.crId} />;
          } else return <>{value}</>;
        },
        aggregate: "count",
      },
      {
        id: "customer",
        Header: "Customer",
        accessor: (t) => {
          switch (getTimeType(t)) {
            case TIME_LISTING_TYPES.TBD:
              return t.customer.name;
            default:
              if (t?.customers?.length) {
                if (t.customers.length === 1) return t.customers[0]?.name;
                else return "Multiple custommers";
              }
          }
        },

        Cell: ({ value }) => <div style={{ minWidth: "150px" }}>{value}</div>,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        id: "billingNote",
        Header: "Billing Note",
        Filter: TextColumnFilter,
        filter: "text",
        accessor: "billingNote",

        Cell: ({ row, value }) => {
          const displayVal =
            value?.trim().length < 2
              ? value +
                " ******* Billing note is too short. Please fix ******* "
              : value;
          return (
            <div style={{ maxWidth: "400px", wordWrap: "break-word" }}>
              {row?.original?.timeType === "Gap" ? (
                <span>{displayVal}</span>
              ) : (
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: `/time/${row?.original?.id}/edit?openTimers=true`,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (_.isFunction(onSelect)) onSelect(row?.original?.id);
                    else setModifyShow(row?.original?.id);
                  }}
                >
                  {displayVal}
                </Link>
              )}
            </div>
          );
        },
      },
      {
        id: "startTime",
        Header: "Start",
        accessor: "startTime",
        disableFilters: true,

        Cell: ({ value }) => (
          <div className="text-center">
            {_datetime.displayTimeSeconds(
              _datetime.fromMomentDateStringTime(today, value),
            )}
          </div>
        ),
      },
      {
        id: "endTime",
        Header: "End",
        accessor: "endTime",
        disableFilters: true,

        Cell: ({ value }) => {
          return value === "" ? (
            <div className="text-center">{"Running"}</div>
          ) : (
            <div className="text-center">
              {_datetime.displayTimeSeconds(
                _datetime.fromMomentDateStringTime(today, value),
              )}
            </div>
          );
        },
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: (t) => {
          if (loggedInUser.trim() === t?.userId?.trim() && t.hasRunningTimer) {
            return (
              <Button
                block
                size="sm"
                variant="danger"
                onClick={() => setModifyShow(t.id, true)}
              >
                Stop
              </Button>
            );
          } else {
            return <div className="text-center">{t.formattedSeconds}</div>;
          }
        },
        disableSortBy: true,
        disableFilters: true,

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => {
                if (row.original.timeType === "Gap") return sum;

                return sum + row.original.totalSeconds;
              }, 0),
            [info.rows],
          );
          return (
            <div className="text-center">
              {formatTotalSeconds(total).hhmmss}
            </div>
          );
        },
      },
    ],
    [loggedInUser, onSelect, setModifyShow, today],
  );
  return (
    <>
      <Table
        hasCount
        bordered
        hover
        size="sm"
        label="Timers"
        columns={columns}
        initialSort={initialSort}
        layout={layout}
        data={tableData}
        onRefresh={onRefresh}
        getRowProps={(row) => {
          switch (getTimeType(row?.original)) {
            case TIME_LISTING_TYPES.BILLABLE:
              return {
                className: "table-success",
              };
            case TIME_LISTING_TYPES.TBD:
              return {
                className: "table-warning",
              };
            case TIME_LISTING_TYPES.INTERNAL:
              return {
                className: "table-secondary",
              };
            default:
              if (row?.original.timeType === "Gap") {
                return {
                  className: "bg-dark text-light",
                };
              } else if (row?.original.isInternalCr) {
                return {
                  className: "table-info",
                };
              } else if (row?.original?._skeleton) return {};
              else
                return {
                  className: "table-danger",
                };
          }
        }}
        isLoading={isLoading}
        moveFooterToTop
      />
      <ModifyModal {...modifyModelProps} />
    </>
  );
}

export default React.memo(TimersTable);
