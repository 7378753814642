import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import StampDisplay from "../../../common/components/StampDisplay";
import Page from "../../../common/pages/Page";
import Contract from "../../../contracts";
import ContractNavSidebar from "../ContractNavSidebar";

export default function ContractProductCategoryEdit() {
  const id = String(useParams().id).toUpperCase();
  const { contractProductCategory, loading, updateOne } =
    Contract.ContractProductCategory.useOne({
      id,
      canGet: true,
    });
  const { canMaintainContracts, isAdmin } = useSelector((state) => state.auth);

  if (!(canMaintainContracts || isAdmin)) {
    return (
      <Page
        title="Contract Product Category"
        navSidebar={<ContractNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  return (
    <Page title="Contract Product Category" navSidebar={<ContractNavSidebar />}>
      {contractProductCategory ? (
        <>
          <Contract.ContractProductCategory.Editor
            isNew={false}
            values={contractProductCategory}
            isLoading={loading}
            onSubmit={(values) => {
              updateOne(values);
            }}
          />
          <StampDisplay record={contractProductCategory} />
        </>
      ) : (
        <h4>Contract Product Category not found.</h4>
      )}
    </Page>
  );
}
