import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import useIntervalUpdateComponent from "./common/hooks/useIntervalUpdateComponent";

const compareVersions = (next, prev) => {
  const nextVersions = next.split(/\./g);

  const prevVersions = prev.split(/\./g);
  while (nextVersions.length || prevVersions.length) {
    const a = Number(nextVersions.shift());

    const b = Number(prevVersions.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export default function useVersion({ blocked = false }) {
  const version = localStorage.getItem("version");

  const refreshAndUpdate = useCallback((nextVersion, nextBuild) => {
    localStorage.setItem("version", nextVersion);
    localStorage.setItem("versionBuild", nextBuild);
    //Delay clear to make sure version gets saved via redux-persist
    setTimeout(() => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    }, 500);
  }, []);

  useIntervalUpdateComponent(30000);
  const [lastUpdate, setLastUpdate] = useState(null);

  const shouldFetch =
    (lastUpdate === null || moment().diff(lastUpdate, "minutes") > 5) &&
    !blocked;

  const toastId = "updateVersion";

  useEffect(() => {
    if (!shouldFetch) return;
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const nextVersion = meta.version;
        const nextBuild = meta.build;
        setLastUpdate(moment());
        if (!version || compareVersions(nextVersion, version))
          toast(
            (props) => (
              <UpdateToast
                {...props}
                version={nextVersion}
                versionBuild={nextBuild}
                onUpgradeClick={() => refreshAndUpdate(nextVersion, nextBuild)}
              />
            ),
            {
              position: "bottom-right",
              autoClose: false,
              closeOnClick: false,
              toastId: toastId,
            },
          );
      })
      .catch(() => {});
  }, [version, shouldFetch, refreshAndUpdate]);

  return version;
}

function UpdateToast({ onUpgradeClick, version, versionBuild }) {
  return (
    <>
      <Row>
        <Col>
          <strong className="text-dark">Update Available</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="link" onClick={onUpgradeClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-muted">
            v
            {`${version}${
              versionBuild && versionBuild !== "master"
                ? "-" + versionBuild
                : ""
            }`}
          </small>
        </Col>
      </Row>
    </>
  );
}
