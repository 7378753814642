import ContactTypeDescription from "./ContactTypeDescription";
import ContactTypeSelect from "./ContactTypeSelect";
import ContactTypeTableFilter from "./ContactTypeTableFilter";
import { useContactType, useContactTypes } from "./hooks";
import contactTypeState from "./state";

const ContactType = {
  // Backend API
  api: contactTypeState.api,
  // State
  actions: contactTypeState.actions,
  selectors: contactTypeState.entity.selectors,
  // Hooks
  useAll: useContactTypes,
  useOne: useContactType,
  // Components
  Select: ContactTypeSelect,
  Description: ContactTypeDescription,
  TableFilter: ContactTypeTableFilter,
};
export default ContactType;
