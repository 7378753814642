import { entityTableFilter } from "../../common/entity";
import { useGroups } from "./hooks";

const GroupTableFilter = entityTableFilter({
  name: "group",
  useEntities: useGroups,
  filter: (e, value) => e.inactive === false || e.id === value,
});

export default GroupTableFilter;
