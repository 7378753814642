import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

export default function NavSidebarItem({ children, to, className, ...props }) {
  const classNames = `text-secondary text-decoration-none ${className}`;

  const Item = ({ to, children, ...props }) => {
    if (to) {
      return (
        <Link to={to} {...props}>
          {children}
        </Link>
      );
    } else if (_.isString(children)) {
      return <p {...props}>{children}</p>;
    }
  };

  return (
    <li className="pb-2">
      <Item to={to} className={classNames} {...props}>
        {children}
      </Item>
    </li>
  );
}
