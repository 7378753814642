import React from "react";

import NavSidebar from "../../common/navs/NavSidebar";
import NavSidebarItem from "../../common/navs/NavSidebarItem";

export default function ContractNavSidebar({ children }) {
  return (
    <NavSidebar>
      <NavSidebarItem to="/contracts">Contracts</NavSidebarItem>
      <NavSidebarItem to="/contracts/new">New Contract</NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/contracts/contract-products">
        Contract Products
      </NavSidebarItem>
      <NavSidebarItem to="/contracts/contract-products/new">
        New Contract Product
      </NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/contracts/contract-product-categories">
        Contract Product Categories
      </NavSidebarItem>
      <NavSidebarItem to="/contracts/contract-product-categories/new">
        New Contract Product Category
      </NavSidebarItem>
      <div className="dropdown-divider" />
      <NavSidebarItem to="/contracts/scheduling">Scheduling</NavSidebarItem>
      {children}
    </NavSidebar>
  );
}
