import shark from "../../../apis/shark";

function getAwsZonesAPI() {
  const payload = {
    command: "getAwsZones",
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData.awsZones,
  }));
}

function getAwsZoneAPI({ id }) {
  const payload = {
    command: "getAwsZone",
    id: id,
  };
  return shark.post(payload).then((response) => ({
    data: response.responseData,
  }));
}

function createAwsZoneAPI(awsZone) {
  const payload = {
    command: "createAwsZone",
    ...awsZone,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

function updateAwsZoneAPI(awsZone) {
  const payload = {
    command: "updateAwsZone",
    ...awsZone,
  };
  return shark
    .post(payload)
    .then(({ responseData }) => ({ data: responseData }));
}

export const api = {
  getMany: getAwsZonesAPI,
  getOne: getAwsZoneAPI,
  createOne: createAwsZoneAPI,
  updateOne: updateAwsZoneAPI,
};
