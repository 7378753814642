import { debounce, isEmpty, isUndefined } from "lodash";
import { useCallback, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useFormContext } from "react-hook-form";

const DEFAULT_LAST = {
  values: null,
  datetime: null,
  hasSubmit: false,
  error: null,
};

export function useFormsAutoSubmit({
  debounceMs = 500,
  blocked = false,
  onSubmit: onSubmitOverride,
  onError = () => {},
  shouldResetOnError = false,
} = {}) {
  const {
    watch,
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting, isValid },
    formsContext: { onSubmit },
  } = useFormContext();

  const [last, setLast] = useState(DEFAULT_LAST);

  const values = watch();
  const shouldSubmit =
    !(blocked || isUndefined(values) || isEmpty(dirtyFields) || isSubmitting) &&
    isValid;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncer = useCallback(
    debounce(async (func) => await func(), debounceMs),
    [debounceMs],
  );
  // debounce(, debounceMs);

  useEffect(() => {
    const submit = async () => {
      let error = null;
      await handleSubmit(
        async (v) =>
          await Promise.resolve(
            onSubmitOverride ? onSubmitOverride(v) : onSubmit(v),
          )
            .then((r) => {
              setLast({
                datetime: new Date().toISOString(),
                values: v,
                hasSubmit: true,
                error: null,
              });
              return r;
            })
            .catch((err) => {
              setLast({
                datetime: new Date().toISOString(),
                values: v,
                hasSubmit: false,
                error: err.message,
              });
              error = err.message;
              throw err;
            }),
      )().finally(() => {
        if (error) {
          if (shouldResetOnError) reset();
          const err = new Error(error);
          onError(err);
        }
      });
    };
    if (shouldSubmit && !isEqual(last.values, values)) {
      debouncer(submit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, shouldSubmit]);

  const resetAutoSubmit = useCallback(
    ({ hasSubmit, error }) => setLast({ ...DEFAULT_LAST, hasSubmit, error }),
    [],
  );

  return { last, reset: resetAutoSubmit };
}
