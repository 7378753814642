import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { _date } from "../common/functions/dates";
import customerState from "./state";

function CustomerConditions({
  name,
  conditions,
  warranty,
  futureUpgrade,
  today,
  isPoRequired,
}) {
  const _warrantyExpirationDate = _date.fromStamp(warranty);
  const _futureUpgrade = _date.fromStamp(futureUpgrade);
  return (
    <>
      <Row>
        <Col>
          <h6>{name}</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPoRequired ? <strong>PO is Required</strong> : null}
          {_futureUpgrade > today ? (
            <p>Upcoming upgrade on {_date.display(_futureUpgrade)}</p>
          ) : null}
          {_warrantyExpirationDate < today ? (
            <p>
              Warranty Expired as of {_date.display(_warrantyExpirationDate)}
            </p>
          ) : null}
        </Col>
      </Row>
      {conditions.split(/\r\n|\r|\n/).map((x, idx) => (
        <Row key={`customer-special-work-conditions-${idx}`}>
          <Col>{x}</Col>
        </Row>
      ))}
    </>
  );
}

export default function CustomerSpecialWorkConditions({
  customers,
  isLoading = false,
}) {
  const customerEntities = useSelector((state) =>
    customerState.entity.selectors.selectByIds(state, customers || []),
  );

  const today = useMemo(() => moment(), []);

  const customersWithConditions = customerEntities?.filter(
    (c) =>
      c?.specialWorkConditions ||
      _date.fromStamp(c?.warrantyExpirationDate) < today ||
      _date.fromStamp(c?.futureUpgradeDate) > today,
  );

  if (isLoading || _.isEmpty(customersWithConditions)) return null;

  return (
    <Row className="my-3 pt-2 border table-danger text-center rounded">
      <Col>
        <Row>
          <Col>
            <h5>Special Work Conditions</h5>
          </Col>
        </Row>
        {customersWithConditions.map((c) => (
          <CustomerConditions
            key={`special-work-conditions-${c?.id}`}
            name={c?.name}
            conditions={c?.specialWorkConditions}
            warranty={c?.warrantyExpirationDate}
            futureUpgrade={c?.futureUpgradeDate}
            today={today}
            isPoRequired={c?.isPoRequired}
          />
        ))}
      </Col>
    </Row>
  );
}
