import React from "react";

import { useDefectOccurrence } from "./hooks";

export default function DefectOccurrenceDescription({ id }) {
  const { defect } = useDefectOccurrence({ id });
  if (!defect) return null;

  return <>{defect.description}</>;
}
