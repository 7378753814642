import React from "react";
import { Modal } from "react-bootstrap";

import EstimateEditor from "./EstimateEditor";

export default function EstimateEditorModal({ title, show, onHide, ...props }) {
  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EstimateEditor onCancel={onHide} {...props} />
      </Modal.Body>
    </Modal>
  );
}
