import React from "react";

import { _date } from "../common/functions/dates";
import EstimateEditorModal from "./EstimateEditorModal";
import { useEstimate } from "./hooks";

//When no estimateId is given then we want to throw away the modal.
//Then when an estimateID is given it will be initiliazed again
//Which will get our estimate record a new.
export default function EstimateModifyModal({ estimateId, ...props }) {
  console.log("modify modal - estimate id " + estimateId);
  return (
    <>
      {estimateId ? <ShowTheModal estimateId={estimateId} {...props} /> : null}
    </>
  );
}

// Get the estimate record and show the modal.
function ShowTheModal({ onUpdate, estimateId, onClose }) {
  const { estimate, updateOne, loading, createOne } = useEstimate({
    id: estimateId,
    canGet: true,
  });

  return (
    <EstimateEditorModal
      title="Modify Estimate"
      values={{ ...estimate }}
      onHide={onClose}
      show={true}
      loading={loading}
      onSubmit={(estimate) => {
        const payloadEstimate = { ...estimate };
        payloadEstimate.date = _date.stamp(estimate.date);
        updateOne(payloadEstimate).then(() => {
          onUpdate();
          onClose();
        });
      }}
      onSaveAsNew={(estimate) => {
        const payloadEstimate = { ...estimate };
        payloadEstimate.date = _date.stamp(estimate.date);
        createOne(payloadEstimate).then(() => {
          onUpdate();
          onClose();
        });
      }}
    />
  );
}
