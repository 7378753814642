export const ESTIMATE_TYPES = {
  B: {
    id: "B",
    description: "Ballpark",
  },
  A: {
    id: "A",
    description: "Actual",
  },
  I: {
    id: "I",
    description: "Internal",
  },
};
