import React, { useMemo } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Page from "../../../common/pages/Page";
import Customer from "../../../customers";
import HostingAgreementNavSidebar from "./HostingAgreementNavSidebar";

export default function HostingAgreementMonthShow() {
  const id = parseInt(useParams().id);
  const title = "Hosting Agreement Month";

  const { hostingAgreementAccess, isAdmin } = useSelector(
    (state) => state.auth,
  );

  if (
    !(
      hostingAgreementAccess === "V" ||
      hostingAgreementAccess === "M" ||
      isAdmin
    )
  ) {
    return (
      <Page
        title={title}
        navSidebar={<HostingAgreementNavSidebar />}
        lead="You do not have access to this feature"
      />
    );
  }

  const canEditHostingAgreements = hostingAgreementAccess === "M" || isAdmin;

  return (
    <HostingAgreementMonthReview
      id={id}
      title={title}
      canSubmit={canEditHostingAgreements}
    />
  );
}

function HostingAgreementMonthReview({ id, title, canSubmit }) {
  const { entity, getOne, updateOne, loading } =
    Customer.HostingAgreementMonths.useOne({
      id,
      canGet: true,
    });

  const dataCharges = useMemo(() => {
    if (entity?.charges) {
      const charges = [];
      for (let i = 0; i < entity?.charges.length; i++) {
        charges.push({ ...entity?.charges[i], exceptionsExist: false });
        for (let x = 0; x < entity?.exceptions.length; x++) {
          if (
            entity?.exceptions[x].customerId === entity?.charges[i].customerId
          ) {
            charges[i].exceptionsExist = true;
            break;
          }
        }
      }
      return charges;
    } else return null;
  }, [entity]);

  return (
    <Page
      title={title}
      lead={loading ? "Loading..." : !entity ? "Month not found" : ""}
      navSidebar={<HostingAgreementNavSidebar />}
    >
      <Tabs defaultActiveKey="Charges" id="tabs" className="mb-3">
        <Tab eventKey="Charges" title="Charges">
          <Customer.HostingAgreementMonths.Table
            data={dataCharges}
            isLoading={loading}
            onRefresh={() => {
              getOne({ id });
            }}
          />
        </Tab>
        <Tab eventKey="Exceptions" title="Exceptions">
          <Customer.HostingAgreementMonths.ExceptionsTable
            data={entity?.exceptions}
            isLoading={loading}
            onRefresh={() => {
              getOne({ id });
            }}
          />
        </Tab>
      </Tabs>
      <Button
        onClick={() => updateOne({ id: id })}
        disabled={loading || !canSubmit}
      >
        Generate Monthly Charges
      </Button>
    </Page>
  );
}
