import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ReportActivity from "./ReportActivity";
import ReportARYearlyCustomerSummary from "./ReportARYearlyCustomerSummary";
import ReportCodeChanges from "./ReportCodeChanges";
import ReportContractorWorkSummary from "./ReportContractorWorkSummary";
import ReportCustomAnalysis from "./ReportCustomAnalysis";
import ReportCustomerBilledByProduct from "./ReportCustomerBilledByProduct";
import ReportCustomerCrs from "./ReportCustomerCrs";
import ReportCustomerProducts from "./ReportCustomerProducts";
import ReportCustomerStatement from "./ReportCustomerStatement";
import ReportCustomerTime from "./ReportCustomerTime";
import ReportDailyBreakdown from "./ReportDailyBreakdown";
import ReportEmployeeByMeasures from "./ReportEmployeeByMeasures";
import ReportHostingYearBreakdown from "./ReportHostingYearBreakdown";
import ReportIndex from "./ReportIndex";
import ReportInternalTime from "./ReportInternalTime";
import ReportModuleSummary from "./ReportModuleSummary";
import ReportNonbillableTime from "./ReportNonbillableTime";
import ReportOutOfOffice from "./ReportOutOfOffice";
import ReportOvertime from "./ReportOvertime";
import ReportPrCrTextSearch from "./ReportPrCrTextSearch";
import ReportQaReviewers from "./ReportQaReviewers";
import ReportTimeOnHold from "./ReportTimeOnHold";
import ReportUnbilledTime from "./ReportUnbilledTime";
import ReportWorkSummary from "./ReportWorkSummary";

export default function ReportsRouting() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/internal-time`}>
        <ReportInternalTime />
      </Route>
      <Route path={`${path}/code-changes`}>
        <ReportCodeChanges />
      </Route>
      <Route path={`${path}/customer-crs`}>
        <ReportCustomerCrs />
      </Route>
      <Route path={`${path}/customer-statement`}>
        <ReportCustomerStatement />
      </Route>
      <Route path={`${path}/ar-yearly-customer-summary`}>
        <ReportARYearlyCustomerSummary />
      </Route>
      <Route path={`${path}/hosting-year-breakdown`}>
        <ReportHostingYearBreakdown />
      </Route>
      <Route path={`${path}/customer-billed-by-product`}>
        <ReportCustomerBilledByProduct />
      </Route>
      <Route path={`${path}/customer-time`}>
        <ReportCustomerTime />
      </Route>
      <Route path={`${path}/daily-breakdown`}>
        <ReportDailyBreakdown />
      </Route>
      <Route path={`${path}/activity`}>
        <ReportActivity />
      </Route>
      <Route path={`${path}/employee-by-measures`}>
        <ReportEmployeeByMeasures />
      </Route>
      <Route path={`${path}/module-summary`}>
        <ReportModuleSummary />
      </Route>
      <Route path={`${path}/nonbillable-time`}>
        <ReportNonbillableTime />
      </Route>
      <Route path={`${path}/custom-analysis`}>
        <ReportCustomAnalysis />
      </Route>
      <Route path={`${path}/out-of-office`}>
        <ReportOutOfOffice />
      </Route>
      <Route path={`${path}/bac`}>
        <ReportOvertime />
      </Route>
      <Route path={`${path}/time-on-hold`}>
        <ReportTimeOnHold />
      </Route>
      <Route path={`${path}/unbilled-time`}>
        <ReportUnbilledTime />
      </Route>
      <Route path={`${path}/work-summary`}>
        <ReportWorkSummary />
      </Route>
      <Route path={`${path}/contractor-work-summary`}>
        <ReportContractorWorkSummary />
      </Route>
      <Route path={`${path}/pr-cr-text-search`}>
        <ReportPrCrTextSearch />
      </Route>
      <Route path={`${path}/qa-reviewers`}>
        <ReportQaReviewers />
      </Route>
      <Route path={`${path}/customer-products`}>
        <ReportCustomerProducts />
      </Route>
      <Route exact path={`${path}`}>
        <ReportIndex />
      </Route>
    </Switch>
  );
}
