import { useType, useTypes } from "./hooks";
import typeState from "./state";
import TypeDescription from "./TypeDescription";
import TypeSelect from "./TypeSelect";
import TypeTableFilter from "./TypeTableFilter";

const Type = {
  // Backend API
  api: typeState.api,
  // State
  actions: typeState.actions,
  selectors: typeState.entity.selectors,
  // Hooks
  useAll: useTypes,
  useOne: useType,
  // Components
  Select: TypeSelect,
  Description: TypeDescription,
  TableFilter: TypeTableFilter,
};
export default Type;
