import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import User from "../../../users";
import { isTierOneGroup } from "../../../users/groups";
import { useFormContext } from "../../forms";

function canOwnerChangeGroup(owner, groupId) {
  const isTierOne =
    (owner === "*TR1*" || owner === "*TR2*") && isTierOneGroup(groupId);
  return owner && owner.charAt(0) === "*" && owner !== "*OSL*" && !isTierOne;
}

export default function useOwnerGroupFields({
  autoUpdate = true,
  isNew = false,
} = {}) {
  const { watch, setValue } = useFormContext();
  const [owner, groupId, statusId] = watch(["owner", "groupId", "statusId"]);

  const loggedInUser = useSelector((state) => state.auth.user);

  const [ownerUpdateId, setOwnerUpdateId] = useState(null);
  const [groupUpdateId, setGroupUpdateId] = useState(null);

  const handleGroupChange = useCallback(
    (groupId, isOwnerReset) => {
      const _isOwnerReset = _.isBoolean(isOwnerReset)
        ? isOwnerReset
        : !owner || canOwnerChangeGroup(owner, groupId);
      setValue("groupId", groupId, { shouldValidate: true, shouldDirty: true });
      if (_isOwnerReset)
        if ((isNew || owner === "*WEB*") && isTierOneGroup(groupId))
          setValue("owner", "*TR1*", {
            shouldValidate: true,
            shouldDirty: true,
          });
        else setGroupUpdateId(groupId);
    },
    [owner, setValue, isNew],
  );

  const handleOwnerChange = useCallback(
    (owner) => {
      if (owner) {
        setValue("owner", owner, { shouldValidate: true, shouldDirty: true });
        if (canOwnerChangeGroup(owner, groupId) && owner?.charAt(0) === "*")
          setOwnerUpdateId(owner);
      } else setGroupUpdateId(groupId);
    },
    [groupId, setValue],
  );

  const { group: { defaultOwner: groupDefaultOwner = "" } = {} } =
    User.Group.useOne({
      id: groupUpdateId,
    });
  const { pseudo: { defaultGroup: ownerDefaultGroup = "" } = {} } =
    User.Pseudo.useOne({
      id: ownerUpdateId,
    });

  // Update owner to default when group changes
  //  or default to current user if not open
  // Update group to default when user changes
  useEffect(() => {
    if (!autoUpdate) return;
    if (!owner && statusId !== "OP")
      setValue("owner", loggedInUser, {
        shouldValidate: true,
        shouldDirty: true,
      });
    else if (groupUpdateId && groupDefaultOwner) {
      setValue("owner", groupDefaultOwner, { shouldValidate: true });
      setGroupUpdateId(null);
    } else if (ownerUpdateId && ownerDefaultGroup) {
      setValue("groupId", ownerDefaultGroup, { shouldValidate: true });
      setOwnerUpdateId(null);
    }
  }, [
    autoUpdate,
    groupDefaultOwner,
    groupUpdateId,
    loggedInUser,
    owner,
    ownerDefaultGroup,
    ownerUpdateId,
    setValue,
    statusId,
  ]);

  return { handleGroupChange, handleOwnerChange };
}
