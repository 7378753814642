import React from "react";

import { useUserGrouping } from "./hooks";
import UserGroupingEditor from "./UserGroupingEditor";

export default function UserGroupingNew({ onNew = () => {} }) {
  const { createOne } = useUserGrouping();
  return (
    <UserGroupingEditor
      isNew
      onSubmit={(values) => createOne(values).then(onNew)}
    />
  );
}
