import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import shark from "../apis/shark";
import ContactTable from "./ContactTable";
import ContactTypeSelect from "./contactTypes/ContactTypeSelect";

// don't move this inside the function unless you memo it
const layout = ["name", "customer", "title", "phones", "email"];

export default function ContactLookupModal({ show, onHide, TableComponents }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const ref = useRef();

  useEffect(() => {
    if (show) ref.current.focus();
  }, [show]);

  const onSubmit = () => {
    setLoading(true);
    shark
      .post({
        command: "contactLookup",
        search: searchValue,
        contactTypes: types,
      })
      .then((result) => {
        setTableData(result.responseData.contacts);
      })
      .catch((result) => {
        toast.error(result);
        console.log(result);
      })
      .finally(() => {
        setLoading(false);
        ref.current.focus();
      });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      dialogClassName="modal-95-percent"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Customer Contact Lookup
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group>
              <Form.Text className="text-muted pb-2">
                Type at least 3 characters then press <kbd>Enter</kbd> and/or
                choose type(s) and hit GO
              </Form.Text>
              <SearchInput
                ref={ref}
                onChange={(v) => {
                  console.log(v);
                  setSearchValue(v);
                }}
                onEnter={onSubmit}
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mb-0">Contact Type</Form.Label>
            <ContactTypeSelect
              id="contactType"
              defaultValue={types}
              onChange={(v) => {
                console.log(v);
                setTypes(v);
              }}
              disabled={loading}
              value={types}
              isMulti
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={onSubmit}>Go</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ContactTable
              layout={layout}
              contacts={tableData}
              contactLink={false}
              Components={TableComponents}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const SearchInput = React.forwardRef(({ onEnter, disabled, onChange }, ref) => {
  const [query, setQuery] = useState("");
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      onEnter(query);
    }
  };
  return (
    <Form.Control
      ref={ref}
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      onKeyDown={handleEnter}
      disabled={disabled}
    />
  );
});
