import React from "react";
import { useSelector } from "react-redux";

import Page from "../../common/pages/Page";
import CodeReviewOptions from "./CodeReviewOptions";

export default function CodeReviewNew() {
  const { canPerformCodeReview, isManager, isAdmin } = useSelector(
    (state) => state.auth,
  );

  if (!(isManager || canPerformCodeReview || isAdmin))
    return (
      <Page
        title="Code Reviews"
        lead="You do not have access to this feature"
      />
    );

  return (
    <Page title="Code Reviews">
      <CodeReviewOptions />
    </Page>
  );
}
